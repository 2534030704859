import gql from 'graphql-tag';
import { userInfoFrag } from './userInfoFrag';
import { mtsActivityTypeFrag } from './mtsActivityTypeFrag';
import { userMtsActivityTypeFrag } from './userMtsActivityTypeFrag';
import { attachmentFrag } from './attachmentFrag';

export const mtsActivityFrag = gql`
  ${attachmentFrag}
  ${userInfoFrag}
  ${mtsActivityTypeFrag}
  ${userMtsActivityTypeFrag}

  fragment MTSActivityFrag on MTSActivity {
    id
    day
    date
    hours
    note
    modifiedAt
    externalApprovalSubmissionId
    formSubmission {
      id
    }
    secondaryFormSubmission {
      id
    }
    lastEditedBy {
      ...UserInfoFrag
    }
    type {
      ...MTSActivityTypeFrag
    }
    userType {
      ...UserMTSActivityTypeFrag
    }
    attachments {
      ...AttachmentFrag
    }
  }
`;
