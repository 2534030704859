import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from '../../dto';
import { ReqVars } from './common';

type Variables = {
  studentIds?: number[];
};
type Results = {
  studentsWithId?: User[];
};

const query = gql`
  query getStudentsById($studentIds: [Int]) {
    studentsWithId(ids: $studentIds) {
      id
      username
      firstName
      lastName
      phoneNumber
      customProperties {
        id
        value
        type {
          id
          name
          fieldType
        }
      }
      emails {
        id
        email
        primary
      }
    }
  }
`;

export const useStudentsWithId = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
