import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSchedulePrintFileDownload } from '../../hooks/gql/useSchedulePrintFileDownload';
import { PrintLocation } from 'tuapath-common/generated/schema';
import { StudentContext } from '../../contexts';

export interface RecordOfContactPrintDialogProps {
  rocId?: number;
  open: boolean;
  toggle: () => void;
}

const ROCDialogContent: React.FC<RecordOfContactPrintDialogProps & { toggle: () => void }> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [success, setSuccess] = React.useState<boolean | undefined>(undefined);
  const studentCtx = useContext(StudentContext);
  const [downloadPrint] = useSchedulePrintFileDownload({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false);
      setSuccess(data.user.schedulePrintFileDownload);
    },
    onError: (e) => {
      setLoading(false);
    }
  });

  const submitButtonPressed = () => {
    setLoading(true);
    void downloadPrint({
      variables: {
        userId: studentCtx?.student.id ?? 0,
        location: PrintLocation.ROC,
        queryInput: {
          relationId: props.rocId ? props.rocId.toString() : undefined,
          toUserId: studentCtx?.student.id ?? 0,
          startFilterDate: startDate !== null ? startDate : undefined,
          endFilterDate: endDate !== null ? endDate : undefined
        }
      }
    });
  };

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (success) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <LanguageString groupName="ROC" resourceName="ROC_PRINT_DIALOG_SUCCESS" defaultText="Your ROC report has been generated and will be emailed to you shortly" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button color="primary" variant="contained" onClick={props.toggle}>
            <LanguageString groupName="GENERAL" resourceName="CLOSE" defaultText="Close" />
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography>
          <LanguageString groupName="ROC" resourceName="ROC_PRINT_DIALOG_INFO" defaultText="Click the Start Generating button to begin creating your ROC report and have it emailed to you" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!props.rocId && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  label={<LanguageString groupName="ROC" resourceName="ROC_START_DATE" defaultText="Start Date" />}
                  value={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  label={<LanguageString groupName="ROC" resourceName="ROC_END_DATE" defaultText="End Date" />}
                  value={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button color="primary" variant="contained" onClick={submitButtonPressed}>
          <LanguageString groupName="GENERAL" resourceName="START_GENERATING" defaultText="Start Generating" />
        </Button>
      </Grid>
    </>
  );
};

export const RecordOfContactPrintDialog: React.FC<RecordOfContactPrintDialogProps> = (props) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.toggle} aria-labelledby="Form Dialog">
      <DialogTitle>
        <LanguageString groupName="ROC" resourceName="ROC_PRINT_DIALOG" defaultText="Generate ROC Report" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <ROCDialogContent {...props} toggle={props.toggle} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
