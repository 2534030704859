import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { ChangePasswordFailureReason } from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { useChangePassword, useLanguageString } from '../../hooks';
import { LanguageString, SuccessIconMessage } from '../Common';

interface ChangePasswordDialogProps {
  open: boolean;
  toggle: () => void;
}

interface ChangePasswordDialogFormSchema {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = (props) => {
  const formRef = useRef<FormikProps<ChangePasswordDialogFormSchema> | null>(null);
  const [changePassword] = useChangePassword();
  const { data } = useLanguageString({ variables: { groupName: 'CHANGE_PW', resourceName: 'WEAK_PW' } });

  const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: ''
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const Validation = Yup.object().shape({
    password: Yup.string().required('* Required'),
    newPassword: Yup.string().required('* Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('* Required')
  });

  const submitButtonClicked = async () => {
    if (formRef && formRef.current) await formRef.current.submitForm();
  };

  let submitEnabled = true;
  if (formRef && formRef.current) {
    const status = formRef.current.status as FormStatus;
    if (status.submitted) {
      submitEnabled = false;
    }
  }

  const handleSubmit = (values: ChangePasswordDialogFormSchema, formikHelpers: FormikHelpers<ChangePasswordDialogFormSchema>) => {
    if (values.confirmPassword.length > 0 && values.confirmPassword === values.newPassword) {
      changePassword({
        variables: {
          input: {
            oldPassword: values.password,
            newPassword: values.confirmPassword
          }
        },
        update: (cache, result) => {
          let success = false;
          let message: string | undefined = undefined;

          if (result.data) {
            if (result.data.changePassword.success) {
              success = true;
            } else if (result.data.changePassword.reason) {
              switch (result.data.changePassword.reason) {
                case ChangePasswordFailureReason.oldPasswordIncorrect:
                  message = 'An old password was used. Please try a different one';
                  break;
                case ChangePasswordFailureReason.passwordBreached:
                  message = 'Bad password, please use a different one';
                  break;
                case ChangePasswordFailureReason.validationError:
                  message = 'Validation error. Please make sure your passwords were the same.';
                  break;
                case ChangePasswordFailureReason.serverError:
                  message = 'Server error. Please try again soon.';
                  break;
                case ChangePasswordFailureReason.weakPassword:
                  message = data?.string.text;
                  break;
                default:
                  message = 'An unknown error occurred';
              }
            } else {
              message = 'An unknown error occured';
            }
          } else {
            message = 'An unknown error occured';
          }

          formikHelpers.setStatus({
            submitted: success,
            message: message
          });
        }
      }).catch((res) => {
        const errors: string[] = res.graphQLErrors.map((gqlError: ApolloError) => gqlError.message);
        const error = errors.join(', ');

        if (errors.length > 0) {
          formikHelpers.setStatus({
            submitted: false,
            message: error
          });
        }
      });
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={props.toggle}>
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
          <LanguageString groupName="SITE_CONENT" resourceName="CHANGE_PASSWORD" defaultText="Change Password" />
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={props.toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} initialStatus={initialStatus} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={Validation}>
          {formikProps => {
            formRef.current = formikProps;

            const status = formikProps.status as FormStatus;

            if (status.submitted) {
              return (
                <SuccessIconMessage animated message="Success" />
              );
            }

            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="password">
                      {() => (
                        <TextField
                          fullWidth
                          name="password"
                          label="Current Password"
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.password && formikProps.touched.password)}
                          helperText={formikProps.errors.password && formikProps.touched.password && String(formikProps.errors.password)}
                        />
                      )}
                    </Field>

                    <Field validateOnBlur validateOnChange name="newPassword">
                      {() => (
                        <TextField
                          fullWidth
                          name="newPassword"
                          label="New Password"
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.newPassword && formikProps.touched.newPassword)}
                          helperText={formikProps.errors.newPassword && formikProps.touched.newPassword && String(formikProps.errors.newPassword)}
                        />
                      )}
                    </Field>

                    <Field validateOnBlur validateOnChange name="confirmPassword">
                      {() => (
                        <TextField
                          fullWidth
                          name="confirmPassword"
                          label="Confirm New Password"
                          type="password"
                          autoComplete="current-password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.confirmPassword && formikProps.touched.confirmPassword)}
                          helperText={formikProps.errors.confirmPassword && formikProps.touched.confirmPassword && String(formikProps.errors.confirmPassword)}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                {(formikProps.status as FormStatus).message && (
                  <Grid item xs={12}>
                    <Alert severity="error">{formikProps.status.message}</Alert>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
        <DialogActions>
          <Button type="submit" variant="text" color="primary" onClick={props.toggle}>
            <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
          </Button>
          <Button disabled={!submitEnabled} color="primary" variant="contained" onClick={submitButtonClicked}>
            <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="SUBMIT" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default ChangePasswordDialog;
