import React from 'react';
import {
  Link
} from '@material-ui/core';
import { Location } from 'history';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export const RelativeLink: React.FC<LinkProps> = (props) => {
  return (
    <Link
      style={props.style}
      component={RouterLink}
      to={(location: Location<unknown>) => {
        return location.pathname + props.to;
      }}
    >
      {props.children}
    </Link>
  );
};
export default RelativeLink;
