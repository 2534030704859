import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { languageStringFrag, sitePathsMilestoneGroupFrag } from './fragments';

export type SitePathsResults = { site: Site };
export const sitePathsQuery = gql`
  ${languageStringFrag}
  ${sitePathsMilestoneGroupFrag}

  query sitePaths {
    site {
      id
      paths {
        id
        name {
          ...LangStringFrag
        }
        description {
          ...LangStringFrag
        }
        milestoneGroups {
          ...msGroup
          subGroups {
            ...msGroup
            subGroups {
              ...msGroup
              subGroups {
                ...msGroup
              }
            }
          }
        }
      }
    }
  }
`;

export function useSitePaths(options?: QueryHookOptions<SitePathsResults>) {
  return useQuery<SitePathsResults>(sitePathsQuery, options);
}
