import React from 'react';
import { Form, PrintLocation } from '../../dto';
import { Grid, Typography } from '@material-ui/core';
import { DownloadTemplate } from '../Common';

interface FormHeaderProps {
  form: Form;
  renderFormTitle?: boolean;
  disabledDownloadButton?: boolean;
  formSubmissionId?: number;
}

export const FormHeader: React.FC<FormHeaderProps> = ({ form, renderFormTitle, disabledDownloadButton, formSubmissionId }) => {

  return (
    <Grid container item direction="row" justify="space-between">
      <Grid item>
        {(renderFormTitle === undefined || renderFormTitle === true) && form.title && (
          <Typography variant="h5">{form.title.text ?? ''}</Typography>
        )}
      </Grid>
      <Grid item>
        {form.downloadButtonEnabled && (
          <DownloadTemplate
            location={formSubmissionId && formSubmissionId > 0  ? PrintLocation.FormSubmission : PrintLocation.Form}
            primary={true}
            relationId={formSubmissionId && formSubmissionId > 0 ? formSubmissionId.toString() : form.id.toString()}
            schemaId={form.printFileSchema?.id}
            disabledDownloadButton={disabledDownloadButton}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FormHeader;
