import { CircularProgress, Grid, Icon, InputAdornment, Paper, TextField, TablePagination } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { StudentsContext } from '../../contexts';
import { LocalStorageKey, useLocalStorage } from '../../hooks';
import { LanguageString } from '../Common';
import { initialColumnsSettings } from './StudentsFiltersDialog';
import StudentsSearchTable from './StudentsSearchTable';

type DelayTextFieldProps = {
  id: string;
  delay: number;
  type?: string;
  label?: JSX.Element;
  fullWidth?: boolean;
  value?: string;
  onChange: (value: string) => void;
  endAdornment: React.ReactNode;
};

const DelayTextField: React.FC<DelayTextFieldProps> = (props) => {
  const { value, endAdornment, onChange, delay, ...tfProps } = props;
  const [search, setSearch] = useState(value ?? '');
  const lastChange = useRef(0);
  const delayChangeTimer = useRef<NodeJS.Timeout | undefined>();
  const searchRef = useRef(value ?? '');

  const delayChangeTimerFunc = () => {
    if (Date.now() - lastChange.current > delay) {
      delayChangeTimer.current = undefined;
      onChange(searchRef.current);
    } else {
      delayChangeTimer.current = setTimeout(delayChangeTimerFunc, delay);
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    lastChange.current = Date.now();
    if (!delayChangeTimer.current) delayChangeTimer.current = setTimeout(delayChangeTimerFunc, delay);
    searchRef.current = ev.currentTarget.value;
    setSearch(ev.currentTarget.value);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.keyCode === 13) {
      if (delayChangeTimer.current) {
        clearTimeout(delayChangeTimer.current);
        delayChangeTimer.current = undefined;
      }
      onChange(searchRef.current);
    }
  };

  return <TextField {...tfProps} value={search} InputProps={{ endAdornment: endAdornment }} onChange={handleChange} onKeyDown={handleKeyDown} />;
};

export const BrowseStudentsSearch: React.FC = () => {
  const studentsCtx = useContext(StudentsContext);
  const [columns] = useLocalStorage(LocalStorageKey.StudentsTableColumns, initialColumnsSettings);
  const rowsPerPageOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[rowsPerPageOptions.length - 1]);

  useEffect(() => {
    if (studentsCtx) {
      studentsCtx.setLimit(rowsPerPage);
      studentsCtx.setOffset(0);
    }
  }, []);

  useEffect(() => {
      if (studentsCtx && studentsCtx.advisorIds.length > 0) studentsCtx.setAdvisorIds([]);
  }, [studentsCtx]);

  const students = useMemo(() => studentsCtx?.filteredStudents ?? [], [studentsCtx?.filteredStudents]);

  const triggerSearch = (text: string) => studentsCtx ? studentsCtx.setSearch(text) : undefined;

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
    if (studentsCtx) {
      studentsCtx.setOffset(studentsCtx.limit * newPage);
    }
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const rows = Number(event.target.value);
    if (studentsCtx) {
      studentsCtx.setOffset(0);
      studentsCtx.setLimit(rows);
    }
    setRowsPerPage(rows);
    setPage(0);
  }

  const endAdornment = studentsCtx?.loading
    ? <InputAdornment position="end"><CircularProgress size="20px" color="primary" /></InputAdornment>
    : <InputAdornment position="end"><Icon>search</Icon></InputAdornment>;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DelayTextField
          id="searchField"
          delay={1000}
          fullWidth
          type="search"
          label={<LanguageString groupName="GENERAL" resourceName="SEARCH_STUDENTS" defaultText="Search Students" />}
          onChange={triggerSearch}
          endAdornment={endAdornment}
        />
      </Grid>

      {studentsCtx?.search && studentsCtx.search.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <StudentsSearchTable students={students} columns={columns} />
          </Paper>
        </Grid>
      )}

      <Grid item xs={12}>
        <TablePagination

          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={studentsCtx?.totalCount ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>

    </Grid>
  );
};
export default BrowseStudentsSearch;
