import React, { useContext, useMemo } from 'react';
import {
  Grid
} from '@material-ui/core';
import { addDays } from 'date-fns';
import CalendarMonthViewCell from '../CalendarMonthView/CalendarMonthViewCell';
import { useClasses } from '../../../hooks';
import { MTSContext } from '../../../contexts';

export const CalendarCustomViewRow: React.FC<{ periodStartDate: Date, periodEndDate: Date, weekStartDate: Date }> = ({ periodStartDate, periodEndDate, weekStartDate }) => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  const cells = useMemo(() => {
    const endOfWeek = addDays(weekStartDate, 7);
    endOfWeek.setHours(0, 0, 0, 0);

    const c: React.ReactNode[] = [];
    for (let i = 0; i <= 6; i++) {
      const date = addDays(weekStartDate, i);

      c.push(
        <CalendarMonthViewCell
          key={`calendar_month_cell_${date}`}
          showMonth
          mtsPeriod={mtsCtx.mtsPeriod}
          date={date}
          monthDate={date}
          periodStartDate={periodStartDate}
          periodEndDate={periodEndDate}
        />
      );
    }

    return c;
  }, [weekStartDate, periodStartDate]);

  return (
    <Grid item xs={12}>
      <Grid className={classes.mtsMonthRow} container direction="row" justify="flex-start">
        {cells}
      </Grid>
    </Grid>
  );
};
export default CalendarCustomViewRow;
