import { FieldMetaProps, FieldProps, FormikContextType } from 'formik';
import { hasProp } from '../../common';
import { Form, FormElement, FormItemEnableRule, FormOperation, FormQuestion, FormQuestionType, LanguageString, SecuredFormSubmission } from '../../dto';
import { QueryParams } from '../../hooks/Form/common';

export interface IValues {
  [questionId: string]: (number | string)[] | Date | boolean | number | string | null;
}

export const isNumber = (str: string) => !isNaN(parseInt(str)) && !isNaN(Number(str));
export const isReadOnly = (form: TypedFieldProps['form']) => form.status && form.status.isReadOnly;
export const isInvalid = (meta: FieldMetaProps<unknown>) => meta.touched && !!meta.error;
export const isNonBlank = (languageString: LanguageString | undefined) =>
  languageString != null && languageString.text != null && languageString.text.length > 0;
export const convertToFormId = (id: number) => `question-${id}`;
export const getQuestionFormId = (question: FormQuestion) => convertToFormId(question.id);
export const getQuestionFormLabel = (question: FormQuestion) => (question.text && question.text.text ? question.text : null);
export const isDateQuestion = ({ questionType }: FormQuestion) =>
  questionType === FormQuestionType.DATE || questionType === FormQuestionType.DATETIME || questionType === FormQuestionType.TIME || questionType === FormQuestionType.DUEDATE;

export type FormStatus = { isReadOnly: boolean; isSaving: boolean; disabled: boolean };
export interface TypedFieldProps {
  field: FieldProps['field'];
  form: Omit<FieldProps['form'], 'setStatus' | 'status'> & { status?: FormStatus; setStatus: (status: FormStatus) => void };
}
export type FormFieldComp<T = {}> = React.FC<T & { question: FormQuestion }>;
type DependentItem = { dependsOn?: Array<FormItemEnableRule> };

export type QuestionCache = { [questionFormId: string]: FormQuestion };
function isDependsOnRuleSatisified(values: IValues, depend?: FormItemEnableRule) {
  if (depend == null) throw new Error(`No rule`);

    const sourceVal = values && values[getQuestionFormId(depend.source)];
    switch (depend.operation) {
      case FormOperation.SIMPLE:
        return sourceVal != null && sourceVal !== '';
      case FormOperation.EQUAL:
        return String(sourceVal) === depend.operand;
      case FormOperation.NOT_EQUAL:
        return String(sourceVal) !== depend.operand;
      case FormOperation.MATCH:
        return new RegExp(depend.operand).test(String(sourceVal));
      default:
        return false;
    }
}

const isDependsOnLogicallySatisified = (values: IValues, origLogicalRule: string, question: DependentItem): boolean => {
  if (question.dependsOn == null) throw new Error(`No rules`);
  let logicalRule = origLogicalRule;
  const testExp = /\((?:(?<not1>NOT)\s+)?(?<arg1>\w+)\s+(?<op>OR|AND)\s+(?:(?<not2>NOT)\s+)?(?<arg2>\w+)\)/;
  while (logicalRule.match(testExp) != null) {
    logicalRule = logicalRule.replace(testExp, (exp) => {
      const test = exp.match(testExp);
      if (test?.groups) {
        const { arg1, arg2, op, not1, not2 } = test.groups;
        let arg1Satisified = arg1 === 'true' ? true : arg1 === 'false' ? false : isDependsOnRuleSatisified(values, question.dependsOn?.find(dep => dep.name === arg1));
        let arg2Satisified = arg2 === 'true' ? true : arg2 === 'false' ? false : isDependsOnRuleSatisified(values, question.dependsOn?.find(dep => dep.name === arg2));
        if (not1) arg1Satisified = !arg1Satisified;
        if (not2) arg2Satisified = !arg2Satisified;

        return op === 'OR' ? String(arg1Satisified || arg2Satisified) : String(arg1Satisified && arg2Satisified);
      }

      throw new Error(`No groups`);
    });
  }

  const resultTestExp = /(?:(?<not>NOT)\s+)?(?<rslt>true|false)/;
  const resultMatch = logicalRule.match(resultTestExp);
  if (resultMatch?.groups == null) throw new Error(`Syntax error in logical rule ${origLogicalRule}`);
  const { not, rslt } = resultMatch.groups;
  const rsltTrue = not ? 'false' : 'true';

  return rslt === rsltTrue ? true : false;
};

// TODO: This will need to traverse up the tree hierarchy and check containing elements
export function isDependsOnSatisfied(values: IValues, question: DependentItem) {
  if (question.dependsOn == null || question.dependsOn.length === 0) return true;
  const logicalRule = question.dependsOn.find(dep => dep.operation === FormOperation.LOGICAL);
  return logicalRule == null
    ? question.dependsOn.every(depend => isDependsOnRuleSatisified(values, depend))
    : isDependsOnLogicallySatisified(values, logicalRule.operand, question);
}

export const isDependsOnSatisfiedForAll = (valuesArray: IValues[], question: DependentItem) => question.dependsOn?.length ? valuesArray.every(values => isDependsOnSatisfied(values, question)) : true;
export const isDependsOnSatisfiedForAny = (valuesArray: IValues[], question: DependentItem) => question.dependsOn?.length ? valuesArray.some(values => isDependsOnSatisfied(values, question)) : true;

export function getQueryVariables(question: FormQuestion, formik: FormikContextType<IValues>) {
  if (question.dependsOn && question.dependsOn.length > 0) {
    const variables = {};

    for (const dependsOnSingle of question.dependsOn) {
      const currentFormValue = formik.values[getQuestionFormId(dependsOnSingle.source)];
      if (currentFormValue) {
        variables[`${dependsOnSingle.name}`] = currentFormValue;
      }
    }

    return variables;
  }

  return undefined;
}

export const getQueryParamsForQuestion = (question: FormQuestion, answerMap: IValues, questionMap: { [questionId: string]: FormQuestion }) => {
  const rtnVal: QueryParams = {};
  question.queryParams?.filter(qp => qp.source != null).forEach(qp => {
    const pqFormId = getQuestionFormId(qp.source!);
    const paramQuestion = questionMap[pqFormId];
    const ans = String(answerMap[pqFormId]);
    if (ans != null && ans !== '' && qp.name != null) {
      rtnVal[paramQuestion.id] = {
        name: qp.name,
        property: paramQuestion.userProperty,
        value: ans
      };
    }
  });

  return rtnVal;
};

export const getQueryParamsFromFormik = (formik: FormikContextType<IValues>, question: FormQuestion): QueryParams =>
  getQueryParamsForQuestion(question, formik.values, formik.status.qc);

const propsRegex = /\${([^}]*)}/g;

export function getDynamicSelectOptions({ optionDefinition: od }: FormQuestion, data: unknown) {
  // TODO: error if question is not dynamic option type question
  const getDeepProp = (pathString: string, obj: unknown) => {
    const path = pathString.split('.');
    let deepProp = obj;
    for (const comp of path) {
      deepProp = Array.isArray(deepProp) ? deepProp[0]?.[comp] : hasProp(deepProp, comp) ? deepProp[comp] : undefined;
    }

    return isNaN(deepProp as number) ? deepProp : Number(deepProp);
  };

  if (od == null) return null;

  const { label, value, optionsPath } = od;

  if (label != null && value != null && optionsPath != null) {
    const queryObject = getDeepProp(optionsPath, data);
    return Array.isArray(queryObject)
      ? queryObject.map(item => ({
          label: label.replace(propsRegex, (_substring, prop) => String(getDeepProp(prop, item))),
          value: getDeepProp(value, item) as number | string
        }))
      : null;
  }

  return null;
}

export function* elementsIterator(elements: FormElement[]): Generator<FormElement, void, unknown> {
  for (const el of elements) {
    yield el;
    if (el.childElements) yield* elementsIterator(el.childElements);
  }
}

export function* questionsIterator(elements: FormElement[], pred?: (q: FormQuestion) => boolean): Generator<FormQuestion, void, unknown> {
  for (const el of elements) {
    if (pred != null) {
      if (el.questions != null) for (const q of el.questions) if (pred(q)) yield q;
    } else if (el.questions != null) for (const q of el.questions) yield q;

    if (el.childElements) yield* questionsIterator(el.childElements, pred);
  }
}

export const getFormQuestion = (qName: string, form: Form) => {
  if (form.elements != null) {
    for (const el of elementsIterator(form.elements)) {
      const question = el.questions?.find(q => q.name?.includes(qName));
      if (question != null) return question;
    }
  }

  return null;
};

export const getFormQuestions = (form: Form) => form.elements ? [...questionsIterator(form.elements)] : [];;

export const convertFsToValues = (fs: SecuredFormSubmission, questionCache: { [qId: string]: FormQuestion }) => {
  const values: IValues = {};

  for (const answer of fs.answers) {
    if (answer.answer == null && answer.selections == null) continue;
    const questionFormId = getQuestionFormId(answer.question);
    const question = questionCache[questionFormId];
    if (question == null) continue;

    const subQuestion = question.question;
    switch (question.questionType) {
      case FormQuestionType.BOOLEAN:
        values[questionFormId] = answer.answer === 'true';
        break;
      case FormQuestionType.SELECT:
        if (answer.selections != null && answer.selections.length > 0) values[questionFormId] = answer.selections[0].id;
        break;
      case FormQuestionType.MULTISELECT:
        if (answer.selections?.length ?? 0 > 1) values[questionFormId] = answer.selections!.map(s => s.id);
        else if (answer.selections?.length ?? 0 > 0) values[questionFormId] = answer.selections![0].id;
        break;
      case FormQuestionType.RATING:
        if (answer.answer) values[questionFormId] = Number(answer.answer);
        else values[questionFormId] = question.minRateLevel ?? 1;
        break;
      case FormQuestionType.GRAPH:
      case FormQuestionType.GRAPHWITHDATE:
        break;
      case FormQuestionType.DATE:
      case FormQuestionType.DATETIME:
      case FormQuestionType.DUEDATE:
      case FormQuestionType.TIME:
        if (answer.answer) values[questionFormId] = new Date(answer.answer);
        break;
      default:
        if (answer.selections != null && answer.selections.length > 0) {
          const selId = answer.selections[0].id;
          const opt = subQuestion && subQuestion.options ? subQuestion.options.find(o => o.id === selId) : null;
          values[questionFormId] = opt != null ? opt.text : selId;
        } else if (answer.answer != null) {
          values[questionFormId] = answer.answer;
        }
    }
  }

  return values;
};

type QueryArgContext = { [prop: string]: QueryArgContext };
export const resolveQueryArgTemplate = (ruleTemplate: string, context: QueryArgContext) =>
  ruleTemplate.replace(/\${([^}]*)}/g, (v) => {
    const fields = v.slice(2, -1).split('.');
    return fields.reduce((pr, cu) => pr[cu], context) as unknown as string;
  });
