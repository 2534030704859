import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { RecordOfContacts } from '../../components/RecordOfContacts';
import { SiteContext } from '../../contexts';

interface EmployerContactROCDialogProps {
  toggle: () => void;
  open: boolean;
}

export const EmployerContactROCDialog: React.FC<EmployerContactROCDialogProps> = ({ open, toggle }) => {

  const siteCtx = useContext(SiteContext);
  const [tab, setTab] = useState(0);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={toggle} aria-labelledby="Form Dialog">
      <DialogTitle>
        <LanguageString groupName="EMPLOYER_CONTACT" resourceName="RECORD_OF_CONTACTS" defaultText="Record of Contacts" />
      </DialogTitle>
      <DialogContent dividers>
        <Paper>
          <Tabs
            value={tab}
            onChange={(event, val) => setTab(val)}
            aria-label="Employer Contact Tab"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={<LanguageString groupName="EMPLOYER_CONTACT" resourceName="EMPLOYERS_THAT_CONTACTED_ME" defaultText="Employers That Contacted Me" />} value={0} />
            <Tab label={<LanguageString groupName="EMPLOYER_CONTACT" resourceName="EMPLOYERS_IVE_CONTACTED" defaultText="Employers I've Contacted" />} value={1} />
          </Tabs>
          {siteCtx.site?.employeeInfoROCTypeId && tab == 0 && (
            <RecordOfContacts showTitleBar={false} filter={{ rocTypeId: siteCtx.site.employeeInfoROCTypeId, filterTo: true }} />
          )}
          {siteCtx.site?.employeeInfoROCTypeId && tab == 1 && (
            <RecordOfContacts showTitleBar={false} filter={{ rocTypeId: siteCtx.site.employeeInfoROCTypeId, filterTo: false }} />
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={toggle}>
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
