import { Grid } from '@material-ui/core';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import * as yup from 'yup';
import { LanguageString } from '..';
import { SiteStatusContext } from '../../contexts';
import { useClasses } from '../../hooks';

export interface FormValues {
  file: File[];
}

interface FileTableDropzoneProps {
  callback: (file: File) => Promise<void>;
}

type DropzoneProps = { name: string; disabled?: boolean, formEmbed?: boolean };
export const Dropzone: React.FC<DropzoneProps> = ({ name, disabled, formEmbed }) => {
  const classes = useClasses();
  const formik = useFormikContext<dropzoneFormValues>();
  const onDrop = useCallback((acf: File[]) => {
    formik.setFieldValue(name, acf);
    if (!formEmbed) {
      void formik.submitForm();
    }
  }, []);
  const { getInputProps, getRootProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, disabled });

  return (
    <div {...getRootProps({ className: `${classes.dropzoneContainer}${disabled ? ' disabled' : ''}` })} role="button">
      <input {...getInputProps()} placeholder="val" multiple={false} />
      {disabled ? (
        <p><LanguageString groupName="FILEBOX" resourceName="DROPZONEDISABLED" defaultText="Disabled" /></p>
      ) : formEmbed && acceptedFiles.length ? (
        acceptedFiles.map(f => <p key={f.name}>{f.name}</p>)
      ) : isDragActive ? (
        <p><LanguageString groupName="FILEBOX" resourceName="DROPZONEDRAGACTIVE" defaultText="Drop the files here ..." /></p>
      ) : (
        <p><LanguageString groupName="FILEBOX" resourceName="DROPZONEWAIT" defaultText="Drag 'n' drop some files here, or click to select files" /></p>
      )}
    </div>
  );
};

export type dropzoneFormValues = { file: string };
export const FileTableDropzone: React.FC<FileTableDropzoneProps> = (props) => {
  const siteStatCtx = useContext(SiteStatusContext);

  const formValidation = yup.object<FormValues>().shape({
    file: yup.mixed().required('A file is required')
  });

  return (
    <Formik<FormValues>
      initialValues={{
        file: []
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={formValidation}
      onSubmit={async (values: FormValues) => {
        if (values.file.length < 1) {
          siteStatCtx.setStatus(Error('No files were dropped'));
        }
        void props.callback(values.file[0]);
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Dropzone name="file" />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>);
};
