import React, { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  Icon
} from '@material-ui/core';
import { useClasses } from '../../hooks';
import { MTSCalendarViewType, MTSContext } from '../../contexts';
import MTSHelpers from '../../helpers/mtsHelpers';
import {
  startOfMonth,
  endOfMonth
} from 'date-fns';
import { MTSDocumentsDialog } from './MTSDocumentsDialog';
import { MTSReviewHoursDialog } from './MTSReviewHoursDialog';
import { LogNotesDialog } from './LogNotesDialog';
import { DownloadTemplate, EmailDialog, LanguageString } from '../Common';
import { PrintLocation } from 'tuapath-common/generated/schema';

export const MTSFooter: React.FC = () => {
  const mtsCtx = useContext(MTSContext);
  const classes = useClasses();

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [logNotesOpen, setLogNotesOpen] = useState(false);
  const [documentModalOpen, setDocumentsModalOpen] = useState(false);
  const [reviewHoursModalOpen, setReviewHoursModalOpen] = useState(false);

  let monthStart = startOfMonth(mtsCtx.date);
  let monthEnd = endOfMonth(mtsCtx.date);

  if (mtsCtx.mtsPeriods.length > 0) {
    let startDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;

    for (const period of mtsCtx.mtsPeriods) {
      if ((startDate && MTSHelpers.dateFromString(period.startDate) < startDate) || !startDate) {
        startDate = MTSHelpers.dateFromString(period.startDate);
      }
      if ((endDate && MTSHelpers.dateFromString(period.endDate, true) > endDate) || !endDate) {
        endDate = MTSHelpers.dateFromString(period.endDate, true);
      }
    }

    if (startDate) monthStart = startDate;
    if (endDate) monthEnd = endDate;
  }

  const weekTotalHours = MTSHelpers.totalHoursForDateRange(mtsCtx.startOfWeek, mtsCtx.endOfWeek, mtsCtx.mtses);
  const weekWprHours = MTSHelpers.wprHoursForDateRange(mtsCtx.startOfWeek, mtsCtx.endOfWeek, mtsCtx.mtses);
  const monthTotalMonthlyHours = MTSHelpers.totalHoursForDateRange(monthStart, monthEnd, mtsCtx.mtses);
  const monthWprMonthlyHours = MTSHelpers.wprHoursForDateRange(monthStart, monthEnd, mtsCtx.mtses);

  const toggleDocumentsModal = () => {
    setDocumentsModalOpen(!documentModalOpen);
  };

  const toggleLogNotes = () => {
    setLogNotesOpen(!logNotesOpen);
  };

  const toggleEmailDialog = () => {
    setEmailDialogOpen(!emailDialogOpen);
  };

  const toggleReviewHoursModal = () => {
    setReviewHoursModalOpen(!reviewHoursModalOpen);
  };

  return (
    <Toolbar className={classes.mtsCalendarFooter}>
      <Grid container>
        <Grid item xs>
          <Grid container spacing={3} justify="space-evenly">
            {(
              mtsCtx.viewType === MTSCalendarViewType.Month ||
              mtsCtx.viewType === MTSCalendarViewType.Custom ||
              mtsCtx.viewType === MTSCalendarViewType.CustomGrouped)
              && (
              <>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        <LanguageString groupName="MTS" resourceName="TOTAL_HOURS" defaultText="Total Hours" />
                      </Typography>
                      <Typography variant="h5" color="primary">{monthTotalMonthlyHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        <LanguageString groupName="MTS" resourceName="WPR_HOURS" defaultText="WPR Hours" />
                      </Typography>
                      <Typography variant="h5" color="primary">{monthWprMonthlyHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}

            {(mtsCtx.viewType === MTSCalendarViewType.Week || mtsCtx.viewType === MTSCalendarViewType.CustomWeek) && (
              <>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        <LanguageString groupName="MTS" resourceName="TOTAL_HOURS" defaultText="Total Hours" />
                      </Typography>
                      <Typography variant="h5" color="primary">{weekTotalHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        <LanguageString groupName="MTS" resourceName="WPR_HOURS" defaultText="WPR Hours" />
                      </Typography>
                      <Typography variant="h5" color="primary">{weekWprHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        {mtsCtx.viewType === MTSCalendarViewType.Week && (
                          <LanguageString groupName="MTS" resourceName="MONTHLY_TOTAL_HOURS" defaultText="Monthly Total Hours" />
                        )}
                        {mtsCtx.viewType === MTSCalendarViewType.CustomWeek && (
                          <LanguageString groupName="MTS" resourceName="PERIOD_TOTAL_HOURS" defaultText="Period Total Hours" />
                        )}
                      </Typography>
                      <Typography variant="h5" color="primary">{monthTotalMonthlyHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="textSecondary">
                        {mtsCtx.viewType === MTSCalendarViewType.Week && (
                          <LanguageString groupName="MTS" resourceName="MONTHLY_WPR_HOURS" defaultText="Monthly WPR Hours" />
                        )}
                        {mtsCtx.viewType === MTSCalendarViewType.CustomWeek && (
                          <LanguageString groupName="MTS" resourceName="PERIOD_WPR_HOURS" defaultText="Period WPR Hours" />
                        )}
                      </Typography>
                      <Typography variant="h5" color="primary">{monthWprMonthlyHours}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {(mtsCtx.viewType === MTSCalendarViewType.Month || mtsCtx.viewType === MTSCalendarViewType.Custom || mtsCtx.viewType === MTSCalendarViewType.CustomGrouped) && (
          <Grid item xs>
            <Grid container justify="flex-end" alignItems="flex-end">
              <Grid item>
                {mtsCtx.mtses.length > 0 && (
                  <DownloadTemplate
                    location={PrintLocation.MTS}
                    relationId={mtsCtx.mtses[0].id.toString()}
                  />
                )}
                <Tooltip title="Documents">
                  <IconButton aria-label="view" onClick={toggleDocumentsModal}>
                    <Icon>folder</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Email">
                  <IconButton aria-label="view" onClick={toggleEmailDialog}>
                    <Icon>email</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notes">
                  <IconButton disabled={mtsCtx.mtses.length <= 0} aria-label="view" onClick={toggleLogNotes}>
                    <Icon>create</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Review Hours">
                  <IconButton disabled={mtsCtx.mtses.length <= 0} aria-label="view" onClick={toggleReviewHoursModal}>
                    <Icon>check_circle</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <MTSDocumentsDialog open={documentModalOpen} toggle={toggleDocumentsModal} />
      <MTSReviewHoursDialog open={reviewHoursModalOpen} toggle={toggleReviewHoursModal} />
      <LogNotesDialog open={logNotesOpen} toggle={toggleLogNotes} />
      <EmailDialog open={emailDialogOpen} toggle={toggleEmailDialog} />
    </Toolbar>
  );
};
