import { Milestone, MilestoneGroup, Roadmap, RoadmapMilestone, RoadmapMilestoneGroup, SuccessPath, Task, User } from '../dto';

type MilestoneGroupMap = {
  [groupId: number]: MilestoneGroup;
};

export class RoadmapHelper {
  static roadmapMilestoneGroupForMilestone(milestone: RoadmapMilestone, roadmap: Roadmap): RoadmapMilestoneGroup | undefined {
    if (roadmap.milestoneGroups) {
      for (const group of roadmap.milestoneGroups) {
        if (group.milestones) {
          for (const ms of group.milestones) {
            if (ms.id === milestone.id) {
              return group;
            }
          }
        }
      }
    }

    return undefined;
  }

  private static addGroupsToParentMap(parentMap: MilestoneGroupMap, msGroups: MilestoneGroup[], parentGroup: MilestoneGroup) {
    for (const grp of msGroups) {
      parentMap[grp.id] = parentGroup;
      if (grp.subGroups != null) this.addGroupsToParentMap(parentMap, grp.subGroups, grp);
    }
  }

  private static parentMilestoneGroupMap(path: SuccessPath) {
    const parentMap: MilestoneGroupMap = {};
    if (path.milestoneGroups) {
      for (const grp of path.milestoneGroups) {
        if (grp.subGroups != null) this.addGroupsToParentMap(parentMap, grp.subGroups, grp);
      }
    }

    return parentMap;
  }

  static parentMilestoneGroup(milestoneGroup: MilestoneGroup, path: SuccessPath): MilestoneGroup | undefined {
    const map = this.parentMilestoneGroupMap(path);
    return map[milestoneGroup.id];
  }

  private static _milestoneGroupForMilestone(milestone: Milestone, group: MilestoneGroup): MilestoneGroup | undefined {
    if (group.milestones) {
      for (const ms of group.milestones) {
        if (ms.id === milestone.id) return group;
      }
    }
    if (group.subGroups) {
      for (const subg of group.subGroups) {
        const g = this._milestoneGroupForMilestone(milestone, subg);
        if (g) return g;
      }
    }

    return undefined;
  }

  static milestoneGroupForMilestone(milestone: Milestone, path: SuccessPath): MilestoneGroup | undefined {
    if (path.milestoneGroups) {
      for (const group of path.milestoneGroups) {
        const foundGroup = this._milestoneGroupForMilestone(milestone, group);
        if (foundGroup) return foundGroup;
      }
    }

    return undefined;
  }

  static getAssignment = (user: User, milestone: Milestone, task: Task, rmMsId?: number) =>
    user.assignments?.assignments?.find(a => a.milestoneId === milestone.id && a.taskId === task.id && a.roadmapMilestoneId === (rmMsId ?? null));

  private static milestoneForIdInGroup(id: number, group: MilestoneGroup): Milestone | undefined {
    if (group.milestones) {
      for (const ms of group.milestones) {
        if (ms.id === id) return ms;
      }
    }
    if (group.subGroups) {
      for (const subg of group.subGroups) {
        const ms = this.milestoneForIdInGroup(id, subg);
        if (ms) return ms;
      }
    }

    return undefined;
  }

  static milestoneForRoadmapMilestone(roadmapMilestone: RoadmapMilestone, path: SuccessPath): Milestone | undefined {
    if (path.milestoneGroups) {
      for (const group of path.milestoneGroups) {
        const ms = this.milestoneForIdInGroup(roadmapMilestone.milestoneId, group);
        if (ms) return ms;
      }
    }

    return undefined;
  }
}
