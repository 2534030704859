import { ApolloError } from '@apollo/client';
import { Button, Dialog, DialogContent, Grid, Tab, Tabs, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { DialogTitle, LanguageString, SuccessIconMessage } from '../';
import { StudentContext, UserContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { RecordOfContactInput } from '../../../dto';
import { useRocTypes, useSaveRoc, useSendSupportEmail } from '../../../hooks';
import { getFormQuestion } from '../../DynamicForm/common';

interface ForgotPasswordDialogProps {
  open: boolean;
  toggle: () => void;
  initialSubject?: string;
  initialBody?: string;
  sendToSupport?: boolean;
}

interface FormSchema {
  subject: string;
  body: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

interface EmailFormProps {
  isAdvisor: boolean;
  tab: number;
  initialSubject?: string;
  initialBody?: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ isAdvisor, tab, initialSubject, initialBody }) => {
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const formik = useRef<FormikProps<FormSchema> | null>(null);
  const rocTypes = useRocTypes({ variables: {} });
  const [sendSupportEmail] = useSendSupportEmail();
  const [saveRoc] = useSaveRoc();
  const [submitting, setSubmitting] = useState(false);

  const initalFormValues: FormSchema = {
    subject: initialSubject ?? '',
    body: initialBody ?? ''
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const FormValidation = Yup.object().shape({
    subject: Yup.string().required('* Required'),
    body: Yup.string().required('* Required')
  });

  const createFSInput = (values: FormSchema, form: DTO.Form): DTO.SubmitFormInput => {
    const getQuestionId = (qname: string) => {
      const q = getFormQuestion(qname, form);
      if (q == null) throw new Error(`No question named '${qname}' on the email form'`);
      return q.id;
    };

    return {
      formId: form.id,
      answers: [{ questionId: getQuestionId('summary'), text: values.subject }, { questionId: getQuestionId('details'), text: values.body }]
    };
  };

  const handleSubmit = (values: FormSchema, formikHelpers: FormikHelpers<FormSchema>) => {
    const emailRocType = rocTypes.data?.rocTypes?.find(type => type.name?.text === 'Email');

    if (emailRocType == null) throw new Error(`No 'EMAIL' record of contact type in the database`);

    setSubmitting(true);
    if (tab === 0) {
      const input: RecordOfContactInput = {
        date: new Date(),
        isPrivate: false,
        fromId: userCtx.user?.id ?? 0,
        toId: (isAdvisor ? studentCtx?.student.id : userCtx.user?.advisor?.id) ?? 0,
        rocTypeId: emailRocType.id,
        sendEmail: true,
        formSubmission: emailRocType.form != null ? createFSInput(values, emailRocType.form) : undefined
      };

      saveRoc({
        variables: {
          input: input
        },
        update: (cache, result) => {
          if (result.data?.saveRoc.id) {
            formikHelpers.setStatus({
              submitted: true,
              message: undefined
            });
          } else {
            formikHelpers.setStatus({
              submitted: false,
              message: 'Oops. Something went wrong'
            });
          }
        }
      }).then(() => {
        setSubmitting(false);
      }).catch(res => {
        const errors: string[] = res.graphQLErrors.map((error: ApolloError) => error.message);

        if (errors.length > 0) {
          formikHelpers.setStatus({
            submitted: false,
            message: errors[0]
          });
        }
        setSubmitting(false);
      });
    } else {
      sendSupportEmail({
        variables: {
          subject: values.subject,
          body: values.body
        },
        update: (cache, result) => {
          if (result.data?.sendSupportEmail === true) {
            formikHelpers.setStatus({
              submitted: true,
              message: undefined
            });
          } else {
            formikHelpers.setStatus({
              submitted: false,
              message: 'Oops. Something went wrong'
            });
          }
        }
      }).then(() => {
        setSubmitting(false);
      }).catch(res => {
        const errors: string[] = res.graphQLErrors.map((error: ApolloError) => error.message);

        if (errors.length > 0) {
          formikHelpers.setStatus({
            submitted: false,
            message: errors[0]
          });
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <Formik<FormSchema> initialValues={initalFormValues} initialStatus={initialStatus} onSubmit={handleSubmit} validationSchema={FormValidation}>
      {formikProps => {
        formik.current = formikProps;
        return (
          <Form>
            {(formikProps.status as FormStatus).message && (
              <Grid container>
                <Grid item xs>
                  <Alert severity="error">{formikProps.status.message}</Alert>
                </Grid>
              </Grid>
            )}

            {(formikProps.status as FormStatus).submitted && <SuccessIconMessage message="Email Sent" animated={true} />}

            {!(formikProps.status as FormStatus).submitted && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field validateOnBlur validateOnChange name="subject">
                    {() => (
                      <TextField
                        fullWidth
                        name="subject"
                        label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_SUBJECT" defaultText="Subject" />}
                        value={formikProps.values.subject}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        error={Boolean(formikProps.errors.subject && formikProps.touched.subject)}
                        helperText={formikProps.errors.subject && formikProps.touched.subject && String(formikProps.errors.subject)}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field validateOnBlur validateOnChange name="body">
                    {() => (
                      <TextField
                        fullWidth
                        name="body"
                        label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_BODY" defaultText="Body" />}
                        multiline={true}
                        rows={5}
                        value={formikProps.values.body}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        error={Boolean(formikProps.errors.body && formikProps.touched.body)}
                        helperText={formikProps.errors.body && formikProps.touched.body && String(formikProps.errors.body)}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                    <LanguageString groupName="GENERAL" resourceName="SEND_EMAIL" defaultText="Send Email" />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export const EmailDialog: React.FC<ForgotPasswordDialogProps> = ({ open, toggle, initialSubject, initialBody, sendToSupport }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const userCtx = useContext(UserContext);
  const isAdvisor = useMemo(() =>
    userCtx.user?.currentRole === DTO.Role.ADMIN ||
    userCtx.user?.currentRole === DTO.Role.ADVISOR ||
    userCtx.user?.currentRole === DTO.Role.SUPERADMIN ||
    userCtx.user?.currentRole === DTO.Role.EDITOR,
    [userCtx.user]
  );

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => setCurrentTab(newValue);

  const participantRender = (
    <Grid container spacing={2}>
      {!sendToSupport && (
        <Grid item xs={12}>
          <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Email Tabs" variant="fullWidth">
            <Tab label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_ADVISOR_TAB" defaultText="Email Advisor" />} />
            <Tab label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_SUPPORT_TAB" defaultText="Email Tech Support" />} />
          </Tabs>
        </Grid>
      )}

      {currentTab === 0 && (
        <Grid item xs={12}>
          <EmailForm isAdvisor={isAdvisor} tab={currentTab} initialSubject={initialSubject} initialBody={initialBody} />
        </Grid>
      )}
      {currentTab === 1 && (
        <Grid item xs={12}>
          <EmailForm isAdvisor={isAdvisor} tab={currentTab} initialSubject={initialSubject} initialBody={initialBody} />
        </Grid>
      )}
    </Grid>
  );

  const advisorRender = (
    <Grid container spacing={2}>
      {!sendToSupport && (
        <Grid item xs={12}>
          <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Email Tabs" variant="fullWidth">
            <Tab label="Email Participant" />
            <Tab label="Email Tech Support" />
          </Tabs>
        </Grid>
      )}

      {currentTab === 0 && (
        <Grid item xs={12}>
          <EmailForm isAdvisor={isAdvisor} tab={currentTab} initialSubject={initialSubject} initialBody={initialBody} />
        </Grid>
      )}
      {currentTab === 1 && (
        <Grid item xs={12}>
          <EmailForm isAdvisor={isAdvisor} tab={currentTab} initialSubject={initialSubject} initialBody={initialBody} />
        </Grid>
      )}
    </Grid>
  );

  return (
    <Dialog fullWidth open={open} onClose={toggle} aria-labelledby="Signup Form">
      <DialogTitle onClose={toggle}>
        <LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_TITLE" defaultText="Send an email" />
      </DialogTitle>
      <DialogContent dividers>
        {isAdvisor ? advisorRender : participantRender}
      </DialogContent>
    </Dialog>
  );
};

export default EmailDialog;
