import { useEffect, useState, useCallback } from 'react';

export enum LocalStorageKey {
  CaseLoadColumn = 'CaseLoadColumn',
  CaseLoadPagination = 'CaseLoadPagination',
  CaseLoadOrder = 'CaseLoadOrder',
  StudentsTableColumns = 'StudentsTableColumns',
  StudentsFilters = 'StudentsFilters',
  CaseLoadFilters = 'CaseLoadFilters',
  CaseLoadAdvisor = 'CaseLoadAdvisor',
  NotificationBlastFilters = 'NotificationBlastFilters',
  SearchAdvisors = 'SearchAdvisors',
  LiveEdit = 'LiveEdit',
  InlineHelp = 'InlineHelp',
  EmployerFilters = 'EmployerFilters'
}

export function useLocalStorage<T>(key: (keyof typeof LocalStorageKey), initialValue: T): [T, (newValue: T) => void] {
  // Initialize useState with the local storage value
  const newValue = window.localStorage.getItem(key);

  const [value, setValue] = useState(newValue ?? JSON.stringify(initialValue));

  // Setup the useState that will be a middle layer between the caller and local storage
  const setItem = (lnewValue: T) => {
    const json = JSON.stringify(lnewValue);
    setValue(json);
    window.localStorage.setItem(key, json);
    window.dispatchEvent(new StorageEvent('storage', {key, newValue: json}));
  };

  if (newValue) {
    if (value !== newValue) {
      setValue(newValue);
    }
  } else {
    const initialJSON = JSON.stringify(initialValue);
    if (value !== initialJSON) {
      setValue(initialJSON);
    }
  }

  // Update state with changes to local storage
  const handleStorage = useCallback(
    event => event.key === key && event.newValue !== value && setValue(event.newValue ? event.newValue : JSON.stringify(initialValue)),
    [value]
  );

  // Setup the handleStorage function to be called when storage changes occur
  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [JSON.parse(value) as T, setItem];
}

export default useLocalStorage;
