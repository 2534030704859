import { gql, QueryHookOptions } from '@apollo/client';
import { FormSubmission, IncludeSubmissionTypes, SubmissionQueryArgs } from '../../dto';
import { ReqVars, useMappedQuery } from './common';

type Variables = {
  includes?: IncludeSubmissionTypes;
} & {
  includes?: IncludeSubmissionTypes;
} & (
    | {
        formId: number;
        userId: number;
        assignmentId?: number;
      }
    | {
        formName: string;
        userId: number;
        assignmentId?: number;
      }
  );
type InnerVariables = { userId?: number; params: SubmissionQueryArgs };

type Results = { user?: { formSubmissions?: FormSubmission[] } };

export const getInnerVars = (vars: Variables): InnerVariables => ({
  userId: vars.userId ?? -1,
  params: {
    ...('formId' in vars
      ? { formIdArgs: { formId: vars.formId, assignmentId: vars.assignmentId } }
      : { formNameArgs: { formName: vars.formName ?? '', assignmentId: vars.assignmentId } }),
    include: vars.includes
  }
});

export const fsQuery = gql`
  query getSubmissions($userId: Int!, $params: SubmissionQueryArgs!) {
    user(id: $userId) {
      id
      formSubmissions(params: $params) {
        id
        percentComplete
        percentCorrect
        startedAt
        modifiedAt
        completedAt
        assignment {
          id
        }
        submittedBy {
          id
          username
          firstName
          lastName
        }
        form {
          id
          name
        }
        answers {
          id
          question {
            id
            name
          }
          answer
          correctAnswer
          translation
          originalLanguageId
          selections {
            id
          }
        }
      }
    }
  }
`;

export const useFormSubmissions = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useMappedQuery(fsQuery, options, getInnerVars);
