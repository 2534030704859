import React, { useContext } from 'react';
import {
  Tab, Tabs, Paper, Box, Divider, IconButton, Grid, Icon
} from '@material-ui/core';
import { ChildRoutes, useChildLinks, ComponentNotificationProvider, ComponentNotificationContext } from '../../contexts';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

const NotificationEditorInner: React.FC = () => {
  const notificationHandle = useContext(ComponentNotificationContext);
  const history = useHistory();
  const location = useLocation();
  const childLinks = useChildLinks();
  const match = useRouteMatch();

  if (match.isExact && childLinks && childLinks.length > 0) {
    history.push(childLinks[0].path);
  }

  const changedTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(newValue);
  };

  return (
    <Paper>
      <Grid container justify="space-between">
        <Grid item />
        <Grid item>
          <Tabs
            value={location.pathname}
            onChange={changedTab}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {childLinks?.map(link => (
              <Tab
                key={`notification_link_${link.id}`}
                value={link.path}
                label={link.title}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item>
          <IconButton onClick={() => notificationHandle.triggerListeners(location.pathname)}>
            <Icon>add_circle</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Box>
        <ChildRoutes />
      </Box>
    </Paper>
  );
};

export const NotificationsEditor: React.FC = () => {
  return (
    <ComponentNotificationProvider>
      <NotificationEditorInner />
    </ComponentNotificationProvider>
  );
};
export default NotificationsEditor;
