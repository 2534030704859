import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { SuccessPath } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { pathFrag } from './fragments/pathFrag';

type Variables = { progId: number };
type Results = {
  path: QueryType<SuccessPath>;
};

export function usePath(options?: QueryHookOptions<Results, Variables>) {
  const query = gql`
    ${pathFrag}

    query getpath($progId: Int!) {
      path(progId: $progId) {
        ...pathFrag
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
}
