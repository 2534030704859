import { Grid, Hidden, Icon, IconButton, List, ListItem, ListItemText, ListSubheader, Menu, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { addDays, addMonths, addWeeks, format, getDate, getMonth, getYear, setDay, setHours, setMinutes, subMonths, subWeeks } from 'date-fns';
import React, { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ChildLinks, MTSCalendarViewType, MTSContext, SiteContext, useLinks } from '../../contexts';
import { MTSPeriod, MTSPeriodGroup } from '../../dto';
import { useClasses } from '../../hooks';
import { LanguageString } from '../Common';
import MTSHelpers from '../../helpers/mtsHelpers';

const MTSToolBarArrowButtons: React.FC = props => {
  const classes = useClasses();
  const siteCtx = useContext(SiteContext);
  const mtsCtx = useContext(MTSContext);
  const lastMonth = subMonths(mtsCtx.date, 1);
  const nextMonth = addMonths(mtsCtx.date, 1);
  let perviousPeriod: MTSPeriod | undefined = undefined;
  let nextPeriod: MTSPeriod | undefined = undefined;
  let perviousPeriodGroup: MTSPeriodGroup | undefined = undefined;
  let nextPeriodGroup: MTSPeriodGroup | undefined = undefined;

  if (mtsCtx.allMtsPeriods.length > 0) {
    const index = mtsCtx.allMtsPeriods.findIndex(i => i.id === mtsCtx.mtsPeriod?.id);
    if (index !== undefined) {
      if (index > 0) {
        perviousPeriod = mtsCtx.allMtsPeriods[index - 1];
      }
      if (index < mtsCtx.allMtsPeriods.length - 1) {
        nextPeriod = mtsCtx.allMtsPeriods[index + 1];
      }
    }
  }

  if (siteCtx.site?.mtsPeriodGroups && mtsCtx.groupMtsPeriods.length > 0) {
    const index = siteCtx.site.mtsPeriodGroups.findIndex(g => g.id === mtsCtx.groupMtsPeriods[0].id);
    if (index !== undefined) {
      if (index > 0) {
        perviousPeriodGroup = siteCtx.site.mtsPeriodGroups[index - 1];
      }
      if (index < siteCtx.site.mtsPeriodGroups.length - 1) {
        nextPeriodGroup = siteCtx.site.mtsPeriodGroups[index + 1];
      }
    }
  }

  const customNavigationLinks = [];
  if (perviousPeriod) {
    customNavigationLinks.push({ tooltip: 'Previous', icon: 'keyboard_arrow_left', period: perviousPeriod.id });
  }
  if (nextPeriod) {
    customNavigationLinks.push({ tooltip: 'Next', icon: 'keyboard_arrow_right', period: nextPeriod.id });
  }

  const customGroupNavigationLinks = [];
  if (perviousPeriodGroup) {
    customGroupNavigationLinks.push({ tooltip: 'Previous', icon: 'keyboard_arrow_left', group: perviousPeriodGroup.id });
  }
  if (nextPeriodGroup) {
    customGroupNavigationLinks.push({ tooltip: 'Next', icon: 'keyboard_arrow_right', group: nextPeriodGroup.id });
  }

  const monthViewNavigationRoutes = useLinks({
    items: [
      { tooltip: 'Previous', icon: 'keyboard_arrow_left', m: getMonth(lastMonth) + 1, y: getYear(lastMonth) },
      { tooltip: 'Next', icon: 'keyboard_arrow_right', m: getMonth(nextMonth) + 1, y: getYear(nextMonth) }
    ],
    paramMap: { m: 'month', y: 'year' },
    routeName: 'mts-month'
  });

  const lastWeek = subWeeks(mtsCtx.date, 1);
  const nextWeek = addWeeks(mtsCtx.date, 1);

  const weekViewNavigationRoutes = useLinks({
    items: [
      { tooltip: 'Previous', icon: 'keyboard_arrow_left', m: getMonth(lastWeek) + 1, y: getYear(lastWeek), d: getDate(lastWeek) },
      { tooltip: 'Next', icon: 'keyboard_arrow_right', m: getMonth(nextWeek) + 1, y: getYear(nextWeek), d: getDate(nextWeek) }
    ],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-week'
  });

  const yearViewNavigationRoutes = useLinks({
    items: [
      { tooltip: 'Previous', icon: 'keyboard_arrow_left', y: getYear(mtsCtx.date) - 1 },
      { tooltip: 'Next', icon: 'keyboard_arrow_right', y: getYear(mtsCtx.date) + 1 }
    ],
    paramMap: { y: 'year' },
    routeName: 'mts-year'
  });

  const customViewNavigationRoutes = useLinks({
    items: customNavigationLinks,
    paramMap: { period: 'periodId' },
    routeName: 'mts-custom'
  });

  const customGroupedViewNavigationRoutes = useLinks({
    items: customGroupNavigationLinks,
    paramMap: { group: 'groupId' },
    routeName: 'mts-custom-grouped'
  });

  const customWeekViewNavigationRoutes = useLinks({
    items: [
      { tooltip: 'Previous', icon: 'keyboard_arrow_left', m: getMonth(lastWeek) + 1, y: getYear(lastWeek), d: getDate(lastWeek) },
      { tooltip: 'Next', icon: 'keyboard_arrow_right', m: getMonth(nextWeek) + 1, y: getYear(nextWeek), d: getDate(nextWeek) }
    ],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-custom-week'
  });

  let navigationLinks: ChildLinks<{ tooltip: string; icon: string; y?: number; m?: number; d?: number }>[] | undefined;

  switch (mtsCtx.viewType) {
    case MTSCalendarViewType.Year:
      navigationLinks = yearViewNavigationRoutes;
      break;
    case MTSCalendarViewType.Week:
      navigationLinks = weekViewNavigationRoutes;
      break;
    case MTSCalendarViewType.Month:
      navigationLinks = monthViewNavigationRoutes;
      break;
    case MTSCalendarViewType.Custom:
      navigationLinks = customViewNavigationRoutes;
      break;
    case MTSCalendarViewType.CustomGrouped:
      navigationLinks = customGroupedViewNavigationRoutes;
      break;
    case MTSCalendarViewType.CustomWeek:
      navigationLinks = customWeekViewNavigationRoutes;
      break;
    default:
      navigationLinks = monthViewNavigationRoutes;
      break;
  }

  return (
    <>
      {navigationLinks?.map(link => {
        return (
          <Tooltip key={`mts_toolbar_tooltip_${link.path}`} title={link.item.tooltip}>
            <RouterLink className={classes.buttonLink} to={link.path}>
              <IconButton>
                <Icon>{link.item.icon}</Icon>
              </IconButton>
            </RouterLink>
          </Tooltip>
        );
      })}
    </>
  );
};

const MTSToolBarTodayButton: React.FC = props => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);
  const today = new Date();
  const currentPeriod = MTSHelpers.getCurrentMTSPeriod(mtsCtx.allMtsPeriods);

  const monthViewTodayLink = useLinks({
    items: [{ m: getMonth(today) + 1, y: getYear(today) }],
    paramMap: { m: 'month', y: 'year' },
    routeName: 'mts-month'
  });

  const yearViewTodayLink = useLinks({
    items: [{ y: getYear(today) }],
    paramMap: { y: 'year' },
    routeName: 'mts-year'
  });

  const weekViewTodayLink = useLinks({
    items: [{ m: getMonth(today) + 1, y: getYear(today), d: getDate(today) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-week'
  });

  const customViewTodayLink = useLinks({
    items: [{ period: currentPeriod?.id ?? 1 }],
    paramMap: { period: 'periodId' },
    routeName: 'mts-custom'
  });

  const customGroupedViewTodayLink = useLinks({
    items: [{ group: currentPeriod?.group?.id ?? 1 }],
    paramMap: { group: 'groupId' },
    routeName: 'mts-custom-grouped'
  });

  const customWeekViewTodayLink = useLinks({
    items: [{ m: getMonth(today) + 1, y: getYear(today), d: getDate(today) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-custom-week'
  });

  let todayLink: ChildLinks<{ y?: number; m?: number; d?: number; period?: number; group?: number; }>[] | undefined;

  switch (mtsCtx.viewType) {
    case MTSCalendarViewType.Year:
      todayLink = yearViewTodayLink;
      break;
    case MTSCalendarViewType.Week:
      todayLink = weekViewTodayLink;
      break;
    case MTSCalendarViewType.Month:
      todayLink = monthViewTodayLink;
      break;
    case MTSCalendarViewType.Custom:
      todayLink = customViewTodayLink;
      break;
    case MTSCalendarViewType.CustomGrouped:
      todayLink = customGroupedViewTodayLink;
      break;
    case MTSCalendarViewType.CustomWeek:
      todayLink = customWeekViewTodayLink;
      break;
    default:
      todayLink = monthViewTodayLink;
  }

  if (todayLink && todayLink.length > 0) {
    return (
      <Tooltip title="Today">
        <RouterLink className={classes.buttonLink} to={todayLink[0].path}>
          <IconButton aria-label="today">
            <Icon>calendar_today</Icon>
          </IconButton>
        </RouterLink>
      </Tooltip>
    );
  }

  return null;
};

const MTSToolBarViewItem: React.FC<{ type: MTSCalendarViewType }> = props => {
  const mtsCtx = useContext(MTSContext);
  const currentPeriod = MTSHelpers.getCurrentMTSPeriod(mtsCtx.allMtsPeriods);

  const monthLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year' },
    routeName: 'mts-month'
  });

  const yearLinks = useLinks({
    items: [{ y: getYear(mtsCtx.date) }],
    paramMap: { y: 'year' },
    routeName: 'mts-year'
  });

  const weekLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date), d: getDate(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-week'
  });

  const customLinks = useLinks({
    items: [{ period: currentPeriod?.id ?? -1 }],
    paramMap: { period: 'periodId' },
    routeName: 'mts-custom'
  });

  const customWeekLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date), d: getDate(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-custom-week'
  });

  const customGroupedLinks = useLinks({
    items: [{ group: currentPeriod?.group?.id ?? -1 }],
    paramMap: { group: 'groupId' },
    routeName: 'mts-custom-grouped'
  });

  let viewLink: ChildLinks<{ y?: number; m?: number; d?: number; period?: number; group?: number }>[] | undefined = monthLinks;

  let typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_MONTH" defaultText="Month" />;
  if (props.type === MTSCalendarViewType.Week) {
    typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_WEEK" defaultText="Week" />;;
    viewLink = weekLinks;
  } else if (props.type === MTSCalendarViewType.Year) {
    typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_YEAR" defaultText="Year" />;;
    viewLink = yearLinks;
  } else if (props.type === MTSCalendarViewType.Custom) {
    typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_PERIOD" defaultText="Period" />;;
    viewLink = customLinks;
  } else if (props.type === MTSCalendarViewType.CustomWeek) {
    typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_PERIOD_WEEK" defaultText="Week" />;;
    viewLink = customWeekLinks;
  } else if (props.type === MTSCalendarViewType.CustomGrouped) {
    typeText = <LanguageString groupName="MTS" resourceName="VIEW_TYPE_PERIOD_GROUPED" defaultText="Grouped" />;;
    viewLink = customGroupedLinks;
  }

  if (viewLink && viewLink.length > 0) {
    return (
      <ListItem selected={mtsCtx.viewType === props.type} button component={RouterLink} to={viewLink[0].path}>
        <ListItemText>{typeText}</ListItemText>
        <IconButton edge="end" aria-controls="calendar-menu" aria-haspopup="true">
          <Icon>keyboard_arrow_right</Icon>
        </IconButton>
      </ListItem>
    );
  }

  return null;
};

export const MTSToolBar: React.FC = () => {
  const mtsCtx = useContext(MTSContext);
  const siteCtx = useContext(SiteContext);
  const viewButtonRef = useRef<HTMLButtonElement>(null);
  const [viewOpen, setViewOpen] = useState(false);
  const selectedDate = mtsCtx.date;
  const classes = useClasses();

  const monthLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year' },
    routeName: 'mts-month'
  });

  const yearLinks = useLinks({
    items: [{ y: getYear(mtsCtx.date) }],
    paramMap: { y: 'year' },
    routeName: 'mts-year'
  });

  const weekLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date), d: getDate(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-week'
  });

  const customLinks = useLinks({
    items: [{ period: -1 }],
    paramMap: { period: 'periodId' },
    routeName: 'mts-custom'
  });

  const customWeekLinks = useLinks({
    items: [{ m: getMonth(mtsCtx.date) + 1, y: getYear(mtsCtx.date), d: getDate(mtsCtx.date) }],
    paramMap: { m: 'month', y: 'year', d: 'date' },
    routeName: 'mts-custom-week'
  });

  const customGroupedLinks = useLinks({
    items: [{ group: -1 }],
    paramMap: { group: 'groupId' },
    routeName: 'mts-custom-grouped'
  });

  let startOfWeek = setDay(selectedDate, siteCtx?.site?.mtsWeekStartDay ?? 0);
  startOfWeek = setHours(startOfWeek, 0);
  startOfWeek = setMinutes(startOfWeek, 0);
  const endOfWeek = addDays(startOfWeek, 6);

  const toggleViewMenu = () => {
    setViewOpen(!viewOpen);
  };

  const menu = (
    <Grid container justify="flex-end">
      <Grid item>
        <Tooltip title="View">
          <IconButton ref={viewButtonRef} aria-label="view" onClick={toggleViewMenu}>
            <Icon>more_vert</Icon>
          </IconButton>
        </Tooltip>

        <Menu
          id="calendar-menu"
          anchorEl={viewButtonRef.current}
          keepMounted
          open={viewOpen}
          onClose={toggleViewMenu}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          {mtsCtx.allMtsPeriods.length > 0 ? (
            <List>
              <ListSubheader>
                <LanguageString groupName="GENERAL" resourceName="MTS_VIEW" defaultText="View" />
              </ListSubheader>

              {mtsCtx.allMtsPeriods.find(p => p.group != null) != null && customGroupedLinks != null && (
                <MTSToolBarViewItem type={MTSCalendarViewType.CustomGrouped} />
              )}
              {customLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.Custom} />}
              {customWeekLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.CustomWeek} />}
              {yearLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.Year} />}
            </List>
          ) : (
              <List>
                <ListSubheader>
                  <LanguageString groupName="GENERAL" resourceName="MTS_VIEW" defaultText="View" />
                </ListSubheader>

                {monthLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.Month} />}
                {weekLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.Week} />}
                {yearLinks != null && <MTSToolBarViewItem type={MTSCalendarViewType.Year} />}
              </List>
            )}
        </Menu>
      </Grid>
    </Grid>
  );

  return (
    <Toolbar className={classes.mtsCalendarHeader}>
      <Grid container spacing={3}>
        <Grid item xs={8} md={4}>
          <MTSToolBarArrowButtons />
          <MTSToolBarTodayButton />
        </Grid>

        <Hidden only={['md', 'lg', 'xl']}>
          <Grid item xs={4}>
            {menu}
          </Grid>
        </Hidden>

        <Grid item xs={12} md={4}>
          <Typography variant="h5" align="center" id="tableTitle">
            {mtsCtx.viewType === MTSCalendarViewType.Month && <>{format(selectedDate, 'LLL yyyy')}</>}
            {mtsCtx.viewType === MTSCalendarViewType.Year && <>{format(selectedDate, 'yyyy')}</>}
            {(mtsCtx.viewType === MTSCalendarViewType.Week || mtsCtx.viewType === MTSCalendarViewType.CustomWeek) && (
              <>
                {format(startOfWeek, 'MM/dd/yyyy')} - {format(endOfWeek, 'MM/dd/yyyy')}
              </>
            )}
            {mtsCtx.viewType === MTSCalendarViewType.Custom && mtsCtx.mtsPeriod && (
              <>{format(MTSHelpers.getPeriodDates(mtsCtx.mtsPeriod).startDate, 'MM/dd/yyyy')}&nbsp;-&nbsp;
              {format(MTSHelpers.getPeriodDates(mtsCtx.mtsPeriod).endDate, 'MM/dd/yyyy')}</>
            )}
            {mtsCtx.viewType === MTSCalendarViewType.CustomGrouped && mtsCtx.mtsPeriods && (
              <>{format(MTSHelpers.getPeriodDates(mtsCtx.mtsPeriods[0]).startDate, 'MM/dd/yyyy')}&nbsp;-&nbsp;
              {format(MTSHelpers.getPeriodDates(mtsCtx.mtsPeriods[mtsCtx.mtsPeriods.length - 1]).endDate, 'MM/dd/yyyy')}</>
            )}
          </Typography>
        </Grid>

        <Hidden only={['xs', 'sm']}>
          <Grid item xs={4}>
            {menu}
          </Grid>
        </Hidden>
      </Grid>
    </Toolbar>
  );
};
