import { Card, CardHeader, Divider, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Callout, IconButton, LanguageString, moveSortable } from '..';
import * as Edit from '../../common/pathEditTypes';
import { PathEditorContext } from './PathEditor';

const isPath = (item: Edit.MilestoneGroup | Edit.SuccessPath): item is Edit.SuccessPath => (item as Edit.SuccessPath).__type === 'SuccessPath';

const SubGroupListInner: React.FC<FieldArrayRenderProps> = ({ name, push, remove }) => {
  const { values } = useFormikContext<Edit.MilestoneGroup | Edit.SuccessPath>();
  const [{ value: subGroups }] = useField<Edit.MilestoneGroup[]>(name);
  const { cacheContext: cc, refresh } = useContext(PathEditorContext);
  const parentType = isPath(values) ? 'paths' : 'msGroups';

  const handleDragEnd = (result: DropResult) => {
    if (result.reason === 'DROP') {
      if (subGroups != null && result.destination?.index != null) {
        moveSortable(subGroups, result.source.index, result.destination.index);
        refresh();
      }
    }
  };

  return subGroups ? (
    <Card>
      <CardHeader
        title={<LanguageString groupName="EDITOR" resourceName="SUBGROUPS" defaultText="Subgroups" />}
        action={
          <Callout name="edit-grp" body={<LanguageString groupName="HELP" resourceName="ADD_GRP" />} step={2} placement="right">
            <IconButton icon="add_circle" onClick={() => push(Edit.createNewGroup({ cc, pId: values.id, parentType }))} />
          </Callout>
        }
      />
      <Divider />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="subgroup-list">
          {dpProvided => (
            <List dense ref={dpProvided.innerRef}>
              {subGroups.map((sg, index) => (
                <Draggable key={String(sg.id)} draggableId={`sg-list-${sg.id}`} index={index}>
                  {dgProvided => (
                    <ListItem {...dgProvided.draggableProps} ref={dgProvided.innerRef}>
                      <ListItemIcon>
                        <Icon>account_tree</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={<LanguageString languageString={sg.name} />}
                        secondary={<LanguageString languageString={sg.description} />}
                      />
                      {sg.id < 0 && <IconButton icon="delete" onClick={() => remove(subGroups.indexOf(sg))} />}
                      {index === 0 ? (
                        <Callout name="srt-grps" body={<LanguageString groupName="HELP" resourceName="SORT_SUBGROUPS" />} placement="right" dependsOn={['edit-grp']}>
                          <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
                        </Callout>
                      ) : (
                        <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
                      )}
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {dpProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  ) : null;
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
export const SubGroupList = SubGroupListInner as React.FC<FieldArrayRenderProps | void>;
