import React, { useContext, Fragment } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  Icon,
  Slide,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Checkbox
} from '@material-ui/core';
import { Roadmap, RoadmapMilestone, RoadmapMilestoneState, TaskActionType } from '../../dto';
import { format } from 'date-fns';
import { DynamicForm } from '../DynamicForm';
import { SiteContext, StudentContext, UserContext, ChildRoutes, useChildLinks } from '../../contexts';
import { TransitionProps } from '@material-ui/core/transitions';
import { useDialogController, useClasses, useRoadmapById } from '../../hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LanguageString, GeneralErrorMessage, RouterLink, DownloadTemplate } from '../Common';
import { RoadmapHelper } from '../../helpers/roadmapHelpers';
import { PrintLocation } from 'tuapath-common/generated/schema';

const DialogTransition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RoadmapMilestoneContent: React.FC<{ roadmapMilestone: RoadmapMilestone }> = ({ roadmapMilestone }) => {
  const studentCtx = useContext(StudentContext);
  const path = studentCtx?.path;

  if (path) {
    const ms = RoadmapHelper.milestoneForRoadmapMilestone(roadmapMilestone, path);

    if (ms && ms.tasks && ms.tasks.length > 0 && studentCtx?.student) {
      return (
        <>
          {ms.tasks.map(task => {
            if (task.actionType === TaskActionType.webForm && task.action && task.action !== '') {
              const assignment = RoadmapHelper.getAssignment(studentCtx.student, ms, task);

              if (assignment) {
                return (
                  <div key={`task_content_${task.id}`} className="form-wrapper">
                    <DynamicForm
                      formId={Number(task.action)}
                      userId={studentCtx?.student.id ?? 0}
                      assignmentId={assignment?.id}
                      disabled={true}
                      disableCache={true}
                      autoloadFormSubmission={true}
                      showSubmitButton={false}
                      autoSave={false}
                    />
                  </div>
                );
              }
            }

            return null;
          })}
        </>
      );
    }
  }

  return null;
};

const RoadmapDataSection: React.FC<{ roadmap: Roadmap }> = ({ roadmap }) => {
  const classes = useClasses();
  const studentCtx = useContext(StudentContext);
  const path = studentCtx?.path;

  const milestones: RoadmapMilestone[] = [];
  if (roadmap.milestoneGroups) {
    for (const group of roadmap.milestoneGroups) {
      if (group.milestones) {
        for (const ms of group.milestones) {
          let hasMs = false;
          for (const existingMs of milestones) {
            if (existingMs.id === ms.id) {
              hasMs = true;
            }
          }

          if (!hasMs) {
            milestones.push(ms);
          }
        }
      }
    }
  }

  const msLinks = useChildLinks({
    items: milestones,
    paramMap: { milestoneId: 'msId' },
    routeName: 'print-roadmap-ms'
  });

  const lifeAreaForMilestone = (roadmapMilestone: RoadmapMilestone) => {
    let name = '';

    if (path) {
      const milestone = RoadmapHelper.milestoneForRoadmapMilestone(roadmapMilestone, path);
      if (milestone) {
        const group = RoadmapHelper.milestoneGroupForMilestone(milestone, path);
        if (group) {
          const parentGroup = RoadmapHelper.parentMilestoneGroup(group, path);
          if (parentGroup) {
            const rootGroup = RoadmapHelper.parentMilestoneGroup(parentGroup, path);
            if (rootGroup && rootGroup.name && rootGroup.name.text) {
              name = rootGroup.name.text;
            }
          }
        }
      }
    }

    return (
      <>
        <Typography variant="body1">
          <LanguageString groupName="ROADMAP" resourceName="LIFE_AREA" defaultText="Life Area" />
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          {name}
        </Typography>
      </>
    );
  };

  const pathwayForMilestone = (roadmapMilestone: RoadmapMilestone) => {
    let name = '';

    if (path) {
      const milestone = RoadmapHelper.milestoneForRoadmapMilestone(roadmapMilestone, path);
      if (milestone) {
        const group = RoadmapHelper.milestoneGroupForMilestone(milestone, path);
        if (group) {
          const parentGroup = RoadmapHelper.parentMilestoneGroup(group, path);
          if (parentGroup && parentGroup.name && parentGroup.name.text) {
            name = parentGroup.name.text;
          }
        }
      }
    }

    return (
      <>
        <Typography variant="body1">
          <LanguageString groupName="ROADMAP" resourceName="ROADMAP_PATHWAY" defaultText="Pathway" />
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          {name}
        </Typography>
      </>
    );
  };

  const goalForMilestone = (roadmapMilestone: RoadmapMilestone) => {
    let goal = '';

    if (path) {
      const group = RoadmapHelper.roadmapMilestoneGroupForMilestone(roadmapMilestone, roadmap);
      if (group && group.name && group.name.text) {
        goal = group.name.text;
      }
    }

    return (
      <>
        <Typography variant="body1">
          <LanguageString groupName="ROADMAP" resourceName="ROADMAP_GOAL" defaultText="Goal" />
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          {goal}
        </Typography>
      </>
    );
  };

  const nameForMilestone = (milestone: RoadmapMilestone) => {
    if (milestone.tasks && milestone.tasks.length > 0 && milestone.tasks[0].name) {
      return milestone.tasks[0].name.text;
    }

    return null;
  };

  const linkForMilestone = (milestone: RoadmapMilestone) => {
    if (msLinks && msLinks.length > 0) {
      for (const link of msLinks) {
        if (link.path.includes(String(milestone.milestoneId))) {
          return link.path;
        }
      }
    }

    return undefined;
  };

  const iconForMilestone = (milestone: RoadmapMilestone) => {
    switch (milestone.status) {
      case RoadmapMilestoneState.COMPLETED:
        return <Icon className={classes.success}>thumb_up</Icon>;
      case RoadmapMilestoneState.CONTINUE:
        return <Icon className={classes.warning}>play_arrow</Icon>;
      case RoadmapMilestoneState.DEFERRED:
        return <Icon color="error">stop</Icon>;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      {milestones.map(ms => (
        <Fragment key={`ms_line_${ms.id}`}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={3}>
                {lifeAreaForMilestone(ms)}
                {pathwayForMilestone(ms)}
                {goalForMilestone(ms)}
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container justify="space-between">
                  <Grid item>
                    <RouterLink to={linkForMilestone(ms) ?? ''}>
                      <Typography variant="h6">{nameForMilestone(ms)}</Typography>
                    </RouterLink>
                  </Grid>
                  <Grid item>
                    {iconForMilestone(ms)}
                  </Grid>
                </Grid>

                <RoadmapMilestoneContent roadmapMilestone={ms} />
              </Grid>
              {ms.deferredNotes && (
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.bold}>
                    <LanguageString groupName="ROADMAP" resourceName="DEFERRED_MILESTONE_REASON" defaultText="Reason Deferred" />
                  </Typography>
                  <Typography variant="body1">{ms.deferredNotes}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

const RoadmapPrintHeader: React.FC<{ roadmap: Roadmap }> = ({ roadmap }) => {
  const siteCtx = useContext(SiteContext);

  if (roadmap.reviewedAt) {
    // Competed roadmap header
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">
            <LanguageString groupName="ROADMAP" resourceName="ROADMAP_REVIEW_DATE" defaultText="Roadmap Review Date" />
          </Typography>
          <Typography variant="body1">
            {format(new Date(roadmap.reviewedAt), 'MM/dd/yy')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify="center">
            <Typography variant="h5">
              {roadmap.reviewedAt ? (
                <LanguageString groupName="ROADMAP" resourceName="COMPLETED_ROADMAP" defaultText="Completed Roadmap" />
              ) : (
                <LanguageString groupName="ROADMAP" resourceName="CURRENT_ROADMAP" defaultText="Current Roadmap" />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify="flex-end">
            <img className="logo" alt="logo" height="80" src={siteCtx.site?.branding?.logoUrl ?? ''} />
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    // Current roadmap header
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_START_DATE" defaultText="Roadmap Start Date" />
              </Typography>
              {roadmap.plannedStartDate && (
                <Typography variant="body1">
                  {format(new Date(roadmap.plannedStartDate), 'MM/dd/yy')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_MEETING_DATE" defaultText="Roadmap Meeting Date" />
              </Typography>
              {roadmap.meeting?.date && (
                <Typography variant="body1">
                  {format(new Date(roadmap.meeting.date), 'MM/dd/yy')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify="center">
            <Typography variant="h5">
              {roadmap.reviewedAt ? (
                <LanguageString groupName="ROADMAP" resourceName="COMPLETED_ROADMAP" defaultText="Completed Roadmap" />
              ) : (
                <LanguageString groupName="ROADMAP" resourceName="CURRENT_ROADMAP" defaultText="Current Roadmap" />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container justify="flex-end">
            <img className="logo" alt="logo" height="80" src={siteCtx.site?.branding?.logoUrl ?? ''} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const RoadmapPrintInner: React.FC<{ roadmap: Roadmap }> = ({ roadmap }) => {
  const classes = useClasses();
  const siteCtx = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);

  let coachSignature = '';
  if (userCtx.user?.id && studentCtx?.student.id && studentCtx.student.advisor && userCtx.user.id === studentCtx.student.id) {
    coachSignature = `${studentCtx.student.advisor.firstName} ${studentCtx.student.advisor.lastName}`;
  } else if (userCtx.user) {
    coachSignature = `${userCtx.user.firstName} ${userCtx.user.lastName}`;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RoadmapPrintHeader roadmap={roadmap} />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <DynamicForm
            formId={siteCtx.site?.roadmapPrintHeaderForm?.id ?? 0}
            isReadOnly={true}
            autoloadFormSubmission={true}
            userId={studentCtx?.student.id ?? 0}
            showSubmitButton={false}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" className={classes.bold}>
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_DESTINATION_TITLE" defaultText="My destination. Where I am heading." />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {roadmap.goal ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <RoadmapDataSection roadmap={roadmap} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" className={classes.bold}>
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_NOTES" defaultText="Roadmap Notes" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {roadmap.reviewNotes ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" className={classes.bold}>
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_COMMITMENTS" defaultText="Coach Notes / Commitments" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {roadmap.advisorReviewNotes ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="ROADMAP" resourceName="ROADMAP_COMMUNICATION" defaultText="Ongoing Communication" />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <LanguageString
                          groupName="ROADMAP"
                          resourceName="ROADMAP_COMMUNICATION_VALUE"
                          defaultText="I will use the online system to view my path, goal and action steps.
                                    I can view or download supporting information and resources. I will enter my progress on each action step as I complete it."
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="ROADMAP" resourceName="ROADMAP_COUNTY_COMMITMENTS" defaultText="County Commitments" />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <LanguageString
                          groupName="ROADMAP"
                          resourceName="ROADMAP_COUNTY_COMMITMENTS_VALUE"
                          defaultText="My coach will continue to provide ongoing support to help me move toward my goals.
                          My coach wil provide referrals and resources as needed."
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <LanguageString
                  groupName="ROADMAP"
                  resourceName="ROADMAP_PRINT_AGREEMENT"
                  defaultText="I agree to this plan and will participate in what it says. Because it is my plan, I can change it,
              but I need to let my coach know ahead of time, and will contact the County within (48) forty-eight hours of any changes,
              problems, or concerns. I understand that this program is time-limited with a life time limit of (60) sixty months.
              I understand that if I don't hold up my end of the plan, I may risk the consequence outlined in the conditions.
              I have had the change to ask questions, and understand and agree with my plan, above."
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <LanguageString
                  groupName="ROADMAP"
                  resourceName="ROADMAP_PRINT_AGREEMENT_TWO"
                  defaultText="By checking this box and signing below, I am stating that I do not agree with my plan as outline above.
                  I am requesting a County Dispute Resolution to resolve the disagreement." />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox checked={true} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="ROADMAP" resourceName="ROADMAP_PARTICIPANT_SIGNATURE" defaultText="Participant Signature" />
                      </Typography>
                      <Typography variant="body1">
                        {studentCtx?.student && (
                          `${studentCtx.student.firstName} ${studentCtx.student.lastName}`
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="ROADMAP" resourceName="ROADMAP_COACH_SIGNATURE" defaultText="Coach Signature" />
                      </Typography>
                      <Typography variant="body1">
                        {coachSignature}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="GENERAL" resourceName="DATE" defaultText="Date" />
                      </Typography>
                      <Typography variant="body1">
                        {roadmap.reviewedAt ? format(new Date(roadmap.reviewedAt), 'MM/dd/yy') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" className={classes.bold}>
                        <LanguageString groupName="GENERAL" resourceName="DATE" defaultText="Date" />
                      </Typography>
                      <Typography variant="body1">
                        {roadmap.reviewedAt ? format(new Date(roadmap.reviewedAt), 'MM/dd/yy') : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="body1" className={classes.bold}>
                <LanguageString groupName="ROADMAP" resourceName="ROADMAP_MORE_INFO" defaultText="" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
};

export const RoadmapPrint: React.FC = () => {
  const studentCtx = useContext(StudentContext);
  const history = useHistory();
  const classes = useClasses();
  const handleClose = () => history.goBack();
  const { props: dProps } = useDialogController(true, handleClose);
  const match = useRouteMatch<{ id?: string; }>();
  const roadmap = useRoadmapById({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      roadmapId: match.params.id ? Number(match.params.id) : 0
    }
  });

  let contentArea = (<></>);
  if (roadmap.data?.user?.roadmapById) {
    contentArea = (
      <RoadmapPrintInner roadmap={roadmap.data.user.roadmapById} />
    );
  } else if (roadmap.loading) {
    contentArea = (
      <CircularProgress color="primary" />
    );
  } else if (roadmap.error) {
    contentArea = (
      <GeneralErrorMessage message="Invalid Roadmap" />
    );
  }

  return (
    <Dialog
      fullScreen
      scroll="paper"
      TransitionComponent={DialogTransition}
      transitionDuration={0}
      {...dProps}
    >
      <DialogContent dividers>
        <AppBar position="fixed">
          <Toolbar>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  <LanguageString groupName="ROADMAP" resourceName="PRINT_ROADMAP" defaultText="Print Roadmap" />
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={5}>
                  <Grid item>
                    <DownloadTemplate
                      location={roadmap.data?.user?.roadmapById?.reviewedAt ? PrintLocation.RoadmapComplete : PrintLocation.Roadmap}
                      relationId={match.params.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.fullscreenModalContainer} spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {contentArea}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <ChildRoutes />
      </DialogContent>
    </Dialog>
  );
};
