import gql from 'graphql-tag';

export const languageFrag = gql`
  fragment LangFrag on Language {
    id
    name
    code
  }
`;

export default languageFrag;
