import { gql, QueryHookOptions } from '@apollo/client';
import { Milestone } from '../../dto';
import { ReqVars, useMappedQuery } from './common';
import { languageStringFrag } from './fragments';

type Variables = { milestoneId: number };
type Results = { milestone?: Pick<Milestone, 'completedEmail'> };
const query = gql`
  ${languageStringFrag}
  query milestone($milestoneId: Int!, $withMilestone: Boolean!) {
    milestone(id: $milestoneId) @include(if: $withMilestone) {
      id
      completedEmail {
        id
        subject {
          ...LangStringFrag
        }
        body {
          ...LangStringFrag
        }
        additionalRecipients
      }
    }
  }
`;

export const useMsCompletedEmail = (options: ReqVars<QueryHookOptions<Results, Variables>>) =>
  useMappedQuery(query, options, vars => ({ ...vars, withMilestone: vars.milestoneId != null }));
