import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Snackbar,
  Slide,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { CancelRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { SiteStatusContext, UserContext, useInsights, StatusError } from '../../contexts';
import { useClasses } from '../../hooks';
import { ErrorResponse } from '@apollo/client/link/error';
import { Role } from '../../dto';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

function TransitionUp(props: TransitionProps & { children?: React.ReactElement }) {
  return <Slide {...props} direction="up" />;
}

interface IErrorDialogContent {
  status: string;
  title: string;
  message: string;
}

enum DialogStatus {
  Success = 'Success',
  Error = 'Error'
}

const isStatusError = (err: unknown): err is StatusError => err != null && typeof err === 'object' && 'dismissal' in err;

export const AppErrors: React.FC = () => {
  const insights = useInsights();
  const classes = useClasses();
  const userCtx = useContext(UserContext);
  const siteStatCtx = useContext(SiteStatusContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState<string | undefined>();
  const dismissal = useRef<'auto' | 'manual'>();
  const closeSnackbar = (force?: boolean) => {
    if (dismissal.current !== 'manual' || force === true) setSnackBarStatus(undefined);
  };

  const [errorDialogContent, setErrorDialogContent] = useState<IErrorDialogContent[]>([]);
  const toggleDialog = () => {
    if (dialogOpen) {
      setErrorDialogContent([]);
    }
    setDialogOpen(!dialogOpen);
  };

  useEffect(() => {
    if (!userCtx.user) return;

    if (siteStatCtx.status) {
      let statusMessage: string | undefined;
      let severityLevel = SeverityLevel.Critical;
      let allUsers = false;

      if (siteStatCtx.status instanceof Error) {
        statusMessage = siteStatCtx.status.message;
        severityLevel = SeverityLevel.Error;
        const err = siteStatCtx.status;
        if (isStatusError(err)) {
          dismissal.current = err.dismissal === 'manual' ? 'manual' : 'auto';
          allUsers = err.allUsers === true;
        }

        setErrorDialogContent([{ status: DialogStatus.Error, title: 'General', message: siteStatCtx.status.message }]);
      } else if (typeof siteStatCtx.status === 'object' && siteStatCtx.status !== null && 'title' in siteStatCtx.status && 'message' in siteStatCtx.status) {
        const { title, message } = siteStatCtx.status;
        statusMessage = message;
        severityLevel = SeverityLevel.Information;
        dismissal.current = siteStatCtx.status.dismissal === 'manual' ? 'manual' : 'auto';

        setErrorDialogContent([{ status: DialogStatus.Success, title, message }]);
      } else {
        const dialogContent: IErrorDialogContent[] = [];

        const err = siteStatCtx.status as ErrorResponse;
        if (err.graphQLErrors) {
          for (const error of err.graphQLErrors) {
            if (error.extensions != null && isStatusError(error.extensions.exception)) {
              dismissal.current = error.extensions.exception.dismissal;
              allUsers = error.extensions.exception.allUsers === true;
            }

            if (error.extensions && 'code' in error.extensions) {
              if (error.message) {
                statusMessage = error.message;

                dialogContent.push({ status: DialogStatus.Error, title: 'Error', message: error.message });
              } else if (error.extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
                statusMessage = 'Oops, there\'s a GQL validation error. Support has been notified.';

                dialogContent.push({ status: DialogStatus.Error, title: 'Error', message: statusMessage });
              } else if (error.extensions.code === 'UNAUTHENTICATED' && userCtx.user) {
                // Do not show auth error because the user obj already exists
                siteStatCtx.setStatus(undefined);
                return;
              }
            }
          }
        }

        if (!statusMessage) {
          statusMessage = 'Oops, a network error has occured. Support has been notified.';
          dialogContent.push({ status: DialogStatus.Error,  title: 'Error', message: 'Oops, a network error has occured. Support has been notified.' });
        }

        setErrorDialogContent(dialogContent);
      }

      insights.trackException(statusMessage, severityLevel);
      if (userCtx.user?.roles?.includes(Role.ADMIN) === true || allUsers) setSnackBarStatus(statusMessage);
      siteStatCtx.setStatus(undefined);
    }
  }, [siteStatCtx.status, userCtx.user]);

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={toggleDialog} aria-labelledby="Error Dialog">
        <DialogTitle>Statuses</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Message Status</TableCell>
                <TableCell>Message Category</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errorDialogContent.map(item => (
                <TableRow key={`app_error_${item.title}`}>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarStatus != null}
        TransitionComponent={TransitionUp}
        onClose={() => closeSnackbar()}
        key={`snackbar_error`}
        autoHideDuration={dismissal.current === 'manual' ? undefined : 7000}
      >
        <Alert
          severity={errorDialogContent.some(item => item.status === DialogStatus.Success) ? 'success' : 'error'}
          action={
            <IconButton onClick={() => closeSnackbar(true)}><CancelRounded /></IconButton>
          }
        >
          <span className={classes.clickable} onClick={toggleDialog}>
            {snackBarStatus}
          </span>
        </Alert>
      </Snackbar>
    </>
  );
};
export default AppErrors;
