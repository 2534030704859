import { createStyles, DialogTitle as MuiDialogTitle, Icon, IconButton, makeStyles, Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

// TODO: Move the styles to the db
const useDlgStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1)
    }
  })
);

type Props = {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: TypographyProps['variant'];
};

export const DialogTitle: React.FC<Props> = ({ onClose, children }) => {
  const classes = useDlgStyles();

  return (
    <MuiDialogTitle className={classes.root} disableTypography>
      <Typography variant="h6">
        {children}
      </Typography>
      <IconButton className={classes.closeButton} edge="start" color="inherit" onClick={onClose} aria-label="close">
        <Icon>close</Icon>
      </IconButton>
    </MuiDialogTitle>
  );
};
