import { Backdrop, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Icon, List, ListItem, ListItemText, MenuItem } from '@material-ui/core';
import { Formik, useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import * as yup from 'yup';
import { Checkbox, IconButton, LanguageString, moveSortable, TextField } from '..';
import { makeStyles } from '../../common';
import { useDialogController, useForms, useSiteMTSActivityTypes, useUpdateMTSActivityType } from '../../hooks';
import useUpdateMATOrder from '../../hooks/gql/useUpdateMATOrder';
import { MessageDialog, LanguageStringEditor } from '../Common';
import * as DTO from './MATDtoTypes';
import { LanguageString as LanguageStringDTO } from '../../dto';
import * as Edit from './MATEditTypes';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.spacing(2)}px`
  },
  [theme.breakpoints.up('md')]: {
    container: {
      gridTemplateColumns: 'minmax(360px, 1fr) 3fr'
    }
  }
}));

type MATListProps = {
  mats: Edit.MTSActivityType[];
  onClick: (mat: Edit.MTSActivityType) => void;
  selectedMat?: Edit.MTSActivityType;
  refresh: () => void;
};
const MATList: React.FC<MATListProps> = ({ mats, onClick, selectedMat, refresh }) => {
  const handleDragEnd = (result: DropResult) => {
    if (result.reason === 'DROP') {
      if (mats != null && result.destination?.index != null) {
        moveSortable(mats, result.source.index, result.destination.index);
        refresh();
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="milestone-list">
        {dpProvided => (
          <List style={{ maxHeight: '1024px', overflowY: 'auto' }} ref={dpProvided.innerRef}>
            {mats.map((mat, index) => (
              <Draggable key={String(mat.id)} draggableId={`sg-list-${mat.id}`} index={index}>
                {dgProvided => (
                  <ListItem button onClick={() => onClick(mat)} selected={mat.id === selectedMat?.id} {...dgProvided.draggableProps} ref={dgProvided.innerRef}>
                    <ListItemText
                      primary={
                        <LanguageString languageString={mat.name} />
                      }
                    />
                    <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
                  </ListItem>
                )}
              </Draggable>
            ))}
            {dpProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
 };

const schema = yup.object().shape({
  id: yup.number(),
  attachmentFormEnabled: yup.boolean(),
  code: yup.string().max(10).required(),
  description: yup.string(),
  enabled: yup.boolean(),
  hidden: yup.boolean(),
  formId: yup.number(),
  nameId: yup.number(),
  noteRequired: yup.boolean(),
  secondaryFormId: yup.number().when('childGenerateTypesEnabled', {
    is: true,
    then: yup.number().required()
  }),
  wpr: yup.boolean(),
  canEditEntryData: yup.boolean(),
  canEditHours: yup.boolean(),
  childGeneratedTypesEnabled: yup.boolean(),
  filterCustomPropertyValue: yup.string(),
  defaultHours: yup.number().notRequired().nullable(true),
  maxDailyHours: yup.number().notRequired().nullable(true),
  maxEntryHours: yup.number().notRequired().nullable(true),
  maxNumberOfEntries: yup.number().notRequired().nullable(true)
});

const Name = () => {
  const [resourceName, setResouceName] = useState('');
  const [{ value }] = useField('name');
  const groupName = useMemo(() => {
    return (value as LanguageStringDTO)?.group?.groupName ?? 'MTSACTIVITYTYPE';
  }, []);

  console.log(`form value: `, value);

  useEffect(() => {
    const rsName = (value as LanguageStringDTO)?.group?.resourceName;
    setResouceName(rsName ?? (Math.random() + 1).toString(36).substring(7));
  }, [value]);

  return (
    <LanguageStringEditor
      name="name"
      title="Name"
      groupName={groupName}
      resourceName={resourceName}
      shouldWarnOnDuplicateString={false}
    />
  );
};

type MATDetailProps = { selectedMAT: Edit.MTSActivityType, saveMAT: (mat: Edit.MTSActivityType) => void };
const MATDetail: React.FC<MATDetailProps> = ({ selectedMAT, saveMAT }) => {
  const forms = useForms().data?.site.forms;
  const handleSubmit = async (values: Edit.MTSActivityType) => saveMAT(values);

  const formOpts = forms?.map(f => <MenuItem key={f.id} value={f.id}> {f.name} </MenuItem>);

  return forms ? (
    <Formik initialValues={selectedMAT} onSubmit={handleSubmit} enableReinitialize validationSchema={schema}>
      {formik => (
        <Card>
          <CardHeader title={selectedMAT.description} action={<IconButton icon="save" onClick={formik.submitForm} disabled={!formik.dirty} />} />
          <Divider />
          <CardContent>
            <Box style={{ display: 'grid', gridColumn: '1fr', gap: '16px', alignContent: 'start' }}>
              {/* <TextField name="name" label="Name" /> */}
              <Name />
              <TextField name="description" label="Description" />
              <TextField name="code" label="Primary Code" />
              <TextField name="secondaryCode" label="Secondary Code" />
              <TextField name="tertiaryCode" label="Tertiary Code" />
              <Checkbox name="noteRequired" label="Note Required" />
              <Checkbox name="attachmentFormEnabled" label="Attachment Form Enabled" />
              <Checkbox name="childGenerateTypesEnabled" label="Open/Close Activity Type" />
              <Checkbox name="appearsInReviewAllHours" label="Show in 'Review All Hours'" />
              <Checkbox name="mtsInRoadmapEnabled" label="Show in 'Roadmap'" />
              <TextField name="formId" label={formik.values.childGenerateTypesEnabled ? 'Open Form' : 'Form'} select>
                <MenuItem value="">None</MenuItem>
                {formOpts}
              </TextField>
              {formik.values.childGenerateTypesEnabled === true &&
                <TextField name="secondaryFormId" label="Close Form" select>
                  <MenuItem value={undefined}>None</MenuItem>
                  {forms.map(f => <MenuItem key={f.id} value={f.id}> {f.name} </MenuItem>)}
                </TextField>
              }
              <Checkbox name="wpr" label="WPR" />
              <Checkbox name="canEditEntryData" label="Can Edit Entry Data" />
              <Checkbox name="canEditHours" label="Can Edit Hours" />
              <Checkbox name="hidden" label="Hidden" />
              <TextField name="filterCustomPropertyValue" label="Filter Custom Property Value" />
              <TextField name="defaultHours" label="Default Hours" />
              <TextField name="maxDailyHours" label="Daily Max Hours" />
              <TextField name="maxEntryHours" label="Max Hours Per Entry" />
              <TextField name="maxNumberOfEntries" label="Daily Max Number of Entries" />

              <TextField name="reqExternalApprovalFormId" label="Request Approval Form" select>
                <MenuItem value="">None</MenuItem>
                {formOpts}
              </TextField>
              <TextField name="externalApprovalFormId" label="External Approval Form" select>
                <MenuItem value="">None</MenuItem>
                {formOpts}
              </TextField>
            </Box>
          </CardContent>
        </Card>
      )}
    </Formik>
  ) : <CircularProgress color="primary" />;
};

type Props = { site: {id: number, mtsActivityTypes: DTO.MTSActivityType[]} };
const MATEditorInner: React.FC<Props> = ({ site }) => {
  const [selectedMat, setSelectedMat] = useState<Edit.MTSActivityType>();
  const localMATs = useMemo(
    () => site.mtsActivityTypes.map(mat => Edit.convertMAT(mat)).sort((m1, m2) => m1.sortIndex - m2.sortIndex).map((mat, index) => ({...mat, sortIndex: index})),
    [site.mtsActivityTypes.length, site.mtsActivityTypes]
  );
  const [updateMAT, {loading: updatingMAT}] = useUpdateMTSActivityType();
  const [updateMATOrder, { loading: updatingMATOrder }] = useUpdateMATOrder();
  const classes = useStyles();
  const [,setSortVersion] = useState(1);
  const confirmDlgCtrl = useDialogController(false);
  const refresh = () => setSortVersion(v => v++);
  const handleSelect = (mat: Edit.MTSActivityType) => setSelectedMat(mat);
  const addMAT = () => {
    const newMAT = Edit.createMAT({ sortIndex: localMATs.length });
    setSelectedMat(newMAT);
  };
  const saveMAT = async (values: Edit.MTSActivityType) => {
    const input = DTO.convertMAT(values);
    await updateMAT({
      variables: { input },
      update: (cache, { data }) => {
        if (input.id < 0 && data != null) {
          cache.modify({
            id: cache.identify(site),
            fields: {
              mtsActivityTypes(existingMATs = [], { toReference }) { return [...existingMATs, toReference(data.updateMTSActivityType)]; }
            }
          });
          setSelectedMat(Edit.convertMAT(data.updateMTSActivityType));
        }
      }
    });
    confirmDlgCtrl.controller.setOpen();
  };

  if (selectedMat != null && !localMATs.includes(selectedMat)) {
    const localSelectedMAT = localMATs.find(mat => mat.id === selectedMat.id);
    if (localSelectedMAT != null) setSelectedMat(localSelectedMAT);
  }

  const saveSortedMATs = async () => {
    const input = localMATs.filter(lm => lm.sortIndex != site.mtsActivityTypes.find(om => om.id === lm.id)?.sortIndex).map(m => DTO.convertMAT(m));
    await updateMATOrder({ variables: { input } });
    confirmDlgCtrl.controller.setOpen();
  };

  const navActions = (
    <>
      <IconButton icon="add" onClick={addMAT} />
      <IconButton icon="save" onClick={saveSortedMATs} />
    </>
  );

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader title={<LanguageString groupName="EDITOR" resourceName="MTS_ACTIVITY_TYPES" />} action={navActions} />
        <Divider />
        <MATList mats={localMATs} onClick={handleSelect} selectedMat={selectedMat} refresh={refresh} />
      </Card>
      {selectedMat && <MATDetail selectedMAT={selectedMat} saveMAT={saveMAT} />}
      <Backdrop className={classes.backdrop} open={updatingMAT || updatingMATOrder}>
        <CircularProgress color="primary" />
      </Backdrop>
      <MessageDialog ctrl={confirmDlgCtrl} message={<LanguageString groupName="EDITOR" resourceName="CONFIRM_SAVED" />} />
    </div>
  );
};

const MATEditorDataLoader: React.FC = () => {
  const { data } = useSiteMTSActivityTypes({ variables: { getFilterPropValue: true } });

  return data == null ? <CircularProgress color="primary" /> : <MATEditorInner site={data.site} />;
};

export const MTSActivityTypeEditor: React.FC<Partial<Props>> = ({ site }) =>
  site == null ? <MATEditorDataLoader /> : <MATEditorInner site={site} />;
