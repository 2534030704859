import { Button, Dialog, DialogContent, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { DialogTitle, LanguageString } from '../Common';

export interface PBISaveAsDialogProps {
  close: () => void;
  save: (name: string) => void;
  existingNames: string[];
}

interface IFormValues {
  name: string;
}

export const PBISaveAsDialog: React.FC<PBISaveAsDialogProps> = props => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [submitting, setSubmitting] = useState(false);

  const formValidation = yup.object<IFormValues>().shape({
    name: yup.string().required('You must supply a name.').notOneOf(props.existingNames, 'You must supply a unique name, another report has that name.')
  });

  return (<Dialog maxWidth="sm" fullWidth fullScreen={fullscreen} open={true} onClose={props.close} aria-label="Save As Dialog">
    <DialogTitle onClose={props.close}>
      <LanguageString groupName="REPORTING" resourceName="SAVE_AS_DIALOG_TITLE" defaultText="Save As" />
    </DialogTitle>
    <DialogContent dividers>
      <Grid container>
        {<Formik<IFormValues>
          initialValues={{
            name: ''
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={formValidation}
          onSubmit={async (values: IFormValues) => {

            setSubmitting(true);
            props.save(values.name);
          }}
        >
          {formikProps => {
            return (
              <Grid container direction="column" spacing={2}>
                <Field validateOnBlur validateOnChange name="name">
                  {() => (
                    <TextField
                      fullWidth
                      disabled={false}
                      name="name"
                      label={<LanguageString groupName="REPORTING" resourceName="NAME" defaultText="Name" />}
                      type="text"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.name ?? 'reeee'}
                      error={Boolean(formikProps.errors.name && (formikProps.touched.name || formikProps.submitCount > 0))}
                      helperText={formikProps.errors.name && (formikProps.touched.name || formikProps.submitCount > 0) ?
                        String(formikProps.errors.name) : ''}
                      role="textbox"
                      aria-label="name"
                    />
                  )}
                </Field>

                <Grid item container spacing={2}>
                  <Grid item>
                    <Button
                      disabled={submitting}
                      onClick={() => formikProps.handleSubmit()}
                      color="primary"
                      variant="contained">
                      <LanguageString groupName="REPORTING" resourceName="CONFIRMSAVE" defaultText="Save" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => props.close()} color="primary" variant="contained">
                      <LanguageString groupName="REPORTING" resourceName="CANCEL" defaultText="Cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>);
          }}
        </Formik>}
      </Grid >
    </DialogContent >
  </Dialog >);
};

export default PBISaveAsDialog;
