import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { FieldArray, Formik } from 'formik';
import React, { useContext } from 'react';
import { PathEditorContext } from '.';
import * as Edit from '../../common/pathEditTypes';
import { LanguageString, LSTextField } from '../Common';
import { updateLs } from '../MilestoneEditor/MilestoneEditorForm';
import { SubGroupList } from './SubgroupsList';

export const PathDetail: React.FC<{ path: Edit.SuccessPath }> = ({ path }) => {
  const { hasUnsavedChanges, refresh } = useContext(PathEditorContext);
  const handleSubmit = (values: Edit.SuccessPath) => {
    updateLs(path, values, 'name');
    updateLs(path, values, 'description');
    refresh();
  };

  return (
    <Formik initialValues={path} onSubmit={handleSubmit}>
      {formik => {
        hasUnsavedChanges.current = formik.dirty;
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title={<LanguageString groupName="EDITOR" resourceName="PATH" />} />
                <Divider />
                <CardContent>
                  <Grid container spacing={2} direction="column">
                    <LSTextField item name="name" label="Name" />
                    <LSTextField item name="description" label="Description" />
                  </Grid>
                </CardContent>
                <CardActions><Button onClick={formik.submitForm}>Save</Button></CardActions>
              </Card>
            </Grid>
            {path.msGroups?.length && (
              <Grid item xs={12}>
                <FieldArray name="msGroups" component={SubGroupList} />
              </Grid>
            )}
          </Grid>
        );
      }}
    </Formik>
  );
};
