import React, { useState, useRef, useEffect } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as DTO from '../../dto';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';
import { useSaveMTSPeriod, useDeleteMTSPeriod } from '../../hooks';
import { LanguageString, TextField, DateField } from '../Common';
import MTSHelpers from '../../helpers/mtsHelpers';

interface MTSPeriodAddDialogProps {
  period?: DTO.MTSPeriod;
  open: boolean;
  toggle: () => void;
}

interface FormType {
  startDate: Date;
  endDate: Date;
  customPropertyValue?: string;
  startDateYearOffset: number;
  endDateYearOffset: number;
  startModificationOffset?: number;
  endModificationOffset?: number;
  startApprovalOffset?: number;
  endApprovalOffset?: number;
  startSubmissionOffset?: number;
  endSubmissionOffset?: number;
}

const schema = () =>
  Yup.object().shape({
    startDate: Yup.string().required('* Required'),
    endDate: Yup.string().required('* Required')
  }
);

export const MTSPeriodAddDialog: React.FC<MTSPeriodAddDialogProps> = ({ period, open, toggle }) => {
  const formik = useRef<FormikProps<FormType> | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [saveMTSPeriod] = useSaveMTSPeriod({ refetchQueries: ['periods'] });
  const [deleteMTSPeriod] = useDeleteMTSPeriod({  refetchQueries: ['periods'] });

  const initialValues: FormType = {
    startDate: new Date(),
    endDate: new Date(),
    customPropertyValue: period?.customPropertyValue,
    startDateYearOffset: period?.startDateYearOffset ?? 0,
    endDateYearOffset: period?.endDateYearOffset ?? 0,
    startModificationOffset: period?.mtsModificationStartDayOffset ?? 0,
    endModificationOffset: period?.mtsModificationEndDayOffset ?? 0,
    startApprovalOffset: period?.mtsApproveStartDayOffset ?? 0,
    endApprovalOffset: period?.mtsApproveEndDayOffset ?? 0,
    startSubmissionOffset: period?.mtsSubmissionStartDayOffset ?? 0,
    endSubmissionOffset: period?.mtsSubmissionEndDayOffset ?? 0
  };

  const initialStatus = {
    errors: [] as string[]
  };

  useEffect(() => {
    if (open) {
      const periodDates = period ? MTSHelpers.getPeriodDates(period) : { startDate: new Date(), endDate: new Date() };

      formik.current?.setFieldValue('startDate', periodDates.startDate);
      formik.current?.setFieldValue('endDate', periodDates.endDate);
      formik.current?.setFieldValue('customPropertyValue', period?.customPropertyValue);
      formik.current?.setFieldValue('startDateYearOffset', period?.startDateYearOffset ?? 0);
      formik.current?.setFieldValue('endDateYearOffset', period?.endDateYearOffset ?? 0);
      formik.current?.setFieldValue('startModificationOffset', period?.mtsModificationStartDayOffset ?? undefined);
      formik.current?.setFieldValue('endModificationOffset', period?.mtsModificationEndDayOffset ?? undefined);
      formik.current?.setFieldValue('startSubmissionOffset', period?.mtsSubmissionStartDayOffset ?? undefined);
      formik.current?.setFieldValue('endSubmissionOffset', period?.mtsSubmissionEndDayOffset ?? undefined);
      formik.current?.setFieldValue('startApprovalOffset', period?.mtsApproveStartDayOffset ?? undefined);
      formik.current?.setFieldValue('endApprovalOffset', period?.mtsApproveEndDayOffset ?? undefined);

    }
  }, [period, open]);

  const handleSubmit = async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    const startDate = values.startDate;
    const endDate = values.endDate;
    startDate.setHours(6);
    endDate.setHours(6);

    await saveMTSPeriod({
      variables: {
        input: {
          id: period?.id,
          startDate: startDate,
          endDate: endDate,
          customPropertyValue: values.customPropertyValue != null && values.customPropertyValue !== '' ? values.customPropertyValue : undefined,
          startDateYearOffset: values.startDateYearOffset ? Number(values.startDateYearOffset) : 0,
          endDateYearOffset: values.endDateYearOffset ? Number(values.endDateYearOffset) : 0,
          mtsModificationStartDayOffset: values.startModificationOffset ? Number(values.startModificationOffset) : undefined,
          mtsModificationEndDayOffset: values.endModificationOffset ? Number(values.endModificationOffset) : undefined,
          mtsSubmissionStartDayOffset: values.startSubmissionOffset ? Number(values.startSubmissionOffset) : undefined,
          mtsSubmissionEndDayOffset: values.endSubmissionOffset ? Number(values.endSubmissionOffset) : undefined,
          mtsApproveStartDayOffset: values.startApprovalOffset ? Number(values.startApprovalOffset) : undefined,
          mtsApproveEndDayOffset: values.endApprovalOffset ? Number(values.endApprovalOffset) : undefined
        }
      },
      update: (c, r) => {
        _toggle();
      }
    });
  };

  const toggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };

  const _toggle = () => {
    formik.current?.resetForm();
    toggle();
  };

  const handleDelete = async () => {
    toggleDelete();

    if (period) {
      await deleteMTSPeriod({
        variables: { id: period.id },
        update: (c, r) => {
          _toggle();
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize={false}
    >
      {formikVar => {
        formik.current = formikVar;

        return (
          <>
            <Dialog fullWidth maxWidth="md" open={open} onClose={_toggle}>
              <DialogTitle>{period ? `Edit MTS Period ${period.id}` : 'Create MTS Period'}</DialogTitle>
              <DialogContent dividers>
                <Form>
                  <Grid container spacing={2}>
                    <DateField item xs={6} name="startDate" label="Start Date" />
                    <TextField item xs={6} name="startDateYearOffset" label="Start Date Year Offset" rows={1} />
                    <DateField item xs={6} name="endDate" label="End Date" />
                    <TextField item xs={6} name="endDateYearOffset" label="End Date Year Offset" rows={1} />
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">The modification offsets are tied to the start end date dates of the period. For example, if you enter 1 for the start offset the modification range will start 1 day after the period had begun. If you were to entery -1, the modification range would begin one day before the period starts. The same logic applies to the end range, but in correlation to the end date of the period. </Typography>
                    </Grid>
                    <TextField item xs={6} name="startModificationOffset" label="Start Modification Offset" />
                    <TextField item xs={6} name="endModificationOffset" label="End Modification Offset" rows={1} />
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">The submission/approval offset are tied to the end date of the period. If you want the MTS to be submitable 2 days before the end of the period and 2 days after, the beginning offset would be -2 and the end offset would be 2.</Typography>
                    </Grid>
                    <TextField item xs={6} name="startSubmissionOffset" label="Start Submission Offset" />
                    <TextField item xs={6} name="endSubmissionOffset" label="End Submission Offset" rows={1} />
                    <TextField item xs={6} name="startApprovalOffset" label="Start Approval Offset" />
                    <TextField item xs={6} name="endApprovalOffset" label="End Approval Offset" rows={1} />
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <TextField item xs={12} name="customPropertyValue" label="Filter (Custom Property Value)" rows={1} />
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={_toggle}>
                  <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
                </Button>
                {period && (
                  <Button color="secondary" onClick={toggleDelete}>
                    <LanguageString groupName="GENERAL" resourceName="DELETE" defaultText="Delete" />
                  </Button>
                )}
                <Button color="primary" onClick={formikVar.submitForm}>
                  <LanguageString groupName="GENERAL" resourceName="SAVE" defaultText="Save" />
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={deleteDialog} onClose={toggleDelete}>
              <DialogTitle>Delete MTS Period</DialogTitle>
              <DialogContent>
                <Typography>Are you sure you want to delete this MTS period?</Typography>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={toggleDelete}>
                  <LanguageString groupName="GENERAL" resourceName="NO" defaultText="No" />
                </Button>
                <Button color="primary" onClick={handleDelete}>
                  <LanguageString groupName="GENERAL" resourceName="YES" defaultText="Yes" />
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};
export default MTSPeriodAddDialog;
