import gql from 'graphql-tag';

export const rocFrag = gql`
  fragment rocFrag on RecordOfContact {
    id
    date
    isPrivate
    from {
      id
      username
      firstName
      lastName
    }
    to {
      id
      username
      firstName
      lastName
    }
    rocType {
      id
    }
    formSubmission {
      id
      answers {
        id
        question {
          id
          questionType
        }
        answer
        selections {
          id
          text
        }
      }
    }
  }
`;
