import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { attachmentTypeFrag } from './fragments/attachmentTypeFrag';
import formQuestionFrag from './fragments/formQuestionFrag';
import { languageFrag } from './fragments/languageFrag';
import { mtsPeriodGroupFrag } from './fragments/mtsPeriodGroupFrag';

type Results = { site: Site };
export const useSite = (options?: QueryHookOptions<Results>) => {
  const query = gql`
    ${formQuestionFrag}
    ${attachmentTypeFrag}
    ${mtsPeriodGroupFrag}
    ${languageFrag}

    fragment navRouteFields on NavRoute {
      id
      name
      title {
        id
        text
      }
      path
      href
      icon
      svgIcon
      role
      group
      isExact
      isRecursive
      component
      sortOrder
      isReadonly
    }

    query site {
      siteSettings {
        id
        key
        value
        valueDataType
      }
      site {
        id
        defaultContactEmail
        defaultLanguage {
          id
          code
        }
        defaultLogin
        drawerNavigation
        lockoutPeriod
        navigationDrawerOpenByDefault
        mtsWeekStartDay
        mtsEnabled
        mtsReviewEnabled
        notificationsEnabled
        studentSearchEnabled
        studentFilterEnabled
        newStudentModifier
        hideNotesButton
        defaultMtsView
        roadmapEnabled
        mtsHoursIncrement
        roadmapPrintPageEnabled
        mtsPrintPageEnabled
        mtsPeriodsEnabled
        caseLoadPathTabsEnabled
        icsFileCreationEnabled
        mtsInRoadmapEnabled
        mtsPeriodCustomPropertyTypeId
        enableUserPIN
        accountSetupProcess
        signupButtonEnabled
        translationPolicy
        applicationInsightsKey
        languages {
          ...LangFrag
        }
        mtsPeriodGroups {
          ...MTSPeriodGroupFrag
        }
        paths {
          id
          name {
            id
            text
          }
          description {
            id
            text
          }
          programs {
            id
          }
        }
        ssoConfigs {
          id
          loginPath
          name
          default
          automaticallyCreateUnknownUsers
          formMappings {
            id
            propName
            form {
              id
            }
            formQuestion {
              ...FormQuestionFrag2
            }
          }
        }
        mtsAdvisorForm {
          id
        }
        mtsStudentForm {
          id
        }
        registrationForm {
          id
        }
        userEndRegistrationForm {
          id
        }
        advisorProfileForm {
          id
        }
        caseLoadMTSRequiredForm {
          id
        }
        caseLoadSupportServicesForm {
          id
        }
        studentProfileForm {
          id
        }
        roadmapPrintHeaderForm {
          id
        }
        studentsFilterForm {
          id
        }
        caseLoadFilterForm {
          id
        }
        notificationBlastForm {
          id
        }
        employerFilterForm {
          id
        }
        employeeInfoROCTypeId
        employerProfileFormId
        employeeProfileFormId
        mtsSubmissionEndDayOffset
        mtsSubmissionStartDayOffset
        coachDeletePermission
        caseLoadLastNameCharacters
        settings {
          id
          key
          value
          valueDataType
        }
        branding {
          id
          cssUrl
          logoUrl
          cssClasses
          theme
          imagesUrl
        }
        attachmentTypes{
          ...AttachmentTypeFrag
        }
        navRoutes {
          ...navRouteFields
          children {
            ...navRouteFields
            children {
              ...navRouteFields
              children {
                ...navRouteFields
                children {
                  ...navRouteFields
                  children {
                    ...navRouteFields
                  }
                }
              }
            }
          }
        }
        mtsModificationEndDayOffset
        mtsModificationStartDayOffset
        mtsApproveEndDayOffset
        mtsApproveStartDayOffset
        caseLoadCustomPropertyColumns
        caseLoadCustomPropertyColumnsQuery
        downloadRocEnabled
        publicCustomPropertyTypes {
          id
          name
          fieldType
        }
      }
    }
  `;

  return useQuery<Results>(query, options);
};

export default useSite;
