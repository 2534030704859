import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { NotificationTrigger, NotificationTriggerInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { notificationTriggerFrag } from './fragments';

type Result = { saveNotificationTrigger: QueryType<NotificationTrigger> };
type Variables = { input: NotificationTriggerInput };
export const useSaveNotificationTrigger = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${notificationTriggerFrag}

    mutation saveNotificationTrigger($input: NotificationTriggerInput!) {
      saveNotificationTrigger(input: $input) {
        ...NotificationTriggerFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
