import gql from 'graphql-tag';
import { languageStringFrag } from './languageStringFrag';

export const mtsActivityMinFrag = gql`
  ${languageStringFrag}

  fragment MTSActivityMinFrag on MTSActivity {
    id
    day
    date
    hours
    note
    modifiedAt
    lastEditedBy {
      firstName
      lastName
    }
    type {
      id
      externalApprovalFormId
      externalApprovalRequired
      wpr
      appearsInReviewAllHours
      name {
        ...LangStringFrag
      }
    }
    userType {
      id
      name {
        ...LangStringFrag
      }
    }
  }
`;
