import { useApolloClient } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Portal, Typography, TypographyProps } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { RawHTML } from '..';
import { LgValues, lsDto, lsValue, LsValues } from '../../../common';
import { AppContext, UserContext, StudentContext } from '../../../contexts';
import { usePageString } from '../../../contexts/LanguageStringContext';
import { LanguageString as LanguageStringDto, Role, User } from '../../../dto';
import { useDialogController, useLocalStorage } from '../../../hooks';
import { useLanguageString } from '../../../hooks/gql';
import { query, Results, Variables } from '../../../hooks/gql/useLanguageString';
import useUpdateString from '../../../hooks/gql/useUpdateString';
import { LSTextField } from '../TextField';

type Variant = TypographyProps['variant'];
type LanguageStringArgs = { languageString: LanguageStringDto | undefined; defaultText?: string; className?: string; variant?: Variant, renderUserData?: boolean, disableHtml?: boolean };
type LanguageStringGroupArgs = { groupName: string; resourceName: string; defaultText?: string; className?: string; variant?: Variant, renderUserData?: boolean, disableHtml?: boolean };

function isLSGArgs(args: LanguageStringArgs | LanguageStringGroupArgs): args is LanguageStringGroupArgs {
  const candidateArgs = args as LanguageStringGroupArgs;
  return candidateArgs.groupName != null;
}

const showEditor = (user: User | undefined) => {
  return user?.roles?.includes(Role.EDITOR);
};

const StringEditor: React.FC<LanguageStringArgs | LanguageStringGroupArgs> = args => {
  const appCtx = useContext(AppContext);
  const ref = useRef<FormikProps<LSForm>>();
  const [isEditing, setIsEditing] = useState(false);
  const { controller, props } = useDialogController(true);
  const [updateString] = useUpdateString({ refetchQueries: ['getLanguageString'] });

  const handleEditClick: React.MouseEventHandler<HTMLElement> = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsEditing(true);
    controller.setOpen();
  };

  const handleSubmit = async (values: LSForm) => {
    const { text: lsData } = values;
    const lgVal = args as LgValues;
    setIsEditing(false);
    controller.setClose();
    if (values.text != null) {
      await updateString({ variables: { data: lsData?.id === 0 ? lsDto({ ...lgVal, text: values.text.text }) : lsDto(values.text) } });
    }
  };

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = event => event.stopPropagation();

  const submitForm = () => ref.current?.submitForm();
  const lsVals: LSForm = isLSGArgs(args) ? { text: { id: 0, isHtml: false, text: args.defaultText } } : { text: lsValue(args.languageString) };

  return (
    <Fragment>
      <Box
        style={{ position: 'absolute', transform: 'translateY(-20px)' }}
        className="MuiButtonBase-root MuiIconButton-root"
        onClick={handleEditClick}
      >
        <Icon>edit</Icon>
      </Box>
      {isEditing && (
        <Formik initialValues={lsVals} onSubmit={handleSubmit}>
          {formik => {
            ref.current = formik;
            return (
              <Portal container={appCtx.rootContainer}>
                <Dialog {...props} onClick={stopPropagation} maxWidth="md" fullWidth>
                  <DialogTitle>Edit String</DialogTitle>
                  <DialogContent dividers>
                    <LSTextField name="text" label="Text" />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={submitForm}>Save</Button>
                  </DialogActions>
                </Dialog>
              </Portal>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
};

const LSG: React.FC<LanguageStringGroupArgs & { user?: User }> = ({ groupName, resourceName, defaultText, user, className, variant, renderUserData, disableHtml }) => {
  const studentCtx = useContext(StudentContext);
  const { data, error } = useLanguageString({
    variables: {
      groupName,
      resourceName,
      userId: studentCtx?.student.id,
      renderUserData: renderUserData != null ? renderUserData : true
    },
    fetchPolicy: 'cache-first'
  });
  const [liveEdit] = useLocalStorage('LiveEdit', false);

  if (error) {
    return <span className={className}>{defaultText ? defaultText : 'No data'}</span>;
  } else {
    return data?.string ? (
      <LanguageString languageString={data.string} defaultText={defaultText} className={className} variant={variant} disableHtml={disableHtml} />
    ) : (
      <span className={className}>
        {defaultText ? defaultText : 'No data'}
        {liveEdit && showEditor(user) && <StringEditor groupName={groupName} resourceName={resourceName} defaultText={defaultText} />}
      </span>
    );
  }
};

type LSForm = { text: LsValues | undefined };

export const LanguageString: React.FC<LanguageStringArgs | LanguageStringGroupArgs> = args => {
  const pageStr = usePageString(isLSGArgs(args) ? args.groupName : undefined, isLSGArgs(args) ? args.resourceName : undefined);
  const userCtx = useContext(UserContext);
  const client = useApolloClient();
  const [liveEdit] = useLocalStorage('LiveEdit', false);
  const { defaultText } = args;
  let cachedLs: Results | null = null;

  const renderLs = (ls: LanguageStringDto | undefined) => {
    const { className, variant } = args;

    if (ls && ls.text != null) {
      return (
        <Fragment>
          {ls.isHtml && (args.disableHtml == false || args.disableHtml == null) ? (
            <RawHTML html={ls.text} />
          ) : variant == null ? (
            <span className={className}>{args.disableHtml === true ? ls.text.replace(/<[^>]+>/g, '') : ls.text}</span>
          ) : (
            <Typography variant={variant}>{args.disableHtml === true ? ls.text.replace(/<[^>]+>/g, '') : ls.text}</Typography>
          )}
          {liveEdit && showEditor(userCtx.user) && <StringEditor languageString={ls} />}
        </Fragment>
      );
    } else {
      return <span className={className}>{defaultText ? defaultText : 'No data'}</span>;
    }
  };

  // If there is a page string already in the context, don't bother running any other logic, just render the string
  if (pageStr) return renderLs(pageStr);

  if (isLSGArgs(args)) {
    try {
      cachedLs = client.readQuery<Results, Variables>({
        query: query,
        variables: { groupName: args.groupName, resourceName: args.resourceName }
      });
    } catch {
      cachedLs = null;
    }
  }

  if (cachedLs?.string == null && isLSGArgs(args) && !pageStr) {
    return <LSG {...args} user={userCtx.user} />;
  } else {
    const ls = cachedLs?.string || (args as LanguageStringArgs).languageString;
    return renderLs(ls);
  }
};
