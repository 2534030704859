import { Container, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { AppContext, ChildRoutes, SiteContext, UserContext, StudentsMenuProvider } from '../../contexts';
import { Role } from '../../dto';
import { useClasses, useClassesObject } from '../../hooks';
import { Footer } from '../Footer';
import { NavigationBar, NavigationDrawer } from '../Navigation';

const Navigation: React.FC<{ handleUpdatedBarHeight: (height: number) => void }> = ({ handleUpdatedBarHeight }) => {
  const siteCtx = useContext(SiteContext);

  const userCtx = useContext(UserContext);
  const currentRole = userCtx.user.currentRole;

  if (currentRole === Role.ADMIN || currentRole === Role.ADVISOR || currentRole === Role.SUPERADMIN) {
    return (
      <StudentsMenuProvider>
        {siteCtx.site?.drawerNavigation ? <NavigationDrawer updatedBarHeight={handleUpdatedBarHeight} /> : <NavigationBar />}
      </StudentsMenuProvider>
    );
  }

  return <>{siteCtx.site?.drawerNavigation ? <NavigationDrawer updatedBarHeight={handleUpdatedBarHeight} /> : <NavigationBar />}</>;
};

// Adding 'styleHack' to fix the layout of the reporting view. This is a temp fix until we refactor the app layout code (probably using css grid)
export const MainContent: React.FC<{styleHack: boolean}> = ({styleHack}) => {
  const classes = useClasses();
  const classesOb = useClassesObject();
  const appCtx = useContext(AppContext);
  const rootContainerRef = useCallback((node: HTMLDivElement) => appCtx?.setRootContainer?.(node), []);
  const [barHeight, setBarHeight] = useState(64);

  const padding = classesOb?.componentContainer?.paddingTop ?? 60;

  const handleUpdatedBarHeight = (height: number) => {
    setBarHeight(height);
  };

  const hack = styleHack ? { style: { display: 'flex', width: '100%' } } : {};

  return (
    <div className={classes.mainContentContainer}>
      <Navigation handleUpdatedBarHeight={handleUpdatedBarHeight} />
      <main className={classes.componentContainer} style={{ paddingTop: barHeight + padding }}>
        <Container ref={rootContainerRef} {...hack}>
          <Grid container spacing={3} {...hack}>
            <Grid xs={12} item {...hack}>
              <ChildRoutes group="navbar" />
              <ChildRoutes />
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </main>
    </div>
  );
};

export default MainContent;
