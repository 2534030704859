import React, { useMemo, useContext, useRef, useEffect, useState, ChangeEventHandler } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Icon,
  Tooltip,
  Collapse,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField, Select, SelectOption, Switch, RawHTML, LanguageString } from '../../Common';
import { useLanguageString, useUpdateString, useCustomProperties } from '../../../hooks';
import { SiteContext, UserContext } from '../../../contexts';
import { HtmlTextField } from '../HtmlTextField/HtmlTextField';
import { useFormikContext } from 'formik';
import * as DTO from '../../../dto';
import { CustomPropertyTypeFieldType } from 'tuapath-common/generated/schema';

interface LagnuageStringEditorProps {
  name: string;
  groupName: string;
  resourceName: string;
  title?: React.ReactNode | string;
  onChange?: ChangeEventHandler<{ name?: string | undefined; value: unknown; }>;
  shouldWarnOnDuplicateString?: boolean;
}

interface FormType {
  html: boolean;
  language: number;
  text?: string;
  htmlText?: string;
}

interface CreateStringFormType {
  groupName: string;
  resourceName: string;
}

// interface TextFieldAutoCompleteProps {
//   open: boolean;
//   toggle: () => void;
// }

const schema = () =>
  Yup.object().shape({

  });

const createScheam = Yup.object().shape({
  groupName: Yup.string().required('* Required'),
  resourceName: Yup.string().required('* Required')
});

const getUserDataVariables = (customProperties: DTO.CustomPropertyType[]): { [key: string]: { [key: string]: string } } => {
  const userProps = {
    'First Name': 'user.firstName',
    'Last Name': 'user.lastName',
    'Username': 'user.username',
    'Phone': 'user.phoneNumber',
    'Email': 'user.email'
  };

  const customProperyItems = {};
  for (const cp of customProperties) {
    const userPropVals = Object.values(userProps);
    if (cp.fieldType !== CustomPropertyTypeFieldType.PASSWORD && !cp.sensitive && !userPropVals.includes(`user.${cp.name}`)) {
      customProperyItems[`${cp.name} custom property`] = `user.${cp.name}`;
    }
  }

  return {
    'User': {
      ...userProps,
      ...customProperyItems
    },
    'Advisor': {
      'First Name': 'user.advisor.firstName',
      'Last Name': 'user.advisor.lastName',
      'Username': 'user.advisor.username',
      'Phone': 'user.advisor.phoneNumber',
      'Email': 'user.advisor.email'
    },
    'Dates': {
      'Now (MM-DD-YYYY)': 'now',
      'Now Pretty (MMMM, DD YYYY)': 'nowPretty',
      'Now Extended (MM-DD-YYYY hh:mmA)': 'nowExt'
    },
    'Meetings': {
      'Meeting Date (MM-DD-YYYY hh:mmA)': 'meetingDate',
      'Meeting Date Pretty (MMMM, DD YYYY hh:mmA)': 'meetingDatePretty',
      'Meeting Type': 'meetingType'
    },
    'Site': {
      'Site URL': 'site.url'
    },
    'Branding': {
      'Branding Name': 'site.branding.name',
      'Site Logo': 'site.branding.logoUrl'
    }
  };
};

const LanguageStringEditorVariablesDialogItem: React.FC<{ name: string, items: {[key: string]: string} }> = ({ name, items }) => {
  const [open, setOpen] = useState(false);

  const keys = Object.keys(items);

  return (
    <>
      <ListItem
        button
        onClick={(event) => {
          setOpen(!open);
        }}
      >
        <ListItemText>{name}</ListItemText>
        {open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {keys.map((key, index) => (
            <ListItem
              key={`child_variable_${index}`}
              style={{ paddingLeft: 40 }}
            >
              <ListItemText>
                <Typography variant="subtitle2">{key}</Typography>
                <Typography variant="body1">{`{{` + items[key] + `}}`}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

const LanguageStringEditorVariablesDialog: React.FC<{ open: boolean, toggle: () => void }> = ({ open, toggle }) => {
  const { data } = useCustomProperties();
  const userItems = useMemo(() => {
    if (data?.site.customProperties) {
      return getUserDataVariables(data.site.customProperties);
    }

    return [];
  }, [data]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={toggle} aria-labelledby="Template Variables">
      <DialogTitle>
        <LanguageString groupName="GENERAL" resourceName="TEMPLATE_VARIABLES" defaultText="Template Variables" />
      </DialogTitle>
      <DialogContent dividers>
        <List component="div" disablePadding>
          {Object.keys(userItems).map(key => {
            return (
              <LanguageStringEditorVariablesDialogItem
                key={`library_section_${key}`}
                name={key}
                items={userItems[key]}
              />
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

interface LanguageStringCreatorProps {
  title?: React.ReactNode | string;
  createdString: (ls: DTO.LanguageString) => void;
  shouldWarnOnDuplicateString?: boolean;
}

const LanguageStringCreator: React.FC<LanguageStringCreatorProps> = ({ title, createdString, shouldWarnOnDuplicateString }) => {

  const [updateString, updateStringData] = useUpdateString();

  useEffect(() => {
    if (updateStringData.data?.updateString?.string && updateStringData.data.updateString.groupName !== '') {
      createdString(updateStringData.data.updateString.string);
    }
  }, [updateStringData.data]);

  const initialValues: CreateStringFormType = {
    groupName: '',
    resourceName: ''
  };

  const initialStatus = {
    errors: [] as string[]
  };

  const handleSubmit = async (values: CreateStringFormType, formikHelpers: FormikHelpers<CreateStringFormType>) => await updateString({
    variables: {
      data: {
        group: {
          groupName: values.groupName,
          resourceName: values.resourceName,
          languageCode: 'en',
          newText: '',
          isHtml: false
        },
        shouldWarnOnDuplicateString: shouldWarnOnDuplicateString === false ? false : true
      }
    }
  });


  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      validationSchema={createScheam}
      enableReinitialize={false}
    >
      {formikVar => {
        return (
          <Form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={2} justify="flex-end">
              <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        {title && typeof title === 'string' ? <Typography variant="subtitle2">{title}</Typography> : title}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TextField item xs={12} name="groupName" label="Group Name" rows={1} />
              <TextField item xs={12} name="resourceName" label="Resource Name" rows={1} />

              <Grid item>
                <Button
                  disabled={updateStringData.loading}
                  onClick={() => formikVar.submitForm()}
                  color="primary"
                >
                  {updateStringData.loading ? <CircularProgress size={20} /> : `Submit`}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export const LanguageStringEditor: React.FC<LagnuageStringEditorProps> = ({ groupName, resourceName, title, name, shouldWarnOnDuplicateString }) => {
  const parentFormikContext = useFormikContext();
  const formik = useRef<FormikProps<FormType> | null>(null);
  const userCtx = useContext(UserContext);
  const siteCtx = useContext(SiteContext);
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [updateString, updatStringData] = useUpdateString({ refetchQueries: ['getLanguageString'] });
  const [variableDialogOpen, setVariableDialogOpen] = useState(false);

  const selectLanguages = useMemo(() => {
    return (siteCtx.site?.languages ?? []).map(l => {
      return { id: l.id, text: l.name, value: l.code} as SelectOption;
    }) ?? [];
  }, [siteCtx.site?.languages]);
  const englishId = useMemo(() => {
    return selectLanguages.find(l => l.value === 'en')?.id;
  }, [selectLanguages]);

  // const customProperties = useCustomProperties();
  // const userItems = useMemo(() => {
  //   let allItems: string[] = [];

  //   const items = getUserDataVariables(customProperties.data?.site.customProperties ?? []);
  //   for (const key of Object.keys(items)) {
  //     allItems = allItems.concat(Object.values(items[key]));
  //   }

  //   return allItems;
  // }, [customProperties.data]);

  // Fetch the english and current lan strings
  const englishLs = useLanguageString({
    variables: {
      groupName: groupName,
      resourceName: resourceName,
      userId: userCtx.user?.id,
      languageId: englishId,
      renderUserData: false
    },
    fetchPolicy: 'network-only'
  });
  const selectedLs = useLanguageString({
    variables: {
      groupName: groupName,
      resourceName: resourceName,
      userId: userCtx.user?.id,
      languageId: selectedLanguage,
      renderUserData: false
    },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (selectedLs.data?.string && selectedLs.data.string.text && groupName !== '' && resourceName !== '') {
      formik.current?.setFieldValue('text', selectedLs.data.string.text);
      formik.current?.setFieldValue('htmlText', selectedLs.data.string.text);
      formik.current?.setFieldValue('html', selectedLs.data.string.isHtml ?? false);

      parentFormikContext.setFieldValue(name, selectedLs.data?.string);
    } else {
      formik.current?.setFieldValue('text', '');
      formik.current?.setFieldValue('htmlText', '');
      formik.current?.setFieldValue('html', false);
    }
  }, [selectedLs.data?.string]);

  const initialValues: FormType = {
    html: false,
    text: '',
    htmlText: '',
    language: 1
  };

  const initialStatus = {
    errors: [] as string[]
  };

  const handleSubmit = async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    const language = siteCtx.site?.languages.find(l => l.id === selectedLanguage);

    if (language && language.code) {
      await updateString({
        variables: {
          data: {
            group: {
              groupName: groupName,
              resourceName: resourceName,
              languageCode: language.code,
              newText: values.html ? (values.htmlText ?? '') : (values.text ?? ''),
              isHtml: values.html
            },
            shouldWarnOnDuplicateString: shouldWarnOnDuplicateString === false ? false : true
          }
        }
      });
    }
  };

  const toggleVariableDialog = () => {
    setVariableDialogOpen(!variableDialogOpen);
  };

  // const findVariableStringInText = (formikVar: FormikProps<FormType>) => {
  //   const regex = /{{/gi;
  //   let result: RegExpExecArray | null = null;
  //   const indices = [];
  //   const val = (formikVar.values.html ? formikVar.values.htmlText : formikVar.values.text) ?? '';
  //   while ((result = regex.exec(val))) {
  //     indices.push(result.index);
  //   }

  //   for (const index of indices) {
  //     if (val) {
  //       const substr = val.substr(index, val.length - index);
  //       if (!substr.includes('}}')) {
  //         return substr.toLocaleLowerCase();
  //       }
  //     }
  //   }

  //   return undefined;
  // };

  return (
    <>
      <Formik
        initialValues={initialValues}
        initialStatus={initialStatus}
        onSubmit={handleSubmit}
        validationSchema={schema}
        enableReinitialize={false}
      >
        {formikVar => {
          formik.current = formikVar;

          return (
            <Card>
              <CardContent>
                {resourceName === '' || groupName === '' ? (
                  <LanguageStringCreator
                    title={title}
                    createdString={(str) => parentFormikContext.setFieldValue(name, str)}
                    shouldWarnOnDuplicateString={shouldWarnOnDuplicateString}
                  />
                ) :
                  (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item xs={12}>
                          <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                              <Grid container justify="center">
                                <Grid item>
                                  {title && typeof title === 'string' ? <Typography variant="subtitle2">{title}</Typography> : title}
                                </Grid>
                                <Grid item style={{ marginLeft: 8}}>
                                  {selectedLs.loading && <CircularProgress size={15} />}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Switch item name="html" label="HTML" />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography><strong>Group:</strong> {groupName}</Typography>
                          <Typography><strong>Resource:</strong> {resourceName}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Select
                            item
                            xs={12}
                            name="language"
                            label="Language"
                            items={selectLanguages}
                            onChange={(e) => setSelectedLanguage(e.target.value as number)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        {formikVar.values.language !== englishId && (
                          <>
                            <Grid item xs={12}>
                              <Box>
                                {formikVar.values.html ?
                                  <RawHTML html={englishLs.data?.string?.text ?? ''} /> :
                                  <Typography variant="caption">{englishLs.data?.string?.text ?? ''}</Typography>
                                }
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          {/* <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            autoSelect={false}
                            options={userItems}
                            value={formikVar.values.html ? formikVar.values.htmlText : formikVar.values.text}
                            filterOptions={(options, params) => {
                              let foundSubstr = findVariableStringInText(formikVar);

                              if (foundSubstr !== undefined) {
                                foundSubstr = foundSubstr.replace('{{', '');
                                if (foundSubstr !== '') {
                                  return userItems.filter(i => i.toLowerCase().includes(foundSubstr ?? ''));
                                }

                                return userItems;
                              }

                              return [];
                            }}
                            onChange={(e, value, reason, details) => {
                              const foundSubstr = findVariableStringInText(formikVar);
                              if (foundSubstr && value) {
                                let newVal = (formikVar.values.html ? formikVar.values.htmlText : formikVar.values.text) ?? '';
                                newVal = newVal.replace(foundSubstr.replace('{{', ''), value) + '}}';
                                formikVar.setFieldValue(formikVar.values.html ? 'htmlText' : 'text', newVal);
                              }
                            }}
                            renderInput={(props) => (
                              <>
                                {formikVar.values.html ? (
                                  <HtmlTextField {...props} item xs={12} name="htmlText" />
                                ) : (
                                  <TextField {...props} item xs={12} name="text" label="Text" rows={5} />
                                )}
                              </>
                            )}
                          /> */}
                          <>
                            {formikVar.values.html ? (
                              <HtmlTextField item xs={12} name="htmlText" />
                            ) : (
                              <TextField item xs={12} name="text" label="Text" rows={5} />
                            )}
                          </>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container justify="space-between">
                            <Grid item>
                              <Tooltip
                                title={
                                  <LanguageString groupName="GENERAL" resourceName="TEMPLATE_VARIABLES" defaultText="Template Variables" />
                                }
                              >
                                <IconButton onClick={toggleVariableDialog}><Icon>info</Icon></IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={updatStringData.loading}
                                onClick={() => formikVar.submitForm()}
                                color="primary"
                              >
                                {updatStringData.loading ? <CircularProgress size={20} /> : `Save String`}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
              </CardContent>
            </Card>
          );
        }}
      </Formik>
      <LanguageStringEditorVariablesDialog open={variableDialogOpen} toggle={toggleVariableDialog} />
    </>
  );
};
export default LanguageStringEditor;
