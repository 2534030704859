import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { milestoneFrag } from './fragments';

export type SiteMilestonesQuery = { site: Pick<Site, 'id' | 'milestones'> };
export const siteMilestonesQuery = gql`
  ${milestoneFrag}
  query siteMilestones {
    site {
      id
      milestones {
        ...MilestoneFrag
      }
    }
  }
`;

export function useSiteMilestones(options?: QueryHookOptions<SiteMilestonesQuery>) {
  return useQuery<SiteMilestonesQuery>(siteMilestonesQuery, options);
}
