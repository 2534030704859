import React, { useContext, useMemo } from 'react';
import { useField } from 'formik';
import {
  TextField
} from '@material-ui/core';
import * as DTO from '../../../dto';
import { LanguageString } from '../../Common';
import { MTSContext } from '../../../contexts';

const MTSNoteTextField: React.FC<{ enabled: boolean; readonly: boolean; activityFieldName: string; activityTypes: DTO.MTSActivityType[] }> = props => {
  const [activityField] = useField(props.activityFieldName);
  const [noteField, noteFieldMeta] = useField('note');
  const mtsCtx = useContext(MTSContext);

  const type: DTO.MTSActivityType | DTO.UserMTSActivityType | undefined = activityField.value;
  const selectedActivityType = useMemo(() => {
    if (type && ('expiring' in type)) {
      return mtsCtx.mtsActivityTypeById((type as DTO.UserMTSActivityType).parentActivityType.id);
    } else {
      return type;
    }
  }, [type]);

  if ((selectedActivityType && selectedActivityType.noteRequired) || props.enabled) {
    return (
      <TextField
        disabled={props.readonly}
        name="note"
        fullWidth
        multiline={true}
        rows={5}
        rowsMax={5}
        label={<LanguageString groupName="MTS" resourceName="NOTE" defaultText="Note" />}
        onChange={noteField.onChange}
        onBlur={noteField.onBlur}
        value={noteField.value ?? ''}
        error={Boolean(noteFieldMeta.error && noteFieldMeta.touched)}
        helperText={noteFieldMeta.error && noteFieldMeta.touched && String(noteFieldMeta.error)}
      />
    );
  }

  return null;
};

export default MTSNoteTextField;
