import React, { useState } from 'react';

type EmptyFunc = () => void;

interface ComponentNotificationContextProps {
  triggerListeners: (key: string) => void;
  listeners: { [key: string]: EmptyFunc };
  removeListender: (key: string) => void;
  addListener: (key: string, listener: EmptyFunc) => void;
}

export const ComponentNotificationContext = React.createContext<ComponentNotificationContextProps>({
  triggerListeners: (k) => { console.log(`nothing here...`); },
  listeners: {},
  removeListender: (k) => { console.log(''); },
  addListener: (k, l) => { console.log(''); }
});

export const ComponentNotificationProvider: React.FC = ({ children }) => {
  const [_listeners, _setListenders] = useState<{ [key: string]: EmptyFunc }>({});

  const _trigger = (key: string) => {
    const keys = Object.keys(_listeners);
    if (keys.includes(key)) {
      _listeners[key]();
    } else {
      console.log(`key ${key} was not stored in the list of listeners`);
    }
  };

  return (
    <ComponentNotificationContext.Provider
      value={{
        triggerListeners: _trigger,
        listeners: _listeners,
        removeListender: (k) => {
          const list = _listeners;
          delete list[k];
          _setListenders(list);
        },
        addListener: (k, l) => {
          const list = _listeners;
          list[k] = l;
          _setListenders(list);
        }
      }}
    >
      {children}
    </ComponentNotificationContext.Provider>
  );
};
export default ComponentNotificationProvider;
