import React, { useContext } from 'react';
import {
  Icon,
  TableRow,
  TableCell,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton
} from '@material-ui/core';
import {format, getMonth, getYear, subMonths } from 'date-fns';
import { useClasses, UserResult } from '../../hooks';
import RouteBuilderHelper from '../../helpers/routeBuilderHelper';
import { CaseLoadMTSCell, CaseLoadMeetingCell } from './CaseLoadCells';
import { DynamicForm } from '../DynamicForm';
import * as DTO from '../../dto';
import { CustomPropertyTypeFieldType } from 'tuapath-common/generated/schema';
import { CaseLoadColumnSettings } from './CaseLoadFilterDialog/CaseLoadColumnSettingsTab';
import { SiteContext } from '../../contexts';
import { useMemo } from 'react';
import { LanguageString } from '../Common';
import { useState } from 'react';
import { useCallback } from 'react';
import { MTSPeriod } from 'tuapath-common/generated/schema';

interface CaseLoadTableRowProps {
  student: UserResult;
  columns: CaseLoadColumnSettings;
  currentPeriod?: MTSPeriod;
  previousPeriod?: MTSPeriod;
  twoPeriodsAgo?: MTSPeriod;
  customPropertyColumns: string[];
}

interface CaseLoadTableRowFormDialogProps {
  toggle: () => void;
  mtsRequired: boolean;
  student: UserResult;
}

interface CustomPropertyFormDialogProps {
  open: boolean;
  toggle: () => void;
  student: UserResult;
  customPropertyTypeName: string;
}

const CaseLoadTableRowFormDialog: React.FC<CaseLoadTableRowFormDialogProps> = React.memo(({ toggle, mtsRequired, student }) => {
  const siteCtx = useContext(SiteContext);
  const formId = useMemo(() => {
    return mtsRequired ? siteCtx.site?.caseLoadMTSRequiredForm?.id : siteCtx.site?.caseLoadSupportServicesForm?.id;
  }, [siteCtx.site?.caseLoadMTSRequiredForm?.id, siteCtx.site?.caseLoadSupportServicesForm?.id, mtsRequired]);
  let dynamicFormRef: DynamicForm | null = null;

  const submitButtonPressed = useCallback(() => {
    if (dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }
  }, []);

  return (
    <Dialog fullWidth maxWidth="lg" open={true} onClose={toggle} aria-labelledby="Form Dialog">
      <DialogTitle>
        {mtsRequired ?
          <LanguageString groupName="CASE_LOAD" resourceName="MTS_REQUIRED_DIALOG" defaultText="MTS Required" /> :
        <LanguageString groupName="CASE_LOAD" resourceName="SUPPORT_SERVICES_DIALOG" defaultText="Support Services" />}
      </DialogTitle>
      <DialogContent dividers>
        {formId && (
          <DynamicForm
            ref={f => (dynamicFormRef = f)}
            formId={formId}
            userId={student.id}
            autoSave={false}
            showSubmitButton={false}
            autoloadFormSubmission={true}
            renderFormTitle={false}
            refreshQueries={['coachHomePage']}
            onFormSubmitted={(data) => {
              toggle();
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={toggle}>
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        <Button color="primary" variant="contained" onClick={submitButtonPressed}>
          <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const CustomPropertyFormDialog: React.FC<CustomPropertyFormDialogProps> = React.memo(({ toggle, open, student, customPropertyTypeName }) => {
  let dynamicFormRef: DynamicForm | null = null;

  const submitButtonPressed = useCallback(() => {
    if (dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }
  }, []);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={toggle} aria-labelledby="Form Dialog">
      <DialogTitle>
        <LanguageString groupName="CASE_LOAD" resourceName={customPropertyTypeName} defaultText={customPropertyTypeName} />
      </DialogTitle>
      <DialogContent dividers>
        <DynamicForm
          ref={f => (dynamicFormRef = f)}
          formName={customPropertyTypeName}
          userId={student.id}
          autoSave={false}
          showSubmitButton={false}
          autoloadFormSubmission={true}
          renderFormTitle={false}
          disabled={false}
          isReadOnly={false}
          refreshQueries={['coachHomePage']}
          onFormSubmitted={(data) => {
            toggle();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={toggle}>
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        <Button color="primary" variant="contained" onClick={submitButtonPressed}>
          <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export interface CaseLoadCustomPropertyValueProps {
  customProperty?: DTO.UserCustomProperty;
  student: UserResult;
  customPropertyName: string;
}

const CaseLoadCustomPropertyValue: React.FC<CaseLoadCustomPropertyValueProps> = React.memo(({ customPropertyName, customProperty, student }) => {
  const siteCtx = useContext(SiteContext);
  const classes = useClasses();
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const customPropertyType = useMemo(() => {
    return siteCtx.site?.publicCustomPropertyTypes.find(cpt => cpt.name === customPropertyName);
  }, [customPropertyName]);

  if (customPropertyType == null) return null;

  if (customPropertyType.fieldType === CustomPropertyTypeFieldType.DATE) {
    return <>{customProperty?.value ? format(new Date(customProperty.value), 'MM/dd/yy h:mm a') : null}</>;
  } else if (customPropertyType.fieldType === CustomPropertyTypeFieldType.CHECKBOX) {
    const value = (customProperty?.value === 'true' || customProperty?.value === '1') ? true : false;

    return (
      <>
        <IconButton
          onClick={toggleDialog}
        >
          <Icon
            className={value ? classes.success : ''}
            color={value ? 'inherit' : 'error'}
          >
            {value ? 'check_circle' : 'error'}
          </Icon>
        </IconButton>
        {dialogOpen && (
          <CustomPropertyFormDialog
            toggle={toggleDialog}
            open={dialogOpen}
            student={student}
            customPropertyTypeName={customPropertyName}
          />
        )}
      </>
    );
  }

  return <>{customProperty?.resolvedValue ?? customProperty?.value}</>;
});

export const CaseLoadTableRow: React.FC<CaseLoadTableRowProps> = React.memo((
  { student, columns, currentPeriod, previousPeriod, twoPeriodsAgo, customPropertyColumns }
) => {
  const classes = useClasses();
  const siteCtx = useContext(SiteContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [clickedMTSRequired, setClickedMTSRequired] = useState(false);

  const currentDate = new Date();
  const currentMonthNum = getMonth(currentDate) + 1;
  const currentMonthYear = getYear(currentDate);
  const lastMonth = subMonths(currentDate, 1);
  const lastMonthNum = getMonth(lastMonth) + 1;
  const lastMonthYear = getYear(lastMonth);
  const twoMonthsAgo = subMonths(currentDate, 2);
  const twoMonthsAgoNum = getMonth(twoMonthsAgo) + 1;
  const twoMonthsAgoYear = getYear(twoMonthsAgo);

  const closedUser = useMemo(() => {
    if (student.customPropertiesByNames) {
      // TODO: Remove the hard reference to custom prop names
      return (student.customPropertiesByNames.find(cp => cp.type?.name === DTO.CustomPropertyNames.closedCase)?.value === 'true');
    }

    return false;
  }, [student, student.customPropertiesByNames]);

  const newNotifUser = useMemo(() => {
    if (student.customPropertiesByNames && siteCtx.site?.newStudentModifier) {
      const stdAccount = student.customPropertiesByNames?.find(cp => cp.type?.name === DTO.CustomPropertyNames.advisorID)?.modifiedAt;
      if (stdAccount) {
        const coachAssignDate = new Date(stdAccount);
        if (coachAssignDate) {
          const daysToMili = siteCtx.site?.newStudentModifier * 86400000;
          const newStudentPeriod = currentDate.getTime() - daysToMili;
          if ((coachAssignDate.getTime() >= newStudentPeriod)) {
            return true;
          };
        };
      }

      return false;
    }
  }, [student, student.createdAt, DTO]);

  const lastNotificationUser = useMemo(() => {
    if (student.customPropertiesByNames) {
      // TODO: Remove the hard reference to custom prop names
      const lastNotificationProp = student.customPropertiesByNames.find(cp => cp.type?.name === DTO.CustomPropertyNames.lastNotificationDate);
      if (lastNotificationProp && lastNotificationProp.value !== undefined) {
        const date = new Date(lastNotificationProp.value);
        if (new Date() < date) {
          return true;
        }
      }
    }

    return false;
  }, [student, student.customPropertiesByNames]);

  const caseId = useMemo(() => {
    if (student.customPropertiesByNames) {
      for (const prop of student.customPropertiesByNames) {
        if (prop.type?.name === DTO.CustomPropertyNames.caseIDNum) {
          return prop.value;
        }
      }
    }

    return undefined;
  }, [student]);

  const lastRoadmapInteraction = useMemo(() => {
    if (student.assignments && student.assignments.assignments && student.assignments.assignments.length > 0) {
      const assignment = student.assignments.assignments[0];
      if (assignment.modifiedAt) {
        return format(new Date(assignment.modifiedAt), 'MM/dd/yy h:mm a');
      }
    }

    return undefined;
  }, [student, student.assignments]);

  const lastMTSInteractionDate = useMemo(() => {
    let newestDate: Date | undefined = undefined;
    let activities: DTO.MTSActivity[] = [];

    if (student.twoMonthsAgoMTS && student.twoMonthsAgoMTS.entries && student.twoMonthsAgoMTS.entries.entries) {
      activities = activities.concat(student.twoMonthsAgoMTS.entries.entries);
    }
    if (student.lastMonthMTS && student.lastMonthMTS.entries && student.lastMonthMTS.entries.entries) {
      activities = activities.concat(student.lastMonthMTS.entries.entries);
    }
    if (student.currentMonthMTS && student.currentMonthMTS.entries && student.currentMonthMTS.entries.entries) {
      activities = activities.concat(student.currentMonthMTS.entries.entries);
    }

    for (const activity of activities) {
      if (activity.modifiedAt) {
        const modifiedAt = new Date(activity.modifiedAt);
        if (!newestDate || modifiedAt > newestDate) {
          newestDate = modifiedAt;
        }
      }
    }

    if (newestDate) {
      return format(newestDate, 'MM/dd/yy h:mm a');
    }

    return undefined;
  }, []);

  const mts = useMemo(() => {
    if (student.currentMonthMTS) {
      return student.currentMonthMTS;
    } else {
      return {
        id: -1,
        state: DTO.MTSState.NOT_SUBMITTED,
        month: currentMonthNum,
        year: currentMonthYear,
        mtsPeriod: currentPeriod,
        totalHours: 0,
        totalWprHours: 0
      } as DTO.MTS;
    }
  }, [student.currentMonthMTS, currentPeriod]);

  const lastMTS = useMemo(() => {
    if (student.lastMonthMTS) {
      return student.lastMonthMTS;
    } else {
      return {
        id: -1,
        state: DTO.MTSState.NOT_SUBMITTED,
        month: lastMonthNum,
        year: lastMonthYear,
        mtsPeriod: previousPeriod,
        totalHours: 0,
        totalWprHours: 0
      } as DTO.MTS;
    }
  }, [student.lastMonthMTS, previousPeriod]);

  const twoMtsAgoMTS = useMemo(() => {
    if (student.twoMonthsAgoMTS) {
      return student.twoMonthsAgoMTS;
    } else {
      return {
        id: -1,
        state: DTO.MTSState.NOT_SUBMITTED,
        month: twoMonthsAgoNum,
        year: twoMonthsAgoYear,
        mtsPeriod: twoPeriodsAgo,
        totalHours: 0,
        totalWprHours: 0
      } as DTO.MTS;
    }
  }, [student.twoMonthsAgoMTS, twoPeriodsAgo]);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <TableRow
      key={`case_load_student_${student.id}`}
      className={`${newNotifUser ? classes.newStudent : (lastNotificationUser ? classes.lastNotification : (closedUser ? classes.closedCase : ''))}`}
    >
      {columns.name && (
        <TableCell>
          <Link href={RouteBuilderHelper.openStudentRoute('/profile', [student.id], student.id)} target="_blank">
            {student.firstName} {student.lastName.substring(0, siteCtx.site?.caseLoadLastNameCharacters ?? 1)}{caseId ? `, ${caseId}` : ''}
          </Link>
        </TableCell>
      )}

      {columns.twoMonthsAgoMTSStatus && (
        <CaseLoadMTSCell mts={twoMtsAgoMTS} user={student} period={twoPeriodsAgo} />
      )}
      {columns.lastMonthMTSStatus && (
        <CaseLoadMTSCell mts={lastMTS} user={student} period={previousPeriod} />
      )}
      {columns.currentMonthStatus && (
        <CaseLoadMTSCell mts={mts} user={student} period={currentPeriod} />
      )}

      {columns.coachMeeting && (
        <CaseLoadMeetingCell student={student} />
      )}
      {columns.lastMTSInteraction && (
        <TableCell>{lastMTSInteractionDate}</TableCell>
      )}
      {columns.lastRoadmapInteraction && (
        <TableCell>{lastRoadmapInteraction}</TableCell>
      )}
      {customPropertyColumns.map(c => (
        <TableCell key={`custom_property_${c}`}>
          <CaseLoadCustomPropertyValue
            customPropertyName={c}
            customProperty={student.customPropertiesByNames?.find(cp => cp.type?.name === c)}
            student={student}
          />
        </TableCell>
      ))}

      {dialogOpen && (
        <CaseLoadTableRowFormDialog
          toggle={toggleDialog}
          mtsRequired={true}
          student={student}
        />
      )}
    </TableRow>
  );
});

export default CaseLoadTableRow;
