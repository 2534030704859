import React, { useContext } from 'react';
import {
  Menu,
  List
} from '@material-ui/core';
import { useClasses } from '../../../hooks';
import { NotificationsTable } from '../NotificationsTable';
import { NotificationsProvider } from '../../../contexts/NotificationsProvider';
import { UserContext } from '../../../contexts';

interface NotificationsMenuProps {
  open: boolean;
  anchor: React.RefObject<HTMLElement>;
  toggle: () => void;
}

export const NotificationsMenu: React.FC<NotificationsMenuProps> = props => {
  const classes = useClasses();
  const userCtx = useContext(UserContext);

  return (
    <Menu
      id="notifications-menu"
      className={classes.notificationsContainer}
      anchorEl={props.anchor.current}
      keepMounted
      open={props.open}
      onClose={props.toggle}
      PaperProps={{
        style: {
          width: 320
        }
      }}
    >
      <List>
        <NotificationsProvider type="user" userId={userCtx.user.id}>
          <NotificationsTable currentUserContext={true} clickedNotification={props.toggle} scrollId='scrollableDivMenu'/>
        </NotificationsProvider>
      </List>
    </Menu>
  );
};

export default NotificationsMenu;
