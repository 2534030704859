import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { removeTemplate: boolean };
type Variables = { id: number };
export const useDeleteNotificationTemplate = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation removeTemplate($id: Int!) {
      removeTemplate(id: $id)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
