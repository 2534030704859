import { Button, Grid, MenuItem, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext } from 'react';
import { LanguageString } from '..';
import { SiteContext } from '../../contexts';

interface FileTableUploadRowProps {
  file: File;
  fileowner: number;
  lockedFileTypeId?: number;
  destroy: (file: File) => void;
  uploadFile: (file: File, description: string, fileTypeId: number, userId: number) => Promise<void>;
}

interface FormValues {
  file: File;
  description: string;
  fileTypeId: number;
}

const formValidation = yup.object<FormValues>().shape({
  file: yup.mixed().required('file is missing'),
  description: yup.string().required('You must describe the upload'),
  fileTypeId: yup.number().required('You must select a file type').notOneOf([0], 'You must select a file type')
});

export const FileTableUploadRow: React.FC<FileTableUploadRowProps> = (props) => {
  const siteCtx = useContext(SiteContext);
  let fileTypes = siteCtx.site?.attachmentTypes ?? [];
  fileTypes = fileTypes.filter(el => el.canUpload && !el.editorType);

  return (
    <TableRow>
      <Formik<FormValues>
        initialValues={{
          file: props.file,
          description: '',
          fileTypeId: 0
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={formValidation}
        onSubmit={async (values: FormValues) => {
          if (props.lockedFileTypeId) {
            values.fileTypeId = props.lockedFileTypeId;
          }
          await props.uploadFile(values.file, values.description, values.fileTypeId, props.fileowner);
        }}
      >
        {formikProps => {
          return (
            <>
              <TableCell><Typography>{formikProps.values.file.name}</Typography></TableCell>
              <TableCell><Field validateOnBlur validateOnChange name="description">
                {() => (
                  <TextField
                    fullWidth
                    disabled={false}
                    name="description"
                    label={<LanguageString groupName="FILEBOX" resourceName="DESCRIPTION" defaultText="Description" />}
                    type="text"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.description ?? ''}
                    error={Boolean(formikProps.errors.description && (formikProps.touched.description || formikProps.submitCount > 0))}
                    helperText={formikProps.errors.description && (formikProps.touched.description || formikProps.submitCount > 0) ?
                      String(formikProps.errors.description) : ''}
                    role="textbox"
                    aria-label="Description"
                  />
                )}
              </Field></TableCell>
              <TableCell>
                <Field validateOnBlur validateOnChange name="fileTypeId">
                  {() => {
                    if (!props.lockedFileTypeId) {
                      return (
                        <TextField
                          fullWidth
                          value={formikProps.values.fileTypeId === 0 ? '' : formikProps.values.fileTypeId}
                          variant="outlined"
                          label={<LanguageString groupName="FILEBOX" resourceName="FILETYPE" defaultText="File Type" />}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.fileTypeId && (formikProps.touched.fileTypeId || formikProps.submitCount > 0))}
                          helperText={formikProps.errors.fileTypeId && (formikProps.touched.fileTypeId || formikProps.submitCount > 0) ?
                            String(formikProps.errors.fileTypeId) : ''}
                          select
                          name="fileTypeId"
                          role="listbox"
                          aria-label="File Type"
                        >
                          {fileTypes.map(type => (
                            <MenuItem
                              key={`activity_${type.id}`}
                              value={type.id}
                            >
                              {type.name?.text}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    } else {
                      return null;
                    }
                  }}
                </Field>
              </TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={() => formikProps.handleSubmit()}
                      disabled={formikProps.isSubmitting}
                      color="primary"
                      variant="contained"
                    >
                      <LanguageString groupName="FILEBOX" resourceName="UPLOAD" defaultText="Upload" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => props.destroy(props.file)} color="primary" variant="contained">
                      <LanguageString groupName="FILEBOX" resourceName="CANCEL" defaultText="Cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </>);
        }}
      </Formik>
    </TableRow>
  );
};
