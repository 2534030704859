import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';
import { ReqVars } from './common';
import { mtsFrag } from './fragments/mtsFrag';

type Variables = { userId: number; mtsId: number };
type Results = { user: { mtsById: DTO.MTS } };
const query = gql`
  ${mtsFrag}

  query mtsById($userId: Int!, $mtsId: Int!) {
    user(id: $userId) {
      id
      mtsById(id: $mtsId) {
        ...MTSFrag
      }
    }
  }
`;

export const useMTSById = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export default useMTSById;
