import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { deleteMTSPeriod: boolean };
type Variables = { id: number };
export const useDeleteMTSPeriod = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation deleteMTSPeriod($id: Int!) {
      deleteMTSPeriod(id: $id)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
