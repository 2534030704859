import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { RemoveMilestoneMutationArgs, SuccessPath } from '../../dto';
import { pathFrag } from './fragments';

type Result = { removeMilestone: SuccessPath };
type Variables = RemoveMilestoneMutationArgs;
export function useRemoveMilestone(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${pathFrag}

    mutation removeMilestone($input: MilestoneInput!) {
      removeMilestone(input: $input) {
        ...pathFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useRemoveMilestone;
