import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';
import { ReqVars } from './common';
import { mtsPeriodFrag } from './fragments';

type Variables = { shouldFilter: boolean };
type Results = { site: { mtsPeriods: DTO.MTSPeriod[] } };

const query = gql`
  ${mtsPeriodFrag}

  query periods($shouldFilter: Boolean!) {
    site {
      id
      mtsPeriods(shouldFilter: $shouldFilter) {
        ...MTSPeriodFrag
      }
    }
  }
`;

export const useMTSPeriods = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export default useMTSPeriods;
