import { Grid, Icon } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import React, { useContext } from 'react';
import { DragDropContext, DragDropContextProps, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { moveSortable } from '..';
import * as Edit from '../../common/pathEditTypes';
import { MilestoneTreeItem } from './MilestoneTreeItem';
import { PathEditorContext } from './PathEditor';
import { EditLabel } from './PathTreeItem';

type GroupTreeItemProps = {
  path: Edit.SuccessPath;
  group: Edit.MilestoneGroup;
  dragHandleProps: DraggableProvided['dragHandleProps'];
};

export const GroupTreeItem: React.FC<GroupTreeItemProps> = ({ path, group, dragHandleProps }) => {
  const { refresh } = useContext(PathEditorContext);

  const handleDrag: DragDropContextProps['onDragEnd'] = (result, provided) => {
    const msGroups = result.draggableId.startsWith('ms-') ? group.milestones : group.subGroups;
    if (msGroups == null) return;

    if (result.reason === 'DROP' && result.destination != null && group.subGroups!) {
      moveSortable(msGroups, result.source.index, result.destination.index);
      refresh();
    }
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={`child-${String(group.id)}`}>
        {(provided, snapshot) => (
          <TreeItem
            ref={provided.innerRef}
            nodeId={`grp-${path.id}-${group.id}`}
            label={
              <Grid container justify="space-between" wrap="nowrap">
                <Grid item>
                  <EditLabel name={group.name} item={group} />
                </Grid>
                <Grid item>
                  <Icon {...dragHandleProps}>drag_indicator</Icon>
                </Grid>
              </Grid>
            }
          >
            {group.subGroups?.map((msGrp, index) => (
              <Draggable key={msGrp.id} draggableId={String(msGrp.id)} index={index}>
                {(dragProvided, dragSnapshot) => (
                  <Grid item {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
                    <GroupTreeItem key={msGrp.id} path={path} group={msGrp} dragHandleProps={dragProvided.dragHandleProps} />
                  </Grid>
                )}
              </Draggable>
            ))}
            {group.milestones?.map((ms, index) => (
              <Draggable key={ms.id} draggableId={`ms-${ms.id}`} index={index}>
                {(dragProvided, dragSnapshot) => (
                  <Grid item {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
                    <MilestoneTreeItem key={ms.id} path={path} group={group} milestone={ms} dragHandleProps={dragProvided.dragHandleProps} />
                  </Grid>
                )}
              </Draggable>
            ))}
          </TreeItem>
        )}
      </Droppable>
    </DragDropContext>
  );
};
