import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  TextField
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FEValues, FValues, QValues } from '../../common';
import { FormLayoutType, FormOperation, FormQuestionType } from '../../dto';
import { DialogController } from '../../hooks';
import { LanguageString } from '../Common';

const layoutDir = (layoutType?: FormLayoutType) => (layoutType === FormLayoutType.ROWS ? 'column' : 'row');
type QuestionPreview = { question: QValues; field: FieldInputProps<string> };

// TODO: Move form styles to the database both here and in the DynamicForm component
const useStyles = makeStyles(theme =>
  createStyles({
    formTitle: {
      display: 'block',
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: '10px'
    },
    sectionTitle: {
      display: 'block',
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: '10px'
    }
  })
);

export function isDependsOnSatisfied2(field: FieldInputProps<string>, question: QValues) {
  const { value: sourceVal } = field;
  const depend = question.dependsOn?.[0];

  if (depend != null) {
    switch (depend.operation) {
      case FormOperation.SIMPLE:
        return sourceVal != null;
      case FormOperation.EQUAL:
        return String(sourceVal) === depend.operand;
      default:
        return false;
    }
  } else return false;
}

export function isDependsOnSatisfied(values: FValues, question: QValues) {
  if (question.dependsOn == null || question.dependsOn.length === 0) {
    return true;
  }

  for (const depend of question.dependsOn) {
    const sourceVal = values && values.previewVals[depend.source.id];
    switch (depend.operation) {
      case FormOperation.SIMPLE:
        return sourceVal != null;
      case FormOperation.EQUAL:
        return String(sourceVal) === depend.operand;
      default:
        return false;
    }
  }

  return false;
}

const BooleanQuestionPreview: React.FC<QuestionPreview> = ({ question, field }) => (
  <FormControl fullWidth>
    <FormControlLabel control={<Checkbox {...field} />} label={<LanguageString languageString={question.text} />} />
    {question.hint && (
      <FormHelperText id={`${question.id}-helper-text`}>
        <LanguageString languageString={question.hint} />
      </FormHelperText>
    )}
  </FormControl>
);

const DateQuestionPreview: React.FC<QuestionPreview> = ({ question }) => {
  const [date, setDate] = useState<Date | null>(null);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker fullWidth label={<LanguageString languageString={question.text} />} value={date} onChange={setDate} />
    </MuiPickersUtilsProvider>
  );
};

const TextQuestionPreview: React.FC<QuestionPreview> = ({ question, field }) => (
  <TextField
    {...field}
    label={<LanguageString languageString={question.text} />}
    multiline={question.rows != null}
    type={question.questionType === FormQuestionType.PASSWORD ? 'password' : 'text'}
    rows={question.rows}
    fullWidth
  />
);

const SelectQuestionPreview: React.FC<QuestionPreview> = ({ question, field }) => (
  <TextField
    {...field}
    label={<LanguageString languageString={question.text} />}
    SelectProps={{ multiple: question.questionType === FormQuestionType.MULTISELECT }}
    select
    fullWidth
  >
    {question.options?.map(o => (
      <MenuItem key={o.id} value={o.id}>
        {o.text}
      </MenuItem>
    ))}
  </TextField>
);

const DependentQuestionPreview: React.FC<{ question: QValues; dependsOnName: string }> = ({ question, dependsOnName }) => {
  const [field] = useField(dependsOnName);

  return isDependsOnSatisfied2(field, question) ? <IndependentQuestionPreview question={question} /> : null;
};

const IndependentQuestionPreview: React.FC<{ question: QValues }> = React.memo(({ question }) => {
  const [field] = useField(`previewVals.${question.id}`);

  const props = { question, field };

  switch (question.questionType) {
    case FormQuestionType.TEXT:
    case FormQuestionType.PASSWORD:
      return <TextQuestionPreview {...props} />;
    case FormQuestionType.SELECT:
    case FormQuestionType.MULTISELECT:
      return <SelectQuestionPreview {...props} />;
    case FormQuestionType.DATE:
    case FormQuestionType.DATETIME:
    case FormQuestionType.DUEDATE:
    case FormQuestionType.TIME:
      return <DateQuestionPreview {...props} />;
    case FormQuestionType.INFORMATION:
      return <LanguageString languageString={question.text} />;
    case FormQuestionType.BOOLEAN:
      return <BooleanQuestionPreview {...props} />;
    default:
      return <LanguageString languageString={question.text} />;
  }
});

const QuestionPreview: React.FC<{ question: QValues }> = ({ question }) => {
  return question.dependsOn?.length ? (
    <DependentQuestionPreview question={question} dependsOnName={`previewVals.${question.dependsOn![0].source.id}`} />
  ) : (
    <IndependentQuestionPreview question={question} />
  );
};

const ElementPreview: React.FC<{ element: FEValues }> = React.memo(({ element }) => {
  const classes = useStyles();

  return (
    <Grid className="element-root" container item direction="column">
      {element.title && (
        <Grid className="element-title-container" item>
          <LanguageString languageString={element.title} className={classes.sectionTitle} />
        </Grid>
      )}
      {element.instructions && (
        <Grid className="element-instructions-container" item>
          <LanguageString languageString={element.instructions} />
        </Grid>
      )}
      <Grid className="element-children-container" container item direction={layoutDir(element.layoutType)} wrap="nowrap" spacing={2}>
        {element.elements?.map(el => (
          <Grid key={el.id} className="element-child-container" item xs>
            <ElementPreview element={el} />
          </Grid>
        ))}
        {!!element.questions?.length &&
          element.questions?.map(q => (
            <Grid key={q.id} className="element-question-container" item xs>
              <QuestionPreview question={q} />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
});

// TODO: Should convert the form editor format to the graphql Form format and use the actual DynamicForm component for previews
export const FormPreview: React.FC = () => {
  const formik = useFormikContext<FValues>();
  const { title, layoutType, elements } = formik.values;
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      {title && <LanguageString languageString={title} className={classes.formTitle} />}
      <Grid className="form-root" container direction={layoutDir(layoutType)} wrap="nowrap" spacing={2}>
        {elements?.map(el => (
          <ElementPreview key={el.id} element={el} />
        ))}
      </Grid>
    </Grid>
  );
};

export const FormPreviewDialog: React.FC<DialogController['props']> = props => (
  <Dialog {...props} maxWidth="lg" fullWidth={true}>
    <DialogTitle>Form Title</DialogTitle>
    <DialogContent dividers>
      <FormPreview />
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>Submit</Button>
    </DialogActions>
  </Dialog>
);
