import { tmpId } from '../../contexts';
import * as DTO from '../../dto';

export type MTSActivityType = {
  __type: 'MTSActivityType';
  id: number;
  attachmentFormEnabled: boolean;
  code: string;
  secondaryCode: string | undefined;
  tertiaryCode: string | undefined;
  description: string;
  enabled: boolean;
  hidden: boolean;
  formId?: number;
  name?: DTO.LanguageString;
  nameId?: number;
  noteRequired: boolean;
  secondaryFormId?: number;
  sortIndex: number;
  wpr: boolean;
  canEditEntryData: boolean;
  canEditHours: boolean;
  maxDailyHours?: number;
  maxEntryHours?: number;
  maxNumberOfEntries?: number;
  childGenerateTypesEnabled?: boolean;
  filterCustomPropertyValue?: string;
  appearsInReviewAllHours?: boolean;
  defaultHours?: number;
  mtsInRoadmapEnabled?: boolean;
  externalApprovalFormId?: number;
  reqExternalApprovalFormId?: number;
};

export const convertMAT = (mat: DTO.MTSActivityType): MTSActivityType => {
    return {
      __type: 'MTSActivityType',
      id: mat.id,
      attachmentFormEnabled: mat.attachmentFormEnabled,
      code: mat.code,
      secondaryCode: mat.secondaryCode,
      tertiaryCode: mat.tertiaryCode,
      description: mat.description,
      enabled: mat.enabled,
      hidden: mat.hidden,
      formId: mat.formId ?? undefined,
      name: mat.name,
      nameId: mat.name?.group?.id,
      noteRequired: mat.noteRequired,
      secondaryFormId: mat.secondaryFormId ?? undefined,
      sortIndex: mat.sortIndex,
      wpr: mat.wpr,
      canEditEntryData: mat.canEditEntryData,
      canEditHours: mat.canEditHours,
      maxDailyHours: mat.maxDailyHours,
      maxEntryHours: mat.maxEntryHours,
      maxNumberOfEntries: mat.maxNumberOfEntries,
      childGenerateTypesEnabled: mat.childGeneratedTypesEnabled,
      filterCustomPropertyValue: mat.filterCustomPropertyValue ?? undefined,
      appearsInReviewAllHours: mat.appearsInReviewAllHours,
      defaultHours: mat.defaultHours,
      mtsInRoadmapEnabled: mat.mtsInRoadmapEnabled,
      externalApprovalFormId: mat.externalApprovalFormId,
      reqExternalApprovalFormId: mat.reqExternalApprovalFormId
  };
};

export const createMAT = (args?: Partial<MTSActivityType>): MTSActivityType => {
  return {
    __type: 'MTSActivityType',
    id: tmpId.nextId,
    attachmentFormEnabled: args?.attachmentFormEnabled ?? false,
    code: args?.code ?? 'NewCode',
    secondaryCode: args?.secondaryCode,
    tertiaryCode: args?.tertiaryCode,
    description: args?.description ?? 'NewDesc',
    enabled: args?.enabled ?? true,
    hidden: args?.hidden ?? false,
    name: args?.name,
    nameId: args?.nameId ?? undefined,
    noteRequired: args?.noteRequired ?? false,
    sortIndex: args?.sortIndex ?? 0,
    wpr: args?.wpr ?? false,
    canEditEntryData: args?.canEditEntryData ?? true,
    canEditHours: args?.canEditHours ?? true,
    maxDailyHours: args?.maxDailyHours,
    maxEntryHours: args?.maxEntryHours,
    maxNumberOfEntries: args?.maxNumberOfEntries,
    defaultHours: args?.defaultHours
  };
};
