import gql from 'graphql-tag';

export const languageStringFrag = gql`
  fragment LangStringFrag on LanguageString {
    id
    text
    isHtml
    group {
      id
      groupName
      resourceName
    }
  }
`;

export const languageStringMinFrag = gql`
  fragment LangStringMinFrag on LanguageString {
    id
    text
    isHtml
  }
`;

export default languageStringFrag;
