import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { InvalidSite } from '../../components/InvalidSite';

export interface ApplicationInsightsContextProps {
  insights?: ApplicationInsights;
  reactPlugin?: ReactPlugin;
  telemetryId?: string;
  setTelemetryId: (tel: string) => void;
  username?: string;
  setUsername: (u: string) => void;
}

export const ApplicationInsightsContext = React.createContext<ApplicationInsightsContextProps>({
  setTelemetryId: (t) => undefined,
  setUsername: (u) => undefined
});
export const ApplicationInsightsProvider: React.FC<{ instrumentationKey?: string }> = ({ instrumentationKey, children }) => {
  const history = useHistory();
  const [appInsights, setAppInsights] = useState<ApplicationInsights | undefined>(undefined);
  const [telemetryId, setTelemetryId] = useState<string | undefined>();
  const [username, setUsername] = useState<string | undefined>();
  const reactPlugin = useMemo(() => new ReactPlugin(), []);

  const initialize = (instrumentationKey: string, browserHistory: History) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
    }

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory
          }
        }
      }
    });

    appInsights.loadAppInsights();

    return appInsights;
  };

  useEffect(() => {
    if (instrumentationKey) {
      if (!appInsights) {
        const appInsights = initialize(instrumentationKey, history);
        setAppInsights(appInsights);
      } else if (telemetryId) {
        appInsights.context.user.id = telemetryId;
      }
    }
  }, [telemetryId, instrumentationKey]);

  return (
    <ApplicationInsightsContext.Provider
      value={{
        insights: appInsights,
        reactPlugin,
        username,
        telemetryId,
        setUsername,
        setTelemetryId
      }}
    >
      <AppInsightsErrorBoundary onError={() => <InvalidSite />} appInsights={reactPlugin}>
        {children}
      </AppInsightsErrorBoundary>
    </ApplicationInsightsContext.Provider>
  );
};
export default ApplicationInsightsProvider;

export const useInsights = () => {
  const insightsCtx = useContext(ApplicationInsightsContext);
  const insights = insightsCtx.insights;

  const trackException = (message: string, level: SeverityLevel) => {
    if (insights) {
      insights.trackException({ error: new Error(message), severityLevel: level });
    } else {
      console.log(`couldn't log to insights right now`);
    }
  };

  const trackTrace = (message: string, level: SeverityLevel) => {
    if (insights) {
      insights.trackTrace({ message: message, severityLevel: level }, { username: insightsCtx.username });
    } else {
      console.log(`couldn't log to insights right now`);
    }
  };

  const trackMetric = (name: string, average: number) => {
    if (insights) {
      insights.trackMetric({ name, average }, { username: insightsCtx.username });
    } else {
      console.log(`couldn't log to insights right now`);
    }
  };

  const trackEvent = (name: string) => {
    if (insights) {
      insights.trackEvent({ name }, { username: insightsCtx.username });
    } else {
      console.log(`couldn't log to insights right now`);
    }
  };

  const trackPageView = (page: string) => {
    if (insights) {
      insights.trackPageView({ name: page });
    } else {
      console.log(`couldn't log to insights right now`);
    }
  };

  return { trackException, trackTrace, trackMetric, trackEvent, trackPageView };
};
