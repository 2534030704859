import { ApolloClient, ApolloLink, ApolloProvider, defaultDataIdFromObject, gql, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import React, { useContext, useEffect, useState } from 'react';
import { Role, User } from '../../dto';
import Auth from '../../helpers/auth';
import Config from '../../helpers/config';
import { updateCurrentRole } from '../../hooks';
import { typeDefs } from '../../resolvers';
import { SiteStatusContext } from '../SiteProvider';

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    User: {
      fields: {
        currentRole: {
          read: (cur, { cache, ...data }) => {
            if (cur == null) {
              const user = (cache.readQuery({ query: gql`query Me {me {roles}}` }) as { me: User} | undefined)?.me;
              if (user == null) throw new Error('No user in cache');
              const roles = user.roles;
              if (!roles?.length) throw new Error('User has no roles');
              const lastRoleUsed = localStorage.getItem('last_role_used') as Role | undefined;
              const cachedRole = (cache.readQuery({ query: gql`query Me {me {roles}}` }) as { me: User })?.me.roles?.[0];
              const storedRole = lastRoleUsed ?? cachedRole;
              const newRole = storedRole != null && roles.includes(storedRole) ? storedRole : roles[0];
              if (newRole !== lastRoleUsed || newRole !== cachedRole) updateCurrentRole(cache, { user, role: newRole });

              return newRole;
            }

            return cur;
          }
        }
      }
    }
  },
  dataIdFromObject(obj) {
    if (obj.__typename === 'LanguageStringGroup') {
      if (obj.resourceName) {
        if (obj.groupName) {
          return `${obj.__typename}:${obj.groupName}:${obj.resourceName}`;
        } else {
          return `${obj.__typename}:${obj.resourceName}`;
        }
      }
    }
    return defaultDataIdFromObject(obj);
  }
  // TODO: Upgrade Fix Needed
  // See https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
  // ,
  // cacheRedirects: {
  //   Query: {
  //     getStringGroup(_, { groupName, resourceName }, { getCacheKey }) {
  //       return getCacheKey({ __typename: 'LanguageStringGroup', id: `${groupName}:${resourceName}` });
  //     }
  //   }
  // }
});

// TODO: Needs to be able to connect to GraphQL in production
const authLink = setContext((_, { headers }) => {
  const token = Auth.token();
  return {
    headers: {
      ...headers,
      authorization: token ? `Session ${token}` : ''
    }
  };
});

export const ApolloCustomProvider: React.FC = ({ children }) => {
  const siteStatCtx = useContext(SiteStatusContext);
  const [stateClient, setStateClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    Config.shared().gqlConnectionURL((gqlUrl: string) => {
      const link = ApolloLink.from([
        onError(data => {
          console.log(`[ON ERROR]: `, data);
          siteStatCtx.setStatus(data);

          if (data.graphQLErrors) {
            data.graphQLErrors.forEach(error => {
              if (error.extensions && 'code' in error.extensions && error.extensions.code === 'UNAUTHENTICATED') {
                Auth.logout();
              }
            });
          }
          if (data.networkError) {
            console.error(`[Network Error] ${data.networkError} (Online state: ${navigator.onLine})`);
          }
        }),
        createUploadLink({
          uri: gqlUrl,
          credentials: 'same-origin'
        })
      ]);

      const client = new ApolloClient({
        cache,
        link: authLink.concat(link),
        typeDefs
      });

      const isLoggedInQuery = gql`
        query isLoggedIn {
          isLoggedIn @client
        }
      `;

      client.writeQuery({ query: isLoggedInQuery, data: { isLoggedIn: Auth.isLoggedIn() } });

      client.onResetStore(async () => client.writeQuery({ query: isLoggedInQuery, data: { isLoggedIn: Auth.isLoggedIn() } }));

      setStateClient(client);
    });
  }, []);

  if (!stateClient) return null;

  return (
    <ApolloProvider client={stateClient}>
      {children}
    </ApolloProvider>
  );
};
