import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }

  extend type User {
    currentRole: Role
  }

  extend type Mutation {
    updateCurrentRole(role: Role!): Boolean
  }
`;
