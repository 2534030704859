import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { getYear } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { MTSContext } from '../../../contexts';
import MTSHelpers from '../../../helpers/mtsHelpers';
import { useClasses } from '../../../hooks';
import { GeneralErrorMessage } from '../../Common';
import { MTSToolBar } from '../MTSToolBar';
import CalendarYearViewCell from './CalendarYearViewCell';

export const CalendarYearView: React.FC = () => {
  const mtsCtx = useContext(MTSContext);
  const classes = useClasses();
  const year = getYear(mtsCtx.date);
  const monthCounter = 0;

  const yearMtsPeriods = useMemo(() => {
    if (mtsCtx.mtsPeriods.length > 0) {
      return mtsCtx.mtsPeriods
        .filter(p => {
          const periodDates = MTSHelpers.getPeriodDates(p);
          const startYear = getYear(periodDates.startDate);
          const endYear = getYear(periodDates.endDate);

          return startYear === year || endYear === year;
        })
        .sort((a, b) => MTSHelpers.dateFromString(a.endDate).getTime() - MTSHelpers.dateFromString(b.endDate, true).getTime());
    }

    return [];
  }, [mtsCtx.mtsPeriods, mtsCtx.date]);

  const calendarRows = useMemo(() => {
    const rows = [];

    if (mtsCtx.mtsPeriods && mtsCtx.mtsPeriods.length > 0) {
      for (const period of yearMtsPeriods) {
        rows.push(
          <Grid item xs={12} md={4} key={monthCounter}>
            <CalendarYearViewCell period={period} date={MTSHelpers.getPeriodDates(period).startDate} onDateClick={() => undefined} />
          </Grid>
        );
      }
    } else {
      for (let i = 0; i < 12; i++) {
        rows.push(
          <Grid item xs={12} md={4} key={monthCounter}>
            <CalendarYearViewCell date={new Date(year, i, 1, 0, 0, 0)} onDateClick={() => undefined} />
          </Grid>
        );
      }
    }

    return rows;
  }, [mtsCtx.mtses, yearMtsPeriods]);

  if (mtsCtx.error) {
    return <GeneralErrorMessage message={mtsCtx.error.message} />;
  }

  return (
    <Paper>
      <MTSToolBar />

      <Grid container className={classes.mtsCalendarYear}>
        <Grid item xs={12}>
          {mtsCtx.loading && <CircularProgress color="primary" />}

          {!mtsCtx.loading && (
            <Grid container className={classes.mtsYearViewRow}>
              {calendarRows}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
export default CalendarYearView;
