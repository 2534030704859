import { useEffect, useState } from 'react';
import { ExternalMTS, getTRPCClient } from './getTRPCClient';

const getExternalMTS = async (token: string) => {
  const client = getTRPCClient();
  return client.query('externalMTS', { token });
};

export const useExternalMTS = (token: string) => {
  const [{ mts, loading }, setMts] = useState<{ loading: boolean, mts?: ExternalMTS }>({ loading: true });

  useEffect(() => {
    void getExternalMTS(token).then(rtnMts => {
      rtnMts ? setMts({ mts: rtnMts, loading: false }) : setMts({ loading: false });
    });
  }, []);

  return { mts, loading };
};
