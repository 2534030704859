import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Icon
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { useMTSPeriods, useClasses } from '../../hooks';
import { MTSPeriodAddDialog } from './MTSPeriodAddDialog';
import * as DTO from '../../dto';
import MTSHelpers from '../../helpers/mtsHelpers';

export const MTSPeriodEditor: React.FC = () => {
  const classes = useClasses();
  const { data } = useMTSPeriods({ variables: { shouldFilter: false } });
  const [selectedPeriod, setSelectedPeriod] = useState<DTO.MTSPeriod>();
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const plusButtonClicked = () => {
    setSelectedPeriod(undefined);
    toggleDialog();
  };

  const clickedRow = (period: DTO.MTSPeriod) => {
    setSelectedPeriod(period);
    toggleDialog();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <LanguageString groupName="MTS_PERIOD_EDITOR" resourceName="NAME" defaultText="MTS Periods" />
            }
            action={
              <IconButton onClick={plusButtonClicked}>
                <Icon>add_circle</Icon>
              </IconButton>
            }
          />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <LanguageString groupName="MTS_PERIOD_EDITOR" resourceName="ID" defaultText="ID" />
                  </TableCell>
                  <TableCell>
                    <LanguageString groupName="MTS_PERIOD_EDITOR" resourceName="START_DATE" defaultText="Start Date" />
                  </TableCell>
                  <TableCell>
                    <LanguageString groupName="MTS_PERIOD_EDITOR" resourceName="END_DATE" defaultText="End Date" />
                  </TableCell>
                  <TableCell>
                    <LanguageString groupName="MTS_PERIOD_EDITOR" resourceName="FILTER" defaultText="Filter" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.site.mtsPeriods.map(period => {
                  return (
                    <TableRow
                      className={classes.clickable}
                      key={`mts_period_${period.id}`}
                      onClick={() => clickedRow(period)}
                    >
                      <TableCell>{period.id}</TableCell>
                      <TableCell>{MTSHelpers.getPeriodDates(period).startDate.toString().substr(0, 10)}</TableCell>
                      <TableCell>{MTSHelpers.getPeriodDates(period).endDate.toString().substr(0, 10)}</TableCell>
                      <TableCell>{period.customPropertyValue}</TableCell>
                    </TableRow>
                  );
                }
              )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>

      <MTSPeriodAddDialog period={selectedPeriod} open={dialogOpen} toggle={toggleDialog} />
    </Grid>
  );
};
