import React, { useContext, useMemo, useState } from 'react';
import { SwapVerticalCircleRounded, CheckCircle, CloseRounded } from '@material-ui/icons';
import { LanguageString, DynamicForm } from '../../components';
import { StudentContext, useLinks } from '../../contexts';
import { useMTSUserActivityTypes } from '../../hooks';
import { UserMTSActivityType } from '../../dto';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  useTheme,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider
} from '@material-ui/core';

const MTSInRoadmapDialog: React.FC<{ activity: UserMTSActivityType, open: boolean, toggle: () => void }> = ({ activity, open, toggle }) => {
  const studentCtx = useContext(StudentContext);

  const mtsRoutes = useLinks({
    items: [{}],
    paramMap: {},
    routeName: 'mts'
  });

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={toggle}>
      <DialogTitle style={{ fontStyle: activity.expiring ? 'italic' : 'normal' }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item>
                {activity.expiring ? <CheckCircle style={{ color: 'green' }} /> : <SwapVerticalCircleRounded style={{ color: '#f1c40f' }} />}
              </Grid>
              <Grid item>
                {activity.name.text}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={toggle}><CloseRounded /></IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {mtsRoutes && mtsRoutes.length > 0 && (
              <Button component={RouterLink} to={mtsRoutes[0].path} color="primary" variant="contained">
                <LanguageString groupName="ROADMAP" resourceName="EDIT_MTS" defaultText="Edit MTS" />
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {activity.formSubmission && (
            <Grid item xs={12}>
              <DynamicForm
                formId={activity.parentActivityType.formId}
                formSubmissionId={activity.formSubmission?.id}
                userId={studentCtx?.student.id}
                autoloadFormSubmission={false}
                showSubmitButton={false}
                renderFormTitle={false}
                disableCache={true}
                shouldOnlySubmitIfDirty={false}
                disabled={true}
                isReadOnly={true}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const MTSInRoadmapLine: React.FC<{ activity: UserMTSActivityType, selected: (a: UserMTSActivityType) => void }> = ({ activity, selected }) => {
  const expiring = ('expiring' in activity) ? (activity as UserMTSActivityType).expiring : false;

  return (
    <ListItem button onClick={() => selected(activity)}>
      <ListItemIcon>
        {expiring ? <CheckCircle style={{ color: 'green' }} /> : <SwapVerticalCircleRounded style={{ color: '#f1c40f' }} />}
      </ListItemIcon>
      <ListItemText style={{ fontStyle: expiring ? 'italic' : 'normal' }}><LanguageString languageString={activity.name} /></ListItemText>
    </ListItem>
  );
};

export const MTSInRoadmap: React.FC = () => {
  const theme = useTheme();
  const studentCtx = useContext(StudentContext);
  const [selectedActivity, setSelectedActivity] = useState<UserMTSActivityType | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);

  const activityTypesResult = useMTSUserActivityTypes({
    variables: {
      includeDisabled: false,
      includeExpiredGeneratedTypes: false,
      userId: studentCtx?.student.id
    },
    fetchPolicy: 'cache-and-network'
  });
  const activites = useMemo(() => {
    let result: UserMTSActivityType[] = [];

    if (activityTypesResult.data?.mtsUserActivityTypes) {
      for (const activity of activityTypesResult.data.mtsUserActivityTypes) {
        if ((activity.mtsInRoadmapEnabled === true) && activity.generatedChildTypes) {
          result = activity.generatedChildTypes.concat(result);
        }
      }
    }

    return result;
  }, [activityTypesResult.data]);

  console.log(`activities: `, activites);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
    if (dialogOpen) {
      setSelectedActivity(undefined);
    }
  };

  const handleSelectActivity = (a: UserMTSActivityType) => {
    setSelectedActivity(a);
    setDialogOpen(true);
  };

  if (activites.length <= 0) return null;

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Box color="primary.contrastText">
                <LanguageString groupName="ROADMAP" resourceName="MTS" defaultText="MTS" />
              </Box>
            }
            style={{ backgroundColor: theme.palette.primary.main }}
          />
          <List>
            {activites.map(activity => (
              <MTSInRoadmapLine activity={activity} selected={handleSelectActivity} />
            ))}
          </List>
        </Card>

        {selectedActivity && (
          <MTSInRoadmapDialog activity={selectedActivity} open={dialogOpen} toggle={toggleDialog} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};
export default MTSInRoadmap;
