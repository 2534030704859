import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Roadmap } from '../../dto';
import { roadmapFrag } from './fragments';

type Variables = { userId: number, roadmapId: number };
type Results = {
  user?: {
    roadmapById?: Roadmap;
  }
};

export const useRoadmapById = (options?: QueryHookOptions<Results, Variables>) => {
  const query = gql`
    ${roadmapFrag}
    query getRoadmapById($userId: Int!, $roadmapId: Int!) {
      user(id: $userId) {
        id
        roadmapById(id: $roadmapId) {
          ...RoadmapFrag
        }
      }
    }
  `;

  return useQuery(query, options);
};

export default useRoadmapById;
