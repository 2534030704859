import React, { useContext } from 'react';
import { StudentContext } from '../../contexts';
import { DisplayPath } from './DisplayPath';

export const ActionStepLibrary: React.FC = () => {
  const studentCtx = useContext(StudentContext);

  return studentCtx?.path != null ? <DisplayPath path={studentCtx.path} /> : <div>Loading...</div>;
};

export default ActionStepLibrary;
