import gql from 'graphql-tag';

export const dependsOnFrag = gql`
  fragment DependsOnFrag on FormItemEnableRule {
    operation
    operand
    name
    source {
      id
    }
    targetForm {
      id
    }
    targetElement {
      id
    }
    targetQuestion {
      id
    }
  }
`;

export default dependsOnFrag;
