import React, { useContext, useState } from 'react';
import {
  Card, CardContent, CardHeader, Divider, Grid, Table,
  TableBody, TableCell, TableHead, TableRow, TextField, Icon, TablePagination, CircularProgress, Box
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { useJobs } from '../../hooks';
import { StudentContext } from '../../contexts';

const JobLink: React.FC<{ url: string, children: React.ReactNode }> = ({ url, children }) => {
  return <a href={url} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>{children}</a>;
};

export const CareerOneStop: React.FC = () => {

  const rowsPerPageOptions = [10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

  const [keyword, setKeyword] = useState<string>('');
  const [days, setDays] = useState<number>(30);
  const [radius, setRadius] = useState<number>(25);
  const [zip, setZip] = useState<string>('');

  const studentCtx = useContext(StudentContext);
  const { loading, data } = useJobs({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      offset: page * rowsPerPage,
      pageSize: rowsPerPage,
      days: days,
      radius: radius,
      keyword: keyword,
      zip: zip?.toString()
    }
   });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={
            <LanguageString groupName="CAREER_ONE_STOP" resourceName="Title" defaultText="Career One Stop Jobs" />
          } />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={<LanguageString groupName="CAREER_ONE_STOP" resourceName="SEARCH_KEYWORD" defaultText="Search Keyword" />}
                  value={keyword}
                  onChange={e => setKeyword(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={<LanguageString groupName="CAREER_ONE_STOP" resourceName="SEARCH_RADIUS" defaultText="Search Radius (in miles)" />}
                  value={radius}
                  onChange={e => setRadius(Number(e.currentTarget.value))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={<LanguageString groupName="CAREER_ONE_STOP" resourceName="SEARCH_AGE" defaultText="Job Age (in days)" />}
                  value={days}
                  onChange={e => setDays(Number(e.currentTarget.value))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={<LanguageString groupName="CAREER_ONE_STOP" resourceName="SEARCH_ZIP" defaultText="Job Location (zip code)" />}
                  value={zip}
                  onChange={e => setZip(e.currentTarget.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {loading ? (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress color="primary" />
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <LanguageString groupName="CAREER_ONE_STOP" resourceName="JOB_TITLE" defaultText="Job Title" />
                        </TableCell>
                        <TableCell>
                          <LanguageString groupName="CAREER_ONE_STOP" resourceName="JOB_COMPANY" defaultText="Company" />
                        </TableCell>
                        <TableCell>
                          <LanguageString groupName="CAREER_ONE_STOP" resourceName="JOB_LOCATION" defaultText="Location" />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data?.user.jobs.jobs ?? []).map(job => (
                        <TableRow key={`job_${job.id}`}>
                          <TableCell>
                            <JobLink url={job.url}>{job.jobTitle}</JobLink>
                          </TableCell>
                          <TableCell>
                            <JobLink url={job.url}>{job.company}</JobLink>
                          </TableCell>
                          <TableCell>
                            <JobLink url={job.url}>{job.location}</JobLink>
                          </TableCell>
                          <TableCell>
                            <JobLink url={job.url}>
                              <Icon color="primary">arrow_forward</Icon>
                            </JobLink>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={data?.user.jobs.totalCount ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(_, p) => setPage(p)}
                    onChangeRowsPerPage={(e) => {
                      setRowsPerPage(Number(e.target.value));
                      setPage(0);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
