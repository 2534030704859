import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { RecordOfContact, RecordOfContactInput } from '../../dto';
import { QueryType } from '../../helpers/query';
import { rocFrag } from './fragments';

type Result = { saveRoc: QueryType<RecordOfContact> };
type Variables = { input: RecordOfContactInput };
export const useSaveRoc = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${rocFrag}

    mutation saveROC($input: RecordOfContactInput!) {
      saveRoc(input: $input) {
        ...rocFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
