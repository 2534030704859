import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { mtsSubmit: boolean };
type Variables = { mtsId: number, formSubmissionId: number, pin: string };
export const useSaveMTS = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation partcipantSaveMTS($mtsId: Int!, $formSubmissionId: Int!, $pin: String!) {
      mtsSubmit(mtsId: $mtsId, formSubmissionId: $formSubmissionId, pin: $pin)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useSaveMTS;
