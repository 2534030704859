import gql from 'graphql-tag';
import { languageStringFrag } from './languageStringFrag';

export const userMtsActivityTypeFrag = gql`
  ${languageStringFrag}

  fragment UserMTSActivityTypeFrag on UserMTSActivityType {
    id
    name {
      ...LangStringFrag
    }
    expirationDate
    closed
    expiring
    parentActivityType {
      id
      name {
        ...LangStringFrag
      }
      description
      code
      noteRequired
      childGeneratedTypesEnabled
      wpr
      enabled
      appearsInReviewAllHours
      formId
      secondaryFormId
      attachmentFormEnabled
      defaultHours
      canEditHours
      canEditEntryData
      printFileSchema {
        ...PrintFileSchemaFrag
      }
    }
    user {
      id
    }
    formSubmission {
      id
    }
    secondaryFormSubmission {
      id
    }
  }
`;
