import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MTSPeriod, MTSPeriodInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { mtsPeriodFrag } from './fragments';

type Result = { saveMTSPeriod: QueryType<MTSPeriod> };
type Variables = { input: MTSPeriodInput };
export const useSaveMTSPeriod = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${mtsPeriodFrag}

    mutation saveMTSPeriod($input: MTSPeriodInput!) {
      saveMTSPeriod(input: $input) {
        ...MTSPeriodFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
