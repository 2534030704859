import gql from 'graphql-tag';

export const caseLoadMtsPeriodFrag = gql`
  fragment CaseLoadMTSPeriodFrag on MTSPeriod {
    id
    startDate
    endDate
    customPropertyValue
  }
`;
