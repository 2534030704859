import { useApolloClient } from '@apollo/client';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { DefaultLoginOption, FormAnswerInput, SubmitFormInput } from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { SiteContext } from '../../contexts';
import { LoginFailureReason, SSOConfig } from '../../dto';
import Auth from '../../helpers/auth';
import Config from '../../helpers/config';
import { useClasses, useLoginWithPassword } from '../../hooks';
import { LanguageString } from '../Common';
import { ForgotUsernamePasswordDialog } from './ForgotUsernamePasswordDialog/ForgotUsernamePasswordDialog';
import { ResetPasswordDialog } from './ForgotUsernamePasswordDialog/ResetPasswordDialog';
import { ResetUsernameDialog } from './ForgotUsernamePasswordDialog/ResetUsernameDialog';
import { SignupFormDialog } from './SignupFormDialog';

interface LoginFormSchema {
  username: string;
  password: string;
  generalError: string;
}

export const LoginForm: React.FC = () => {
  const classes = useClasses();
  const [gqlUrl, setGqlUrl] = useState<string | undefined>(undefined);
  const [login] = useLoginWithPassword();
  const history = useHistory();
  const location = useLocation<{ from?: { pathname?: string } }>();
  const client = useApolloClient();
  const siteCtx = useContext(SiteContext);
  const loginMatch = useRouteMatch<{ token: string }>('/login');
  const match = useRouteMatch<{ token: string }>('/login/password/:token?');
  const usernameMatch = useRouteMatch<{ token: string }>('/login/username/:token?');
  const ssoConfigMatch = useRouteMatch<{ config?: string }>('/login/ssoconfig/:config?');
  const ssoMatch = useRouteMatch<{ token?: string }>('/login/sso/:token?');
  const signupMatch = useRouteMatch<{ token: string }>('/signup');
  const signupSSOMatch = useRouteMatch<{ config: string }>('/signup/ssoconfig/:config');
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(match?.params.token && match.params.token.length > 0 ? true : false);
  const [resetUsernameOpen, setResetUsernameOpen] = useState(usernameMatch?.params.token && usernameMatch.params.token.length > 0 ? true : false);
  const ssoConfig: SSOConfig | undefined = useMemo(() => {
    const configName = ssoConfigMatch?.params.config ?? signupSSOMatch?.params.config;
    if (!configName && siteCtx.site?.defaultLogin === DefaultLoginOption.UsernamePassword) return undefined;

    if (siteCtx.site?.ssoConfigs?.length) {
      let config = siteCtx.site.ssoConfigs.find(s => s.default) ?? siteCtx.site.ssoConfigs[0];
      if (configName?.length) {
        const tmp = siteCtx.site.ssoConfigs.find(s => s.name === configName);
        if (tmp) {
          config = tmp;
        }
      }

      return config;
    }

    return undefined;
  }, [ssoConfigMatch, siteCtx.site?.ssoConfigs, loginMatch]);
  const params = useMemo(() => {
    const p: { [key: string]: string } = {};

    const qs = window.location.search.replace('?', '');
    const keyValPairs = qs.split('&');
    for (const pair of keyValPairs) {
      const keyVal = pair.split('=');
      if (keyVal.length > 1) {
        p[keyVal[0]] = keyVal[1];
      }
    }

    return p;
  }, [window.location.search]);
  const ssoSignupFormSubmission = useMemo(() => {
    if (params && ssoConfig) {
      const mappings = ssoConfig.formMappings;
      if (mappings && mappings.length > 0) {
        const input: SubmitFormInput = {
          formId: mappings[0].form.id,
          answers: mappings.map(m => {
            return {
              questionId: m.formQuestion.id,
              text: params[m.propName]
            } as FormAnswerInput;
          })
        };

        return input;
      }
    }

    return undefined;
  }, [params, ssoConfig]);

  const initialValues = {
    username: '',
    password: '',
    generalError: ''
  };

  const LoginValidation = Yup.object().shape({
    username: Yup.string().required('* Required'),
    password: Yup.string().required('* Required')
  });

  async function handleAuthenticationWithToken(token: string) {
    Auth.setToken(token);
    await client.cache.reset();
    await client.resetStore();

    const state = location.state;
    const path = state?.from?.pathname ?? '/';
    if (path && path !== '/login') {
      history.push(path);
    } else {
      history.push('/');
    }
  }

  async function handleLogin(values: LoginFormSchema, formikHelpers: FormikHelpers<LoginFormSchema>) {
    formikHelpers.setSubmitting(true);

    await login({
      variables: { username: values.username, password: values.password },
      update: async (cache, result) => {
        formikHelpers.setSubmitting(false);

        if (!result.data) {
          formikHelpers.setFieldValue('generalError', 'An unknown error occured. Please try again.');
        } else {
          const { data } = result;

          if (data && data.loginWithPassword.sessionToken) {
            await handleAuthenticationWithToken(data.loginWithPassword.sessionToken);
          } else {
            if (data.loginWithPassword.reason) {
              switch (data.loginWithPassword.reason) {
                case LoginFailureReason.badUsernameOrPassword:
                  formikHelpers.setFieldValue('generalError', 'Invalid username or password');
                  break;
                case LoginFailureReason.accountDisabled:
                  formikHelpers.setFieldValue('generalError', 'This account has been disabled');
                  break;
                case LoginFailureReason.noAccessToSite:
                  formikHelpers.setFieldValue('generalError', 'Cannot access site');
                  break;
                case LoginFailureReason.serverError:
                  formikHelpers.setFieldValue('generalError', 'A server error has occured');
                  break;
                case LoginFailureReason.accountLocked:
                  const lockoutPeriod = siteCtx.site?.lockoutPeriod;
                  const lockMessage = `account will be locked for ${lockoutPeriod
                    ? lockoutPeriod < 60 ? `${lockoutPeriod} seconds` : `${Math.round(lockoutPeriod / 6)/10} minutes` : 'a period of time'}`;
                  formikHelpers.setFieldValue('generalError', `Too many attempts - ${lockMessage}`);
                  break;
                default:
                  formikHelpers.setFieldValue('generalError', 'An unknown error occured. Please try again.');
                  break;
              }
            } else {
              formikHelpers.setFieldValue('generalError', 'An unknown error occured. Please try again.');
            }
          }
        }
      }
    });
  }

  const toggleSignupModal = () => {
    if ((signupMatch && signupMatch.isExact) || (signupSSOMatch && signupSSOMatch.isExact)) {
      history.push('/login');
    } else {
      if (ssoConfig) {
        history.push(`/signup/ssoconfig/${ssoConfig.name}`);
      } else {
        history.push('/signup');
      }
    }
  };

  const toggleResetPasswordModal = () => {
    setResetPasswordOpen(!resetPasswordOpen);
  };

  const toggleResetUsernameModal = () => {
    setResetUsernameOpen(!resetUsernameOpen);
  };

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModalOpen(!forgotPasswordModalOpen);
  };

  useEffect(() => {
    const handeSSOAuth = async () => {
      if (ssoMatch && ssoMatch.params.token) {
        console.log(`attmpeting login with token: ${ssoMatch.params.token}`);
        await handleAuthenticationWithToken(ssoMatch.params.token);
      }
    };
    void handeSSOAuth();
  }, [ssoMatch]);

  const loginForm = (
    <Formik initialValues={initialValues} onSubmit={handleLogin} enableReinitialize={true} validationSchema={LoginValidation}>
      {formikProps => {
        return (
          <Form
            onSubmit={e => {
              formikProps.setFieldValue('generalError', '');
              formikProps.handleSubmit(e);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field validateOnBlur validateOnChange name="username">
                  {() => (
                    <TextField
                      fullWidth
                      name="username"
                      label={<LanguageString groupName="GENERAL" resourceName="USERNAME" defaultText="Username" />}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={Boolean(formikProps.errors.username && formikProps.touched.username)}
                      helperText={formikProps.errors.username && formikProps.touched.username && String(formikProps.errors.username)}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field validateOnBlur validateOnChange name="password">
                  {() => (
                    <TextField
                      fullWidth
                      name="password"
                      label={<LanguageString groupName="GENERAL" resourceName="PASSWORD" defaultText="Password" />}
                      type="password"
                      autoComplete="current-password"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      error={Boolean(formikProps.errors.password && formikProps.touched.password)}
                      helperText={formikProps.errors.password && formikProps.touched.password && String(formikProps.errors.password)}
                    />
                  )}
                </Field>
              </Grid>

              {formikProps.values.generalError && (
                <Grid item xs={12}>
                  <Alert severity="error">{formikProps.values.generalError}</Alert>
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  <LanguageString groupName="GENERAL" resourceName="LOGIN" defaultText="Login" />
                </Button>
              </Grid>
              {siteCtx.site?.signupButtonEnabled && (
                <Grid item xs={12} md={6}>
                  <Button color="primary" fullWidth onClick={toggleSignupModal}>
                    <LanguageString groupName="GENERAL" resourceName="SIGNUP" defaultText="Signup" />
                  </Button>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Button color="primary" onClick={toggleForgotPasswordModal} fullWidth>
                  <LanguageString groupName="GENERAL" resourceName="FORGOT_USERNAME_PASSWORD" defaultText="Forgot Username / Password" />
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );

  const ssoLoginButton = useMemo(() => {
    if (gqlUrl && ssoConfig) {
      const url = gqlUrl.replace('/graphql', ssoConfig.loginPath);

      if (siteCtx.site?.signupButtonEnabled) {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  window.location.href = url;
                }}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" fullWidth onClick={toggleSignupModal}>
                Signup
              </Button>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  window.location.href = url;
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        );
      }
    }

    return null;
  }, [gqlUrl, siteCtx.site, ssoConfig]);

  Config.shared().gqlConnectionURL((url: string) => {
    if (url && url !== gqlUrl) {
      setGqlUrl(url);
    }
  });

  return (
    <Paper className={classes.welcomeContainerLoginForm}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <LanguageString variant="h4" groupName="GENERAL" resourceName="LOGIN_ALLCAPS" defaultText="LOGIN" />
        </Grid>
        <Grid item>
          {ssoConfig ? ssoLoginButton : loginForm}

          <SignupFormDialog
            open={signupMatch != null || signupSSOMatch != null}
            toggle={toggleSignupModal}
            overrideFormSubmitInput={ssoSignupFormSubmission}
            ssoConfig={ssoConfig}
          />
          <ForgotUsernamePasswordDialog open={forgotPasswordModalOpen} toggle={toggleForgotPasswordModal} />
          {match && match.isExact && <ResetPasswordDialog open={resetPasswordOpen} toggle={toggleResetPasswordModal} token={match?.params.token} />}
          {usernameMatch && usernameMatch.isExact && (
            <ResetUsernameDialog open={resetUsernameOpen} toggle={toggleResetUsernameModal} token={usernameMatch?.params.token} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginForm;
