import { gql, MutationUpdaterFn, QueryHookOptions } from '@apollo/client';
import { Form, SubmissionQueryArgs, User } from '../../dto';
import { ReqVars, useMappedQuery } from './common';
import { formFrag, formSubmissionFrag } from './fragments';

type Results = { form: Form; user?: User };
const query = gql`
  ${formFrag}
  ${formSubmissionFrag}

  query getFormAndSubmission($formName: String, $formId: Int, $userId: Int!, $withSubmission: Boolean!, $params: SubmissionQueryArgs!) {
    form(formName: $formName, formId: $formId) {
      ...FormFrag
    }
    user(id: $userId) @include(if: $withSubmission) {
      id
      formSubmission(params: $params) {
        ...FormSubmissionFrag
      }
    }
  }
`;

type Variables = {
  formName?: string;
  formId?: number;
  userId?: number;
  assignmentId?: number;
  submissionId?: number;
};

type InnerVariables = { formName?: string; formId?: number; userId?: number; withSubmission: boolean; params: SubmissionQueryArgs };

const getInnerVars = (variables: Variables): InnerVariables => ({
  formId: variables.formId,
  formName: variables.formName,
  userId: variables.userId ?? -1,
  withSubmission: variables.userId != null,
  params:
    variables.submissionId != null
      ? {
          submissionIdArgs: { submissionId: variables.submissionId }
        }
      : variables.formId != null
      ? {
          formIdArgs: {
            formId: variables.formId,
            assignmentId: variables.assignmentId
          }
        }
      : {
          formNameArgs: {
            formName: variables.formName ?? '',
            assignmentId: variables.assignmentId
          }
        }
});

// TODO: This needs some work to be useful for more than the one place it's currently being used
export function updateFormAndSubmission<
  T extends keyof Results,
  P extends keyof Required<Results>[T],
  C extends keyof Required<Required<Results>[T]>[P] | undefined
>(
  store: Parameters<MutationUpdaterFn>[0],
  vars: Variables,
  path: [T, P?, C?],
  data: undefined extends C ? Required<Results>[T][P] : Required<Required<Required<Results>[T]>[P]>[Exclude<C, undefined>]
) {
  const variables = getInnerVars(vars);
  const currData = store.readQuery<Results, InnerVariables>({ query, variables });
  if (currData != null) {
    if (path[1] != null) {
      store.writeQuery<Results, InnerVariables>({
        query,
        variables,
        data: { ...currData, ...{ [path[0]]: { ...currData[path[0]], ...{ [path[1]]: data } } } }
      });
    }
  }
}

export const useFormAndSubmission = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useMappedQuery(query, options, getInnerVars);
