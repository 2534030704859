import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useField, useFormikContext } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import React, { useState, ChangeEventHandler, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Grid, makeStyles } from '@material-ui/core';
import { GridWrapperProps } from '../TextField';

const useStyles = makeStyles(theme => ({
  editorRow: {
    minHeight: '200px'
  }
}));

export const HtmlTextField: React.FC<GridWrapperProps & { name: string; onChange?: ChangeEventHandler<HTMLInputElement> }> = props => {
  const styles = useStyles();
  const { xs, sm, md, lg, xl, item, name } = props;
  const formik = useFormikContext<{ [index: string]: string }>();
  const [field] = useField(name);
  const [editorState, setEditorState] = useState(() => {
    const test = htmlToDraft(field.value ?? '');
    const contentState = ContentState.createFromBlockArray(test.contentBlocks);

    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (!field.value || (field.value && field.value.length <= 0)) {
      setEditorState(EditorState.createEmpty());
    }
  }, [field.value]);

  const handleChange = (state: EditorState) => {
    formik.setFieldValue(name, draftToHtml(convertToRaw(state.getCurrentContent())));
    setEditorState(state);
  };

  const linkCallback = (link: { target: string }) => ({ ...link, target: encodeURI(link.target) });

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <Editor editorState={editorState} onEditorStateChange={s => handleChange(s)} toolbar={{ link: { linkCallback } }} editorClassName={styles.editorRow} />
    </Grid>
  );
};
