import React, { useContext } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Icon,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { DynamicForm } from '../DynamicForm';
import { StudentContext } from '../../contexts';
import { useClasses } from '../../hooks';
import * as DTO from '../../dto';

interface SnapShotDialogProps {
  open: boolean;
  toggle: () => void;
  submission?: DTO.FormSubmission;
  submitted: () => void;
}

export const SnapShotDialog: React.FC<SnapShotDialogProps> = props => {
  const studentCtx = useContext(StudentContext);
  const classes = useClasses();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={props.open}
      fullScreen={fullscreen}
      onClose={props.toggle}
      aria-labelledby="SnapShot"
    >
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <LanguageString groupName="GENERAL" resourceName="NEW_SNAPSHOT" defaultText="New Snapshot" />
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={props.toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className={classes.snapShotContainer}>
        <DynamicForm
          formName="assessmentToolTests"
          autoloadFormSubmission={false}
          formSubmissionInst={props.submission}
          disabled={props.submission?.completedAt != null}
          userId={studentCtx?.student.id ?? 0}
          autoSave={false}
          onFormSubmitted={(data, error) => {
            if (data && props.open) {
              props.toggle();
              props.submitted();
            }
          }}
          renderFormTitle={false}
        />
      </DialogContent>
    </Dialog>
  );
};
export default SnapShotDialog;
