import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  InputAdornment,
  Icon,
  CircularProgress,
  TextField,
  Checkbox,
  makeStyles,
  Paper,
  Box,
  IconButton,
  TablePagination
} from '@material-ui/core';
import { BlastNotificationsContext } from '../../contexts';
import { useClasses } from '../../hooks';
import { LanguageString } from '../Common';
import * as DTO from '../../dto';
import { useField } from 'formik';
import { NotificationBlastFilterDialog } from './NotificationBlastFilterDialog/NotificationBlastFilterDialog';
import { makeVar } from '@apollo/client';

let searchTimeout: NodeJS.Timeout | undefined = undefined;

interface UsersTableProps {
  filterAdvisorIds: number[];
  setFilterAdvisorIds: (ids: number[]) => void;
  showClosedUsers: boolean;
  setShowClosedUsers: (c: boolean) => void;
  defaultFilters: DTO.SubmitFormInput | undefined;
  setAllStudents: (field: string, value: boolean, shouldValidate?: boolean | undefined) => void;
  allStudents: boolean;
}

const useStyles = makeStyles(theme => ({
  usersTable: {
    maxHeight: 450,
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}));

const pageVar = makeVar(0);

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const [field, _, helpers] = useField<DTO.User[]>('selectedStudents');
  const classes = useClasses();
  const styles = useStyles();
  const studentsCtx = useContext(BlastNotificationsContext);
  const [search, setSearch] = useState(studentsCtx?.search ?? '');
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const toggleFilterDialog = () => setFilterDialogOpen(!filterDialogOpen);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPageLocal] = useState(pageVar());

  useEffect(() => {
    if (studentsCtx && studentsCtx.limit !== rowsPerPage) {
      studentsCtx.setLimit(rowsPerPage);
      studentsCtx.setOffset(page);
    }
    // if (studentsCtx && studentsCtx.advisorIds.length > 0) {
    //   studentsCtx.setAdvisorIds([]);
    // }
  }, [studentsCtx]);

  const setPage = (newPage: number) => {
    setPageLocal(newPage);
    pageVar(newPage);
    studentsCtx?.setOffset(newPage * studentsCtx.limit);
  };

  const setRows = (rows: number) => {
    setPageLocal(0);
    setRowsPerPage(rows);
    studentsCtx?.setLimit(rows);
  };

  const triggerSearch = () => {
    if (studentsCtx) {
      cancelTimer();
      studentsCtx.setSearch(search);
    }
  };

  const cancelTimer = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
      searchTimeout = undefined;
    }
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    cancelTimer();
    const searchStr = event.target.value;
    searchTimeout = setTimeout(triggerSearch, 750);
    setSearch(searchStr);
  };

  const handleSearchEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    cancelTimer();

    if (event.keyCode === 13 && studentsCtx) {
      triggerSearch();
    }
  };

  const setStudentCheck = (studentId: number) => {
    if (!props.allStudents) {
      return field.value.find(s => s.id === studentId) != null ?? false;
    } else {
      return !(field.value.find(s => s.id === studentId) != null ?? false);
    }
  };

  let endAdornment = (
    <InputAdornment position="end">
      <Icon>search</Icon>
    </InputAdornment>
  );

  if (studentsCtx?.loading || searchTimeout) {
    endAdornment = (
      <InputAdornment position="end">
        <CircularProgress size="20px" color="primary" />
      </InputAdornment>
    );
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              style={{ width: 'calc(100% - 50px)' }}
              id="searchField"
              type="search"
              value={search}
              label={<LanguageString groupName="GENERAL" resourceName="SEARCH_STUDENTS" defaultText="Search Students" />}
              InputProps={{
                endAdornment: endAdornment
              }}
              onChange={handleSearchTextChange}
              onKeyDown={handleSearchEnter}
            />
            <IconButton onClick={toggleFilterDialog}>
              <Icon>filter_list</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12} className={styles.usersTable}>
            <Table className={classes.stripedTable} size={'medium'} aria-label="Students Table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      checked={props.allStudents}
                      onChange={(event) => {
                        helpers.setValue([]);

                        props.setAllStudents('allStudents', event.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <LanguageString groupName="GENERAL" resourceName="NAME" defaultText="Name" />
                  </TableCell>
                  <TableCell align="left">
                    <LanguageString groupName="GENERAL" resourceName="USERNAME" defaultText="Username" />
                  </TableCell>
                  <TableCell align="left">
                    <LanguageString groupName="GENERAL" resourceName="EMAIL" defaultText="Email" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(studentsCtx?.filteredStudents ?? []).map(student => {
                  let closedUser = false;
                  let lastNotificationUser = false;
                  if (student.customProperties) {
                    // TODO: Remove the hard reference to custom prop names
                    closedUser = (student.customProperties.find(cp => cp.type?.name === DTO.CustomPropertyNames.closedCase)?.value === 'true');
                    const lastNotificationProp = student.customProperties.find(cp => cp.type?.name === DTO.CustomPropertyNames.lastNotificationDate);
                    if (lastNotificationProp && lastNotificationProp.value !== undefined) {
                      const date = new Date(lastNotificationProp.value);
                      if (new Date() < date) {
                        lastNotificationUser = true;
                      }
                    }
                  }

                  return (
                    <TableRow
                      key={`notification_student_search_${student.id}`}
                      className={`${classes.clickable} ${lastNotificationUser ? classes.lastNotification : (closedUser ? classes.closedCase : '')}`}
                    >
                      <TableCell align="left">
                        <Checkbox
                          checked={setStudentCheck(student.id)}
                          onChange={(event) => {
                            const students = [...field.value];
                            if (!props.allStudents) {
                              if (event.target.checked) {
                                if (!students.find(s => s.id === student.id)) {
                                  helpers.setValue([...students, student]);
                                }
                              } else {
                                const index = field.value.findIndex(s => s.id === student.id);
                                if (index >= 0) {
                                  students.splice(index, 1);
                                  helpers.setValue([...students]);
                                }
                              }
                            } else {
                              if (event.target.checked) {
                                const index = field.value.findIndex(s => s.id === student.id);
                                if (index >= 0) {
                                  students.splice(index, 1);
                                  helpers.setValue([...students]);
                                }
                              } else {
                                if (!students.find(s => s.id === student.id)) {
                                  helpers.setValue([...students, student]);
                                }
                              }
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {student.firstName} {student.lastName.charAt(0)}
                      </TableCell>
                      <TableCell align="left">
                        {student.username}
                      </TableCell>
                      <TableCell align="left">
                        {(student.emails) && (
                          student.emails.find(a => a.primary === true)?.email
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>

      <TablePagination
        rowsPerPageOptions={[15, 25, 50, 75, 100]}
        component="div"
        count={studentsCtx?.totalCount ?? 15}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, np) => setPage(np)}
        onChangeRowsPerPage={rpp => setRows(Number(rpp.target.value))}
      />

      <NotificationBlastFilterDialog
        open={filterDialogOpen}
        toggle={toggleFilterDialog}
        selectedAdvisorIds={props.filterAdvisorIds}
        changedSelectedAdvisorIds={(ids) => {
          props.setFilterAdvisorIds(ids);
        }}
        showClosedUsers={props.showClosedUsers}
        changedShowClosedUsers={(showClosed) => {
          props.setShowClosedUsers(showClosed);
        }}
      />
    </Paper>
  );
};
export default UsersTable;
