import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core';
import { LanguageString } from '../Common';

export const Resources: React.FC = () => {

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <LanguageString groupName="RESOURCES" resourceName="CONNECTING_WITH_US_TITLE" defaultText="Connecting With Us" />
            }
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <LanguageString groupName="RESOURCES" resourceName="CONNECTING_HEADER" defaultText="For Questions and Appointments" />
                </Typography>
              </Grid>
              <Grid xs={12} md={6}>
                <LanguageString groupName="RESOURCES" resourceName="CONNECTING_LEFT_SECTION" defaultText="" />
              </Grid>
              <Grid xs={12} md={6}>
                <LanguageString groupName="RESOURCES" resourceName="CONNECTING_RIGHT_SECTION" defaultText="" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <LanguageString groupName="RESOURCES" resourceName="RESOURCES" defaultText="Resources" />
            }
          />
          <CardContent>
            <LanguageString groupName="RESOURCES" resourceName="RESOURCES_DATA" defaultText="" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default Resources;
