import { gql, QueryHookOptions } from '@apollo/client';
import { Milestone } from '../../dto';
import { QueryType } from '../../helpers/query';
import { ReqVars, useMappedQuery } from './common';
import { milestoneFrag } from './fragments';

type Variables = { milestoneId: number };
type Results = { milestone?: QueryType<Milestone> };

const query = gql`
  ${milestoneFrag}

  query milestone($milestoneId: Int!, $withMilestone: Boolean!) {
    milestone(id: $milestoneId) @include(if: $withMilestone) {
      ...MilestoneFrag
    }
  }
`;

export const useMilestone = (options: ReqVars<QueryHookOptions<Results, Variables>>) =>
  useMappedQuery(query, options, vars => ({ ...vars, withMilestone: vars.milestoneId != null }));
