import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { History } from '@material-ui/icons';
import { useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { LanguageString } from '../Common/LanguageString';
import { FormFieldComp, FormStatus, getQuestionFormId, isInvalid, IValues } from './common';
import { useDialogController, useFocusOnError } from '../../hooks';
import { StudentContext, UserContext } from '../../contexts';
import { PropertyHistoryDialog } from './PropertyHistory';
import UserHelper from '../../helpers/userHelper';

export const FormBoolean: FormFieldComp = ({ question }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const fieldId = getQuestionFormId(question);
  const [field, meta] = useField<IValues>({ name: fieldId, type: 'checkbox' });
  const formik = useFormikContext<IValues>();
  const error = isInvalid(meta);
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const histCtl = useDialogController(false);
  const cp = UserHelper.isAdvisorOrAdmin(userCtx.user) ? question.userProperty : undefined;

  const handleHistoryClick = async (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    histCtl.controller.setOpen();
  };
  useFocusOnError(fieldRef, field.name);

  const { disabled: isDisabled, isReadOnly } = formik.status as FormStatus;
  const disabled = isDisabled || isReadOnly || question.isReadOnly;
  const label = cp
    ? <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><LanguageString languageString={question.text} /><div style={{display: 'flex', alignItems: 'center'}} onClick={handleHistoryClick}><History /></div></div>
    : <LanguageString languageString={question.text} />;
  return (
    <FormControl error={error}>
      <FormControlLabel
        disabled={disabled}
        control={<Checkbox {...field} indeterminate={field.value === undefined} />}
        label={label}
        ref={fieldRef}
      />
      {(question.hint || error) && (
        <FormHelperText id={`${fieldId}-helper-text`}>
          <LanguageString languageString={question.hint} />
          {error && <span>meta.error</span>}
        </FormHelperText>
      )}
      <PropertyHistoryDialog userId={studentCtx?.student.id} propertyId={cp?.id} ctrl={histCtl} />
    </FormControl>
  );
};
