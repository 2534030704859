import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { SubmitFormResult } from 'tuapath-common/generated/schema';
import { SubmitFormInput } from '../../dto';
import { QueryType } from '../../helpers/query';
import { formSubmissionFrag } from './fragments';

type Result = { submitForm: QueryType<SubmitFormResult> };
type Variables = { input: SubmitFormInput };
export function useSubmitForm(options?: MutationHookOptions<Result, Variables>) {
  const submitFormMutation = gql`
    ${formSubmissionFrag}

    mutation submitMilestoneForm($input: SubmitFormInput!) {
      submitForm(input: $input) {
        formSubmission {
          ...FormSubmissionFrag
        }
        assignment {
          id
          isComplete
          startedAt
          completedAt
          percentComplete
          task {
            id
          }
          milestone {
            id
          }
        }
      }
    }
  `;

  return useMutation<Result, Variables>(submitFormMutation, options);
}
