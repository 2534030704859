import React, { useEffect, useContext, useState } from 'react';
import { ComponentNotificationContext } from '../../../contexts';
import { useLocation } from 'react-router-dom';
import { useNotificationTriggers, useClasses } from '../../../hooks';
import {
  Table, TableRow, TableHead, TableCell, TableBody, Typography
} from '@material-ui/core';
import { LanguageString } from '../../Common';
import { NotificationTrigger, TriggerType } from 'tuapath-common/generated/schema';
import { NotificationScheduleEditor } from './NotificationScheduleEditor';

export const NotificationSchedulesTable: React.FC = () => {
  const classes = useClasses();
  const location = useLocation();
  const notificationHandler = useContext(ComponentNotificationContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState<NotificationTrigger | undefined>(undefined);
  const notificationTriggers = useNotificationTriggers();

  useEffect(() => {
    notificationHandler.addListener(location.pathname, () => {
      setSelectedTrigger(undefined);
      toggleDialog();
    });
  }, []);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const stringForType = (type: TriggerType) => {
    let resourceName = '';
    let defaultText = '';
    switch (type) {
      case TriggerType.MTS_INACTIVE:
        resourceName = 'TRIGGER_TYPE_MTS_INACTIVE';
        defaultText = 'MTS Inactive';
        break;
      case TriggerType.MTS_NOT_SUBMIT:
        resourceName = 'TRIGGER_TYPE_MTS_NOT_SUBMITTED';
        defaultText = 'MTS Not Submitted';
        break;
      case TriggerType.ROADMAP_INACTIVE:
        resourceName = 'TRIGGER_TYPE_ROADMAP_INACTIVE';
        defaultText = 'Roadmap Inactive';
        break;
      case TriggerType.DRIP_NOTIFICATION:
        resourceName = 'TRIGGER_TYPE_DRIP_NOTIFICATION';
        defaultText = 'Drip Notification';
        break;
      default:
        resourceName = 'TRIGGER_TYPE_MTS_INACTIVE';
        defaultText = 'MTS Inactive';
        break;
    }

    return <LanguageString groupName="GENERAL" resourceName={resourceName} defaultText={defaultText} />;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="ID" defaultText="ID" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NAME" defaultText="Name" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="SUCCESSPATH" defaultText="Success Path" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="TYPE" defaultText="Type" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="OPERAND" defaultText="Operand" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="SECONDARYOPERAND" defaultText="Secondary Operand" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="TEMPLATE" defaultText="Template" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notificationTriggers.data?.site.notificationTriggers?.map(trigger => (
            <TableRow
              className={classes.clickable}
              key={`trigger_${trigger.id}`}
              onClick={() => {
                setSelectedTrigger(trigger);
                toggleDialog();
              }}
            >
              <TableCell>
                <Typography variant="subtitle2">{trigger.id}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">{trigger.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">{trigger.successPathId}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{stringForType(trigger.type)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{trigger.operand}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{trigger.secondaryOperand}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{trigger.notificationTemplate?.name}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <NotificationScheduleEditor
        open={dialogOpen}
        toggle={toggleDialog}
        trigger={selectedTrigger}
      />
    </>
  );
};
export default NotificationSchedulesTable;
