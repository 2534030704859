import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';
import { ReqVars } from './common';
import { mtsFrag } from './fragments';

type Variables = { userId: number; startDate: Date; endDate: Date; periodIds?: number[] };
export type MTSEntriesResults = { user: { mtsEntries: DTO.MTS[] } };

export const mtsEntriesQuery = gql`
  ${mtsFrag}

  query mtsEntries($userId: Int!, $startDate: DateTime!, $endDate: DateTime!, $periodIds: [Int]) {
    user(id: $userId) {
      id
      mtsEntries(startDate: $startDate, endDate: $endDate, periodIds: $periodIds) {
        ...MTSFrag
      }
    }
  }
`;

export const useMTSEntries = (options: ReqVars<QueryHookOptions<MTSEntriesResults, Variables>>) =>
  useQuery<MTSEntriesResults, Variables>(mtsEntriesQuery, options);

export default useMTSEntries;
