import axios, { AxiosResponse } from 'axios';

interface IConfigJSON {
  gqlConnectionString: string;
}

export default class Config {

  static sharedInstance: Config | null;
  private json?: IConfigJSON;
  static gqlConnectionString?: string;

  public static shared() {
    if (this.sharedInstance == null) {
      this.sharedInstance = new Config();
    }

    return this.sharedInstance;
  }

  gqlConnectionURL(fn: (url: string) => void): string {
    this.getLocalJson((json: IConfigJSON) => {
      fn(json.gqlConnectionString);
    });

    return '';
  }

  private getLocalJson(fn: (json: IConfigJSON) => void) {
    if (this.json) {
      fn(this.json);
    } else {
      this.getJson(fn);
    }
  }

  private getJson(fn: (json: IConfigJSON) => void) {
    axios.get('/config.json')
      .then(function (response: AxiosResponse) {
        Config.gqlConnectionString = response.data.gqlConnectionString;
        fn(response.data);
      })
      .catch(function (error: Object) {
        console.log('Failed to get configuration file: ' + error);
      });
  }

}
