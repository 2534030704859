import { CssBaseline } from '@material-ui/core';
import { PaletteColor, PaletteColorOptions } from '@material-ui/core/styles/createPalette';
import React from 'react';
import { render } from 'react-dom';
import { App } from './components/App';
import { SiteProvider, SiteStatusProvider, ApolloCustomProvider } from './contexts';
import { unregister } from './serviceWorker';

export type comps = 'MTS' | 'Roadmap' | 'SuccessPath';
export type muicomps = 'Box' | 'Grid';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    paletteExtension?: {
      information?: PaletteColor;
      success?: PaletteColor;
    };
  }

  interface ThemeOptions {
    paletteExtension?: {
      information?: PaletteColorOptions;
      success?: PaletteColorOptions;
    };
  }
}

const rootElement = document.getElementById('root');

render(
  <SiteStatusProvider>
    <ApolloCustomProvider>
      <CssBaseline />
      <SiteProvider>
        <App />
      </SiteProvider>
    </ApolloCustomProvider>
  </SiteStatusProvider>,
  rootElement
);
unregister();
