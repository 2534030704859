import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  Icon,
  InputAdornment,
  Paper,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { UserContext, StudentsContext } from '../../contexts';
import { useLocalStorage, LocalStorageKey  } from '../../hooks';
import { LanguageString } from '../Common';
import { useMemo } from 'react';
import StudentsSearchTable from './StudentsSearchTable';
import { initialColumnsSettings } from './StudentsFiltersDialog';

let tableSearchTimeout: NodeJS.Timeout | undefined = undefined;

export const BrowseStudentsTable: React.FC = () => {
  const studentsCtx = useContext(StudentsContext);
  const [columns] = useLocalStorage(LocalStorageKey.StudentsTableColumns, initialColumnsSettings);
  const userCtx = useContext(UserContext);
  const selectedStudentsMatch = useRouteMatch<{ id?: string; studentId?: string; route?: string; }>(`/students/:id([^\/a-zA-Z]+)*/student/:studentId/:route`);
  const linkItems: { id: string, studentId: string }[] = [];
  if (selectedStudentsMatch && selectedStudentsMatch.params.id) {
    linkItems.push({ id: selectedStudentsMatch.params.id, studentId: selectedStudentsMatch.params.studentId ?? '' });
  }

  const initialAdvisorSettings: { advisorIds: number[] } = {
    advisorIds: [userCtx.user?.id ?? 0]
  };

  const [search, setSearch] = useState('');
  const [selectedAdivorSettings] = useLocalStorage(LocalStorageKey.SearchAdvisors, initialAdvisorSettings);
  const rowsPerPageOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[rowsPerPageOptions.length - 1]);

  useEffect(() => {
    // This function handle setting the appropriate search settings when in table mode
    // For example: when in table mode it should be limited to the students of the selected coaches
    if (studentsCtx) {
      let newAdviorsIds = false;
      if (selectedAdivorSettings.advisorIds.length !== studentsCtx.advisorIds.length) {
        newAdviorsIds = true;
      } else {
        for (const id of selectedAdivorSettings.advisorIds) {
          let found = false;

          for (const selectedId of studentsCtx.advisorIds) {
            if (id === selectedId) {
              found = true;
              break;
            }
          }

          if (!found) {
            newAdviorsIds = true;
            break;
          }
        }
      }

      if (newAdviorsIds) {
        studentsCtx.setAdvisorIds(selectedAdivorSettings.advisorIds);
      }
    }
  }, [studentsCtx, selectedAdivorSettings.advisorIds]);

  // This will run once each time this component is attached to the tree. This allows settings that the other tabs change to be reset.
  useEffect(() => {
    if (studentsCtx) {
      studentsCtx.setLimit(rowsPerPage);
      studentsCtx.setOffset(0);
      studentsCtx.setSearch('');
    }
  }, []);

  const students = useMemo(() => studentsCtx?.filteredStudents ?? [], [studentsCtx?.filteredStudents]);

  const triggerSearch = (str: string) => {
    if (studentsCtx) {
      tableSearchTimeout = undefined;
      studentsCtx.setSearch(str);
    }
  };

  const cancelTimer = () => {
    if (tableSearchTimeout) {
      clearTimeout(tableSearchTimeout);
      tableSearchTimeout = undefined;
    }
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    cancelTimer();
    const searchStr = event.target.value;
    tableSearchTimeout = setTimeout(() => {
      triggerSearch(searchStr);
    }, 750);
    setSearch(searchStr);
  };

  const handleSearchEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    cancelTimer();

    if (event.keyCode === 13 && studentsCtx) {
      triggerSearch(search);
    }
  };

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
    if (studentsCtx) {
      studentsCtx.setOffset(studentsCtx.limit * newPage);
    }
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const rows = Number(event.target.value);
    if (studentsCtx) {
      studentsCtx.setOffset(0);
      studentsCtx.setLimit(rows);
    }
    setRowsPerPage(rows);
    setPage(0);
  }

  let endAdornment = (
    <InputAdornment position="end">
      <Icon>search</Icon>
    </InputAdornment>
  );

  if (studentsCtx?.loading) {
    endAdornment = (
      <InputAdornment position="end">
        <CircularProgress size="20px" color="primary" />
      </InputAdornment>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <Grid container spacing={3} alignItems="center" justify="space-between" direction="row">
              <Grid item>
                <Typography variant="h6" id="tableTitle">
                  <LanguageString groupName="GENERAL" resourceName="ALL_STUDENTS" defaultText="All Students" />
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="searchField"
                  type="search"
                  value={search}
                  label={<LanguageString groupName="GENERAL" resourceName="SEARCH" defaultText="Search" />}
                  InputProps={{
                    endAdornment: endAdornment
                  }}
                  onChange={handleSearchTextChange}
                  onKeyDown={handleSearchEnter}
                />
              </Grid>
            </Grid>
          </Toolbar>

          <StudentsSearchTable students={students} columns={columns} />

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={studentsCtx?.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default BrowseStudentsTable;
