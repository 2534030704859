import { Box, Button, Grid, List, ListSubheader, Typography } from '@material-ui/core';
import { FieldArrayRenderProps, useField } from 'formik';
import React, { useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { QValues } from '../../common';
import { useDialogController } from '../../hooks';
import { ClickEvent } from './common';
import { EditQuestionDialog } from './EditQuestionDialog';
import { QuestionListItem } from './QuestionListItem';
import { createNewQuestion } from './utils';

const QuestionsInner: React.FC<FieldArrayRenderProps> = ({ name, push, remove }) => {
  const [{ value: questions }] = useField<QValues[] | undefined>(name);
  const [selectedQuestion, setSelectedQuestion] = useState<{ question: QValues; fieldName: string } | undefined>();
  const { controller, props } = useDialogController(false);

  const handleAddClick: ClickEvent<HTMLButtonElement> = event => {
    const newQuestion = createNewQuestion();

    push(newQuestion);
    setSelectedQuestion({ question: newQuestion, fieldName: `${name}[${questions?.length ?? 0}]` });
    controller.setOpen();
  };

  const handleDeleteClick = (qIndex: number) => {
    remove(qIndex);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const sourceIndex = result.source.index;
    const destIndex = result.destination?.index;

    if (result.reason === 'DROP' && destIndex != null && questions != null) {
      questions.splice(destIndex, 0, questions.splice(sourceIndex, 1)[0]);
      questions.forEach((q, nIndex) => (q.sortIndex = nIndex));
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="question-list">
        {dpProvided => (
          <List
            style={{ marginTop: '15px' }}
            subheader={
              <ListSubheader>
                <Grid container>
                  <Typography>Questions</Typography>
                  <Box ml="auto" clone>
                    <Button onClick={handleAddClick} style={{ marginTop: 0 }}>
                      Add
                    </Button>
                  </Box>
                </Grid>
              </ListSubheader>
            }
            dense
            ref={dpProvided.innerRef}
          >
            {questions?.map((q, qIndex) => (
              <QuestionListItem key={q.id} question={q} fieldName={`${name}[${qIndex}]`} onDelete={() => handleDeleteClick(qIndex)} index={qIndex} />
            ))}
            {selectedQuestion && <EditQuestionDialog {...selectedQuestion} controller={controller} props={props} />}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
export const Questions: React.FC<FieldArrayRenderProps | void> = QuestionsInner as React.FC<FieldArrayRenderProps | void>;
