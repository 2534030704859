import React, { useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import { LocalStorageKey, useLocalStorage } from '../../../hooks';
import { LanguageString } from '../../Common';
import { SiteContext } from '../../../contexts';

export interface CaseLoadColumnSettings {
  name: boolean;
  twoMonthsAgoMTSStatus: boolean;
  lastMonthMTSStatus: boolean;
  currentMonthStatus: boolean;
  coachMeeting: boolean;
  lastMTSInteraction: boolean;
  lastRoadmapInteraction: boolean;
  customProperties: { [key: string]: boolean };
}

export const initialColumnsSettings: CaseLoadColumnSettings = {
  name: true,
  twoMonthsAgoMTSStatus: true,
  lastMonthMTSStatus: true,
  currentMonthStatus: true,
  coachMeeting: true,
  lastMTSInteraction: true,
  lastRoadmapInteraction: true,
  customProperties: {}
};

export const CaseLoadColumnSettingsTab: React.FC = () => {
  const site = useContext(SiteContext);
  console.log(`site custom props: `, site.site?.caseLoadCustomPropertyColumns);
  const [columns, setColumns] = useLocalStorage(LocalStorageKey.CaseLoadColumn, initialColumnsSettings);
  const customPropertyColumns = React.useMemo(() => {
    return site.site?.caseLoadCustomPropertyColumns?.split(',') || [];
  }, [site.site?.caseLoadCustomPropertyColumns]);


  return (
    <Grid item xs>
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.name}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.name = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_NAME" defaultText="Participant Name" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.twoMonthsAgoMTSStatus}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.twoMonthsAgoMTSStatus = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_TWO_MONTHS_STATUS" defaultText="Two Months Ago MTS" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.lastMonthMTSStatus}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.lastMonthMTSStatus = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_LAST_MONTH_STATUS" defaultText="Last Month MTS" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.currentMonthStatus}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.currentMonthStatus = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_CURRENT_MONTH_STATUS" defaultText="Current Month MTS" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.coachMeeting}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.coachMeeting = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_COACH_MEETING" defaultText="Coach Meeting" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.lastMTSInteraction}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.lastMTSInteraction = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_LAST_MTS_INTERACTION" defaultText="Last MTS Interaction" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.lastRoadmapInteraction}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.lastRoadmapInteraction = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMN_LAST_ROADMAP_INTERACTION" defaultText="Last Roadmap Interaction" />}
          />
        </Grid>
        {customPropertyColumns.map((customPropertyColumn, index) => (
          <Grid item xs={12} md={4} key={`customprop_column_${index}`}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.customProperties[customPropertyColumn]}
                  value="primary"
                  onChange={event => {
                    const c = columns;
                    c.customProperties[customPropertyColumn] = event.target.checked;
                    setColumns(c);
                  }}
                />
              }
              label={
                <LanguageString groupName='CASE_LOAD' resourceName={customPropertyColumn} defaultText={customPropertyColumn} />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CaseLoadColumnSettingsTab;
