import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Assignment, UpdateAssignmentMutationArgs } from '../../dto';
import { assignmentFrag } from './fragments';

type Result = { updateAssignment: Assignment };
type Variables = UpdateAssignmentMutationArgs;
export function useUpdateAssignment(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${assignmentFrag}
    mutation updateAssignment($input: UpdateAssignmentInput!) {
      updateAssignment(input: $input) {
        ...AssignmentFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, { ...options });
}

export default useUpdateAssignment;
