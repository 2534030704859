import React, { useState, useContext, useEffect } from 'react';
import {
  Table, TableRow, TableHead, TableCell, TableBody, Icon, Typography
} from '@material-ui/core';
import { LanguageString } from '../../Common';
import { useNotificationTemplates, useClasses } from '../../../hooks';
import * as DTO from 'tuapath-common/generated/schema';
import NotificationTemplateEditor from './NotificationTemplateEditor';
import { ComponentNotificationContext } from '../../../contexts';
import { useLocation } from 'react-router-dom';

export const NotificationTemplatesTable: React.FC = () => {
  const location = useLocation();
  const notificationHandler = useContext(ComponentNotificationContext);
  const classes = useClasses();
  const notificationTemplates = useNotificationTemplates();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<DTO.NotificationTemplate | undefined>(undefined);

  useEffect(() => {
    notificationHandler.addListener(location.pathname, () => {
      // Plus button clicked
      setSelectedTemplate(undefined);
      toggleDialog();
    });
  }, []);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="ID" defaultText="ID" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_NAME" defaultText="Name" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_TITLE" defaultText="Title" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_MESSAGE" defaultText="Message" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_MESSAGE_PLAIN" defaultText="Plain Message" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_EMAIL" defaultText="Email" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_SMS" defaultText="SMS" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_UI" defaultText="UI" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_TEMPLATE_ROC" defaultText="ROC" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notificationTemplates.data?.site.notificationTemplates?.map(template => (
            <TableRow
              className={classes.clickable}
              key={`template_${template.id}`}
              onClick={() => {
                setSelectedTemplate(template);
                toggleDialog();
              }}
            >
              <TableCell>
                <Typography variant="subtitle2">{template.id}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">{template.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <LanguageString languageString={template.title} renderUserData={false} disableHtml={true} />
                </Typography>
              </TableCell>
              <TableCell>
                <LanguageString languageString={template.message} renderUserData={false} disableHtml={true} />
              </TableCell>
              <TableCell>
                <LanguageString languageString={template.messagePlainText} renderUserData={false} disableHtml={true} />
              </TableCell>
              <TableCell>
                <Icon>{template.shouldEmail === true ? 'check_circle' : 'clear'}</Icon>
              </TableCell>
              <TableCell>
                <Icon>{template.shouldSMS === true ? 'check_circle' : 'clear'}</Icon>
              </TableCell>
              <TableCell>
                <Icon>{template.showInUI === true ? 'check_circle' : 'clear'}</Icon>
              </TableCell>
              <TableCell>
                <Icon>{template.shouldCreateROC === true ? 'check_circle' : 'clear'}</Icon>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <NotificationTemplateEditor
        open={dialogOpen}
        toggle={toggleDialog}
        template={selectedTemplate}
      />
    </>
  );
};
export default NotificationTemplatesTable;
