import React, { useRef, useContext } from 'react';
import {
  IconButton,
  Icon,
  Tooltip
} from '@material-ui/core';
import { LanguageString, FullscreenLoading } from '../';
import { PrintableProvider, PrintableContext } from '../../../contexts';
import Html2Canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const PrintableButton: React.FC<{ download: boolean; }> = ({ download }) => {
  const printableContext = useContext(PrintableContext);

  const handlePrint = async () => {
    if (printableContext.printableComponent && printableContext.printableComponent.current) {
      printableContext.setLoading(true);

      const canvas = await Html2Canvas(printableContext.printableComponent!.current!);
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF('p', 'mm');
      let position = 0;
      let loops = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0 && loops <= 65) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        loops += 1;
      }
      window.open(doc.output('bloburl'), '_blank');

      printableContext.setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (printableContext.printableComponent && printableContext.printableComponent.current) {
      printableContext.setLoading(true);

      const canvas = await Html2Canvas(printableContext.printableComponent!.current!);
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF('p', 'mm');
      let position = 0;
      let loops = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0 && loops <= 65) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        loops += 1;
      }
      doc.save(`${printableContext.fileName ?? 'file'}.pdf`);

      printableContext.setLoading(false);
    }
  };

  if (download) {
    return (
      <>
        <Tooltip
          title={
            <LanguageString groupName="SITE_CONTENT" resourceName="DOWNLOAD" defaultText="Download" />
          }
        >
          <IconButton onClick={handleDownload}>
            <Icon>cloud_download</Icon>
          </IconButton>
        </Tooltip>

        {printableContext.loading && (
          <FullscreenLoading text="Generating file..." />
        )}
      </>
    );
  } else {
    return (
      <>
        <Tooltip
          title={
            <LanguageString groupName="SITE_CONTENT" resourceName="PRINT" defaultText="Print" />
          }
        >
          <IconButton onClick={handlePrint}>
            <Icon>print</Icon>
          </IconButton>
        </Tooltip>

        {printableContext.loading && (
          <FullscreenLoading text="Generating file..." />
        )}
      </>
    );
  }
};

export const Printable: React.FC<{ fileName?: string, printActions: React.ReactNode }> = (props) => {
  const printableComponent = useRef<HTMLDivElement>(null);

  return (
    <>
      <PrintableProvider printableComponent={printableComponent} fileName={props.fileName}>
        {props.printActions}
        <div ref={printableComponent}>
          {props.children}
        </div>
      </PrintableProvider>
    </>
  );
};
export default Printable;
