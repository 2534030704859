import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Form } from '../../dto';
import { formFrag } from './fragments';

type Results = { form: Form };
type Variables = {
  formName?: string;
  formId?: number;
};

const query = gql`
  ${formFrag}

  query getFormAndSubmission($formName: String, $formId: Int) {
    form(formName: $formName, formId: $formId) {
      ...FormFrag
    }
  }
`;

export function useForm(options: QueryHookOptions<Results, Variables> & { variables: Variables }) {
  return useQuery<Results, Variables>(query, { ...options, variables: options.variables });
}
