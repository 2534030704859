import { gql, QueryHookOptions } from '@apollo/client';
import { User } from '../../dto';
import { ReqVars, useMappedQuery } from './common';
import { attachmentFrag } from './fragments/attachmentFrag';

type Variables = { userId: number; assignmentId?: number };
type InnerVars = Required<Variables> & { withAssignment: boolean };
type AssignmentProp = keyof Pick<User, 'assignment'>;
type AssignmentType = User[AssignmentProp] extends (infer U)[] ? U : User[AssignmentProp];
type Results = { user?: { [P in AssignmentProp]?: Pick<AssignmentType, 'attachments'> } };
export const query = gql`
  ${attachmentFrag}
  query user($userId: Int!, $assignmentId: Int!, $withAssignment: Boolean!) {
    user(id: $userId) @include(if: $withAssignment) {
      id
      assignment(assignmentId: $assignmentId) {
        id
        attachments {
          ...AttachmentFrag
        }
      }
    }
  }
`;

const innerVars = (vars: Variables): InnerVars => ({ userId: vars.userId, assignmentId: vars.assignmentId ?? -1, withAssignment: vars.assignmentId != null });

export const useAssignmentAttachments = (options: ReqVars<QueryHookOptions<Results, Variables>>) =>
  useMappedQuery(query, { fetchPolicy: 'cache-first', ...options }, innerVars);
