import { Box, CircularProgress, Collapse, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { TypedReflect } from '../../../common';
import { SiteContext, StudentContext, UserContext } from '../../../contexts';
import { FormQuestion, MTS, MTSActivity, MTSActivityType, PrintLocation, UserMTSActivityType, FormSubmission } from '../../../dto';
import MTSHelpers, { getTypesNeedingApproval } from '../../../helpers/mtsHelpers';
import { useForm, useFormAndSubmission, useGetMTSEntryById, useMTSUserActivityTypes, useUserMTSPeriods } from '../../../hooks';
import { getApprovalStatus } from '../../../server';
import { DownloadTemplate, LanguageString } from '../../Common';
import { questionsIterator } from '../../DynamicForm/common';
import { MTSAddActivityDialog } from '../MTSAddActivity/MTSAddActivityDialog';
import { ExtApprovalInfoDlg } from '../MTSReviewHoursDialog/MTSReviewRow';
import { ApprovalState } from '../MTSReviewHoursDialog/MTSSubmitDialog';

const useStyles = makeStyles(theme => ({
  tableCellRow: {
    cursor: 'pointer'
  },
  errorText: {
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    color: theme.palette.error.main
  }
}));

const FormRow: React.FC<{
  entry: MTSActivity,
  questions: FormQuestion[],
  mts: MTS,
  clickedRow: (entry: MTSActivity) => void,
  type: MTSActivityType | UserMTSActivityType,
  activityTypes: MTSActivityType[]
}> = ({ entry, questions, mts, type, clickedRow, activityTypes }) => {
  const studentCtx = useContext(StudentContext);
  const primaryFormSubmission = useFormAndSubmission({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      formId: entry.type.formId,
      submissionId: entry.formSubmission?.id ?? 0
    }
  });
  const secondaryFormSubmission = useFormAndSubmission({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      formId: entry.type.formId,
      submissionId: entry.secondaryFormSubmission?.id ?? 0
    }
  });

  const answersForQuestion = useMemo(() => {
    const answers: { [id: number]: string } = {};

    let actualType: MTSActivityType | undefined = type as MTSActivityType;
    if ('expiring' in type) {
      const userType = type as UserMTSActivityType;
      actualType = activityTypes.find(a => a.id === userType.parentActivityType.id);
    }

    let formSubmission: FormSubmission | undefined = undefined;
    if (primaryFormSubmission && primaryFormSubmission.data?.form.id === actualType?.formId) {
      formSubmission = primaryFormSubmission.data?.user?.formSubmission;
    } else if (secondaryFormSubmission && secondaryFormSubmission.data?.form.id === actualType?.formId) {
      formSubmission = primaryFormSubmission.data?.user?.formSubmission;
    }

    if (formSubmission) {
      for (const answer of formSubmission.answers) {
        if (answer.selections && answer.selections.length > 0) {
          let an = '';
          for (const selection of answer.selections) {
            const option = answer.question.options?.find(o => o.id == selection.id);
            an = an.length > 0 ? `${an}, ${option?.text ?? ''}` : option?.text ?? '';
          }
          answers[answer.question.id] = an;
        } else if (answer.answer) {
          if (answer.question.questionType === 'DATE') {
            answers[answer.question.id] = format(new Date(answer.answer), 'MM/dd/yyyy');
          } else if (answer.question.questionType !== 'PASSWORD' && answer.question.questionType !== 'HIDDEN') {
            answers[answer.question.id] = answer.answer;
          }
        }
      }
    }

    return answers;
  }, [entry, activityTypes, primaryFormSubmission, secondaryFormSubmission]);

  return (
    <TableRow key={`entry_row_${entry.id}`}>
      <TableCell>
        {entry.date ? format(MTSHelpers.dateFromString(entry.date), 'MM/dd') : `${mts.month}/${entry.day}`}
      </TableCell>
      {questions.map(question => (
        <TableCell key={`question_row_${entry.id}_${question.id}`}>
          {answersForQuestion[question.id]}
        </TableCell>
      ))}
      <TableCell>
        {entry.hours}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => clickedRow(entry)}>
          <Icon>visibility</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const RowInner: React.FC<{
  mts: MTS, type: MTSActivityType | UserMTSActivityType, entries: MTSActivity[],
  activityTypes: MTSActivityType[]
}> = ({ mts, type, entries, activityTypes }) => {
  const userCtx = useContext(UserContext);
  const siteCtx = useContext(SiteContext);
  const mtsPeriodResults = useUserMTSPeriods();
  const styles = useStyles();
  const [selectedEntry, setSelectedEntry] = useState<MTSActivity | undefined>(undefined);
  const actualType = useMemo(() => {
    if ('expiring' in type) {
      const userType = type as UserMTSActivityType;
      return activityTypes.find(a => a.id === userType.parentActivityType.id);
    }

    return type as MTSActivityType;
  }, [type, activityTypes]);

  if (!actualType?.formId) {
    return (
      <Typography variant="subtitle1" className={styles.errorText}>
        <LanguageString groupName="MTS" resourceName="INVALID_TYPE" defaultText="Invalid Activity Type" />
      </Typography>
    );
  }

  const form = useForm({ variables: { formId: actualType.formId } });
  const questions = useMemo(() => form.data?.form?.elements ?  [...questionsIterator(form.data.form.elements)] : [], [form.data?.form]);
  const canChangeMTS = useMemo(() => {
    if (selectedEntry && siteCtx.site) {
      const date = selectedEntry.date ?
        MTSHelpers.dateFromString(selectedEntry.date) :
        new Date(selectedEntry.day, mts.month - 1, mts.year, 0, 0);
      return MTSHelpers.canChangeMTS(userCtx.user, date, siteCtx.site, mts, mtsPeriodResults.data?.site.mtsPeriods);
    }
    return false;
  }, [mtsPeriodResults.data?.site.mtsPeriods, selectedEntry]);

  const toggleAddModal = () => {
    setSelectedEntry(undefined);
  };

  return (
    <Paper>
      <Table style={{ overflowX: 'scroll' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="DATE" defaultText="Date" />
            </TableCell>
            {questions.map(question => (
              <Tooltip key={`question_tooltip_${question.id}`} title={question.text.text ?? ''} placement="top-start">
                <TableCell key={`question_header_${question.id}`}>
                  {question.text.text && question.text.text.length > 25 ?
                    `${question.text.text?.substr(0, 25)}...` :
                    question.text.text
                  }
                </TableCell>
              </Tooltip>
            ))}
            <TableCell>
              <LanguageString groupName="MTS" resourceName="HOURS" defaultText="Hours" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map(entry => (
            <FormRow
              key={`form_row_${entry.id}`}
              questions={questions}
              mts={mts}
              entry={entry}
              type={type}
              clickedRow={setSelectedEntry}
              activityTypes={activityTypes}
            />
          ))}
        </TableBody>
      </Table>

      <MTSAddActivityDialog
        open={selectedEntry != null}
        toggle={toggleAddModal}
        mts={mts}
        date={selectedEntry != null ?
          (selectedEntry?.date ?
            MTSHelpers.dateFromString(selectedEntry.date) :
            new Date(mts.year, mts.month - 1, selectedEntry.day, 0, 0, 0)
          ) :
          new Date()
        }
        activityTypes={activityTypes}
        mtsEntryId={selectedEntry?.id}
        readonly={!canChangeMTS}
        refreshQueries={['mtsById', 'mtsUserActivityType']}
      />
    </Paper>
  );
};

const Row: React.FC<{
  mts: MTS, type: MTSActivityType | UserMTSActivityType, entries: MTSActivity[],
  typeHours: number, activityTypes: MTSActivityType[], hasTypesNeedingApproval: boolean,
  loading: boolean
}> = ({ mts, type, entries, typeHours, activityTypes, hasTypesNeedingApproval, loading }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();

  const actualType = useMemo(() => {
    if ('expiring' in type) {
      const userType = type as UserMTSActivityType;
      return activityTypes.find(a => a.id === userType.parentActivityType.id);
    }

    return type;
  }, [type, activityTypes]);

  const first = entries[0];
  const approvalRequired = first?.type?.externalApprovalFormId != null;

  return (
    <>
      <TableRow key={`activity_row_${type.id}`}>
        {hasTypesNeedingApproval &&
          <TableCell style={{ width: 0 }}>{
            approvalRequired
              ? <ExtApprovalInfoDlg dtoMts={mts} activity={first} />
              : <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_APPLICABLE" />
          }</TableCell>
        }
        <TableCell className={styles.tableCellRow} onClick={() => setOpen(!open)}>
          <Typography style={{ fontStyle: 'expiring' in type && (type as UserMTSActivityType).expiring ? 'italic' : 'normal' }}>
            <LanguageString languageString={type.name} />
          </Typography>
        </TableCell>
        <TableCell className={styles.tableCellRow} onClick={() => setOpen(!open)}>
          {typeHours}
        </TableCell>
        <TableCell>
          {actualType?.printFileSchema?.id && (
            <DownloadTemplate
              location={PrintLocation.MTSActivityType}
              schemaId={actualType.printFileSchema?.id}
              relationId={`${mts.id}-${'expiring' in type ? 'user' : 'main'}:${type.id}`}
              primary={true}
            />
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ paddingBottom: open ? 10 : 0, paddingTop: open ? 10 : 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {(loading || (entries.length === 0)) ? <CircularProgress /> :
              <RowInner entries={entries} type={type} mts={mts} activityTypes={activityTypes} />
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const idForActivityType = (type: MTSActivityType | UserMTSActivityType) => {
  return `${'expiring' in type ? 'user' : 'mtsactivity'}${type.id}`;
};

export const ActivityFormsTable: React.FC<{ mts?: MTS, showMtsTitle?: boolean }> = ({ mts, showMtsTitle }) => {
  const studentCtx = useContext(StudentContext);
  const { data: mtsEntries, loading: mtsEntriesLoading } = useGetMTSEntryById({
    variables: {
      entryIds: mts?.entries?.entries?.map(e => e.id) ?? [0]
    }
  });
  const [hasUnrequestedApprovals, setHasUnrequestedApprovals] = useState(true);
  const [approvalsRequested, setApprovalsRequested] = useState({} as ApprovalState);
  const activityTypesResult = useMTSUserActivityTypes({
    variables: {
      includeDisabled: false,
      includeExpiredGeneratedTypes: false,
      userId: studentCtx?.student.id
    },
    fetchPolicy: 'network-only'
  });

  const typesNeedingApproval = mts ? getTypesNeedingApproval(mts) : undefined;
  const types = useMemo(() => {
    return mtsEntries?.mtsEntryById?.map(e => ({
      id: idForActivityType(e.userType ?? e.type),
      type: e.userType ? 'user' : 'activitytype',
      activityType: e.userType ?? e.type,
      appearsInReviewAllHours: e.type.appearsInReviewAllHours
    }))
    .filter((ob, index, arr) => arr.findIndex(e => e.id === ob.id) === index)
    .filter(type => type.appearsInReviewAllHours)
    ?? [];
  }, [mtsEntries]);

  const hoursForType = useMemo(() => {
    const hours: { [id: string]: number } = {};

    if (mts?.entries?.entries) {
      for (const entry of mts?.entries?.entries) {
        const id = idForActivityType(entry.userType ?? entry.type);
        if (hours[id]) {
          hours[id] += entry.hours;
        } else {
          hours[id] = entry.hours;
        }
      }
    }

    return hours;
  }, [mts]);

  const entriesForType = useMemo(() => {
    const hours: { [id: string]: MTSActivity[] } = {};

    if (mtsEntries?.mtsEntryById && mts) {
      for (const entry of mtsEntries?.mtsEntryById) {
        const id = idForActivityType(entry.userType ?? entry.type);
        if (hours[id]) {
          hours[id] = [
            ...hours[id],
            entry
          ].sort((a, b) => {
            if (a.date && b.date && (typeof a.date !== 'string' || a.date.length > 0) && (typeof b.date !== 'string' || b.date.length > 0)) {
              return MTSHelpers.dateFromString(a.date).getTime() - MTSHelpers.dateFromString(b.date).getTime();
            } else {
              return new Date(mts.year, mts.month, a.day, 0, 0).getTime() - new Date(mts.year, mts.month, b.day, 0, 0).getTime();
            }
          });
        } else {
          hours[id] = [entry];
        }
      }
    }

    return hours;
  }, [mtsEntries, mts]);

  const titleForMTS = useMemo(() => {
    if (mts) {
      if (mts?.mtsPeriod) {
        return `${format(MTSHelpers.dateFromString(mts.mtsPeriod.startDate), 'MM/dd/yyyy')} - ${format(MTSHelpers.dateFromString(mts.mtsPeriod.endDate, true), 'MM/dd/yyyy')}`;
      } else {
        return `${mts.month}/${mts.year}`;
      }
    }

    return '';
  }, [mts]);

  const hasTypesNeedingApproval = typesNeedingApproval
    ? TypedReflect.ownKeys(typesNeedingApproval)
      .some(tna => TypedReflect.ownKeys(typesNeedingApproval[tna])
        .some(ap => typesNeedingApproval[tna][ap].length > 0)) === true
    : false;

  if (typesNeedingApproval != null && mts != null) {
    void (async () => {
      const localAppsRequested: ApprovalState = {};
      const localHasUnrequestedApprovals = !(await Promise.all(
        TypedReflect.ownKeys(typesNeedingApproval).map(typeId => TypedReflect.ownKeys(typesNeedingApproval[typeId]).map(async uTypeId => {
          if (localAppsRequested[typeId] == null) localAppsRequested[typeId] = {};
          if (localAppsRequested[typeId][uTypeId]) {
            return localAppsRequested[typeId][uTypeId].state = (await getApprovalStatus(mts.id, typeId, Number(uTypeId) === 0 ? undefined : uTypeId)) ?? 'not-requested';
          } else {
            return 'not-requested';
          }
       })).flat())).reduce((pv, cv) => pv && cv != 'not-requested', true);

      if (localHasUnrequestedApprovals !== hasUnrequestedApprovals) setHasUnrequestedApprovals(localHasUnrequestedApprovals);
      if (TypedReflect.ownKeys(typesNeedingApproval).some(prop =>
        approvalsRequested[prop] == null || TypedReflect.ownKeys(localAppsRequested[prop]).some(uTypeId => approvalsRequested[prop][uTypeId] !== localAppsRequested[prop][uTypeId])
      )) setApprovalsRequested(localAppsRequested);
    })();
  }

  return (
    <>
      {showMtsTitle === true && (
        <Box padding={2}>
          <Typography variant="subtitle1" color="textSecondary">
            <Typography variant="subtitle2" color="textPrimary">
              <LanguageString groupName="MTS" resourceName="MTS" defaultText="MTS" />
            </Typography>
            {titleForMTS}
          </Typography>
        </Box>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {hasTypesNeedingApproval && <TableCell>Approval</TableCell>}
            <TableCell>
              <LanguageString groupName="MTS" resourceName="ACTIVITY_TYPE" defaultText="Activity" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="ACTIVITY_HOURS" defaultText="Activity Hours" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="DOWNLOAD" defaultText="Download" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {mts != null && (
            <>
              {types.map(type => (
                <Row
                  key={`activity_row_${type.id}`}
                  type={type.activityType}
                  typeHours={hoursForType[type.id]}
                  entries={entriesForType[type.id] ?? []}
                  mts={mts}
                  activityTypes={activityTypesResult.data?.mtsUserActivityTypes ?? []}
                  hasTypesNeedingApproval={hasTypesNeedingApproval}
                  loading={mtsEntriesLoading}
                />
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};
export default ActivityFormsTable;
