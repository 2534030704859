
import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { UpdateStringMutationArgs, LanguageStringGroup } from '../../dto';
import { languageStringGroupFrag } from './fragments';

type Result = { updateString: LanguageStringGroup, shouldWarnOnDuplicateString?: boolean };
type Variables = UpdateStringMutationArgs;
export function useUpdateString(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${languageStringGroupFrag}

    mutation updateString($data: UpdateStringInput!) {
      updateString(data: $data) {
        ...LangStringGroupFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateString;
