import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem
} from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { LsValues } from '../../common';
import * as Dto from '../../common/pathDtoTypes';
import * as Edit from '../../common/pathEditTypes';
import { tmpId } from '../../contexts';
import { Milestone, TaskActionType } from '../../dto';
import { useDialogController, useForms, useUpdateMilestone } from '../../hooks';
import { Callout, Checkbox, IconButton, LanguageString, LSTextField, TextField } from '../Common';
import { PathEditorContext } from '../PathEditor';
import { ActionTypeTextField } from './common';

type ActionSelectorProps = { taskIndex: number };

const lsSchema = yup.object().shape({
  id: yup.number(),
  isHtml: yup.boolean(),
  text: yup.string()
});

const lsRequiredSchema = yup.object().shape({
  id: yup.number(),
  isHtml: yup.boolean(),
  text: yup.string().required('Subject is required')
});

const schema = yup.object().shape({
  id: yup.number(),
  groupId: yup.number(),
  required: yup.boolean(),
  sendEmailOnCompletionEnabled: yup.boolean(),
  onCompletedEmail: yup.object().when('sendEmailOnCompletionEnabled', {
    is: true,
    then: yup.object().shape({
      id: yup.number(),
      subject: lsRequiredSchema,
      body: lsRequiredSchema,
      additionalRecipients: yup
        .string()
        .matches(
          /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)(,\s*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)*$/,
          'Invalid email/s'
        )
    })
  }),
  tasks: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      name: lsSchema,
      description: lsSchema,
      summary: lsSchema,
      instructions: lsSchema,
      requiresApproval: yup.boolean(),
      actionType: yup.string(),
      action: yup.string().nullable(true)
    })
  )
});

const TaskFormSelector: React.FC<ActionSelectorProps> = ({ taskIndex }) => {
  const { loading, data: fsData } = useForms();

  return loading ? (
    <CircularProgress color="primary" />
  ) : fsData?.site.forms != null ? (
    <TextField name={`tasks[${taskIndex}].action`} label="Form" select>
      {fsData.site.forms.map(f => (
        <MenuItem key={f.id} value={String(f.id)}>
          {f.name}
        </MenuItem>
      ))}
    </TextField>
  ) : null;
};

const TaskVideoField: React.FC<ActionSelectorProps> = ({ taskIndex }) => <TextField name={`tasks[${taskIndex}].action`} label="Video ID" />;

const TaskLinkField: React.FC<ActionSelectorProps> = ({ taskIndex }) => <TextField name={`tasks[${taskIndex}].action`} label="Link" />;

const TaskActionSelector: React.FC<{ selectedTaskId: number | undefined }> = ({ selectedTaskId }) => {
  const [{ value: tasks }] = useField<Edit.Task[]>('tasks');
  const taskIndex = tasks.findIndex(t => t.id === selectedTaskId);

  const selectedTask = tasks.find(t => t.id === selectedTaskId);

  switch (selectedTask?.actionType) {
    case TaskActionType.webForm:
      return <TaskFormSelector taskIndex={taskIndex} />;
    case TaskActionType.video:
      return <TaskVideoField taskIndex={taskIndex} />;
    case TaskActionType.link:
      return <TaskLinkField taskIndex={taskIndex} />;
    default:
      return selectedTask?.actionType != null ? <div>{selectedTask.actionType}</div> : null;
  }
};

const TaskForm: React.FC<{ name: string, id: number | undefined }> = ({ name, id }) => {
  const [field] = useField<boolean>({ name: `${name}.sendEmailOnCompletionEnabled`, type: 'checkbox' });

  return (
    <Grid container spacing={2} direction="column">
      <LSTextField item name={`${name}.name`} label="Name" />
      <LSTextField item name={`${name}.summary`} label="Summary" />
      <LSTextField item name={`${name}.description`} label="Description" />
      <LSTextField item name={`${name}.instructions`} label="Instructions" />
      <Checkbox item name={`${name}.requiresApproval`} label="Require coach approval" />
      <Checkbox item name={`${name}.sendEmailOnCompletionEnabled`} label={<LanguageString groupName="EDITOR" resourceName="SEND_EMAIL_ON_COMPLETION" defaultText="Send email on completion" />} />
      {field.checked && <CompletedEmailSection name={name} />}
      <ActionTypeTextField item name={`${name}.actionType`} />
      <Grid item>
        <TaskActionSelector selectedTaskId={id} />
      </Grid>
    </Grid>
  );
};

const TaskListInner: React.FC<FieldArrayRenderProps> = ({ name, push, remove }) => {
  const [{ value: tasks }] = useField<Edit.Task[]>(name);
	const [selectedTaskId, setSelectedTaskId] = useState<number>();
  const formik = useFormikContext<Edit.Milestone>();
  const { cacheContext: cc } = useContext(PathEditorContext);

  if (selectedTaskId && !tasks.find(t => t.id === selectedTaskId)) setSelectedTaskId(undefined);

  return (
    <Card>
      <CardHeader
        title={<LanguageString groupName="EDITOR" resourceName="TASKS" defaultText="Tasks" />}
        action={
          <Callout name="edit-ms" body={<LanguageString groupName="HELP" resourceName="ADD_TASKS" />} step={3}>
            <IconButton icon="add" onClick={() => push(Edit.createNewTask({ cc, pId: formik.values.id }))} />
          </Callout>
        }
      />
      <Divider />
      <Callout name="edit-ms" body={<LanguageString groupName="HELP" resourceName="CLICK_EDIT_TASK" />} step={2}>
        <List>
          {tasks.map(task => {
            return (
              <ListItem key={task.id} selected={task.id === selectedTaskId} button onClick={() => setSelectedTaskId(task.id)}>
                <ListItemText primary={<LanguageString languageString={task.name} />} />
                {tasks.length > 1 && (
                  <ListItemSecondaryAction>
                    <IconButton icon="delete" onClick={() => remove(tasks.indexOf(task))} edge="end" />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </Callout>
      {selectedTaskId && (
        <CardContent>
          <TaskForm name={`${name}[${tasks.findIndex(t => t.id === selectedTaskId)}]`} id={selectedTaskId} />
        </CardContent>
      )}
    </Card>
  );
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
const TaskList = TaskListInner as React.FC<FieldArrayRenderProps | void>;

type MilestoneEditorFormProps = {
  group: Edit.MilestoneGroup;
  milestone: Edit.Milestone;
  onSaved?: (ms: Milestone, grpId: number) => void;
};

const CompletedEmailSection: React.FC<{ name?: string }> = ({ name }) => (
  <Grid container item spacing={2} direction="column">
    <LSTextField item name={`${name ? `${name}.` : ''}onCompletedEmail.subject` } label="Subject" />
    <LSTextField item name={`${name ? `${name}.` : ''}onCompletedEmail.body`} label="Body" rows={8} />
    <TextField item name={`${name ? `${name}.` : ''}onCompletedEmail.additionalRecipients`} label="Additional Recipients" hint="Comma separated list if more than one" />
  </Grid>
);

const MilestoneDetail: React.FC = () => {
  const [field] = useField<boolean>({ name: 'sendEmailOnCompletionEnabled', type: 'checkbox' });

  return (
    <Grid container spacing={2} direction="column">
      <Checkbox item name="required" label={<LanguageString groupName="EDITOR" resourceName="REQUIRED" defaultText="Required" />} />
      <Checkbox item name="sendEmailOnCompletionEnabled" label={<LanguageString groupName="EDITOR" resourceName="SEND_EMAIL_ON_COMPLETION" defaultText="Send email on completion" />} />
      <Checkbox item name="allowMultipleInstances" label={<LanguageString groupName="EDITOR" resourceName="ALLOW_MULTIPLE_INST" />} />
      {field.checked && <CompletedEmailSection />}
      <LSTextField item name="tasks[0].name" label="Name" />
    </Grid>
  );
};

const MilestoneAndTasks: React.FC = () => (
  <Grid container spacing={2} direction="column">
    <Grid item>
      <Card>
        <CardHeader
          title={<LanguageString groupName="EDITOR" resourceName="MILESTONE" defaultText="Milestone" />}
          action={
            <Callout body={<LanguageString groupName="HELP" resourceName="STAGE_CHANGES" />} name="save-help" step={1} of={3} dependsOn={['edit-grp', 'edit-ms']}>
              <IconButton icon="save_alt" type="submit" />
            </Callout>
          }
        />
        <Divider />
        <Callout name="edit-ms" body={<LanguageString groupName="HELP" resourceName="EDIT_MS" />} step={1} of={3} placement="left">
          <CardContent>
            <MilestoneDetail />
          </CardContent>
        </Callout>
      </Card>
    </Grid>
    <Grid item>
      <FieldArray name="tasks" component={TaskList} />
    </Grid>
  </Grid>
);

export function setIfChanged<T>(original: T, newVal: T, prop: keyof T) {
  if (original[prop] !== newVal[prop]) original[prop] = newVal[prop];
}

type LSProps<T> = {
  [P in keyof T as NonNullable<T[P]> extends LsValues ? P : never]: LsValues;
};

export function updateLs<T extends LSProps<T>>(t: T, s: T, prop: keyof LSProps<T>) {
  const ls2 = s[prop];
  const ls1 = t[prop] ?? (ls2 != null ? { id: tmpId.nextId, isHtml: false } : undefined);

  if (ls1 == null || ls2 == null) return;

  if (ls1.text != ls2.text || ls1.isHtml != ls2.isHtml) t[prop] = ls2;
}

type CompletedEmailNotifier = {
  onCompletedEmail?: Edit.MilestoneCompletedEmail;
  sendEmailOnCompletionEnabled: boolean;
};
const updateEmail = (tMS: CompletedEmailNotifier, sMS: CompletedEmailNotifier) => {
  const tEmail = tMS.onCompletedEmail;
  const sEmail = sMS.onCompletedEmail;

	if (sEmail != null && sEmail.id == null) sEmail.id = tmpId.nextId;

  setIfChanged(tMS, sMS, 'sendEmailOnCompletionEnabled');

  if (tEmail == null) {
    if (sEmail != null) tMS.onCompletedEmail = sEmail;
  } else if (sEmail != null) {
    setIfChanged(tEmail, sEmail, 'additionalRecipients');
    setIfChanged(tEmail, sEmail, 'body');
    setIfChanged(tEmail, sEmail, 'subject');
  } else {
    tMS.onCompletedEmail = undefined;
  }
};

export const updateTasks = (tasks1?: Edit.Task[], tasks2?: Edit.Task[], pId?: number, cc?: Edit.CacheContext) => {
  if (tasks1 == null || tasks2 == null || pId == null || cc == null) return;

  const delTasks = tasks1.filter(t1 => tasks2.find(t2 => t2.id === t1.id) == null);
  const addTasks = tasks2.filter(t2 => tasks1.find(t1 => t1.id === t2.id) == null);

  delTasks.forEach(dt =>
    tasks1.splice(
      tasks1.findIndex(t1 => t1.id === dt.id),
      1
    )
  );

  tasks1.forEach(ot => {
    const nt = tasks2.find(t2 => t2.id === ot.id);
    if (nt != null) {
      updateLs(ot, nt, 'name');
      updateLs(ot, nt, 'summary');
      updateLs(ot, nt, 'description');
      updateLs(ot, nt, 'instructions');
      setIfChanged(ot, nt, 'requiresApproval');
      setIfChanged(ot, nt, 'actionType');
      setIfChanged(ot, nt, 'action');
      updateEmail(ot, nt);
    }
  });

  addTasks.forEach(nt => {
    const ot = cc.tasks[nt.id];
    if (ot != null) {
      updateLs(ot, nt, 'name');
      updateLs(ot, nt, 'summary');
      updateLs(ot, nt, 'description');
      updateLs(ot, nt, 'instructions');
      setIfChanged(ot, nt, 'requiresApproval');
      setIfChanged(ot, nt, 'actionType');
      setIfChanged(ot, nt, 'action');
      updateEmail(ot, nt);
    }
  });

  addTasks.length && tasks1.push(...addTasks.map(t => Edit.convertTask(t, cc, pId)));
};

export const MilestoneEditorForm: React.FC<MilestoneEditorFormProps> = ({ group, milestone, onSaved }) => {
  const { controller: confirmCtrl, props: confirmedProps } = useDialogController(false);
  const [updateMs] = useUpdateMilestone({ onCompleted: () => confirmCtrl.setOpen() });
  const { cache, cacheContext, refresh, hasUnsavedChanges } = useContext(PathEditorContext);

  const handleSubmit = async (values: Edit.Milestone, formik: FormikHelpers<Edit.Milestone>) => {
    if (cache.length !== 0) {
      updateEmail(milestone, values);
      setIfChanged(milestone, values, 'required');
      setIfChanged(milestone, values, 'allowMultipleInstances');
      updateTasks(milestone.tasks, values.tasks, milestone.id, cacheContext);
      refresh();
      formik.resetForm();
    } else {
      const input = [Dto.convertMilestone(values, group.id)];
      await updateMs({
        variables: { input },
        update: (store, data) => {
          if (data.data?.updateMilestones?.[0] != null) {
            const newMs = data.data.updateMilestones[0];
            store.modify({
              id: `MilestoneGroup:${input[0].groupId}`,
              fields: {
                milestones: (milestones: Milestone[] = []) =>
                  milestones.find(ms => ms.id === newMs.id) == null ? [...milestones, newMs] : milestones
              }
            });
            if (onSaved != null) onSaved(newMs, group.id);
          }
        }
      });
    }
  };

  return (
    <Formik initialValues={milestone} enableReinitialize onSubmit={handleSubmit} validationSchema={schema}>
      {formik => {
        hasUnsavedChanges.current = formik.dirty;

        return (
          <Form>
            <MilestoneAndTasks />
            <Dialog {...confirmedProps}>
              <DialogTitle>Milestone Saved</DialogTitle>
              <DialogContent dividers>Changes to the milestone have been saved</DialogContent>
              <DialogActions>
                <Button onClick={() => confirmCtrl.setClose()}>Ok</Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};
