import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { RecordOfContact, Role } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Result = { sendEmployeeInfo: QueryType<RecordOfContact>[] };
type Variables = { toUsers: number[], currentRole: Role, currentUserId: number };
export const useSendEmployeeInfo = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation sendEmployeeInfo($toUsers: [Int]!, $currentUserId: Int!, $currentRole: Role!) {
      sendEmployeeInfo(toUsers: $toUsers, currentUserId: $currentUserId, currentRole: $currentRole) {
        id
        date
        isPrivate
        from {
          id
          username
          firstName
          lastName
        }
        to {
          id
          username
          firstName
          lastName
        }
        rocType {
          id
          name {
            id
            text
          }
          sortIndex
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
