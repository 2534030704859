import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import { LanguageString, TextField, Select } from '../../Common';
import { useNotificationTemplates, useSite, useCustomProperties } from '../../../hooks';
import { Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import * as DTO from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { useSaveNotificationTrigger, useDeleteNotificationTrigger } from '../../../hooks';
// import { questionsIterator } from '../../DynamicForm/common';

interface NotificationScheduleEditorProps {
  open: boolean;
  toggle: () => void;
  trigger?: DTO.NotificationTrigger;
}

interface FormType {
  name: string;
  successPathId?: number;
  customPropertyTypeId?: number;
  customPropertyValue?: string;
  type: number;
  operand?: string;
  operandSegmentOne?: string;
  operandSegmentTwo?: string;
  operandSegmentThree?: string;
  secondaryOperand?: string;
  secondaryOperandSegmentOne?: string;
  secondaryOperandSegmentTwo?: string;
  secondaryOperandSegmentThree?: string;
  triggerRate?: number;
  notificationTemplateId?: number;
  dripCustomPropertyId?: number;
  killSwitchDripCustomPropertyId?: number;
  dripScheduling?: number;
  dripTimeExtensionType?: number;
  milestoneIds?: string;
}

const schema = () =>
  Yup.object().shape({
    name: Yup.string().required('* Required'),
    type: Yup.string().required('* Required'),
    operand: Yup.string(),
    secondaryOperand: Yup.string(),
    notificationTemplateId: Yup.string().required('* Required')
  }
);

const types = [
  { id: 1, text: 'MTS Inactive', value: DTO.TriggerType.MTS_INACTIVE },
  { id: 2, text: 'MTS Not Submitted', value: DTO.TriggerType.MTS_NOT_SUBMIT },
  { id: 3, text: 'Roadmap Inactive', value: DTO.TriggerType.ROADMAP_INACTIVE },
  { id: 4, text: 'Drip Notification', value: DTO.TriggerType.DRIP_NOTIFICATION}
];

const dripNotifSelection = [
  { id: 1, text: 'Daily', value: DTO.DripScheduleType.DAILY },
  { id: 2, text: 'Weekly', value: DTO.DripScheduleType.WEEKLY },
  { id: 3, text: 'Monthly', value: DTO.DripScheduleType.MONTHLY }
];
const timeExtensionSelection = [
  { id: 1, text: 'Days', value: DTO.TimeExtensionType.DAYS },
  { id: 2, text: 'Weeks', value: DTO.TimeExtensionType.WEEKS },
  { id: 3, text: 'Months', value: DTO.TimeExtensionType.MONTHS }
];

const triggerTypeId = (type: DTO.TriggerType) => {
  return types.find(t => t.value === type)?.id;
};

const NotificationScheduleOperandField: React.FC<{ trigger?: DTO.NotificationTrigger, useSecondaryOperand: boolean }> = ({ trigger, useSecondaryOperand }) => {
  const formikContext = useFormikContext<FormType>();
  const selectedType = types.find(t => t.id === formikContext.values.type);

  useEffect(() => {
    formikContext.setFieldValue('operand', undefined);
    formikContext.setFieldValue('operandSegmentOne', undefined);
    formikContext.setFieldValue('operandSegmentTwo', undefined);
    formikContext.setFieldValue('operandSegmentThree', undefined);
    formikContext.setFieldValue('secondaryOperand', undefined);
    formikContext.setFieldValue('secondaryOperandSegmentOne', undefined);
    formikContext.setFieldValue('secondaryOperandSegmentTwo', undefined);
    formikContext.setFieldValue('secondaryOperandSegmentThree', undefined);
  }, [formikContext.values.type]);

  useEffect(() => {
    const dashCount = (formikContext.values.operand?.match(/-/g) || []).length ?? 0;
    if (formikContext.values.operand) {
      if (dashCount === 2) {
        const currentSegments = `${formikContext.values.operandSegmentOne}-${formikContext.values.operandSegmentTwo}-${formikContext.values.operandSegmentThree}`;
        if (formikContext.values.operand !== currentSegments) {
          const segments = formikContext.values.operand.split('-');
          formikContext.setFieldValue('operandSegmentOne', segments[0]);
          formikContext.setFieldValue('operandSegmentTwo', segments[1]);
          formikContext.setFieldValue('operandSegmentThree', segments[2]);
        }
      } else if (dashCount === 1) {
        const currentSegments = `${formikContext.values.operandSegmentOne}-${formikContext.values.operandSegmentTwo}`;
        if (formikContext.values.operand !== currentSegments) {
          const segments = formikContext.values.operand.split('-');
          formikContext.setFieldValue('operandSegmentOne', segments[0]);
          formikContext.setFieldValue('operandSegmentTwo', segments[1]);
        }
      } else {
        if (formikContext.values.operand !== formikContext.values.operandSegmentOne) {
          formikContext.setFieldValue('operandSegmentOne', formikContext.values.operand);
        }
      }
    }
  }, [formikContext.values.operand, trigger]);

  useEffect(() => {
    const dashCount = (formikContext.values.secondaryOperand?.match(/-/g) || []).length ?? 0;
    if (formikContext.values.secondaryOperand) {
      if (dashCount === 2) {
        const currentSegments = `${formikContext.values.secondaryOperandSegmentOne}-${formikContext.values.secondaryOperandSegmentTwo}-${formikContext.values.secondaryOperandSegmentThree}`;
        if (formikContext.values.secondaryOperand !== currentSegments) {
          const segments = formikContext.values.secondaryOperand.split('-');
          formikContext.setFieldValue('secondaryOperandSegmentOne', segments[0]);
          formikContext.setFieldValue('secondaryOperandSegmentTwo', segments[1]);
          formikContext.setFieldValue('secondaryOperandSegmentThree', segments[2]);
        }
      } else if (dashCount === 1) {
        const currentSegments = `${formikContext.values.secondaryOperandSegmentOne}-${formikContext.values.secondaryOperandSegmentTwo}`;
        if (formikContext.values.secondaryOperand !== currentSegments) {
          const segments = formikContext.values.secondaryOperand.split('-');
          formikContext.setFieldValue('secondaryOperandSegmentOne', segments[0]);
          formikContext.setFieldValue('secondaryOperandSegmentTwo', segments[1]);
        }
      } else {
        if (formikContext.values.secondaryOperand !== formikContext.values.secondaryOperandSegmentOne) {
          formikContext.setFieldValue('secondaryOperandSegmentOne', formikContext.values.secondaryOperand);
        }
      }
    }
  }, [formikContext.values.secondaryOperand, trigger]);

  const changedSegment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let segmentOne: string | undefined = formikContext.values.operandSegmentOne;
    let segmentTwo: string | undefined = formikContext.values.operandSegmentTwo;
    let segmentThree: string | undefined = formikContext.values.operandSegmentThree;

    //Set temporary segment values
    if (event.target.name === 'operandSegmentOne') {
      segmentOne = event.target.value;
    } else if (event.target.name === 'operandSegmentTwo') {
      segmentTwo = event.target.value;
    } else if (event.target.name === 'operandSegmentThree') {
      segmentThree = event.target.value;
    }

    let val = '';
    if (segmentOne) {
      if (segmentTwo && segmentThree) {
        val = `${segmentOne}-${segmentTwo}-${segmentThree}`;
      } else if (segmentTwo) {
        val = `${segmentOne}-${segmentTwo}`;
      } else {
        val = `${segmentOne}`;
      }
    }

    formikContext.setFieldValue('operand', val);
  };

  const changedSecondarySegment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let segmentOne: string | undefined = formikContext.values.secondaryOperandSegmentOne;
    let segmentTwo: string | undefined = formikContext.values.secondaryOperandSegmentTwo;
    let segmentThree: string | undefined = formikContext.values.secondaryOperandSegmentThree;

    //Set temporary segment values
    if (event.target.name === 'secondaryOperandSegmentOne') {
      segmentOne = event.target.value;
    } else if (event.target.name === 'secondaryOperandSegmentTwo') {
      segmentTwo = event.target.value;
    } else if (event.target.name === 'secondaryOperandSegmentThree') {
      segmentThree = event.target.value;
    }

    let val = '';
    if (segmentOne) {
      if (segmentTwo && segmentThree) {
        val = `${segmentOne}-${segmentTwo}-${segmentThree}`;
      } else if (segmentTwo) {
        val = `${segmentOne}-${segmentTwo}`;
      } else {
        val = `${segmentOne}`;
      }
    }

    formikContext.setFieldValue('secondaryOperand', val);
  };

  if (selectedType) {
    if (selectedType.value === DTO.TriggerType.MTS_INACTIVE || selectedType.value === DTO.TriggerType.ROADMAP_INACTIVE) {
      return (
        <>
          {useSecondaryOperand ? (
            <>
              <TextField item xs={12} name="secondaryOperandSegmentOne" label="Day of Week" onChange={changedSecondarySegment} />
              <TextField item xs={12} name="secondaryOperandSegmentTwo" label="Hour of Day" onChange={changedSecondarySegment} />
              <TextField
                item
                xs={12}
                name="secondaryOperandSegmentThree"
                label="Hourly Offset"
                onChange={changedSecondarySegment}
                hint={
                  `This offset is used to check the user's most recent record. If it's older than the entered offset,
                  they're considered to be inactive. For example: if you enter 4 and they haven't entered a record
                  in the last four hours, they're inactve.`
                }
              />
            </>
          ): (
              <>
                <TextField item xs={6} name="operandSegmentOne" label="Day of Month" onChange={changedSegment} />
                <TextField item xs={6} name="operandSegmentTwo" label="Hour of Day" onChange={changedSegment} />
                <TextField
                  item
                  xs={12}
                  name="operandSegmentThree"
                  label="Hourly Offset"
                  onChange={changedSegment}
                  hint={
                    `This offset is used to check the user's most recent record. If it's older than the entered offset,
                    they're considered to be inactive. For example: if you enter 4 and they haven't entered a record
                    in the last four hours, they're inactve.`
                  }
                />
              </>
          )}
          <TextField
            item
            xs={12}
            name="triggerRate"
            label="Repeat Notification Delay (in seconds)"
            rows={1}
            hint={
              `The delay to be used before the next attempt is made to send this notification (in seconds). For example:
            another attempt to send this notification will not be made in 2 minutes if 120 is entered. Trigger rates are required for
            MTS Inactive and Roadmap Inactive notification types`
            }
          />
        </>
      );
    } else if (selectedType.value === DTO.TriggerType.MTS_NOT_SUBMIT) {
      return useSecondaryOperand ? (
        <>
          <TextField item xs={6} name="secondaryOperandSegmentOne" label="Day of Week" onChange={changedSecondarySegment} />
          <TextField item xs={6} name="secondaryOperandSegmentTwo" label="Hour of Day" onChange={changedSecondarySegment} />
        </>
      ): (
          <>
            <TextField item xs={6} name="operandSegmentOne" label="Day of Month" onChange={changedSegment} />
            <TextField item xs={6} name="operandSegmentTwo" label="Hour of Day" onChange={changedSegment} />
          </>
      );
    }
  }

  return null;
};

const DripNotificationSelection: React.FC<{ trigger?: DTO.NotificationTrigger }> = ({ trigger }) => {
  const formikContext = useFormikContext<FormType>();
  const { data: customPropertyTypes } = useCustomProperties();
  const selectedType = types.find(t => t.id === formikContext.values.type);

  useEffect(() => {
    formikContext.setFieldValue('milestoneIds', trigger?.milestoneIds?.join(',') ?? undefined);
    formikContext.setFieldValue('dripCustomPropertyId', trigger?.dripCustomPropertyId ?? undefined);
    formikContext.setFieldValue('killSwitchDripCustomPropertyId', trigger?.killSwitchDripCustomPropertyId ?? undefined);
    formikContext.setFieldValue('dripScheduling', trigger?.dripSchedule ? dripNotifSelection.find(t => t.value === trigger.dripSchedule)?.id : undefined);
    formikContext.setFieldValue('dripTimeExtensionType', trigger?.dripTimeExtensionType ? timeExtensionSelection.find(t => t.value === trigger.dripTimeExtensionType)?.id : undefined);

    const operandValues = trigger?.operand?.split('-');
    if (operandValues) {
      formikContext.setFieldValue('operandSegmentOne', operandValues.length > 0 ? operandValues[0] : undefined);
      formikContext.setFieldValue('operandSegmentTwo', operandValues.length > 1 ? operandValues[1] : undefined);
      formikContext.setFieldValue('operandSegmentThree', operandValues.length > 2 ? operandValues[2] : undefined);
    }
  }, [trigger]);

  const changedSegment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let segmentOne: string | undefined = formikContext.values.operandSegmentOne;
    let segmentTwo: string | undefined = formikContext.values.operandSegmentTwo;
    let segmentThree: string | undefined = formikContext.values.operandSegmentThree;
    if (event.target.name === 'operandSegmentOne') {
      segmentOne = event.target.value;
    } else if (event.target.name === 'operandSegmentTwo') {
      segmentTwo = event.target.value;
    } else if (event.target.name === 'operandSegmentThree') {
      segmentThree = event.target.value;
    }
    let val = '';
    if (segmentOne) {
      if (segmentTwo && segmentThree) {
        val = `${segmentOne}-${segmentTwo}-${segmentThree}`;
      } else if (segmentTwo) {
        val = `${segmentOne}-${segmentTwo}`;
      } else {
        val = `${segmentOne}`;
      }
    }
    formikContext.setFieldValue('operand', val);
  };

  if (selectedType?.value === DTO.TriggerType.DRIP_NOTIFICATION) {
    return (
      <>
        <Select
          item
          xs={12}
          name="dripCustomPropertyId"
          label="Trigger Custom Property"
          items={(customPropertyTypes?.site.customProperties ?? []).map(p => ({id: p.id, text: p.name ?? '', value: p.id.toString()}))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            formikContext.setFieldValue('dripCustomPropertyId', event.target.value);
          }}
        />
        <Select
          item
          xs={6}
          name="dripTimeExtensionType"
          label="Trigger valid for (measurement)"
          items={timeExtensionSelection}
        />
        <TextField item xs={6} name="operandSegmentTwo" label="Trigger valid for (amount)" onChange={changedSegment}></TextField>
        <Select
          item
          xs={formikContext.values.dripScheduling === 1 ? 6 : 4}
          name="dripScheduling"
          label="Trigger Scheduling"
          items={dripNotifSelection}
        />
        {formikContext.values.dripScheduling !== 1 && (
          <>
            <TextField
              item
              xs={formikContext.values.dripScheduling === 1 ? 6 : 4}
              name="operandSegmentThree"
              label="Trigger Days"
              onChange={changedSegment}
              hint={
                `Set trigger days, starting at zero, in a comma delimited list`
              }
            ></TextField>
          </>
        )}
        <TextField item xs={formikContext.values.dripScheduling === 1 ? 6 : 4} name="operandSegmentOne" label="Trigger Time (hours)" onChange={changedSegment}></TextField>
        <TextField
            item
            xs={12}
            name="triggerRate"
            label="Repeat Notification Delay (in seconds)"
            rows={1}
            hint={
              `The delay to be used before the next attempt is made to send this notification (in seconds). For example:
            another attempt to send this notification will not be made in 2 minutes if 120 is entered. Trigger rates are required for
            MTS Inactive and Roadmap Inactive notification types`
            }
          />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">Filters</Typography>
        </Grid>
        <Select
          item
          xs={6}
          name="killSwitchDripCustomPropertyId"
          label="Manual Kill Switch Custom Property"
          items={(customPropertyTypes?.site.customProperties ?? []).map(p => ({id: p.id, text: p.name ?? '', value: p.id.toString()}))}
        />
        <TextField item xs={6} name="milestoneIds" label="Milestone Ids (seprated by comma)"></TextField>
      </>
    );
  }
  return null;
};

const CustomPropertyTypeField: React.FC<{ trigger?: DTO.NotificationTrigger, name: string, title: string, valueName: string, valueTitle: string }> = ({ trigger, name, title, valueName, valueTitle }) => {
  const formikContext = useFormikContext<FormType>();
  const selectedType = types.find(t => t.id === formikContext.values.type);
  const { data: customPropertyTypes } = useCustomProperties();

  useEffect(() => {
    formikContext.setFieldValue('customPropertyTypeId', undefined);
  }, [formikContext.values.type]);

  useEffect(() => {
    if (formikContext.values.customPropertyTypeId) {
      formikContext.setFieldValue('customPropertyTypeId', formikContext.values.customPropertyTypeId);
    }
  }, [formikContext.values.customPropertyTypeId, trigger]);

  const handleSuccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue(name, event.target.value);
  };

  if (selectedType && selectedType.value !== DTO.TriggerType.ROADMAP_INACTIVE) {
    return (
      <>
        <Select
          item
          xs={6}
          name={name}
          label={title}
          items={(customPropertyTypes?.site.customProperties ?? []).map(p => ({id: p.id, text: p.name ?? '', value: p.id.toString()}))}
          onChange={handleSuccess}
        />
        <TextField item xs={6} name={valueName} label={valueTitle} />
      </>
    );
  };

  return null;
};

const NotificationScheduleSuccessField: React.FC<{ trigger?: DTO.NotificationTrigger }> = ({ trigger }) => {
  const formikContext = useFormikContext<FormType>();
  const selectedType = types.find(t => t.id === formikContext.values.type);
  const { data } = useSite();

  useEffect(() => {
    formikContext.setFieldValue('successPathId', undefined);
  }, [formikContext.values.type]);

  useEffect(() => {
    if (formikContext.values.successPathId) {
      formikContext.setFieldValue('successPathId', formikContext.values.successPathId);
    }
  }, [formikContext.values.successPathId, trigger]);

  const handleSuccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue('successPathId', event.target.value);
  };

  if (selectedType && (selectedType.value === DTO.TriggerType.MTS_INACTIVE || selectedType.value === DTO.TriggerType.MTS_NOT_SUBMIT)) {
    return (
      <Select
        item
        xs={12}
        name="successPathId"
        label="Success Path"
        items={(data?.site.paths ?? []).map(p => ({id: p.id, text: p.name?.text ?? '', value: p.id.toString()}))}
        onChange={handleSuccess}
      />
    );
  };

  return null;
};

export const NotificationScheduleEditor: React.FC<NotificationScheduleEditorProps> = ({ open, toggle, trigger }) => {
  const formik = useRef<FormikProps<FormType> | null>(null);
  const notificationTemplates = useNotificationTemplates();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [saveNotificationTrigger] = useSaveNotificationTrigger({ refetchQueries: ['notificationTriggers'] });
  const [deleteNotificationTrigger] = useDeleteNotificationTrigger({  refetchQueries: ['notificationTriggers'] });
  const selectNotificationTemplates = useMemo(() => {
    return notificationTemplates.data?.site.notificationTemplates?.map(t => { return { id: t.id, text: t.name ?? '', value: t.id.toString() }; }) ?? [];
  }, [notificationTemplates.data]);
  const [useSecondaryOperand, setUseSecondaryOperand] = useState(false);
  const changeSecondaryOperand = (e: boolean) => {
    if (useSecondaryOperand && !e) {
      formik.current?.setFieldValue('secondaryOperand', undefined);
    }
    setUseSecondaryOperand(e);
  };

  useEffect(() => {
    setUseSecondaryOperand(Boolean(trigger?.secondaryOperand && trigger.secondaryOperand.length > 0));
  }, [trigger]);

  const initialValues: FormType = {
    name: '',
    successPathId: undefined,
    customPropertyValue: undefined,
    customPropertyTypeId: undefined,
    type: types[0].id,
    operand: '',
    secondaryOperand: '',
    triggerRate: undefined,
    notificationTemplateId: undefined
  };

  const initialStatus = {
    errors: [] as string[]
  };

  useEffect(() => {
    formik.current?.setFieldValue('name', trigger?.name ?? '');
    formik.current?.setFieldValue('successPathId', trigger?.successPathId ?? undefined);
    formik.current?.setFieldValue('customPropertyTypeId', trigger?.customPropertyTypeId ?? undefined);
    formik.current?.setFieldValue('customPropertyValue', trigger?.customPropertyValue ?? undefined);
    formik.current?.setFieldValue('type', trigger?.type ? triggerTypeId(trigger.type) : triggerTypeId);
    formik.current?.setFieldValue('operand', trigger?.operand ?? undefined);
    formik.current?.setFieldValue('secondaryOperand', trigger?.secondaryOperand ?? undefined);
    formik.current?.setFieldValue('triggerRate', trigger?.triggerRate ?? undefined);
    formik.current?.setFieldValue('notificationTemplateId', trigger?.notificationTemplate?.id);
  }, [trigger, open]);

  const _toggle = () => {
    formik.current?.resetForm();
    toggle();
  };

  const toggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleSubmit = async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    if (values.notificationTemplateId && (values.operand || values.secondaryOperand)) {
      await saveNotificationTrigger({
        variables: {
          input: {
            id: trigger?.id,
            name: values.name,
            successPathId: values.successPathId,
            customPropertyTypeId: values.customPropertyTypeId,
            customPropertyValue: values.customPropertyValue,
            type: types.find(t => t.id === values.type)?.value ?? DTO.TriggerType.MTS_NOT_SUBMIT,
            triggerRate: Number(values.triggerRate),
            operand: values.operand ?? '',
            secondaryOperand: values.secondaryOperand,
            notificationTemplateId: values.notificationTemplateId,
            dripCustomPropertyId: values.dripCustomPropertyId ?? undefined,
            killSwitchDripCustomPropertyId: values.killSwitchDripCustomPropertyId ?? undefined,
            dripSchedule: values.dripScheduling ? dripNotifSelection[values.dripScheduling -1].value : undefined,
            dripTimeExtensionType: values.dripTimeExtensionType ? timeExtensionSelection[values.dripTimeExtensionType - 1].value : undefined,
            milestoneIds: values.milestoneIds ? values.milestoneIds.split(',').map(m => Number(m)) : []
          }
        },
        update: (c, r) => {
          _toggle();
        }
      });
    } else {
      console.log(`a notification template must be set`);
    }
  };

  const handleDelete = async () => {
    toggleDelete();

    if (trigger) {
      await deleteNotificationTrigger({
        variables: { id: trigger.id },
        update: (c, r) => {
          _toggle();
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize={false}
    >
      {formikVar => {
        formik.current = formikVar;

        return (
          <>
            <Dialog fullWidth maxWidth="md" open={open} onClose={_toggle}>
              <DialogTitle>{trigger?.name ? `Edit ${trigger.name}` : 'Create Scheduled Notification'}</DialogTitle>
              <DialogContent dividers>
                <Form>
                  <Grid container spacing={2}>
                    <TextField item xs={12} name="name" label="Name" rows={1} />
                    <Select
                      item
                      xs={6}
                      name="type"
                      label="Type"
                      items={types}
                    />
                    <Select
                      item
                      xs={6}
                      name="notificationTemplateId"
                      label="Notification Template"
                      items={selectNotificationTemplates}
                    />
                    {formikVar.values.type !== 4 && formikVar.values.type !== 3 && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">Filters</Typography>
                        </Grid>
                      </>
                    )}
                    <NotificationScheduleSuccessField trigger={trigger} />
                    {formik.current.values.type === 2 && (
                      <CustomPropertyTypeField
                        name="customPropertyTypeId"
                        title="Filter by Custom Property Type"
                        trigger={trigger}
                        valueName="customPropertyValue"
                        valueTitle="Filter by Custom Property Value"
                      />
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Scheduling</Typography>
                    </Grid>
                    {formik.current.values.type !== 4 && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch checked={useSecondaryOperand} onChange={(e) => changeSecondaryOperand(e.target.checked)} />
                          }
                          label={
                            <Typography>Send via Day of Week</Typography>
                          }
                        />
                      </Grid>
                    )}
                    {formik.current.values.type !== 4 ?
                      <NotificationScheduleOperandField trigger={trigger} useSecondaryOperand={useSecondaryOperand} />
                      : <DripNotificationSelection trigger={trigger}/>
                    }
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={_toggle}>
                  <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
                </Button>
                {trigger && (
                  <Button color="secondary" onClick={toggleDelete}>
                    <LanguageString groupName="GENERAL" resourceName="DELETE" defaultText="Delete" />
                  </Button>
                )}
                <Button color="primary" onClick={formikVar.submitForm}>
                  <LanguageString groupName="GENERAL" resourceName="SAVE" defaultText="Save" />
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={deleteDialog} onClose={toggleDelete}>
              <DialogTitle>Delete Scheduled Notification</DialogTitle>
              <DialogContent>
                <Typography>Are you sure you want to delete this scheduled notification?</Typography>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={toggleDelete}>
                  <LanguageString groupName="GENERAL" resourceName="NO" defaultText="No" />
                </Button>
                <Button color="primary" onClick={handleDelete}>
                  <LanguageString groupName="GENERAL" resourceName="YES" defaultText="Yes" />
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};
export default NotificationScheduleEditor;
