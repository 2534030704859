import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import { SiteContext, StudentContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { QueryType } from '../../helpers/query';
import UserHelper from '../../helpers/userHelper';
import { useSaveRoc } from '../../hooks';
import { DialogTitle, IconButton, LanguageString } from '../Common';
import { DynamicForm } from '../DynamicForm';
import { grpIterator } from '../PathEditor';
import { RecordOfContactPrintDialog } from './RecordOfContactPrintDialog';

interface RecordOfContactDialogProps {
  open: boolean;
  toggle: () => void;
  saved?: (roc: QueryType<DTO.RecordOfContact>) => void;
  recordOfContact?: QueryType<DTO.RecordOfContact>;
  rocTypes: QueryType<DTO.ROCType>[];
  rtMap: { [rtId: number]: DTO.ROCType };
}

interface FormSchema {
  dateOfContact: Date;
  typeOfContactId?: number;
  contactedBy: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const RecordOfContactDialog: React.FC<RecordOfContactDialogProps> = ({ open, toggle, recordOfContact, rocTypes, saved, rtMap }) => {
  const siteCtx = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);
  const userContext = useContext(UserContext);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const togglePrintDialog = () => setPrintDialogOpen(!printDialogOpen);
  const [saveRoc] = useSaveRoc();
  const [submitting, setSubmitting] = useState(false);
  const formik = useRef<FormikProps<FormSchema>>();
  const rtTypeId = recordOfContact?.rocType?.id;
  const [form, setForm] = useState<DTO.Form | undefined>(rtTypeId != null ? rtMap[rtTypeId]?.form : undefined);
  let milestones: DTO.Milestone[] = [];
  let canEdit = false;

  if (userContext.user) canEdit = UserHelper.isAdvisorOrAdmin(userContext.user);

  if (!studentCtx || !studentCtx.student) return null;

  if (studentCtx?.path?.milestoneGroups) {
    for (const group of grpIterator(studentCtx.path.milestoneGroups)) {
      if (group.milestones) milestones = milestones.concat(group.milestones);
    }
  }

  let contactBy = '';
  if (recordOfContact && recordOfContact.from) {
    contactBy = recordOfContact.from.firstName + ' ' + recordOfContact.from.lastName;
  } else if (canEdit) {
    contactBy = userContext.user?.firstName + ' ' + userContext.user?.lastName;
  }

  const initialValues: FormSchema = {
    dateOfContact: recordOfContact && recordOfContact.date ? new Date(recordOfContact.date) : new Date(),
    contactedBy: contactBy,
    typeOfContactId: recordOfContact?.rocType?.id ?? undefined
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const Validation = Yup.object().shape({
    typeOfContactId: Yup.string().required('* Required'),
    dateOfContact: Yup.string().required('* Required'),
    duration: Yup.string().required('* Required'),
    summary: Yup.string().required('* Required'),
    details: Yup.string().required('* Required')
  });

  const noop = () => undefined;

  const fs = recordOfContact?.formSubmission;

  const typeChanged: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void = (ev, _ch) => {
    formik.current?.handleChange(ev);
    setForm(rtMap[ev.target.value as number]?.form);
  };

  const handleSubmit = async (data: DTO.SubmitFormInput) => {
    const values = formik.current?.values;
    if (!canEdit || values == null) return;

    setSubmitting(true);

    const input: DTO.RecordOfContactInput = {
      id: recordOfContact?.id ?? undefined,
      date: values.dateOfContact,
      isPrivate: false,
      fromId: userContext.user?.id ?? 0,
      toId: studentCtx?.student.id ?? 0,
      rocTypeId: values.typeOfContactId ?? 0,
      formSubmission: data
    };

    await saveRoc({ variables: { input }, refetchQueries: ['getROCs'], awaitRefetchQueries: true });
    setSubmitting(false);
    toggle();
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={toggle} aria-labelledby="Record of Contact Form">
        <DialogTitle onClose={toggle}>
          <Box display="flex" justifyContent="space-between" width="92%">
            {recordOfContact
              ? <LanguageString groupName="ROC" resourceName="ROC" defaultText="Record of Contact" />
                : <LanguageString groupName="ROC" resourceName="ADD_ROC" defaultText="Add Record of Contact" />}
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Formik initialValues={initialValues} initialStatus={initialStatus} onSubmit={noop} validationSchema={Validation}>
            {formikProps => {
              formik.current = formikProps;
              return (
                <Form>
                    {/* Date of Contact & Topic */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field validateOnBlur validateOnChange name="dateOfContact">
                          {() => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                disabled={!canEdit}
                                fullWidth
                                label={<LanguageString groupName="ROC" resourceName="DATE_OF_CONTACT" defaultText="Date of Contact" />}
                                name="dateOfContact"
                                format="MM/dd/yyyy h:mm a"
                                value={formikProps.values.dateOfContact}
                                onChange={newDate => {
                                  formikProps.setFieldValue('dateOfContact', newDate);
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field validateOnBlur validateOnChange name="typeOfContactId">
                          {() => (
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="typeOfContactId">
                                <LanguageString groupName="ROC" resourceName="TYPE_OF_CONTACT" defaultText="Type of Contact" />
                              </InputLabel>
                              <Select
                                disabled={!canEdit}
                                fullWidth
                                labelId="typeOfContactId"
                                name="typeOfContactId"
                                value={formikProps.values.typeOfContactId}
                                error={Boolean(formikProps.errors.typeOfContactId && formikProps.touched.typeOfContactId)}
                                onChange={typeChanged}
                              >
                                {rocTypes.map(type => {
                                  return (
                                    <MenuItem key={`roc_type_${type.id}`} value={type.id}>
                                      {type.name?.text ?? ''}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </Grid>

                      {/* Contacted By */}
                      <Grid item xs={12}>
                        <Field validateOnBlur validateOnChange name="contactedBy">
                          {() => (
                            <TextField
                              fullWidth
                              disabled
                              name="contactedBy"
                              label={<LanguageString groupName="ROC" resourceName="CONTACT_BY" defaultText="Contact By" />}
                              value={formikProps.values.contactedBy}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              error={Boolean(formikProps.errors.contactedBy && formikProps.touched.contactedBy)}
                              helperText={formikProps.errors.contactedBy && formikProps.touched.contactedBy && String(formikProps.errors.contactedBy)}
                            />
                          )}
                        </Field>
                      </Grid>

                      {(formikProps.status as FormStatus).message && (
                        <Grid item xs={12}>
                          <Alert severity="error">{formikProps.status}</Alert>
                        </Grid>
                      )}
                    </Grid>
                </Form>
              );
            }}
          </Formik>
          {form != null && <DynamicForm formInst={form} formSubmissionInst={fs} autoloadFormSubmission={true} overrideSubmitHandler={handleSubmit} disabled={!canEdit || submitting} showSubmitButton={canEdit} />}
          {!canEdit && <Button variant="contained" style={{ marginTop: '16px' }} color="primary" onClick={toggle}>Close</Button>}
          <Box style={{ position: 'absolute', right: 20, bottom: 10 }}>
            {siteCtx.site?.downloadRocEnabled === true && recordOfContact?.id && (
              <Tooltip title={<LanguageString groupName="GENERAL" resourceName="ROC_TOOLTIP" defaultText="Print ROCs" />} placement="left">
                <IconButton style={{ color: 'gray', marginTop: -8 }} aria-label="print rocs" onClick={togglePrintDialog} icon="cloud_download" />
              </Tooltip>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <RecordOfContactPrintDialog open={printDialogOpen} toggle={togglePrintDialog} rocId={recordOfContact?.id} />
    </>
  );
};
export default RecordOfContactDialog;
