import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { SubmitFormInput, RoadmapMeeting } from '../../dto';
import { roadmapMeetingFrag } from './fragments';

type Result = { updateRoadmapMeeting: RoadmapMeeting };
type Variables = { input: SubmitFormInput };
export function useUpdateRoadmapMeeting(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${roadmapMeetingFrag}
    mutation updateRoadmapMeeting($input: SubmitFormInput!) {
      updateRoadmapMeeting(input: $input) {
        ...RoadmapMeetingFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateRoadmapMeeting;
