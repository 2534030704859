import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { RoadmapMilestoneGroup, UpdateRoadmapMilestoneGroupInput } from '../../dto';
import { QueryType } from '../../helpers/query';

type Result = { updateRoadmapMilestoneGroups: QueryType<RoadmapMilestoneGroup>[] };
type Variables = { input: UpdateRoadmapMilestoneGroupInput[] };
export function useUpdateRoadmapMilestoneGroups(options?: MutationHookOptions<Result, Variables>) {
  const submitFormMutation = gql`
    mutation updateRoadmapMilestoneGroups($input: [UpdateRoadmapMilestoneGroupInput!]!) {
      updateRoadmapMilestoneGroups(input: $input) {
        id
        goal
        sortIndex
      }
    }
  `;

  return useMutation<Result, Variables>(submitFormMutation, options);
}

export default useUpdateRoadmapMilestoneGroups;
