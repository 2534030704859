import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { NotificationTemplate, NotificationTemplateInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { notificationTemplateFrag } from './fragments';

type Result = { saveNotificationTemplate: QueryType<NotificationTemplate> };
type Variables = { input: NotificationTemplateInput };
export const useSaveNotificationTemplate = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${notificationTemplateFrag}

    mutation saveNotificationTemplate($input: NotificationTemplateInput!) {
      saveTemplate(input: $input) {
        ...NotificationTemplateFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
