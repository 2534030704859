import { Table, TableBody, TableHead, TableRow, Typography } from '@material-ui/core';
import { format, subMonths } from 'date-fns';
import React, { useMemo, useContext } from 'react';
import { LocalStorageKey, useLocalStorage, UserResult } from '../../hooks';
import { LanguageString } from '../Common';
import { CaseLoadMTSHeaderCell, CaseLoadOrder, CaseLoadOrderColumn, CaseLoadTableHeaderCell, CaseLoadTableCustomHeaderCell } from './CaseLoadCells';
import { initialColumnsSettings } from './CaseLoadFilterDialog/CaseLoadColumnSettingsTab';
import { CaseLoadTableRow } from './CaseLoadTableRow';
import { CaseLoadMTSTooltip } from './CaseLoadTooltips';
import { SiteContext } from '../../contexts';
import { MTSPeriod } from 'tuapath-common/generated/schema';

interface CaseLoadTableProps {
  clickedHeader: (column: CaseLoadOrderColumn, customPropName?: string) => void;
  students: UserResult[];
  order: CaseLoadOrder;
  currentPeriod?: MTSPeriod;
  previousPeriod?: MTSPeriod;
  twoPeriodsAgo?: MTSPeriod;
}

const serverDate = (dateStr: string) => {
  const dt = new Date(dateStr);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
};

const currentDate = new Date();

export const CaseLoadTable: React.FC<CaseLoadTableProps> = React.memo((
  { clickedHeader, students, order, currentPeriod, previousPeriod, twoPeriodsAgo }
) => {

  const site = useContext(SiteContext);
  const [columns] = useLocalStorage(LocalStorageKey.CaseLoadColumn, initialColumnsSettings);

  const customPropertyColumns = React.useMemo(() => {
    if (columns?.customProperties) {
      const s = site.site?.caseLoadCustomPropertyColumns?.split(',') || [];
      return Object.keys(columns.customProperties)
        .filter(key => s.includes(key))
        .filter(key => columns.customProperties[key] === true);
    }

    return [];
  }, [columns.customProperties]);

  const twoMonthsAgoDateStr = useMemo(
    () => twoPeriodsAgo
      ? `${format(serverDate(twoPeriodsAgo.startDate.toString()), 'MM/dd/yyyy')} - ${format(serverDate(twoPeriodsAgo.endDate.toString()), 'MM/dd/yyyy')}`
      : format(subMonths(currentDate, 2), 'MMM'),
    [twoPeriodsAgo]
  );

  const lastMonthsDateStr = useMemo(
    () => previousPeriod
      ? `${format(serverDate(previousPeriod.startDate.toString()), 'MM/dd/yyyy')} - ${format(serverDate(previousPeriod.endDate.toString()), 'MM/dd/yyyy')}`
      : format(subMonths(currentDate, 1), 'MMM'),
    [previousPeriod]
  );

  const currentMonthsDateStr = useMemo(
    () => currentPeriod
      ? `${format(serverDate(currentPeriod.startDate.toString()), 'MM/dd/yyyy')} - ${format(serverDate(currentPeriod.endDate.toString()), 'MM/dd/yyyy')}`
      : format(currentDate, 'MMM'),
    [currentPeriod]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.name && (
            <CaseLoadTableHeaderCell
              column={CaseLoadOrderColumn.Name}
              order={order}
              content={
                <LanguageString groupName="CASE_LOAD" resourceName="PARTICIPANT_NAME" defaultText="Participant Name" />
              }
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.twoMonthsAgoMTSStatus && (
            <CaseLoadMTSHeaderCell
              column={CaseLoadOrderColumn.TwoMonthsAgoMTS}
              align="left"
              order={order}
              content={
                <Typography style={{ wordWrap: 'break-word' }}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS" defaultText="MTS" /><br />
                  {twoMonthsAgoDateStr}
                </Typography>
              }
              tooltip={<CaseLoadMTSTooltip />}
              size="small"
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.lastMonthMTSStatus && (
            <CaseLoadMTSHeaderCell
              column={CaseLoadOrderColumn.LastMonthMTS}
              align="left"
              order={order}
              content={
                <Typography style={{ wordWrap: 'break-word' }}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS" defaultText="MTS" /><br />
                  {lastMonthsDateStr}
                </Typography>
              }
              tooltip={<CaseLoadMTSTooltip />}
              size="small"
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.currentMonthStatus && (
            <CaseLoadMTSHeaderCell
              column={CaseLoadOrderColumn.CurrentMTS}
              align="left"
              order={order}
              content={
                <Typography style={{ wordWrap: 'break-word' }}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS" defaultText="MTS" /><br />
                  {currentMonthsDateStr}
                </Typography>
              }
              tooltip={<CaseLoadMTSTooltip />}
              size="small"
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.coachMeeting && (
            <CaseLoadTableHeaderCell
              column={CaseLoadOrderColumn.CoachMeeting}
              order={order}
              content={
                <LanguageString groupName="CASE_LOAD" resourceName="ADVISOR_MEETING" defaultText="Advisor Meeting" />
              }
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.lastMTSInteraction && (
            <CaseLoadTableHeaderCell
              column={CaseLoadOrderColumn.LastMTSActivity}
              order={order}
              content={
                <LanguageString groupName="CASE_LOAD" resourceName="MTS_INTERACTION" defaultText="Last MTS Interaction" />
              }
              click={(c) => clickedHeader(c)}
            />
          )}
          {columns.lastRoadmapInteraction && (
            <CaseLoadTableHeaderCell
              column={CaseLoadOrderColumn.LastRoadmapInteraction}
              order={order}
              content={
                <LanguageString groupName="CASE_LOAD" resourceName="ROADAMP_INTERACTION" defaultText="Last Roadmap Interaction" />
              }
              click={(c) => clickedHeader(c)}
            />
          )}
          {customPropertyColumns.map((customPropertyColumn, index) => (
            <CaseLoadTableCustomHeaderCell
              key={`custom_prop_head_${index}`}
              align="center"
              column={CaseLoadOrderColumn.Custom}
              customPropName={customPropertyColumn}
              order={order}
              content={
                <LanguageString groupName='CASE_LOAD' resourceName={customPropertyColumn} defaultText={customPropertyColumn} />
              }
              click={() => clickedHeader(CaseLoadOrderColumn.Custom, customPropertyColumn)}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {students.map(student =>
          <CaseLoadTableRow
            key={`caseload_student_${student.id}`}
            student={student}
            columns={columns}
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            twoPeriodsAgo={twoPeriodsAgo}
            customPropertyColumns={customPropertyColumns}
          />
        )}
      </TableBody>
    </Table>
  );
});

export default CaseLoadTable;
