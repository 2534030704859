import { gql, useMutation } from '@apollo/client';
import { ForgotUsernameInput, ForgotUsernameResult } from 'tuapath-common/generated/schema';

export function useForgotUsername() {
  const mutation = gql`
    mutation forgotUsername($input: ForgotUsernameInput!) {
      forgotUsername(input: $input) {
        success
      }
    }
  `;

  return useMutation<{ forgotUsername: ForgotUsernameResult }, { input: ForgotUsernameInput }>(mutation);
}
