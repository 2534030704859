import React from 'react';
import {
  Icon,
  Grid,
  useTheme
} from '@material-ui/core';
import { useClasses } from '../../hooks';
import { LanguageString } from '../Common';

export const CaseLoadMTSTooltip: React.FC = React.memo(() => {
  const classes = useClasses();
  const theme = useTheme();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Icon className={classes.success}>check_circle</Icon>
          </Grid>
          <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="MTS_APPROVED" defaultText="Approved" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Icon style={{ color: theme.palette.warning.main }}>swap_vertical_circle</Icon>
          </Grid>
          <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="MTS_SUBMITTED" defaultText="Submitted" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Icon color="error">error</Icon>
          </Grid>
          <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="MTS_NOT_SUBMITTED" defaultText="Not Submitted" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const CaseLoadMTSDocsTooltip: React.FC = React.memo(() => {
  const classes = useClasses();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Icon className={classes.success}>check_circle</Icon>
          </Grid>
          <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="MTS_HAS_DOCS" defaultText="Has Docs" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Icon color="error">error</Icon>
          </Grid>
          <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="MTS_DOESNT_HAVE_DOCS" defaultText="Doesn't Have Docs" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
