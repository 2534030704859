import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, Divider, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { FieldArrayRenderProps, Formik, useField, useFormikContext } from 'formik';
import React, { useContext, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Checkbox, DialogTitle, IconButton, LanguageString, moveSortable, TextField } from '..';
import * as Edit from '../../common/pathEditTypes';
import { DialogController, useDialogController, useSiteMilestones } from '../../hooks';
import { PathEditorContext } from './PathEditor';

type AddMilestoneValues = {
  isNew: boolean;
  selectedMilestoneId?: number;
};

const initialValues = (): AddMilestoneValues => ({
  isNew: true,
  selectedMilestoneId: undefined
});

const AddMilestoneForm: React.FC<{ milestones: Edit.Milestone[] }> = ({ milestones }) => {
  const [{ value }] = useField<boolean>('isNew');

  return (
    <Grid container direction="column" spacing={2}>
      <Checkbox item name="isNew" label={<LanguageString groupName="MS_EDITOR" resourceName="CREATE_MILESTONE" />} />
      {!value && (
        <TextField item name="selectedMilestoneId" label={<LanguageString groupName="MS_EDITOR" resourceName="SELECT_MILESTONE" />} select>
          <MenuItem value="ph" disabled>
            <em>
              <LanguageString groupName="MS_EDITOR" resourceName="SELECT_MILESTONE" />
            </em>
          </MenuItem>
          {milestones.map(ms => (
            <MenuItem key={ms.id} value={ms.id}>
              <LanguageString languageString={ms.tasks?.[0].name} />
            </MenuItem>
          ))}
        </TextField>
      )}
    </Grid>
  );
};

type AddMilestoneDialogProps = {
  controller: DialogController;
  group: Edit.MilestoneGroup;
  onAddMilestone: (milestone: Edit.Milestone) => void;
};

const AddMilestoneDialog: React.FC<AddMilestoneDialogProps> = ({ controller: { props, controller }, group, onAddMilestone }) => {
  const { data: msData } = useSiteMilestones();
  const { cacheContext: cc } = useContext(PathEditorContext);
  const msList = useMemo(() => msData?.site.milestones.map(ms => Edit.convertMilestone(ms, group.milestones?.length ?? 0, cc, group.id)) ?? [], [msData?.site.milestones]);
  const handleSubmit = (values: AddMilestoneValues) => {
    let newMs: Edit.Milestone | undefined;
    const sortIndex = group.milestones?.length ?? 0;

    values.isNew ? (newMs = Edit.createNewMilestone({cc, sortIndex, pId: group.id })) : (newMs = msList.find(ms => ms.id === values.selectedMilestoneId));
    if (newMs != null) onAddMilestone(newMs);

    controller.setClose();
  };

  return (
    <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
      {formik => {
        return (
          <Dialog {...props} maxWidth="sm" fullWidth>
            <DialogTitle onClose={controller.setClose}>
              <LanguageString groupName="MS_EDITOR" resourceName="NEW_OR_EXISTING" />
            </DialogTitle>
            <DialogContent>{msData?.site.milestones.length && <AddMilestoneForm milestones={msList} />}</DialogContent>
            <DialogActions>
              <Button onClick={controller.setClose}>Cancel</Button>
              <Button onClick={formik.submitForm}>Ok</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

const MilestoneListInner: React.FC<FieldArrayRenderProps> = ({ name, push, remove }) => {
  const [{ value: milestones }] = useField<Edit.Milestone[]>(name);
  // TODO: This is a hack to get the parent group which means this component can't be used anywhere except the toplevel of a form.
  const { values: group } = useFormikContext<Edit.MilestoneGroup>();
  const controller = useDialogController(false);
  const { refresh } = useContext(PathEditorContext);

  const handleDragEnd = (result: DropResult) => {
    if (result.reason === 'DROP') {
      if (milestones != null && result.destination?.index != null) {
        moveSortable(milestones, result.source.index, result.destination.index);
        refresh();
      }
    }
  };

  const msName = (ms: Edit.Milestone) => ms.tasks?.[0].name;

  return milestones ? (
    <Card>
      <CardHeader
        title={<LanguageString groupName="EDITOR" resourceName="MILESTONES" defaultText="Milestones" />}
        action={<IconButton icon="add_circle" onClick={() => controller.controller.setOpen()} />}
      />
      <Divider />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="milestone-list">
          {dpProvided => (
            <List dense ref={dpProvided.innerRef}>
              {milestones.map((ms, index) => (
                <Draggable key={String(ms.id)} draggableId={`sg-list-${ms.id}`} index={index}>
                  {dgProvided => (
                    <ListItem {...dgProvided.draggableProps} ref={dgProvided.innerRef}>
                      <ListItemIcon>
                        <Icon>account_tree</Icon>
                      </ListItemIcon>
                      <ListItemText primary={<LanguageString languageString={msName(ms)} />} />
                      <IconButton icon="delete" onClick={() => remove(milestones.indexOf(ms))} />
                      <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {dpProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {controller.props.open && <AddMilestoneDialog controller={controller} onAddMilestone={push} group={group} />}
    </Card>
  ) : null;
};

export const MilestoneList = MilestoneListInner as React.FC<FieldArrayRenderProps | void>;
