import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { CreateNotificationInput } from 'tuapath-common/generated/schema';

type Result = {
  sendNotification: {
    success: Boolean,
    scheduledNotificationsUserIds: number[]
  }
};
type Variables = { input: CreateNotificationInput };
export const useSendNotification = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation sendNotification($input: CreateNotificationInput!) {
      sendNotification(input: $input) {
        success
        scheduledNotificationsUserIds
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
