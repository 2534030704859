import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useStudentsChildLinks } from '../../contexts';

export const StudentNavigationBar = () => {
  const selectedStudentsMatch = useRouteMatch<{ id?: string; studentId?: string; route?: string }>(
    `/students/:id([^\/a-zA-Z]+)*/student/:studentId/:route`
  );
  const linkItems: { id: string; studentId: string }[] = [];
  if (selectedStudentsMatch && selectedStudentsMatch.params.id) {
    linkItems.push({ id: selectedStudentsMatch.params.id, studentId: selectedStudentsMatch.params.studentId ?? '' });
  }

  const childLinks = useStudentsChildLinks('students', {
    items: linkItems,
    paramMap: { id: 'id', studentId: 'studentId' },
    routeName: 'students'
  });
  const links = childLinks?.links?.filter((cl, index) => childLinks.routes[index].group === 'navbar');

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => links && history.push(links[newValue].path);

  const selectedTabIndex = () =>
    selectedStudentsMatch?.params.route && links ? links.findIndex(cl => cl.path.endsWith(`/${selectedStudentsMatch.params.route}`)) : 0;

  return (
    <Tabs
      value={selectedTabIndex()}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
      aria-label="Student Tabs"
      variant="scrollable"
      scrollButtons="auto"
    >
      {links?.map(cl => (
        <Tab key={cl.path} label={cl.title} />
      ))}
    </Tabs>
  );
};
export default StudentNavigationBar;
