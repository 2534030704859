import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Form, UpdateFormMutationArgs } from '../../dto';
import { formFrag } from './fragments';

type Result = { updateForm: Form };
type Variables = UpdateFormMutationArgs;
export function useUpdateForm(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${formFrag}

    mutation updateForm($input: FormInput!) {
      updateForm(input: $input) {
        ...FormFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateForm;
