import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Attachment, AttachFileToMTSMutationArgs } from 'tuapath-common/generated/schema';

type Result = { attachFileToMTS: Attachment };
type Variables = AttachFileToMTSMutationArgs;
export const useMTSAddFile = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation attachFileToMTS($input: MTSFileAttachmentInput!) {
      attachFileToMTS(input: $input) {
        fileName
        url
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useMTSAddFile;
