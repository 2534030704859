import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles/withStyles';
import { useContext } from 'react';
import { SiteContext } from '../contexts';

export type StyleKeys = Record<
  | 'actionstepLibrary'
  | 'bold'
  | 'browseStudents'
  | 'buttonLink'
  | 'caseLoad'
  | 'checkmark'
  | 'clickable'
  | 'closedCase'
  | 'componentContainer'
  | 'dropzoneContainer'
  | 'error'
  | 'footerContainer'
  | 'formErrorText'
  | 'fullscreenModalContainer'
  | 'fullsreenLoadingModal'
  | 'fullWidth'
  | 'generalErrorMessage'
  | 'infocards'
  | 'lastNotification'
  | 'loginContainer'
  | 'mainContentContainer'
  | 'mtsAddActivityDialog'
  | 'mtsCalendar'
  | 'mtsCalendarFooter'
  | 'mtsCalendarHeader'
  | 'mtsCalendarMonth'
  | 'mtsCalendarWeek'
  | 'mtsCalendarYear'
  | 'mtsDayHeaderHeight'
  | 'mtsMonthDayHeader'
  | 'mtsMonthRow'
  | 'mtsMonthViewCell'
  | 'mtsMonthViewCellInactive'
  | 'mtsReviewHoursTable'
  | 'mtsStateBar'
  | 'mtsYearViewCell'
  | 'mtsYearViewRow'
  | 'navigationDrawerAppBar'
  | 'navigationTitle'
  | 'navigationTitleDropdown'
  | 'navigationTopBarAppBar'
  | 'newStudent'
  | 'noPadding'
  | 'notificationsContainer'
  | 'notificationsEmpty'
  | 'popover'
  | 'popoverArrow'
  | 'readNotification'
  | 'scheduleWeekCell'
  | 'scheduleWeekCellEntry'
  | 'scheduleWeekRow'
  | 'scheduleWeekViewTimeBlock'
  | 'scheduleWeekViewTimeBlockHeight'
  | 'snapShotContainer'
  | 'stripedTable'
  | 'studentComponentContainer'
  | 'success'
  | 'successIconMessage'
  | 'tableFooter'
  | 'tableSubfooter'
  | 'unreadNotification'
  | 'warning'
  | 'welcomeContainer'
  | 'welcomeContainerLoginForm'
  | 'welcomeContainerRawHTML'
  | 'whiteContent',
  string
>;

export const useClassesObject = () => {
  const siteCtx = useContext(SiteContext);
  const theme = useTheme();
  let jsonString = siteCtx.site?.branding?.cssClasses ?? '{}';

  while (jsonString.includes('theme.')) {
    const index = jsonString.indexOf('theme.');
    const length = jsonString.substring(index).indexOf('"');
    const themePath = jsonString.substring(index, index + length);

    let ob = undefined;
    for (const pathComp of themePath.split('.')) {
      if (ob) {
        ob = ob[pathComp];
      } else {
        ob = theme[pathComp];
      }
    }

    jsonString = jsonString.replace(themePath, ob ? ob : '');
  }

  const classes = JSON.parse(jsonString);

  return classes;
};

export const useClasses = () => {
  const classes = useClassesObject();

  const useStyles = makeStyles((theme: Theme) => createStyles(classes as StyleRules));

  return useStyles() as StyleKeys;
};
