import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { SubmitRoadmapMutationArgs, User } from '../../dto';
import { roadmapFrag } from './fragments';

type Result = { submitRoadmap: User };
type Variables = SubmitRoadmapMutationArgs;
export function useSubmitRoadmap(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${roadmapFrag}
    mutation submitRoadmap($input: SubmitRoadmapInput!) {
      submitRoadmap(input: $input) {
        id
        roadmap {
          ...RoadmapFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useSubmitRoadmap;
