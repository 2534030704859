import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

export const useFocusOnError = (fieldRef: React.MutableRefObject<HTMLDivElement | null>, name: string) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];

  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) {
        fieldRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey]);
};
