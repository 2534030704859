import { AppBar, Box, Grid, Icon, IconButton, makeStyles, Paper, Tab, TablePagination, Tabs, TextField, Toolbar, Tooltip } from '@material-ui/core';
import React, { ChangeEvent, useContext, useMemo, useRef, useState } from 'react';
import { LanguageString } from '..';
import { SiteContext, StudentContext } from '../../contexts';
import { Attachment } from '../../dto';
import useAddFileToBox from '../../hooks/gql/useAddFileToBox';
import useDeleteFile from '../../hooks/gql/useDeleteFile';
import { useUserAttachemnts } from '../../hooks/gql/useUserAttachments';
import { compare, FileTable, FileTableSortColumn, SortDirection, SortInfo } from './FileTable';

const useStyles = makeStyles({
  searchTextField: {
    margin: 0
  }
});

export const FileBox: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);

  const styles = useStyles();

  const tableEndRef = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const allFiles = studentCtx?.student.files ?? [];

  const [addFile] = useAddFileToBox();
  const [deleteFile] = useDeleteFile();
  useUserAttachemnts({ variables: { userId: studentCtx?.student?.id ?? null }, fetchPolicy: 'network-only'});

  const rowsPerPageOptions = [5, 10, 25, 50];
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortInfo>({ order: SortDirection.DESC, column: FileTableSortColumn.UPLOADDATE });
  const [newUpload, setNewUpload] = useState<File | undefined>();

  const filterFiles = (files: Attachment[], value = tab) => {
    if (!studentCtx) {
      return [];
    }
    if (value === 0) {
      return files;
    }

    return files.filter(el => el.type.id === value);
  };

  const filterPage = (files: Attachment[], currentPage: number, currentRowsPerPage: number) => {
    return files.slice(currentPage * currentRowsPerPage, (currentPage + 1) * currentRowsPerPage);
  };

  const makeUploaderName = (el: Attachment) => {
    return `${el.uploader?.firstName ?? ''} ${el.uploader?.lastName ?? ''}`;
  };

  const runsearch = (files: Attachment[], currentSearch: string) => {
    if (currentSearch === '') {
      return files;
    }
    const re = RegExp(currentSearch, 'i');
    return files.filter(el =>
      (el.fileName.search(re) > -1) ||
      ((el.description ?? '').search(re) > -1) ||
      (makeUploaderName(el).search(re) > -1));
  };

  const runSort = (files: Attachment[], currentSort: SortInfo) => {
    switch (currentSort.column) {
      case FileTableSortColumn.FILENAME:
        return files.slice().sort((a, b) => compare(a.fileName, b.fileName, currentSort.order));
      case FileTableSortColumn.UPLOADDATE:
        return files.slice().sort((a, b) => compare(a.createdAt, b.createdAt, currentSort.order));
      case FileTableSortColumn.UPLOADER:
        return files.slice().sort((a, b) => compare(makeUploaderName(a), makeUploaderName(b), currentSort.order));
      default:
        console.log('Undefined sort direction.');
        return files;
    }
  };

  const fileList = useMemo(() => filterFiles(allFiles, tab), [tab, allFiles]);
  const fileSearch = useMemo(() => runsearch(fileList, search), [fileList, search]);
  const fileSort = useMemo(() => runSort(fileSearch, sort), [fileSearch, sort]);
  const filePage = useMemo(() => filterPage(fileSort, page, rowsPerPage), [fileSort, page, rowsPerPage]);

  const handleSortChange = (req: FileTableSortColumn) => {
    let newSort;
    if (req === sort.column) {
      if (sort.order === SortDirection.ASC) {
        newSort = { column: req, order: SortDirection.DESC };
      } else {
        newSort = { column: req, order: SortDirection.ASC };
      }
    } else {
      newSort = { column: req, order: SortDirection.ASC };
    }
    setSort(newSort);
  };

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newValue: number) => {
    setPage(newValue);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value, 10);
    setrowsPerPage(rows);
    setPage(0);
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(event.target.value);
  };

  const deleteElement = async (attachmentId: number, deleteReason: string) => {
    await deleteFile({
      variables: {
        attachmentId: attachmentId,
        deleteReason: deleteReason
      }
    });
  };

  const uploadFile = async (file: File, description: string, fileTypeId: number, fileowner: number) => {
    await addFile({
      variables: {
        file: file,
        description: description,
        fileTypeId: fileTypeId,
        userId: fileowner
      }
    });
  };

  return (
    <Paper>
      <AppBar position="relative">
        <Toolbar disableGutters={true}>
          <Grid container spacing={3} alignItems="center" justify="space-between" direction="row">
            <Grid item>
              <Box m={2}>
                <Tooltip
                  title={<LanguageString groupName="FILEBOX" resourceName="ADDFILE" defaultText="Upload a File" />}
                >
                  <IconButton
                    onClick={() => {
                      if (fileInput.current) {
                        fileInput.current.click();
                      }
                    }}
                    aria-label="Upload a File"
                  >
                    <input
                      type="file"
                      ref={fileInput}
                      style={{ display: 'none' }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNewUpload(e?.target?.files ? (e.target.files[0]) : undefined);
                        if (tableEndRef.current) {
                          tableEndRef.current.scrollIntoView({ behavior: 'smooth' });
                        }
                        if (fileInput.current) {
                          fileInput.current.value = '';
                        }
                      }}
                    />
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item>
              <Box m={2}>
                <TextField
                  id="searchField"
                  type="search"
                  value={search}
                  label={<LanguageString groupName="GENERAL" resourceName="SEARCH" defaultText="Search" />}
                  onChange={handleSearchTextChange}
                  className={styles.searchTextField}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="File Type Tab"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={<LanguageString groupName="FILEBOX" resourceName="ALL" defaultText="All" />} value={0} />
                {siteCtx.site?.attachmentTypes && siteCtx.site.attachmentTypes.map(attType => {
                  return !attType.editorType ? (<Tab key={attType.id} label={<LanguageString languageString={attType.name} />} value={attType.id} />) : null;
                })}
              </Tabs>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {studentCtx && (
        <>
          <FileTable
            fileList={filePage}
            fileowner={studentCtx.student.id}
            upload={uploadFile}
            deleteElement={deleteElement}
            handleRequestSort={handleSortChange}
            sort={sort}
            newUpload={newUpload}
          />
          <div ref={tableEndRef} />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={fileSearch.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>);
};

export default FileBox;
