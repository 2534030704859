import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Roadmap } from '../../dto';
import { roadmapFrag } from './fragments';

type Variables = { userId: number };
type Results = {
  roadmap: Roadmap;
};

export const useRoadmap = (options?: QueryHookOptions<Results, Variables>) => {
  const query = gql`
    ${roadmapFrag}
    query getRoadmap($userId: Int!) {
      roadmap(userId: $userId) {
        ...RoadmapFrag
      }
    }
  `;

  return useQuery(query, options);
};

export default useRoadmap;
