import React, { useContext, useMemo, useState } from 'react';
import { Dropzone } from '../FileBox/Dropzone';
import {
  Grid,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Paper,
  IconButton,
  Box
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import useAddFileToPublicBox from '../../hooks/gql/useAddFileToPublicBox';
import { SiteContext, UserContext } from '../../contexts';
import { ApolloError } from 'apollo-client';
import { Alert } from '@material-ui/lab';
import { Attachment } from 'tuapath-common/generated/schema';
import { DeleteRounded } from '@material-ui/icons';
import * as DTO from '../../dto';

interface FormValues {
  file?: File[];
}

interface NotificationBlastAttachmentProps {
  addAttachment: (a: Attachment) => void;
  removeAttachment: (a: Attachment) => void;
  attachments: Attachment[];
}

const schema = yup.object().shape({
  file: yup.mixed().required('File is required')
});

export const NotificationBlastAttachment: React.FC<NotificationBlastAttachmentProps> = (props) => {
  const siteCtx = useContext(SiteContext);
  const [addFile] = useAddFileToPublicBox();
  const userCtx = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const attachmentType = useMemo(() => {
    return siteCtx.site?.attachmentTypes?.find(a => a.typeName === DTO.SpecialUploadTypes.NotificationAttachment);
  }, [siteCtx.site?.attachmentTypes]);

  return (
    <Grid container spacing={2}>
      {props.attachments.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <LanguageString groupName="GENERAL" resourceName="FILE" defaultText="File" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {props.attachments.map(a => (
                  <TableRow key={`notification_attachment_${a.id}`}>
                    <TableCell>
                      <Box display="flex" alignContent="center">
                        <Box mr={2}>
                          <img style={{ height: 150 }} src={a.url} />
                        </Box>
                        {a.fileName}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => props.removeAttachment(a)}>
                        <DeleteRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Formik
          initialValues={{
          }}
          validationSchema={schema}
          enableReinitialize={false}
          onSubmit={async (values: FormValues, helpers) => {
            if (userCtx?.user.id && values.file?.length && attachmentType) {
              addFile({
                variables: {
                  file: values.file[0],
                  description: '',
                  fileTypeId: attachmentType.id,
                  userId: userCtx.user.id
                }
              }).then(file => {
                if (file.data?.addFileToPublicBox?.files?.length) {
                  props.addAttachment(file.data?.addFileToPublicBox.files[file.data.addFileToPublicBox.files.length - 1]);
                }
                helpers.resetForm();
              }).catch(res => {
                const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
                  return error.message;
                });

                if (errors.length > 0) {
                  setErrorMessage(errors[0]);
                }
              });
            }
          }}
        >
          {formikVar => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Dropzone name="file" formEmbed={false} />
                    {errorMessage && (
                      <Grid container>
                        <Grid item xs>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
