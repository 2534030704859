import { Grid, Icon, IconButton, Link, makeStyles, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import format from 'date-fns/format';
import React, { useMemo } from 'react';
import { UserDeleteAbility } from 'tuapath-common/generated/schema';
import { LanguageString } from '..';
import { Attachment } from '../../dto';
import { QueryType } from '../../helpers/query';
import { FileTableColumn } from './FileTable';

interface FileTableRowProps {
  attachment: Attachment;
  deleteFile: (att: Attachment) => void;
  shownRows: boolean[];
  disableDelete: boolean;
  overrideTypeDeleteLogic?: boolean;
}

const useStyles = makeStyles({
  UserInputLayoutGuard: {
    overflowWrap: 'anywhere'
  }
});

//returns touple [disable delete, delete reason string]
function determineDeleteInfo(attachment: QueryType<Attachment>, overrideTypeDeleteLogic: boolean): [boolean, JSX.Element] {
  if (attachment.userDeleteAbility) {
    switch (attachment.userDeleteAbility) {
      case (UserDeleteAbility.CannotDeleteType):
        if (overrideTypeDeleteLogic) {
          return [false, <LanguageString groupName="FILEBOX" resourceName="DELETE" defaultText="Delete" />];
        }
        return [true, <LanguageString groupName="FILEBOX" resourceName="CANNOTDELETETYPE" defaultText="You cannot delete this type of file" />];

      case (UserDeleteAbility.CannotDeleteUploader):
        return [true, <LanguageString groupName="FILEBOX" resourceName="CANNOTDELETENOTUPLOADER" defaultText="You cannot delete items you did not upload" />];
      case (UserDeleteAbility.CannotDeleteAssignmentFinalized):
        return [true, <LanguageString groupName="FILEBOX" resourceName="CANNOTDELETEFINALIZED" defaultText="You cannot delete items that are attached to a finalized roadmap" />];
      case (UserDeleteAbility.Allowed):
        return [false, <LanguageString groupName="FILEBOX" resourceName="DELETE" defaultText="Delete" />];
    }
  } else {
    //returning disable delete with the standard string because userDeleteAbility not loaded
    return [true, <LanguageString groupName="FILEBOX" resourceName="DELETE" defaultText="Delete" />];
  }
}

export const FileTableRow: React.FC<FileTableRowProps> = (props) => {
  const styles = useStyles();

  const [disableDelete, deleteTooltip] = useMemo(
    (() => (determineDeleteInfo(props.attachment, props.overrideTypeDeleteLogic ?? false))),
    [props.attachment, props.overrideTypeDeleteLogic]);

  return (
    <TableRow key={`attachment_${props.attachment.id}`}>
      {props.shownRows[FileTableColumn.FILENAME] && (
        <TableCell>
          <Grid className={styles.UserInputLayoutGuard}>
            <Link href={props.attachment.url} target="_blank">
              <Typography variant="body1">{props.attachment.fileName}</Typography>
            </Link>
          </Grid>
        </TableCell>
      )}
      {props.shownRows[FileTableColumn.DESCRIPTION] && (
        <TableCell>
          <Grid className={styles.UserInputLayoutGuard}>
            <Typography variant="body1">{props.attachment.description}</Typography>
          </Grid>
        </TableCell>
      )}
      {props.shownRows[FileTableColumn.UPLOADER] && (
        <TableCell>
          {props.attachment.uploader && (
            <Typography variant="body1">
              {props.attachment.uploader.firstName} {props.attachment.uploader.lastName}
            </Typography>
          )}
        </TableCell>
      )}
      {props.shownRows[FileTableColumn.FILETYPE] && (
        <TableCell>
          <Typography variant="body1">{props.attachment.type.name?.text}</Typography>
        </TableCell>
      )}
      {props.shownRows[FileTableColumn.UPLOADDATE] && (
        <TableCell>
          {props.attachment.createdAt && (
            <Typography variant="body1">{format(new Date(props.attachment.createdAt), 'MM/dd/yy h:mm')}</Typography>
          )}
        </TableCell>
      )}
      <TableCell>
        <Grid container justify="flex-end">
          <Grid item xs={6}>
            <Tooltip
              title={<LanguageString groupName="FILEBOX" resourceName="DOWNLOAD" defaultText="Download" />}
            >
              <Link href={props.attachment.url} target="_blank" aria-label="Download File">
                <IconButton aria-hidden="true">
                  <Icon>cloud_download</Icon>
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
          {!props.disableDelete && (
            <Grid item xs={6}>
              <Tooltip
                title={deleteTooltip}
              >
                <span>
                  <IconButton
                    onClick={() => props.deleteFile(props.attachment)}
                    disabled={disableDelete}
                    aria-label="Delete File"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow >
  );
};
