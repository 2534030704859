import { createTRPCClient } from '@trpc/client';
import { inferProcedureInput, inferProcedureOutput } from '@trpc/server';
import superjson from 'superjson';
import type { AppRouter } from 'tuapath-server';
import Auth from '../helpers/auth';
import Config from '../helpers/config';

type TQuery = keyof AppRouter['_def']['queries'];
type TQueryCall<TRouteKey extends TQuery> = AppRouter['_def']['queries'][TRouteKey];
export type InferQueryInput<T extends TQuery> = inferProcedureInput<TQueryCall<T>>;
export type InferQueryOutput<T extends TQuery> = inferProcedureOutput<TQueryCall<T>>;
export type ExternalMTS = InferQueryOutput<'externalMTS'>;

export function getTRPCClient() {
  const token = Auth.token();
  const gqlUrl = Config.gqlConnectionString;

  if (gqlUrl == null) throw new Error(`Check site configuration`);
  const url = gqlUrl.replace('graphql', 'trpc');

  const client = createTRPCClient<AppRouter>({ transformer: superjson, url, headers: { authorization: token ? `Session ${token}` : '' } });
  return client;
}
