import React, { Fragment } from 'react';
import { LanguageString } from '..';
import { useStringGroup } from '../../../hooks';

interface PlacementProps {
  groupName: string;
}

export const Placement: React.FC<PlacementProps> = props => {
  const stringGroup = useStringGroup({ variables: { groupName: props.groupName } });

  return stringGroup.data != null ? (
    <Fragment>
      {stringGroup.data.stringGroup.map(sg => (
        <LanguageString key={sg.resourceName} languageString={sg.string} />
      ))}
    </Fragment>
  ) : null;
};

export default Placement;
