import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MilestoneGroup, AddOrRemoveSubGroupsMutationArgs } from '../../dto';
import { languageStringFrag } from './fragments';

type Result = { addOrRemoveSubGroups: { id: number, subGroups: MilestoneGroup[] }[] };
type Variables = AddOrRemoveSubGroupsMutationArgs;
export function useAddOrRemoveSubGroups(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${languageStringFrag}
    mutation addOrRemoveSubGroups($input: [AddRmSubGrpsInput]!) {
      addOrRemoveSubGroups(input: $input) {
        id
        subGroups {
          id
          sortIndex
          color
          name {
            ...LangStringFrag
          }
          description {
            ...LangStringFrag
          }
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useAddOrRemoveSubGroups;
