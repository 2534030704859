import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useContext, useRef, useState } from 'react';
import { StudentContext } from '../../contexts';
import { Form, Roadmap, RoadmapMeetingChangeReason, RoadmapMeetingType, SubmitFormInput } from '../../dto';
import { DialogController, useSiteMeetingForm, useUpdateRoadmapMeeting } from '../../hooks';
import { roadmapFrag } from '../../hooks/gql/fragments';
import { DialogTitle, LanguageString } from '../Common';
import { DynamicForm } from '../DynamicForm';

export type MeetingValues = {
  meetingDate: Date;
  meetingType: RoadmapMeetingType;
  changeReason: RoadmapMeetingChangeReason;
};

export const MeetingDialog: React.FC<{ dprops: DialogController['props'] } & { rmId: number, userId?: number, pathId?: number }> = ({ dprops, rmId, userId, pathId }) => {
  const { data, loading } = useSiteMeetingForm({variables: {pathId}});
  const [updateMeeting] = useUpdateRoadmapMeeting();
  const [submitting, setSubmitting] = useState(false);
  const df = useRef<DynamicForm | null>(null);
  const studentCtx = useContext(StudentContext);

  const handleSubmit = async (values: SubmitFormInput, form: Form) => {
    if (studentCtx?.student != null) {
      setSubmitting(true);
      if (form.elements == null) throw new Error(`Invalid Form`);

      await updateMeeting({
        variables: {
          input: {
            ...values,
            userId: userId ?? studentCtx.student.id
          }
        },
        update: (proxy, result) => {
          proxy.writeFragment<Partial<Roadmap>>({
            id: `Roadmap:${rmId}`,
            fragment: roadmapFrag,
            fragmentName: 'RoadmapFrag',
            data: {
              meeting: result.data?.updateRoadmapMeeting
            }
          });

          dprops.onClose();
        }
      });
      setSubmitting(false);
    }
  };

  const handleSubmitClicked = async () => {
    setSubmitting(true);
    console.log('handle submit');
    await df.current?.submitForm();
    setSubmitting(false);
  };

  return (
    <Dialog {...dprops} onExited={() => setSubmitting(false)}>
      <DialogTitle onClose={dprops.onClose}>
        <LanguageString groupName="ROADMAP" resourceName="SCHEDULE_MEETING" defaultText="Schedule a Meeting" />
      </DialogTitle>
      <DialogContent dividers>
        {loading
          ? <CircularProgress color="primary" />
          : data?.site.roadmapMeetingForm
            ? <DynamicForm ref={df} autoloadFormSubmission={false} formInst={data?.site.roadmapMeetingForm} overrideSubmitHandler={handleSubmit} showSubmitButton={false} />
            : <div>There is no meeting form configured. Please contact support</div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={dprops.onClose}>
          <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        <Button type="submit" onClick={handleSubmitClicked} disabled={submitting}>
          <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="SUBMIT" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
