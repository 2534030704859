import { MilestoneInput, PathInput, TaskInput, UpdateMilestoneGroupInput } from '../dto';
import { lsDtoWithId as convertLSWithId } from './formDataTypes';
import * as Edit from './pathEditTypes';

export { TaskActionType } from '../dto';
export type { Milestone, MilestoneCompletedEmail, MilestoneGroup, MilestoneInput, SuccessPath, Task, TaskInput } from '../dto';
export { lsDto as convertLS, lsDtoWithId as convertLSWithId } from './formDataTypes';

const convertTask = (taskData: Edit.Task): TaskInput => ({
  id: taskData.id,
  action: taskData.action,
  actionType: taskData.actionType,
  requiresApproval: taskData.requiresApproval,
  name: convertLSWithId(taskData.name),
  summary: convertLSWithId(taskData.summary),
  description: convertLSWithId(taskData.description),
  instructions: convertLSWithId(taskData.instructions),
  completedEmail: taskData.sendEmailOnCompletionEnabled && taskData.onCompletedEmail != null
    ? {
      id: taskData.onCompletedEmail.id,
      subject: convertLSWithId(taskData.onCompletedEmail.subject),
      body: convertLSWithId(taskData.onCompletedEmail.body),
      additionalRecipients: taskData.onCompletedEmail.additionalRecipients
  } : undefined
});

export const convertMilestone = (msData: Edit.Milestone, groupId: number) => {
  const dto: MilestoneInput = {
    id: msData.id,
    groupId: groupId,
    required: msData.required,
    sortIndex: msData.sortIndex,
    completedEmail:
      msData.sendEmailOnCompletionEnabled && msData.onCompletedEmail != null
        ? {
            id: msData.onCompletedEmail.id,
            subject: convertLSWithId(msData.onCompletedEmail.subject),
            body: convertLSWithId(msData.onCompletedEmail.body),
            additionalRecipients: msData.onCompletedEmail.additionalRecipients
          }
        : undefined,
    tasks: msData.tasks?.map(t => convertTask(t)),
    allowMultipleInstances: msData.allowMultipleInstances
  };

  return dto;
};

export const convertGroup = (grpData: Edit.MilestoneGroup, exclusive?: boolean): UpdateMilestoneGroupInput => ({
  id: grpData.id,
  color: grpData.color,
  sortIndex: grpData.sortIndex,
  description: convertLSWithId(grpData.description),
  name: convertLSWithId(grpData.name),
  milestones: exclusive === true ? undefined : grpData.milestones?.map(ms => convertMilestone(ms, grpData.id)),
  subGroups: exclusive === true ? undefined : grpData.subGroups?.map(grp => convertGroup(grp))
});

export const convertPath = (pathData: Edit.SuccessPath, exclusive?: boolean): PathInput => ({
  id: pathData.id,
  name: convertLSWithId(pathData.name),
  description: convertLSWithId(pathData.description),
  msGroups: exclusive === true ? undefined : pathData.msGroups?.map(grp => convertGroup(grp))
});
