import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Attachment } from 'tuapath-common/generated/schema';
import { attachmentFrag } from './fragments/attachmentFrag';

type Result = { addFileToBox: { id: number, files: Attachment[] } };
type Variables = { file: File, userId: number, description: string, fileTypeId: number };
export const useAddFileToBox = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
  ${attachmentFrag}
    mutation addFileToBox($file: Upload!, $userId: Int!, $description:String, $fileTypeId: Int!) {
      addFileToBox(file: $file, userId: $userId, description:$description, fileTypeId: $fileTypeId) {
        id
        files{
          ...AttachmentFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useAddFileToBox;
