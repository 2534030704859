import {
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  Tooltip
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import { StudentsMenuContext, useStudentsChildLinks } from '../../contexts';
import { User } from '../../dto';
import RouteBuilderHelper from '../../helpers/routeBuilderHelper';
import UserHelper from '../../helpers/userHelper';
import { LanguageString } from '../Common';

interface StudentSelectionMenuProps {
  open: boolean;
  anchor: React.RefObject<HTMLElement>;
  toggle: () => void;
}

export const StudentSelectionMenu: React.FC<StudentSelectionMenuProps> = ({ open, anchor, toggle }) => {
  const history = useHistory();
  const studentsCtx = useContext(StudentsMenuContext);

  const selectedStudentsMatch = useRouteMatch<{ id?: string; studentId?: string; route?: string }>(`/students/:id([^\/a-zA-Z]+)*/student/:studentId/:route`);
  const linkItems: { id: string; studentId: string }[] = [];
  if (selectedStudentsMatch && selectedStudentsMatch.params.id) {
    linkItems.push({ id: selectedStudentsMatch.params.id, studentId: selectedStudentsMatch.params.studentId ?? '' });
  }

  const studentsLinks = useStudentsChildLinks('students', {
    items: linkItems,
    paramMap: { id: 'id', studentId: 'studentId' },
    routeName: 'students'
  });
  let firstRoute = '/roadmap';
  if (studentsLinks && studentsLinks.links && studentsLinks.links.length > 0) {
    const vals = studentsLinks.links[0].path.split('/');
    firstRoute = `/${vals[vals.length - 1]}`;
  }

  const handleRemoveOpenStudent = (student: User) => {
    if (studentsCtx) {
      const students = studentsCtx.openStudents.filter(s => {
        return s.id !== student.id;
      });

      let selectedStudent: User | undefined = undefined;
      if (studentsCtx.selectedStudent) {
        const foundStudent = students.find(s => {
          return s.id === studentsCtx.selectedStudent?.id;
        });

        if (foundStudent) {
          selectedStudent = foundStudent;
        } else if (students.length > 0) {
          selectedStudent = students[0];
        }
      }

      history.push(RouteBuilderHelper.openStudentRoute(firstRoute, students.map(s => s.id), selectedStudent?.id));
    }
  };

  return (
    <Menu
      id="student-menu"
      anchorEl={anchor.current}
      keepMounted
      open={open}
      onClose={toggle}
      PaperProps={{
        style: {
          width: 200
        }
      }}
    >
      <List>
        <ListItem
          button
          component={RouterLink}
          to={RouteBuilderHelper.allStudentsRoute(studentsCtx?.openStudents ? studentsCtx.openStudents.map(s => s.id) : [])}
          onClick={toggle}
        >
          <ListItemText>
            <LanguageString groupName="GENERAL" resourceName="ALL_STUDENTS" defaultText="All Students" />
          </ListItemText>
          <Icon color="primary">keyboard_arrow_right</Icon>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListSubheader>
          <LanguageString groupName="GENERAL" resourceName="OPEN_STUDENTS" defaultText="Open Students" />
        </ListSubheader>

        {studentsCtx?.openStudents.map(student => {
          const caseIdNum = UserHelper.getCaseId(student);

          return (
            <ListItem
              key={`student_menu_${student.id}`}
              button
              component={RouterLink}
              to={RouteBuilderHelper.openStudentRoute(
                firstRoute,
                studentsCtx?.openStudents ? studentsCtx.openStudents.map(s => s.id) : [],
                student.id
              )}
              onClick={toggle}
            >
              <ListItemText>
                {student.firstName}
                {caseIdNum ? `, ${UserHelper.getCaseId(student)}` : ``}
              </ListItemText>
              <ListItemSecondaryAction>
                <Tooltip title={<LanguageString groupName="NAV" resourceName="CLOSE_STUDENT" defaultText="Close" />}>
                  <IconButton
                    edge="end"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      handleRemoveOpenStudent(student);
                    }}
                  >
                    <Icon color="error">close</Icon>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Menu>
  );
};

export default StudentSelectionMenu;
