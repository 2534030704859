import { createStyles, FormLabel, Grid, makeStyles, Slider, SliderProps } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { LanguageString } from '../Common';
import { FormFieldComp, FormStatus, getQuestionFormId, IValues } from './common';
import { useFocusOnError } from '../../hooks';

// TODO: Move styles to the database
const useStyles = (val: number) =>
  makeStyles(theme =>
    createStyles({
      root: {
        marginTop: '20px',
        marginBottom: 0,
        '& .MuiSlider-markLabel': {
          top: '-20px'
        },
        '& .MuiSlider-rail': {
          height: '12px',
          background: 'linear-gradient(to right, #ff3d00 0%, #ffeb3b 50%, #00e676 100%)'
        },
        '& .MuiSlider-track': {
          height: '12px',
          background: `linear-gradient(to right, #ff3d00 0%, #ffeb3b ${50 / val}%, #00e676 ${100 / val}%)`
        },
        '& .MuiSlider-mark': {
          height: '12px'
        },
        '& .MuiSlider-thumb': {
          height: '20px',
          width: '20px',
          marginTop: '-5px',
          marginLeft: '-10px',
          backgroundColor: theme.palette.background.default,
          border: '2px solid',
          borderColor: theme.palette.primary.dark
        }
      }
    })
  );

const sequence = (start: number, end: number) => ({
  [Symbol.iterator]: function* () {
    for (let i = start; i <= end; i++) yield i;
  }
});

export const FormRating: FormFieldComp<{ min?: number; max?: number }> = ({ question, min = 1, max = 7 }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const fieldId = getQuestionFormId(question);
  const [field] = useField<number>(fieldId);
  const { onChange, onBlur, ...lField } = field;
  const formik = useFormikContext<IValues>();
  const half = (max - min) / 2 + min;
  const classes = useStyles(((field.value ?? half) - min) / (max - min))();
  const { disabled: isDisabled, isReadOnly } = formik.status as FormStatus;
  const disabled = isDisabled || isReadOnly || question.isReadOnly;

  const handleChange = (_event: React.ChangeEvent<{}>, val: number[] | number) => formik.setFieldValue(fieldId, val);

  const handleBlur: SliderProps['onBlur'] = () => formik.handleBlur(fieldId);

  if (lField.value == null) lField.value = Math.round(half);

  useFocusOnError(fieldRef, field.name);

  return (
    <Grid container>
      {question.text?.text && (
        <FormLabel htmlFor={fieldId}>
          <LanguageString languageString={question.text} />
        </FormLabel>
      )}
      <Slider
        id={fieldId}
        className={classes.root}
        {...lField}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        min={min}
        max={max}
        marks={[...sequence(min, max)].map(index => ({ value: index, label: String(index) }))}
        ref={fieldRef}
      />
      <Grid container item justify="space-between">
        <LanguageString languageString={question.lowText} />
        <LanguageString languageString={question.highText} />
      </Grid>
    </Grid>
  );
};
