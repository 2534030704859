import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MTSActivityType, MTSActivityTypeInput } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

type Result = { updateMTSActivityType: MTSActivityType };
type Variables = { input: MTSActivityTypeInput };
export function useUpdateMTSActivityType(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${languageStringFrag}

    mutation updateMTSActivityType($input: MTSActivityTypeInput!) {
      updateMTSActivityType(input: $input) {
        id
        name {
          ...LangStringFrag
        }
        description
        sortIndex
        code
        noteRequired
        wpr
        enabled
        hidden
        childGeneratedTypesEnabled
        filterCustomPropertyValue
        appearsInReviewAllHours
        formId
        mtsInRoadmapEnabled
        secondaryFormId
        attachmentFormEnabled
        defaultHours
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateMTSActivityType;
