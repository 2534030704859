import React from 'react';
import { useClasses } from '../../hooks';
import { LanguageString } from '../Common';

export const Footer: React.FC = () => {
  const classes = useClasses();

  return (
    <div className={classes.footerContainer}>
      <LanguageString groupName="SITE_CONTENT_FOOTER" resourceName="FOOTER" defaultText="No Footer" />
    </div>
  );
};

export default Footer;
