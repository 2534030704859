import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';
import { ReqVars } from './common';
import { mtsPeriodFrag } from './fragments';

export type UserResult = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  customPropertiesByNames?: DTO.UserCustomProperty[];
  assignments?: DTO.AssignmentResults;
  nextMeetingDate?: DTO.Roadmap;
  currentMonthMTS?: DTO.MTS;
  lastMonthMTS?: DTO.MTS;
  twoMonthsAgoMTS?: DTO.MTS;
};

type Variables = {
  search?: string;
  advisorIds: number[];
  currentMonth?: number;
  currentMonthYear?: number;
  currentPeriodId?: number;
  lastMonth?: number;
  lastMonthYear?: number;
  lastPeriodId?: number;
  twoMonthsAgo?: number;
  twoMonthsAgoYear?: number;
  twoPeriodsAgoId?: number;
  limit: number;
  offset: number;
  order: DTO.StudentsOrder;
  filter?: DTO.SubmitFormInput;
  pathIds?: number[];
  customPropertyNames: string[];
};
type Results = {
  pageInfo: {
    totalCount: number;
    students?: UserResult[];
  };
};
export const useCoachHomePage = (options: ReqVars<QueryHookOptions<Results, Variables>>) => {
  const query = gql`
    ${mtsPeriodFrag}

    query coachHomePage(
      $search: String,
      $advisorIds: [Int]!,
      $currentMonth: Int,
      $currentMonthYear: Int,
      $currentPeriodId: Int,
      $lastMonth: Int,
      $lastMonthYear: Int,
      $lastPeriodId: Int,
      $twoMonthsAgo: Int,
      $twoMonthsAgoYear: Int,
      $twoPeriodsAgoId: Int,
      $limit: Int!,
      $offset: Int!,
      $order: StudentsOrder!,
      $filter: SubmitFormInput,
      $pathIds: [Int],
      $customPropertyNames: [String!]!
    ) {
      pageInfo: students(searchParams: {advisorIds: $advisorIds, limit: $limit, offset: $offset, search: $search, order: $order, filter: $filter, pathIds: $pathIds}) {
        totalCount
        students {
          id
          username
          firstName
          lastName
          createdAt
          customPropertiesByNames(names: $customPropertyNames) {
            id
            value
            modifiedAt
            type {
              id
              name
              fieldType
            }
            resolvedValue
          }
          assignments(limit: 1, offset: 0, orderBy: "modifiedAt", orderDirection: "DESC") {
            totalCount
            assignments {
              id
              modifiedAt
            }
          }
          nextMeetingDate: roadmap {
            id
            meeting {
              date
              meetingType
            }
          }
          currentMonthMTS: mts(month: $currentMonth, year: $currentMonthYear, periodId: $currentPeriodId) {
            id
            month
            year
            totalHours
            totalWprHours
            state
            submitted
            approved
            entries(limit: 1, offset: 0, orderBy: "modifiedAt", orderDirection: "DESC") {
              entries {
                id
                modifiedAt
              }
            }
            attachments {
              id
            }
            mtsPeriod {
              ...MTSPeriodFrag
            }
          }
          lastMonthMTS: mts(month: $lastMonth, year: $lastMonthYear, periodId: $lastPeriodId) {
            id
            month
            year
            totalHours
            totalWprHours
            state
            submitted
            approved
            entries(limit: 1, offset: 0, orderBy: "modifiedAt", orderDirection: "DESC") {
              entries {
                id
                modifiedAt
              }
            }
            attachments {
              id
            }
            mtsPeriod {
              ...MTSPeriodFrag
            }
          }
          twoMonthsAgoMTS: mts(month: $twoMonthsAgo, year: $twoMonthsAgoYear, periodId: $twoPeriodsAgoId) {
            id
            month
            year
            totalHours
            totalWprHours
            state
            submitted
            approved
            entries(limit: 1, offset: 0, orderBy: "modifiedAt", orderDirection: "DESC") {
              entries {
                id
                modifiedAt
              }
            }
            attachments {
              id
            }
            mtsPeriod {
              ...MTSPeriodFrag
            }
          }
        }
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
};

export default useCoachHomePage;
