import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { ROCType } from '../../dto';
import { QueryType } from '../../helpers/query';
import formFrag from './fragments/formFrag';

export type ROCTypeVariables = {  };
export type ROCTypeResults = { rocTypes: QueryType<ROCType>[] };

export function useRocTypes(options: QueryHookOptions<ROCTypeResults, ROCTypeVariables> & { variables: ROCTypeVariables }) {
  const query = gql`
    ${formFrag}
    query getROCTypes {
      rocTypes {
        id
        name {
          id
          text
        }
        form {
          ...FormFrag
        }
      }
    }
  `;

  return useQuery<ROCTypeResults>(query, options);
}

export default useRocTypes;
