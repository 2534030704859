import { Icon, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { QValues } from '../../common';
import { FormQuestionType } from '../../dto';
import { useDialogController } from '../../hooks';
import { ClickEvent } from './common';
import { EditQuestionDialog } from './EditQuestionDialog';

type QuestionListItemProps = {
  question: QValues;
  fieldName: string;
  onDelete?: () => void;
  index: number;
};

export const QuestionListItem: React.FC<QuestionListItemProps> = ({ question, fieldName, onDelete, index }) => {
  const dlgCtrl = useDialogController(false);

  const handleEditClick: ClickEvent<HTMLDivElement> = event => {
    dlgCtrl.controller.setOpen();
    event.stopPropagation();
  };

  const qIcon = (() => {
    switch (question.questionType) {
      case FormQuestionType.TEXT:
        return <Icon>text_fields</Icon>;
      case FormQuestionType.SELECT:
        return <Icon>check_circle</Icon>;
      case FormQuestionType.MULTISELECT:
        return <Icon>check_box</Icon>;
      case FormQuestionType.BOOLEAN:
        return <Icon>thumbs_up_down</Icon>;
      case FormQuestionType.DATE:
      case FormQuestionType.DATETIME:
      case FormQuestionType.DUEDATE:
      case FormQuestionType.TIME:
        return <Icon>event</Icon>;
      case FormQuestionType.PASSWORD:
        return <Icon>lock</Icon>;
      case FormQuestionType.RATING:
        return <Icon>star</Icon>;
      case FormQuestionType.HIDDEN:
        return <Icon>not_interested</Icon>;
      case FormQuestionType.INFORMATION:
        return <Icon>info</Icon>;
      default:
        return <Icon>add_circle</Icon>;
    }
  })();

  return (
    <Draggable draggableId={`q-list-${question.id}`} index={index}>
      {dgProvided => (
        <Fragment>
          <ListItem button onClick={handleEditClick} {...dgProvided.draggableProps} ref={dgProvided.innerRef}>
            <ListItemIcon>{qIcon}</ListItemIcon>
            <ListItemText primary={question.name != null ? question.name : question.text ? question.text.text : 'No Data'} />
            <IconButton onClick={onDelete}>
              <Icon>delete</Icon>
            </IconButton>
            <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
          </ListItem>
          <EditQuestionDialog {...dlgCtrl} question={question} fieldName={fieldName} />
        </Fragment>
      )}
    </Draggable>
  );
};
