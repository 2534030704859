import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { printFileSchemaFrag } from './fragments/printFileSchemaFrag';
import { languageStringFrag } from './fragments/languageStringFrag';

type Variables = { getFilterPropValue: boolean };
export type SiteMTSActivityTypesQuery = { site: Pick<Site, 'id' | 'mtsActivityTypes'> };
export function useSiteMTSActivityTypes(options?: QueryHookOptions<SiteMTSActivityTypesQuery, Variables>) {
  const localVars = options?.variables;
  delete options?.variables;
const siteMTSActivityTypesQuery = gql`
  ${printFileSchemaFrag}
  ${languageStringFrag}

  query siteMTSActivityTypes {
    site {
      id
      mtsActivityTypes {
        id
        name {
          ...LangStringFrag
        }
        description
        sortIndex
        code
        secondaryCode
        tertiaryCode
        noteRequired
        wpr
        canEditEntryData
        canEditHours
        enabled
        hidden
        childGeneratedTypesEnabled
        ${localVars?.getFilterPropValue ? 'filterCustomPropertyValue' : ''}
        appearsInReviewAllHours
        mtsInRoadmapEnabled
        formId
        secondaryFormId
        attachmentFormEnabled
        defaultHours
        maxDailyHours
        maxEntryHours
        maxNumberOfEntries
        printFileSchema {
          ...PrintFileSchemaFrag
        }
        externalApprovalFormId
        reqExternalApprovalFormId
      }
    }
  }
`;

  return useQuery<SiteMTSActivityTypesQuery, Variables>(siteMTSActivityTypesQuery, options);
}
