import { Button, Dialog, DialogActions, DialogContent, Icon, IconButton, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React, { PropsWithChildren, useContext } from 'react';
import { DialogTitle, LanguageString, TextField } from '..';
import { makeStyles } from '../../common';
import { SiteContext } from '../../contexts';
import { LanguageString as LangStringDto, Roadmap, RoadmapMilestoneGroup } from '../../dto';
import { DialogController, useDialogController, useUpdateRoadmap, useUpdateRoadmapMilestoneGroups } from '../../hooks';

type PropsOfType<O extends object, T> = { [P in keyof O as Exclude<O[P], undefined> extends T ? P : never]: T };
type GoalItem = Roadmap | RoadmapMilestoneGroup;
type GoalProps<GI extends GoalItem, SP = PropsOfType<GI, string>> =
  { goalItem: GI, gp: keyof SP, tp: keyof SP, lsData: LsData, dlgCtrl?: DialogController, readonly?: boolean, placeholder: string };
type GrpData = { groupName: string; resourceName: string };
type LsData = GrpData | (LangStringDto | undefined);

const isGrpData = (lsData: GrpData | (LangStringDto | undefined)): lsData is GrpData => (lsData as GrpData).groupName != null;
const isRoadmap = (goalItem: GoalItem): goalItem is Roadmap => (goalItem as Roadmap).milestoneGroups != null;
const isRoadmapMsGroup = (goalItem: GoalItem): goalItem is RoadmapMilestoneGroup => (goalItem as RoadmapMilestoneGroup).milestoneGroupId != null;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateAreas: '"goal goalEdit" "translation translation"',
    gridTemplateColumns: '1fr auto',
    width: '100%'
  },
  goal: {
    gridArea: 'goal',
    alignSelf: 'center'
  },
  goalEdit: {
    gridArea: 'goalEdit',
    marginTop: `-${theme.spacing(2)}px`,
    marginBottom: `-${theme.spacing(2)}px`
  },
  translation: {
    gridArea: 'translation',
    marginTop: `${theme.spacing(1)}px`
  }
}));

export const Goal = function <GI extends GoalItem>({ goalItem, lsData, dlgCtrl, gp, tp, readonly, placeholder }: PropsWithChildren<GoalProps<GI>>) {
  const siteCtx = useContext(SiteContext);
  const { controller: localCtrl, props: localProps } = useDialogController(false);
  const [updateRoadmap] = useUpdateRoadmap();
  const [updateRoadmapMsGroups] = useUpdateRoadmapMilestoneGroups();
  const [controller, dlgProps] = dlgCtrl == null ? [localCtrl, localProps] : [dlgCtrl.controller, dlgCtrl.props];
  const classes = useStyles();
  const goalStrings: PropsOfType<GI, string> = goalItem as unknown as PropsOfType<GI, string>;

  const handleEditClick = () => controller.setOpen();

  const handleSaveClick = async (values: { goal?: string }) => {
    if (isRoadmap(goalItem)) await updateRoadmap({ variables: { input: { id: goalItem.id, [gp === 'goal' ? gp : 'employmentInfo']: values.goal } } });
    if (isRoadmapMsGroup(goalItem)) await updateRoadmapMsGroups({ variables: { input: [{ id: goalItem.id, goal: values.goal }] } });
    controller.setClose();
  };

  const lsArgs = isGrpData(lsData) ? lsData : { languageString: lsData };
  const showTranslation = navigator.language.startsWith(siteCtx.site?.defaultLanguage.code ?? 'undefined');
  const translation = goalStrings[tp];

  return (
    <div className={classes.container}>
      <Typography className={classes.goal}>{goalStrings[gp] != null ? goalStrings[gp] : <LanguageString {...lsArgs} />}</Typography>
      {dlgCtrl == null && readonly !== true && (
        <IconButton className={classes.goalEdit} color="inherit" onClick={handleEditClick} edge="end">
          <Icon>edit</Icon>
        </IconButton>
      )}
      {showTranslation && translation && <Alert className={classes.translation} severity="info"><AlertTitle>Translation</AlertTitle>{translation}</Alert>}
      <Dialog {...dlgProps} maxWidth="md" fullWidth>
        <Formik initialValues={{ goal: goalStrings[gp] }} onSubmit={handleSaveClick}>
          <Form>
            <DialogTitle onClose={controller.setClose}>
              <LanguageString groupName="ROADMAP" resourceName={placeholder} />
            </DialogTitle>
            <DialogContent dividers>
              <TextField label={<LanguageString groupName="ROADMAP" resourceName={placeholder} />} name="goal" rows={3} />
            </DialogContent>
            <DialogActions>
              <Button onClick={controller.setClose}>
                <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="CLOSE" />
              </Button>
              <Button type="submit">
                <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="SAVE" defaultText="Save" />
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};
