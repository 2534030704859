import gql from 'graphql-tag';

export const printFileSchemaFrag = gql`
  fragment PrintFileSchemaFrag on PrintFileSchema {
    id
    url
    default
    location
  }
`;

export default printFileSchemaFrag;
