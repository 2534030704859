import gql from 'graphql-tag';
import { languageStringMinFrag } from './languageStringFrag';

export const roadmapTaskFrag = gql`
  ${languageStringMinFrag}

  fragment RoadmapTaskFrag on RoadmapTask {
    id
    name {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    summary {
      ...LangStringMinFrag
    }
    instructions {
      ...LangStringMinFrag
    }
  }
`;
