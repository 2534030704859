import { Box, Breadcrumbs, Card, CardActionArea, CardContent, CardHeader, Grid, Link, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { LanguageString } from '..';
import { ChildRoutes, useChildLinks } from '../../contexts';
import { SuccessPath, LanguageString as LangString } from '../../dto';
import { MsGroupContext } from '../MsGroupProvider';
import { useClasses } from '../../hooks';

const SuccessPathBreadcrumbs: React.FC<{ name?: LangString }> = ({ name }) => {
  const match = useRouteMatch();

  return (
    <Breadcrumbs>
      {match.isExact ? (
        <LanguageString languageString={name} />
      ) : (
          <Link color="inherit" component={RouterLink} to={match.url}>
            <LanguageString languageString={name} />
          </Link>
        )}
      {!match.isExact && <ChildRoutes group="breadcrumbs" />}
    </Breadcrumbs>
  );
};

export const MsGroupCrumb: React.FC = () => {
  const group = useContext(MsGroupContext);

  return <SuccessPathBreadcrumbs name={group?.name} />;
};

export const DisplayPath: React.FC<{ path: SuccessPath }> = ({ path }) => {
  const classes = useClasses();
  const match = useRouteMatch();
  const grpLinks = useChildLinks(
    path.milestoneGroups
      ? {
          items: path.milestoneGroups,
          paramMap: { id: 'groupId' },
          childParams: {
            subGroups: {
              paramMap: { id: 'groupId' },
              routeName: 'ms-group'
            }
          },
          routeName: 'ms-group'
        }
      : null
  );

  return (
    <Grid container item spacing={3} className={classes.actionstepLibrary}>
      <Grid item xs={12}>
        <SuccessPathBreadcrumbs name={path.name} />
      </Grid>
      {match?.isExact ? (
        <Grid container item spacing={1} className="ms-container">
          {grpLinks?.map(grpLink => (
            <Grid key={grpLink.path} container item spacing={1} xs direction="column">
              <Grid item>
                <Card style={{ backgroundColor: grpLink.item.color, color: 'white' }}>
                  <CardActionArea component={RouterLink} to={grpLink.path}>
                    <CardContent>
                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '72px' }}>
                        <Typography align="center" style={{ fontWeight: 'bold' }} variant="h5">
                          <LanguageString languageString={grpLink.item.name} />
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {grpLink.childLinks?.map(subGrpLink => (
                <Grid key={subGrpLink.path} item>
                  <Card style={{ backgroundColor: grpLink.item.color, color: 'white' }}>
                    <CardActionArea component={RouterLink} to={subGrpLink.path}>
                      <CardHeader
                        title={
                          <Typography align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                            <LanguageString languageString={subGrpLink.item.name} />
                          </Typography>
                        }
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      ) : (
        <ChildRoutes />
      )}
    </Grid>
  );
};
