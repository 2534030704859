import { ButtonGroup, FormControl, FormLabel, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { IconButton } from '..';
import { AppContext } from '../../contexts';
import { FormQuestionType } from '../../dto';
import { useFocusOnError } from '../../hooks';
import { LanguageString } from '../Common/LanguageString';
import { FormFieldComp, FormStatus, getQuestionFormId, isInvalid, IValues } from './common';
import { helperText } from './HelperText';

const useStyles = makeStyles({
  ninput: {
    '&-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '&-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    }
  }
});

export const FormNumber: FormFieldComp = ({ question }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const fieldId = getQuestionFormId(question);
  const [field, meta] = useField<string>(fieldId);
  const formik = useFormikContext<IValues>();
  let { useFloatingLabels } = useContext(AppContext);
  const classes = useStyles();
  const error = isInvalid(meta);
  const { disabled: isDisabled, isReadOnly } = formik.status as FormStatus;
  const disabled = isDisabled || isReadOnly || question.isReadOnly;
  if (question.questionType !== FormQuestionType.NUMBER) throw new Error(`Cannot use Number input for question of type ${question.questionType}`);
  const step = question.step ?? .25;
  const inputProps = {
    error,
    multiline: question.rows ? question.rows > 1 : false,
    rows: question.rows,
    type: 'number',
    disabled,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <ButtonGroup style={{ padding: '-8px', margin: '-8px' }}>
            <IconButton icon="remove_circle" onClick={() => formik.setFieldValue(field.name, Number(field.value) - step)} disabled={disabled} />
            <IconButton icon="add_circle" onClick={() => formik.setFieldValue(field.name, Number(field.value) + step)} disabled={disabled} />
          </ButtonGroup>
        </InputAdornment>
      )
    },
    ...field
  };
  // TODO: Make the length at which labels become static an application setting.
  if ((question.text.text?.length ?? 0) > 70) useFloatingLabels = false;

  useFocusOnError(fieldRef, inputProps.name);

  return useFloatingLabels ? (
    <TextField className={classes.ninput} fullWidth {...inputProps} label={<LanguageString languageString={question.text} />} helperText={helperText({ hint: question.hint, meta })} ref={fieldRef} />
  ) : (
    <FormControl disabled={disabled} fullWidth>
      <FormLabel htmlFor={fieldId}>
        <LanguageString languageString={question.text} />
      </FormLabel>
      <TextField id={fieldId} aria-describedby={`${fieldId}-helper-text`} {...inputProps} helperText={helperText({ hint: question.hint, meta })} ref={fieldRef} />
    </FormControl>
  );
};
