import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { PrintLocation } from 'tuapath-common/generated/schema';

type Result = { user: { downloadPrintFile?: string } };
type Variables = { userId: number, location: PrintLocation, primary?: Boolean, relationId?: string, schemaId?: number };
export function useDownloadPrintFile(options?: LazyQueryHookOptions<Result, Variables>) {
  const validateFormMutation = gql`
    query downloadPrintFile($userId: Int!, $location: PrintLocation!, $primary: Boolean, $relationId: String, $schemaId: Int) {
      user(id: $userId) {
        id
        downloadPrintFile(location: $location, primary: $primary, relationId: $relationId, schemaId: $schemaId)
      }
    }
  `;

  return useLazyQuery<Result, Variables>(validateFormMutation, options);
}
