import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { PowerBIReporting } from 'tuapath-common/generated/schema';

type Results = { PowerBIReporting: PowerBIReporting };

export function usePowerBI(options: QueryHookOptions<Results>) {
  const query = gql`
    query PowerBI {
      PowerBIReporting {
        embedToken
        embedTokenExpiry
        workspace
        reports{
          name
          id
          embedURI
          allowEdit
        }
        datasets{
          name
          id
          createEmbedURI
        }
        UserContentPrefix
      }
    }
  `;

  return useQuery<Results>(query, options);
}
