import { Grid } from '@material-ui/core';
import { FieldMetaProps } from 'formik';
import React from 'react';
import { LanguageString as LangString } from '../../dto';
import { LanguageString } from '../Common';
import { isInvalid } from './common';

type Props = { hint: LangString | undefined; meta: FieldMetaProps<unknown> };
export const helperText = ({ hint, meta }: Props) => (isInvalid(meta) || hint != null ? <HelperText hint={hint} meta={meta} /> : null);

const HelperText: React.FC<Props> = ({ hint, meta }) => {
  const error = isInvalid(meta);

  return error || hint != null ? (
    <Grid container direction="column">
      {hint != null ? (
        <Grid item>
          <LanguageString languageString={hint} />
        </Grid>
      ) : null}
      {error ? (
        Array.isArray(meta.error) ? (
          meta.error.map(e => (
            <Grid key={e} item>
              {e}
            </Grid>
          ))
        ) : (
          <Grid item>{meta.error}</Grid>
        )
      ) : null}
    </Grid>
  ) : null;
};
