import gql from 'graphql-tag';
import { languageStringMinFrag } from './languageStringFrag';
import { milestoneGroupFrag, sitePathsMilestoneGroupFrag } from './milestoneGroupFrag';

export const pathFrag = gql`
  ${languageStringMinFrag}
  ${milestoneGroupFrag}

  fragment pathFrag on SuccessPath {
    id
    name {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    milestoneGroups {
      ...msGroup
      subGroups {
        ...msGroup
        subGroups {
          ...msGroup
          subGroups {
            ...msGroup
          }
        }
      }
    }
  }
`;

export const sitePathsPathFrag = gql`
  ${languageStringMinFrag}
  ${sitePathsMilestoneGroupFrag}

  fragment pathFrag on SuccessPath {
    id
    name {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    milestoneGroups {
      ...msGroup
      subGroups {
        ...msGroup
        subGroups {
          ...msGroup
          subGroups {
            ...msGroup
          }
        }
      }
    }
  }
`;
