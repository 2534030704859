import { Button, Checkbox, CircularProgress, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MessageDialog, MsGroupContext } from '..';
import { ChildRoutes, SiteContext, StudentContext, useChildLinks } from '../../contexts';
import { Assignment, Milestone, MilestoneGroup, Site, Task, TaskActionType, User } from '../../dto';
import UserHelper, { MilestoneState } from '../../helpers/userHelper';
import { useAddMilestoneToPath, useRoadmap } from '../../hooks';
import { LanguageString } from '../Common/LanguageString';

type MsWithBtns = Milestone & { buttons?: (rmMsId: number | undefined) => React.ReactNode };
type DisplayMilestone = MsWithBtns & { rmMsId?: number };

export interface MsGroupBtns extends MilestoneGroup {
  milestones?: MsWithBtns[];
  assignments?: Assignment[];
  filter?: number[];
}

type MilestoneListInnerProps = {
  group: MsGroupBtns;
  inPathOnly?: boolean;
  student: User;
  taskDetail?: boolean;
};

const MsIcon: React.FC<{ status: MilestoneState }> = ({ status }) =>
  status === MilestoneState.COMPLETE ? (
    <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="MILESTONE_COMPLETED" defaultText="Completed" />}>
      <Icon style={{ color: 'green' }}>check_circle</Icon>
    </Tooltip>
  ) : status === MilestoneState.STARTED ? (
    <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="MILESTONE_STARTED" defaultText="Started" />}>
      <Icon style={{ color: '#f1c40f' }}>swap_vertical_circle</Icon>
    </Tooltip>
  ) : status === MilestoneState.NOT_STARTED ? (
    <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="MILESTONE_NOT_STARTED" defaultText="No Started" />}>
      <Icon color="error">error</Icon>
    </Tooltip>
  ) : (
    <Tooltip title="Not in path">
      <Icon style={{ color: 'black' }}>clear</Icon>
    </Tooltip>
  );

const TsIcon: React.FC<{ student: User; msId: number; task: Task; step: number, rmMsId: number | undefined }> = ({ student, msId, task, step, rmMsId }) => {
  const status = UserHelper.taskStatus(student, msId, task, rmMsId);
  const style = { width: '24px', heigth: '24px', display: 'flex', justifyContent: 'center' };

  const icon = task.actionType === TaskActionType.webForm
    ? 'list_alt'
    : task.actionType === TaskActionType.video
      ? 'ondemand_video'
      : task.actionType === TaskActionType.link
        ? 'language'
        : task.actionType === TaskActionType.document
          ? 'backup'
          : task.actionType === TaskActionType.readOnly
            ? 'book'
            : 'event';

  return <Tooltip title={<LanguageString languageString={task.name} />}>{
    status === MilestoneState.COMPLETE
      ? <Icon style={{ color: 'green', ...style }}>{icon}</Icon>
      : status === MilestoneState.STARTED
        ? <Icon style={{ color: '#f1c40f', ...style }}>{icon}</Icon>
        : <Icon style={{ color: 'red', ...style }}>{icon}</Icon>}
  </Tooltip>;
};

const msName = (ms: Milestone) => {
  return ms.tasks?.[0]?.name;
};

type MSProps = {
  item: MsWithBtns;
  path: string;
  student: User;
  site?: Site;
  maxTasks: number;
  taskDetail?: boolean;
  inPathOnly?: boolean;
  handleAddMilestoneClick: (milestone: Milestone) => void;
  handleAddAnother: (milestone: Milestone) => void;
  assignments?: Assignment[];
};
const MS: React.FC<MSProps> = ({item, path, student, site, maxTasks, taskDetail, inPathOnly, handleAddMilestoneClick, handleAddAnother, assignments}) => {
  const msIsInPath = (ms: Milestone) => student.assignments?.assignments?.find(a => a.milestoneId === ms.id) != null;

  const msAssignments = assignments?.filter(a => a.milestoneId === item.id).reduce((pv, cv) => {
    pv.add(cv.roadmapMilestoneId);
    return pv;
  }, new Set<number | undefined>());

  const dItems: DisplayMilestone[] = msAssignments?.size ? [...msAssignments].map(rmMsId => ({ ...item, rmMsId })): [item];

  return (<>{dItems.map(item => (
    <ListItem key={item.id} component={RouterLink} to={`${path}${item.rmMsId ? `/${item.rmMsId}` : ''}`} button>
      <ListItemIcon>
        <MsIcon status={UserHelper.milestoneStatus(student, item.id, item.rmMsId)} />
      </ListItemIcon>
      {taskDetail && <div style={{ display: 'flex', flexDirection: 'row', width: `${26 * maxTasks}px`, justifyContent: 'start', marginRight: '8px', marginLeft: '-12px' }}>
        {item.tasks?.map((t, step) => <TsIcon student={student} msId={item.id} task={t} step={step} rmMsId={item.rmMsId} />)}
      </div>}
      <ListItemText id={String(item.id)}>
        <LanguageString languageString={msName(item)} />
      </ListItemText>
      {!inPathOnly && site?.roadmapEnabled ? (
        <ListItemSecondaryAction>
          {item.allowMultipleInstances
            ? msIsInPath(item)
              ? <Button variant="contained" color="primary" style={{ cursor: 'pointer', padding: 0, minWidth: 0 }} onClick={() => handleAddAnother(item)}><Icon>add</Icon></Button>
              : <Checkbox edge="end" checked={msIsInPath(item)} onClick={() => handleAddMilestoneClick(item)} />
            : <Checkbox edge="end" checked={msIsInPath(item)} onClick={() => handleAddMilestoneClick(item)} />
          }
        </ListItemSecondaryAction>
      ) : null}
      {item.buttons != null ? <ListItemSecondaryAction>{item.buttons(item.rmMsId)}</ListItemSecondaryAction> : null}
    </ListItem>
  ))}</>);
};

const MilestoneListInner: React.FC<MilestoneListInnerProps> = ({ group, inPathOnly, student, taskDetail }) => {
  const [addMsToPath] = useAddMilestoneToPath();
  const siteCtx = useContext(SiteContext);
  const msgCtx = useContext(MsGroupContext);
  const childLinks = useChildLinks({
    items: group.milestones ? group.milestones : [],
    paramMap: { id: 'msId' },
    routeName: 'milestone'
  });
  const [selectedMs, setSelectedMs] = useState<Milestone | undefined>();
  const [anotherMs, setAnotherMs] = useState<Milestone | undefined>();

  const handleAddMilestoneClick = (milestone: Milestone) => setSelectedMs(milestone);

  const handleAddMilestoneConfirm = async (milestone: Milestone) => {
    await addMsToPath({ variables: { input: { milestoneId: milestone.id, userId: student.id, milestoneGroupId: msgCtx?.id } } });
    setSelectedMs(undefined);
  };

  const handleAddAnother = async (milestone: Milestone) => setAnotherMs(milestone);

  const msIsInPath = (ms: Milestone) => student.assignments?.assignments?.find(a => a.milestoneId === ms.id) != null;

  const showMilestone = (ms: Milestone) => {
    const passesFilter = group.filter == null || group.filter.includes(ms.id);
    const passesInPath = inPathOnly !== true || msIsInPath(ms);

    return passesFilter && passesInPath;
  };

  const maxTasks = group.milestones?.reduce((max, ms) => showMilestone(ms) ? (ms.tasks?.length ?? 0) > max ? (ms.tasks?.length ?? 0) : max : max, 0) ?? 1;

  const msProps: Omit<MSProps, 'item' | 'path'> = {
    handleAddAnother,
    handleAddMilestoneClick,
    inPathOnly,
    maxTasks,
    student,
    taskDetail,
    site: siteCtx.site,
    assignments: group.assignments
  };

  return childLinks ? (
    <List>
      {childLinks.map(ch =>
        showMilestone(ch.item) ? <MS {...msProps} item={ch.item} path={ch.path} /> : null
      )}
      {(selectedMs != null || anotherMs != null) && (
        <MessageDialog
          message={selectedMs
            ? <LanguageString groupName="ROADMAP" resourceName="ADD_MILESTONE" defaultText="Add this milestone to your roadmap?" />
            : <LanguageString groupName="ROADMAP" resourceName="ADD_ANOTHER_MILESTONE" defaultText="Add another one of these milestones to your roadmap?" />}
          onConfirm={() => handleAddMilestoneConfirm(selectedMs ? selectedMs : anotherMs!)}
        />
      )}
    </List>
  ) : (
    <ChildRoutes />
  );
};

type MilestoneListProps = {
  group: MsGroupBtns;
  inPathOnly?: boolean;
  taskDetail?: boolean;
};

export const MilestoneList: React.FC<MilestoneListProps> = props => {
  const studentCtx = useContext(StudentContext);
  const { loading, data: rmData } = useRoadmap({ variables: { userId: studentCtx!.student.id } });

  return loading ? (
    <CircularProgress color="primary" />
  ) : rmData != null && studentCtx?.student != null ? (
    <MilestoneListInner student={studentCtx.student} {...props} />
  ) : null;
};
