import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { StudentsOrder, SubmitFormInput, User } from '../../dto';
import { ReqVars } from './common';

type Variables = {
  limit: number;
  offset: number;
  advisorIds?: number[];
  search?: string;
  order?: StudentsOrder;
  filter?: SubmitFormInput;
  studentIds?: number[];
};
type Results = {
  students: {
    totalCount: number;
    students: User[];
  };
  studentsWithId?: User[];
};

const query = gql`
  query getStudentsNotification($limit: Int!, $offset: Int!, $advisorIds: [Int], $search: String, $order: StudentsOrder, $filter: SubmitFormInput) {
    students(searchParams: {limit: $limit, offset: $offset, advisorIds: $advisorIds, search: $search, order: $order, filter: $filter}) {
      totalCount
      students {
        id
        username
        firstName
        lastName
        phoneNumber
        advisor {
          id
        }
        customProperties {
          id
          value
          type {
            id
            name
            fieldType
          }
        }
        emails {
          id
          email
          primary
        }
      }
    }
  }
`;

export const useStudentsNotification = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
