import * as pathToRegexp from 'path-to-regexp';
import * as React from 'react';
import { Fragment, useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { TypedReflect } from '../../common';
import { RouteBuilder } from '../../components';
import { NavComponent, NavRoute } from '../../dto';
import { SiteContext } from '../SiteProvider';
import { UserContext } from '../UserProvider';

export type RouteInfo = {
  name: string;
  title: string;
  path: string;
  icon?: string;
  exact?: boolean;
  recursive?: boolean;
  component: NavComponent;
  group?: string;
  sortOrder: number;
  children?: RouteInfo[];
  role: string;
};

export type ChildRoute = {
  name: string;
  route: React.ReactElement;
};

// TODO: Get these from the RouteInfo somehow?
type ValidLinkParams = 'date' | 'groupId' | 'id' | 'month' | 'msId' | 'mtsId' | 'periodId' | 'selectedDay' | 'studentId' | 'year';
export type RouteContext = { info: NavRoute; childRoutes?: ChildRoute[] };
export const RouteContext = React.createContext<RouteContext>({ info: {} as NavRoute });

export type LinkParams<T> = {
  items: T[];
  paramMap: { [P in keyof T]?: ValidLinkParams };
  childParams?: { [P in keyof T]?: Omit<LinkParams<Exclude<T[P], undefined> extends Array<infer C> ? C : never>, 'items'> };
  routeName?: string;
};
export type ChildLinks<T> = { item: T ; path: string; title?: string; id: number; childLinks?: ChildLinks<T>[] };

function getRelevantChildren<T>(navRoute: NavRoute, params?: LinkParams<T>) {
  let children = navRoute.children ? navRoute.children : [];
  if (params?.routeName) {
    children = children.filter(ch => ch.name === params.routeName);
  }
  if (navRoute.isRecursive && (params?.routeName == null || navRoute.name === params.routeName)) {
    children.push(navRoute);
  }

  return children;
}

function mapRoutesToLinks<T>(routes: NavRoute[], baseUrl?: string, param?: LinkParams<T>): ChildLinks<T>[] | undefined {
  return routes
    .map(chRt => {
      const chRtPath = chRt.href && chRt.href.length > 0 ? chRt.href : chRt.path;
      const toPath = pathToRegexp.compile(baseUrl ? `${baseUrl}${chRtPath}` : chRtPath, { validate: false });
      if (param) {
        // const childRoutes = chRt.children ? chRt.children : chRt.recursive ? [chRt] : [];
        return param.items
          ? param.items.map(item => {
            const paramMap: { [index: string]: string } = {};
            for (const pi of TypedReflect.ownKeys(param.paramMap)) {
              paramMap[param.paramMap[pi]!] = String(item[pi]);
            }
            // TODO: Clean this up!
            let childLinks: LinkParams<T> | undefined;
            if ((chRt.children || chRt.isRecursive) && param.childParams) {
              const childKey = TypedReflect.ownKeys(param.childParams)[0];
              const childItems = item[childKey] as unknown as T[];
              const childParams = param.childParams[childKey];
              childLinks = { ...childParams, items: childItems } as unknown as LinkParams<T>;
            }
            const children = getRelevantChildren(chRt, param);
            return {
              item,
              path: toPath(paramMap),
              title: chRt.title.text ?? '',
              id: chRt.id,
              childLinks:
                childLinks && (chRt.children || chRt.isRecursive) ? mapRoutesToLinks(children, toPath(paramMap), childLinks) : undefined
            };
          })
          : [];
      } else {
        return [{ path: toPath(), item: chRt as unknown as T, title: chRt.title.text ?? '', id: chRt.id }];
      }
    })
    .flat();
}

function findRouteWithName(navRoute: NavRoute, name: string): NavRoute | undefined {
  if (navRoute.name === name) {
    return navRoute;
  } else if (navRoute.children) {
    for (const c of navRoute.children) {
      const cn = findRouteWithName(c, name);
      if (cn) return cn;
    }
  }

  return undefined;
}

function findParentRoute(childRoute: NavRoute, navRoute: NavRoute): NavRoute | undefined {
  if (navRoute.children) {
    for (const c of navRoute.children) {
      if (c.id === childRoute.id) {
        return navRoute;
      } else {
        const parent = findParentRoute(childRoute, c);
        if (parent) return parent;
      }
    }
  }

  return undefined;
}

function buildPathForRoute(navRoute: NavRoute, navRoutes: NavRoute[]): string | undefined {
  for (const r of navRoutes) {
    const cp = compilePathForRoute(navRoute, r);
    if (cp) {
      const path = cp[0];
      const hasRoute = cp[1];

      if (hasRoute) {
        return path;
      }
    }
  }

  return undefined;
}

function compilePathForRoute(navRoute: NavRoute, route: NavRoute): [string, boolean] | undefined {
  if (route.id === navRoute.id) {
    return [route.path, true];
  } else if (route.children) {
    for (const c of route.children) {
      const childRoute = compilePathForRoute(navRoute, c);
      if (childRoute && childRoute[1] === true) {
        if (route.path === '/') {
          return [childRoute[0], true];
        } else {
          return [route.path + childRoute[0], true];
        }
      }
    }
  }

  return undefined;
}

export function useStudentsChildLinks<T = NavRoute>(
  parentRouteName: string,
  params?: LinkParams<T> | null,
  generateFullRoute?: boolean
): { links: ChildLinks<T>[] | undefined, routes: NavRoute[] } | undefined {
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);
  let parentRoute: NavRoute | undefined = undefined;

  if (siteCtx.site?.navRoutes) {
    for (const r of siteCtx.site.navRoutes) {
      if (r.role === userCtx.user?.currentRole) {
        const p = findRouteWithName(r, parentRouteName);
        if (p) {
          parentRoute = p;
          break;
        }
      }
    }
  }

  let fullPath: string | undefined = '';
  if (parentRoute) {
    fullPath = buildPathForRoute(parentRoute, siteCtx.site?.navRoutes ?? []);
  }

  const pathComponents = fullPath?.split('/');
  const rootComp = pathComponents && pathComponents.length > 1 ? pathComponents[1] : undefined;
  const match = useRouteMatch(fullPath ?? '/');
  const baseUrl = match ? match.url : (rootComp ? `/${rootComp}` : undefined);

  if (parentRoute) {
    if (params === null) return;

    if (parentRoute.children && parentRoute.children.length > 0) {
      const links = mapRoutesToLinks(
        parentRoute.children,
        generateFullRoute ? fullPath : baseUrl === '/' ? undefined : baseUrl,
        params
      );

      return { links: links, routes: parentRoute.children };
    }
  }

  return;
}

export function useFullRoute(): string | undefined {
  const siteCtx = useContext(SiteContext);
  const routeCtx = useContext(RouteContext);

  return buildPathForRoute(routeCtx.info, siteCtx.site?.navRoutes ?? []);
}

export function useChildLinkOfParentWithName<T = NavRoute>(childName: string, route?: NavRoute, params?: LinkParams<T> | null): ChildLinks<T>[] | undefined {
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);

  let parentRoute = route;
  if (!parentRoute && siteCtx.site?.navRoutes) {
    for (const route of siteCtx.site.navRoutes) {
      if (route.role === userCtx.user?.currentRole) {
        parentRoute = route;
        break;
      }
    }
  }

  if (!parentRoute) return;

  const childRoute = findRouteWithName(parentRoute, childName);

  if (!childRoute) {
    console.log(`failed to find child route with name: ${childName} in parent route: `, parentRoute);
    return;
  }

  let fullPath = buildPathForRoute(childRoute!, siteCtx.site?.navRoutes ?? []);
  if (fullPath) {
    // Remove optional path segments. This is used specifically to fix the issue with "students"
    // being left out of the generated URL
    const i = fullPath.indexOf(')?');
    if (i >= 0) {
      fullPath = fullPath.substring(0, i) + fullPath.substring(i + 2);
      const startIndex = fullPath.lastIndexOf('(', i);
      fullPath = fullPath.substring(0, startIndex) + fullPath.substring(startIndex + 1);
    }
  }

  if (childRoute) {
    if (params === null) return;

    const children = getRelevantChildren(childRoute, params);

    if (children && children.length > 0) {
      return mapRoutesToLinks(children, fullPath, params);
    }
  }

  return;
}

export function useLinks<T = NavRoute>(params?: LinkParams<T> | null): ChildLinks<T>[] | undefined {
  // This function is essentially the same as useChildLinks but will also search the sibiling routes, not just children
  const siteCtx = useContext(SiteContext);
  const context = useContext(RouteContext);
  let parentRoute: NavRoute | undefined = undefined;

  if (siteCtx.site?.navRoutes) {
    for (const r of siteCtx.site.navRoutes) {
      const p = findParentRoute(context.info, r);
      if (p) {
        parentRoute = p;
        break;
      }
    }
  }

  const fullPath = buildPathForRoute(parentRoute!, siteCtx.site?.navRoutes ?? []);
  const match = useRouteMatch(fullPath ?? '/');

  if (parentRoute && match) {
    const baseUrl = match ? match.url : undefined;

    if (params === null) return;

    const children = getRelevantChildren(parentRoute, params);

    if (children && children.length > 0) {
      return mapRoutesToLinks(children, baseUrl === '/' ? undefined : baseUrl, params);
    }
  }

  return;
}

export function useParentLinks<T = NavRoute>(parentRouteName: string, params?: LinkParams<T> | null): ChildLinks<T>[] | undefined {
  // This function is essentially the same as useChildLinks but will also search the sibiling routes, not just children
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);
  let parentRoute: NavRoute | undefined = undefined;

  if (siteCtx.site?.navRoutes) {
    for (const r of siteCtx.site.navRoutes) {
      if (r.role === userCtx.user?.currentRole) {
        const p = findRouteWithName(r, parentRouteName);
        if (p) {
          parentRoute = p;
          break;
        }
      }
    }
  }

  const fullPath = buildPathForRoute(parentRoute!, siteCtx.site?.navRoutes ?? []);
  const match = useRouteMatch(fullPath ?? '/');

  if (parentRoute && match) {
    const baseUrl = match ? match.url : undefined;

    if (params === null) return;

    const children = getRelevantChildren(parentRoute, params);

    if (children && children.length > 0) {
      return mapRoutesToLinks(children, baseUrl === '/' ? undefined : baseUrl, params);
    }
  }

  return;
}

export function useRawChildLinks<T = NavRoute>(parentRouteName: string, params?: LinkParams<T> | null): NavRoute[] | undefined {
  const userCtx = useContext(UserContext);
  const siteCtx = useContext(SiteContext);

  if (params === null) return;

  let parentRoute: NavRoute | undefined = undefined;

  if (siteCtx.site?.navRoutes) {
    for (const r of siteCtx.site.navRoutes) {
      if (r.role === userCtx.user?.currentRole) {
        const p = findRouteWithName(r, parentRouteName);
        if (p) {
          parentRoute = p;
          break;
        }
      }
    }
  }

  return parentRoute?.children;
}

export function useChildLinks<T = NavRoute>(params?: LinkParams<T> | null): ChildLinks<T>[] | undefined {
  const match = useRouteMatch();
  const context = useContext(RouteContext);

  const baseUrl = match ? match.url : undefined;

  if (params === null) return;

  const children = getRelevantChildren(context.info, params);

  if (children && children.length > 0) {
    return mapRoutesToLinks(children, baseUrl === '/' ? undefined : baseUrl, params);
  }

  return;
}

export const useSelectedChildIndex = () => {
  const match = useRouteMatch();
  const { info: routes } = useContext(RouteContext);
  if (!match) throw new Error('No match - cannot use useSelectedChildIndex hook');
  const matchPath = `${match.url}:child`;
  const match2 = useRouteMatch<{ child: string }>(matchPath);

  const tIndex = match2 ? (routes.children ? routes.children.findIndex(r => r.path === `/${match2.params.child}`) : 0) : 0;

  return tIndex;
};

export const ChildRoutes: React.FC<{ group?: string | null }> = ({ group = null }) => {
  const match = useRouteMatch();
  if (!match) throw new Error('No match');
  const ctx = useContext(RouteContext);

  const children = ctx.info.children;
  return (
    <Fragment>
      {children &&
        children
          .filter(ch => ch.group === group)
          .map(ch => <RouteBuilder key={ch.name} route={ch} baseUrl={match.url} />)}
      {ctx.info.isRecursive && <RouteBuilder route={ctx.info} baseUrl={match.url} />}
    </Fragment>
  );
};

export default RouteContext;
