import {
  FormControl,
  Grid,
  Select as MuiSelect,
  InputLabel,
  MenuItem,
  TextFieldProps as MuiTextFieldProps
} from '@material-ui/core';
import { useField } from 'formik';
import React, { ChangeEventHandler, CSSProperties } from 'react';
import { GridWrapperProps } from '../TextField';

export interface SelectOption {
  id: number;
  text: string;
  value: string;
}

export type SelectFieldProps = GridWrapperProps & {
  name: string;
  label?: MuiTextFieldProps['label'];
  style?: CSSProperties;
  onChange?: ChangeEventHandler<{ name?: string | undefined; value: unknown; }>;
  type?: string;
  items: SelectOption[];
};

export const Select: React.FC<SelectFieldProps> = props => {
  const { name, onChange, xs, sm, md, lg, xl, item, items, label } = props;
  const [{ onChange: onFieldChange, value = '', ...field }] = useField(name);
  const handleChange: ChangeEventHandler<{ name?: string | undefined; value: unknown; }> = event => {
    onFieldChange(event);
    if (onChange) onChange(event);
  };

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id={`${field.name}_label`}>{label}</InputLabel>
        <MuiSelect
          id={`select_${field.name}`}
          name={name}
          fullWidth
          labelId={`${field.name}_label`}
          value={value}
          onChange={handleChange}
          label={label}
        >
          {items.map(l => {
            return (
              <MenuItem key={`select_option_${l.id}`} value={l.id}>
                {l.text}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    </Grid>
  );
};
export default Select;
