import React from 'react';
import {
  SvgIcon
} from '@material-ui/core';

export const ProfileIcon: React.FC = () => (
  <SvgIcon>
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M11.06,4.89a3.87,3.87,0,1,0,3.87,3.87A3.87,3.87,0,0,0,11.06,4.89Zm0,6.1a2.23,2.23,0,1,1,2.23-2.23A2.23,2.23,0,0,1,11.06,11Z"/><path d="M11.06,0A11,11,0,0,0,4.75,20.12a9.82,9.82,0,0,0,1.64.94l.32.16a11.08,11.08,0,0,0,8.7,0l.32-.16a11.59,11.59,0,0,0,1.64-.94A11,11,0,0,0,11.06,0Zm0,20.22A9.07,9.07,0,0,1,6.44,19a4.66,4.66,0,0,1,9.24,0A9.1,9.1,0,0,1,11.06,20.22Zm6.07-2.32A6.3,6.3,0,0,0,5,17.9a9.16,9.16,0,1,1,12.14,0Z"/></g></g>
  </SvgIcon>
);

export default ProfileIcon;
