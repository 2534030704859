import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { GridWrapperProps } from '../TextField';

type DateFieldProps = GridWrapperProps & {
  name: string;
  label?: MuiTextFieldProps['label'];
};

type DateTimeFieldProps = GridWrapperProps & {
  name: string;
  label?: MuiTextFieldProps['label'];
  minutesStep?: number;
  onChange?: (date: MaterialUiPickersDate) => void;
};

export const DateField: React.FC<DateFieldProps> = ({ name, label, item, xs, sm, md, lg, xl }) => {
  const [field] = useField<{ [name: string]: Date }>(name);
  const formik = useFormikContext<{ [name: string]: Date }>();
  const handleChange = useCallback((date: MaterialUiPickersDate) => formik.setFieldValue(field.name, date), []);
  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker fullWidth label={label} {...field} onChange={handleChange} />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export const DateTimeField: React.FC<DateTimeFieldProps> = ({ name, label, minutesStep = 1, item, xs, sm, md, lg, xl, onChange }) => {
  const [field] = useField<{ [name: string]: Date }>(name);
  const formik = useFormikContext<{ [name: string]: Date }>();
  const handleChange = useCallback((date: MaterialUiPickersDate) => formik.setFieldValue(field.name, date), []);
  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker fullWidth label={label} {...field} onChange={onChange ?? handleChange} minutesStep={minutesStep} />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
