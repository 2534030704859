import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';

type Variables = DTO.AdvisorsQueryArgs;
type Results = { advisors: DTO.AdvisorResponse };
export const useAdvisors = (options: QueryHookOptions<Results, Variables> & { variables: Variables }) => {
  const query = gql`
    query getAdvisors($limit: Int, $offset: Int, $params: [ResponseParamInput], $order: StudentsOrder) {
      advisors(limit: $limit, offset: $offset, params: $params, order: $order) {
        advisors {
          id
          username
          firstName
          lastName
        }
        totalCount
      }
    }
  `;

  return useQuery<Results>(query, options);
};

export default useAdvisors;
