import { Grid } from '@material-ui/core';
import React from 'react';
import * as DTO from '../../dto';
import { LanguageString } from '../Common/LanguageString';
import { isDependsOnSatisfied, isNonBlank, IValues } from './common';
import { FormQuestion } from './FormQuestion';

interface FormElementProps {
  element: DTO.FormElement;
  values: IValues;
  shouldRemovePaswordFields: boolean;
}

const FormElementTitle: React.FC<{ element: DTO.FormElement }> = ({ element }) =>
  isNonBlank(element.title) ? (
    <Grid className="element-title-container" item>
      <LanguageString languageString={element.title} className="section-title" />
    </Grid>
  ) : null;

const FormElementInstructions: React.FC<{ element: DTO.FormElement }> = ({ element }) =>
  isNonBlank(element.instructions) ? (
    <Grid className="element-instructions-container" item>
      <LanguageString languageString={element.instructions} />
    </Grid>
  ) : null;

export const layoutDir = (layoutType?: DTO.FormLayoutType) => (layoutType === DTO.FormLayoutType.ROWS ? 'column' : 'row');
export const FormLayout: React.FC<{ layoutType: DTO.FormLayoutType }> = React.memo(({ layoutType, children }) => (
  <Grid className="element-children-container" container item direction={layoutDir(layoutType)} wrap="nowrap" spacing={2}>
    {children}
  </Grid>
));

export const FormElement: React.FC<FormElementProps> = React.memo(({ element, values, shouldRemovePaswordFields }) =>
  isDependsOnSatisfied(values, element) ? (
    <Grid className="element-root" container item direction="column" spacing={1}>
      <FormElementTitle element={element} />
      <FormElementInstructions element={element} />
      <Grid className="element-children-container" container item direction={layoutDir(element.layoutType)} wrap="nowrap" spacing={2}>
        {element.childElements?.map(el => (
          <Grid key={el.id} className="element-child-container" item xs>
            <FormElement element={el} values={values} shouldRemovePaswordFields={shouldRemovePaswordFields} />
          </Grid>
        ))}
        {!!element.questions?.length && element.questions?.map(q =>
          <FormQuestion key={q.id} question={q} shouldRemovePaswordFields={shouldRemovePaswordFields} />
        )}
      </Grid>
    </Grid>
  ) : null
);
