import React, { useState } from 'react';
import {
  Grid,
  Container,
  Typography,
  Icon,
  Grow
} from '@material-ui/core';

export const InvalidSite: React.FC = () => {

  const timeout = 1500;
  const [animateIn, setAnimateIn] = useState(true);

  setTimeout(() => {
    setAnimateIn(!animateIn);
  }, timeout);

  return (
    <Container>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          height: '100vh'
        }}
      >
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <Typography variant="h5">Oops, something is wrong. We're working on fixing it.</Typography>
          <Grow in={animateIn} timeout={timeout}>
            <Icon style={{ fontSize: 50, marginTop: 20, color: '#e74c3c' }}>bug_report</Icon>
          </Grow>
        </Grid>
      </Grid>
    </Container>
  );
};
export default InvalidSite;
