import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MilestoneGroup, AddOrRemoveMilestonesMutationArgs } from '../../dto';
import { milestoneGroupFrag } from './fragments/milestoneGroupFrag';

type Result = { milestoneGroups: MilestoneGroup[] };
type Variables = AddOrRemoveMilestonesMutationArgs;
export function useAddOrRemoveMilestone(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${milestoneGroupFrag}

    mutation addOrRemoveMilestones($input: AddRmMsQueryArgs!) {
      addOrRemoveMilestones(input: $input) {
        ...msGroup
        subGroups {
          ...msGroup
          subGroups {
            ...msGroup
            subGroups {
              ...msGroup
            }
          }
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useAddOrRemoveMilestone;

