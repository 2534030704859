import gql from 'graphql-tag';
import { mtsPeriodGroupFrag } from './mtsPeriodGroupFrag';

export const mtsPeriodFrag = gql`
  ${mtsPeriodGroupFrag}
  fragment MTSPeriodFrag on MTSPeriod {
    id
    startDate
    endDate
    customPropertyValue
    startDateYearOffset
    endDateYearOffset
    mtsSubmissionEndDayOffset
    mtsSubmissionStartDayOffset
    mtsModificationEndDayOffset
    mtsModificationStartDayOffset
    mtsApproveEndDayOffset
    mtsApproveStartDayOffset
    group {
      ...MTSPeriodGroupFrag
    }
  }
`;
