import { Grid } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import * as DTO from 'tuapath-common/generated/schema';
import { useFieldValidator } from '../../hooks/gql/useFieldValidator';
import { getQuestionFormId, isDependsOnSatisfied, IValues } from './common';
import { FormBoolean } from './FormBoolean';
import { FormDate } from './FormDate';
import { FormInformation } from './FormInformation';
// import { getQuestionFormId, isDependsOnSatisfied } from './common';
// import { FormBoolean } from './FormBoolean';
// import { FormDate } from './FormDate';
// import { FormInformation } from './FormInformation';
import { FormInput } from './FormInput';
import { FormRating } from './FormRating';
import { FormSelect } from './FormSelect';
import { FormGraph } from './FormGraph';
import { FormNumber } from './FormNumber';
// import { Input } from '@material-ui/core';
// import { FormRating } from './FormRating';
// import { FormSelect } from './FormSelect';

async function validateOnServer(validator: ReturnType<typeof useFieldValidator>, q: DTO.FormQuestion, value: unknown) {
  const error = await validator({ variables: { input: { questionId: q.id, answer: value != null ? String(value) : undefined } } });

  if (error.data?.validateQuestion != null) {
    return error.data.validateQuestion.errorMessage;
  }

  return;
}

export const FormQuestion: React.FC<{ question: DTO.FormQuestion, shouldRemovePaswordFields: boolean }> = ({ question, shouldRemovePaswordFields }) => {
  const serverValidation = useFieldValidator();
  const fieldName = getQuestionFormId(question);
  const validator = useCallback(async (value: unknown) => await validateOnServer(serverValidation, question, value), []);
  useField({ name: fieldName, validate: question.name === 'verifyPIN' ? validator : undefined });
  const { values } = useFormikContext<IValues>();
  let q: JSX.Element | null = null;

  if (shouldRemovePaswordFields && question.questionType === DTO.FormQuestionType.PASSWORD) {
    return null;
  }

  if (isDependsOnSatisfied(values, question)) {
    switch (question.questionType) {
      case DTO.FormQuestionType.TEXT:
      case DTO.FormQuestionType.PASSWORD:
        q = <FormInput question={question} />;
        break;
      case DTO.FormQuestionType.SELECT:
      case DTO.FormQuestionType.MULTISELECT:
        q = <FormSelect question={question} />;
        break;
      case DTO.FormQuestionType.BOOLEAN:
        q = <FormBoolean question={question} />;
        break;
      case DTO.FormQuestionType.RATING:
        q = <FormRating question={question} min={question.minRateLevel ?? 1} max={question.rateLevels} />;
        break;
      case DTO.FormQuestionType.DATE:
      case DTO.FormQuestionType.DATETIME:
      case DTO.FormQuestionType.DUEDATE:
      case DTO.FormQuestionType.TIME:
        q = <FormDate question={question} />;
        break;
      case DTO.FormQuestionType.INFORMATION:
        q = <FormInformation question={question} />;
        break;
      case DTO.FormQuestionType.GRAPH:
      case DTO.FormQuestionType.GRAPHWITHDATE:
        q = <FormGraph question={question} />;
        break;
      case DTO.FormQuestionType.NUMBER:
        q = <FormNumber question={question} />;
        break;
      default:
        q = <div>Not implemented {question.questionType}</div>;
    }

    return (
      <Grid className="element-question-container" item xs>
        {q}
      </Grid>
    );
  }

  return null;

  // return (
  //   <Field name={getQuestionFormId(question)} validate={validate}>
  //     {(fieldProps: FieldProps) => {
  //       if (question.shouldHideIfNull && fieldProps.field.value == null) {
  //         return null;
  //       }
  //       // if (!isDependsOnSatisfied(fieldProps.form, question)) {
  //       //   return <Input type="hidden" {...fieldProps.field} />;
  //       // }
  //       const props = { question, fieldProps };

  //       // if (question.isReadOnly) {
  //       //   return <FormInformation {...props} />;
  //       // }

  //       switch (question.questionType) {
  //         // case DTO.FormQuestionType.INFORMATION:
  //         //   return <FormInformation {...props} />;
  //         case DTO.FormQuestionType.TEXT:
  //         case DTO.FormQuestionType.PASSWORD:
  //           return <FormInput {...props} />;
  //         // case DTO.FormQuestionType.SELECT:
  //         // case DTO.FormQuestionType.MULTISELECT:
  //         //   return <FormSelect {...props} />;
  //         // case DTO.FormQuestionType.BOOLEAN:
  //         //   return <FormBoolean {...props} />;
  //         // case DTO.FormQuestionType.RATING:
  //         //   return <FormRating {...props} />;
  //         // case DTO.FormQuestionType.DATE:
  //         //   return <FormDate {...props} />;
  //         default:
  //           return <div>Not implemented</div>;
  //       }
  //     }}
  //   </Field>
  // );
};
