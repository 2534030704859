import { TableCell, TableRow } from '@material-ui/core';
import React, { useContext } from 'react';
import * as DTO from '../../dto';
import { QueryType } from '../../helpers/query';
import { format } from 'date-fns';
import { FQContext } from '.';
import { FQContextProps } from './RecordOfContacts';
import { getDynamicSelectOptions, questionsIterator } from '../DynamicForm/common';
import { LanguageString } from '..';

interface RecordOfContactsTableBodyProps {
  rocs: DTO.RecordOfContact[];
  colQuestions: DTO.FormQuestion[];
  selectedRoc: (roc: QueryType<DTO.RecordOfContact>) => void;
  rocTypes: DTO.ROCType[];
}

const formatDate = (dateStr: string) => format(new Date(dateStr), 'MM/dd/yyyy');

const formatAnswer = (question: DTO.FormQuestion, rocTypes: DTO.ROCType[], fqCtx: FQContextProps, answer?: DTO.FormSubmissionAnswer) => {
  if (answer != null) {
    switch (question.questionType) {
      case DTO.FormQuestionType.DATE:
        return answer.answer != null ? formatDate(answer.answer) : undefined;
      default:
        if (question.optionDefinition?.query != null) {
          const data = getDynamicSelectOptions(question, fqCtx.qresults);
          if (answer.selections?.length) {
            return data?.find(d => d.value === answer.selections?.[0].id)?.label ?? `Not found ${answer.selections?.[0].id}`;
          }
        }
        if (answer.selections?.length) return answer.selections[0].text;
        return question.name?.startsWith('toc') && (answer.selections?.length ?? 0 > 0) ? rocTypes.find(rt => rt.id === Number(answer.selections?.[0].id))?.name?.text : answer.answer;
    }
  } else {
    return undefined;
  }
};
const qIsDoc = (question: DTO.FormQuestion) => question.name?.match(/^doc(-.*)?$/) != null;
const qIsToc = (question: DTO.FormQuestion) => question.name?.match(/^toc(-.*)?$/) != null;
const qIsFrom = (question: DTO.FormQuestion) => question.name?.match(/^from(-.*)?$/) != null;
const getHasDoc = (questions: DTO.FormQuestion[]) => questions.find(q => qIsDoc(q)) != null;
const getHasToc = (questions: DTO.FormQuestion[]) => questions.find(q => qIsToc(q)) != null;
const getHasFrom = (questions: DTO.FormQuestion[]) => questions.find(q => qIsFrom(q)) != null;
type RtHasMap = { [rtId: number]: { hasDoc: boolean; hasToc: boolean; hasFrom: boolean; rocType: DTO.ROCType; questions: DTO.FormQuestion[] } };
const buildRtHasMap = (rocTypes: DTO.ROCType[]) => {
  const rtnHasMap: RtHasMap = {};
  rocTypes.forEach(rt => {
    if (rt.form?.elements) {
      const questions = [...questionsIterator(rt.form.elements)];
      rtnHasMap[rt.id] = { hasDoc: getHasDoc(questions), hasToc: getHasToc(questions), hasFrom: getHasFrom(questions), rocType: rt, questions: rt.form.elements ? [...questionsIterator(rt.form.elements)] : [] };
    }
  });

  return rtnHasMap;
};

const getAnswerForQName = (roc: DTO.RecordOfContact, qname: string, rtHasMap: RtHasMap) => {
  if (roc.formSubmission == null) return;
  const q = rtHasMap[roc.rocType!.id].questions.find(rq => rq.name === qname);

  return q ? roc.formSubmission.answers.find(a => a.question.id === q.id) : undefined;
};

const alreadyDisplayed = (roc: DTO.RecordOfContact, rtHasMap: RtHasMap, question: DTO.FormQuestion) => {
  const questions = rtHasMap[roc.rocType!.id].questions;
  return qIsDoc(question) ? !getHasDoc(questions) :
    qIsToc(question) ? !getHasToc(questions) :
      qIsFrom(question) ? !getHasFrom(questions) : false;
};

export const RecordOfContactsTableBody: React.FC<RecordOfContactsTableBodyProps> = ({ rocs, colQuestions, selectedRoc, rocTypes }) => {
  const fqCtx = useContext(FQContext);
  const rtHasMap: RtHasMap = buildRtHasMap(rocTypes);

  return (
    <>
      {rocs.map(roc => (
        <TableRow hover tabIndex={-1} key={'roc_' + roc.id} selected={false} onClick={() => { selectedRoc(roc); }} >
          {!rtHasMap[roc.rocType!.id].hasDoc && <TableCell>{roc.date != null ? formatDate(roc.date) : 'unknown'}</TableCell>}
          {!rtHasMap[roc.rocType!.id].hasToc && <TableCell><LanguageString languageString={rtHasMap[roc.rocType!.id].rocType.name} /></TableCell>}
          {!rtHasMap[roc.rocType!.id].hasFrom && <TableCell>{`${roc.from?.firstName} ${roc.from?.lastName}`}</TableCell>}
          {colQuestions.filter(q => !alreadyDisplayed(roc, rtHasMap, q)).map(q => <TableCell key={q.id}>{formatAnswer(q, rocTypes, fqCtx, getAnswerForQName(roc, q.name!, rtHasMap))}</TableCell>)}
        </TableRow>
      ))}
    </>
  );
};

export default RecordOfContactsTableBody;
