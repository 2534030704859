import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site, CustomPropertyType } from '../../dto';

type CustomPropProp = keyof Pick<Site, 'customProperties'>;
type Results = { site: { [P in CustomPropProp]?: CustomPropertyType[] }};
const query = gql`
  query site {
    site {
      id
      customProperties {
        id
        name
        fieldType
      }
    }
  }
`;

export function useCustomProperties(options?: QueryHookOptions<Results>) {
  return useQuery<Results>(query, { fetchPolicy: 'cache-first', ...options });
}
