import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ImportDataMutationArgs } from '../../dto';

    // mutation importData($action: String!, $file: Upload!, $formData: FormInput) {
type Result = { importData: string[] };
type Variables = ImportDataMutationArgs;
export const useImportData = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation importData($input: DataImportArgs!) {
      importData(input: $input)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};

export default useImportData;
