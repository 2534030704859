import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { SiteBranding, UpdatedThemeInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Result = { submitForm: QueryType<SiteBranding> };
type Variables = { input: UpdatedThemeInput };
export const useUpdateTheme = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation updateTheme($input: UpdatedThemeInput!) {
      updateTheme(input: $input) {
        id
        cssUrl
        logoUrl
        cssClasses
        imagesUrl
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
