import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Slide, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { ApolloError } from 'apollo-client';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { MTSState } from 'tuapath-common/generated/schema';
import { SiteContext, StudentContext, UserContext, useInsights } from '../../../contexts';
import * as DTO from '../../../dto';
import { useClasses, useMTSApprove } from '../../../hooks';
import { DialogTitle, GeneralErrorMessage, LanguageString } from '../../Common';
import { DynamicForm } from '../../DynamicForm';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface MTSApproveDialogProps {
  open: boolean;
  toggle: () => void;
  mts?: DTO.MTS;
  reviewingMTS?: boolean;
}

const DialogTransition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MTSApproveDialog: React.FC<MTSApproveDialogProps> = props => {
  const insights = useInsights();
  let dynamicFormRef: DynamicForm | null = null;
  const classes = useClasses();
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const siteCtx = useContext(SiteContext);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [mtsApprove] = useMTSApprove();
  const [formSubmissionDate, setFormSubmissionDate] = useState<Date | undefined>(undefined);

  const handleSubmit = () => {
    if (dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }
  };

  const dynamicFormSubmit = (data?: { submitForm: DTO.SubmitFormResult }, error?: ApolloError) => {
    if (data && data.submitForm && data.submitForm.formSubmission) {
      const submission = data.submitForm.formSubmission;

      if (submission && props.mts && props.mts.state === MTSState.SUBMITTED) {
        let pin: string | undefined = undefined;

        if (submission.answers) {
          for (const answer of submission.answers) {
            // TODO: This or verifyPIN should be removed when the backend is fixed for STUDENT_PIN
            if (answer.question.name === 'verifyPIN') {
              pin = answer.answer;
              break;
            }
          }
        }

        if (pin) {
          mtsApprove({
            variables: {
              mtsId: props.mts.id,
              formSubmissionId: submission.id,
              pin: pin
            },
            update: (cache, result) => {
              if (result.data && result.data.mtsApprove) {
                if (props.open) {
                  props.toggle();
                }
              } else {
                setErrorMessage('An unknown error occured');
              }
            },
            refetchQueries: ['mtsEntries']
          }).catch(res => {
            const errors: string[] = res.graphQLErrors.map((gqlError: ApolloError) => gqlError.message);

            if (errors.length > 0 && errorMessage !== errors[0]) {
              setErrorMessage(errors[0]);
            }

            insights.trackException(`${MTSApproveDialog.name} - Failed to approve MTS with errors: ${JSON.stringify(errors)}`, SeverityLevel.Error);
          });
        } else {
          insights.trackEvent(`${MTSApproveDialog.name} - Failed to approve MTS because the PIN was null`);
        }
      } else {
        insights.trackEvent(`${MTSApproveDialog.name} - Failed to approve MTS because MTS was already approved: ${props.mts?.id}`);
      }
    } else {
      insights.trackEvent(`${MTSApproveDialog.name} - Failed to approve MTS because values were null`);
    }
  };

  return (
    <Dialog scroll="paper" maxWidth="xl" open={props.open} onClose={props.toggle} TransitionComponent={DialogTransition} fullWidth>
      <DialogTitle onClose={props.toggle}>
        <LanguageString groupName="MTS" resourceName={!props.reviewingMTS ? 'Approve_MTS' : 'Review_MTS'} defaultText={!props.reviewingMTS ? 'Approve MTS' : 'Review MTS'} />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className={classes.fullscreenModalContainer} spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DynamicForm
                      formId={siteCtx.site?.mtsStudentForm?.id ?? 0}
                      userId={studentCtx?.student.id ?? 0}
                      autoSave={false}
                      isReadOnly={true}
                      disabled={true}
                      showSubmitButton={false}
                      autoloadFormSubmission={false}
                      formSubmissionId={props.mts && props.mts.participantForm ? props.mts.participantForm.id : undefined}
                      renderFormTitle={false}
                      onFormLoaded={(form, submission) => {
                        if (submission && submission.completedAt && !formSubmissionDate) {
                          setFormSubmissionDate(new Date(submission.completedAt));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formSubmissionDate && <TextField disabled fullWidth value={format(formSubmissionDate, 'MM/dd/yyyy h:mma')} />}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {errorMessage && (
                    <Grid item xs>
                      <GeneralErrorMessage message={errorMessage} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <DynamicForm
                      ref={f => (dynamicFormRef = f)}
                      formId={siteCtx.site?.mtsAdvisorForm?.id ?? 0}
                      userId={userCtx.user?.id ?? 0}
                      disabled={props.reviewingMTS}
                      autoSave={false}
                      showSubmitButton={false}
                      autoloadFormSubmission={false}
                      formSubmissionId={props.mts && props.mts.advisorForm ? props.mts.advisorForm.id : undefined}
                      onIsSubmittingChange={submitting => {
                        setSubmitButtonDisabled(submitting);
                      }}
                      onFormSubmitted={dynamicFormSubmit}
                      renderFormTitle={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField disabled fullWidth value={format(new Date(), 'MM/dd/yyyy')} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!props.reviewingMTS ? (
          <Button disabled={submitButtonDisabled} onClick={handleSubmit} color="primary" variant="contained">
            <LanguageString groupName="MTS" resourceName="Approve_MTS" defaultText="Approve MTS" />
          </Button>
        ) : (
          <Button onClick={props.toggle} color="primary" variant="contained">
            <LanguageString groupName="MTS" resourceName="Close_MTS" defaultText="Close" />
          </Button>
        )}

      </DialogActions>
    </Dialog>
  );
};

export default MTSApproveDialog;
