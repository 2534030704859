import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useClasses, useLoggedInUser, useStringGroup } from '../../hooks';
import { LanguageString } from '../Common';
import { Footer } from '../Footer';
import LoginForm from './LoginForm';

export const Login: React.FC = () => {
  const classes = useClasses();
  const sections = useStringGroup({ variables: { groupName: 'SITE_CONTENT_SECTION' } });
  const image = useStringGroup({ variables: { groupName: 'SITE_CONTENT_WELCOME_IMAGE' } });
  const { loading, data } = useLoggedInUser({ fetchPolicy: 'cache-first', errorPolicy: 'ignore' });

  const ready = !loading && image.data != null && sections.data != null;

  if (data?.me != null) return <Redirect to="/" push={false} />;

  return ready ? (
    <div className={classes.loginContainer}>
      <div
        className={classes.welcomeContainer}
        style={{
          backgroundImage: `url(${image.data?.stringGroup[0].string?.text})`,
          backgroundSize: 'cover'
        }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <Grid item>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} className={classes.welcomeContainerRawHTML}>
                  <LanguageString groupName="SITE_CONTENT_WELCOME" resourceName="WELCOME" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LoginForm />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>

      {sections.data && (
        <div>
          {sections.data.stringGroup.map(s => (
            <LanguageString key={s.resourceName} languageString={s.string} />
          ))}
        </div>
      )}

      <Footer />
    </div>
  ) : null;
};

export default Login;
