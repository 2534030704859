import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

// TODO: Move these fragments to the fragments folder
const assignmentWithUserFrag = gql`
  ${languageStringFrag}
  fragment AssignmentWithUserFrag on Assignment {
    id
    isComplete
    startedAt
    completedAt
    percentComplete
    durationWatched
    user {
      id
      firstName
      lastName
    }
    task {
      id
      name {
        ...LangStringFrag
      }
    }
    milestoneId
    taskId
  }
`;

const assignmentResultsFrag = gql`
  ${assignmentWithUserFrag}
  fragment AssignmentResultsFrag2 on AssignmentResults {
    totalCount
    assignments {
      ...AssignmentWithUserFrag
    }
  }
`;

export type SiteNeedingApprovalResults = { site: Pick<Site, 'id' | 'milestonesAwaitingApproval'> };
export const siteNeedingApprovalQuery = gql`
  ${assignmentResultsFrag}

  query siteNeedingApproval {
    site {
      id
      milestonesAwaitingApproval {
        ...AssignmentResultsFrag2
      }
    }
  }
`;

export function useSiteNeedingApproval(options?: QueryHookOptions<SiteNeedingApprovalResults>) {
  return useQuery<SiteNeedingApprovalResults>(siteNeedingApprovalQuery, options);
}
