import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { SiteContext, StudentContext } from '../../contexts';
import * as DTO from '../../dto';
import { ReqVars } from './common';
import { mtsPeriodFrag } from './fragments';

type Variables = { shouldFilter: boolean; customPropertyValue?: String };
type Results = { site: { mtsPeriods: DTO.MTSPeriod[] } };

const query = gql`
  ${mtsPeriodFrag}

  query periods($shouldFilter: Boolean!, $customPropertyValue: String) {
    site {
      id
      mtsPeriods(shouldFilter: $shouldFilter, customPropertyValue: $customPropertyValue) {
        ...MTSPeriodFrag
      }
    }
  }
`;

const useMTSPeriods = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export const useUserMTSPeriods = () => {
  const studentCtx = useContext(StudentContext);
  const siteCtx = useContext(SiteContext);
  const filterVal = siteCtx.site?.mtsPeriodCustomPropertyTypeId
    ? (studentCtx?.student?.customProperties ?? []).find(cp => cp.type?.id === siteCtx.site?.mtsPeriodCustomPropertyTypeId)?.value
    : undefined;

  return useMTSPeriods({ variables: { shouldFilter: true, customPropertyValue: filterVal } });
};

export default useUserMTSPeriods;
