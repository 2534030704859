import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client';

// TODO: Upgrade Fix Needed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryFunction = (args: QueryHookOptions) => any;
export type QueryResult<T extends QueryFunction> = Pick<Required<ReturnType<T>>, 'data'>['data'];

export type ReqVars<V> = { [P in keyof V as Exclude<P, 'variables'>]: V[P] } & {[P in keyof V as P extends 'variables' ? P : never]-?: V[P]};

export function useMappedQuery<Results, ExternVars, InternVars>(query: DocumentNode, options: ReqVars<Omit<QueryHookOptions<Results, ExternVars>, 'nextFetchPolicy'>>, innerVars: (vars: ExternVars) => InternVars) {
  const { variables, ...opts } = options;
  return useQuery<Results, InternVars>(query, {...opts, variables: innerVars(variables)});
}
