import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import { Callout, IconButton, LanguageString } from '..';
import * as Edit from '../../common/pathEditTypes';
import { setIfChanged, updateLs, updateTasks } from '../MilestoneEditor/MilestoneEditorForm';
import { GroupForm } from './GroupForm';
import { MilestoneList } from './MilestoneList';
import { PathEditorContext } from './PathEditor';
import { SubGroupList } from './SubgroupsList';

const updateGroups = (grps1?: Edit.MilestoneGroup[], grps2?: Edit.MilestoneGroup[], cc?: Edit.CacheContext, pId?: number) => {
  if (grps1 == null || grps2 == null || cc == null || pId == null) return;

  const delGrps = grps1.filter(g1 => grps2.find(g2 => g2.id === g1.id) == null);
  const addGrps = grps2.filter(g2 => grps1.find(g1 => g1.id === g2.id) == null);

  delGrps.forEach(dg =>
    grps1.splice(
      grps1.findIndex(g1 => g1.id === dg.id),
      1
    )
  );

  grps1.forEach(og => {
    const ng = grps2.find(g2 => g2.id === og.id);
    if (ng != null) {
      updateLs(og, ng, 'name');
      updateLs(og, ng, 'description');
      setIfChanged(og, ng, 'color');
      setIfChanged(og, ng, 'sortIndex');
    }
  });

  addGrps.length && grps1.push(...addGrps.map(g => Edit.convertGroup(g, g.sortIndex + grps1.length, cc, pId, 'msGroups')));
  grps1.forEach((g, idx) => g.sortIndex !== idx ? g.sortIndex = idx : undefined);
};

const updateMilestones = (milestones1?: Edit.Milestone[], milestones2?: Edit.Milestone[], group?: Edit.MilestoneGroup, cc?: Edit.CacheContext) => {
  if (milestones1 == null || milestones2 == null || group == null || cc == null) return;

  const delMilestones = milestones1.filter(m1 => milestones2.find(m2 => m2.id === m1.id) == null);
  const addMilestones = milestones2.filter(m2 => milestones1.find(m1 => m1.id === m2.id) == null);

  delMilestones.forEach(dm =>
    milestones1.splice(
      milestones1.findIndex(m1 => m1.id === dm.id),
      1
    )
  );

  delMilestones.length && (group.milestones = milestones1);

  milestones1.forEach(om => {
    const nm = milestones2.find(m2 => m2.id === om.id);
    if (nm != null) {
      setIfChanged(om, nm, 'required');
      updateTasks(om.tasks, nm.tasks);
    }
  });

  addMilestones.length && (group.milestones = [...milestones1, ...addMilestones.map((nms, index) => Edit.convertMilestone(nms, milestones1.length + index, cc, group.id))]);
};

export const GroupDetail: React.FC<{ group: Edit.MilestoneGroup }> = ({ group }) => {
  const { cacheContext: cc, hasUnsavedChanges, refresh } = useContext(PathEditorContext);
  const handleSubmit = (values: Edit.MilestoneGroup, formik: FormikHelpers<Edit.MilestoneGroup>) => {
    updateLs(group, values, 'name');
    updateLs(group, values, 'description');
    setIfChanged(group, values, 'color');
    setIfChanged(group, values, 'sortIndex');
    updateGroups(group.subGroups, values.subGroups, cc, group.id);
    updateMilestones(group.milestones, values.milestones, group, cc);
    refresh();
    formik.resetForm();
  };

  return (
    <Formik initialValues={group} onSubmit={handleSubmit} enableReinitialize>
      {formik => {
        hasUnsavedChanges.current = formik.dirty;
        return (
          <div style={{ display: 'grid', gridColumn: '1fr', gap: '16px', alignContent: 'start' }}>
            <Card>
              <CardHeader
                title={<LanguageString groupName="EDITOR" resourceName="MILESTONE_GROUP" defaultText="Milestone Group" />}
                action={
                  <Callout body={<LanguageString groupName="HELP" resourceName="STAGE_CHANGES" />} name="save-help" step={1} of={3} dependsOn={['edit-grp', 'edit-ms']}>
                    <IconButton icon="save_alt" onClick={() => formik.handleSubmit()} />
                  </Callout>
                }
              />
              <Divider />
              <Callout name="edit-grp" body={<LanguageString groupName="HELP" resourceName="EDIT_GROUP" />} step={1} of={2} placement="right">
                <CardContent>
                  <GroupForm />
                </CardContent>
              </Callout>
            </Card>
            <FieldArray name="subGroups" component={SubGroupList} />
            <FieldArray name="milestones" component={MilestoneList} />
          </div>
        );
      }}
    </Formik>
  );
};
