import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { ChangePasswordInput, ChangePasswordResult } from 'tuapath-common/generated/schema';

type Variables = { input: ChangePasswordInput };
type Result = { changePassword: ChangePasswordResult };
export const useChangePassword = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation changePassword($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        success
        reason
      }
    }
  `;

  return useMutation<Result, Variables>(mutation);
};

