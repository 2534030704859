import { useState } from 'react';

export type DialogController = {
  controller: {
    setIsOpen: (isOpen: boolean) => void;
    setOpen: () => void;
    setClose: () => void;
    toggle: () => void;
  };
  props: {
    open: boolean;
    onClose: () => void;
  };
};

export function useDialogController(isInitiallyOpen: boolean, onClose?: () => void): DialogController {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  return {
    controller: {
      setIsOpen: (isOpen) => setIsOpen(isOpen),
      setOpen: () => setIsOpen(true),
      setClose: () => setIsOpen(false),
      toggle: () => setIsOpen(!isOpen)
    },
    props: {
      open: isOpen,
      onClose: onClose ? onClose : () => setIsOpen(false)
    }
  };
}
