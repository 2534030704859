import { ApolloCache, gql, useApolloClient } from '@apollo/client';
import { Role, User } from '../../dto';

type Variables = { user: User; role: Role };

const mutation = gql`
  fragment RoleFrag on User {
    currentRole @client
  }
`;

// TODO: probably should put this somewhere else
export const updateCurrentRole = (cache: ApolloCache<object>, { user, role }: Variables) => {
  localStorage.setItem('last_role_used', role);
  if (user.id == null) return;
  cache.writeFragment({
    id: cache.identify(user),
    fragment: mutation,
    data: { currentRole: role }
  });
};

export const useUpdateCurrentRole = () => {
  const { cache } = useApolloClient();
  return [(options: Variables) => updateCurrentRole(cache, options)];
};
