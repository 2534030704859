import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { PrintLocation, SchedulePrintFileQueryInput } from 'tuapath-common/generated/schema';

type Result = { user: { schedulePrintFileDownload?: boolean } };
type Variables = { userId: number, location: PrintLocation, queryInput: SchedulePrintFileQueryInput };
export function useSchedulePrintFileDownload(options?: LazyQueryHookOptions<Result, Variables>) {
  const validateFormMutation = gql`
    query schedulePrintFileDownload($userId: Int!, $location: PrintLocation!, $queryInput: SchedulePrintFileQueryInput!) {
      user(id: $userId) {
        id
        schedulePrintFileDownload(location: $location, queryInput: $queryInput)
      }
    }
  `;

  return useLazyQuery<Result, Variables>(validateFormMutation, options);
}
