import React, { useContext } from 'react';
import { useMemo } from 'react';
import { MTSContext, ChildRoutes } from '../../../contexts';
import {
  Grid,
  CircularProgress,
  Typography,
  Paper
} from '@material-ui/core';
import { MTSToolBar } from '../MTSToolBar';
import { format, addDays, differenceInDays } from 'date-fns';
import CalendarCustomViewRow from './CalendarCustomViewRow';
import clsx from 'clsx';
import { useClasses } from '../../../hooks';
import { MTSFooter } from '../MTSFooter';
import { CalendarMonthSubmissionStatus } from '../CalendarMonthView/CalendarMonthSubmissionStatus';
import { startOfWeek } from 'date-fns';
import MTSHelpers from '../../../helpers/mtsHelpers';

export const CalendarCustomView: React.FC = () => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  const startDate = useMemo(() => {
    if (mtsCtx.startDate) {
      return startOfWeek(mtsCtx.startDate);
    }

    return new Date();
  }, [mtsCtx.startDate]);

  const weekdays = useMemo(() => {
    if (mtsCtx.startDate) {
      const days = [];
      const sow = startOfWeek(mtsCtx.startDate);

      for (let i = 0; i <= 6; i++) {
        days.push(format(addDays(sow, i), 'E'));
      }

      return days;
    }

    return [];
  }, [startDate]);

  const rows = useMemo(() => {
    if (mtsCtx.mtsPeriod && mtsCtx.startDate && mtsCtx.endDate) {
      const startDate = new Date(mtsCtx.startDate.getFullYear(), mtsCtx.startDate.getMonth(), mtsCtx.startDate.getDate(), 0, 0, 0);
      const endDate = new Date(mtsCtx.endDate.getFullYear(), mtsCtx.endDate.getMonth(), mtsCtx.endDate.getDate(), 23, 59, 59);

      const periodDates = MTSHelpers.getPeriodDates(mtsCtx.mtsPeriod);
      const diff = differenceInDays(endDate, startDate) + 1;
      const weeks = Math.ceil((diff / 7.0));

      const r: React.ReactNode[] = [];

      for (let i = 0; i < weeks; i++) {
        const sow = addDays(startDate, 7 * i);

        r.push((
          <CalendarCustomViewRow
            key={`mts_custom_row_${i}`}
            periodStartDate={periodDates.startDate}
            periodEndDate={periodDates.endDate}
            weekStartDate={sow}
          />
        ));
      }

      return r;
    }

    return [];
  }, [mtsCtx.mtsPeriod, startDate]);

  return (
    <Paper>
      <MTSToolBar />
      <CalendarMonthSubmissionStatus />

      <Grid className={classes.mtsMonthRow} container direction="row" justify="space-evenly">
        <Grid item xs={12}>
          <Grid className={clsx([classes.mtsDayHeaderHeight, classes.mtsMonthDayHeader])} container direction="row" justify="space-evenly">
            {mtsCtx.loading && (
              <CircularProgress color="primary" />
            )}

            {!mtsCtx.loading && (
              <>
                {weekdays.map(wday => (
                  <Grid item key={`day_of_week${wday}`} xs>
                    <Typography variant="h6">{wday}</Typography>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        {rows}

        <ChildRoutes />
      </Grid>

      <MTSFooter />
    </Paper>
  );
};
export default CalendarCustomView;
