import { Icon } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import React from 'react';
import { Milestone, MilestoneGroup, SuccessPath, Task } from '../../common/pathEditTypes';
import { EditLabel } from './PathTreeItem';

type TaskTreeItemProps = { path: SuccessPath; group: MilestoneGroup; milestone: Milestone; task: Task };
export const TaskTreeItem: React.FC<TaskTreeItemProps> = ({ path, group, milestone, task }) => {
  return (
    <TreeItem
      nodeId={`task-${path.id}-${group.id}-${milestone.id}-${task.id}`}
      label={<EditLabel name={task.name} item={task} />}
      icon={<Icon>assignment_ind</Icon>}
    >
      {/* hack - added so the TreeView will send a nodeToggle event for the task TreeItem */}
      <TreeItem nodeId={`hack-${task.id}`} />
    </TreeItem>
  );
};
