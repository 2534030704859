import {
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs
} from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { SiteContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { LocalStorageKey, useLocalStorage } from '../../../hooks';
import { DialogTitle, LanguageString } from '../../Common';
import { DynamicForm } from '../../DynamicForm';
import { CaseLoadColumnSettingsTab } from './CaseLoadColumnSettingsTab';
import { CaseLoadAdvisorTab } from './CaseLoadAdvisorTab';

interface CaseLoadFilterDialogProps {
  open: boolean;
  toggle: () => void;
  selectedAdvisorIds: number[];
  changedSelectedAdvisorIds: (advisorIds: number[]) => void;
  showClosedUsers: boolean;
  changedShowClosedUsers: (showClosedUsers: boolean) => void;
}

export const CaseLoadFilterDialog: React.FC<CaseLoadFilterDialogProps> = props => {
  const [currentTab, setCurrentTab] = useState(0);
  const [dismissOnSave, setDismissOnSave] = useState(false);
  const siteCtx = useContext(SiteContext);
  const [filters, setFilters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.CaseLoadFilters, {});
  let dynamicFormRef: DynamicForm | null = null;
  const [innerAdvisorIds, setInnerAdvisorIds] = useState<number[]>(props.selectedAdvisorIds);
  useEffect(() => setInnerAdvisorIds(props.selectedAdvisorIds), [props.selectedAdvisorIds]);

  const toggle = () => {
    if (currentTab === 1 && dynamicFormRef) {
      setDismissOnSave(true);
      void dynamicFormRef.submitForm();
    } else {
      props.toggle();
    }
    props.changedSelectedAdvisorIds(innerAdvisorIds);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (currentTab === 1 && dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }

    setCurrentTab(newValue);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={props.open} onClose={toggle} aria-labelledby="Case Load Filters">
      <DialogTitle onClose={toggle}>
        <LanguageString groupName="CASE_LOAD" resourceName="CASE_LOAD_FILTERS" defaultText="Filters" />
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filter Tabs"
          variant="fullWidth"
        >
          <Tab label={<LanguageString groupName="CASE_LOAD" resourceName="ADVISOR_FILTERS" defaultText="Advisors" />} />
          <Tab label={<LanguageString groupName="CASE_LOAD" resourceName="FILTERS" defaultText="Filters" />} />
          <Tab label={<LanguageString groupName="CASE_LOAD" resourceName="COLUMNS" defaultText="Columns" />} />
        </Tabs>

        <Grid container>
          {currentTab === 0 && (
            <CaseLoadAdvisorTab selectedAdvisorIds={innerAdvisorIds} changedSelectedAdvisorIds={setInnerAdvisorIds} />
          )}
          {currentTab === 1 && (
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <DynamicForm
                    ref={f => (dynamicFormRef = f)}
                    formId={siteCtx.site?.caseLoadFilterForm?.id ?? 0}
                    autoloadFormSubmission={false}
                    showSubmitButton={false}
                    autoSave={true}
                    overrideFormSubmissionInput={Object.keys(filters).length > 0 ? (filters as DTO.SubmitFormInput) : undefined}
                    overrideSubmitHandler={(input, form) => {
                      if (!filters || filters !== { filters: input }) {
                        setFilters(input);
                      }
                      if (dismissOnSave) {
                        setDismissOnSave(false);
                        props.toggle();
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {currentTab === 2 && <CaseLoadColumnSettingsTab />}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CaseLoadFilterDialog;
