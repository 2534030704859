import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Divider,
  Paper,
  Checkbox
} from '@material-ui/core';
import { DynamicForm } from '../../DynamicForm';
import { TransitionProps } from '@material-ui/core/transitions';
import { LanguageString, DownloadTemplate } from '../../Common';
import * as DTO from '../../../dto';
import { SiteContext, StudentContext } from '../../../contexts';
import MTSReviewRow from '../MTSReviewHoursDialog/MTSReviewRow';
import MTSHelper from '../../../helpers/mtsHelpers';
import { useClasses } from '../../../hooks';
import { format } from 'date-fns';
import UserHelper from '../../../helpers/userHelper';
import { PrintLocation } from 'tuapath-common/generated/schema';

interface MTSCompletedDialogProps {
  open: boolean;
  toggle: () => void;
  mts?: DTO.MTS;
}

interface MTSCompletedDialogContentRowProps {
  leftTitle?: React.ReactNode;
  leftValue?: React.ReactNode;
  rightTitle?: React.ReactNode;
  rightValue?: React.ReactNode;
}

const DialogTransition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MTSCompletedDialogContentRow: React.FC<MTSCompletedDialogContentRowProps> = props => {
  const classes = useClasses();

  return (
    <Grid container spacing={4} justify="space-between">
      {props.leftTitle && (
        <Grid item sm={12} md={5}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.bold} variant="body1">
                {props.leftTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="right" display="inline">
                {props.leftValue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {props.rightTitle && (
        <Grid item sm={12} md={5} justify="space-between">
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.bold} variant="body1" align="left" display="inline">
                {props.rightTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="right" display="inline">
                {props.rightValue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const MTSCompletedTable: React.FC<{ mts?: DTO.MTS }> = props => {
  const classes = useClasses();
  const dayColumns: Date[] = props.mts ? MTSHelper.dayColumnsForMTSEntries(props.mts) : [];
  const activitesGrouped: Array<Array<DTO.MTSActivity>> = props.mts ? MTSHelper.activitiesGrouped(props.mts) : [];
  const totalHours = props.mts ? MTSHelper.totalHoursForMTS(props.mts) : 0;

  return (
    <Paper>
      <Table className={classes.mtsReviewHoursTable}>
        <TableHead>
          <TableRow>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_ACTIVITY" defaultText="Activity" />
            </TableCell>
            {dayColumns.map(day => {
              return (
                <TableCell key={`day_${day}`}>
                  {format(day, 'do')}
                </TableCell>
              );
            })}
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_TOTAL" defaultText="Total" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activitesGrouped.map((activity, index) => {
            return props.mts && <MTSReviewRow key={`mts_review_row_${index}`} days={dayColumns} activities={activity} mts={props.mts} hasTypesNeedingApproval={false} />;
          })}

          <TableRow>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_TOTAL" defaultText="Total" />
            </TableCell>
            {dayColumns.map(day => {
              return (
                <TableCell key={`blank_${day}`} />
              );
            })}
            <TableCell>
              {totalHours}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export const MTSCompletedDialog: React.FC<MTSCompletedDialogProps> = props => {

  const classes = useClasses();
  const siteCtx = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);
  const mtsDate = new Date(props.mts?.year ?? 2020, (props.mts?.month ?? 2) - 1, 1);

  return (
    <Dialog
      fullScreen
      scroll="paper"
      open={props.open}
      onClose={props.toggle}
      TransitionComponent={DialogTransition}
    >
      <DialogContent dividers>
        <AppBar position="fixed">
          <Toolbar>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  {format(mtsDate, 'LLL do')} - <LanguageString groupName="MTS" resourceName="COMPLETED_MTS" defaultText="Completed MTS" />
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={3}>
                  <Grid item>
                    <DownloadTemplate
                      location={PrintLocation.MTS}
                      relationId={props.mts ? String(props.mts.id) : undefined}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton edge="start" color="inherit" onClick={props.toggle} aria-label="close">
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Grid container className={classes.fullscreenModalContainer}>
          <Grid item xs>
            <Card>
              <CardHeader
                title={
                  <Typography variant="h5">
                    <LanguageString groupName="MTS" resourceName="MTS_COMPLETED_REPORT_TITLE" defaultText="Monthly Time Sheet" />
                  </Typography>
                }
              />
              <CardContent>
                <Grid container direction="column" spacing={5}>
                  <Grid item xs>
                    <MTSCompletedDialogContentRow
                      leftTitle={<LanguageString groupName="MTS" resourceName="NAME" defaultText="Name" />}
                      leftValue={`${studentCtx?.student.firstName ?? ''} ${studentCtx?.student.lastName ?? ''}`}
                      rightTitle={<LanguageString groupName="MTS" resourceName="MTS_DATE" defaultText="MTS Date" />}
                      rightValue={format(new Date(props.mts?.year ?? 2020, (props.mts?.month ?? 1) - 1, 1), 'MM/dd/yyyy')}
                    />

                    <MTSCompletedDialogContentRow
                      leftTitle={<LanguageString groupName="MTS" resourceName="ADDRESS" defaultText="Address" />}
                      leftValue={studentCtx?.student ? UserHelper.customProperty(studentCtx.student, 'street') : ''}
                      rightTitle={<LanguageString groupName="MTS" resourceName="PHONE" defaultText="Phone" />}
                      rightValue={studentCtx?.student ? studentCtx.student.phoneNumber : ''}
                    />

                    <MTSCompletedDialogContentRow
                      leftTitle={<LanguageString groupName="MTS" resourceName="CITY" defaultText="City" />}
                      leftValue={studentCtx?.student ? UserHelper.customProperty(studentCtx.student, 'city') : ''}
                      rightTitle={<LanguageString groupName="MTS" resourceName="EMAIL" defaultText="Email" />}
                      rightValue={(studentCtx?.student?.emails && studentCtx.student.emails.length > 0) ? studentCtx.student.emails[0].email : ''}
                    />

                    <MTSCompletedDialogContentRow
                      leftTitle={<LanguageString groupName="MTS" resourceName="ZIP" defaultText="Zip" />}
                      leftValue={studentCtx?.student ? UserHelper.customProperty(studentCtx.student, 'zip') : ''}
                    />
                  </Grid>

                  <Grid item xs>
                    <MTSCompletedTable mts={props.mts} />
                  </Grid>

                  <Grid item xs>
                    <DynamicForm
                      formId={siteCtx.site?.mtsStudentForm?.id ?? 0}
                      formSubmissionId={props.mts?.participantForm?.id}
                      userId={studentCtx?.student.id ?? 0}
                      autoSave={false}
                      showSubmitButton={false}
                      isReadOnly={true}
                      disabled={true}
                      shouldAutoExpandTextAreas={true}
                      autoloadFormSubmission={false}
                      renderFormTitle={false}
                    />
                  </Grid>

                  <Grid item xs>
                    <Divider />
                  </Grid>

                  <Grid item xs>
                    <Grid container direction="column" spacing={3}>
                      <Grid item xs>
                        <Typography variant="h6">
                          <LanguageString groupName="MTS" resourceName="CAREER_USE_ONLY" defaultText="For Career Coach Use Only" />
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Checkbox
                          checked={true}
                          value="primary"
                        />
                        <Typography display="inline">
                          <LanguageString
                            groupName="MTS"
                            resourceName="CAREER_USE_ONLY_AGREEMENT"
                            defaultText="I have reviewed this form and certify that the information that my client
                            provided complies with Colorado Works requirements"
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <MTSCompletedDialogContentRow
                          leftTitle={<LanguageString groupName="MTS" resourceName="SIGNATURE" defaultText="Signature" />}
                          leftValue={
                            `${studentCtx?.student.advisor?.firstName ?? ''} ${studentCtx?.student.advisor?.lastName ?? ''}`
                          }
                          rightTitle={<LanguageString groupName="MTS" resourceName="DATE" defaultText="Date" />}
                          rightValue={format(props.mts?.approved ? new Date(props.mts.approved) : new Date(), 'MM/dd/yyyy h:mma')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MTSCompletedDialog;
