import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { format } from 'date-fns';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MTSActivityInput, Role, MTSState } from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { MTSContext, SiteContext, StudentContext, UserContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { useClasses, useGetMTSEntryById, useSaveMTSEntry } from '../../../hooks';
import { DialogTitle, LanguageString } from '../../Common';
import { DynamicForm } from '../../DynamicForm';
import MTSActivityDropDown from './MTSActivityDropDown';
import { MTSAttachmentUpload } from './MTSAttachmentUpload';
import MTSNoteTextField from './MTSNoteTextField';

interface MTSAddActivityDialogProps {
  open: boolean;
  readonly: boolean;
  toggle: () => void;
  date: Date;
  activityTypes: DTO.MTSActivityType[];
  refreshQueries?: string[];
  mts?: DTO.MTS;
  mtsEntryId?: number;
}

interface FormSchema {
  activityType?: DTO.MTSActivityType | DTO.UserMTSActivityType;
  closed?: boolean;
  hours: number;
  note?: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const MTSAddActivityDialog: React.FC<MTSAddActivityDialogProps> = props => {
  const { readonly } = props;
  const { data: mtsEntry, loading: loadingMtsEntry } = useGetMTSEntryById({
    variables: {
      entryIds: props.mtsEntryId ? [props.mtsEntryId] : [0]
    }
  });
  const [submitting, setSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [activityType, setActivityType] = useState<DTO.MTSActivityType | DTO.UserMTSActivityType | undefined>(undefined);
  const [noteFieldEnabled, setNoteFieldEnabled] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    activityType: undefined,
    hours: 0,
    note: undefined
  } as FormSchema);
  const [formSubmission, setFormSubmission] = useState<DTO.FormSubmission | undefined>(undefined);
  const [activityPopoverOpen, setActivityPopoverOpen] = useState(false);
  const [saveMTSEntry] = useSaveMTSEntry({ refetchQueries: props.refreshQueries });
  const classes = useClasses();
  const userCtx = useContext(UserContext);
  const studentCtx = useContext(StudentContext);
  const mtsCtx = useContext(MTSContext);
  const siteCtx = useContext(SiteContext);
  const formRef = useRef<FormikProps<FormSchema> | null>(null);
  const uploadRef = useRef<MTSAttachmentUpload | null>(null);
  const activityButtonRef = useRef<HTMLButtonElement | null>(null);
  const dynamicFormRef = useRef<DynamicForm | null>();
  const parentActivityType = useMemo(() => {
    if (mtsEntry?.mtsEntryById && mtsEntry?.mtsEntryById?.length > 0) {
      if (mtsEntry?.mtsEntryById[0]?.userType?.parentActivityType) {
        return mtsEntry.mtsEntryById[0].userType.parentActivityType;
      } else {
        return mtsEntry?.mtsEntryById[0].type;
      }
    } else {
      return undefined;
    }
  }, [mtsEntry]);

  useEffect(() => {
    if (mtsEntry) {
      if (mtsEntry?.mtsEntryById[0]?.note && mtsEntry?.mtsEntryById[0]?.note.length > 0 && !noteFieldEnabled) {
        setNoteFieldEnabled(true);
      }
      setInitialFormValues({
        activityType: mtsEntry?.mtsEntryById[0]?.userType ?? mtsEntry?.mtsEntryById[0]?.type,
        hours: mtsEntry?.mtsEntryById[0]?.hours ?? 0,
        note: mtsEntry?.mtsEntryById[0]?.note ?? undefined
      });
      setActivityType(mtsEntry?.mtsEntryById[0]?.userType ?? mtsEntry?.mtsEntryById[0]?.type);
    }
  }, [props.open, mtsEntry]);

  useEffect(() => {
    if (activityType) {
      const actType = activityType as DTO.MTSActivityType;

      if (actType.noteRequired) {
        setNoteFieldEnabled(true);
      }
    }
  }, [activityType]);

  const formEditDisabled = useMemo(() => {

    if (readonly) {
      return true;
    }

    if (userCtx.user?.roles?.includes(Role.ADMIN) ||
      userCtx.user?.roles?.includes(Role.ADVISOR) ||
      userCtx.user?.roles?.includes(Role.SUPERADMIN)
    ) return false;

    if (props.mts?.state === MTSState.SUBMITTED || props.mts?.state === MTSState.APPROVED) {
      return true;
    }

    if (mtsEntry && mtsEntry?.mtsEntryById[0] !== undefined) {
      if (parentActivityType && parentActivityType.canEditEntryData) {
        return false;
      }

      return true;
    }

    return false;
  }, [userCtx.user?.currentRole, mtsEntry?.mtsEntryById, props.readonly, props.open, props.mts?.state]);

  const formHoursDisabled = useMemo(() => {

    if (readonly) {
      return true;
    }

    if (userCtx.user?.roles?.includes(Role.ADMIN) ||
      userCtx.user?.roles?.includes(Role.ADVISOR) ||
      userCtx.user?.roles?.includes(Role.SUPERADMIN)
    ) return false;

    if (props.mts?.state === MTSState.SUBMITTED || props.mts?.state === MTSState.APPROVED) {
      return true;
    }

    if (mtsEntry && mtsEntry?.mtsEntryById[0] !== undefined) {
      if (parentActivityType && parentActivityType.canEditHours) {
        return false;
      }

      return true;
    }

    return false;
  }, [userCtx.user?.currentRole, mtsEntry?.mtsEntryById, props.readonly, props.open, props.mts?.state]);

  const handleNoteButtonClicked = () => {
    setNoteFieldEnabled(true);
  };

  const toggleModal = () => {
    props.toggle();

    if (props.open) {
      setNoteFieldEnabled(false);
      setFormSubmission(undefined);
    }
  };

  const handleSubmitButtonPressed = () => {

    const submit = async () => {
      if (formRef.current?.isValid && !formEditDisabled) {
        if (dynamicFormRef.current) {
          const validation = await dynamicFormRef.current.validateForm(true);
          if (validation === true) {
            setSubmitting(true);
            void dynamicFormRef.current.submitForm();
          }
        } else if (formRef && formRef.current) {
          setSubmitting(true);
          await formRef.current.submitForm();
        }
      } else if (formRef && formRef.current) {
        setSubmitting(true);
        await formRef.current.submitForm();
      } else {
        setSubmitting(false);
      }
    };
    void submit();
  };

  const dynamicFormSubmitCancelled = async () => {
    // If the user didn't change any values on the form, submission will be cancelled.
    // If the user is updating an existing entry it should submit the regular form and update MTS data
    if (formRef && formRef.current && mtsEntry?.mtsEntryById[0]?.formSubmission) {
      await formRef.current.submitForm();
    } else if (formRef?.current &&
      dynamicFormRef &&
      formRef.current.values.activityType &&
      'expiring' in formRef.current.values.activityType
    ) {
      await formRef.current.submitForm();
    } else {
      dynamicFormRef.current?.validateForm();
      setSubmitting(false);
    }
  };

  const dynamicFormSubmit = async (data?: { submitForm: DTO.SubmitFormResult }, error?: ApolloError) => {
    if (data && data.submitForm && data.submitForm.formSubmission) {
      setFormSubmission(data.submitForm.formSubmission);
      if (formRef && formRef.current) {
        await formRef.current.submitForm();
      }
    }
  };

  const handleSubmit = (values: FormSchema, formikHelpers: FormikHelpers<FormSchema>) => {
    if (!values.activityType) {
      console.log(`cancelling submission because the activity type was null`);
      return;
    }

    const mainActivityType = ('expiring' in values.activityType) ?
      props.activityTypes.find(a => a.id === (values.activityType as DTO.UserMTSActivityType).parentActivityType.id) :
      values.activityType;
    if (!mainActivityType) return;

    let formSubmissionId: number | undefined;
    if (formSubmission && formSubmission.form.id === mainActivityType.formId) formSubmissionId = formSubmission.id;
    else if (mtsEntry?.mtsEntryById[0]?.formSubmission) formSubmissionId = mtsEntry?.mtsEntryById[0]?.formSubmission.id;
    else if ('expiring' in values.activityType) formSubmissionId = (values.activityType as DTO.UserMTSActivityType).formSubmission.id;

    let secondaryFormSubmissionId: number | undefined;
    if (formSubmission && formSubmission.form.id === mainActivityType.secondaryFormId) secondaryFormSubmissionId = formSubmission.id;
    else if (mtsEntry?.mtsEntryById[0]?.secondaryFormSubmission) secondaryFormSubmissionId = mtsEntry?.mtsEntryById[0]?.secondaryFormSubmission.id;
    else if ('expiring' in values.activityType && values.activityType.secondaryFormSubmission) secondaryFormSubmissionId = (values.activityType as DTO.UserMTSActivityType).secondaryFormSubmission?.id;

    const input: MTSActivityInput = {
      id: mtsEntry?.mtsEntryById[0]?.id ?? undefined,
      userId: studentCtx?.student.id ?? 0,
      periodId: mtsCtx.mtsPeriod?.id ?? props.mts?.mtsPeriod?.id,
      date: format(props.date, 'yyyy-MM-dd'),
      hours: values.hours,
      activityTypeId: mainActivityType.id,
      note: values.note,
      formSubmissionId: formSubmissionId,
      secondaryFormSubmissionId: secondaryFormSubmissionId
    };

    if (mainActivityType.childGeneratedTypesEnabled) {
      input.userActivityInput = {
        id: ('expiring' in values.activityType) ? (values.activityType as DTO.UserMTSActivityType).id : mtsEntry?.mtsEntryById[0]?.userType?.id,
        parentActivityTypeId: mainActivityType.id,
        formSubmissionId: formSubmissionId ?? 0,
        secondaryFormSubmissionId: secondaryFormSubmissionId
      };
    }

    saveMTSEntry({
      variables: {
        input: input
      },
      update: (cache, result) => {
        setSubmitting(false);
        if (result.data?.saveMTSEntry) {
          if (uploadRef.current) {
            uploadRef.current.submitForm(result.data.saveMTSEntry);
          }

          toggleModal();
        } else {
          formikHelpers.setStatus({ submitted: false, message: 'An unknown error occured' });
        }
      },
      refetchQueries: ['mtsEntries', 'mtsActivityType', 'mtsUserActivityType']
    }).catch(res => {
      setSubmitting(false);
      const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
        return error.message;
      });

      formikHelpers.setStatus({ submitted: false, message: errors && errors.length > 0 ? errors[0] : undefined });
    });
  };

  const toggleActivityPopover = () => {
    setActivityPopoverOpen(!activityPopoverOpen);
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const FormValidation = Yup.object().shape({
    activityType: Yup.object().required('* Required'),
    hours: Yup.number()
      .required('* Required')
      .min(0.25, 'Your hours must be at least 0.25')
      .max(24, 'Your hours must be between 0.25 and 24 hours')
  });

  const dynamicForm = useMemo(() => {
    if (activityType && props.open) {
      if ('closed' in activityType) {
        const activity = (activityType as DTO.UserMTSActivityType);
        const parentActivity = props.activityTypes.find(a => a.id === activity.parentActivityType.id);

        // Only show the activity type generating form if we're editing an existing entry
        // or creating a new entry on an existing user generated type
        if (parentActivity?.formId && (mtsEntry?.mtsEntryById[0] || !activity.formSubmission)) {
          return (
            <Card>
              <CardContent>
                <DynamicForm
                  ref={r => (dynamicFormRef.current = r)}
                  formId={parentActivity.formId}
                  formSubmissionId={activity.formSubmission?.id}
                  userId={studentCtx?.student.id ?? 0}
                  autoloadFormSubmission={false}
                  onFormSubmitCancelled={dynamicFormSubmitCancelled}
                  onFormSubmitted={dynamicFormSubmit}
                  showSubmitButton={false}
                  renderFormTitle={false}
                  disableCache={true}
                  shouldOnlySubmitIfDirty={true}
                  disabled={formEditDisabled}
                />
              </CardContent>
            </Card>
          );
        }
      } else {
        // Setup form for normal non-generating activity type
        const activity = (activityType as DTO.MTSActivityType);

        if (activity.formId) {
          return (
            <Card>
              <CardContent>
                <DynamicForm
                  ref={r => (dynamicFormRef.current = r)}
                  formId={activity.formId}
                  formSubmissionId={mtsEntry?.mtsEntryById[0]?.formSubmission?.id}
                  userId={studentCtx?.student.id ?? 0}
                  autoloadFormSubmission={false}
                  onFormSubmitCancelled={dynamicFormSubmitCancelled}
                  onFormSubmitted={dynamicFormSubmit}
                  showSubmitButton={false}
                  renderFormTitle={false}
                  disableCache={true}
                  shouldOnlySubmitIfDirty={true}
                  disabled={formEditDisabled}
                />
              </CardContent>
            </Card>
          );
        }
      }
    }

    return null;
  }, [
    activityType,
    mtsEntry?.mtsEntryById,
    props.date,
    props.open,
    props.activityTypes
  ]);
  const secondaryDynamicForm = useMemo(() => {
    if (activityType && props.open && 'closed' in activityType) {
      const activity = (activityType as DTO.UserMTSActivityType);
      const parentActivity = props.activityTypes.find(a => a.id === activity.parentActivityType.id);

      if (!mtsEntry?.mtsEntryById[0] && parentActivity?.secondaryFormId) {
        return (
          <Card>
            <CardContent>
              <DynamicForm
                ref={r => (dynamicFormRef.current = r)}
                formId={parentActivity.secondaryFormId}
                formSubmissionId={activity.secondaryFormSubmission?.id}
                userId={studentCtx?.student.id ?? 0}
                autoloadFormSubmission={false}
                onFormSubmitCancelled={dynamicFormSubmitCancelled}
                onFormSubmitted={dynamicFormSubmit}
                showSubmitButton={false}
                renderFormTitle={false}
                disableCache={true}
                shouldOnlySubmitIfDirty={true}
                disabled={formEditDisabled}
              />
            </CardContent>
          </Card>
        );
      }
    }

    return null;
  }, [
    activityType,
    mtsEntry?.mtsEntryById,
    props.date,
    props.open,
    props.activityTypes
  ]);

  const hoursCheck = (formikProps: FormikProps<FormSchema>) => {
    const inputVal = formikProps.values.hours;
    const mtsInc = siteCtx.site?.mtsHoursIncrement ? siteCtx.site?.mtsHoursIncrement : 0.25;

    if ((inputVal % mtsInc) !== 0) {
      const fallBack = mtsInc * ~~(inputVal / mtsInc);
      formikProps.setFieldValue('hours', fallBack);
    }

    return;
  };

  return (
    <Dialog className={classes.mtsAddActivityDialog} maxWidth="sm" fullWidth open={props.open} onClose={toggleModal} aria-labelledby="Signup Form">
      <DialogTitle onClose={toggleModal}>
        {mtsEntry?.mtsEntryById
          ? <LanguageString groupName="MTS" resourceName="EDIT_ACTIVITY_TITLE" defaultText="Edit Activity" />
          : <LanguageString groupName="MTS" resourceName="ADD_ACTIVITY_TITLE" defaultText="Add Activity" />
        }
      </DialogTitle>
      <DialogContent dividers>
        {loadingMtsEntry ? <CircularProgress /> : (
          <>
          <Formik<FormSchema>
            initialValues={initialFormValues}
            initialStatus={initialStatus}
            onSubmit={handleSubmit}
            validationSchema={FormValidation}
            validateOnMount
            enableReinitialize
            validateOnChange
          >
            {formikProps => {
              formRef.current = formikProps;

              useEffect(() => {
                const e = Boolean(Object.keys(formikProps.errors ?? {}).length > 0);
                setHasErrors(e);
              }, [formikProps.errors]);

              useEffect(() => {
                setTimeout(() => {
                  // This is here because for some reason the validateOnMount prop is not working
                  void formikProps.validateForm();
                }, 200);
              }, []);

              return (
                <Form>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Field validateOnBlur validateOnChange name="activityTypeId">
                        {() => {
                          const expiring = formikProps.values.activityType ?
                            ('expiring' in formikProps.values.activityType) ? (formikProps.values.activityType as DTO.UserMTSActivityType).expiring : false
                            : false;

                          return (
                            <>
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={toggleActivityPopover}
                                ref={activityButtonRef}
                                disabled={formEditDisabled}
                              >
                                <Grid container justify="space-between" alignItems="center">
                                  <Grid item>
                                    {formikProps.values.activityType ? (
                                      <Typography style={{ fontStyle: expiring ? 'italic' : 'normal' }}>
                                        <LanguageString languageString={formikProps.values.activityType?.name} />
                                      </Typography>
                                    ) : (
                                      <LanguageString groupName="MTS" resourceName="ACTIVITY_TYPE" defaultText="Activity Type" />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Icon style={{ height: '100%', display: 'block' }}>expand_more</Icon>
                                  </Grid>
                                </Grid>
                              </Button>
                              <MTSActivityDropDown
                                activityTypes={props.activityTypes}
                                anchor={activityButtonRef}
                                open={activityPopoverOpen}
                                toggle={toggleActivityPopover}
                                selectedActivityType={(type) => {
                                  formikProps.setFieldValue('activityType', type);
                                  setActivityType(type);

                                  const activity = ('expiring' in type) ? props.activityTypes.find(a => a.id === (type as DTO.UserMTSActivityType).parentActivityType.id) : type;

                                  if (activity && (activity.defaultHours && formikProps.values.hours !== activity.defaultHours)) {
                                    formikProps.setFieldValue('hours', activity.defaultHours);
                                  }

                                  setTimeout(async () => {
                                    await formikProps.validateForm();
                                    dynamicFormRef.current?.validateForm();
                                  }, 300);
                                }}
                              />
                            </>
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item>
                      <Field validateOnBlur validateOnChange name="hours">
                        {() => {
                          const type = formikProps.values.activityType;
                          const activity = type ?
                            (('expiring' in type) ?
                              props.activityTypes.find(a => a.id === (type as DTO.UserMTSActivityType).parentActivityType.id) :
                              type) :
                            null;
                          const disabled = formHoursDisabled ? formHoursDisabled : (activity?.defaultHours != null && activity.defaultHours > 0);

                          return (
                            <TextField
                              fullWidth
                              disabled={disabled}
                              name="hours"
                              label={<LanguageString groupName="MTS" resourceName="HOURS" defaultText="Hours" />}
                              type="number"
                              onChange={formikProps.handleChange}
                              onBlur={() => {
                                formikProps.handleBlur;
                                hoursCheck(formikProps);
                              }}
                              value={formikProps.values.hours ?? ''}
                              error={Boolean(formikProps.errors.hours && (formikProps.touched.hours || formikProps.submitCount > 0))}
                              helperText={formikProps.errors.hours && (formikProps.touched.hours || formikProps.submitCount > 0) ?
                                String(formikProps.errors.hours) : ''}
                              InputProps={{
                                endAdornment: !disabled ? (
                                  <InputAdornment position="end">
                                    <ButtonGroup>
                                      <IconButton
                                        onClick={() => {
                                          formikProps.setFieldTouched('hours', true);
                                          formikProps.setFieldValue('hours', formikProps.values.hours - (siteCtx.site?.mtsHoursIncrement ? siteCtx.site?.mtsHoursIncrement : 0.25));
                                          void formikProps.validateForm();
                                        }}
                                      >
                                        <Icon>remove_circle</Icon>
                                      </IconButton>
                                      <IconButton
                                        onClick={() => {
                                          formikProps.setFieldTouched('hours', true);
                                          formikProps.setFieldValue('hours', formikProps.values.hours + (siteCtx.site?.mtsHoursIncrement ? siteCtx.site?.mtsHoursIncrement : 0.25));
                                          void formikProps.validateForm();
                                        }}
                                      >
                                        <Icon>add_circle</Icon>
                                      </IconButton>
                                    </ButtonGroup>
                                  </InputAdornment>
                                ) : null
                              }}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    {siteCtx.site?.hideNotesButton !== true && (
                      <Grid item>
                        <MTSNoteTextField
                          readonly={formEditDisabled}
                          enabled={noteFieldEnabled}
                          activityFieldName="activityType"
                          activityTypes={props.activityTypes}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {(formikProps.status as FormStatus).message && (
                    <Grid container>
                      <Grid item xs>
                        <Alert severity="error">{formikProps.status.message}</Alert>
                      </Grid>
                    </Grid>
                  )}
                </Form>
              );
            }}
          </Formik>

          {dynamicForm}
          {secondaryDynamicForm}

          {activityType && !formEditDisabled && (
            <FormControl fullWidth>
              <MTSAttachmentUpload
                ref={uploadRef}
                date={props.date}
                selectedActivityType={activityType}
                activityTypes={props.activityTypes}
                mtsActivity={mtsEntry?.mtsEntryById[0]}
              />
            </FormControl>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!formEditDisabled && siteCtx.site?.hideNotesButton !== true && (
          <Tooltip title="Add Note">
            <IconButton disabled={noteFieldEnabled} onClick={handleNoteButtonClicked} style={{ marginRight: 'auto' }}>
              <Icon>note_add</Icon>
            </IconButton>
          </Tooltip>
        )}
        <Button onClick={toggleModal} color="primary" variant="text">
          <LanguageString groupName="MTS" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        {(!formEditDisabled || !formHoursDisabled) && (
          <Button onClick={handleSubmitButtonPressed} disabled={submitting || hasErrors} color="primary" variant="contained">
            {submitting ? <CircularProgress size={20} /> : <LanguageString groupName="MTS" resourceName="SAVE" defaultText="Save" />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MTSAddActivityDialog;
