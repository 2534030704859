import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import * as DTO from '../../dto';

// type Variables = DTO.AdvisorsQueryArgs;
type Results = { advisors: DTO.AdvisorResponse };
export const useAdvisorsAll = (options: QueryHookOptions<Results>) => {
  const query = gql`
    query getAdvisors($params: [ResponseParamInput], $order: StudentsOrder) {
      advisors(params: $params, order: $order) {
        advisors {
          id
        }
      }
    }
  `;

  return useQuery<Results>(query, options);
};

export default useAdvisorsAll;
