import React, { useState, useMemo, useCallback } from 'react';
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import { useAdvisors, useAdvisorsAll } from '../../../hooks';
import { LanguageString, GeneralErrorMessage } from '../../Common';
import * as DTO from '../../../dto';

interface NotificationBlastAdvisorTabProps {
  selectedAdvisorIds: number[];
  changedSelectedAdvisorIds: (advisorIds: number[]) => void;
}

const NotificationBlastAdvisorTabRow: React.FC<NotificationBlastAdvisorTabProps & { advisor: DTO.UserInfo }> = React.memo(({ advisor, ...props }) => {

  const isUserChecked = useMemo(() => {
    for (const u of props.selectedAdvisorIds) {
      if (u === advisor.id) {
        return true;
      }
    }

    return false;
  }, [advisor, props.selectedAdvisorIds]);

  const checkValueChanged = useCallback(() => {
    const selectedUserIds: number[] = [];
    let found = false;

    for (const u of props.selectedAdvisorIds) {
      if (u === advisor.id) {
        found = true;
      } else {
        selectedUserIds.push(u);
      }
    }

    if (!found) {
      selectedUserIds.push(advisor.id);
    }

    props.changedSelectedAdvisorIds([...selectedUserIds]);
  }, [advisor, props.selectedAdvisorIds]);

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={isUserChecked}
          value="primary"
          onChange={event => {
            checkValueChanged();
          }}
        />
      </TableCell>
      <TableCell>
        {advisor.firstName} {advisor.lastName}
      </TableCell>
    </TableRow>
  );
});

const NotificationBlastAdvisorTabBody: React.FC<NotificationBlastAdvisorTabProps & { advisors: DTO.UserInfo[] }> = React.memo(({ advisors, ...props }) => (
  <TableBody>
    {advisors.map(advisor => <NotificationBlastAdvisorTabRow key={`advisor_${advisor.id}`} advisor={advisor} {...props} />)}
  </TableBody>
));

export const NotificationBlastAdvisorTab: React.FC<NotificationBlastAdvisorTabProps> = (props) => {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { data: allAdvisors } = useAdvisorsAll({});

  const { loading, data, error } = useAdvisors({
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage
    }
  });

  const checkAll = () => {
    const allIds: number[] = [];
      console.log('what is isn allAdvisors: ', allAdvisors?.advisors.advisors);

    if (allAdvisors?.advisors.advisors) {
      if (!isAllChecked) {
        allAdvisors.advisors.advisors.map(a => { allIds.push(a.id); });
        props.changedSelectedAdvisorIds(allIds);
      } else {
        props.changedSelectedAdvisorIds(allIds);
      }

      setIsAllChecked(!isAllChecked);
    } else {
      console.error('Could not find advisors');
    };
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Grid item xs>
      {error && <GeneralErrorMessage message={error.message} />}

      {loading && <CircularProgress color="primary" />}

      {!loading && !error && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={isAllChecked}
                    value="primary"
                    onChange={event => { checkAll(); }}
                  />
                </TableCell>
                <TableCell>
                  <LanguageString groupName="CASE_LOAD" resourceName="ADVISOR_FILTER_NAME" defaultText="Name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <NotificationBlastAdvisorTabBody advisors={data?.advisors.advisors ?? []} {...props} />
          </Table>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data?.advisors.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Grid>
  );
};

export default NotificationBlastAdvisorTab;
