import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form, Formik, useFormikContext } from 'formik';
import React, { Fragment, useContext } from 'react';
import * as Yup from 'yup';
import { LanguageString } from '..';
import { SiteContext } from '../../contexts';
import { Roadmap, SubmitRoadmapInput } from '../../dto';
import { DialogController, useSubmitRoadmap } from '../../hooks';
import { Checkbox, DateField, DialogTitle, TextField } from '../Common';

type FormType = SubmitRoadmapInput & { isNextMeetingSet?: boolean };

const finalizeRoadmapSchema = (userPinEnabled: boolean) =>
  Yup.object().shape({
    isNextMeetingSet: Yup.boolean().required('Please confirm next meeting has been set'),
    advisorPin: Yup.string().required('PIN is required'),
    studentPin: userPinEnabled ? Yup.string().required('PIN is required') : Yup.string()
  });

const FinalizeRoadmapForm: React.FC<{ userPinEnabled: boolean, endDateEnabled: boolean }> = ({ userPinEnabled, endDateEnabled }) => (
  <Grid container spacing={2}>
    <Checkbox item xs={12} name="isNextMeetingSet" label={<LanguageString groupName="ROADMAP" resourceName="ROADMAP_SET_MEETING" />} />
    <DateField item xs={12} name="nextRoadmap.plannedStartDate" label={<LanguageString groupName="ROADMAP" resourceName="ROADMAP_START_DATE" />} />
    {endDateEnabled && <DateField item xs={12} name="nextRoadmap.plannedEndDate" label={<LanguageString groupName="ROADMAP" resourceName="ROADMAP_END_DATE" />} />}
    <TextField item xs={12} name="reviewNotes" label={<LanguageString groupName="ROADMAP" resourceName="ROADMAP_NOTES" />} rows={5} />
    <TextField item xs={12} name="advisorReviewNotes" label={<LanguageString groupName="ROADMAP" resourceName="ROADMAP_COACH_NOTES" />} rows={5} />
    <TextField item xs={12} md={6} name="advisorPin" label={<LanguageString groupName="ROADMAP" resourceName="COACH_PIN" />} type="password" />
    {userPinEnabled && (
      <TextField item xs={12} md={6} name="studentPin" label={<LanguageString groupName="ROADMAP" resourceName="STUDENT_PIN" />} type="password" />
    )}
  </Grid>
);

const SubmitBtn: React.FC = () => {
  const formik = useFormikContext();

  return <Button onClick={formik.submitForm}>Submit</Button>;
};

type FinalizeRoadmapProps = { roadmap: Roadmap; dlgCtrl: DialogController };

export const FinalizeRoadmap: React.FC<FinalizeRoadmapProps> = ({ roadmap, dlgCtrl: { props: dlgProps, controller: dlgCtrl } }) => {
  const [submitRoadmap, qry] = useSubmitRoadmap();
  const siteCtx = useContext(SiteContext);
  const userPinEnabled = siteCtx.site?.enableUserPIN === true;

  const handleSubmit = async (values: FormType) => {
    const { isNextMeetingSet, ...submitData } = values;
    await submitRoadmap({ variables: { input: submitData }, refetchQueries: ['getRoadmap', 'getCompletedRoadmapMilestones'] });
  };

  const initialValues: FormType = {
    roadmapId: roadmap.id,
    isNextMeetingSet: undefined,
    nextRoadmap: {
      plannedStartDate: new Date().toLocaleDateString()
    },
    reviewNotes: '',
    advisorReviewNotes: '',
    advisorPin: '',
    studentPin: ''
  };

  const initialStatus = {
    errors: [] as string[]
  };

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      validationSchema={finalizeRoadmapSchema(userPinEnabled)}
    >
      <Dialog {...dlgProps} disableBackdropClick maxWidth="lg" fullWidth={true}>
        <DialogTitle onClose={dlgCtrl.setClose}>
          <LanguageString groupName="ROADMAP" resourceName="FINALIZE_ROADMAP" />
        </DialogTitle>
        <DialogContent dividers>
          {qry.called && qry.loading ? (
            <CircularProgress color="primary" />
          ) : qry.called && !qry.error ? (
            <LanguageString groupName="Roadmap" resourceName="SUBMIT_SUCCESS" defaultText="Submitted successfully" />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Form>
                  <FinalizeRoadmapForm userPinEnabled={userPinEnabled} endDateEnabled={siteCtx.site?.id === 182} />
                </Form>
              </Grid>
              <Grid item xs={12}>
                {qry.error && <Alert severity="error">{qry.error.graphQLErrors.map(ge => ge.message).join(',')}</Alert>}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {qry.called && !qry.error ? (
            <Button onClick={dlgCtrl.setClose}>Close</Button>
          ) : (
            <Fragment>
              <Button onClick={dlgCtrl.setClose}>Cancel</Button>
              <SubmitBtn />
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Formik>
  );
};
