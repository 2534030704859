import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import React, { useContext } from 'react';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd';
import { LanguageString, moveSortable } from '..';
import * as Edit from '../../common/pathEditTypes';
import { GroupTreeItem } from './GroupTreeItem';
import { PathEditorContext } from './PathEditor';

type PathTreeItemProps = {
  path: Edit.SuccessPath;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    red: {
      color: 'red'
    },
    blue: {
      color: 'blue'
    },
    bold: {
      fontWeight: 'bold'
    }
  })
);

export const EditLabel: React.FC<{name?: Edit.LanguageString, item: Edit.Editable<object>}> = ({name, item}) => {
  const classes = useStyles();
  const className = `${item.hasChanges ? classes.red : classes.blue} ${item.isChanged ? classes.bold : ''}`;

  return <LanguageString languageString={name} className={className} />;
};

export const PathTreeItem: React.FC<PathTreeItemProps> = ({ path }) => {
  const { refresh } = useContext(PathEditorContext);

  const handleDrag: DragDropContextProps['onDragEnd'] = result => {
    const msGroups = path.msGroups;
    if (msGroups == null) return;

    if (result.reason === 'DROP' && result.destination != null) {
      moveSortable(msGroups, result.source.index, result.destination.index);
      refresh();
    }
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={`root-${String(path.id)}`}>
        {provided => (
          <TreeItem ref={provided.innerRef} key={path.id} nodeId={`path-${path.id}`} label={<EditLabel name={path.name} item={path} />}>
            {path.msGroups?.map((msGrp, index) => (
              <Draggable key={msGrp.id} draggableId={String(msGrp.id)} index={index}>
                {dragProvided => (
                  <Grid item {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
                    <GroupTreeItem path={path} group={msGrp} dragHandleProps={dragProvided.dragHandleProps} />
                  </Grid>
                )}
              </Draggable>
            ))}
          </TreeItem>
        )}
      </Droppable>
    </DragDropContext>
  );
};
