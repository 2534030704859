import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { notificationTemplateFrag } from './fragments';

type Results = { site: Site };
export const useNotificationTemplates = (options?: QueryHookOptions<Results>) => {
  const query = gql`
    ${notificationTemplateFrag}

    query notificationTemplates {
      site {
        id
        notificationTemplates {
          ...NotificationTemplateFrag
        }
      }
    }
  `;

  return useQuery<Results>(query, options);
};

export default useNotificationTemplates;
