import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Divider,
  makeStyles,
  Paper,
  Collapse,
  Typography,
  Icon,
  Button,
  Box,
  TextField,
  Snackbar,
  IconButton,
  Slide,
  CircularProgress,
  Chip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BlastNotificationsContext, UserContext } from '../../contexts';
import { UsersTable } from './UsersTable';
import { LanguageString, Select } from '../Common';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import SendDialog from './SendDialog';
import { useSendNotification, useClasses } from '../../hooks';
import { CancelRounded } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import { NotificationBlastAttachment } from './NotificationBlastAttachment';
import { NotificationForm, sendViaOptions } from './NotificationTypes';
import * as DTO from '../../dto';
import { HtmlTextField } from '../Common/HtmlTextField/HtmlTextField';
import { addHours } from 'date-fns';
import { SiteStatusContext } from '../../contexts';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20
  }
}));

function TransitionUp(props: TransitionProps & { children?: React.ReactElement }) {
  return <Slide {...props} direction="up" />;
}

const formValidation = yup.object().shape({
  selectedStudents: yup.array().when('allStudents', {is: true, then: '', otherwise: yup.array().min(1, 'Please select someone to send your message to')}),
  sendVia: yup.string().required('Please select how you want this notification to be sent'),
  subject: yup.string().when('sendVia', {is: '2', then: '', otherwise: yup.string().required('Please set the subject')}),
  body: yup.string().when('sendVia', {is: '2', then: '', otherwise: yup.string().required('Please set your message')}),
  sendNow: yup.bool().required('Send now required'),
  plainBody: yup.string().when('sendVia', { is: '1', then: '', otherwise: yup.string().required('Please set your message') })
});

const now = new Date();
const exp = addHours(now, 24);

const formInitialValues: NotificationForm = {
  selectedStudents: [],
  allStudents: false,
  sendVia: 0,
  replyTo: '',
  subject: '',
  body: '',
  plainBody: '',
  sendNow: true,
  expiration: exp,
  sendDate: now
};

function stripHtmlTags(input: string): string {
  const regex = /<[^>]+>/g;
  return input.replace(regex, '');
}

export const NotificationBlastInner: React.FC<{ defaultFilters: DTO.SubmitFormInput | undefined }> = ({ defaultFilters }) => {
  const styles = useStyles();
  const userCtx = useContext(UserContext);
  const studentsCtx = useContext(BlastNotificationsContext);
  const siteStatCtx = useContext(SiteStatusContext);
  const classes = useClasses();
  const [studentsOpen, setStudentsOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const toggleSendOpen = () => setSendOpen(!sendOpen);
  const [sendNotification, { loading, error }] = useSendNotification();
  const [snackbarMsg, setSnackbarMsg] = useState<{ type: 'error' | 'success', message: string } | undefined>();
  const closeSnackbar = () => setSnackbarMsg(undefined);
  const [filterAdvisorIds, setFilterAdvisorIds] = useState<number[]>([userCtx.user?.id ?? 0]);
  const [showClosedUsers, setShowClosedUsers] = useState(false);
  const [customCodeBox, setCustomCodeBox] = useState(false);
  const [emailAdr, setEmailAdr] = useState(false);

  useEffect(() => {
    studentsCtx?.setAdvisorIds([...filterAdvisorIds]);
  }, [filterAdvisorIds]);

  useEffect(() => {
    if (error) {
      setSnackbarMsg({ type: 'error', message: 'Failed to send notification(s)' });
    }
  }, [error]);

  const submit = () => {
    toggleSendOpen();
  };

  const removeReceiver = (formik: FormikProps<NotificationForm>, stuId: number) => {
    const valIndex = formik.values.selectedStudents.findIndex(s => s.id === stuId);
    const newArr = formik.values.selectedStudents;

    if (valIndex >= 0 && newArr) {
      newArr.splice(valIndex, 1);
      formik.setFieldValue('selectedStudents', newArr);
      formik.setFieldValue('allStudents', false);
    } else {
      setSnackbarMsg({ type: 'error', message: 'Failed to find student in array' });
    }
  };

  const unsetAllStudents = (formik: FormikProps<NotificationForm>) => {
    formik.setFieldValue('allStudents', false);
    formik.setFieldValue('selectedStudents', []);
  };

  const send = (values: NotificationForm, resetForm: () => void) => {
    setSnackbarMsg({ type: 'success', message: 'Started scheduling your notifications' });

    const now = new Date();
    const formattedDate = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
    const formattedTime = `${now.getHours()}:${now.getMinutes()}`;

    const strippedContent = stripHtmlTags(values.body).replace(/\s/g, '');
    let shouldSend = true;
    let message = 'Failed to schedule notification';

    if ((values.sendVia === 0 || values.sendVia === 1) && strippedContent.length <= 0) {
      shouldSend = false;
    }

    if (values.expiration) {
      if (values.expiration < addHours(values.sendDate ?? new Date(), 24)) {
        shouldSend = false;
        message = 'Expiration date must be at least 24 hours after send date';
      }
    }

    if (shouldSend) {
      resetForm();
      setStudentsOpen(false);
      toggleSendOpen();

      void sendNotification({
        variables: {
          input: {
            toUserIds: values.selectedStudents.map(u => u.id),
            shouldSearch: values.allStudents,
            replyTo: emailAdr ? values.replyTo : '',
            subject: values.subject,
            body: values.body,
            plainTextBody: values.plainBody,
            sendDate: values.sendDate ?? new Date(),
            shouldSMS: sendViaOptions[values.sendVia] === 'Email and SMS' || sendViaOptions[values.sendVia] === 'SMS',
            shouldEmail: sendViaOptions[values.sendVia] === 'Email and SMS' || sendViaOptions[values.sendVia] === 'Email',
            showInUI: true,
            attachmentIds: values.attachments ? values.attachments.map(a => a.id) : undefined,
            blastEmail: true,
            blastSMS: true,
            expiration: values.expiration ? values.expiration : undefined,
            search: {
              advisorIds: studentsCtx?.advisorIds && studentsCtx.advisorIds.length > 0 ? studentsCtx.advisorIds : undefined,
              search: studentsCtx?.search && studentsCtx.search.length > 0 ? studentsCtx.search : undefined,
              filter: studentsCtx?.filters,
              excludedIds: values.selectedStudents.map(u => u.id)
            }
          }
        }
      }).then(value => {
        if (value.data?.sendNotification) {
          // setSnackbarMsg({ type: 'success', message: `Your notification(s) to ${value.data.sendNotification.scheduledNotificationsUserIds.length} users has been schedule` });
          siteStatCtx.setStatus({ title: 'Notification Blast', message: `Notification sent to ${value.data.sendNotification.scheduledNotificationsUserIds.length} users on ${formattedDate} at ${formattedTime}` });
        }
      }).catch(e => {
        setSnackbarMsg({ type: 'error', message: 'Failed to schedule notification' });
      });
    } else {
      setSnackbarMsg({ type: 'error', message });
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={submit}
      validationSchema={formValidation}
      enableReinitialize={false}
    >
      {formikVar => {
        return (
          <Form>
            <Paper className={styles.container}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    onClick={() => {
                      setStudentsOpen(!studentsOpen);
                      if (studentsOpen) {
                        formikVar.setFieldTouched('selectedStudents');
                      }
                    }}
                  >
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <Box display="flex" alignContent="center">
                          <Typography variant="button">
                            <LanguageString groupName="GENERAL" resourceName="SEND_TO" defaultText="Send To: " />
                          </Typography>
                          <Typography variant="body2" style={{ marginLeft: 5 }}>
                            {/* {formikVar.values.selectedStudents.map(s => s.username).join(', ')} */}
                            <Grid container spacing={1}>
                              {!formikVar.values.allStudents && (formikVar.values.selectedStudents.length < 100) ?
                                formikVar.values.selectedStudents.map(s => (
                                  <Grid item>
                                    <Chip
                                      label={`${s.firstName} ${s.lastName.charAt(0)}`}
                                      onDelete={() => removeReceiver(formikVar, s.id)}
                                    />
                                  </Grid>
                                ))
                                :<Grid item>
                                  <Chip
                                    label={`Sending to ${formikVar.values.allStudents && studentsCtx?.totalCount ? studentsCtx.totalCount - formikVar.values.selectedStudents.length : formikVar.values.selectedStudents.length} participants`}
                                    onDelete={() => unsetAllStudents(formikVar)}
                                  />
                                </Grid>
                              }
                            </Grid>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        {studentsOpen ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={studentsOpen} timeout="auto" unmountOnExit>
                    <UsersTable
                      defaultFilters={defaultFilters}
                      filterAdvisorIds={filterAdvisorIds}
                      setFilterAdvisorIds={setFilterAdvisorIds}
                      showClosedUsers={showClosedUsers}
                      setShowClosedUsers={setShowClosedUsers}
                      setAllStudents={formikVar.setFieldValue}
                      allStudents={formikVar.values.allStudents}
                    />
                  </Collapse>
                  {Boolean(formikVar.errors.selectedStudents && formikVar.touched.selectedStudents) && (
                    <Box mt={2}>
                      <Typography color="error">{formikVar.errors.selectedStudents}</Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="REPLY_TO1" defaultText="Reply to:" />
                      {!emailAdr && (
                        <LanguageString groupName="GENERAL" resourceName="REPLY_TO2" defaultText=" Coach" />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='outlined' onClick={() => { setEmailAdr(!emailAdr); }}>{emailAdr ? 'Custom' : 'Coach'}</Button>
                  </Grid>
                  {emailAdr && (
                    <Grid item xs={12} style={{paddingTop: '7px'}}>
                      <TextField
                        fullWidth
                        name="replyTo"
                        label={<LanguageString groupName="GENERAL" resourceName="REPLY_TO3" defaultText="Email" />}
                        value={formikVar.values.replyTo}
                        onChange={formikVar.handleChange}
                        onBlur={formikVar.handleBlur}
                        error={Boolean(formikVar.errors.replyTo && formikVar.touched.replyTo)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    item
                    xs={12}
                    name="sendVia"
                    label="Send Via"
                    items={sendViaOptions.map((option, i) => ({
                      id: i,
                      text: option,
                      value: option
                    }))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="subject"
                    label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_SUBJECT" defaultText="Subject" />}
                    value={formikVar.values.subject}
                    onChange={formikVar.handleChange}
                    onBlur={formikVar.handleBlur}
                    error={Boolean(formikVar.errors.subject && formikVar.touched.subject)}
                    helperText={formikVar.errors.subject && formikVar.touched.subject && String(formikVar.errors.subject)}
                  />
                </Grid>
                {(formikVar.values.sendVia === 0 || formikVar.values.sendVia === 1) && (
                  <Grid item xs={12}>
                    <Paper>
                      <Box p={1}>
                      <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2">
                              <LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_BODY" defaultText="HTML Text Body" />
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant='outlined' onClick={() => { setCustomCodeBox(!customCodeBox); }}>{customCodeBox ? 'HTML Editor' : 'Text Editor'}</Button>
                          </Grid>
                          </Grid>
                          {!customCodeBox && (
                            <Box mt={1}>
                              <HtmlTextField item xs={12} name="body" />
                            </Box>
                          )}
                          {customCodeBox && (
                            <Box mt={1}>
                              <TextField
                                fullWidth
                                name='body'
                                label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_HTML_BODY" defaultText="Custom HTML" />}
                                multiline={true}
                                rows={5}
                                value={formikVar.values.body}
                                onChange={formikVar.handleChange}
                                onBlur={formikVar.handleBlur}
                                error={Boolean(formikVar.errors.body && formikVar.touched.body)}
                              />
                            </Box>
                          )}
                      </Box>
                    </Paper>
                  </Grid>
                )}
                {(formikVar.values.sendVia === 0 || formikVar.values.sendVia === 2) && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="plainBody"
                      label={<LanguageString groupName="GENERAL" resourceName="EMAIL_DIALOG_SMS_BODY" defaultText="Plain Text Body (SMS / Non-HTML Email)" />}
                      multiline={true}
                      rows={5}
                      value={formikVar.values.plainBody}
                      onChange={formikVar.handleChange}
                      onBlur={formikVar.handleBlur}
                      error={Boolean(formikVar.errors.plainBody && formikVar.touched.plainBody)}
                      helperText={formikVar.errors.plainBody && formikVar.touched.plainBody && String(formikVar.errors.plainBody)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Paper>
                    <Box p={2}>
                      <Typography variant="subtitle2">
                        <LanguageString groupName="GENERAL" resourceName="ATTACHMENTS" defaultText="Attachments" />
                      </Typography>
                      <Box mt={2}>
                        <NotificationBlastAttachment
                          attachments={formikVar.values.attachments ?? []}
                          addAttachment={att => {
                            formikVar.setFieldValue('attachments', [att, ...(formikVar.values.attachments ?? [])]);
                          }}
                          removeAttachment={att => {
                            formikVar.setFieldValue('attachments', [...(formikVar.values.attachments ?? []).filter(a => a.id !== att.id)]);
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    {loading ?
                      <CircularProgress size={20} /> :
                      <LanguageString groupName="GENERAL" resourceName="SEND_NOTIFICATION" defaultText="Send Notification" />
                    }
                  </Button>
                </Grid>
              </Grid>
            </Paper>

            <SendDialog
              open={sendOpen}
              toggle={toggleSendOpen}
              send={(data) => send(data, formikVar.resetForm)}
              data={formikVar.values}
              studentCount={studentsCtx?.totalCount}
            />

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={snackbarMsg != null}
              TransitionComponent={TransitionUp}
              onClose={closeSnackbar}
              key={`snackbar_error`}
              autoHideDuration={7000}
            >
              <Alert
                severity={snackbarMsg?.type}
                action={
                  <IconButton onClick={closeSnackbar}><CancelRounded /></IconButton>
                }
              >
                <span className={classes.clickable} onClick={closeSnackbar}>
                  {snackbarMsg?.message}
                </span>
              </Alert>
            </Snackbar>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NotificationBlastInner;
