import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { LanguageString, StringQueryArgs } from '../../dto';
import { ReqVars } from './common';
import { languageStringFrag } from './fragments';

export type Variables = StringQueryArgs;
export type Results = { string: LanguageString };

export const query = gql`
  ${languageStringFrag}
  query getLanguageString($groupName: String!, $resourceName: String!, $languageId: Int, $userId: Int, $renderUserData: Boolean) {
    string(groupName: $groupName, resourceName: $resourceName, languageId: $languageId, userId: $userId, renderUserData: $renderUserData) {
      ...LangStringFrag
    }
  }
`;

export const useLanguageString = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
