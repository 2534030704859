import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from '../../dto';

export type Results = { me?: User };
export const query = gql`
  query Me {
    me {
      id
      username
      firstName
      lastName
      telemetryId
      advisor {
        id
        firstName
        lastName
      }
      roles
      currentRole @client
      preferredLanguage {
        id
        code
        name
      }
      unreadNotificationCount
    }
  }
`;

export function useLoggedInUser(options: QueryHookOptions<Results>) {
  return useQuery<Results>(query, options);
}
