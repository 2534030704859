import React from 'react';
import {
  SvgIcon
} from '@material-ui/core';

export const NotificationsIcon: React.FC = () => (
  <SvgIcon>
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M13.92,5.84H8.2A1.69,1.69,0,0,0,6.51,7.53v5.71A1.69,1.69,0,0,0,8.2,14.93H9.86L10.58,16a.54.54,0,0,0,.95,0l.72-1.06h1.67a1.69,1.69,0,0,0,1.68-1.69V7.53A1.69,1.69,0,0,0,13.92,5.84Zm0,7.4H8.2V7.53h5.72Z"/><path d="M11,10.92a.63.63,0,0,0,.63-.63V8.76a.63.63,0,0,0-1.26,0v1.53A.63.63,0,0,0,11,10.92Z"/><path d="M11,12.64a.7.7,0,1,0-.69-.69A.69.69,0,0,0,11,12.64Z"/><path d="M11.06,0a11.06,11.06,0,1,0,11,11.06A11.07,11.07,0,0,0,11.06,0Zm0,20.22a9.16,9.16,0,1,1,9.16-9.16A9.17,9.17,0,0,1,11.06,20.22Z"/></g></g>
  </SvgIcon>
);

export default NotificationsIcon;
