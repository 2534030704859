import { Grid, CircularProgress } from '@material-ui/core';
import { getDate, getMonth, getYear } from 'date-fns';
import React, { useContext } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { MTSView } from 'tuapath-common/generated/schema';
import { ChildRoutes, SiteContext } from '../../contexts';
import MTSHelpers from '../../helpers/mtsHelpers';
import { useUserMTSPeriods } from '../../hooks';
import { GeneralErrorMessage, LanguageString } from '../Common';

export const MTS: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const match = useRouteMatch();

  const mtsPeriodResults = useUserMTSPeriods();
  const mtsPeriods = mtsPeriodResults.data?.site.mtsPeriods ?? [];

  if (mtsPeriodResults.loading) {
    return (
      <Grid container justify="center" alignItems="center" style={{ minHeight: 400 }}>
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  } else if (mtsPeriods.length <= 0 && siteCtx.site?.mtsPeriodCustomPropertyTypeId && siteCtx.site?.mtsPeriodsEnabled) {
    return (
      <Grid container justify="center" alignItems="center" style={{ minHeight: 400 }}>
        <Grid item>
          <GeneralErrorMessage
            message={
              <LanguageString groupName="MTS" resourceName="MTS_ERROR" defaultText="Oops, looks like your MTS periods are misconfigured" />
            }
          />
        </Grid>
      </Grid>
    );
  }

  const currentPeriod = MTSHelpers.getCurrentMTSPeriod(mtsPeriods);

  if (match.isExact) {
    const date = new Date();

    if (siteCtx.site?.defaultMtsView) {
      if (siteCtx.site.defaultMtsView === MTSView.week) {
        return <Redirect to={`${match.path}/week/${getMonth(date) + 1}/${getDate(date)}/${getYear(date)}`} />;
      } else if (siteCtx.site.defaultMtsView === MTSView.year) {
        return <Redirect to={`${match.path}/year/${getYear(date)}`} />;
      } else if (siteCtx.site.defaultMtsView === MTSView.custom && currentPeriod) {
        return <Redirect to={`${match.path}/${currentPeriod.id}}`} />;
      }
    }

    if (currentPeriod) {
      return <Redirect to={`${match.path}/custom/${currentPeriod.id}`} />;
    } else {
      return <Redirect to={`${match.path}/month/${getMonth(date) + 1}/${getYear(date)}`} />;
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ChildRoutes />
      </Grid>
    </Grid>
  );
};

export default MTS;
