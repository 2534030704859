import { Collapse, Grid, Icon, List, ListItem, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import React, { Fragment } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { TextField } from '..';
import { FEValues } from '../../common';
import { useDialogController } from '../../hooks';
import { LSTextField } from '../Common';
import { LayoutTextField } from './common';
import { Questions } from './Questions';
import { sectionIndent } from './utils';

const ChildElementsInner: React.FC<FieldArrayRenderProps> = ({ name, push }) => {
  const [{ value: elements }] = useField<FEValues[]>(name);

  return elements.length > 0 ? (
    <List
      style={{ marginTop: '15px' }}
      subheader={
        <ListSubheader>
          <Typography>Sections</Typography>
        </ListSubheader>
      }
    >
      {elements.map((ce, cIndex) => (
        <ElementListItem key={ce.id} element={ce} fieldName={`${name}[${cIndex}]`} index={cIndex} />
      ))}
    </List>
  ) : null;
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
const ChildElements: React.FC<FieldArrayRenderProps | void> = ChildElementsInner as React.FC<FieldArrayRenderProps | void>;

export const ElementListItem: React.FC<{ element: FEValues; fieldName: string; index: number }> = ({ element, fieldName, index }) => {
  const { controller, props } = useDialogController(false);

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const sourceIndex = result.source.index;
    const destIndex = result.destination?.index;
    const { elements } = element;

    if (result.reason === 'DROP' && destIndex != null && elements != null) {
      elements.splice(destIndex, 0, elements.splice(sourceIndex, 1)[0]);
      elements.forEach((el, nIndex) => (el.sortIndex = nIndex));
    }
  };

  return (
    <Draggable draggableId={`ce-list-${element.id}`} index={index}>
      {dgProvided => (
        <Fragment>
          <ListItem button onClick={() => controller.toggle()} {...dgProvided.draggableProps} ref={dgProvided.innerRef}>
            <ListItemText primary={element.name} />
            {props.open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
            <Icon {...dgProvided.dragHandleProps}>drag_handle</Icon>
          </ListItem>
          {/* TODO: Disable dnd for parent when this is expanded */}
          <Collapse in={props.open} timeout="auto" unmountOnExit>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={`element-list-${element.id}`} type={`ec-${element.id}`}>
                {dpProvided => (
                  <List disablePadding style={{ paddingLeft: sectionIndent }} ref={dpProvided.innerRef}>
                    <ListItem>
                      <Grid container spacing={2} direction="column">
                        {/* TODO: Create a function to return a 'parent' with type-safe fields that return these strings */}
                        <TextField item name={`${fieldName}.name`} label="Name" />
                        <LSTextField item name={`${fieldName}.title`} label="Title" />
                        <LSTextField item name={`${fieldName}.instructions`} label="Instructions" />
                        <LayoutTextField item name={`${fieldName}.layoutType`} />
                      </Grid>
                    </ListItem>
                    {(element.elements?.length ?? 0) > 0 && <FieldArray name={`${fieldName}.elements`} component={ChildElements} />}
                    {<FieldArray name={`${fieldName}.questions`} component={Questions} />}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Collapse>
        </Fragment>
      )}
    </Draggable>
  );
};
