import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import { DialogTitle, LanguageString } from '..';
import { DialogController, useDialogController } from '../../../hooks';

type MessageDialogProps = {
  message: React.ReactNode;
  title?: React.ReactNode;
  confirmMessage?: React.ReactNode;
  cancelMessage?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  ctrl?: DialogController;
};

export const MessageDialog: React.FC<MessageDialogProps> = ({
  message,
  title,
  confirmMessage = <LanguageString groupName="SITE_CONTENT" resourceName="OK" />,
  cancelMessage,
  onConfirm,
  onCancel,
  ctrl
}) => {
  const nCtrl = useDialogController(true);
  const { controller, props } = ctrl ?? nCtrl;

  const handleConfirmClick = () => {
    if (onConfirm) onConfirm();
    controller.setClose();
  };

  const handleCancelClick = () => {
    if (onCancel) onCancel();
    controller.setClose();
  };

  return (
    <Dialog {...props} maxWidth="md" fullWidth>
      <DialogTitle onClose={controller.setClose}>
        {title || <LanguageString groupName="SITE_CONTENT" resourceName="MESSAGE_DIALOG" />}
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        {cancelMessage && <Button onClick={handleCancelClick}>{cancelMessage}</Button>}
        <Button onClick={handleConfirmClick}>{confirmMessage}</Button>
      </DialogActions>
    </Dialog>
  );
};
