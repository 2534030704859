import React, { useCallback, useState } from 'react';

const useFloatingLables = true;

export const AppContext = React.createContext<{
  rootContainer: HTMLDivElement | null;
  setRootContainer?: (container: HTMLDivElement) => void;
  useFloatingLabels: boolean;
}>({ rootContainer: null, useFloatingLabels: useFloatingLables });

export const AppProvider: React.FC = ({ children }) => {
  const [rootContainer, setRootContainer] = useState<HTMLDivElement | null>(null);
  const cb = useCallback((container: HTMLDivElement) => {
    setRootContainer(container);
  }, []);

  return <AppContext.Provider value={{ rootContainer: rootContainer, setRootContainer: cb, useFloatingLabels: useFloatingLables }}>{children}</AppContext.Provider>;
};
