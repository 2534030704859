import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { attachmentFrag } from './fragments/attachmentFrag';

type Result = { fileDeleted: boolean };
type Variables = { attachmentId: number, deleteReason: string, allowDeleteSpecialTypes?: Boolean};
export const useDeleteFile = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
  ${attachmentFrag}
    mutation deleteFile($attachmentId: Int!, $deleteReason: String!, $allowDeleteSpecialTypes: Boolean) {
      deleteFile(attachmentId: $attachmentId, deleteReason: $deleteReason, allowDeleteSpecialTypes: $allowDeleteSpecialTypes){
        id
        files{
          ...AttachmentFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useDeleteFile;
