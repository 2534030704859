import React from 'react';
import { useParams } from 'react-router-dom';
import { Milestone } from '../../dto';
import { useMilestone } from '../../hooks/gql/useMilestone';

export const MilestoneContext = React.createContext<{ milestone?: Milestone, rmMsId?: number }>({});

export const MilestoneProvider: React.FC = ({ children }) => {
  const { msId, rmMsId: rmMsIdParam } = useParams<{ msId: string, rmMsId?: string }>();
  const { data } = useMilestone({ variables: { milestoneId: Number(msId) } });

  const rmMsId = rmMsIdParam ? Number(rmMsIdParam) : undefined;
  if (data?.milestone) return <MilestoneContext.Provider value={{ milestone: data.milestone, rmMsId }}>{children}</MilestoneContext.Provider>;

  return null;
};
