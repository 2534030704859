import gql from 'graphql-tag';

export const roadmapMeetingFrag = gql`
  fragment RoadmapMeetingFrag on RoadmapMeeting {
    id
    meetingType
    requestorRole
    requestReason
    reasonForChange
    date
    event {
      id
      name
      scheduledDate
      attachment{
        id
        url
      }
    }
  }
`;

export default roadmapMeetingFrag;
