import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { SiteBranding, UpdatedCSSInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Result = { updateStyles: QueryType<SiteBranding> };
type Variables = { input: UpdatedCSSInput };
export const useUpdateBrandingCSS = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation updateStyles($input: UpdatedCSSInput!) {
      updateCssClasses(input: $input) {
        id
        cssUrl
        logoUrl
        cssClasses
        imagesUrl
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
