import React, { useContext, useMemo } from 'react';
import {
  Typography,
  Grid,
  ButtonBase,
  makeStyles
  // Button
} from '@material-ui/core';
import {
  isSameMonth,
  isToday,
  format,
  getUnixTime
} from 'date-fns';
import clsx from 'clsx';
import { useClasses } from '../../../hooks';
import MTSHelpers from '../../../helpers/mtsHelpers';
import { MTSContext } from '../../../contexts';
import { RelativeLink } from '../../Common';
import { MTSPeriod } from '../../../dto';

const useStyles = makeStyles({
  inativeCell: {
    backgroundColor: 'rgba(50,50,50,0.1)'
  }
});

interface CalendarDayProps {
  date: Date;
  monthDate: Date;
  mtsPeriod?: MTSPeriod;
  textColor?: string;
  showMonth?: boolean;
  periodStartDate?: Date;
  periodEndDate?: Date;
  shouldCheckCellInactive?: boolean;
}

export const CalendarMonthViewCell: React.FC<CalendarDayProps> = (props) => {
  const styles = useStyles();
  const mtsCtx = useContext(MTSContext);
  const classes = useClasses();
  const activities = MTSHelpers.mtsEntryForDate(props.date, mtsCtx.mtses, props.mtsPeriod);
  const totalHours = useMemo(() => {
    let hours = 0;

    if (activities) {
      for (const activity of activities) {
        hours += activity.hours;
      }
    }

    return hours;
  }, [activities, props.date]);

  const inativeCell = useMemo(() => {
    if (props.shouldCheckCellInactive === false) return false;

    if (props.mtsPeriod) {
      if (props.periodStartDate && props.periodEndDate) {
        const startDate = props.periodStartDate;
        startDate.setHours(0, 0, 0);
        const endDate = props.periodEndDate;
        endDate.setHours(0, 0, 0);

        return !(props.date >= startDate && props.date <= endDate);
      }

      return true;
    } else {
      return !isSameMonth(props.date, props.monthDate);
    }
  }, [props.periodStartDate, props.date, props.shouldCheckCellInactive]);

  return (
    <Grid item style={{ width: `${(1.0 / 7.0) * 100}%` }}>
      <RelativeLink style={{ textDecoration: 'none', pointerEvents: inativeCell ? 'none' : 'inherit' }} to={`/entries/${getUnixTime(props.date)}`}>
        <ButtonBase style={{ width: '100%', height: '100%' }}>
          <Grid
            container
            direction="column"
            className={clsx([classes.mtsMonthViewCell], {
              [styles.inativeCell]: inativeCell
            })}
          >
            <Grid item xs={12}>
              <Typography
                align="left"
                variant="h6"
                color={isToday(props.date) ? 'primary' : 'textSecondary'}
              >
                {format(props.date, props.showMonth === true ? 'MMM d' : 'd')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography
                align="center"
                variant="h5"
                color="primary"
              >
                {totalHours > 0 ? totalHours : <br />}
              </Typography>
            </Grid>
          </Grid>
        </ButtonBase>
      </RelativeLink>
    </Grid>
  );
};
export default CalendarMonthViewCell;
