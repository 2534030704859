"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormUsage = exports.DynamicFormSortType = exports.NotificationRelationType = exports.RocTypeCommunicationPlatform = exports.NotificationTemplateName = exports.FormQuestionNames = exports.CustomPropertyNames = exports.RoadmapMeetingChangeReason = exports.RequestorRole = exports.RoadmapMeetingType = exports.ResetUsernameFailureReason = exports.ResetPasswordFailureReason = exports.ChangePasswordFailureReason = exports.RegistrationFailureReason = exports.LoginFailureReason = exports.SpecialUploadTypes = exports.StudentSortType = exports.SortDirection = exports.DefaultLoginOption = exports.CoachDeletePermission = exports.SiteSettingsDataType = exports.TimeExtensionType = exports.DripScheduleType = exports.TriggerType = exports.NavComponent = exports.AppNavBarConfigurationType = exports.MTSView = exports.FormAnswerTranslationPolicy = exports.IncludeSubmissionTypes = exports.RoadmapMilestoneState = exports.Role = exports.UserDeleteAbility = exports.TaskActionType = exports.PrintLocation = exports.FormLayoutType = exports.FormOperation = exports.FormQuery = exports.FormOptionType = exports.FormRatingQuestionOrientation = exports.FormAnswerType = exports.FormQuestionType = exports.FormType = exports.MTSState = exports.CustomPropertyTypeFieldType = void 0;
var CustomPropertyTypeFieldType;
(function (CustomPropertyTypeFieldType) {
    CustomPropertyTypeFieldType["TEXT"] = "TEXT";
    CustomPropertyTypeFieldType["CHECKBOX"] = "CHECKBOX";
    CustomPropertyTypeFieldType["DATE"] = "DATE";
    CustomPropertyTypeFieldType["SELECT"] = "SELECT";
    CustomPropertyTypeFieldType["PASSWORD"] = "PASSWORD";
    CustomPropertyTypeFieldType["MULTISELECT"] = "MULTISELECT";
    CustomPropertyTypeFieldType["HIDDEN"] = "HIDDEN";
})(CustomPropertyTypeFieldType = exports.CustomPropertyTypeFieldType || (exports.CustomPropertyTypeFieldType = {}));
var MTSState;
(function (MTSState) {
    MTSState["NOT_SUBMITTED"] = "NOT_SUBMITTED";
    MTSState["SUBMITTED"] = "SUBMITTED";
    MTSState["APPROVED"] = "APPROVED";
})(MTSState = exports.MTSState || (exports.MTSState = {}));
var FormType;
(function (FormType) {
    FormType["INFORMATION"] = "INFORMATION";
    FormType["TEST"] = "TEST";
})(FormType = exports.FormType || (exports.FormType = {}));
var FormQuestionType;
(function (FormQuestionType) {
    FormQuestionType["BOOLEAN"] = "BOOLEAN";
    FormQuestionType["DATE"] = "DATE";
    FormQuestionType["DATETIME"] = "DATETIME";
    FormQuestionType["DUEDATE"] = "DUEDATE";
    FormQuestionType["GRAPH"] = "GRAPH";
    FormQuestionType["GRAPHWITHDATE"] = "GRAPHWITHDATE";
    FormQuestionType["HIDDEN"] = "HIDDEN";
    FormQuestionType["INFORMATION"] = "INFORMATION";
    FormQuestionType["MULTISELECT"] = "MULTISELECT";
    FormQuestionType["NUMBER"] = "NUMBER";
    FormQuestionType["PASSWORD"] = "PASSWORD";
    FormQuestionType["RATING"] = "RATING";
    FormQuestionType["SELECT"] = "SELECT";
    FormQuestionType["TEXT"] = "TEXT";
    FormQuestionType["TIME"] = "TIME";
})(FormQuestionType = exports.FormQuestionType || (exports.FormQuestionType = {}));
var FormAnswerType;
(function (FormAnswerType) {
    FormAnswerType["ONE"] = "ONE";
    FormAnswerType["SOME"] = "SOME";
    FormAnswerType["ALL"] = "ALL";
})(FormAnswerType = exports.FormAnswerType || (exports.FormAnswerType = {}));
var FormRatingQuestionOrientation;
(function (FormRatingQuestionOrientation) {
    FormRatingQuestionOrientation["HORIZONTAL"] = "HORIZONTAL";
    FormRatingQuestionOrientation["VERTICAL"] = "VERTICAL";
})(FormRatingQuestionOrientation = exports.FormRatingQuestionOrientation || (exports.FormRatingQuestionOrientation = {}));
var FormOptionType;
(function (FormOptionType) {
    FormOptionType["STATIC"] = "STATIC";
    FormOptionType["DYNAMIC"] = "DYNAMIC";
    FormOptionType["REFERENCE"] = "REFERENCE";
})(FormOptionType = exports.FormOptionType || (exports.FormOptionType = {}));
var FormQuery;
(function (FormQuery) {
    FormQuery["useAdvisors"] = "useAdvisors";
    FormQuery["useAvailableRoles"] = "useAvailableRoles";
    FormQuery["useCustomProperties"] = "useCustomProperties";
    FormQuery["useDepartments"] = "useDepartments";
    FormQuery["useFormSubmissions"] = "useFormSubmissions";
    FormQuery["useLanguages"] = "useLanguages";
    FormQuery["usePath"] = "usePath";
    FormQuery["usePrograms"] = "usePrograms";
    FormQuery["useRocTypes"] = "useRocTypes";
    FormQuery["useSiteMilestones"] = "useSiteMilestones";
    FormQuery["useSiteMTSActivityTypes"] = "useSiteMTSActivityTypes";
    FormQuery["useSiteOrganizations"] = "useSiteOrganizations";
})(FormQuery = exports.FormQuery || (exports.FormQuery = {}));
var FormOperation;
(function (FormOperation) {
    FormOperation["SIMPLE"] = "SIMPLE";
    FormOperation["EQUAL"] = "EQUAL";
    FormOperation["NOT_EQUAL"] = "NOT_EQUAL";
    FormOperation["LESS_THAN"] = "LESS_THAN";
    FormOperation["GREATER_THAN"] = "GREATER_THAN";
    FormOperation["MATCH"] = "MATCH";
    FormOperation["SUBMITTED"] = "SUBMITTED";
    FormOperation["FALSE"] = "FALSE";
    FormOperation["LOGICAL"] = "LOGICAL";
})(FormOperation = exports.FormOperation || (exports.FormOperation = {}));
var FormLayoutType;
(function (FormLayoutType) {
    FormLayoutType["ROWS"] = "ROWS";
    FormLayoutType["COLUMNS"] = "COLUMNS";
    FormLayoutType["BOOTSTRAP_COLUMNS"] = "BOOTSTRAP_COLUMNS";
})(FormLayoutType = exports.FormLayoutType || (exports.FormLayoutType = {}));
var PrintLocation;
(function (PrintLocation) {
    PrintLocation["General"] = "General";
    PrintLocation["Form"] = "Form";
    PrintLocation["FormSubmission"] = "FormSubmission";
    PrintLocation["MTS"] = "MTS";
    PrintLocation["Roadmap"] = "Roadmap";
    PrintLocation["RoadmapComplete"] = "RoadmapComplete";
    PrintLocation["MTSActivityType"] = "MTSActivityType";
    PrintLocation["ROC"] = "ROC";
})(PrintLocation = exports.PrintLocation || (exports.PrintLocation = {}));
var TaskActionType;
(function (TaskActionType) {
    TaskActionType["video"] = "video";
    TaskActionType["document"] = "document";
    TaskActionType["webForm"] = "webForm";
    TaskActionType["event"] = "event";
    TaskActionType["readOnly"] = "readOnly";
    TaskActionType["link"] = "link";
})(TaskActionType = exports.TaskActionType || (exports.TaskActionType = {}));
var UserDeleteAbility;
(function (UserDeleteAbility) {
    UserDeleteAbility["Allowed"] = "Allowed";
    UserDeleteAbility["CannotDeleteType"] = "CannotDeleteType";
    UserDeleteAbility["CannotDeleteUploader"] = "CannotDeleteUploader";
    UserDeleteAbility["CannotDeleteAssignmentFinalized"] = "CannotDeleteAssignmentFinalized";
})(UserDeleteAbility = exports.UserDeleteAbility || (exports.UserDeleteAbility = {}));
var Role;
(function (Role) {
    Role["SUPERADMIN"] = "SUPERADMIN";
    Role["ADMIN"] = "ADMIN";
    Role["EDITOR"] = "EDITOR";
    Role["REPORTER"] = "REPORTER";
    Role["ADVISOR"] = "ADVISOR";
    Role["STUDENT"] = "STUDENT";
    Role["EMPLOYER"] = "EMPLOYER";
})(Role = exports.Role || (exports.Role = {}));
var RoadmapMilestoneState;
(function (RoadmapMilestoneState) {
    RoadmapMilestoneState["COMPLETED"] = "COMPLETED";
    RoadmapMilestoneState["DEFERRED"] = "DEFERRED";
    RoadmapMilestoneState["CONTINUE"] = "CONTINUE";
})(RoadmapMilestoneState = exports.RoadmapMilestoneState || (exports.RoadmapMilestoneState = {}));
var IncludeSubmissionTypes;
(function (IncludeSubmissionTypes) {
    IncludeSubmissionTypes["ALL"] = "ALL";
    IncludeSubmissionTypes["SUBMITTED"] = "SUBMITTED";
    IncludeSubmissionTypes["SUBMITTED_AND_LAST_SAVED"] = "SUBMITTED_AND_LAST_SAVED";
    IncludeSubmissionTypes["SAVED"] = "SAVED";
})(IncludeSubmissionTypes = exports.IncludeSubmissionTypes || (exports.IncludeSubmissionTypes = {}));
var FormAnswerTranslationPolicy;
(function (FormAnswerTranslationPolicy) {
    FormAnswerTranslationPolicy["OFF"] = "OFF";
    FormAnswerTranslationPolicy["SAVE_ONLY"] = "SAVE_ONLY";
    FormAnswerTranslationPolicy["SUBMIT_ONLY"] = "SUBMIT_ONLY";
    FormAnswerTranslationPolicy["ON"] = "ON";
})(FormAnswerTranslationPolicy = exports.FormAnswerTranslationPolicy || (exports.FormAnswerTranslationPolicy = {}));
var MTSView;
(function (MTSView) {
    MTSView["month"] = "month";
    MTSView["week"] = "week";
    MTSView["year"] = "year";
    MTSView["custom"] = "custom";
    MTSView["grouped"] = "grouped";
    MTSView["customgrouped"] = "customgrouped";
})(MTSView = exports.MTSView || (exports.MTSView = {}));
var AppNavBarConfigurationType;
(function (AppNavBarConfigurationType) {
    AppNavBarConfigurationType["MAIN"] = "MAIN";
    AppNavBarConfigurationType["COACH"] = "COACH";
})(AppNavBarConfigurationType = exports.AppNavBarConfigurationType || (exports.AppNavBarConfigurationType = {}));
var NavComponent;
(function (NavComponent) {
    NavComponent["ActionStepLibrary"] = "ActionStepLibrary";
    NavComponent["CalendarMonthView"] = "CalendarMonthView";
    NavComponent["CalendarYearView"] = "CalendarYearView";
    NavComponent["CalendarWeekView"] = "CalendarWeekView";
    NavComponent["CalendarCustomView"] = "CalendarCustomView";
    NavComponent["CalendarCustomWeekView"] = "CalendarCustomWeekView";
    NavComponent["CalendarCustomGroupedView"] = "CalendarCustomGroupedView";
    NavComponent["CalendarGroupedView"] = "CalendarGroupedView";
    NavComponent["CaseLoad"] = "CaseLoad";
    NavComponent["CaseLoadNR"] = "CaseLoadNR";
    NavComponent["DataImport"] = "DataImport";
    NavComponent["DownloadRoute"] = "DownloadRoute";
    NavComponent["FileBox"] = "FileBox";
    NavComponent["FormEditor"] = "FormEditor";
    NavComponent["FormRoute"] = "FormRoute";
    NavComponent["MainContent"] = "MainContent";
    NavComponent["Milestone"] = "Milestone";
    NavComponent["MilestoneEditor"] = "MilestoneEditor";
    NavComponent["MsGroup"] = "MsGroup";
    NavComponent["MTS"] = "MTS";
    NavComponent["MTSActivityTypeEditor"] = "MTSActivityTypeEditor";
    NavComponent["MTSDialog"] = "MTSDialog";
    NavComponent["MTSPeriodEditor"] = "MTSPeriodEditor";
    NavComponent["NotificationsEditor"] = "NotificationsEditor";
    NavComponent["NotificationSchedulesTable"] = "NotificationSchedulesTable";
    NavComponent["NotificationTemplatesTable"] = "NotificationTemplatesTable";
    NavComponent["PathBreadcrumb"] = "PathBreadcrumb";
    NavComponent["PathEditor"] = "PathEditor";
    NavComponent["PlacementPage"] = "PlacementPage";
    NavComponent["Profile"] = "Profile";
    NavComponent["RecordOfContacts"] = "RecordOfContacts";
    NavComponent["Reports"] = "Reports";
    NavComponent["Resources"] = "Resources";
    NavComponent["ReviewAllMTSInfoPage"] = "ReviewAllMTSInfoPage";
    NavComponent["Roadmap"] = "Roadmap";
    NavComponent["RoadmapPrint"] = "RoadmapPrint";
    NavComponent["SnapShot"] = "SnapShot";
    NavComponent["Students"] = "Students";
    NavComponent["StudentProvider"] = "StudentProvider";
    NavComponent["StylesEditor"] = "StylesEditor";
    NavComponent["TaskLibrary"] = "TaskLibrary";
    NavComponent["EmployerContact"] = "EmployerContact";
    NavComponent["EmployeeContact"] = "EmployeeContact";
    NavComponent["PowerBI"] = "PowerBI";
    NavComponent["NotificationBlast"] = "NotificationBlast";
    NavComponent["CareerOneStop"] = "CareerOneStop";
})(NavComponent = exports.NavComponent || (exports.NavComponent = {}));
var TriggerType;
(function (TriggerType) {
    TriggerType["MTS_NOT_SUBMIT"] = "MTS_NOT_SUBMIT";
    TriggerType["MTS_INACTIVE"] = "MTS_INACTIVE";
    TriggerType["ROADMAP_INACTIVE"] = "ROADMAP_INACTIVE";
    TriggerType["DRIP_NOTIFICATION"] = "DRIP_NOTIFICATION";
})(TriggerType = exports.TriggerType || (exports.TriggerType = {}));
var DripScheduleType;
(function (DripScheduleType) {
    DripScheduleType["DAILY"] = "DAILY";
    DripScheduleType["WEEKLY"] = "WEEKLY";
    DripScheduleType["MONTHLY"] = "MONTHLY";
})(DripScheduleType = exports.DripScheduleType || (exports.DripScheduleType = {}));
var TimeExtensionType;
(function (TimeExtensionType) {
    TimeExtensionType["DAYS"] = "DAYS";
    TimeExtensionType["WEEKS"] = "WEEKS";
    TimeExtensionType["MONTHS"] = "MONTHS";
})(TimeExtensionType = exports.TimeExtensionType || (exports.TimeExtensionType = {}));
var SiteSettingsDataType;
(function (SiteSettingsDataType) {
    SiteSettingsDataType["NUMBER"] = "NUMBER";
    SiteSettingsDataType["STRING"] = "STRING";
    SiteSettingsDataType["BOOLEAN"] = "BOOLEAN";
})(SiteSettingsDataType = exports.SiteSettingsDataType || (exports.SiteSettingsDataType = {}));
var CoachDeletePermission;
(function (CoachDeletePermission) {
    CoachDeletePermission["STANDARD"] = "STANDARD";
    CoachDeletePermission["PARTICIPANT"] = "PARTICIPANT";
    CoachDeletePermission["ADVISOR"] = "ADVISOR";
})(CoachDeletePermission = exports.CoachDeletePermission || (exports.CoachDeletePermission = {}));
var DefaultLoginOption;
(function (DefaultLoginOption) {
    DefaultLoginOption["UsernamePassword"] = "UsernamePassword";
    DefaultLoginOption["SSO"] = "SSO";
})(DefaultLoginOption = exports.DefaultLoginOption || (exports.DefaultLoginOption = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "ASC";
    SortDirection["DESC"] = "DESC";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
var StudentSortType;
(function (StudentSortType) {
    StudentSortType["StudentKey"] = "StudentKey";
    StudentSortType["MTSStatus"] = "MTSStatus";
    StudentSortType["MTSDocs"] = "MTSDocs";
    StudentSortType["MTSHours"] = "MTSHours";
    StudentSortType["MTSWPRHours"] = "MTSWPRHours";
    StudentSortType["DynamicForm"] = "DynamicForm";
    StudentSortType["CoachMeeting"] = "CoachMeeting";
    StudentSortType["LastMTSActivity"] = "LastMTSActivity";
    StudentSortType["LastRoadmapInteraction"] = "LastRoadmapInteraction";
    StudentSortType["CustomProperty"] = "CustomProperty";
})(StudentSortType = exports.StudentSortType || (exports.StudentSortType = {}));
var SpecialUploadTypes;
(function (SpecialUploadTypes) {
    SpecialUploadTypes["MTS"] = "MTS";
    SpecialUploadTypes["Assignment"] = "Assignment";
    SpecialUploadTypes["TaskDocument"] = "TaskDocument";
    SpecialUploadTypes["Other"] = "Other";
    SpecialUploadTypes["PublicOther"] = "PublicOther";
    SpecialUploadTypes["NotificationAttachment"] = "NotificationAttachment";
})(SpecialUploadTypes = exports.SpecialUploadTypes || (exports.SpecialUploadTypes = {}));
var LoginFailureReason;
(function (LoginFailureReason) {
    LoginFailureReason["badUsernameOrPassword"] = "badUsernameOrPassword";
    LoginFailureReason["accountDisabled"] = "accountDisabled";
    LoginFailureReason["noAccessToSite"] = "noAccessToSite";
    LoginFailureReason["serverError"] = "serverError";
    LoginFailureReason["accountLocked"] = "accountLocked";
})(LoginFailureReason = exports.LoginFailureReason || (exports.LoginFailureReason = {}));
var RegistrationFailureReason;
(function (RegistrationFailureReason) {
    RegistrationFailureReason["usernameAlreadyExists"] = "usernameAlreadyExists";
    RegistrationFailureReason["emailAlreadyExists"] = "emailAlreadyExists";
    RegistrationFailureReason["passwordBreached"] = "passwordBreached";
    RegistrationFailureReason["missingCustomProperty"] = "missingCustomProperty";
    RegistrationFailureReason["validationError"] = "validationError";
    RegistrationFailureReason["serverError"] = "serverError";
    RegistrationFailureReason["invalidReferralCode"] = "invalidReferralCode";
    RegistrationFailureReason["duplicate"] = "duplicate";
})(RegistrationFailureReason = exports.RegistrationFailureReason || (exports.RegistrationFailureReason = {}));
var ChangePasswordFailureReason;
(function (ChangePasswordFailureReason) {
    ChangePasswordFailureReason["oldPasswordIncorrect"] = "oldPasswordIncorrect";
    ChangePasswordFailureReason["passwordBreached"] = "passwordBreached";
    ChangePasswordFailureReason["validationError"] = "validationError";
    ChangePasswordFailureReason["serverError"] = "serverError";
    ChangePasswordFailureReason["weakPassword"] = "weakPassword";
})(ChangePasswordFailureReason = exports.ChangePasswordFailureReason || (exports.ChangePasswordFailureReason = {}));
var ResetPasswordFailureReason;
(function (ResetPasswordFailureReason) {
    ResetPasswordFailureReason["tokenIncorrect"] = "tokenIncorrect";
    ResetPasswordFailureReason["tokenExpired"] = "tokenExpired";
    ResetPasswordFailureReason["passwordBreached"] = "passwordBreached";
    ResetPasswordFailureReason["validationError"] = "validationError";
    ResetPasswordFailureReason["serverError"] = "serverError";
    ResetPasswordFailureReason["weakPassword"] = "weakPassword";
})(ResetPasswordFailureReason = exports.ResetPasswordFailureReason || (exports.ResetPasswordFailureReason = {}));
var ResetUsernameFailureReason;
(function (ResetUsernameFailureReason) {
    ResetUsernameFailureReason["tokenIncorrect"] = "tokenIncorrect";
    ResetUsernameFailureReason["tokenExpired"] = "tokenExpired";
    ResetUsernameFailureReason["invalidUsername"] = "invalidUsername";
    ResetUsernameFailureReason["validationError"] = "validationError";
    ResetUsernameFailureReason["serverError"] = "serverError";
})(ResetUsernameFailureReason = exports.ResetUsernameFailureReason || (exports.ResetUsernameFailureReason = {}));
var RoadmapMeetingType;
(function (RoadmapMeetingType) {
    RoadmapMeetingType["IN_PERSON"] = "IN_PERSON";
    RoadmapMeetingType["BY_PHONE"] = "BY_PHONE";
    RoadmapMeetingType["VIDEO_CALL"] = "VIDEO_CALL";
})(RoadmapMeetingType = exports.RoadmapMeetingType || (exports.RoadmapMeetingType = {}));
var RequestorRole;
(function (RequestorRole) {
    RequestorRole["ADVISOR"] = "ADVISOR";
    RequestorRole["STUDENT"] = "STUDENT";
})(RequestorRole = exports.RequestorRole || (exports.RequestorRole = {}));
var RoadmapMeetingChangeReason;
(function (RoadmapMeetingChangeReason) {
    RoadmapMeetingChangeReason["NEXT_MEETING"] = "NEXT_MEETING";
    RoadmapMeetingChangeReason["CHANGE_BY_ADVISOR"] = "CHANGE_BY_ADVISOR";
    RoadmapMeetingChangeReason["CHANGE_BY_STUDENT"] = "CHANGE_BY_STUDENT";
    RoadmapMeetingChangeReason["FIRST_MEETING"] = "FIRST_MEETING";
})(RoadmapMeetingChangeReason = exports.RoadmapMeetingChangeReason || (exports.RoadmapMeetingChangeReason = {}));
var CustomPropertyNames;
(function (CustomPropertyNames) {
    CustomPropertyNames["caseIDNum"] = "caseIDNum";
    CustomPropertyNames["mtsRequired"] = "mtsRequired";
    CustomPropertyNames["closedCase"] = "closedCase";
    CustomPropertyNames["lastNotificationDate"] = "lastNotificationDate";
    CustomPropertyNames["emailNotificationsEnabled"] = "emailNotificationsEnabled";
    CustomPropertyNames["smsNotificationsEnabled"] = "smsNotificationsEnabled";
    CustomPropertyNames["advisorID"] = "advisorID";
    CustomPropertyNames["supportiveServices"] = "supportiveServices";
    CustomPropertyNames["username"] = "username";
})(CustomPropertyNames = exports.CustomPropertyNames || (exports.CustomPropertyNames = {}));
var FormQuestionNames;
(function (FormQuestionNames) {
    FormQuestionNames["closedCases"] = "closedCases";
    FormQuestionNames["closingCases"] = "closingCases";
    FormQuestionNames["openCases"] = "openCases";
    FormQuestionNames["jobTitle"] = "jobTitle";
    FormQuestionNames["jobEnded"] = "jobEnded";
    FormQuestionNames["newCases"] = "newCases";
})(FormQuestionNames = exports.FormQuestionNames || (exports.FormQuestionNames = {}));
var NotificationTemplateName;
(function (NotificationTemplateName) {
    NotificationTemplateName["MTSFileAttachment"] = "MTSFileAttachment";
    NotificationTemplateName["MTSFileAttachmentAdvisor"] = "MTSFileAttachmentAdvisor";
    NotificationTemplateName["MTSApproval"] = "MTSApproval";
    NotificationTemplateName["MTSSubmit"] = "MTSSubmit";
    NotificationTemplateName["MTSSubmitCoach"] = "MTSSubmitCoach";
    NotificationTemplateName["NewRoc"] = "NewRoc";
    NotificationTemplateName["NewMeeting"] = "NewMeeting";
    NotificationTemplateName["MeetingReminder"] = "MeetingReminder";
    NotificationTemplateName["AssignmentUploadAdvisor"] = "AssignmentUploadAdvisor";
    NotificationTemplateName["AssignmentUploadStudent"] = "AssignmentUploadStudent";
    NotificationTemplateName["FileBoxUploadStudent"] = "FileBoxUploadStudent";
    NotificationTemplateName["FileBoxUploadAdvisor"] = "FileBoxUploadAdvisor";
    NotificationTemplateName["AccountSetupEmail"] = "AccountSetupEmail";
    NotificationTemplateName["WelcomeEmail"] = "WelcomeEmail";
    NotificationTemplateName["MilestoneDueDate"] = "MilestoneDueDate";
})(NotificationTemplateName = exports.NotificationTemplateName || (exports.NotificationTemplateName = {}));
var RocTypeCommunicationPlatform;
(function (RocTypeCommunicationPlatform) {
    RocTypeCommunicationPlatform["BlastEmail"] = "BlastEmail";
    RocTypeCommunicationPlatform["BlastSMS"] = "BlastSMS";
    RocTypeCommunicationPlatform["Email"] = "Email";
    RocTypeCommunicationPlatform["SMS"] = "SMS";
    RocTypeCommunicationPlatform["Website"] = "Website";
})(RocTypeCommunicationPlatform = exports.RocTypeCommunicationPlatform || (exports.RocTypeCommunicationPlatform = {}));
var NotificationRelationType;
(function (NotificationRelationType) {
    NotificationRelationType["MILESTONE"] = "MILESTONE";
    NotificationRelationType["ROC"] = "ROC";
    NotificationRelationType["MEETING"] = "MEETING";
    NotificationRelationType["MTS"] = "MTS";
    NotificationRelationType["FILEBOX"] = "FILEBOX";
})(NotificationRelationType = exports.NotificationRelationType || (exports.NotificationRelationType = {}));
var DynamicFormSortType;
(function (DynamicFormSortType) {
    DynamicFormSortType["HasTrueAnswer"] = "HasTrueAnswer";
})(DynamicFormSortType = exports.DynamicFormSortType || (exports.DynamicFormSortType = {}));
var FormUsage;
(function (FormUsage) {
    FormUsage["ASSESSMENT_FORM"] = "ASSESSMENT_FORM";
    FormUsage["ASSESSMENT_REPORT"] = "ASSESSMENT_REPORT";
    FormUsage["MILESTONE_FORM"] = "MILESTONE_FORM";
    FormUsage["MILESTONE_REPORT"] = "MILESTONE_REPORT";
    FormUsage["MTS_FORM"] = "MTS_FORM";
    FormUsage["MTS_REPORT"] = "MTS_REPORT";
    FormUsage["ROC_FORM"] = "ROC_FORM";
    FormUsage["ROC_REPORT"] = "ROC_REPORT";
})(FormUsage = exports.FormUsage || (exports.FormUsage = {}));
