import React, { useState } from 'react';
import * as DTO from '../../../dto';
import {
  Popover,
  ListItem,
  List,
  ListItemText,
  Collapse,
  Icon,
  Typography
} from '@material-ui/core';
import { LanguageString } from '../../Common';

interface MTSActivityDropDownProps {
  activityTypes: DTO.MTSActivityType[];
  anchor: React.RefObject<HTMLButtonElement>;
  open: boolean;
  toggle: () => void;
  selectedActivityType: (type: DTO.MTSActivityType | DTO.UserMTSActivityType) => void;
}

const MTSActivityDropDownLine: React.FC<{ type: DTO.MTSActivityType, selectedType: (type: DTO.MTSActivityType | DTO.UserMTSActivityType) => void }> = ({ type, selectedType }) => {

  const [expansionOpen, setExpansionOpen] = useState(false);

  if (type.generatedChildTypes && type.generatedChildTypes.length > 0) {
    return (
      <>
        <ListItem
          button
          onClick={(event) => {
            setExpansionOpen(!expansionOpen);
          }}
        >
          <ListItemText>
            <LanguageString languageString={type.name} />
          </ListItemText>
          {expansionOpen ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
        </ListItem>
        <Collapse in={expansionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {!type.hidden && (
              <ListItem
                key={`child_activity_${type.id}`}
                button
                onClick={(event) => {
                  selectedType(type);
                }}
                style={{ paddingLeft: 40 }}
              >
                <ListItemText>
                  <LanguageString languageString={type.name} />
                </ListItemText>
              </ListItem>
            )}
            {type.generatedChildTypes.map(ct => (
              <ListItem
                key={`child_activity_${ct.id}`}
                button
                onClick={(event) => {
                  selectedType(ct);
                }}
                style={{ paddingLeft: 40 }}
              >
                <ListItemText>
                  <Typography style={{ fontStyle: ct.expiring ? 'italic' : 'normal' }}>
                    <LanguageString languageString={ct.name} />
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItem
        button
        onClick={(event) => {
          selectedType(type);
        }}
      >
        <ListItemText>
          <LanguageString languageString={type.name} />
        </ListItemText>
      </ListItem>
    );
  }
};

export const MTSActivityDropDown: React.FC<MTSActivityDropDownProps> = (props) => {

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      anchorEl={props.anchor.current}
      open={props.open}
      onClose={props.toggle}
    >
      <List>
        {props.activityTypes.filter(t => !t.hidden || (t.hidden && t.generatedChildTypes && t.generatedChildTypes.length > 0)).map(type => (
          <MTSActivityDropDownLine
            key={`activity_${type.id}`}
            type={type}
            selectedType={(selectedType) => {
              props.toggle();
              props.selectedActivityType(selectedType);
            }}
          />
        ))}
      </List>
    </Popover>
  );
};

export default MTSActivityDropDown;
