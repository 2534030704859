import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { DialogTitle, TextFieldProps } from '..';
import { useDialogController } from '../../../hooks';

type Props = Pick<TextFieldProps, Breakpoint | 'item' | 'label' | 'name'>;
export const ColorPicker: React.FC<Props> = ({ name, label, item, xs, sm, md, lg, xl }) => {
  const formik = useFormikContext<{ [index: string]: string }>();
  const [field] = useField(name as string);
  const { controller, props } = useDialogController(false);
  const [newColor, setNewColor] = useState(field.value);
  const handleOk = () => {
    formik.setFieldValue(name, newColor);
    controller.setClose();
  };

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <TextField
        {...field}
        label={label}
        fullWidth
        InputProps={{ startAdornment: <Box mr={1} width={14} height={14} bgcolor={field.value} onClick={() => controller.setOpen()} /> }}
      />
      <Dialog {...props}>
        <DialogTitle onClose={controller.setClose}>Color Picker</DialogTitle>
        <DialogContent dividers>
          <Box p={2} width="400px">
            <SketchPicker color={newColor} onChangeComplete={color => setNewColor(color.hex)} styles={{ picker: { picker: { boxShadow: ''}} }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
