import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from '../../dto';
import { ReqVars } from './common';
import { mtsCompleteFrag } from './fragments/mtsFrag';

type Variables = { userId: number };
type Results = { user: User };
const query = gql`
  ${mtsCompleteFrag}

  query getCompletedMTSs($userId: Int!) {
    user(id: $userId) {
      id
      completedMTS {
        ...MTSFrag
      }
    }
  }
`;

export const useMTSCompleted = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export default useMTSCompleted;
