import React from 'react';
import { useClasses } from '../../../hooks';
import { Icon, Typography, Grow } from '@material-ui/core';

interface SuccessIconMessageProps {
  message?: React.ReactNode | string;
  animated: Boolean;
  smallMessage?: boolean;
}

export const SuccessIconMessage: React.FC<SuccessIconMessageProps> = ({ message, animated, smallMessage = false }) => {
  const classes = useClasses();

  const inner = (
    <div className={classes.successIconMessage}>
      <Icon>check_circle</Icon>
      {message && typeof message === 'string' ? (
        <Typography variant={smallMessage === true ? 'subtitle1' : 'h4'}>{message}</Typography>
      ) : message}
    </div>
  );

  return (
    <>
      {animated && (
        <Grow in timeout={1500}>
          {inner}
        </Grow>
      )}

      {!animated && (
        <>
          {inner}
        </>
      )}
    </>
  );
};

SuccessIconMessage.defaultProps = {
  smallMessage: false
};

export default SuccessIconMessage;
