import React, { useState } from 'react';
import { useCallback } from 'react';

interface PrintableContextProps {
  printableComponent: React.RefObject<HTMLDivElement>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fileName?: string;
}

export const PrintableContext = React.createContext<PrintableContextProps>({ printableComponent: React.createRef(), loading: false, setLoading: () => undefined });

export const PrintableProvider: React.FC<{
  printableComponent: React.RefObject<HTMLDivElement>, fileName?: string;
}> = ({ printableComponent, fileName, children }) => {
  const [loading, setLoading] = useState(false);

  const _setLoading = useCallback((l: boolean) => {
    setLoading(l);
  }, []);

  return (
    <PrintableContext.Provider
      value={{
        printableComponent: printableComponent,
        loading: loading,
        setLoading: _setLoading,
        fileName: fileName
      }}
    >
      {children}
    </PrintableContext.Provider>
  );
};

export default PrintableProvider;
