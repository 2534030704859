import * as React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed } from 'powerbi-client';
import { useMemo, useState } from 'react';
import { Grid, Icon, IconButton, makeStyles, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import { usePowerBI } from '../../hooks/gql/usePowerBI';
import { PowerBIReport } from 'tuapath-common/generated/schema';
import PBISaveAsDialog from './PBISaveAsDialog';
import { LanguageString } from '../Common';
import PBIUnsavedChangesDialog from './PBIUnsavedChangesDialog';

const useStyles = makeStyles({
  powerBIWrapper: {
    display: 'flex',
    flexBasis: '100%',
    alignSelf: 'stretch',
    flexDirection: 'column',
    width: '100%'
  },
  powerBIChild: {
    flexGrow: 1,
    margin: 3
  },
  selector: {
    flexDirection: 'row',
    flexGrow: 0,
    margin: 3
  }
});

const ExpirationRefreshTimeMINUTE = 3; //number of minutes to refresh before our token expires
const ExpirationRefreshTimeMS = ExpirationRefreshTimeMINUTE * 60 * 1000; //previous value in ms for simplicity
const ExperationCheckIntervalMS = 30 * 1000; // recheck our token this often (in ms) for if its expiry is coming up (within ExpirationRefreshTime minutes).

const PBIEmbed: React.FC = (props) => {
  const classes = useStyles();
  const PBIQuery = usePowerBI({ fetchPolicy: 'network-only' });
  const { loading, refetch } = PBIQuery;
  let { data } = PBIQuery;
  const [reportDescriptor, setReportDescriptor] = useState<PowerBIReport>();
  const [report, setReport] = useState<Report>(); //report object for updating

  //save-as related state
  const [saveAsDialogShow, setSaveAsDialogShow] = useState<boolean>();
  const reportNames = useMemo(() => {
    return data?.PowerBIReporting?.reports?.map(e => e.name) ?? [];
  }, [data]);

  //edit mode related state
  const [editMode, setEditMode] = useState<boolean>(false);
  const editable = useMemo(() => { return reportDescriptor?.allowEdit; }, [reportDescriptor]);

  //unsaved changes related state
  const [unsavedChangesDialogShow, setUnsavedChangesDialogShow] = useState(false);
  const [delayedNav, setDelayedNav] = useState<string>('');
  const [unsaved, setUnsavedChanges] = useState<boolean>(false);

  const checkUnsavedChanges = async (): Promise<boolean> => {
    if (!report) {
      setUnsavedChanges(false);
      return false;
    }
    const unsaved = !await report.isSaved();
    setUnsavedChanges(unsaved);

    return unsaved;
  };

  const ignoreUnsavedChanges = () => {
    changeReport(delayedNav);
    setUnsavedChangesDialogShow(false);
  };

  //registers a time check every 5 seconds for unsaved state
  React.useEffect(() => {
    if (report) {
      const interval = setInterval(() => {
        void checkUnsavedChanges();
      }, 5000); //check every 1 second if we have unsaved changes
      return () => clearInterval(interval);
    }
    return () => { return; };
  }, [report]);

  const toggleEditMode = () => {
    if (!report) {
      return;
    }

    if (editMode) {
      setEditMode(false);
      void report.switchMode('view');
    } else {
      if (editable) {
        setEditMode(true);
        void report.switchMode('edit');
      } else {
        //silent error condion, ui update error or edge case (fail safe)
        void report.switchMode('view');
      }
    }
  };

  const newReport = async () => {
    if (!report) {
      throw new Error('An error has occured, report not initialized');
    }
    const datasets = data?.PowerBIReporting?.datasets ?? [];
    const firstDataset = datasets[0];
    const reportConfig: models.IReportCreateConfiguration = {
      tokenType: models.TokenType.Embed,
      accessToken: data?.PowerBIReporting.embedToken,
      embedUrl: firstDataset.createEmbedURI ?? '',
      datasetId: firstDataset.name ?? '',
      groupId: data?.PowerBIReporting?.workspace ?? '',
      settings: {
        useCustomSaveAsDialog: true,
        panes: {
          filters: {
            expanded: false,
            visible: true
          },
          pageNavigation: {
            visible: true
          }
        },
        layoutType: models.LayoutType.Custom
      }
    };

    setReportDescriptor(({
      name: 'New Report',
      allowEdit: true
    } as PowerBIReport));

    await report.createReport(reportConfig);
    setEditMode(true);
  };

  //registers the token refresh checker
  React.useEffect(() => {
    if (data?.PowerBIReporting?.embedTokenExpiry) {
      const interval = setInterval(() => {
        checkTokenAndUpdate();
      }, ExperationCheckIntervalMS);
      return () => clearInterval(interval);
    }
    return () => { return; };
  }, [!data?.PowerBIReporting?.embedTokenExpiry]);

  // use effect hook to register the on save as dialog override
  React.useEffect(() => {
    report?.on('saveAsTriggered', (event) => {
      setSaveAsDialogShow(true);
    });
  }, [report]);

  //complete the save as operation
  const saveComplete = async (name: string) => {
    if (!data?.PowerBIReporting || !report) {
      throw Error('An error occurred during save, cannnot complete save.');
    }
    const saveParam: models.ISaveAsParameters = {
      name: `${data.PowerBIReporting.UserContentPrefix}${name}`
    };

    await report.saveAs(saveParam);
    try {
      const e = await refetch({ fetchPolicy: 'network-only' });
      ({ data } = e);
    } catch (e) {
      console.log('There was a problem refreshing the token.');
    }
    setSaveAsDialogShow(false);
    const reportId = data.PowerBIReporting.reports.find(f => f.name === name)?.id;
    if (reportId) {
      changeReport(reportId);
    }
  };

  const checkTokenAndUpdate = () => {
    if (!data?.PowerBIReporting?.embedTokenExpiry) {
      return;
    }
    const currentTime = Date.now();
    const expiration = Date.parse(data.PowerBIReporting.embedTokenExpiry);

    const timeUntillExpiration = expiration - currentTime;

    if (timeUntillExpiration <= ExpirationRefreshTimeMS) {
      console.log('Updating report access token');
      void updateToken();
    }
  };

  //handles updating the token through a network refresh
  const updateToken = async () => {
    await refetch({ fetchPolicy: 'network-only' })
      .then(e => { ({ data } = e); })
      .catch(e => { console.log('There was a problem refreshing the token.'); });
  };

  //Performs a report change after checking for unsaved changes (if changes dirty, will delay report change untill dialog confirm)
  const startChangeReport = async (reportId: string) => {
    const unsaved = await checkUnsavedChanges();
    if (unsaved) {
      //prompt user about unsaved changes
      setDelayedNav(reportId);
      setUnsavedChangesDialogShow(true);
    } else {
      changeReport(reportId); //change immediately
    }
  };

  //perform a change of the report right away
  const changeReport = (reportId: string) => {
    setEditMode(false);
    setReportDescriptor(data?.PowerBIReporting?.reports?.find(f => f.id === reportId));
  };

  //set our report descriptor if we find ourself without one (usually startup)
  if (!reportDescriptor && data?.PowerBIReporting?.reports) {
    changeReport(data.PowerBIReporting.reports[0].id);
  }

  return <>
    <Paper className={classes.powerBIWrapper}>
      {data?.PowerBIReporting?.reports && (
        <Grid container className={classes.selector}>
          <Grid item className={classes.selector}>
            <Select value={reportDescriptor?.id} onChange={e => startChangeReport((e.target.value as string))}>
              {data?.PowerBIReporting.reports?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item>
            <Tooltip
              title={editable ?
                <LanguageString groupName="REPORTING" resourceName="EDIT" defaultText="Edit" /> :
                <LanguageString groupName="REPORTING" resourceName="CANNOTEDIT" defaultText="This report is not editable" />}
            >

              <span>
                <IconButton
                  onClick={() => toggleEditMode()}
                  disabled={!editable}
                  aria-label="Edit"
                >
                  <Icon>edit</Icon>
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={<LanguageString groupName="REPORTING" resourceName="NEW" defaultText="New Report" />}
            >
              <span>
                <IconButton
                  onClick={() => newReport()}
                  disabled={false}
                  aria-label="new Report"
                >
                  <Icon>add</Icon>
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item className={classes.selector}>
            {unsaved ? <LanguageString groupName="REPORTING" resourceName="UNSAVED" defaultText="There are unsaved changes!" /> : ''}
          </Grid>
        </Grid>
      )}
      {(loading || data?.PowerBIReporting) ? (
        <PowerBIEmbed
          cssClassName={classes.powerBIChild}
          embedConfig={
            {
              type: 'report',
              id: reportDescriptor?.id,
              embedUrl: reportDescriptor?.embedURI,
              accessToken: data?.PowerBIReporting?.embedToken,
              tokenType: models.TokenType.Embed,
              viewMode: (editMode ? models.ViewMode.Edit : models.ViewMode.View),
              permissions: models.Permissions.All,
              settings: {
                useCustomSaveAsDialog: true,
                panes: {
                  filters: {
                    expanded: false,
                    visible: true
                  },
                  pageNavigation: {
                    visible: true
                  }
                },
                layoutType: models.LayoutType.Custom
              }
            }
          }
          getEmbeddedComponent={(embeddedReport: Embed) => {
            setReport(embeddedReport as Report);
          }}
        />
      ) : (
        <Grid>Reporting is not available - please contact support</Grid>
      )}
    </Paper>
    {saveAsDialogShow && <PBISaveAsDialog close={(() => setSaveAsDialogShow(false))} save={saveComplete} existingNames={reportNames} />}
    {unsavedChangesDialogShow && <PBIUnsavedChangesDialog closeAgree={ignoreUnsavedChanges} close={(() => setUnsavedChangesDialogShow(false))} />}
  </>;
};

export default PBIEmbed;
