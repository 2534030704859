import gql from 'graphql-tag';
import { dependsOnFrag } from './dependsOnFrag';
import { formElementTreeFrag } from './formElementTreeFrag';
import { languageStringFrag } from './languageStringFrag';
import { printFileSchemaFrag } from './printFileSchemaFrag';

export const formFrag = gql`
  ${languageStringFrag}
  ${dependsOnFrag}
  ${formElementTreeFrag}
  ${printFileSchemaFrag}

  fragment FormFrag on Form {
    id
    formType
    isReadonly
    name
    title {
      ...LangStringFrag
    }
    layoutType
    dependsOn {
      ...DependsOnFrag
    }
    elements {
      ...FormElementFrag2
    }
    downloadButtonEnabled
    allowMultipleSubmissions
    autoGrade
    showCorrect
    printFileSchema {
      ...PrintFileSchemaFrag
    }
  }
`;

export default formFrag;
