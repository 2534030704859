import React, { useContext } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent
} from '@material-ui/core';
import { LanguageString } from '../../Common';
import { useClasses } from '../../../hooks';
import { format } from 'date-fns';
import MTSHelpers from '../../../helpers/mtsHelpers';
import { MTSContext } from '../../../contexts';
import { MTSPeriod } from '../../../dto';

interface CalendarYearViewCellProps {
  date: Date;
  period?: MTSPeriod;
  onDateClick: (date: Date) => void;
}

export const CalendarYearViewCell: React.FC<CalendarYearViewCellProps> = (props) => {
  const mtsCtx = useContext(MTSContext);
  const classes = useClasses();
  const totalHours = MTSHelpers.totalHoursForYear(props.date, mtsCtx.mtses);
  const wprHours = MTSHelpers.wprHoursForYear(props.date, mtsCtx.mtses);

  return (
    <Grid
      container
      direction="column"
      className={classes.mtsYearViewCell}
      onClick={() => {
        props.onDateClick(props.date);
      }}
      spacing={3}
    >
      <Grid item xs>
        <Typography
          align="left"
          variant="h5"
        >
          {props.period ?
            `${format(MTSHelpers.dateFromString(props.period.startDate), 'MMM dd')} - ${format(MTSHelpers.dateFromString(props.period.endDate, true), 'MMM dd')}` :
            `${format(props.date, 'MMM')}`
          }
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs>
            <Card>
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  <LanguageString groupName="MTS" resourceName="TOTAL_HOURS" defaultText="Total Hours" />
                </Typography>
                <Typography variant="h5" color="primary">{totalHours}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs>
            <Card>
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  <LanguageString groupName="MTS" resourceName="WPR_HOURS" defaultText="WPR Hours" />
                </Typography>
                <Typography variant="h5" color="primary">{wprHours}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CalendarYearViewCell;
