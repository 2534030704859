import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { CareerOneStopJobsResponse } from 'tuapath-common/generated/schema';


type Results = {
  user: {
    jobs: CareerOneStopJobsResponse;
  };
};
type Variables = {
  userId: number;
  offset: number;
  pageSize: number;
  days: number;
  radius: number;
  keyword?: string;
  zip?: string;
};

export const useJobs = (options?: QueryHookOptions<Results, Variables>) => {
  const query = gql`
    query getJobs($userId: Int!, $offset: Int!, $pageSize: Int!, $days: Int!, $radius: Int!, $keyword: String, $zip: String) {
      user(id: $userId) {
        id
        jobs(offset: $offset, pageSize: $pageSize, days: $days, radius: $radius, keyword: $keyword, zip: $zip) {
          totalCount
          jobs {
            id
            location
            url
            jobTitle
            company
            fc
            accquisitionDate
          }
        }
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
};

export default useJobs;
