import { gql, useMutation } from '@apollo/client';
import { ForgotPasswordInput, ForgotPasswordResult } from 'tuapath-common/generated/schema';

export function useForgotPassword() {
  const mutation = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
      forgotPassword(input: $input) {
        success
      }
    }
  `;

  return useMutation<{ forgotPassword: ForgotPasswordResult }, { input: ForgotPasswordInput }>(mutation);
}
