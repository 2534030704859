import React, { useContext, useMemo } from 'react';
import {
  Grid,
  CardHeader,
  CardContent,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Divider
} from '@material-ui/core';
import { useParams } from 'react-router';
import { LanguageString, GeneralErrorMessage } from '../../Common';
import { StudentContext } from '../../../contexts';
import * as DTO from '../../../dto';
import MTSHelpers from '../../../helpers/mtsHelpers';
import UserHelper from '../../../helpers/userHelper';
import { useClasses, useMTSById } from '../../../hooks';
import MTSDocumentsTab from '../MTSDocumentsDialog/MTSDocumentsTab';
import { RecordOfContacts } from '../../RecordOfContacts';
import { lastDayOfMonth, format } from 'date-fns';
import { ActivityFormsTable } from './ActivityFormsTable';

export const ReviewAllMTSInfoLogNotes: React.FC<{ mts?: DTO.MTS }> = ({ mts }) => {
  const classes = useClasses();
  const activityGroups = MTSHelpers.logNotesEntryGroups(mts != null ? [mts] : []);
  let grandTotal = 0;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_DATE" defaultText="Date" />
          </TableCell>
          <TableCell>
            <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_ACTIVITY_TYPE_NAME" defaultText="Activity Name" />
          </TableCell>
          <TableCell>
            <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_LOG_NOTES" defaultText="Note" />
          </TableCell>
          <TableCell>
            <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_HOURS" defaultText="Hours" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activityGroups.map(activityGroup => {
          let total = 0;
          for (const activity of activityGroup) {
            total += activity.hours;
          }
          grandTotal += total;

          return (
            <>
              {activityGroup.map(activity => {
                return (
                  <TableRow key={`mts_activity_${activity.id}`}>
                    <TableCell>{activity.date ? format(MTSHelpers.dateFromString(activity.date), 'MM/dd/yyyy') : `${mts?.month ?? 1}/${activity.day}/${mts?.year ?? 2021}`}</TableCell>
                    <TableCell>
                      <LanguageString languageString={activity.type.name} />
                    </TableCell>
                    <TableCell>{activity.note}</TableCell>
                    <TableCell>{activity.hours}</TableCell>
                  </TableRow>
                );
              })}

              <TableRow className={classes.tableSubfooter}>
                <TableCell>
                  <LanguageString groupName="MTS" resourceName="MTS_REVIEW_ACTIVITY_TOTAL" defaultText="Activity Total" />
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell>{total}</TableCell>
              </TableRow>
            </>
          );
        })}

        {activityGroups.length > 0 && (
          <TableRow className={classes.tableFooter}>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_GRAND_TOTAL" defaultText="Grand Total" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell>{grandTotal}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export const ReviewAllMTSInfoPage: React.FC = () => {
  const studentCtx = useContext(StudentContext);

  const { mtsId } = useParams<{ mtsId: string }>();
  const mtsByIdResult = useMTSById({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      mtsId: mtsId ? Number(mtsId) : 0
    }
  });

  let mtsMinDate: Date | undefined = undefined;
  let mtsMaxDate: Date | undefined = undefined;
  let selectedMTS: DTO.MTS | undefined = undefined;

  if (mtsByIdResult.data?.user.mtsById) {
    selectedMTS = mtsByIdResult.data.user.mtsById;
  }

  if (selectedMTS) {
    const month = selectedMTS.month - 1;
    const year = selectedMTS.year;
    mtsMinDate = new Date(year, month, 1, 0, 0, 0);
    mtsMaxDate = lastDayOfMonth(mtsMinDate);
  }

  const dateHeader = useMemo(() => {
    if (selectedMTS) {
      if (selectedMTS.mtsPeriod) {
        const mtsDates = MTSHelpers.getPeriodDates(selectedMTS.mtsPeriod);

        return `${format(mtsDates.startDate, 'MMM dd yyyy')} - ${format(mtsDates.endDate, 'MMM dd yyyy')}`;
      } else {
        const mtsMinDate2 = new Date(selectedMTS.year, selectedMTS.month - 1, 1, 0, 0, 0);
        return format(mtsMinDate2, 'MMM yyyy');
      }
    }

    return '';
  }, [selectedMTS]);

  if (mtsByIdResult.error) {
    return <GeneralErrorMessage message={mtsByIdResult.error.message} />;
  }
  if (mtsByIdResult.loading) {
    return <CircularProgress color="primary" />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {studentCtx?.student.firstName ?? ''} {studentCtx?.student.lastName ?? ''}, {studentCtx?.student ?
            UserHelper.customProperty(studentCtx?.student, DTO.CustomPropertyNames.caseIDNum) : ''}
        </Typography>
        <Typography variant="h6">{dateHeader}</Typography>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader
            title={
              <LanguageString groupName="MTS" resourceName="ACTIVITY_FORMS" defaultText="Activity Forms" />
            }
          />
          <CardContent>
            <ActivityFormsTable mts={mtsByIdResult.data?.user.mtsById} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader
            title={
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_LOG_NOTES_TITLE" defaultText="All Log Notes" />
            }
          />
          <CardContent>
            <ReviewAllMTSInfoLogNotes mts={selectedMTS} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item sm={12}>
        <MTSDocumentsTab mts={selectedMTS} />
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
      <Grid item sm={12}>
        <RecordOfContacts defaultRowsPerPageIndex={1} minDate={mtsMinDate} maxDate={mtsMaxDate} />
      </Grid>
    </Grid>
  );
};
export default ReviewAllMTSInfoPage;
