export default class Auth {

  private static tokenKey = 'access_token';

  static isLoggedIn(): Boolean {
    return (Auth.token() != null);
  }

  static token(): string {
    return localStorage.getItem(Auth.tokenKey) as string;
  }

  static setToken(token: string) {
    localStorage.setItem(Auth.tokenKey, token);
  }

  static logout() {
    console.log('Removing item');
    localStorage.removeItem(Auth.tokenKey);
    console.log('Token: ' + this.token());
  }

}
