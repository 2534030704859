import { FormControl, FormLabel, InputAdornment, TextField } from '@material-ui/core';
import { History } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FieldInputProps, FormikContextType, useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { AppContext, StudentContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { FormQuestionType } from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useDialogController, useFocusOnError } from '../../hooks';
import { LanguageString } from '../Common/LanguageString';
import { helperText } from './HelperText';
import { PropertyHistoryDialog } from './PropertyHistory';
import { FormFieldComp, FormStatus, IValues, getQuestionFormId, isInvalid } from './common';
import { FormInformation } from './FormInformation';

const updateDefaultValue = (question: DTO.FormQuestion, values: IValues, formik: FormikContextType<IValues>, field: FieldInputProps<string>) => {
  const qps = question.queryParams?.filter(qp => qp.hint === 'default');
  if (qps?.length) {
    const qp = qps[0];
    if (qp != null && qp.source != null && question.defaultValue != null && qp.name != null) {
      let sourceVal = values && values[getQuestionFormId(qp.source)];
      if (Array.isArray(sourceVal)) sourceVal = sourceVal[0];
      try {
        const defaultObj = JSON.parse(question.defaultValue);
        const defaultVal = defaultObj[qp.name][String(sourceVal)];

        if (Array.isArray(defaultVal) && field.value !== String(defaultVal[0])) {
          formik.setFieldValue(field.name, String(defaultVal[0]));
        }
      } catch (e) {
        console.log(`Error getting ${qp.name} from ${question.defaultValue}`);
        console.log(e);
      }
    }
  }
};

export const FormInput: FormFieldComp = ({ question }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const fieldId = getQuestionFormId(question);
  const [field, meta] = useField<string>(fieldId);
  const formik = useFormikContext<IValues>();
  let { useFloatingLabels } = useContext(AppContext);
  const error = isInvalid(meta);
  const { disabled, isReadOnly } = formik.status as FormStatus;
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const histCtl = useDialogController(false);
  const cp = UserHelper.isAdvisorOrAdmin(userCtx.user) ? question.userProperty : undefined;
  const handleHistoryClick = async () => histCtl.controller.setOpen();

  const { displayAnswerAsHTML: htmlAns } = question;

  const inputProps = {
    error,
    multiline: question.rows ? question.rows > 1 : false,
    rows: question.rows,
    type: question.questionType === FormQuestionType.PASSWORD ? 'password' : 'text',
    disabled: disabled || isReadOnly || question.isReadOnly,
    ...field,
    helperText: helperText({ hint: question.hint, meta }),
    InputProps: cp ? { endAdornment: <InputAdornment onClick={handleHistoryClick} style={{cursor: 'pointer'}} position="end"><History /></InputAdornment> } : undefined
  };

  // TODO: Make the length at which labels become static an application setting.
  if ((question.text.text?.length ?? 0) > 70) useFloatingLabels = false;

  updateDefaultValue(question, formik.values, formik, field);

  const answer = (formik.status?.ans as DTO.FormSubmissionAnswer[])?.find((a: DTO.FormSubmissionAnswer) => a.question.id === question.id);
  const translation = answer?.translation;
  const submittedAns = answer?.answer;
  const correctAnswer = formik.status.showCorrect && answer?.correctAnswer;
  const showTranslation = formik.status?.showTranslation;

  useFocusOnError(fieldRef, inputProps.name);

  console.log(`is html answer ${htmlAns}`);

  return htmlAns
    ? <FormInformation question={question} />
    : useFloatingLabels
      ? (
        <>
          <TextField fullWidth {...inputProps} label={<LanguageString languageString={question.text} />} ref={fieldRef} />
          {showTranslation && translation && <Alert style={{ marginTop: '8px' }} severity="info"><AlertTitle>Translation</AlertTitle>{translation}</Alert>}
          {correctAnswer && correctAnswer !== submittedAns && <Alert style={{ marginTop: '8px' }} severity={correctAnswer === submittedAns ? 'success' : 'error'}>{correctAnswer}</Alert>}
          <PropertyHistoryDialog userId={studentCtx?.student.id} propertyId={cp?.id} ctrl={histCtl} />
        </>
      )
      : (
        <FormControl disabled={disabled} fullWidth>
          <FormLabel htmlFor={fieldId}>
            <LanguageString languageString={question.text} />
          </FormLabel>
          <TextField id={fieldId} aria-describedby={`${fieldId}-helper-text`} {...inputProps} ref={fieldRef} />
          {showTranslation && translation && <Alert style={{ marginTop: '8px' }} severity="info"><AlertTitle>Translation</AlertTitle>{translation}</Alert>}
          {correctAnswer && correctAnswer !== submittedAns && <Alert style={{ marginTop: '8px' }} severity={correctAnswer === submittedAns ? 'success' : 'error'}>{correctAnswer}</Alert>}
          <PropertyHistoryDialog userId={studentCtx?.student.id} propertyId={cp?.id} ctrl={histCtl} />
        </FormControl>
      );
};
