import { CircularProgress, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { LanguageString } from '..';
import { Attachment } from '../../dto';
import FileBoxDeleteDialog, { FileBoxDeleteDialogProps } from './FileBoxDeleteDialog';
import { FileTableRow } from './FileBoxRow';
import { FileTableDropzone } from './Dropzone';
import { FileTableUploadRow } from './FileTableUploadRow';

export enum FileTableSortColumn {
  UPLOADDATE = 'UploadDate',
  UPLOADER = 'Uploader',
  FILENAME = 'FileName'
}

export enum FileTableColumn {
  FILENAME = 0,
  DESCRIPTION = 1,
  UPLOADER = 2,
  FILETYPE = 3,
  UPLOADDATE = 4
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SortInfo {
  order: SortDirection;
  column: FileTableSortColumn;
}

export const compare = (a: string | undefined, b: string | undefined, order: SortDirection) => {
  const multiplier = ((order === SortDirection.ASC) ? 1 : -1);
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return a.localeCompare(b) * multiplier;
};

export interface RowOverride {
  row: FileTableColumn;
  override: boolean;
}

interface FileTableProps {
  fileList: Attachment[];
  fileowner: number;
  lockedFileTypeId?: number;
  sort?: SortInfo;
  deleteElement?: (attachmentId: number, deleteReason: string) => void;
  upload?: (file: File, description: string, fileTypeId: number, userId: number) => Promise<void>;
  handleRequestSort?: (request: FileTableSortColumn) => void;
  newUpload?: File;
  rowShowOverrides?: RowOverride[];
  skipUploadRow?: boolean;
  overrideTypeDeleteLogic?: boolean;
}

const useStyles = makeStyles({
  descriptionField: {
    width: '35%'
  },
  nameField: {
    width: '20%'
  },
  uploaderField: {
    width: '10%'
  },
  fileTypeField: {
    width: '10%'
  },
  uploadDateField: {
    width: '15%'
  },
  actionsField: {
    width: '10%'
  },
  loadingTableContainer: {
    position: 'relative'
  },
  loadingSpinner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#40404080',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const FileTable: React.FC<FileTableProps> = (props) => {
  const loading = false;
  const [uploading, setUploading] = useState<File[]>([]);
  const [deletingFile, setDeletingFile] = useState<FileBoxDeleteDialogProps>();
  const [deleteDialogDisplay, setDeleteDialogDisplay] = useState(false);
  const [lastNewUpload, setLastNewUpload] = useState<File | undefined>();
  const styles = useStyles();

  const calculateHidden = (overrides: RowOverride[] | undefined) => {
    const rows = [true, true, true, true, true];
    if (overrides) {
      for (const el of overrides) {
        rows[el.row] = el.override;
      }
    }
    return rows;
  };

  // normally we will only have to do this once
  const shownRows = useMemo(() => calculateHidden(props.rowShowOverrides), [props.rowShowOverrides]);

  const addUpload = (file: File) => {
    if (props.skipUploadRow) {
      void uploadFile(file, '', 0, props.fileowner);
    } else {
      setUploading(uploading.concat(file));
    }
  };

  // This section watches for new upload property to change, and when it does, we insert a new upload row.
  if (props.newUpload !== lastNewUpload) {
    if (props.newUpload) {
      addUpload(props.newUpload);
    }
    setLastNewUpload(props.newUpload);
  }

  const deleteElement = (attachmentId: number, deleteReason: string) => {
    if (props.deleteElement) {
      props.deleteElement(attachmentId, deleteReason);
    }
    closeDeleteDialog();
  };

  const uploadFile = async (file: File, description: string, fileTypeId: number, fileowner: number) => {
    if (!props.upload) {
      return;
    }
    await props.upload(file, description, fileTypeId, fileowner).then(() => {
      destroyUploadRow(file);
    });
  };

  const activateDeleteDialog = (att: Attachment) => {
    const prop = { attachment: att, close: closeDeleteDialog, deleteElement: deleteElement };
    setDeletingFile(prop);
    setDeleteDialogDisplay(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogDisplay(false);
  };

  const destroyUploadRow = (s: File) => {
    setUploading(() => {
      uploading.splice(uploading.indexOf(s), 1);
      return [...uploading];
    });
  };

  const handleRequestSort = (column: FileTableSortColumn) => {
    if (props.handleRequestSort) {
      props.handleRequestSort(column);
    }
  };

  const determineSort = (column: FileTableSortColumn) => {
    if (!props.sort) {
      return undefined;
    }
    if (props.sort.column !== column) {
      return undefined;
    } else {
      return props.sort.order;
    }
  };

  return (
    <>
      <TableContainer className={styles.loadingTableContainer}>
        {loading && (
          <div
            className={styles.loadingSpinner}
          >
            <CircularProgress color="primary" />
          </div>
        )}
      </TableContainer>

      <Grid spacing={2}>
        <Table>
          <TableHead>
            <TableRow>
              {shownRows[FileTableColumn.FILENAME] && (
                <TableCell
                  className={styles.nameField}
                  sortDirection={determineSort(FileTableSortColumn.FILENAME)} // Aria label
                >
                  <TableSortLabel
                    active={props.sort?.column === FileTableSortColumn.FILENAME}
                    direction={determineSort(FileTableSortColumn.FILENAME)}
                    onClick={() => handleRequestSort(FileTableSortColumn.FILENAME)}
                    disabled={!props.handleRequestSort}
                  >
                    <LanguageString groupName="FILEBOX" resourceName="FILENAME" defaultText="File Name" />
                  </TableSortLabel>
                </TableCell>
              )}
              {shownRows[FileTableColumn.DESCRIPTION] && (
                <TableCell className={styles.descriptionField}>
                  <LanguageString groupName="FILEBOX" resourceName="DESCRIPTION" defaultText="Description" />
                </TableCell>
              )}
              {shownRows[FileTableColumn.UPLOADER] && (
                <TableCell
                  className={styles.uploaderField}
                  sortDirection={determineSort(FileTableSortColumn.UPLOADER)} // Aria label
                >
                  <TableSortLabel
                    active={props.sort?.column === FileTableSortColumn.UPLOADER}
                    direction={determineSort(FileTableSortColumn.UPLOADER)}
                    onClick={() => handleRequestSort(FileTableSortColumn.UPLOADER)}
                    disabled={!props.handleRequestSort}
                  >
                    <LanguageString groupName="FILEBOX" resourceName="UPLOADER" defaultText="Uploader" />
                  </TableSortLabel>
                </TableCell>
              )}
              {shownRows[FileTableColumn.FILETYPE] && (
                <TableCell className={styles.fileTypeField}>
                  <LanguageString groupName="FILEBOX" resourceName="FILETYPE" defaultText="File Type" />
                </TableCell>
              )}
              {shownRows[FileTableColumn.UPLOADDATE] && (
                <TableCell
                  sortDirection={determineSort(FileTableSortColumn.UPLOADDATE)} // Aria label
                  className={styles.uploadDateField}
                >
                  <TableSortLabel
                    active={props.sort?.column === FileTableSortColumn.UPLOADDATE}
                    direction={determineSort(FileTableSortColumn.UPLOADDATE)}
                    onClick={() => handleRequestSort(FileTableSortColumn.UPLOADDATE)}
                    disabled={!props.handleRequestSort}
                  >
                    <LanguageString groupName="FILEBOX" resourceName="UPLOAD_DATE" defaultText="Upload Date" />
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell className={styles.actionsField}>
                <LanguageString groupName="FILEBOX" resourceName="ACTIONS" defaultText="Actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fileList.map(attachment => {
              return (
                <FileTableRow
                  key={attachment.id}
                  attachment={attachment}
                  deleteFile={activateDeleteDialog}
                  shownRows={shownRows}
                  disableDelete={(props.deleteElement === undefined)}
                  overrideTypeDeleteLogic={props.overrideTypeDeleteLogic}
                />);
            })}
          </TableBody>
        </Table>
        {props.upload && (
          <>
            <Table>
              <TableBody>
                {uploading.map(s => {
                  return (<FileTableUploadRow
                    key={s.name}
                    file={s}
                    fileowner={props.fileowner}
                    lockedFileTypeId={props.lockedFileTypeId}
                    destroy={destroyUploadRow}
                    uploadFile={uploadFile}
                  />);
                })}
              </TableBody>
            </Table>
            <Grid item>
              <FileTableDropzone callback={async (file) => addUpload(file) } />
            </Grid>
          </>
        )}
      </Grid>
      {deleteDialogDisplay && deletingFile && (<FileBoxDeleteDialog {...deletingFile} />)}
    </>
  );
};
