import { gql, useApolloClient } from '@apollo/client';
import { ValidateQuestionInput, FormError } from 'tuapath-common/generated/schema';
import { QueryOptions } from 'apollo-client';

export function useFieldValidator() {
  const client = useApolloClient();

  return async (options: Omit<QueryOptions<{ input: ValidateQuestionInput }>, 'query'>) => {
    const validateQuestionMutation = gql`
      query validateQuestion($input: ValidateQuestionInput!) {
        validateQuestion(input: $input) {
          questionId
          errorMessage
        }
      }`;

    const results = await client.query<{ validateQuestion: FormError }, { input: ValidateQuestionInput }>({
      ...options,
      query: validateQuestionMutation
    });

    return results;
  };
}
