import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Checkbox as MuiCheckbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  MenuItem,
  Radio,
  Typography
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import React, { ChangeEventHandler, Fragment, useState } from 'react';
import { Checkbox, DialogTitle, InputBase, LanguageString, LSTextField, TextField } from '..';
import { OValues, QValues, TypedReflect } from '../../common';
import { tmpId } from '../../contexts';
import { FormOptionType, FormQuestionType, FormRatingQuestionOrientation } from '../../dto';
import { DialogController, useCustomProperties } from '../../hooks';
import { KeyPress } from './common';

type QuestionOptionProps = {
  name: string;
  checked: boolean;
  single: boolean;
  autoFocus?: boolean;
  hasCodes?: boolean;
  onDeleteClick?: () => void;
  onEnterPress?: () => void;
  onChange?: () => void;
};

type EditQuestionDialogProps = DialogController & {
  question: QValues;
  fieldName: string;
};

const useStyles = makeStyles(theme =>
  createStyles({
    optionsList: {
      width: '100%'
    }
  })
);

const QuestionOption: React.FC<QuestionOptionProps> = ({ name, checked, single, onDeleteClick, onEnterPress, onChange, autoFocus, hasCodes }) => {
  const handleKeyPress: KeyPress = event => {
    if (onEnterPress != null && event.key === 'Enter') onEnterPress();
  };

  const correctIndicator = single ? <Radio checked={checked} onChange={onChange} /> : <MuiCheckbox checked={checked} onChange={onChange} />;
  const deleteItem = (
    <IconButton onClick={onDeleteClick}>
      <Icon>delete</Icon>
    </IconButton>
  );

  return (
    <ListItem style={{ paddingTop: 0, paddingBottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <InputBase name={name} startAdornment={correctIndicator} endAdornment={deleteItem} onKeyPress={handleKeyPress} autoFocus={autoFocus} />
      {hasCodes && <TextField name={name.replace(/(.*)\.text$/, `\$1.code`)} label="Code" /> }
    </ListItem>
  );
};

const QuestionOptionsInner: React.FC<FieldArrayRenderProps> = ({ name, remove, push }) => {
  const formik = useFormikContext<QValues>();
  // TODO: Hack to get the 'Question' object for these options
  const qFieldName = name.substr(0, name.lastIndexOf('.'));
  const [{ value: question }] = useField<QValues>(qFieldName);
  const { options } = question;
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState<OValues | undefined>();
  const [hasCodes, setHasCodes] = useState<boolean>(options?.some(opt => opt.code != null) ?? true);
  let answers = question.answer ? (JSON.parse(question.answer) as number[]) : [];
  if (!Array.isArray(answers)) answers = [answers];

  const handleAddOption = () => {
    const newOption = {
      id: String(tmpId.nextId),
      text: ''
    };

    push(newOption);
    setSelectedOption(newOption);
  };

  const updateAnswer = (index: number) => {
    answers.includes(index) ? answers.splice(answers.indexOf(index), 1) : answers.push(index);
    answers.sort((a, b) => a - b);
    formik.setFieldValue(`${qFieldName}.answer`, JSON.stringify(answers));
  };

  return (
    <Grid item>
      <List
        className={classes.optionsList}
        subheader={
          <ListSubheader>
            <Grid container style={{alignItems: 'center', gap: '24px'}}>
              <Typography>Answers</Typography>
              <FormControlLabel control={<MuiCheckbox onChange={(_, checked) => setHasCodes(checked)} />} checked={hasCodes} label="Has Codes" />
              <Box ml="auto" clone>
                <Button style={{ marginTop: 0 }} onClick={handleAddOption}>Add</Button>
              </Box>
            </Grid>
          </ListSubheader>
        }
        dense
      >
        {options?.map((o, index) => (
          <QuestionOption
            key={o.id}
            name={`${name}[${index}].text`}
            checked={answers.includes(index)}
            single={question.questionType === FormQuestionType.SELECT}
            onChange={() => updateAnswer(index)}
            // TODO: write a 'remove' function that updates answer indexes (if needed) and then calls 'remove'
            onDeleteClick={() => remove(index)}
            onEnterPress={index === options.length - 1 ? handleAddOption : undefined}
            autoFocus={selectedOption?.id === o.id}
            hasCodes={hasCodes}
          />
        ))}
      </List>
    </Grid>
  );
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
const QuestionOptions: React.FC<FieldArrayRenderProps | void> = QuestionOptionsInner as React.FC<FieldArrayRenderProps | void>;

const DateAnswer: React.FC<{ name: string }> = ({ name: fieldName }) => {
  const [field] = useField<QValues>(fieldName);

  const handleChange = () => {
    console.log('date change');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker fullWidth {...field} label="Answer" onChange={handleChange} />
    </MuiPickersUtilsProvider>
  );
};

const QuestionAnswers: React.FC<{ name: string }> = ({ name: fieldName }) => {
  const [{ value: question }] = useField<QValues>(fieldName);

  switch (question.questionType) {
    case FormQuestionType.TEXT:
      return <TextField item name={`${fieldName}.answer`} label="Answer" />;
    case FormQuestionType.MULTISELECT:
    case FormQuestionType.SELECT:
      return question.optionType === FormOptionType.STATIC ? (
        <FieldArray name={`${fieldName}.options`} component={QuestionOptions} />
      ) : (
        <div>Cannot edit dynamic options yet</div>
      );
    case FormQuestionType.DATE:
    case FormQuestionType.DATETIME:
    case FormQuestionType.DUEDATE:
    case FormQuestionType.TIME:
      return (
        <Grid item>
          <DateAnswer name={`${fieldName}.answer`} />
        </Grid>
      );
    default:
      return null;
  }
};

type OptionProps = { fieldName: string };
const TextLengthOptions: React.FC<OptionProps> = ({ fieldName }) => (
  <Fragment>
    <TextField item name={`${fieldName}.minLength`} label="Min Length" type="number" />
    <TextField item name={`${fieldName}.maxLength`} label="Max Length" type="number" />
    <TextField item name={`${fieldName}.match`} label="Validation Pattern" />
  </Fragment>
);

const SelectOptionType: React.FC<OptionProps> = ({ fieldName }) => (
  <Grid item>
    <TextField name={`${fieldName}.optionType`} label="Option Type" select style={{ textTransform: 'capitalize' }}>
      {TypedReflect.ownKeys(FormOptionType).map((key) => (
        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
          {key.toLowerCase()}
        </MenuItem>
      ))}
    </TextField>
  </Grid>
);

const RatingOptions: React.FC<OptionProps> = ({ fieldName }) => (
  <Fragment>
    <TextField item name={`${fieldName}.minRateLevel`} type="number" label="min levels" />
    <TextField item name={`${fieldName}.rateLevels`} type="number" label="levels" />
    <LSTextField item name={`${fieldName}.lowText`} label="Low Text" />
    <LSTextField item name={`${fieldName}.highText`} label="High Text" />
    <TextField item name={`${fieldName}.orientation`} label="Orientation" select style={{ textTransform: 'capitalize' }}>
      {TypedReflect.ownKeys(FormRatingQuestionOrientation).map(key => (
        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
          {key.toLowerCase()}
        </MenuItem>
      ))}
    </TextField>
  </Fragment>
);

// TODO: Remove all except one answer when swithcing from 'multi' to 'single' select.
export const EditQuestionDialog: React.FC<EditQuestionDialogProps> = ({ fieldName, props, controller }) => {
  const formik = useFormikContext<{ [name: string]: string }>();
  const [{ value: qType }] = useField<FormQuestionType>(`${fieldName}.questionType`);
  const { loading, data } = useCustomProperties();

  const handleTypeChange: ChangeEventHandler<HTMLInputElement> = () => {
    formik.setFieldValue(`${fieldName}.answer`, undefined);
    if (qType !== FormQuestionType.SELECT && qType !== FormQuestionType.MULTISELECT) {
      formik.setFieldValue(`${fieldName}.options`, undefined);
    }
  };

  return loading ? (
    <CircularProgress color="primary" />
  ) : (
    <Dialog {...props} maxWidth="md" fullWidth>
      <DialogTitle onClose={controller.setClose}>Edit Question</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} direction="column">
          <TextField item name={`${fieldName}.name`} label="Name" />
          <LSTextField item name={`${fieldName}.text`} label="Text" />
          <LSTextField
            item
            name={`${fieldName}.hint`}
            label={<LanguageString groupName="FORM_EDITOR" resourceName="QUESTION_HINT" defaultText="Hint" />}
          />
          <TextField item name={`${fieldName}.questionType`} label="Type" select style={{ textTransform: 'capitalize' }} onChange={handleTypeChange}>
            {TypedReflect.ownKeys(FormQuestionType).map(key => (
              <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                {key.toLowerCase()}
              </MenuItem>
            ))}
          </TextField>
          {qType === FormQuestionType.TEXT && <TextField item name={`${fieldName}.rows`} label="Rows" type="number" />}
          {(qType === FormQuestionType.TEXT || qType === FormQuestionType.PASSWORD) && <TextLengthOptions fieldName={fieldName} />}
          {(qType === FormQuestionType.TEXT || qType === FormQuestionType.PASSWORD) && <Checkbox item name={`${fieldName}.encrypt`} label="Encrypt" />}
          {(qType === FormQuestionType.TEXT || qType === FormQuestionType.PASSWORD) && <Checkbox item name={`${fieldName}.transient`} label="Prevent Storing Answer" />}
          {(qType === FormQuestionType.SELECT || qType === FormQuestionType.MULTISELECT) && <SelectOptionType fieldName={fieldName} />}
          {qType === FormQuestionType.RATING && <RatingOptions fieldName={fieldName} />}
          <TextField item name={`${fieldName}.userPropertyId`} label="User Property" select style={{ textTransform: 'capitalize' }}>
            {data?.site.customProperties?.map(cp => (
              <MenuItem key={cp.id} value={cp.id} style={{ textTransform: 'capitalize' }}>
                {cp.name}
              </MenuItem>
            ))}
          </TextField>
          <Grid item>
            <Checkbox name={`${fieldName}.isRequired`} label="Required" />
          </Grid>
          <QuestionAnswers name={fieldName} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
