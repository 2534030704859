import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { RoadmapMilestonesResponse } from '../../dto';

type Variables = { userId: number };
type Results = {
  completedRoadmapMilestones: RoadmapMilestonesResponse;
};

export const useCompletedMilestones = (options: QueryHookOptions<Results, Variables> & { variables: Variables }) => {
  const query = gql`
    query getCompletedRoadmapMilestones($userId: Int!) {
      completedRoadmapMilestones(userId: $userId, limit: 50, offset: 0) {
        totalCount
        roadmapMilestones {
          id
          milestoneId
          tasks {
            id
            name {
              id
              text
            }
          }
        }
      }
    }
  `;

  return useQuery(query, options);
};

export default useCompletedMilestones;
