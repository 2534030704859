import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MilestoneGroup, UpdateMilestoneGroupsMutationArgs } from '../../dto';

type Result = { updateMilestoneGroups: MilestoneGroup[] };
type Variables = UpdateMilestoneGroupsMutationArgs;
export function useUpdateMilestoneGroup(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    mutation updateMilestoneGroup($input: [UpdateMilestoneGroupInput]!) {
      updateMilestoneGroups(input: $input) {
        id
        name {
          id
          text
          isHtml
        }
        description {
          id
          text
          isHtml
        }
        color
        sortIndex
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateMilestoneGroup;
