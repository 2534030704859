import React, {
  useState,
  useContext,
  useEffect
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DynamicForm } from '../DynamicForm';
import * as DTO from '../../dto';
import { SiteContext } from '../../contexts';
import { useAccountSetup } from '../../hooks';

export const AccountSetup: React.FC = () => {
  const [accountSetup] = useAccountSetup();
  const history = useHistory();
  const location = useLocation();
  const siteCtx = useContext(SiteContext);
  const [regToken, setRegToken] = useState('');
  // const [registerSuccess, setRegisterSuccess] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<String>('');

  useEffect(() => {
    if (location) {
      setRegToken(location.pathname.slice(location.pathname.lastIndexOf('/') +1, location.pathname.length));
    }
  }, []);

  async function submitAccountSetup(formInput: DTO.SubmitFormInput) {

    let answers: DTO.FormAnswerInput[] = [];

    if (formInput.answers) {
      answers = formInput.answers;
    }

    await accountSetup({
      variables: {
        input: {
          registerToken: regToken,
          answers: answers
        }
      },
      update: (cache, result) => {
        let error = '';

        if (result.data) {
          if (!result.data.accountSetupCompletion.success) {
            if (result.data.accountSetupCompletion.reason) {
              switch (result.data.accountSetupCompletion.reason) {
                case DTO.RegistrationFailureReason.usernameAlreadyExists:
                  error = 'Username already used';
                  break;
                case DTO.RegistrationFailureReason.passwordBreached:
                  error = 'Password error';
                  break;
                case DTO.RegistrationFailureReason.missingCustomProperty:
                  error = 'Missing property';
                  break;
                case DTO.RegistrationFailureReason.validationError:
                  error = 'Validation Error';
                  break;
                case DTO.RegistrationFailureReason.serverError:
                  error = 'Server Error';
                  break;
                default:
                  error = 'Server Error default';
                  break;
              }
            } else {
              error = 'An unknown error occured';
            }
          }
        } else {
          error = 'An unknown error occured';
        }

        setServerErrorMessage(error);
        if (error.length > 0) {
          // setRegisterSuccess(false);
        } else {
          history.push('/login');
        }
      }
    });

    return;
  };


  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <DynamicForm
          formId={siteCtx.site?.userEndRegistrationForm?.id}
          autoloadFormSubmission={true}
          overrideSubmitHandler={submitAccountSetup}
          renderFormTitle={false}
        />
      </Grid>

      {serverErrorMessage.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="error">{serverErrorMessage}</Alert>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AccountSetup;
