import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Icon,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TransitionProps } from '@material-ui/core/transitions';
import { ApolloError } from 'apollo-client';
import { format, fromUnixTime } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { MTSContext } from '../../contexts';
import * as DTO from '../../dto';
import MTSHelpers from '../../helpers/mtsHelpers';
import { useClasses, useDialogController, useMTSDeleteEntry } from '../../hooks';
import { DialogTitle, LanguageString } from '../Common';
import MTSAddActivityDialog from './MTSAddActivity/MTSAddActivityDialog';

const DialogTransition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MTSDialog: React.FC = () => {
  const classes = useClasses();
  const history = useHistory();
  const mtsCtx = useContext(MTSContext);
  const theme = useTheme();

  const handleClose = () => history.goBack();
  const { props: dProps } = useDialogController(true, handleClose);
  const mtses = mtsCtx.mtses ?? [];
  const activityTypes: DTO.MTSActivityType[] = mtsCtx.activityTypes ?? [];
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [readonlyMode, setReadonlyMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [activeMTSEntry, setActiveMTSEntry] = useState<DTO.MTSActivity | undefined>(undefined);
  const [deleteMTS] = useMTSDeleteEntry();

  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const match = useRouteMatch<{ selectedDay?: string }>();
  const selectedDay = match.params.selectedDay ? Number(match.params.selectedDay) : undefined;
  const date = selectedDay ? fromUnixTime(selectedDay) : new Date();
  const activities = MTSHelpers.mtsEntryForDate(date, mtses, mtsCtx.mtsPeriod);

  const toggleAddModal = () => {
    setReadonlyMode(false);
    if (addModalOpen) {
      setActiveMTSEntry(undefined);
    }
    setAddModalOpen(!addModalOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const confirmDeleteMTS = () => {
    if (activeMTSEntry) {
      toggleDeleteModal();

      deleteMTS({
        variables: {
          id: activeMTSEntry.id
        },
        update: (cache, result) => {
          setActiveMTSEntry(undefined);
        },
        refetchQueries: ['mtsEntries', 'mtsActivityType']
      }).catch(res => {
        const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
          return error.message;
        });

        if (errors.length > 0) {
          setErrorMessage(errors[0]);
        }
      });
    }
  };

  const deleteButtonPressed = (entry: DTO.MTSActivity) => {
    setActiveMTSEntry(entry);
    setDeleteModalOpen(true);
  };

  const totalHours = () => {
    let total = 0;
    if (activities) {
      for (const activity of activities) {
        total += activity.hours;
      }
    }

    return total;
  };

  if (!selectedDay) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="md" fullScreen={fullscreen} scroll="paper" TransitionComponent={DialogTransition} {...dProps}>
      <DialogTitle onClose={handleClose}>
        {format(date, 'MM/dd')} - <LanguageString groupName="MTS" resourceName="MTS_ENTRIES" defaultText="MTS Entries" />
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs>
            {errorMessage && (
              <Grid container>
                <Grid item xs>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              </Grid>
            )}

            <Table className={classes.stripedTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <LanguageString groupName="MTS" resourceName="ACTIVITY" defaultText="Activity" />
                  </TableCell>
                  <TableCell>
                    <LanguageString groupName="MTS" resourceName="HOURS" defaultText="Hours" />
                  </TableCell>
                  <TableCell>
                    <LanguageString groupName="MTS" resourceName="LAST_EDITED" defaultText="Last Edited By" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {activities && activities.length > 0 && (
                  <>
                    {activities.map(mts => (
                      <TableRow key={`mts_activity_${mts.id}`}>
                        <TableCell>
                          <Typography style={{ fontStyle: mts.userType?.expiring === true ? 'italic' : 'normal' }}>
                            <LanguageString languageString={mts.userType?.name ?? mts.type.name} />
                          </Typography>
                        </TableCell>
                        <TableCell>{mts.hours}</TableCell>
                        <TableCell>{mts.lastEditedBy ? mts.lastEditedBy.firstName + ' ' + mts.lastEditedBy.lastName : ''}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setReadonlyMode(true);
                              setActiveMTSEntry(mts);
                              setAddModalOpen(true);
                            }}
                          >
                            <Icon>visibility_outlined</Icon>
                          </IconButton>
                          {mtsCtx.canChangeSelectedMTS && (
                            <IconButton
                              onClick={() => {
                                setActiveMTSEntry(mts);
                                setAddModalOpen(true);
                              }}
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                          )}
                          {mtsCtx.canChangeSelectedMTS && (
                            <IconButton
                              onClick={() => {
                                deleteButtonPressed(mts);
                              }}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={`mts_total_row`}>
                      <TableCell className={classes.bold}>
                        <LanguageString groupName="MTS" resourceName="MTS_TOTAL_HOURS" defaultText="Total" />
                      </TableCell>
                      <TableCell className={classes.bold}>{totalHours()}</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>

            <Dialog open={deleteModalOpen} onClose={toggleDeleteModal} aria-describedby="alert-dialog-description">
              <DialogTitle onClose={toggleDeleteModal}>
                <LanguageString groupName="MTS" resourceName="DELETE_CONFIRMATION_TITLE" defaultText="Delete Entry" />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <LanguageString groupName="MTS" resourceName="DELETE_CONFIRMATION_MESSAGE" defaultText="Are you sure you'd like to delete this entry?" />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleDeleteModal} color="primary">
                  Cancel
                </Button>
                <Button onClick={confirmDeleteMTS} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {mtsCtx.canChangeSelectedMTS && (
          <Button onClick={toggleAddModal} color="primary" variant="contained">
            <LanguageString groupName="MTS" resourceName="ADD_ACTIVITY" defaultText="Add Activity" />
          </Button>
        )}
      </DialogActions>

      <MTSAddActivityDialog
        open={addModalOpen}
        toggle={toggleAddModal}
        date={date}
        activityTypes={activityTypes}
        readonly={readonlyMode}
        refreshQueries={['mtsById', 'mtsUserActivityType']}
        mtsEntryId={activeMTSEntry?.id}
      />
    </Dialog>
  );
};

export default MTSDialog;
