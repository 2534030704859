import gql from 'graphql-tag';
import { languageStringFrag } from './languageStringFrag';

export const formSubmissionFrag = gql`
  ${languageStringFrag}

  fragment FormSubmissionFrag on FormSubmission {
    id
    percentComplete
    percentCorrect
    startedAt
    modifiedAt
    completedAt
    form {
      id
    }
    submittedBy {
      id
      firstName
      lastName
      username
    }
    answers {
      id
      question {
        id
        name
        questionType
        answerType
        answer
        text {
          ...LangStringFrag
        }
        options {
          id
          text
        }
      }
      answer
      translation
      originalLanguageId
      selections {
        id
      }
    }
  }
`;

export default formSubmissionFrag;
