import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import React, { useContext, useState, useMemo } from 'react';
import { MTSContext, StudentContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { useMTSAddFile } from '../../../hooks';
import useDeleteFile from '../../../hooks/gql/useDeleteFile';
import { LanguageString } from '../../Common';
import { FileTable, FileTableColumn } from '../../FileBox/FileTable';
import { format } from 'date-fns';
import MTSHelpers from '../../../helpers/mtsHelpers';

interface MTSDocumentsTabProps {
  mts?: DTO.MTS;
  showTitle?: boolean;
}

export const MTSDocumentsTab: React.FC<MTSDocumentsTabProps> = props => {
  const studentCtx = useContext(StudentContext);
  const mtsCtx = useContext(MTSContext);
  const [addFile] = useMTSAddFile();
  const [deleteFile] = useDeleteFile();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const mts = props.mts ? props.mts : mtsCtx.selectedMTS;

  const titleForMTS = useMemo(() => {
    if (mts) {
      if (mts?.mtsPeriod) {
        return `${format(MTSHelpers.dateFromString(mts.mtsPeriod.startDate), 'MM/dd/yyyy')} - ${format(MTSHelpers.dateFromString(mts.mtsPeriod.endDate, true), 'MM/dd/yyyy')}`;
      } else {
        return `${mts.month}/${mts.year}`;
      }
    }

    return '';
  }, [mts]);

  const uploadFile = async(file: File, description: string, fileTypeId: number, fileowner: number) => {
    if (((mtsCtx.month && mtsCtx.year) || mtsCtx.mtsPeriod) && studentCtx?.student.id) {
      addFile({
        variables: {
          input: {
            file: file,
            month: mtsCtx.month,
            year: mtsCtx.year,
            periodId: mtsCtx.mtsPeriod?.id,
            userId: studentCtx.student.id
          }
        },
        refetchQueries: ['mtsEntries']
      }).catch(res => {
        const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
          return error.message;
        });

        if (errors.length > 0) {
          setErrorMessage(errors[0]);
        }
      });
    }
  };

  const deleteElement = async (attachmentId: number, deleteReason: string) => {
    await deleteFile({
      variables: {
        attachmentId: attachmentId,
        deleteReason: deleteReason,
        allowDeleteSpecialTypes: true
      },
      refetchQueries: ['mtsEntries']
    });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              props.showTitle === true ? (
                <Box padding={2}>
                  <Typography variant="h5" color="textSecondary">
                    <Typography variant="h6" color="textPrimary">
                      <LanguageString groupName="MTS" resourceName="MTS_FILES" defaultText="MTS Files" />
                    </Typography>
                    {titleForMTS}
                  </Typography>
                </Box>
              ) :
              <LanguageString groupName="MTS" resourceName="MTS_FILES" defaultText="MTS Files" />
            }
          />
          <CardContent>
            {studentCtx && (
              <FileTable
                fileList={mts?.attachments ?? []}
                fileowner={studentCtx.student.id}
                upload={mtsCtx.canUploadSelectedMTS ? uploadFile : undefined}
                deleteElement={mtsCtx.canChangeSelectedMTS ? deleteElement : undefined}
                rowShowOverrides={[{ row: FileTableColumn.DESCRIPTION, override: false }, { row: FileTableColumn.FILETYPE, override: false }]}
                skipUploadRow
                overrideTypeDeleteLogic
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      {errorMessage && (
        <Grid item xs={12} style={{ marginBottom: 30 }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default MTSDocumentsTab;
