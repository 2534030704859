import gql from 'graphql-tag';

export const mtsPeriodGroupFrag = gql`
  fragment MTSPeriodGroupFrag on MTSPeriodGroup {
    id
    startMonth
    startYear
    endMonth
    endYear
  }
`;
