import { Role, User, CustomPropertyNames, Task } from '../dto';

export enum MilestoneState {
  NOT_STARTED,
  STARTED,
  COMPLETE,
  NA
}

export default class UserHelper {
  static isAdmin(user: User): boolean {
    return this.userHasRole(user, Role.ADMIN);
  }
  static isAdvisorOrAdmin(user: User): boolean {
    const admin = this.userHasRole(user, Role.ADMIN);
    if (!admin) {
      return this.userHasRole(user, Role.ADVISOR);
    }

    return admin;
  }

  static userHasRole = (user: User, role: Role) => user.roles?.find(r => r === role) != null;
  static customProperty = (user: User, name: string) => user.customProperties?.find(ucp => ucp.type?.name === name)?.value;

  static taskStatus(user: User, msId: number, task: Task, rmMsId: number | undefined): MilestoneState {
    const assignment = user.assignments?.assignments?.find(a => a.milestoneId === msId && a.taskId === task.id && a.roadmapMilestoneId === (rmMsId ?? null));

    return assignment?.isComplete ? MilestoneState.COMPLETE : assignment?.startedAt ? MilestoneState.STARTED : MilestoneState.NOT_STARTED;
  }

  static milestoneStatus(user: User, msId: number, rmMsId: number | undefined): MilestoneState {
    const assignments = user.assignments?.assignments?.filter(a => a.milestoneId === msId && a.roadmapMilestoneId === (rmMsId ?? null));
    const isComplete = assignments && assignments.length > 0 ? assignments.every(a => a.isComplete) : false;
    const isStarted = assignments ? assignments.some(a => a.startedAt != null) : false;

    return isComplete ? MilestoneState.COMPLETE : isStarted ? MilestoneState.STARTED : MilestoneState.NOT_STARTED;
  }

  static getCaseId(user: User | undefined): string | undefined {
    if (!user) return undefined;
    return UserHelper.customProperty(user, CustomPropertyNames.caseIDNum);
  }
}
