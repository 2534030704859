import React, { useState, useContext } from 'react';
import {
  Paper,
  Tabs,
  Tab,
  Grid,
  Divider,
  IconButton,
  Icon,
  Tooltip
} from '@material-ui/core';
import { useClasses } from '../../hooks';
import BrowseStudentsSearch from './BrowseStudentsSearch';
import BrowseStudentsTable from './BrowseStudentsTable';
import StudentsFiltersDialog from './StudentsFiltersDialog';
import { LanguageString } from '../Common';
import { SiteContext, StudentsContext } from '../../contexts';

export const BrowseStudentsInner: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const studentsCtx = useContext(StudentsContext);
  const classes = useClasses();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (studentsCtx) {
      studentsCtx.setSearch('');
    }
    setValue(newValue);
  };

  const toggleFilterModal = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  return (
    <Paper>
      <Grid container justify="space-between">
        <Grid item />
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {siteCtx.site?.studentSearchEnabled && (
              <Tab label={<LanguageString groupName="GENERAL" resourceName="STUDENTS_SEARCH" defaultText="Search" />} />
            )}
            <Tab label={<LanguageString groupName="GENERAL" resourceName="STUDENTS_TABLE" defaultText="Table" />} />
          </Tabs>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <LanguageString groupName="STUDENTS" resourceName="FILTERS_TOOLTIP" defaultText="Filters" />
            }
          >
            <IconButton onClick={toggleFilterModal}>
              <Icon>filter_list</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Divider />

      <Grid container>
        <Grid item xs={12} className={classes.browseStudents}>
          {siteCtx.site?.studentSearchEnabled ? (
            <>
              {value === 0 && (
                <BrowseStudentsSearch />
              )}
              {value === 1 && (
                <BrowseStudentsTable />
              )}
            </>
          ) : (
            <>
              {value === 0 && (
                <BrowseStudentsTable />
              )}
            </>
          )}

        </Grid>
      </Grid>

      <StudentsFiltersDialog
        open={filterModalOpen}
        toggle={toggleFilterModal}
        searchMode={value === 0}
      />
    </Paper>
  );
};
export default BrowseStudentsInner;
