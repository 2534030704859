import React, { useContext, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Link,
  Icon,
  Typography
} from '@material-ui/core';
import {
  format
} from 'date-fns';
import { LanguageString, GeneralErrorMessage, DownloadTemplate } from '../../Common';
import { useMTSCompleted } from '../../../hooks';
import { SiteContext, StudentContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { PrintLocation } from 'tuapath-common/generated/schema';
import MTSCompletedDialog from './MTSCompletedDialog';
import MTSHelpers from '../../../helpers/mtsHelpers';

export const MTSCompletedMTSTab: React.FC = props => {
  const siteCtx = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);
  const [completedModalOpen, setCompletedModalOpen] = useState(false);
  const [selectedMTS, setSelectedMTS] = useState<DTO.MTS | undefined>(undefined);

  const handleClickMTS = (mts: DTO.MTS) => {
    setSelectedMTS(mts);
    toggleCompletedModalOpen();
  };

  const toggleCompletedModalOpen = () => {
    setCompletedModalOpen(!completedModalOpen);
  };

  const { data, loading, error } = useMTSCompleted(
    {
      variables: {
        userId: studentCtx?.student.id ?? 0
      }
    }
  );

  return (
    <Card>
      <CardHeader
        title={
          <LanguageString groupName="MTS" resourceName="COMPLETED_MTS" defaultText="Completed MTS" />
        }
      />
      <CardContent>
        {loading && (
          <Grid container>
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        )}

        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <LanguageString groupName="MTS" resourceName="MTS_DATE" defaultText="Date" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <GeneralErrorMessage message="Unknown error occured"/>
                  </TableCell>
                </TableRow>
              )}

              {!error && (
                <>
                  {data?.user.completedMTS?.map(mts => {
                    return (
                      <TableRow key={`completed_mts_${mts.id}`}>
                        {siteCtx.site?.mtsPrintPageEnabled ? (
                          <>
                            <TableCell>
                              <Link
                                onClick={() => {
                                  handleClickMTS(mts);
                                }}
                              >
                                <Typography variant="body1">
                                  {mts.mtsPeriod ?
                                    `${format(MTSHelpers.getPeriodDates(mts.mtsPeriod).startDate, 'MM/dd/yyyy')} -
                                    ${format(MTSHelpers.getPeriodDates(mts.mtsPeriod).endDate, 'MM/dd/yyyy')}` :
                                    format(new Date(mts.year ?? 2020, (mts.month ?? 1) - 1, 1, 0, 0), 'MM/dd/yyyy')
                                  }
                                </Typography>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Grid container justify="flex-end">
                                <Link
                                  onClick={() => {
                                    handleClickMTS(mts);
                                  }}
                                >
                                  <Icon>keyboard_arrow_right</Icon>
                                </Link>
                              </Grid>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <Typography variant="body1">
                                {mts.mtsPeriod ?
                                  `${format(MTSHelpers.getPeriodDates(mts.mtsPeriod).startDate, 'MM/dd/yyyy')} -
                                  ${format(MTSHelpers.getPeriodDates(mts.mtsPeriod).endDate, 'MM/dd/yyyy')}` :
                                  format(new Date(mts.year ?? 2020, (mts.month ?? 1) - 1, 1, 0, 0), 'MM/dd/yyyy')
                                }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Grid container justify="flex-end">
                                <DownloadTemplate
                                  location={PrintLocation.MTS}
                                  relationId={mts.id.toString()}
                                />
                              </Grid>
                            </TableCell>
                          </>
                        )}

                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        )}
      </CardContent>

      <MTSCompletedDialog open={completedModalOpen} toggle={toggleCompletedModalOpen} mts={selectedMTS} />
    </Card>
  );
};

export default MTSCompletedMTSTab;
