import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ChildRoutes } from '../../contexts';
import { Placement } from '../Common';

export const PlacementPage: React.FC = () => {
  const { placementId } = useParams<{ placementId: string }>();

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          {placementId && <Placement groupName={placementId} />}
        </Grid>
      </Grid>

      <ChildRoutes />
    </Container>
  );
};

export default PlacementPage;
