import React from 'react';
import { useRouteMatch } from 'react-router';
import { useStudentsWithId } from '../../hooks';
import { User } from '../../dto';

interface StudentMenuContextProps {
  loading: boolean;
  totalCount: number;
  filteredStudents: User[];
  openStudents: User[];
  selectedStudent?: User;
}

export const StudentsMenuContext = React.createContext<StudentMenuContextProps | undefined>(undefined);

export const StudentsMenuProvider: React.FC = ({ children }) => {

  const studentsMatch = useRouteMatch<{ id?: string; studentId?: string; }>(`/students/:id([^\/a-zA-Z]+)*/(student)?/:studentId?`);

  const getOpenStudentIds = () => {
    let ids: number[] = [];

    if (studentsMatch?.params?.id && studentsMatch?.params.id.length > 0) {
      ids = studentsMatch?.params.id.split('/').map(numStr => {
        return Number(numStr);
      });
    }

    return ids;
  };

  const getSelectedStudentId = () => {
    let student: number | undefined;

    if (studentsMatch?.params?.studentId && studentsMatch?.params.studentId.length > 0) {
      return parseInt(studentsMatch?.params.studentId, 0);
    }

    return student;
  };

  const findSelectedStudent = (passedStudents: User[]) => {
    const id = getSelectedStudentId();

    if (id) {
      for (const student of passedStudents) {
        if (student.id === id) {
          return student;
        }
      }
    }

    return undefined;
  };

  const openStudentIds = getOpenStudentIds();

  const { loading, data, error } = useStudentsWithId({
    variables: {
      studentIds: openStudentIds
    }
  });

  if (error) {
    console.log(`failed to get students with error: ${error}`);
  }

  let totalCount = 0;
  let filteredStudents: User[] = [];

  if (data?.studentsWithId) {
    totalCount = data.studentsWithId.length;
    filteredStudents = data.studentsWithId;
  }

  const openStudents = data?.studentsWithId?.slice() ?? [];
  const allStudents = filteredStudents.concat(openStudents);
  const selectedStudent = findSelectedStudent(allStudents);

  return (
    <StudentsMenuContext.Provider
      value={{
        loading: loading,
        totalCount: totalCount,
        filteredStudents: filteredStudents,
        openStudents: openStudents,
        selectedStudent: selectedStudent
      }}
    >
      {children}
    </StudentsMenuContext.Provider>
  );
};

export default StudentsMenuProvider;
