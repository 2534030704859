import { Button, CircularProgress, Dialog, DialogContent, TableCell, TableRow, Typography } from '@material-ui/core';
import { getUnixTime } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { MTSContext, useChildLinks } from '../../../contexts';
import * as DTO from '../../../dto';
import { useDialogController, useGetMTSEntryById } from '../../../hooks';
import { useApprovalStatus, useMTSById } from '../../../server';
import { DialogTitle, LanguageString, RouterLink } from '../../Common';
import { DynamicFormProps } from '../../DynamicForm';
import { EMA } from '../ExternalMTSApproval/ExternalMTSApproval';
import { ReqExtApprovalDlg } from './MTSSubmitDialog';

const dateString = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

type ExtApprovalInfoDlgProps = {
  dtoMts: DTO.MTS;
  activity: DTO.MTSActivity
  onSubmit?: () => void;
};

export const ExtApprovalInfoDlg: React.FC<ExtApprovalInfoDlgProps> = (props) => {
  const { data, loading } = useGetMTSEntryById({
    variables: {
      entryIds: [props.activity.id]
    }
  });

  const activity = useMemo(() => {
    if (data?.mtsEntryById && data.mtsEntryById.length > 0) {
      return data.mtsEntryById[0];
    }

    return undefined;
  }, [data, loading]);

  if (activity) {
    return <ExtApprovalInfoDlgInner {...props} activity={activity} />;
  }

  return null;
};

const ExtApprovalInfoDlgInner: React.FC<ExtApprovalInfoDlgProps> = ({ dtoMts, activity, onSubmit }) => {
  const activityType = activity.type;
  const userActivityType = activity.userType;
  const { mts, loading: loadingMts } = useMTSById(dtoMts.id, activityType?.id ?? 0, userActivityType?.id);
  const { controller, props } = useDialogController(false);
  const { controller: reqCtrl, props: reqProps } = useDialogController(false);
  const { approvalRequested, loading: statusLoading } = useApprovalStatus(dtoMts.id, activityType?.id ?? 0, userActivityType?.id === 0 ? undefined : userActivityType?.id);

  const approvalSubmission = mts?.entries.entries[0].externalApprovalSubmission;
  const dynFormProps: DynamicFormProps = {
    formInst: mts?.activityTypes[0].externalApprovalForm,
    autoloadFormSubmission: mts?.entries.entries[0].externalApprovalSubmission != null,
    disabled: true
  };
  if (approvalSubmission != null) dynFormProps.formSubmissionInst = approvalSubmission as unknown as DTO.FormSubmission;

  const loading = useMemo(() => loadingMts || statusLoading, [loadingMts, statusLoading]);

  return !activity || loading
    ? <CircularProgress color="primary" />
    : mts != null
      ? (<>
          {approvalRequested === 'approved'
            ? <Button onClick={controller.setOpen}><LanguageString groupName="EXT_MTS_APP" resourceName="SUBMITTED" /></Button>
            : approvalRequested === 'requested'
              ? <Button onClick={reqCtrl.setOpen}><LanguageString groupName="EXT_MTS_APP" resourceName="REQUESTED" /></Button>
              : activity.type.externalApprovalRequired
                ? <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_REQUESTED" />
                : <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_REQUESTED_OPT" />}
          <Dialog {...props} maxWidth="xl" fullWidth>
            <DialogTitle onClose={controller.setClose}>Request Approval</DialogTitle>
            <DialogContent>
              <EMA mts={mts} approvalSubmission={approvalSubmission} dynFormProps={dynFormProps} />
            </DialogContent>
          </Dialog>
          <ReqExtApprovalDlg mts={dtoMts} activity={activity} onClose={reqCtrl.setClose} props={reqProps} onSubmit={onSubmit} />
        </>)
      : <div>No data found</div>;
};

export const MTSReviewRow: React.FC<{ days: Date[], activities: DTO.MTSActivity[], mts: DTO.MTS, hasTypesNeedingApproval: boolean }> = props => {
  const mtsCtx = useContext(MTSContext);
  const { hasTypesNeedingApproval } = props;

  const dayVals = useMemo(() => {
    const vals: { selectedDay: number; str: string; hours: number }[] = [];

    if (mtsCtx.selectedMTS) {
      for (const date of props.days) {
        const str = dateString(date);
        vals.push({ selectedDay: getUnixTime(date), str, hours: props.activities.reduce((pv, cv) => dateString(cv.date as unknown as Date) === str ? pv + cv.hours : pv, 0) });
      }
    }

    return vals;
  }, [mtsCtx.selectedMTS, props.days]);

  const links = useChildLinks({
    items: dayVals,
    paramMap: { selectedDay: 'selectedDay' },
    routeName: 'month-mts-entries'
  });

  const first = props.activities[0];
  const approvalRequired = first.type.externalApprovalFormId != null;

  return (
    <TableRow>
      { hasTypesNeedingApproval &&
        <TableCell style={{ width: 0 }}>{
          approvalRequired
            ? <ExtApprovalInfoDlg dtoMts={props.mts} activity={first} />
            : <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_APPLICABLE" />
        }</TableCell>
      }

      <TableCell>
        <Typography style={{ fontStyle: first.userType?.expiring === true ? 'italic' : 'normal' }}>
          <LanguageString languageString={first.userType?.name ?? first.type.name} />
        </Typography>
      </TableCell>

      {links?.map(ln => (
        <TableCell key={ln.item.selectedDay}>
          <Button component={RouterLink} to={ln.path ?? ''}>
            {ln.item.hours}
          </Button>
        </TableCell>
      ))}

      <TableCell>{props.activities.reduce((pv, cv) => pv + cv.hours, 0)}</TableCell>
    </TableRow>
  );
};

export default MTSReviewRow;
