import { Checkbox, Divider, Icon, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Menu } from '@material-ui/core';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LanguageString } from '..';
import { SiteContext, UserContext } from '../../../contexts';
import { Role } from '../../../dto';
import { useLocalStorage } from '../../../hooks';
import { useUpdateCurrentRole } from '../../../hooks/gql/useUpdateCurrentRole';

interface AccountMenuProps {
  open: boolean;
  anchor: React.RefObject<HTMLElement>;
  toggle: () => void;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({ open, anchor, toggle }) => {
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  const [updateCurrentRole] = useUpdateCurrentRole();
  const [liveEdit, setLiveEdit] = useLocalStorage('LiveEdit', false);

  const updateCurRole = (role: Role) => user != null && updateCurrentRole({ user, role });
  const navRoles = site?.navRoutes?.filter(nr => nr.children?.length).map(nr => nr.role);

  if (user?.roles == null) return null;

  const handleLiveEditChanged = () => setLiveEdit(!liveEdit);

  return (
    <Menu
      id="account-menu"
      anchorEl={anchor.current}
      keepMounted
      open={open}
      onClose={toggle}
      PaperProps={{
        style: {
          width: 200
        }
      }}
    >
      <List>
        <ListSubheader><LanguageString groupName="ACCOUNT" resourceName="ROLES" /></ListSubheader>
        {user.roles.filter(role => navRoles?.includes(role))?.map(role => (
          <ListItem key={role} onClick={() => updateCurRole(role)} button selected={role === user.currentRole}>
            <ListItemText>
              <LanguageString groupName="ROLE_NAME" resourceName={role} defaultText={role} />
            </ListItemText>
            {role === 'EDITOR' && <ListItemSecondaryAction><Checkbox checked={liveEdit} onChange={handleLiveEditChanged} /></ListItemSecondaryAction>}
          </ListItem>
        ))}
      </List>

      <Divider />

      <List>
        <ListSubheader><LanguageString groupName="ACCOUNT" resourceName="ACCOUNT" /></ListSubheader>
        <ListItem button component={RouterLink} to="/profile">
          <ListItemIcon>
            <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText><LanguageString groupName="ACCOUNT" resourceName="PROFILE" /></ListItemText>
        </ListItem>

        <ListItem button component={RouterLink} to="/logout">
          <ListItemIcon>
            <Icon>power_settings_new</Icon>
          </ListItemIcon>
          <ListItemText><LanguageString groupName="ACCOUNT" resourceName="LOGOUT" /></ListItemText>
        </ListItem>
      </List>
    </Menu>
  );
};

export default AccountMenu;
