import { createStyles, makeStyles } from '@material-ui/core';
import { FEValues, FValues, lsDtoWithId, lsValue, QValues } from '../../common';
import { tmpId } from '../../contexts';
import {
  Form as FormDto,
  FormAnswerType,
  FormElement,
  FormElementInput,
  FormInput,
  FormLayoutType,
  FormOptionType,
  FormQuestion,
  FormQuestionInput,
  FormQuestionType
} from '../../dto';

export const sectionIndent = '24px';
export const subListOffset = '-16px';

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      height: 28,
      marginLeft: 4,
      marginRight: 4
    }
  })
);

export const createNewQuestion: () => QValues = () => ({
  id: tmpId.nextId,
  text: { id: tmpId.nextId, text: '', isHtml: false },
  hint: { id: tmpId.nextId, isHtml: false },
  questionType: FormQuestionType.TEXT,
  answerType: FormAnswerType.ALL,
  hideIfNull: false,
  isReadOnly: false,
  isRequired: false,
  transient: false,
  encrypt: false,
  sortIndex: -1,
  optionType: FormOptionType.STATIC
});

export const createNewElement: (sortIndex: number) => FEValues = (sortIndex = 0) => ({
  id: tmpId.nextId,
  name: 'newSection',
  title: { id: tmpId.nextId, text: 'New Section', isHtml: false },
  instructions: { id: tmpId.nextId, isHtml: false },
  layoutType: FormLayoutType.ROWS,
  sortIndex,
  elements: []
});

export const convertToEditorFormat = (form: FormDto): FValues => {
  const dtoMap: { elements: { [id: number]: FormElement }; questions: { [id: number]: FormQuestion } } = { elements: {}, questions: {} };
  const valMap: { elements: { [id: number]: FEValues }; questions: { [id: number]: QValues } } = { elements: {}, questions: {} };

  const mapDependsOn = (fVal: FValues): void => {
    function* elementsIterator(elements: FEValues[]): Generator<FEValues, void, unknown> {
      for (const el of elements) {
        yield el;
        if (el.elements) yield* elementsIterator(el.elements);
      }
    }

    const getDefaultVal = (qType: FormQuestionType) => {
      switch (qType) {
        case FormQuestionType.BOOLEAN:
          return false;
        case FormQuestionType.MULTISELECT:
          return [];
        default:
          return null;
      }
    };

    if (fVal.elements != null) {
      for (const el of elementsIterator(fVal.elements)) {
        // TODO: map dependsOn for the Elements

        if (el.questions != null) {
          for (const q of el.questions) {
            fVal.previewVals[q.id] = getDefaultVal(q.questionType);
            const qDto = dtoMap.questions[q.id];
            if (qDto.dependsOn != null) {
              q.dependsOn = qDto.dependsOn.map(dp => ({
                id: dp.id,
                operation: dp.operation,
                operand: dp.operand,
                name: dp.name,
                source: { id: dp.source.id } as QValues,
                targetQuestion: q
              }));
            }
          }
        }
      }
    }
  };

  const formElementValues = (fe: FormElement): FEValues => ({
    id: fe.id,
    name: fe.name,
    sortIndex: fe.sortIndex,
    title: lsValue(fe.title),
    showNumbers: fe.showQuestionNumbers,
    instructions: lsValue(fe.instructions),
    layoutType: fe.layoutType,
    elements: fe.childElements?.map(cl => formElementValues(cl)),
    questions: fe.questions?.map(q => {
      const qVal: QValues = {
        id: q.id,
        name: q.name != null ? q.name : undefined,
        questionType: q.questionType,
        text: lsValue(q.text),
        hint: lsValue(q.hint),
        highText: lsValue(q.highText),
        lowText: lsValue(q.lowText),
        sortIndex: q.sortIndex,
        isReadOnly: q.isReadOnly,
        isRequired: q.isRequired,
        transient: q.transientResponse,
        encrypt: q.encryptResponse,
        answerType: q.answerType,
        // TODO: Should we add the 'answer' info to the options and remove the hack in QuestionOptions that gets the question answers?
        answer: q.answer,
        hideIfNull: q.shouldHideIfNull,
        match: q.match,
        maxLength: q.maxLength,
        minLength: q.minLength,
        minRateLevel: q.minRateLevel,
        rateLevels: q.rateLevels,
        rows: q.rows,
        userPropertyId: q.userProperty?.id,
        optionType: q.optionType,
        options: q.options != null ? q.options.map(o => ({ id: o.id, text: o.text, code: o.code })) : []
      };

      dtoMap.questions[q.id] = q;
      valMap.questions[qVal.id] = qVal;

      return qVal;
    })
  });

  const vals = {
    id: form.id,
    name: form.name,
    title: lsValue(form.title),
    formType: form.formType,
    layoutType: form.layoutType,
    downloadButtonEnabled: form.downloadButtonEnabled,
    allowMultipleSubmissions: form.allowMultipleSubmissions,
    autoGrade: form.autoGrade,
    showCorrect: form.showCorrect,
    elements: form.elements?.map(el => formElementValues(el)),
    previewVals: {}
  };

  mapDependsOn(vals);

  return vals;
};

export const convertToDtoFormat = (formData: FValues) => {
  const questionDtoFormat: (questionData: QValues) => FormQuestionInput = qd => ({
    id: qd.id,
    name: qd.name,
    answerType: qd.answerType,
    delayGrading: false,
    isReadOnly: qd.isReadOnly,
    isRequired: qd.isRequired,
    transientResponse: qd.transient,
    encryptResponse: qd.encrypt,
    questionType: qd.questionType,
    shouldHideIfNull: qd.hideIfNull,
    text: lsDtoWithId(qd.text),
    answer: qd.answer,
    highText: lsDtoWithId(qd.highText),
    hint: lsDtoWithId(qd.hint),
    lowText: lsDtoWithId(qd.lowText),
    match: qd.match,
    maxLength: qd.maxLength,
    minLength: qd.minLength,
    minRateLevel: qd.minRateLevel,
    rateLevels: qd.rateLevels,
    rows: qd.rows,
    sortIndex: qd.sortIndex,
    customPropertyId: qd.userPropertyId,
    optionType: qd.optionType,
    options: qd.options?.map(ot => ({ id: ot.id, text: ot.text, code: ot.code }))
  });

  const elementDtoFormat: (ed: FEValues) => FormElementInput = elementData => ({
    id: elementData.id,
    layoutType: elementData.layoutType ?? FormLayoutType.ROWS,
    sortIndex: elementData.sortIndex,
    showQuestionNumbers: elementData.showNumbers ?? false,
    resetNumbers: false,
    childElements: elementData.elements?.map(ed => elementDtoFormat(ed)),
    title: lsDtoWithId(elementData.title),
    instructions: lsDtoWithId(elementData.instructions),
    name: elementData.name,
    questions: elementData.questions?.map(qd => questionDtoFormat(qd))
  });

  const dto: FormInput = {
    id: formData.id,
    downloadButtonEnabled: formData.downloadButtonEnabled,
    allowMultipleSubmissions: formData.allowMultipleSubmissions,
    autoGrade: formData.autoGrade,
    showCorrect: formData.showCorrect,
    formType: formData.formType,
    layoutType: formData.layoutType,
    name: formData.name,
    title: lsDtoWithId(formData.title),
    elements: formData.elements?.map(el => elementDtoFormat(el))
  };

  return dto;
};
