import * as DTO from '../../dto';
import * as Edit from './MATEditTypes';

export type { MTSActivityType, Site } from '../../dto';

export const convertMAT = (mat: Edit.MTSActivityType): DTO.MTSActivityTypeInput => ({
  id: mat.id,
  sortIndex: mat.sortIndex,
  attachmentFormEnabled: mat.attachmentFormEnabled,
  childGeneratedTypesEnabled: mat.childGenerateTypesEnabled,
  appearsInReviewAllHours: mat.appearsInReviewAllHours,
  code: mat.code,
  mtsInRoadmapEnabled: mat.mtsInRoadmapEnabled,
  secondaryCode: mat.secondaryCode,
  tertiaryCode: mat.tertiaryCode,
  description: mat.description,
  enabled: mat.enabled,
  hidden: mat.hidden,
  filterCustomPropertyValue: mat.filterCustomPropertyValue,
  formId: mat.formId ?? null,
  nameId: mat.name?.group?.id,
  noteRequired: mat.noteRequired,
  secondaryFormId: mat.secondaryFormId ?? null,
  wpr: mat.wpr,
  canEditEntryData: mat.canEditEntryData,
  canEditHours: mat.canEditHours,
  defaultHours: mat.defaultHours ? Number(mat.defaultHours) : undefined,
  maxDailyHours: mat.maxDailyHours ? Number(mat.maxDailyHours) : undefined,
  maxEntryHours: mat.maxEntryHours ? Number(mat.maxEntryHours) : undefined,
  maxNumberOfEntries: mat.maxNumberOfEntries ? Number(mat.maxNumberOfEntries) : undefined,
  externalApprovalFormId: mat.externalApprovalFormId,
  reqExternalApprovalFormId: mat.reqExternalApprovalFormId
});
