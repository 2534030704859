import { Grid, Icon } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import React, { useContext } from 'react';
import { DragDropContext, DragDropContextProps, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { moveSortable, PathEditorContext } from '..';
import * as Edit from '../../common/pathEditTypes';
import { EditLabel } from './PathTreeItem';
import { TaskTreeItem } from './TaskTreeItem';

type MilestoneTreeItemProps = {
  path: Edit.SuccessPath;
  group: Edit.MilestoneGroup;
  milestone: Edit.Milestone;
  dragHandleProps: DraggableProvided['dragHandleProps'];
};

export const MilestoneTreeItem: React.FC<MilestoneTreeItemProps> = ({ path, group, milestone, dragHandleProps }) => {
  const { refresh } = useContext(PathEditorContext);
  const firstTask = milestone.tasks?.[0];
  const msLabel = firstTask?.name ? <EditLabel name={firstTask.name} item={milestone} /> : 'New Milestone';

  const handleDrag: DragDropContextProps['onDragEnd'] = (result) => {
    const milestones = group.milestones;
    if (milestones == null) return;

    if (result.reason === 'DROP' && result.destination != null && group.subGroups!) {
      console.log(milestones);
      moveSortable(milestones, result.source.index, result.destination.index);
      refresh();
    }
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={`ms-${path.id}-${group.id}-${milestone.id}`}>
        {(provided) => (
          <TreeItem
            ref={provided.innerRef}
            nodeId={`ms-${path.id}-${group.id}-${milestone.id}`}
            label={
              <Grid container justify="space-between" wrap="nowrap">
                <Grid item>{msLabel}</Grid>
                <Grid item>
                  <Icon {...dragHandleProps}>drag_indicator</Icon>
                </Grid>
              </Grid>
            }
            icon={<Icon>assignment</Icon>}
          >
            {milestone.tasks?.map(task => (
              <TaskTreeItem key={task.id} path={path} group={group} milestone={milestone} task={task} />
            ))}
          </TreeItem>
        )}
      </Droppable>
    </DragDropContext>
  );
};
