import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { LanguageStringGroup, StringGroupQueryArgs } from '../../dto';
import { ReqVars } from './common';
import { languageStringGroupFrag } from './fragments';

type Variables = StringGroupQueryArgs;
type Results = { stringGroup: LanguageStringGroup[] };

const query = gql`
  ${languageStringGroupFrag}
  query getStringGroup($groupName: String!) {
    stringGroup(groupName: $groupName) {
      ...LangStringGroupFrag
    }
  }
`;

export const useStringGroup = (options: ReqVars<QueryHookOptions<Results, Variables>>) =>
  useQuery<Results, Variables>(query, { fetchPolicy: 'cache-first', ...options });
