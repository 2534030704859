import { makeStyles as muiMakeStyles, Theme } from '@material-ui/core';
export class TypedReflect {
  static ownKeys = Reflect.ownKeys as <T extends object>(obj: T) => (keyof T)[];
}

export function hasProp<T extends { [C in P]: string }, P extends string>(obj: unknown, prop: P): obj is T {
  return (obj as T)[prop] != null;
}

export function hasProps<T extends { [C in P]: T[P] }, P extends string>(obj: unknown, props: P[]): obj is T {
  return props.every(prop => (obj as T)[prop] != null);
}

export type MakeNullable<T, P extends keyof T> = {
  [K in keyof T]: K extends P ? T[K] | null : T[K];
};

type CL<T> = { [P in keyof T]: string };
type KnownKeys<T> = {
  [K in keyof T as string extends K ? never : number extends K ? never : K]: T[K];
};

export function makeStyles<T extends object>(f: (t: Theme) => T): () => CL<KnownKeys<T>> {
  return muiMakeStyles(f) as () => CL<KnownKeys<T>>;
}
