import * as React from 'react';
import * as DTO from 'tuapath-common/generated/schema';
import { useReports } from '../../hooks/gql/useReports';
import { Grid, Select, MenuItem } from '@material-ui/core';
import { useState } from 'react';

const Reports: React.FC = (props) => {
  const { loading, data } = useReports({ fetchPolicy: 'network-only' });
  const [report, setReport] = useState<DTO.Report>();
  if (loading) {
    return <div><span>...</span></div>;
  }

  if (data && data.reports && data.reports.length > 0) {
    if (!report) {
      setReport(data.reports[0]);
    }
    return <div>
      <Grid xs={12} item>
        <Select value={report?.name} onChange={e => setReport(data.reports.filter(r => r.name === e.target.value)[0])}>
          {data.reports.map(x => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
        </Select>
      </Grid>
      <Grid xs={12} item>
        { report ? <iframe
          src={report.url}
          title="Reports"
          frameBorder={0}
          style={
            {
              width: '100%',
              height: '75vh'
            }
          }
        /> : <span />}
      </Grid>
    </div>;
  } else {
    return <div>
      <span>Reporting is not available - please contact support</span>
    </div>;
  }
};

export default Reports;
