import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';

export type SiteOrgsResults = { site: Site };
export const siteOrgsQuery = gql`
  query siteOrganization($orgId: Int) {
    site {
      id
      group {
        id
        organizations(id: $orgId) {
          id
          name
        }
      }
    }
  }
`;

export function useSiteOrganizations(options?: QueryHookOptions<SiteOrgsResults>) {
  return useQuery<SiteOrgsResults>(siteOrgsQuery, options);
}
