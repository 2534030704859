import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { languageFrag } from './fragments/languageFrag';

type Results = { site: Pick<Site, 'languages'> };

export function useLanguages(options?: QueryHookOptions<Results>) {
  const query = gql`
    ${languageFrag}

    query languages {
      site {
        languages {
          ...LangFrag
        }
      }
    }
  `;

  return useQuery<Results>(query, options);
}
