import gql from 'graphql-tag';
import { formElementFrag } from './formElementFrag';

export const formElementTreeFrag = gql`
  ${formElementFrag}

  # Please ignore this ugly query :( GraphQL doesn't support recursive data strucutres
  fragment FormElementFrag2 on FormElement {
    ...FormElementFieldFrag2
    childElements {
      ...FormElementFieldFrag2
      childElements {
        ...FormElementFieldFrag2
        childElements {
          ...FormElementFieldFrag2
          childElements {
            ...FormElementFieldFrag2
            childElements {
              ...FormElementFieldFrag2
              childElements {
                ...FormElementFieldFrag2
              }
            }
          }
        }
      }
    }
  }
`;

export default formElementTreeFrag;
