import gql from 'graphql-tag';
import { userInfoFrag } from './userInfoFrag';
import { attachmentTypeFrag } from './attachmentTypeFrag';

export const attachmentFrag = gql`
  ${userInfoFrag}
  ${attachmentTypeFrag}

  fragment AttachmentFrag on Attachment {
    id
    description
    url
    fileName
    createdAt
    type{
      ...AttachmentTypeFrag
    }
    uploader {
      ...UserInfoFrag
    }
    userDeleteAbility
  }
`;
