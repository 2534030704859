import { AppBar, Badge, Icon, IconButton, Tab, Tabs, Toolbar, Container, Grid } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useChildLinks, useSelectedChildIndex } from '../../contexts';
import { LanguageString } from '../Common/LanguageString';
import { Link as RouterLink } from 'react-router-dom';
import { AccountMenu } from '../Common';
import { useClasses } from '../../hooks';

export const NavigationBar: React.FC = () => {
  const childLinks = useChildLinks();
  const tIndex = useSelectedChildIndex();
  const classes = useClasses();

  const accountButtonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <AppBar position="fixed" className={classes.navigationTopBarAppBar}>
      <Toolbar>
        <Container>
          <Grid container>
            <Tabs value={tIndex}>
              {childLinks?.map(cl => (
                <Tab
                  key={cl.item.name}
                  label={<LanguageString languageString={cl.item.title} />}
                  icon={cl.item.icon ? <Icon>{cl.item.icon}</Icon> : undefined}
                  component={RouterLink}
                  to={cl.path}
                />
              ))}
            </Tabs>
            <div style={{ flexGrow: 1 }} />
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <Icon>notifications</Icon>
              </Badge>
            </IconButton>
            <IconButton
              ref={accountButtonRef}
              edge="end"
              aria-controls="account-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={toggleMenu}
            >
              <Icon>account_circle</Icon>
            </IconButton>
            <AccountMenu open={menuOpen} toggle={toggleMenu} anchor={accountButtonRef} />
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
