import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { mtsApprove: Boolean };
type Variables = { mtsId: number, formSubmissionId: number, pin: string };
export const useMTSApprove = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation approveMTS($mtsId: Int!, $formSubmissionId: Int!, $pin: String!) {
      mtsApprove(
        mtsId: $mtsId
        formSubmissionId: $formSubmissionId
        advisorPin: $pin
      )
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useMTSApprove;
