import { MenuItem } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import React from 'react';
import { TextField } from '..';
import { FormLayoutType, FormQuestionType } from '../../dto';
import { TextFieldProps } from '../Common';

export type EditorContext = {
  nextId: number;
};

export type ClickEvent<T = HTMLDivElement> = React.MouseEventHandler<T>;
// type ChangeEvent<T = HTMLInputElement> = React.ChangeEventHandler<T>;
export type KeyPress<T = HTMLInputElement> = React.KeyboardEventHandler<T>;

export const FormEditorContext = React.createContext<EditorContext>({ nextId: -1 });

// TODO: This temporarily creates a section with a question as a starter. Eventually it should create a blank new form.
export const getNewFormData = (context: EditorContext) => ({
  id: context.nextId--,
  layoutType: FormLayoutType.ROWS,
  elements: [
    {
      id: context.nextId--,
      layoutType: FormLayoutType.ROWS,
      questions: [
        {
          id: context.nextId--,
          questionType: FormQuestionType.TEXT
        }
      ]
    }
  ]
});

export const LayoutTextField: React.FC<Pick<TextFieldProps, Breakpoint | 'item' | 'name'>> = props => (
  <TextField {...props} label="Layout" select>
    <MenuItem value="ROWS">Rows</MenuItem>
    <MenuItem value="COLUMNS">Columns</MenuItem>
    <MenuItem value="BOOTSTRAP_COLUMNS">Bootstrap Columns</MenuItem>
  </TextField>
);
