import { ErrorResponse } from '@apollo/client/link/error';
import { Grid, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UserNotification } from '../../../dto';
import { useClasses, useNotificationMarkDeleted, useNotificationMarkRead } from '../../../hooks';
import { GeneralErrorMessage, LanguageString, RawHTML } from '../../Common';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStudentNotificationsCtx, useUserNotificationsCtx } from '../../../contexts/NotificationsProvider';

interface NotificationsTableProps {
  currentUserContext: boolean;
  clickedNotification: (notifications: UserNotification) => void;
  scrollId: string;
}

export const NotificationsTable: React.FC<NotificationsTableProps> = props => {
  const history = useHistory();
  const classes = useClasses();

  const studentNotificationCtx = useStudentNotificationsCtx();
  const userNotificationCtx = useUserNotificationsCtx();
  const notificationsCtx = useMemo(() => {
    return props.currentUserContext ? userNotificationCtx : studentNotificationCtx;
  }, [props.currentUserContext, userNotificationCtx, studentNotificationCtx]);

  const [markRead] = useNotificationMarkRead();
  const [markDeleted] = useNotificationMarkDeleted();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const isErrorResponse = (res: unknown): res is ErrorResponse => (res as ErrorResponse).graphQLErrors != null;
  const handleClickDeleteNotification = async (notification: UserNotification) => {
    try {
      await markDeleted({ variables: { id: notification.id } });
    } catch (res: unknown) {
      if (!isErrorResponse(res)) return;

      const errors = res.graphQLErrors?.map(error => error.message);
      if (errors?.length && errorMessage !== errors[0]) setErrorMessage(errors[0]);
    }
  };

  const handleClickReadNotification = async (notification: UserNotification) => {
    props.clickedNotification(notification);
    try {
      await markRead({ variables: { id: notification.id } });
    } catch (res: unknown) {
      if (!isErrorResponse(res)) return;
      const errors = res.graphQLErrors?.map(error => error.message);

      if (errors?.length && errorMessage !== errors[0]) setErrorMessage(errors[0]);
    }
  };

  return (
    <>
      {errorMessage && <GeneralErrorMessage message={errorMessage} />}

      <List>
        {notificationsCtx.allNotifications.length <= 0 && (
          <>
            <ListSubheader>
              <LanguageString groupName="GENERAL" resourceName="NOTIFICATIONS_TITLE" defaultText="Notifications" />
            </ListSubheader>
            <Grid className={classes.notificationsEmpty} container justify="center" alignItems="center" direction="column">
              <Grid item xs>
                <Icon>notification_important</Icon>
              </Grid>
              <Grid item xs>
                <Typography variant="body2" align="center">
                  <LanguageString groupName="GENERAL" resourceName="NO_NOTIFICATIONS" defaultText="No Notifications" />
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <div id={props.scrollId} style={{maxHeight: '70vh', overflow: 'auto'}}>
          <InfiniteScroll
            dataLength={notificationsCtx.allNotifications.length}
            next={() => {
              notificationsCtx.setNotificationsPage(p => p + 1);
            }}
            hasMore={notificationsCtx.allNotifications.length < notificationsCtx.totalNotifications}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>No more notifications</b>
              </p>
            }
            scrollableTarget={props.scrollId}
          >
            {notificationsCtx.allNotifications.map(notification => {
              return (
                <ListItem
                  key={`notification_${notification.id}`}
                  className={notification.readDate !== null ? classes.readNotification : classes.unreadNotification}
                  button
                  onClick={() => {
                    void handleClickReadNotification(notification);

                    if (notification.actionUrl) history.push(notification.actionUrl);
                  }}
                >
                  <ListItemIcon>
                    <Icon>{notification.icon}</Icon>
                  </ListItemIcon>

                  <ListItemText>
                    <Typography variant="subtitle2">{notification.title?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''}</Typography>
                    <Typography variant="body2">
                      <RawHTML html={notification.message?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''} />
                    </Typography>
                    <Typography variant="caption">{format(new Date(notification.triggerDate), 'MM/dd h:mm a')}</Typography>
                  </ListItemText>

                  <IconButton onClick={() => void handleClickReadNotification(notification)}>
                    <Icon>keyboard_arrow_right</Icon>
                  </IconButton>

                  <IconButton onClick={() => void handleClickDeleteNotification(notification)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </div>
      </List>
    </>
  );
};

export default NotificationsTable;
