import gql from 'graphql-tag';

export const notificationsFrag = gql`
  fragment NotificationsFrag on UserNotification {
    id
    actionUrl
    readDate
    title
    message
    triggerDate
    icon
    showInUi
  }
`;
