import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useResetUsername } from '../../../hooks';
import { DialogTitle, LanguageString, SuccessIconMessage } from '../../Common';

interface ResetUsernameDialogProps {
  open: boolean;
  token?: string;
  toggle: () => void;
}

interface ResetUsernameSchema {
  email: string;
  newUsername: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const ResetUsernameDialog: React.FC<ResetUsernameDialogProps> = ({ open, toggle, token }) => {
  const formRef = useRef<FormikProps<ResetUsernameSchema> | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [resetUsername] = useResetUsername();

  const initialValues: ResetUsernameSchema = {
    email: '',
    newUsername: ''
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const Validation = Yup.object().shape({
    email: Yup.string().email('* Invalid Email').required('* Required'),
    newUsername: Yup.string().required('* Required')
  });

  const localToggle = () => {
    setSubmitted(false);
    toggle();
  };

  const handleSubmit = (values: ResetUsernameSchema, formikHelpers: FormikHelpers<ResetUsernameSchema>) => {
    resetUsername({
      variables: {
        input: {
          email: values.email,
          token: token ?? '',
          newUsername: values.newUsername
        }
      },
      update: (cache, result) => {
        if (result.data?.resetUsername.success === true) {
          formikHelpers.setStatus({
            submitted: true,
            message: undefined
          });

          setSubmitted(true);
        } else {
          formikHelpers.setStatus({
            submitted: false,
            message: 'Oops. Something went wrong'
          });
        }
      }
    }).catch(res => {
      const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
        return error.message;
      });

      if (errors.length > 0) {
        formikHelpers.setStatus({
          submitted: false,
          message: errors[0]
        });
      }
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={localToggle} aria-labelledby="Reset Username Form">
      <DialogTitle onClose={localToggle}>
        <LanguageString groupName="GENERAL" resourceName="RESET_USERNAME" defaultText="Reset Username" />
      </DialogTitle>
      <DialogContent dividers>
        <Formik initialValues={initialValues} initialStatus={initialStatus} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={Validation} >
          {formikProps => {
            formRef.current = formikProps;

            const status = formikProps.status as FormStatus;

            if (status.submitted) {
              return <SuccessIconMessage animated message="Your username has been reset" />;
            }

            return (
              <Form>
                {!status.submitted && (
                  <DialogContentText>
                    <LanguageString
                      groupName="GENERAL"
                      resourceName="FORGOT_USERNAME_RESET_DESCRIPTION"
                      defaultText="Please type in your email address and new username"
                    />
                  </DialogContentText>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="email">
                      {() => (
                        <TextField
                          fullWidth
                          name="email"
                          label={<LanguageString groupName="GENERAL" resourceName="EMAIL" defaultText="Email" />}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.email && formikProps.touched.email)}
                          helperText={formikProps.errors.email && formikProps.touched.email && String(formikProps.errors.email)}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="newUsername">
                      {() => (
                        <TextField
                          fullWidth
                          name="newUsername"
                          label={<LanguageString groupName="GENERAL" resourceName="NEWUSERNAME" defaultText="New Username" />}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.newUsername && formikProps.touched.newUsername)}
                          helperText={formikProps.errors.newUsername && formikProps.touched.newUsername && String(formikProps.errors.newUsername)}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                {(formikProps.status as FormStatus).message && (
                  <Grid item xs={12}>
                    <Alert severity="error">{formikProps.status.message}</Alert>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={localToggle} color="primary">
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        {!submitted && (
          <Button variant="contained" onClick={() => formRef.current?.submitForm()} color="primary">
            <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetUsernameDialog;
