import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MTSActivityType, MTSActivityTypeInput } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

type Result = { updateMATOrder: MTSActivityType[] };
type Variables = { input: MTSActivityTypeInput[] };
export function useUpdateMATOrder(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${languageStringFrag}

    mutation updateMATOrder($input: [MTSActivityTypeInput!]!) {
      updateMATOrder(input: $input) {
        id
        name {
          ...LangStringFrag
        }
        description
        sortIndex
        code
        noteRequired
        wpr
        enabled
        childGeneratedTypesEnabled
        filterCustomPropertyValue
        formId
        secondaryFormId
        attachmentFormEnabled
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateMATOrder;
