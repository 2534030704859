import { tmpId } from '../contexts';
import { lsValue as convertLS, LsValues as LanguageString } from './formDataTypes';
import * as DTO from './pathDtoTypes';
export { lsValue as convertLS } from './formDataTypes';
export type { LgValues as StringGroup, LsValues as LanguageString } from './formDataTypes';

type ChangeType = 'childUpdated' | 'create' | 'delete' | 'update';

type Cacheable<T extends object> = T & {
  id: number;
  __type: 'Milestone' | 'MilestoneCompletedEmail' | 'MilestoneGroup' | 'SuccessPath' | 'Task';
};

type cOnChanged<T> = {
  (changeType: 'childUpdated', child: Cached<object>): void;
  (changeType: Exclude<ChangeType, 'childUpdated'>, prop: keyof T): void;
};

type Cached<T extends object> = Cacheable<T> & {
  handleChange: cOnChanged<T>;
  changedChildren?: Cached<object>[];
  parents: { pId: number, type: string }[];
};

export type Editable<T extends object> = Cacheable<T> & {
  onChanged?: () => void;
  isChanged?: boolean;
  hasChanges?: boolean;
};

export type Task = {
  __type: 'Task';
  id: number;
  name?: LanguageString;
  description?: LanguageString;
  summary?: LanguageString;
  instructions?: LanguageString;
  requiresApproval?: boolean;
  actionType?: DTO.TaskActionType;
  action?: string;
  sendEmailOnCompletionEnabled: boolean;
  onCompletedEmail?: MilestoneCompletedEmail;
};

export type MilestoneCompletedEmail = {
  __type: 'MilestoneCompletedEmail';
  id: number;
  subject: LanguageString;
  body: LanguageString;
  additionalRecipients?: string;
};

export type Milestone = {
  __type: 'Milestone';
  id: number;
  required: boolean;
  sortIndex: number;
  sendEmailOnCompletionEnabled: boolean;
  onCompletedEmail?: MilestoneCompletedEmail;
  tasks?: Task[];
  selectedTaskId: number | undefined;
  allowMultipleInstances: boolean | undefined;
};

export type MilestoneGroup = {
  __type: 'MilestoneGroup';
  id: number;
  sortIndex: number;
  name?: LanguageString;
  description?: LanguageString;
  color: string;
  subGroups?: MilestoneGroup[];
  milestones?: Milestone[];
};

export type SuccessPath = Editable<{
  __type: 'SuccessPath';
  id: number;
  name?: LanguageString;
  description?: LanguageString;
  msGroups?: MilestoneGroup[];
}>;

type CTypeBase = {
  [col: string]: Cacheable<object>;
};

type CTypes = {
  tasks: Task;
  completedEmails: MilestoneCompletedEmail;
  milestones: Milestone;
  msGroups: MilestoneGroup;
  paths: SuccessPath;
};
type CC<T extends CTypeBase> = { [P in keyof T]: CacheIDMap<T[P]> };

type InternalCC<T extends CC<CTypeBase>> = {
  [P in keyof T]: { [id: number]: Cached<T[keyof T][number]> }
};

type CacheIDMap<T> = { [id: number]: T };

export type CacheContext = CC<CTypes>;

function updateParent<T extends object>(item: Cached<T>, pId: number, type: string) {
  item.parents.push({ pId, type });
}

const createArrayProxy = <C extends CC<CTypeBase>>(cache: C, array: unknown[] | undefined, parentId: number, parentType: string & keyof C, propName: string) => {
  if (array == null) return;

  const icache = cache as unknown as InternalCC<typeof cache>;

  const push = (...items: unknown[]) => {
    const parent = icache[parentType][parentId];
    array.push(...items);
    parent.handleChange('update', propName as keyof C[keyof C][number]);
  };

  const splice = (start: number, deleteCount?: number) => {
    const parent = icache[parentType][parentId];
    array.splice(start, deleteCount);
    parent.handleChange('update', propName as keyof C[keyof C][number]);
  };

  return new Proxy(array, {
    set: (obj, prop, val) => {
      const realProp = prop as keyof typeof array;
      obj[realProp] = val;

      return true;
    },
    get: (obj, prop) => {
      if (prop === 'push') return push;
      else if (prop === 'splice') return splice;

      return obj[prop];
    }
  });
};

function isCached<T>(item: Cached<object> | keyof T): item is Cached<object> { return typeof item !== 'string'; }
function createProxy<T extends Cacheable<object>, C extends CC<CTypeBase>>(origObj: T, cache: C, parentId: number | null, parentType: (string & keyof C) | null, isWrapper: boolean): T {
  const icache = cache as unknown as InternalCC<typeof cache>;
  const nObj = origObj as Cached<T> & Editable<T>;
  const parents = [] as { pId: number, type: string }[];
  let changedChildren: Cached<object>[];
  let isChanged = false;
  if (parentId != null && parentType != null) parents.push({ pId: parentId, type: parentType });
  const handleChange = (changeType: ChangeType, item: Cached<object> | keyof T) => {
    if (isCached(item)) {
      if (changeType !== 'childUpdated') throw new Error(`Must be changeType of 'childUpdated'`);
      if (changedChildren == null) changedChildren = [];
      changedChildren.push(item);
    } else {
      if (changeType === 'childUpdated') throw new Error(`changeType cannot be 'childUpdated`);
      isChanged = true;
    }

    for (const { pId, type } of parents) {
      if (pId !== null && type != null) icache[type][pId].handleChange('childUpdated', nObj);
    }

    if (nObj.onChanged) nObj.onChanged();
  };

  return new Proxy(nObj, {
    set: (obj, prop, val) => {
      const realProp = prop as keyof typeof nObj;
      if (realProp === 'hasChanges') throw new Error('Cannot set hasChanges');
      else if (realProp === 'parents') throw new Error('Cannot set parents');
      else if (realProp === 'isChanged') isChanged = val;
      else obj[realProp] = val;
      if (realProp !== 'hasChanges' && realProp !== 'isChanged' && realProp !== 'handleChange' && realProp !== 'changedChildren' && realProp !== 'onChanged' && realProp !== 'parents') {
        handleChange('update', realProp);
      }

      return true;
    },
    get: (obj, prop) => {
      if (prop === 'hasChanges') return (isWrapper && nObj.hasChanges) || isChanged || changedChildren?.length;
      if (prop === 'hack') return obj;

      if (prop === 'isChanged') return isChanged;
      else if (prop === 'parents') return parents;
      else if (prop === 'handleChange') return handleChange;

      return obj[prop];
    }
  });
}

const decify = (num: number) => (num * 10 + 1) / Math.pow(10, Math.floor(Math.log10(num) + 2));

// TODO: This is a hack to allow an object to be updated without triggering any changes
export function hack<T extends Editable<object>>(item: T): T {
  return ((item as unknown) as { hack: T }).hack;
}

function getCached<T extends Cacheable<object>, C extends CC<CTypeBase>>(cc: C, id: number, type: T['__type'], parentId: number | null, parentType: (string & keyof C) | null): T | undefined {
  // TODO: fix the typing here
  const items = getCacheCollection(cc, type as Cacheable<object>['__type']);

  // TODO: fix the typing here
  const item = items[id] as Cached<T>;
  if (item != null && parentId != null && parentType != null && item.parents?.find(p => p.pId === parentId) == null) updateParent(item, parentId, parentType);

  return item;
}

function getCacheCollection<T extends Cacheable<object>, C extends CC<CTypeBase>>(cc: C, type: T['__type']): CacheIDMap<T> {
  // TODO: FIx the typing in here
  switch (type) {
    case 'Task': return cc.tasks as unknown as CacheIDMap<T>;
    case 'Milestone': return cc.milestones as unknown as CacheIDMap<T>;
    case 'MilestoneCompletedEmail': return cc.completedEmails as unknown as CacheIDMap<T>;
    case 'MilestoneGroup': return cc.msGroups as unknown as CacheIDMap<T>;
    case 'SuccessPath': return cc.paths as unknown as CacheIDMap<T>;
  }

  throw new Error(`Invalid type ${type}`);
};

function addCached<T extends Cacheable<object>, C extends CC<CTypeBase>>(cc: C, item: T, parentId: number | null, parentType: keyof CacheContext | null, isWrapper?: boolean): T {
  const id = isWrapper && item.__type === 'Milestone' && parentId != null ? item.id + decify(parentId) : item.id;
  return getCacheCollection(cc, item.__type)[id] = createProxy(item, cc, parentId, parentType, isWrapper === true);
}

type ConvertTask = (task: DTO.Task, cc: CacheContext, pId: number) => Task;
export const convertTask: ConvertTask = (task, cc, pId) => getCached(cc, task.id, 'Task', pId, 'milestones') ?? addCached(cc, {
  __type: 'Task',
  id: task.id,
  name: convertLS(task.name),
  description: convertLS(task.description),
  summary: convertLS(task.summary),
  instructions: convertLS(task.instructions),
  requiresApproval: task.requiresApproval === true,
  actionType: task.actionType,
  action: task.action,
  sendEmailOnCompletionEnabled: task.completedEmail != null,
  onCompletedEmail: task.completedEmail != null ? convertCompletedEmail(task.completedEmail, cc, task.id, 'tasks') : undefined
}, pId, 'milestones');

type ConvertCompletedEmail = (completedEmail: DTO.MilestoneCompletedEmail, cc: CacheContext, pId: number | null, parentType: 'milestones' | 'tasks') => MilestoneCompletedEmail;
export const convertCompletedEmail: ConvertCompletedEmail = (completedEmail, cc, pId, parentType) => getCached(cc, completedEmail.id, 'MilestoneCompletedEmail', pId, parentType) ?? addCached(cc, {
  __type: 'MilestoneCompletedEmail',
  id: completedEmail.id,
  subject: convertLS(completedEmail.subject),
  body: convertLS(completedEmail.body),
  additionalRecipients: completedEmail.additionalRecipients ?? undefined
}, pId, parentType);

type ConvertMilestone = (milestone: DTO.Milestone, index: number, cc: CacheContext, pId: number | null) => Milestone;
export const convertMilestone2: ConvertMilestone = (milestone, index, cc, pId) => getCached(cc, pId ? milestone.id + decify(pId) : milestone.id, 'Milestone', pId, 'msGroups') ?? addCached(cc, {
  __type: 'Milestone',
  id: milestone.id,
  required: milestone.required === true,
  sortIndex: index,
  sendEmailOnCompletionEnabled: milestone.completedEmail != null,
  onCompletedEmail: milestone.completedEmail != null ? convertCompletedEmail(milestone.completedEmail, cc, milestone.id, 'milestones') : undefined,
  selectedTaskId: undefined,
  tasks: milestone.tasks?.map(task => convertTask(task, cc, milestone.id)),
  allowMultipleInstances: milestone.allowMultipleInstances
}, pId, 'msGroups');

export const convertMilestone: ConvertMilestone = (milestone, index, cc, pId) => {
  const wrappedId = milestone.id;
  const wrapperId = pId ? wrappedId + decify(pId) : wrappedId;

  let cached = getCached(cc, wrapperId, 'Milestone', pId, 'msGroups') as Cached<Milestone>;

  // TODO: Fix the typing here
  if (cached) return cached as Cached<Milestone>;

  cached = getCached(cc, wrappedId, 'Milestone', pId, 'msGroups') as Cached<Milestone>;

  if (cached == null) cached = addCached(cc, {
    __type: 'Milestone',
    id: milestone.id,
    required: milestone.required === true,
    sortIndex: index,
    sendEmailOnCompletionEnabled: milestone.completedEmail != null,
    onCompletedEmail: milestone.completedEmail != null ? convertCompletedEmail(milestone.completedEmail, cc, milestone.id, 'milestones') : undefined,
    selectedTaskId: undefined,
    tasks: createArrayProxy(cc, milestone.tasks?.map(task => convertTask(task, cc, milestone.id)), wrapperId, 'milestones', 'tasks'),
    allowMultipleInstances: milestone.allowMultipleInstances
  }, pId, 'msGroups', false) as Cached<Milestone>;

  let sortIndex = cached.sortIndex;
  let isChanged = false;
  const tmpMs = new Proxy(cached, {
    set: (obj, prop, val) => {
      if (prop === 'sortIndex') sortIndex = val;
      else if (prop === 'isChanged') isChanged = val;
      else obj[prop] = val;

      return true;
    },
    get: (obj, prop) => {
      return prop === 'sortIndex' ? sortIndex : prop === 'isChanged' ? isChanged || obj[prop] : obj[prop];
    }
  });

  return addCached(cc, tmpMs, pId, 'msGroups', true) as Cached<Milestone>;
};

const sorted = <T extends {sortIndex?: number}>(items: T[] | undefined) => items?.sort((i1, i2) => (i1.sortIndex ?? items.indexOf(i1)) - (i2.sortIndex ?? items.indexOf(i2)));

type ConvertGroup = (msGroup: DTO.MilestoneGroup, sortIndex: number, cc: CacheContext, pId: number, parentType: 'msGroups' | 'paths') => MilestoneGroup;
export const convertGroup: ConvertGroup = (msGroup, sortIndex, cc, pId, parentType) => getCached(cc, msGroup.id, 'MilestoneGroup', pId, parentType) ?? addCached(cc, {
  __type: 'MilestoneGroup',
  id: msGroup.id,
  sortIndex,
  color: msGroup.color,
  name: convertLS(msGroup.name),
  description: convertLS(msGroup.description),
  subGroups: sorted(msGroup.subGroups?.slice())?.map((subGrp, index) => convertGroup(subGrp, index, cc, msGroup.id, 'msGroups')),
  milestones: msGroup.milestones?.map((ms, index) => convertMilestone(ms, index, cc, msGroup.id))
}, pId, parentType);

type ConvertPath = (path: DTO.SuccessPath, cc: CacheContext) => SuccessPath;
export const convertPath: ConvertPath = (path, cc) => getCached(cc, path.id, 'SuccessPath', null, null) ?? addCached(cc, {
  __type: 'SuccessPath',
  id: path.id,
  name: convertLS(path.name),
  description: convertLS(path.description),
  msGroups: sorted(path.milestoneGroups?.slice())?.map((grp, index) => convertGroup(grp, index, cc, path.id, 'paths'))
}, null, null);

type NewTaskInfo = {
  cc: CacheContext;
  pId: number;
};

export const createNewTask = ({ cc, pId }: NewTaskInfo): Task => addCached(cc, {
  __type: 'Task',
  id: tmpId.nextId,
  name: {
    id: tmpId.nextId,
    isHtml: false,
    text: 'New Task'
  },
  summary: {
    id: tmpId.nextId,
    isHtml: false
  },
  description: {
    id: tmpId.nextId,
    isHtml: false
  },
  instructions: {
    id: tmpId.nextId,
    isHtml: false
  },
  requiresApproval: false,
  actionType: DTO.TaskActionType.readOnly,
  sendEmailOnCompletionEnabled: false
}, pId, 'milestones');

export const createNewOnCompletedEmail = (): MilestoneCompletedEmail => ({
  __type: 'MilestoneCompletedEmail',
  id: tmpId.nextId,
  subject: {
    id: tmpId.nextId,
    text: undefined,
    isHtml: false
  },
  body: {
    id: tmpId.nextId,
    text: undefined,
    isHtml: false
  }
});

type NewMilestoneInfo = {
  cc: CacheContext;
  sortIndex: number;
  pId: number;
};

export const createNewMilestone = ({cc, sortIndex, pId }: NewMilestoneInfo): Milestone => addCached(cc, {
  __type: 'Milestone',
  id: tmpId.nextId,
  required: false,
  sortIndex,
  selectedTaskId: undefined,
  sendEmailOnCompletionEnabled: false,
  tasks: [createNewTask({ cc, pId: tmpId.currentId })],
  allowMultipleInstances: true
}, pId, 'msGroups');

type NewGroupInfo = {
  cc: CacheContext;
  name?: string;
  color?: string;
  sortIndex?: number;
  pId: number;
  parentType: Extract<keyof CacheContext, 'msGroups' | 'paths'>;
};

export const createNewGroup: (info: NewGroupInfo) => MilestoneGroup = ({cc, name, color, sortIndex, pId, parentType}) => addCached(cc, {
  __type: 'MilestoneGroup',
  id: tmpId.nextId,
  name: {
    id: tmpId.nextId,
    isHtml: false,
    text: name ?? 'New Group'
  },
  description: {
    id: tmpId.nextId,
    isHtml: false,
    text: ' '
  },
  color: color ?? '#ffffff',
  sortIndex: sortIndex ?? 0,
  subGroups: [],
  milestones: []
}, pId, parentType);
