import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { LoginResult, LoginWithPasswordMutationArgs } from 'tuapath-common/generated/schema';

type Result = { loginWithPassword: LoginResult };
type Variables = LoginWithPasswordMutationArgs;
export function useLoginWithPassword(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    mutation loginWithPassword($username: String!, $password: String!) {
      loginWithPassword(username: $username, password: $password) {
        success
        sessionToken
        reason
      }
    }
  `;

  return useMutation<Result, LoginWithPasswordMutationArgs>(mutation);
}
