import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

export type DepartmentsResults = { site: Site };
export const departmentsQuery = gql`
  ${languageStringFrag}
  query getDepartments($orgId: Int, $depId: Int) {
    site {
      id
      group {
        organizations(id: $orgId) {
          id
          name
          departments(id: $depId) {
            id
            name {
              ...LangStringFrag
            }
          }
        }
      }
    }
  }
`;

export function useDepartments(options?: QueryHookOptions<DepartmentsResults>) {
  return useQuery<DepartmentsResults>(departmentsQuery, options);
}
