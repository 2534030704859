import { Tabs, Tab, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { LanguageString } from '../../Common';
import ForgotUsername from './ForgotUsername';
import ForgotPassword from './ForgotPassword';

interface ForgotUsernamePasswordDialogProps {
  open: boolean;
  toggle: () => void;
}

export const ForgotUsernamePasswordDialog: React.FC<ForgotUsernamePasswordDialogProps> = ({ open, toggle }) => {
  const [tab, setTab] = useState(0);

  const changedTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog fullWidth open={open} onClose={toggle} aria-labelledby="Reset Password Form">
      <DialogTitle>
        <LanguageString
          groupName="GENERAL"
          resourceName={tab === 0 ? 'FORGOT_PASSWORD' : 'FORGOT_USERNAME'}
          defaultText={tab === 0 ? 'Forgot Password' : 'Forgot Username'}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={changedTab}
              aria-label="disabled tabs example"
            >
              <Tab
                label={
                  <LanguageString groupName="GENERAL" resourceName="FORGOT_PASSWORD" defaultText="Forgot Password" />
                }
              />
              <Tab
                label={
                  <LanguageString groupName="GENERAL" resourceName="FORGOT_USERNAME" defaultText="Forgot Username" />
                }
              />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            {tab === 0 && <ForgotPassword toggle={toggle} />}
            {tab === 1 && <ForgotUsername toggle={toggle} />}
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  );
};

export default ForgotUsernamePasswordDialog;
