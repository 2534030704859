import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { ReqVars } from './common';
import { caseLoadMtsPeriodFrag } from './fragments';
import { CaseLoadMTSPeriodResult } from 'tuapath-common/generated/schema';

type Variables = { programIds: number[];};
type Results = { site: { caseloadMtsPeriods: CaseLoadMTSPeriodResult } };

const query = gql`
  ${caseLoadMtsPeriodFrag}

  query periods($programIds: [Int]!) {
    site {
      id
      caseloadMtsPeriods(programIds: $programIds) {
        currentPeriod {
          ...CaseLoadMTSPeriodFrag
        }
        previousPeriod {
          ...CaseLoadMTSPeriodFrag
        }
        twoPeriodsAgo {
          ...CaseLoadMTSPeriodFrag
        }
      }
    }
  }
`;

export const useCaseLoadMTSPeriods = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export default useCaseLoadMTSPeriods;
