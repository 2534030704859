import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ResetCompletedAssignmentMutationArgs, ResetCompletedAssignmentResult } from '../../dto';
import { assignmentFrag, formSubmissionFrag } from './fragments';

type Result = { resetCompletedAssignment: ResetCompletedAssignmentResult };
type Variables = ResetCompletedAssignmentMutationArgs;
export function useResetCompletedAssignment(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${assignmentFrag}
    ${formSubmissionFrag}
    mutation resetCompletedAssignment($input: UpdateAssignmentInput!) {
      resetCompletedAssignment(input: $input) {
        assignment {
          ...AssignmentFrag
        }
        formSubmission {
          ...FormSubmissionFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, { ...options });
}

export default useResetCompletedAssignment;
