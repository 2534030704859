import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import { SSOConfig, SubmitFormInput } from 'tuapath-common/generated/schema';
import { SiteContext } from '../../contexts';
import * as DTO from '../../dto';
import { useLanguageString, useRegister } from '../../hooks';
import { DialogTitle, LanguageString, SuccessIconMessage } from '../Common';
import { DynamicForm } from '../DynamicForm';

interface SignupFormDialogProps {
  open: boolean;
  toggle: () => void;
  finishedRegistration?: () => void;
  overrideFormSubmitInput: SubmitFormInput | undefined;
  ssoConfig?: SSOConfig;
}

export const SignupFormDialog: React.FC<SignupFormDialogProps> = ({ open, toggle, finishedRegistration, overrideFormSubmitInput, ssoConfig }) => {
  const [register] = useRegister();
  const formName = 'registrationPropsTpl';
  const { site } = useContext(SiteContext);
  let formRef: DynamicForm | null = null;
  const [serverErrorMessage, setServerErrorMessage] = useState<String>('');
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const { data: invalidReferralData, loading } = useLanguageString({ variables: { groupName: 'REG_ERROR', resourceName: 'INVALID_REFERRAL' } });
  const regFormId = ssoConfig?.registrationForm?.id || site?.registrationForm?.id;

  if (loading) return <CircularProgress color="primary" />;

  function submitButtonPressed() {
    if (formRef) {
      void formRef.submitForm();
    }
  }

  async function overrideDynamicFormSubmit(formInput: DTO.SubmitFormInput, form: DTO.Form) {
    let answers: DTO.FormAnswerInput[] = [];

    if (formInput.answers) {
      answers = formInput.answers;
    }

    await register({
      variables: {
        input: {
          ssoConfigId: ssoConfig?.id,
          answers: answers
        }
      },
      update: (cache, result) => {
        let error = '';

        if (result.data) {
          if (result.data.register.success) {
            setRegisterSuccess(true);
            if (finishedRegistration) {
              finishedRegistration();
            }
          } else {
            if (result.data.register.reason) {
              switch (result.data.register.reason) {
                case DTO.RegistrationFailureReason.duplicate:
                  error = `User with ${result.data.register.value} already exists`;
                  break;
                case DTO.RegistrationFailureReason.usernameAlreadyExists:
                  error = 'Username already used';
                  break;
                case DTO.RegistrationFailureReason.emailAlreadyExists:
                  error = 'Email already used';
                  break;
                case DTO.RegistrationFailureReason.passwordBreached:
                  error = 'Password error';
                  break;
                case DTO.RegistrationFailureReason.missingCustomProperty:
                  error = 'Missing property';
                  break;
                case DTO.RegistrationFailureReason.validationError:
                  error = 'Validation Error';
                  break;
                case DTO.RegistrationFailureReason.serverError:
                  error = 'Server Error';
                  break;
                case DTO.RegistrationFailureReason.invalidReferralCode:
                  error = invalidReferralData?.string?.text ?? 'Invalid referral code';
                  break;
                default:
                  error = 'Server Error default';
                  break;
              }
            } else {
              error = 'An unknown error occured';
            }
          }
        } else {
          error = 'An unknown error occured';
        }

        setServerErrorMessage(error);
        if (error.length > 0) {
          setRegisterSuccess(false);
        }
      }
    });
  }

  return (
    <Dialog maxWidth="lg" fullWidth scroll="paper" open={open} onClose={toggle} aria-labelledby="Signup Form">
      <DialogTitle onClose={toggle}>
        <LanguageString groupName="GENERAL" resourceName="SIGNUP" defaultText="Signup" />
      </DialogTitle>

      <DialogContent dividers>
        {registerSuccess && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <SuccessIconMessage animated message="Success" />

              <Button color="primary" variant="outlined" fullWidth onClick={toggle}>
                <LanguageString groupName="GENERAL" resourceName="LOGIN" defaultText="Login" />
              </Button>
            </Grid>
          </Grid>
        )}

        {!registerSuccess && (
          <>
            <DynamicForm
              ref={r => (formRef = r)}
              formId={regFormId}
              formName={regFormId ? undefined : formName}
              autoSave={false}
              autoloadFormSubmission={false}
              showSubmitButton={false}
              overrideSubmitHandler={overrideDynamicFormSubmit}
              renderFormTitle={false}
              overrideFormSubmissionInput={overrideFormSubmitInput}
              shouldRemovePaswordFields={ssoConfig?.automaticallyCreateUnknownUsers}
            />

            {serverErrorMessage.length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Alert severity="error">{serverErrorMessage}</Alert>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="primary">
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        <Button variant="contained" onClick={submitButtonPressed} color="primary">
          <LanguageString groupName="GENERAL" resourceName="SIGNUP" defaultText="Signup" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignupFormDialog;
