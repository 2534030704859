import React from 'react';
import {
  Link
} from '@material-ui/core';
import { Link as RL, LinkProps } from 'react-router-dom';

interface RouterLinkProps extends LinkProps<unknown> {
  showUnderline?: boolean;
}

export const RouterLink: React.FC<RouterLinkProps> = (props) => {
  return (
    <Link
      component={RL}
      to={props.to}
      style={{
        textDecoration: props.showUnderline === true ? 'inherit' : 'none'
      }}
    >
      {props.children}
    </Link>
  );
};
export default RouterLink;
