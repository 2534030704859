import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Role, SecuredUser, SubmissionQueryArgs, SubmitFormInput } from '../../dto';
import { ReqVars } from './common';

export type ProfileSearchVariables = {
  as: Role;
  excludeRole?: Role;
  maxDistance: number;
  filter?: SubmitFormInput;
  limit: number;
  offset: number;
  formParams: SubmissionQueryArgs;
  viewedAsId?: number;
};
export type ProfileSerachResults = {
  securedUsers: {
    totalCount: number;
    users: SecuredUser[]
  }
};

const query = gql`
  query securedUsers($maxDistance: Int, $filter: SubmitFormInput, $formParams: SubmissionQueryArgs!, $as: Role!, $limit: Int, $offset: Int, $viewedAsId: Int) {
    securedUsers(maxDistance: $maxDistance, filter: $filter, as: $as, limit: $limit, offset: $offset, viewedAsId: $viewedAsId) {
    totalCount
    users {
        id
        username
        firstName
        lastName
        phoneNumber
        formSubmission(params: $formParams) {
          id
          answers {
            id
            answer
            selections {
              id
            }
            question {
              id
            }
          }
        }
        customProperties {
          id
          value
          type {
            id
            name
            fieldType
          }
        }
      }
    }
  }
`;

export const useProfileSearch = (options: ReqVars<QueryHookOptions<ProfileSerachResults, ProfileSearchVariables>>) =>
  useQuery<ProfileSerachResults, ProfileSearchVariables>(query, options);

export default useProfileSearch;
