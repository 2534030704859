import React from 'react';
import { MTS, MTSActivityType, MTSPeriod, MTSPeriodGroup } from '../../dto';
import { useMTSEntries, useMTSUserActivityTypes, useUserMTSPeriods } from '../../hooks';
import { ApolloError } from 'apollo-client';
import { useContext } from 'react';
import { StudentContext, SiteContext, useFullRoute } from '..';
import { useRouteMatch } from 'react-router';
import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
  setDay,
  addDays,
  setHours,
  setMinutes,
  getMonth,
  getYear,
  fromUnixTime
} from 'date-fns';
import MTSHelpers from '../../helpers/mtsHelpers';
import { UserContext } from '../UserProvider';
import { useMemo } from 'react';

export enum MTSCalendarViewType {
  Week,
  Month,
  Year,
  Custom,
  CustomWeek,
  CustomGrouped,
  Grouped
}

export interface MTSContextProps {
  loading: boolean;
  loadingPeriods: boolean;
  error?: ApolloError;
  viewType: MTSCalendarViewType;
  date: Date;
  startOfWeek: Date;
  endOfWeek: Date;
  mtses: MTS[];
  activityTypes: MTSActivityType[];
  selectedMTS?: MTS;
  month?: number;
  year?: number;
  canChangeSelectedMTS: boolean;
  canUploadSelectedMTS: boolean;
  mtsPeriod?: MTSPeriod;
  startDate?: Date;
  endDate?: Date;
  mtsPeriods: MTSPeriod[];
  allMtsPeriods: MTSPeriod[];
  groupMtsPeriods: MTSPeriodGroup[];
  mtsActivityTypeById: (activityTypeId: number) => MTSActivityType | undefined;
}

export const MTSContext = React.createContext<MTSContextProps>({
  loading: false,
  loadingPeriods: false,
  error: undefined,
  viewType: MTSCalendarViewType.Month,
  date: new Date(),
  startOfWeek: new Date(),
  endOfWeek: new Date(),
  mtses: [],
  activityTypes: [],
  selectedMTS: undefined,
  canChangeSelectedMTS: false,
  canUploadSelectedMTS: false,
  startDate: new Date(),
  endDate: new Date(),
  mtsPeriods: [],
  allMtsPeriods: [],
  groupMtsPeriods: [],
  mtsActivityTypeById: () => {
    return undefined;
  }
});

export const MTSProvider: React.FC = ({ children }) => {

  const fullRoute = useFullRoute();
  const match = useRouteMatch<{
    month?: string; year?: string;
    date?: string; periodId?: string;
    selectedDay?: string; groupId?: string;
  }>(fullRoute ?? '');
  let selectedMonth = match?.params.month ? Number(match.params.month) : undefined;
  if (selectedMonth != undefined) selectedMonth -= 1;

  const selectedDay = match?.params.date ? Number(match?.params.date) : undefined;
  const selectedYear = match?.params.year ? Number(match?.params.year) : undefined;
  const current = new Date();
  const currentYear = getYear(current);
  const currentMonth = getMonth(current);
  let selectedDate = new Date(selectedYear ?? currentYear, selectedMonth ?? currentMonth, selectedDay ?? 1);

  if (match?.params.selectedDay) {
    const selectedTimestamp = match.params.selectedDay ? Number(match.params.selectedDay) : undefined;
    selectedDate = selectedTimestamp ? fromUnixTime(selectedTimestamp) : new Date();
  }

  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const siteCtx = useContext(SiteContext);

  const mtsPeriodResults = useUserMTSPeriods();
  let mtsPeriods = (mtsPeriodResults.data?.site.mtsPeriods ?? []);
  const allMtsPeriods = mtsPeriods;

  let weekStart = setDay(selectedDate, siteCtx?.site?.mtsWeekStartDay ?? 0);
  weekStart = setHours(weekStart, 0);
  weekStart = setMinutes(weekStart, 0);
  const weekEnd = addDays(weekStart, 6);

  const activityTypesResult = useMTSUserActivityTypes({
    variables: {
      includeDisabled: false,
      includeExpiredGeneratedTypes: false,
      userId: studentCtx?.student.id
    },
    fetchPolicy: 'cache-and-network'
  });

  let startDate = new Date();
  let endDate = new Date();
  let periods: MTSPeriod[] | undefined;
  let periodGroups: MTSPeriodGroup[] = [];

  let viewType = MTSCalendarViewType.Month;
  if (match?.path.includes('/year')) {
    viewType = MTSCalendarViewType.Year;
  } else if (match?.path.includes('/week')) {
    viewType = MTSCalendarViewType.Week;
  } else if (match?.path.includes('/custom-week')) {
    viewType = MTSCalendarViewType.CustomWeek;
  } else if (match?.path.includes('/customgroup')) {
    viewType = MTSCalendarViewType.CustomGrouped;
  } else if (match?.path.includes('/custom')) {
    viewType = MTSCalendarViewType.Custom;
  }

  if (viewType === MTSCalendarViewType.Month) {
    startDate = startOfMonth(selectedDate);
    endDate = endOfMonth(selectedDate);
    startDate.setHours(6);
    endDate.setHours(6);
  } else if (viewType === MTSCalendarViewType.Week) {
    startDate = startOfMonth(weekStart);
    endDate = endOfMonth(weekEnd);
    startDate.setHours(6);
    endDate.setHours(6);
  } else if (viewType === MTSCalendarViewType.Year) {
    startDate = startOfYear(selectedDate);
    endDate = endOfYear(selectedDate);
    startDate.setHours(6);
    endDate.setHours(6);

    const year = getYear(selectedDate);
    periods = mtsPeriods.filter(p => {
      const periodDates = MTSHelpers.getPeriodDates(p);
      const startYear = getYear(periodDates.startDate);
      const endYear = getYear(periodDates.endDate);

      return (startYear === year || endYear === year);
    }).sort((a, b) => {
      return MTSHelpers.dateFromString(a.endDate).getTime() - MTSHelpers.dateFromString(b.endDate).getTime();
    });
  } else if (viewType === MTSCalendarViewType.Custom) {
    if (mtsPeriods.length > 0 && match?.params.periodId) {
      const period = mtsPeriods.find(p => p.id === Number(match.params.periodId));
      if (period) {
        const mtsDates = MTSHelpers.getPeriodDates(period);
        startDate = startOfWeek(mtsDates.startDate);
        endDate = endOfWeek(mtsDates.endDate);

        periods = [period];
      }
    }
  } else if (viewType === MTSCalendarViewType.CustomWeek) {
    startDate = weekStart;
    endDate = weekEnd;

    const filterDate = new Date(selectedDate);
    filterDate.setHours(6);
    filterDate.setMinutes(0);

    if (mtsPeriods.length > 0) {
      const period = mtsPeriods.find(p =>
        MTSHelpers.compareDateIgnoringTime(filterDate, MTSHelpers.dateFromString(p.startDate)) >= 0 &&
        MTSHelpers.compareDateIgnoringTime(filterDate, MTSHelpers.dateFromString(p.endDate, true)) <= 0
      );
      if (period) {
        periods = [period];
        const periodDates = MTSHelpers.getPeriodDates(period);

        startDate = startOfWeek(periodDates.startDate);
        endDate = endOfWeek(periodDates.endDate);
      }
    }
  } else if (viewType === MTSCalendarViewType.CustomGrouped) {
    if (mtsPeriods.length > 0 && match?.params.groupId) {
      periodGroups = mtsPeriods
        .filter(p => p.group != null)
        .filter(p => Number(p.group!.id) == Number(match.params.groupId))
        .map(p => p.group!);
      periodGroups = periodGroups.filter((g, i) => periodGroups.findIndex(sg => sg.id === g.id) === i);
      mtsPeriods = mtsPeriods.filter(p => p.group?.id === Number(match.params.groupId));
      periods = [...mtsPeriods];

      if (mtsPeriods.length > 0) {
        startDate = MTSHelpers.dateFromString(mtsPeriods[0].startDate);
        endDate = MTSHelpers.dateFromString(mtsPeriods[mtsPeriods.length - 1].endDate);
      }
    }
  }

  let period = periods && periods.length > 0 ? periods[0] : undefined;
  if (viewType === MTSCalendarViewType.CustomGrouped) {
    period = mtsPeriods.find(p =>
      MTSHelpers.compareDateIgnoringTime(selectedDate, MTSHelpers.dateFromString(p.startDate)) >= 0 &&
      MTSHelpers.compareDateIgnoringTime(selectedDate, MTSHelpers.dateFromString(p.endDate, true)) <= 0
    );
  }

  const { data, loading, error } = useMTSEntries(
    {
      variables: {
        userId: studentCtx?.student.id ?? 0,
        startDate: startDate,
        endDate: endDate,
        periodIds: periods && periods.length > 0 ? periods.map(p => p.id) : undefined
      }
    }
  );

  const mtses = useMemo(() => {
    return [...(data?.user?.mtsEntries ?? [])]
      .map(mts => (
        {
          ...mts,
          entries: {
            entries: mts.entries?.entries?.map(entry => ({
              ...entry,
              date: MTSHelpers.dateFromString(entry.date)
            }))
          }
        } as MTS)
      )
      .sort((a, b) => {
        if (a.mtsPeriod && b.mtsPeriod) {
          return MTSHelpers.dateFromString(a.mtsPeriod.endDate).getTime() - MTSHelpers.dateFromString(b.mtsPeriod.endDate, true).getTime();
        } else {
          return parseInt(`${a.year}${a.month}`) - parseInt(`${b.year}${b.month}`);
        }
      }
      );
  }, [data?.user?.mtsEntries]);

  const selectedMTS = useMemo(() => {
    if (mtses.length === 1) return mtses[0];

    if (mtses.length) {
      return MTSHelpers.mtsForDate(selectedDate, mtses, period);
    }
    return undefined;
  }, [selectedDate, period, mtses]);

  const canChangeMTS = useMemo(
    () => {
      if (userCtx.user && siteCtx.site) {
        return MTSHelpers.canChangeMTS(userCtx.user, selectedDate, siteCtx.site, selectedMTS, mtsPeriods);
      }

      return false;
    },
    [userCtx.user, selectedMTS, mtsPeriods, selectedDate]
  );

  const mtsActivityTypeById = (activityTypeId: number) => {
    return (activityTypesResult.data?.mtsUserActivityTypes ?? []).find(a => a.id === activityTypeId);
  };

  return (
    <MTSContext.Provider
      value={{
        loading: loading,
        loadingPeriods: mtsPeriodResults.loading,
        error: error,
        viewType: viewType,
        date: selectedDate,
        startOfWeek: weekStart,
        endOfWeek: weekEnd,
        mtses: mtses,
        activityTypes: activityTypesResult.data?.mtsUserActivityTypes ?? [],
        selectedMTS: selectedMTS,
        month: selectedMonth != undefined ? selectedMonth + 1 : undefined,
        year: selectedYear,
        canChangeSelectedMTS: canChangeMTS,
        canUploadSelectedMTS: MTSHelpers.canUploadMTS(userCtx.user, selectedMTS, (selectedMonth ?? 0) + 1, selectedYear),
        mtsPeriod: period,
        startDate: startDate,
        endDate: endDate,
        mtsPeriods: [...mtsPeriods].sort((a, b) => {
          return MTSHelpers.dateFromString(a.endDate).getTime() - MTSHelpers.dateFromString(b.endDate).getTime();
        }),
        allMtsPeriods: [...allMtsPeriods].sort((a, b) => {
          return MTSHelpers.dateFromString(a.endDate).getTime() - MTSHelpers.dateFromString(b.endDate).getTime();
        }),
        groupMtsPeriods: periodGroups,
        mtsActivityTypeById
      }}
    >
      {children}
    </MTSContext.Provider>
  );
};
