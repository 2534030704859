import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { SiteContext, StudentContext, UserContext } from '../../contexts';
import { FormQuestion, FormQuestionType, Role, SecuredFormSubmission, SecuredUser, SubmitFormInput } from '../../dto';
import { LocalStorageKey, useForm, useLocalStorage, useProfileSearch, useSendEmployeeInfo } from '../../hooks';
import { LanguageString } from '../Common';
import { DynamicForm } from '../DynamicForm';
import { convertFsToValues, getQuestionFormId, isDependsOnSatisfiedForAny, questionsIterator } from '../DynamicForm/common';
import { EmployerContactROCDialog } from './EmployerContactROCDialog';

type QuestionCache = { [qId: string]: FormQuestion };

const answersForQuestions = (formSubmission: SecuredFormSubmission, qc: QuestionCache) => {
  const answers: { [id: number]: string } = {};

  for (const answer of formSubmission.answers) {
    const question = qc[getQuestionFormId(answer.question)];
    if (question == null) continue;
    if (question.questionType === FormQuestionType.SELECT || question.questionType === FormQuestionType.MULTISELECT) {
      answers[answer.question.id] = answer.selections?.map(sel => question.options?.find(o => o.id === sel.id)?.text ?? 'Not Available').join() ?? 'Not Available';
    } else if (answer.answer) {
      if (answer.question.questionType === 'DATE') {
        answers[answer.question.id] = format(new Date(answer.answer), 'MM/dd/yyyy');
      } else if (answer.question.questionType !== 'PASSWORD' && answer.question.questionType !== 'HIDDEN') {
        answers[answer.question.id] = answer.answer;
      }
    }
  }

  return answers;
};

// const contactTypes = ['Agency', 'Employer'];
const distanceTypes = [5, 10, 15, 20, 25, 50, 100];

const EmployerContactTableRow: React.FC<{
  user: SecuredUser, distance: number, questions: FormQuestion[], qc: QuestionCache,
  formId?: number, checked: boolean, setChecked: (u: SecuredUser, c: boolean) => void,
  formSubmission: SecuredFormSubmission
}> = (props) => {

  const answers = useMemo(() => {
    return answersForQuestions(props.formSubmission, props.qc);
  }, [props.formSubmission]);

  let miles = Math.round(props.distance * 100 + Number.EPSILON) / 100;
  if (miles <= 0) miles = 0.1;

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          name={`user_${props.user.id}`}
          checked={props.checked}
          onChange={(e, c) => props.setChecked(props.user, c)}
        />
      </TableCell>
      <TableCell>{miles} {miles < 1 ? 'mile' : 'miles'}</TableCell>
      {props.questions.map(question => (
        <TableCell>
          {answers[question.id]}
        </TableCell>
      ))}
    </TableRow>
  );
};

export const EmployerContact: React.FC<{ viewerRole: Role }> = ({ viewerRole }) => {
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);
  const studentCtx = useContext(StudentContext);
  const [sendEmployeeInfo, { loading }] = useSendEmployeeInfo();
  const rowsPerPageOptions = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [recordOfContactsDialog, setRecordOfContactsDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [distance, setDistance] = useState(0);
  const [checkedUsers, setCheckedUsers] = useState<SecuredUser[]>([]);
  const [filters, setFilters] = useLocalStorage<SubmitFormInput | {}>(LocalStorageKey.EmployerFilters, {});

  const formId = useMemo(() =>
    viewerRole === Role.EMPLOYER ? siteCtx.site?.employeeProfileFormId : siteCtx.site?.employerProfileFormId,
  [viewerRole]);
  const { data: fdata, loading: floading } = useForm({ variables: { formId } });

  const users = useProfileSearch({
    variables: {
      as: viewerRole,
      excludeRole: viewerRole === Role.EMPLOYER ? viewerRole : undefined,
      maxDistance: distanceTypes[distance],
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      filter: Object.keys(filters).length > 0 ? (filters as SubmitFormInput) : undefined,
      formParams: { formIdArgs: { formId: formId ?? -1 } },
      viewedAsId: studentCtx?.student.id
    }
  });

  const questionCache: QuestionCache = useMemo(
    () =>
      fdata?.form.elements
        ? [...questionsIterator(fdata.form.elements)].reduce((pv, cv) => {
            pv[getQuestionFormId(cv)] = cv;
            return pv;
          }, {})
        : {},
    [fdata?.form]
  );
  const questions = useMemo(() => {
    const ques = fdata?.form.elements ?
      [...questionsIterator(fdata.form.elements)]
      :
      [];

    const userWithAnswers = users.data?.securedUsers.users.find(u => u.formSubmission?.answers.length);
    if (userWithAnswers?.formSubmission) {
      const filteredQuestions = ques.filter(q => {
        const answers = users.data?.securedUsers.users.map(u => u.formSubmission != null ? convertFsToValues(u.formSubmission, questionCache) : {});
        return answers ? isDependsOnSatisfiedForAny(answers, q) : false;
      });

      return filteredQuestions.filter(q => q.name?.includes('-visible'));
    }

    return [];
  }, [users, questionCache]);

  const getUserDistance = (user: SecuredUser) => Number(user.customProperties?.find(p => p.type?.name === 'distance')?.value) ?? Number.EPSILON;

  const setChecked = (user: SecuredUser, checked: boolean) => {
    if (checked) {
      const users = checkedUsers.filter(u => u.id !== user.id);
      setCheckedUsers([...users, user]);
    } else {
      setCheckedUsers(checkedUsers.filter(u => u.id !== user.id));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const toggleRecordOfContacts = () => {
    setRecordOfContactsDialog(!recordOfContactsDialog);
  };

  const sendInfo = async () => {
    if (checkedUsers.length) {
      await sendEmployeeInfo({
        variables: {
          toUsers: checkedUsers.map(u => u.id),
          currentUserId: studentCtx?.student.id ?? userCtx.user.id,
          currentRole: userCtx.user.currentRole
        }
      });
    }
  };

  return floading ? <CircularProgress color='primary' /> : (
    <Paper>
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="distance-label">
                <LanguageString groupName="EMPLOYER_CONTACT" resourceName="DISTANCE" defaultText="How far from your home would you like to search?" />
              </InputLabel>
              <Select
                fullWidth
                labelId="distance-label"
                name="distance"
                value={distance}
                onChange={(e) => setDistance(Number(e.target.value))}
                label={<LanguageString groupName="EMPLOYER_CONTACT" resourceName="DISTANCE" defaultText="How far from your home would you like to search?" />}
              >
                {distanceTypes.map((distanceType, index) => {
                  return (
                    <MenuItem key={`distancetype_${index}`} value={index}>
                      Up to {distanceType} miles
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <DynamicForm
              formId={siteCtx.site?.employerFilterForm?.id ?? 0}
              autoloadFormSubmission={false}
              showSubmitButton={false}
              autoSave={true}
              shouldSubmitOnChange={true}
              overrideFormSubmissionInput={Object.keys(filters).length > 0 ? (filters as SubmitFormInput) : undefined}
              overrideSubmitHandler={(input, form) => {
                if (!filters || filters !== { filters: input }) {
                  setFilters(input);
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <LanguageString groupName="EMPLOYER_CONTACT" resourceName="SEND_MY_INFO" defaultText="Send My Info" />
                    </TableCell>
                    <TableCell>
                      <LanguageString groupName="EMPLOYER_CONTACT" resourceName="DISTANCE_FROM_HONE" defaultText="Distance" />
                    </TableCell>
                    {questions.map(question => (
                      <TableCell>
                        <LanguageString languageString={question.text} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.data?.securedUsers.users.map(user => (
                    <EmployerContactTableRow
                      formSubmission={user.formSubmission!}
                      key={`user_row_${user.id}`}
                      user={user}
                      distance={getUserDistance(user)}
                      questions={questions}
                      formId={formId}
                      setChecked={setChecked}
                      checked={checkedUsers.find(u => u.id === user.id) != null}
                      qc={questionCache}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={users.data?.securedUsers.totalCount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(e,p) => setPage(p)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Button onClick={toggleRecordOfContacts} size="large" variant="outlined" color="primary">
                  <LanguageString groupName="EMPLOYER_CONTACT" resourceName="RECORD_OF_CONTACTS" defaultText="Record of Contacts" />
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={sendInfo} size="large" disabled={!checkedUsers.length || loading} variant="contained" color="primary">
                  <LanguageString groupName="EMPLOYER_CONTACT" resourceName="SEND_INFO" defaultText="Send My Info" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <EmployerContactROCDialog open={recordOfContactsDialog} toggle={toggleRecordOfContacts} />
    </Paper>
  );
};

export const EmpolyerContactWrapper = () => <EmployerContact viewerRole={Role.EMPLOYER} />;
export const EmpolyeeContactWrapper = () => <EmployerContact viewerRole={Role.STUDENT} />;
