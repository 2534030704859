import { gql, useApolloClient } from '@apollo/client';
import { QueryOptions } from 'apollo-client';
import { FormValidation, SubmitFormInput } from 'tuapath-common/generated/schema';

export function useValidateForm() {
  const client = useApolloClient();

  return async (options: Omit<QueryOptions<{ input: SubmitFormInput }>, 'query'>) => {
    const validateFormMutation = gql`
      query validateForm($input: SubmitFormInput!) {
        validateForm(input: $input) {
          isValid
          errors {
            questionId
            errorMessage
          }
        }
      }
    `;

    const results = await client.query<{ validateForm: FormValidation }, { input: SubmitFormInput }>({
      ...options,
      query: validateFormMutation
    });

    return results;
  };
}
