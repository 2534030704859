import React from 'react';
import { LanguageStringProvider } from '../contexts';
import { NavComponent } from '../dto';

export const renderRouteInLSProvider = (name: NavComponent | string, child: React.ReactNode) => {
  return (
    <LanguageStringProvider pageName={name}>
      {child}
    </LanguageStringProvider>
  );
};
