import { CircularProgress, Grid } from '@material-ui/core';
import * as React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { GeneralErrorMessage } from '../../components';
import { SuccessPath, User } from '../../dto';
import { usePath } from '../../hooks/gql/usePath';
import { useUser } from '../../hooks/gql/useUser';
import { UserContext } from '../UserProvider';

type Props = { student: User; path?: SuccessPath };

export const StudentContext = React.createContext<Props | undefined>(undefined);

export const StudentProvider: React.FC = ({ children }) => {
  const { studentId } = useParams<{ studentId: string }>();
  const userCtx = useContext(UserContext);
  const stdId = studentId != null ? Number(studentId) : userCtx.user.id;
  if (stdId == null || Number.isNaN(stdId)) throw new Error('StudentProvider can only be used with a path that includes :studentId');
  const { data: uData, loading: uLoading } = useUser({ variables: { userId: stdId } });
  const { data: pData, loading: pLoading } = usePath({ variables: { progId: uData?.user?.programIds[0] ?? -1 }, fetchPolicy: 'cache-first', skip: uData?.user == null });

  const ctx = uData?.user ? { student: uData?.user, path: pData?.path } : undefined;

  return uLoading || pLoading
    ? <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    : ctx != null
      ? <StudentContext.Provider value={ctx}>{children}</StudentContext.Provider>
      : <GeneralErrorMessage message="Error loading user" />;
};

export default StudentProvider;
