import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SiteContext } from '../../../contexts';
import { EmailDialog } from '../../Common';

const isElement = (el: EventTarget): el is HTMLDivElement => (el as HTMLDivElement).closest != null;

export const RawHTML: React.FC<{ html: string }> = ({ html }) => {
  const history = useHistory();
  const siteCtx = useContext(SiteContext);
  const [emailDialogInfo, setEmailDialogInfo] = useState<{ subject: string; support: boolean } | undefined>(undefined);

  const toggleEmailDialog = () => {
    setEmailDialogInfo(undefined);
  };

  const handleMailTo = (link: string) => {
    const l = link.replace('mailto:', '');
    let subject = '';
    let email = '';
    let support = false;

    if (l.includes('?')) {
      email = l.substr(0, l.indexOf('?'));
      const queryString = l.replace(email, '');
      const params = new URLSearchParams(queryString);
      subject = params.get('subject') ?? '';
    } else {
      email = l;
    }

    if (email === siteCtx.site?.defaultContactEmail) {
      support = true;
    }

    setEmailDialogInfo({ subject, support });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const t = e.target;
    if (isElement(t)) {
      const targetLink = t.closest('a');
      if (!targetLink) return;
      e.preventDefault();

      const pageURL = window.location.hostname.toLocaleLowerCase();
      const href = targetLink.getAttribute('href');

      if (href && href?.includes('@')) {
        handleMailTo(href);
        return;
      }

      if (href && href.length > 0) {
        if (href.toLocaleLowerCase().includes(pageURL) || !href.includes('http')) {
          // Check if the url is local or is relative. If so, use react router
          history.push(href);
        } else {
          window.open(href, '_blank');
        }
      }
    }
  };

  const filteredHTML = html
    .replace('<script', '')
    .replace('</script>', '');

  return (
    <>
      <div className="resstring" onClick={handleClick} dangerouslySetInnerHTML={{ __html: filteredHTML }} role="none" />
      <EmailDialog
        open={emailDialogInfo !== undefined}
        toggle={toggleEmailDialog}
        sendToSupport={emailDialogInfo?.support ?? false}
        initialSubject={emailDialogInfo?.subject ?? ''}
      />
    </>
  );
};

export default RawHTML;
