import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { AddMilestoneToPathMutationArgs, User } from '../../dto';
import { assignmentResultsFrag, roadmapFrag } from './fragments';

type Result = { addMilestoneToPath: User };
type Variables = AddMilestoneToPathMutationArgs;
export function useAddMilestoneToPath(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${assignmentResultsFrag}
    ${roadmapFrag}
    mutation addMilestoneToPath($input: AddMilestoneInput!) {
      addMilestoneToPath(input: $input) {
        id
        assignments {
          ...AssignmentResultsFrag
        }
        roadmap {
          ...RoadmapFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useAddMilestoneToPath;
