import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { LanguageString, PageStringsQueryArgs } from '../../dto';
import { languageStringFrag } from './fragments';

type Variables = PageStringsQueryArgs;
type Results = { pageStrings: LanguageString[] };

const query = gql`
  ${languageStringFrag}
  query getPageStrings($pageName: String!, $languageId: Int, $userId: Int, $renderUserData: Boolean) {
    pageStrings(pageName: $pageName, languageId: $languageId, userId: $userId, renderUserData: $renderUserData) {
      ...LangStringFrag
    }
  }
`;

export const usePageStrings = (options: QueryHookOptions<Results, Variables> & { variables: Variables, nextFetchPolicy?: 'cache-first' } | undefined) => {
  return useQuery<Results>(query, options);
};

export default usePageStrings;
