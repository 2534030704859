import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { RegistrationInput, RegisterResult } from 'tuapath-common/generated/schema';

type Variables = { input: RegistrationInput };
type Result = { register: RegisterResult };
export const useRegister = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation register($input: RegistrationInput!) {
      register(data: $input) {
        success
        reason
        propId
        value
      }
    }
  `;

  return useMutation<Result, Variables>(mutation);
};
