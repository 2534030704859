import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { notificationsFrag } from './fragments';

type Result = { read: Boolean };
type Variables = { id: number };
export const useNotificationMarkRead = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
  ${notificationsFrag}
    mutation markNotificationRead($id: Int!) {
      userNotificationMarkRead(id: $id) {
        ...NotificationsFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};

export default useNotificationMarkRead;
