import { convertToQueryParamStructure, QueryParams, SupportedQueries } from '../../hooks/Form/common';
import * as Query from '../../hooks/gql';

export function useFormQuery<T extends keyof SupportedQueries>(queryName: T, paramInfo: QueryParams, queryArgs?: object) {
  const query = Query[queryName];

  const qparams = convertToQueryParamStructure(queryName, paramInfo, queryArgs) as Parameters<typeof query>[0];

  // TODO: Fix this 'any'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (qparams != null) return query(qparams as any);
  else throw new Error('No params');
}
