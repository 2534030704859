let __nextId = -1;
export type IdContext = {
  nextId: number;
  currentId: number;
};

export const tmpId: IdContext = {
  get nextId() { return --__nextId; },
  get currentId() { return __nextId; }
};
