import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { StudentsOrder, SubmitFormInput, User } from '../../dto';
import { ReqVars } from './common';
import { assignmentResultsFrag } from './fragments';

type Variables = {
  limit: number;
  offset: number;
  advisorIds?: number[];
  search?: string;
  order?: StudentsOrder;
  filter?: SubmitFormInput;
};
type Results = {
  students2: {
    totalCount: number;
    students: Pick<User, 'assignments' | 'firstName' | 'id' | 'lastName'>[];
  };
};

const query = gql`
  ${assignmentResultsFrag}

  query getStudentsWithAssignments($limit: Int!, $offset: Int!, $advisorIds: [Int], $search: String, $order: StudentsOrder, $filter: SubmitFormInput) {
    students2(limit: $limit, offset: $offset, advisorIds: $advisorIds, search: $search, order: $order, filter: $filter) {
      totalCount
      students {
        id
        firstName
        lastName
        assignments {
          ...AssignmentResultsFrag
        }
      }
    }
  }
`;

export const useStudentsWithAssignments = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
