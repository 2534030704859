import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { LanguageString as LanguageStringDto } from '../../dto';
import { usePageStrings, useUpdatePageAssociation } from '../../hooks';
import { LanguageStringPageAssociationInput, NavComponent } from 'tuapath-common/generated/schema';
import { UserContext } from '../UserProvider';
import { StudentContext } from '../StudentProvider';

interface ILanguageStringInput { groupName: string, resourceName: string };
interface ILanguageStringParentInput {
  usedLanguageStrings: number[];
  nullLanguageStrings: ILanguageStringInput[];
};
export interface ILanguageStringContext {
  pageName: string;
  languageStrings: LanguageStringDto[];
  addUsedLanguageStrings: (numbers: number) => void;
  addNullLanguageStrings: (ls: ILanguageStringInput) => void;
}

const parentContexts: { [pageName: string]: ILanguageStringParentInput } = {};

export const LanguageStringContext = React.createContext<ILanguageStringContext>({
  pageName: '',
  languageStrings: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addUsedLanguageStrings: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addNullLanguageStrings: () => { }
});

export const LanguageStringProvider: React.FC<{ pageName: string }> = (props) => {
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const userId = useMemo(() => {
    return studentCtx?.student.id ?? userCtx.user?.id;
  }, [studentCtx?.student, userCtx.user]);
  const pageStrings = usePageStrings({ variables: { pageName: props.pageName, userId } });
  const [updatePageAssociation] = useUpdatePageAssociation();

  const updatePageStringAssociation = useCallback(() => {
    const context = parentContexts[props.pageName];

    if (!context) return;

    const unusedLanguageStrings = pageStrings.data?.pageStrings.filter(s => !context.usedLanguageStrings.includes(s.id));
    const inputs: LanguageStringPageAssociationInput[] = [];

    if (unusedLanguageStrings) {
      for (const unusedString of unusedLanguageStrings) {
        if (unusedString.group && unusedString.group.groupName && unusedString.group.resourceName) {
          inputs.push({
            pageName: props.pageName,
            groupName: unusedString.group.groupName,
            resourceName: unusedString.group.resourceName,
            add: false
          });
        }
      }
    }

    for (const nullString of context.nullLanguageStrings) {
      inputs.push({
        pageName: props.pageName,
        groupName: nullString.groupName,
        resourceName: nullString.resourceName,
        add: true
      });
    }

    if (inputs.length > 0) {
      updatePageAssociation({ variables: { inputs } }).then(result => {
        parentContexts[props.pageName] = {
          nullLanguageStrings: [],
          usedLanguageStrings: []
        };
      }).catch(e => {
        console.log(`Failed to remove string from page with error: `, e);
      });
    }
  }, [parentContexts, pageStrings]);

  useEffect(() => {
    parentContexts[props.pageName] = {
      nullLanguageStrings: [],
      usedLanguageStrings: []
    };

    if (props.pageName === NavComponent.MainContent) {
      // This exists to update the page string associations for the main content route because the user won't ever navigate away from it
      // until the page is has been closed and in which case our useEffect deallocation function likely won't run
      setTimeout(() => {
        console.log(`calling MainContent updatePageStringAssociation`);
        updatePageStringAssociation();
      }, 10000);
    }

    return updatePageStringAssociation;
  }, []);

  const addUsedLanguageStrings = (id: number) => {
    const page = parentContexts[props.pageName];
    if (page && !page.usedLanguageStrings.includes(id)) {
      parentContexts[props.pageName].usedLanguageStrings = [...page.usedLanguageStrings, id];
    }
  };

  const addNullLanguageStrings = (languageString: ILanguageStringInput) => {
    const page = parentContexts[props.pageName];
    if (page && !page.nullLanguageStrings.find(s => s.groupName === languageString.groupName && s.resourceName === languageString.resourceName)) {
      parentContexts[props.pageName].nullLanguageStrings = [...page.nullLanguageStrings, languageString];
    }
  };

  return (
    <LanguageStringContext.Provider
      value={{
        pageName: props.pageName,
        languageStrings: pageStrings.data?.pageStrings ?? [],
        addUsedLanguageStrings,
        addNullLanguageStrings
      }}
    >
      {!pageStrings.loading && props.children}
    </LanguageStringContext.Provider>
  );
};

export const usePageString = (groupName?: string, resourceName?: string) => {
  if (!groupName || !resourceName) return undefined;

  const lsCtx = useContext(LanguageStringContext);
  if (lsCtx.pageName.length <= 0) return;

  const ls = lsCtx.languageStrings.find(s => s.group?.groupName === groupName && s.group?.resourceName === resourceName);
  if (ls) {
    // The string wasn't null and wasn't already added to the found strings array
    lsCtx.addUsedLanguageStrings(ls.id);
  }
  if (!ls) {
    // The language string was null in the context and wasn't already found in the null strings array
    lsCtx.addNullLanguageStrings({ groupName, resourceName });
  }

  return ls;
};
