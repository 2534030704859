import { ThemeProvider } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, generatePath, matchPath, useLocation } from 'react-router-dom';
import { AccountSetup, Login, Logout, RouteBuilder } from '..';
import { AppProvider, ApplicationInsightsProvider, SiteContext, UserContext, UserProvider } from '../../contexts';
import { NavComponent, NavRoute, Role, User } from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useCustomTheme } from '../../hooks';
import { InvalidSite } from '../InvalidSite';
import { ExternalMTSApproval } from '../MTS/ExternalMTSApproval/ExternalMTSApproval';
import AppErrors from './AppErrors';

const AuthRedirect: React.FC = () => {
  const location = useLocation();

  return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
};

const filterChildRoutes = (route: NavRoute, user: User): NavRoute => ({
  ...route,
  children: route.children?.filter(rt => UserHelper.userHasRole(user, rt.role as Role)).map(rc => filterChildRoutes(rc, user))
});

const matchPathRecursive = (match: string, rtPath: string, route: NavRoute): NavRoute | null | undefined => {
  const matched = matchPath(match, { path: rtPath, exact: !route.isRecursive, strict: false });

  if (route.isRecursive) {
    return matched != null
      ? matchPath(match, { path: rtPath, exact: true, strict: false }) != null
        ? route
        : matchPathRecursive(match, `${rtPath}${route.path}`, route) ?? findRoute(match, route.children, rtPath)
      : undefined;
  }

  return matched == null ? findRoute(match, route.children, rtPath) : route;
};

const findRoute = (path: string, routes?: NavRoute[], rtPath?: string): NavRoute | undefined => {
  let rtnRt: NavRoute | undefined;

  if (routes != null) {
    rtnRt = routes.find(rt => matchPathRecursive(path, `${rtPath ?? ''}${rt.path}`, rt));
    if (rtnRt == null) {
      for (const rt of routes) {
        rtnRt = findRoute(path, rt.children, rt.path);
        if (rtnRt != null) return rtnRt;
      }
    }
  }

  return rtnRt;
};

// TODO: Come up with better design for getting the routes for the current role. This is a quick, temporary solution.
const RoleRouteBuilder: React.FC = () => {
  const location = useLocation();
  const siteCtx = useContext(SiteContext);
  const { user } = useContext(UserContext);

  let roleRoutes = siteCtx.site?.navRoutes?.find(nr => nr.role === user.currentRole) ?? {
    id: -1,
    name: 'temp-route',
    title: { id: -1, text: 'Main Content' },
    path: '/',
    role: user.currentRole ?? 'STUDENT',
    isExact: false,
    isRecursive: false,
    component: NavComponent.MainContent,
    sortOrder: 0,
    children: undefined
  };

  if (user != null) roleRoutes = filterChildRoutes(roleRoutes, user);

  return findRoute(location.pathname, roleRoutes.children) ? (
    <RouteBuilder route={roleRoutes} />
  ) : (
    <Redirect from="/" exact to={generatePath(roleRoutes.children?.[0].href ?? roleRoutes.children?.[0].path ?? '/')} />
  );
};

export const App: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const theme = useCustomTheme();

  return siteCtx.site?.navRoutes ? (
    <ThemeProvider theme={theme}>
      <Router>
        <ApplicationInsightsProvider instrumentationKey={siteCtx.site?.applicationInsightsKey}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Login />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="/approve-activities/:token">
              <ExternalMTSApproval />
            </Route>
            <Route path="/accountsetup/:token">
              <AccountSetup />
            </Route>
            <AppProvider>
              <UserProvider>
                <RoleRouteBuilder />
                <AppErrors />
              </UserProvider>
            </AppProvider>
            <AuthRedirect />
          </Switch>
        </ApplicationInsightsProvider>
      </Router>
    </ThemeProvider>
  ) : (
    <InvalidSite />
  );
};

export default App;
