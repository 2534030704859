import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { FormValidation, SubmitFormInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Result = { submitForm: QueryType<FormValidation> };
type Variables = { input: SubmitFormInput };
export function useLazyValidateForm(options?: LazyQueryHookOptions<Result, Variables>) {
  const validateFormMutation = gql`
    query validateForm($input: SubmitFormInput!) {
      submitForm(input: $input) {
        isValid
        errors {
          questionId
          errorMessage
        }
      }
    }
  `;

  return useLazyQuery<Result, Variables>(validateFormMutation, options);
}
