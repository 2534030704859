import React, { useMemo } from 'react';
import { curveLinear } from '@visx/curve';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { scaleTime, scaleLinear } from '@visx/scale';

export interface GraphData {
  value: number;
  date: Date;
  color?: string;
}

interface FormGraphInnerInterface {
  yAxisLabel?: string;
  xAxisLabel?: string;
  data: GraphData[];
  width: number;
  height: number;
}

const x = (d: GraphData) => new Date(d.date).valueOf();
const y = (d: GraphData) => d.value;

export const LineChart: React.FC<FormGraphInnerInterface> = ({ data, width, height, yAxisLabel, xAxisLabel }) => {
  const xMax = width - 120;
  const yMax = height - 80;
  const numTicks = useMemo(() => {
    return Math.max(...data.map(d => d.value));
  }, [data]);
  const xScale = scaleTime({
    range: [0, xMax],
    domain: [Math.min(...data.map(x)), Math.max(...data.map(x))]
  });
  const yScale = scaleLinear({
    range: [0, yMax],
    domain: [Math.max(...data.map(y)), 0]
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg width={width} height={height}>
        <Group top={25} left={65}>
          <AxisLeft
            scale={yScale}
            numTicks={numTicks}
            label={yAxisLabel ?? 'Answers'}
          />
          <AxisBottom
            scale={xScale}
            label={xAxisLabel ?? 'Date'}
            labelOffset={15}
            numTicks={5}
            top={yMax}
          />
          {data.map((point, pointIndex) => (
            <circle
              key={pointIndex}
              r={5}
              cx={xScale(x(point))}
              cy={yScale(y(point))}
              stroke={point.color ?? '#222222'}
              fill={point.color ?? '#222222'}
              fillOpacity={1}
            />
          ))}
          <LinePath
            data={data}
            curve={curveLinear}
            x={(d) => xScale(x(d))}
            y={(d) => yScale(y(d))}
            stroke="#222222"
            strokeWidth={1.5}
          />
        </Group>
      </svg>
    </div>
  );
};

export default LineChart;
