import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Roadmap, UpdateRoadmapInput } from '../../dto';
import { QueryType } from '../../helpers/query';

type Result = { updateRoadmap: QueryType<Roadmap> };
type Variables = { input: UpdateRoadmapInput };
export function useUpdateRoadmap(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    mutation updateRoadmap($input: UpdateRoadmapInput!) {
      updateRoadmap(input: $input) {
        id
        goal
        employmentInfo
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateRoadmap;
