import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { UpdateStringPageAssociationMutationArgs } from 'tuapath-common/generated/schema';

type Result = { success: boolean };
type Variables = UpdateStringPageAssociationMutationArgs;
export const useUpdatePageAssociation = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation useUpdatePageAssociation($inputs: [LanguageStringPageAssociationInput]!) {
      updateStringPageAssociation(inputs: $inputs)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useUpdatePageAssociation;
