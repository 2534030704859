import { Grid } from '@material-ui/core';
import { Form } from 'formik';
import React from 'react';
import { ColorPicker, LSTextField } from '..';

type GroupForm = { submitForm: () => void };

export const GroupForm: React.FC = () => (
  <Form>
    <Grid container spacing={2} direction="column">
      <LSTextField item name="name" label="Name" />
      <LSTextField item name="description" label="Description" />
      <ColorPicker item name="color" label="Color" />
    </Grid>
  </Form>
);
