import React, { useMemo } from 'react';
import {
  Grid
} from '@material-ui/core';
import { addDays } from 'date-fns';
import CalendarMonthViewCell from '../CalendarMonthView/CalendarMonthViewCell';
import { useClasses } from '../../../hooks';
import { MTSPeriod } from '../../../dto';
import { useContext } from 'react';
import { MTSContext } from '../../../contexts';
import MTSHelpers from '../../../helpers/mtsHelpers';

interface CalendarCustomGroupedViewRowProps {
  periodStartDate: Date;
  periodEndDate: Date;
  mtsPeriod: MTSPeriod;
  weekStartDate: Date;
}

export const CalendarCustomGroupedViewRow: React.FC<CalendarCustomGroupedViewRowProps> = ({ periodStartDate, periodEndDate, mtsPeriod, weekStartDate }) => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  const cells = useMemo(() => {
    const endOfWeek = addDays(weekStartDate, 7);
    endOfWeek.setHours(0, 0, 0, 0);

    const c: React.ReactNode[] = [];
    for (let i = 0; i <= 6; i++) {
      const date = addDays(weekStartDate, i);

      const period = mtsCtx.mtsPeriods.find(p =>
        MTSHelpers.compareDateIgnoringTime(date, MTSHelpers.dateFromString(p.startDate)) >= 0 &&
        MTSHelpers.compareDateIgnoringTime(date, MTSHelpers.dateFromString(p.endDate, true)) <= 0
      );

      c.push(
        <CalendarMonthViewCell
          key={`custom_month_cell_${date}`}
          showMonth
          mtsPeriod={mtsPeriod}
          date={date}
          monthDate={date}
          periodStartDate={periodStartDate}
          periodEndDate={periodEndDate}
          shouldCheckCellInactive={period == null}
        />
      );
    }

    return c;
  }, [weekStartDate, periodStartDate]);

  return (
    <Grid item xs={12}>
      <Grid className={classes.mtsMonthRow} container direction="row" justify="flex-start">
        {cells}
      </Grid>
    </Grid>
  );
};
export default CalendarCustomGroupedViewRow;
