import gql from 'graphql-tag';
import { roadmapTaskFrag } from './roadmapTaskFrag';

export const roadmapMilestoneFrag = gql`
  ${roadmapTaskFrag}

  fragment RoadmapMilestoneFrag on RoadmapMilestone {
    id
    milestoneId
    sortIndex
    status
    tasks {
      ...RoadmapTaskFrag
    }
  }
`;
