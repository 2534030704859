import { Button, DialogActions, DialogContentText, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForgotPassword } from '../../../hooks';
import { LanguageString, SuccessIconMessage } from '../../Common';

interface ForgotPasswordProps {
  toggle: () => void;
}

interface ForgotPasswordSchema {
  email: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ toggle }) => {
  const formRef = useRef<FormikProps<ForgotPasswordSchema> | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [forgotPassword] = useForgotPassword();

  const initialValues: ForgotPasswordSchema = {
    email: ''
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const Validation = Yup.object().shape({
    email: Yup.string().email('* Invalid Email').required('* Required')
  });

  const handleSubmit = (values: ForgotPasswordSchema, formikHelpers: FormikHelpers<ForgotPasswordSchema>) => {
    forgotPassword({
      variables: {
        input: {
          email: values.email
        }
      },
      update: (cache, result) => {
        if (result.data?.forgotPassword.success === true) {
          formikHelpers.setStatus({
            submitted: true,
            message: undefined
          });

          setSubmitted(true);
        } else {
          formikHelpers.setStatus({
            submitted: false,
            message: 'Oops. Something went wrong'
          });
        }
      }
    }).catch(res => {
      const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
        return error.message;
      });

      if (errors.length > 0) {
        formikHelpers.setStatus({
          submitted: false,
          message: errors[0]
        });
      }
    });
  };

  const localToggle = () => {
    setSubmitted(false);
    toggle();
  };

  return (
    <>
      <DialogContentText>
        <LanguageString
          groupName="GENERAL"
          resourceName="FORGOT_PASSWORD_DESCRIPTION"
          defaultText="To get your username sent to you or reset your password please enter your email address"
        />
      </DialogContentText>

      <Formik
        initialValues={initialValues}
        initialStatus={initialStatus}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={Validation}
      >
        {formikProps => {
          formRef.current = formikProps;

          const status = formikProps.status as FormStatus;

          if (status.submitted) {
            return <SuccessIconMessage
              smallMessage
              animated
              message={
                <LanguageString
                  variant="subtitle1"
                  groupName="GENERAL"
                  resourceName="PASSWORD_RESET_SUCCESS"
                  defaultText="If this user exists in the system they will receive an email with further instructions"
                />
              }
            />;
          }

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field validateOnBlur validateOnChange name="email">
                    {() => (
                      <TextField
                        fullWidth
                        name="email"
                        label={<LanguageString groupName="GENERAL" resourceName="EMAIL" defaultText="Email" />}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        error={Boolean(formikProps.errors.email && formikProps.touched.email)}
                        helperText={formikProps.errors.email && formikProps.touched.email && String(formikProps.errors.email)}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              {(formikProps.status as FormStatus).message && (
                <Grid item xs={12}>
                  <Alert severity="error">{formikProps.status.message}</Alert>
                </Grid>
              )}
            </Form>
          );
        }}
      </Formik>
      <DialogActions>
        <Button onClick={localToggle} color="primary">
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        {!submitted && (
          <Button variant="contained" onClick={() => formRef.current?.submitForm()} color="primary">
            <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
          </Button>
        )}
      </DialogActions>
    </>
  );
};
export default ForgotPassword;
