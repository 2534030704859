import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { sendSupportEmail: boolean };
type Variables = { subject: string, body: string };
export const useSendSupportEmail = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation sendSupportEmail($subject: String!, $body: String!) {
      sendSupportEmail(subject: $subject, body: $body)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
