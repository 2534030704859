import gql from 'graphql-tag';
import { assignmentFrag } from './assignmentFrag';

export const assignmentResultsFrag = gql`
  ${assignmentFrag}
  fragment AssignmentResultsFrag on AssignmentResults {
    totalCount
    assignments {
      ...AssignmentFrag
    }
  }
`;

export default assignmentResultsFrag;
