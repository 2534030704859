import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { notificationTriggerFrag } from './fragments';

type Results = { site: Site };
export const useNotificationTriggers = (options?: QueryHookOptions<Results>) => {
  const query = gql`
    ${notificationTriggerFrag}

    query notificationTriggers {
      site {
        id
        notificationTriggers {
          ...NotificationTriggerFrag
        }
      }
    }
  `;

  return useQuery<Results>(query, options);
};

export default useNotificationTriggers;
