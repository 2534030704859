import { gql, QueryHookOptions } from '@apollo/client';
import { User } from '../../dto';
import { ReqVars, useMappedQuery } from './common';
import { attachmentFrag } from './fragments/attachmentFrag';

type Result = { useUserAttachments?: User };
type Variables = { userId: number | null };
const query = gql`
  ${attachmentFrag}

  query useUserAttachments($userId: Int!, $withUser: Boolean!) {
    user(id: $userId) @include(if: $withUser) {
      id
      files {
        ...AttachmentFrag
      }
    }
  }
`;

export const useUserAttachemnts = (options: ReqVars<QueryHookOptions<Result, Variables>>) =>
  useMappedQuery(query, options, vars => ({ ...vars, withUser: vars.userId != null }));
