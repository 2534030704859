import React, { useContext, useEffect, useMemo } from 'react';
import { useCoachHomePage } from '../../hooks';
import {
  CardContent,
  CircularProgress,
  Grid} from '@material-ui/core';
import { CaseLoadTable } from './CaseLoadTable';
import { GeneralErrorMessage } from '../Common';
import { getMonth, getYear, subMonths } from 'date-fns';
import * as DTO from '../../dto';
import { CaseLoadOrderData } from '../../types';
import { CaseLoadOrderColumn } from './CaseLoadCells';
import { MTSPeriod } from 'tuapath-common/generated/schema';
import { SiteContext } from '../../contexts';


interface CaseLoadDataProps {
  filterAdvisorIds: number[]
  realSearchText: string | undefined
  rowsPerPage: number
  page: number
  currentPeriod: MTSPeriod | undefined
  previousPeriod: MTSPeriod | undefined
  twoPeriodsAgo: MTSPeriod | undefined
  selectedPathTab: number
  order: CaseLoadOrderData
  filters: DTO.SubmitFormInput | {}
  clickedHeader: (column: CaseLoadOrderColumn) => void
  totalCount: number
  setTotalCount: (count: number) => void
}

export const CaseLoadData: React.FC<CaseLoadDataProps> = React.memo((props) => {
  const siteCtx = useContext(SiteContext);
  const dates = useMemo(() => {
    const currentDate = new Date();
    const lastMonth = subMonths(currentDate, 1);
    const twoMonthsAgo = subMonths(currentDate, 2);

    return {
      currentMonth: getMonth(currentDate) + 1,
      currentMonthYear: getYear(currentDate),
      lastMonth: getMonth(lastMonth) + 1,
      lastMonthYear: getYear(lastMonth),
      twoMonthsAgo: getMonth(twoMonthsAgo) + 1,
      twoMonthsAgoYear: getYear(twoMonthsAgo)
    };
  }, []);

  const { data, error, loading } = useCoachHomePage({
    variables: {
      search: props.realSearchText && props.realSearchText.length > 0 ? props.realSearchText : undefined,
      advisorIds: props.filterAdvisorIds,
      currentMonth: dates.currentMonth,
      currentMonthYear: dates.currentMonthYear,
      currentPeriodId: props.currentPeriod?.id,
      lastMonth: dates.lastMonth,
      lastMonthYear: dates.lastMonthYear,
      lastPeriodId: props.previousPeriod?.id,
      twoMonthsAgo: dates.twoMonthsAgo,
      twoMonthsAgoYear: dates.twoMonthsAgoYear,
      twoPeriodsAgoId: props.twoPeriodsAgo?.id,
      limit: props.rowsPerPage,
      offset: props.rowsPerPage * props.page,
      order: props.order.order,
      filter: Object.keys(props.filters).length > 0 ? (props.filters as DTO.SubmitFormInput) : undefined,
      pathIds: props.selectedPathTab ? [props.selectedPathTab] : undefined,
      customPropertyNames: siteCtx.site?.caseLoadCustomPropertyColumnsQuery ? siteCtx.site?.caseLoadCustomPropertyColumnsQuery.split(',') : []
    }
  });

  useEffect(() => {
    const c = data?.pageInfo.totalCount ?? 0;
    if (props.totalCount !== c) props.setTotalCount(c);
  }, [data?.pageInfo.totalCount, props.totalCount, props.setTotalCount]);

  if (loading && data?.pageInfo.students && data.pageInfo.students.length > 0) {
    return (
      <Grid item>
        <CircularProgress size={27} color="primary" />
      </Grid>
    );
  }

  return (
    <CardContent>
      <Grid container>
        <Grid item xs style={{ overflowX: 'auto' }}>
          <CaseLoadTable
            clickedHeader={props.clickedHeader}
            students={data?.pageInfo.students ?? []}
            order={props.order}
            currentPeriod={props.currentPeriod}
            previousPeriod={props.previousPeriod}
            twoPeriodsAgo={props.twoPeriodsAgo}
          />

          <Grid container justify="center" alignItems="center" style={{ padding: 20 }}>
            <Grid item>
              {error && <GeneralErrorMessage message={error.message} />}

              {loading && <CircularProgress color="primary" />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
});
