import { Alert } from '@material-ui/lab';
import React from 'react';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  ActionStepLibrary,
  CareerOneStop,
  CaseLoad,
  CaseLoadNR,
  DownloadRoute,
  EmpolyeeContactWrapper,
  EmpolyerContactWrapper,
  FileBox,
  FormRoute,
  LanguageString,
  MainContent,
  MilestoneView,
  MsGroup,
  MTS,
  NotificationBlast,
  PathEditor,
  PlacementPage,
  Profile,
  RecordOfContacts,
  Resources,
  ReviewAllMTSInfoPage,
  RoadmapPrint,
  SnapShot,
  Students,
  StylesEditor
} from '..';
import { MilestoneProvider, MTSProvider, RouteContext, StudentProvider } from '../../contexts';
import { NavComponent, NavRoute } from '../../dto';
import { renderRouteInLSProvider } from '../../helpers/languageStringHelpers';
import { MsGroupCrumb } from '../ActionStepLibrary/DisplayPath';
import { DataImport } from '../DataImport/DataImport';
import { FormEditorView } from '../FormEditor';
import { MilestoneEditor } from '../MilestoneEditor';
import { MsGroupProvider } from '../MsGroupProvider';
import { CalendarCustomGroupedView, CalendarCustomView, CalendarCustomWeekView, CalendarMonthView, CalendarWeekView, CalendarYearView, MTSDialog } from '../MTS';
import { MTSActivityTypeEditor } from '../MTSActivityTypeEditor';
import { MTSPeriodEditor } from '../MTSPeriodEditor';
import { NotificationSchedulesTable, NotificationsEditor, NotificationTemplatesTable } from '../NotificationEditor';
import PBIEmbed from '../PowerBI';
import Reports from '../Reports';
import { RoadmapViewMain } from '../Roadmap/RoadmapView';

export const RouteComponent: React.FC<{ name: NavComponent, styleHack: boolean }> = ({ name, styleHack }) => {
  switch (name) {
    case NavComponent.ActionStepLibrary:
      return <ActionStepLibrary />;
    case NavComponent.CaseLoadNR:
      return <CaseLoadNR />;
    case NavComponent.DataImport:
      return <DataImport />;
    case NavComponent.DownloadRoute:
      return <DownloadRoute />;
    case NavComponent.PathBreadcrumb:
      return (
        <MsGroupProvider>
          <MsGroupCrumb />
        </MsGroupProvider>
      );
    case NavComponent.FileBox:
      return <FileBox />;
    case NavComponent.FormEditor:
      return <FormEditorView />;
    case NavComponent.FormRoute:
      return <FormRoute />;
    case NavComponent.MainContent:
      return <MainContent styleHack={styleHack} />;
    case NavComponent.Milestone:
      return (
        <MilestoneProvider>
          <MilestoneView />
        </MilestoneProvider>
      );
    case NavComponent.MilestoneEditor:
      return <MilestoneEditor />;
    case NavComponent.MsGroup:
      return (
        <MsGroupProvider>
          <MsGroup />
        </MsGroupProvider>
      );
    case NavComponent.MTS:
      return <MTS />;
    case NavComponent.MTSActivityTypeEditor:
      return <MTSActivityTypeEditor />;
    case NavComponent.ReviewAllMTSInfoPage:
      return <ReviewAllMTSInfoPage />;
    case NavComponent.PathEditor:
      return <PathEditor />;
    case NavComponent.RecordOfContacts:
      return <RecordOfContacts />;
    case NavComponent.Roadmap:
      return <RoadmapViewMain />;
    case NavComponent.StudentProvider:
      return <StudentProvider />;
    case NavComponent.Students:
      return <Students />;
    case NavComponent.StylesEditor:
      return <StylesEditor />;
    case NavComponent.Profile:
      return <Profile />;
    case NavComponent.CareerOneStop:
      return <CareerOneStop />;
    case NavComponent.MTSDialog:
      return (
        <MTSProvider>
          <MTSDialog />
        </MTSProvider>
      );
    case NavComponent.CalendarMonthView:
      return (
        <MTSProvider>
          <CalendarMonthView />
        </MTSProvider>
      );
    case NavComponent.CalendarCustomView:
      return (
        <MTSProvider>
          <CalendarCustomView />
        </MTSProvider>
      );
    case NavComponent.CalendarCustomGroupedView:
      return (
        <MTSProvider>
          <CalendarCustomGroupedView />
        </MTSProvider>
      );
    case NavComponent.CalendarCustomWeekView:
      return (
        <MTSProvider>
          <CalendarCustomWeekView />
        </MTSProvider>
      );
    case NavComponent.CalendarYearView:
      return (
        <MTSProvider>
          <CalendarYearView />
        </MTSProvider>
      );
    case NavComponent.CalendarWeekView:
      return (
        <MTSProvider>
          <CalendarWeekView />
        </MTSProvider>
      );
    case NavComponent.Resources:
      return <Resources />;
    case NavComponent.PlacementPage:
      return <PlacementPage />;
    case NavComponent.SnapShot:
      return <SnapShot />;
    case NavComponent.Reports:
      return <Reports />;
    case NavComponent.CaseLoad:
      return <CaseLoad />;
    case NavComponent.RoadmapPrint:
      return <RoadmapPrint />;
    case NavComponent.NotificationsEditor:
      return <NotificationsEditor />;
    case NavComponent.NotificationSchedulesTable:
      return <NotificationSchedulesTable />;
    case NavComponent.NotificationTemplatesTable:
      return <NotificationTemplatesTable />;
    case NavComponent.MTSPeriodEditor:
      return <MTSPeriodEditor />;
    case NavComponent.EmployerContact:
      return <EmpolyerContactWrapper />;
    case NavComponent.EmployeeContact:
      return <EmpolyeeContactWrapper />;
    case NavComponent.PowerBI:
      return <PBIEmbed />;
    case NavComponent.NotificationBlast:
      return <NotificationBlast />;
    default:
      return null;
  }
};

export const RouteBuilder: React.FC<{ route: NavRoute; baseUrl?: string }> = ({ route, baseUrl }) => {
  const loc = useLocation();
  const base = baseUrl == null || baseUrl === '/' ? '' : baseUrl;
  const styleHack = loc.pathname === '/reports';

  return (
    <Route path={`${base}${route.path}`}>
      <RouteContext.Provider
        value={{
          info: route,
          childRoutes: route.children
            ? route.children.map(ch => ({
                name: ch.name,
                route: <RouteBuilder key={ch.name} route={ch} baseUrl={route.path} />
              }))
            : undefined
        }}
      >
        {route.isReadonly
          ? (<div>
            <Alert severity="warning"><LanguageString groupName="ACCOUNT" resourceName="NO_UPDATE_PERMISSION" /></Alert>
            {renderRouteInLSProvider(route.component, <RouteComponent name={route.component} styleHack={styleHack} />)}
          </div>)
          : renderRouteInLSProvider(route.component, <RouteComponent name={route.component} styleHack={styleHack} />)
        }
      </RouteContext.Provider>
    </Route>
  );
};

export default RouteBuilder;
