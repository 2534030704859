import React from 'react';
import {
  Icon,
  Typography,
  Grid
} from '@material-ui/core';
import { useClasses } from '../../../hooks';

export const GeneralErrorMessage: React.FC<{ message: React.ReactNode | string, icon?: string }> = ({ message, icon }) => {
  const classes = useClasses();

  function trimMessage(msg: string) {
    return msg.replace('Network error: ', '')
      .replace(': Received status code 400', '');
  }

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.generalErrorMessage}
    >
      <Grid item xs={6}>
        <Icon>{icon ? icon : 'error_outline'}</Icon>
        <Typography variant="h6">{ typeof message == 'string' ? trimMessage(message) : message}</Typography>
      </Grid>
    </Grid>
  );
};
export default GeneralErrorMessage;
