import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { History } from '@material-ui/icons';
import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps, MuiPickersUtilsProvider, TimePicker, TimePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useContext } from 'react';
import { StudentContext, UserContext } from '../../contexts';
import { FormQuestionType } from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useDialogController, useFocusOnError } from '../../hooks';
import { LanguageString } from '../Common/LanguageString';
import { PropertyHistoryDialog } from './PropertyHistory';
import { FormFieldComp, FormStatus, IValues, getQuestionFormId, isInvalid, isNumber } from './common';

export const FormDate: FormFieldComp = ({ question }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const [field, meta] = useField<IValues>(getQuestionFormId(question));
  const formik = useFormikContext<IValues>();
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const histCtl = useDialogController(false);
  const error = isInvalid(meta);
  const handleChange = useCallback((date: MaterialUiPickersDate) => {
    formik.setFieldValue(field.name, date);
  }, []);
  const questionType = question.questionType;
  const format = questionType === FormQuestionType.DATETIME ? 'MM/dd/yyyy h:mm a' : questionType === FormQuestionType.TIME ? 'h:mm a' : 'MM/dd/yyyy';
  const cp = UserHelper.isAdvisorOrAdmin(userCtx.user) ? question.userProperty : undefined;
  const handleHistoryClick = async (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault();
    ev.stopPropagation();
    histCtl.controller.setOpen();
  };

  const dateProps = {
    clearable: true,
    error,
    fullWidth: true,
    label: <LanguageString languageString={question.text} />,
    ...field,
    onChange: handleChange,
    // Using the 'lowText' column to hold date formats
    format: question.lowText?.text ?? format,
    helperText: (question.hint || error) && (
      <>
        {question.hint && <LanguageString languageString={question.hint} />}
        {error && meta.error}
      </>
    ),
    InputProps: cp ? { endAdornment: <InputAdornment onClick={handleHistoryClick} style={{cursor: 'pointer'}} position="end"><History /></InputAdornment> } : undefined
  };

  const [minDateStr, maxDateStr] = question.match?.split(';') ?? [undefined, undefined];
  const minDate = minDateStr ? isNumber(minDateStr) ? new DateFnsUtils().addDays(new Date(), Number(minDateStr)) : new Date(minDateStr) : undefined;
  const maxDate = maxDateStr ? isNumber(maxDateStr) ? new DateFnsUtils().addDays(new Date(), Number(maxDateStr)) : new Date(maxDateStr) : undefined;
  const commonProps: Partial<DatePickerProps & DateTimePickerProps & TimePickerProps> = {
    disablePast: question.minLength != null,
    disableFuture: question.maxLength != null,
    minDate,
    maxDate
  };

  useFocusOnError(fieldRef, field.name);

  const { disabled: isDisabled, isReadOnly } = formik.status as FormStatus;
  const disabled = isDisabled || isReadOnly || question.isReadOnly;
  const picker =
    question.questionType === FormQuestionType.TIME ? (
      <TimePicker {...dateProps} {...commonProps} disabled={disabled} ref={fieldRef} />
    ) : question.questionType === FormQuestionType.DATETIME ? (
      <DateTimePicker {...dateProps} {...commonProps} disabled={disabled} ref={fieldRef} />
    ) : (
      <DatePicker {...dateProps} {...commonProps} views={['year', 'month', 'date']} openTo="year" disabled={disabled} ref={fieldRef} />
    );

  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {picker}
    <PropertyHistoryDialog userId={studentCtx?.student.id} propertyId={cp?.id} ctrl={histCtl} />
  </MuiPickersUtilsProvider>;
};
