import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

export type ProgramsResults = { site: Site };
export const programsQuery = gql`
  ${languageStringFrag}
  query getPrograms($orgId: Int, $departmentID: Int, $progId: Int) {
    site {
      id
      group {
        organizations(id: $orgId) {
          id
          name
          departments(id: $departmentID) {
            id
            name {
              ...LangStringFrag
            }
            programs(id: $progId) {
              id
              name {
                ...LangStringFrag
              }
            }
          }
        }
      }
    }
  }
`;

export function usePrograms(options?: QueryHookOptions<ProgramsResults, {departmentId?: number}>) {
  return useQuery<ProgramsResults>(programsQuery, options);
}
