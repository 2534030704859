import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { FormsSiteArgs, Site } from '../../dto';
import { languageStringFrag } from './fragments/languageStringFrag';

type FsProp = keyof Pick<Site, 'forms'>;
type FormType = Site[FsProp] extends (infer U)[] ? U : Site[FsProp];
type Results = { site: { [P in FsProp]: Pick<FormType, 'id' | 'name' | 'title'>[] } };
type Variables = FormsSiteArgs & { withTitle?: boolean };
export const query = gql`
  ${languageStringFrag}
  query siteForms($forms: [FormSpecifier], $withTitle: Boolean!) {
    site {
      id
      forms(forms: $forms) {
        id
        name
        title @include(if: $withTitle) {
          ...LangStringFrag
        }
      }
    }
  }
`;

export function useForms(options?: QueryHookOptions<Results, Variables>) {
  console.log(`useForms options`, options);
  if (options === undefined) options = { variables: { withTitle: false } };
  else if (options.variables === undefined) options.variables = { withTitle: false };
  // if (options?.variables?.withTitle === undefined) ((options ??= {}).variables ??= {}).withTitle = false;
  return useQuery<Results>(query, { fetchPolicy: 'cache-first', ...options });
}
