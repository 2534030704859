import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { mtsDeleteEntry: Boolean };
type Variables = { id: number };
export const useMTSDeleteEntry = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation mtsDeleteEntry($id: Int!) {
      mtsDeleteEntry(entryId: $id)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useMTSDeleteEntry;
