import { makeVar } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { format } from 'date-fns';
import * as pathToRegexp from 'path-to-regexp';
import React, { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DialogTitle, LanguageString } from '..';
import { TypedReflect } from '../../common';
import { SiteContext } from '../../contexts';
import { Assignment, Form, Milestone, MilestoneGroup, SubmitFormInput, SuccessPath } from '../../dto';
import { MilestoneState } from '../../helpers/userHelper';
import { useDialogController, useSiteNeedingApproval, useSitePaths, useStudentsWithAssignments } from '../../hooks';
import { DynamicForm } from '../DynamicForm';
import { getFormQuestion } from '../DynamicForm/common';
import { FormButtons, FormContainerProvider } from '../Milestone';

const MilestoneTreeItem: React.FC<{ group: MilestoneGroup; milestone: Milestone }> = ({ group, milestone }) => {
  const firstTask = milestone.tasks?.[0];
  const msLabel = firstTask?.name ? <LanguageString languageString={firstTask.name} /> : 'New Milestone';
  return <TreeItem nodeId={`ms-${group.id}-${milestone.id}`} label={msLabel} />;
};

const GroupTreeItem: React.FC<{ group: MilestoneGroup }> = ({ group }) => (
  <TreeItem nodeId={`grp-${group.id}`} label={<LanguageString languageString={group.name} />}>
    {group.subGroups?.map(msGrp => (
      <GroupTreeItem key={msGrp.id} group={msGrp} />
    ))}
    {false && group.milestones?.map(ms => <MilestoneTreeItem key={ms.id} group={group} milestone={ms} />)}
  </TreeItem>
);

const pageVar = makeVar(0);

const milestoneState = (assignments?: Assignment[]) =>
  assignments
    ? assignments.every(a => a.isComplete)
      ? MilestoneState.COMPLETE
      : assignments.some(a => a.startedAt)
        ? MilestoneState.STARTED
        : MilestoneState.NOT_STARTED
    : MilestoneState.NA;

type LinkFunc = (sId: number, grpIds?: number[], mId?: number) => string;
type BranchPaths = { groupPath: number[]; milestone: Milestone }[];

const addGrpMilestones = (paths: BranchPaths, msGrp: MilestoneGroup, parentIds: number[]) => {
  const pIds = [...parentIds, msGrp.id];
  msGrp.milestones?.forEach(ms => paths.push({ groupPath: pIds, milestone: ms }));
  msGrp.subGroups?.forEach(msg => addGrpMilestones(paths, msg, pIds));
};

const buildMsBranchPaths = (path: SuccessPath) => {
  const paths: BranchPaths = [];
  path.milestoneGroups?.forEach(msg => addGrpMilestones(paths, msg, []));

  return paths;
};

const getMsInfo = (info: BranchPaths, msId: number) => info.find(msInfo => msInfo.milestone.id === msId);

const MsIcon: React.FC<{ status: MilestoneState }> = ({ status }) =>
  status === MilestoneState.COMPLETE ? (
    <Icon style={{ color: 'green' }}>check_circle</Icon>
  ) : status === MilestoneState.STARTED ? (
    <Icon style={{ color: '#f1c40f' }}>swap_vertical_circle</Icon>
  ) : status === MilestoneState.NOT_STARTED ? (
    <Icon color="error">error</Icon>
  ) : (
    <Icon style={{ color: 'black' }}>clear</Icon>
  );

type StatusCellProps = {
  assignments?: Assignment[];
  link?: string;
};

// TODO perf
const LinkCell: React.FC<{ text: string; to: string }> = ({ text, to }) => (
  <TableCell>
    <Link component={RouterLink} to={to}>
      <Typography noWrap>{text}</Typography>
    </Link>
  </TableCell>
);

export const StatusCell: React.FC<StatusCellProps> = ({ assignments, link }) => (
  <TableCell>
    {assignments?.length && link ? (
      <Link component={RouterLink} to={link}>
        <MsIcon status={milestoneState(assignments)} />
      </Link>
    ) : (
      <MsIcon status={milestoneState(assignments)} />
    )}
  </TableCell>
);

type MsGrpFilter = { grpIds: number[]; msIds: number[] };
type CaseLoadComponentProps = { msMap: BranchPaths; link: LinkFunc; openFilter: () => void; filter?: SubmitFormInput; path?: SuccessPath, msGrpId?: number };

const Snapshot: React.FC<CaseLoadComponentProps> = ({ msMap, link, openFilter, filter, path, msGrpId }) => {
  const [page, setPageLocal] = useState(pageVar());
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [msFilter, setMsFilter] = useState<MsGrpFilter>(() => {
    const sf = localStorage.getItem('cs_ms_filter');

    return msGrpId != null ? { grpIds: [msGrpId], msIds: [] } : sf != null ? JSON.parse(sf) : { grpIds: [], msIds: [] };
  });
  const setMsGrpFilter = (filter: MsGrpFilter) => {
    localStorage.setItem('cs_ms_filter', JSON.stringify(filter));
    setMsFilter(filter);
  };
  if (msGrpId == null) {
    if (msFilter.grpIds.length !== 0) setMsGrpFilter({ grpIds: [], msIds: [] });
  } else {
    if (msFilter.grpIds.length !== 1 || msFilter.grpIds[0] !== msGrpId) setMsGrpFilter({ grpIds: [msGrpId], msIds: [] });
  }
  console.log(`HIT STUDENTS`);
  const { loading, data } = useStudentsWithAssignments({
    variables: { limit: rowsPerPage, offset: rowsPerPage * page, filter },
    fetchPolicy: 'cache-first'
  });
  const [totalStudents, setTotalStudents] = useState(data?.students2.totalCount ?? 0);
  const dataWasNull = useRef(data == null);
  const lastPage = useRef(page);
  const calls = useRef(0);
  const { controller, props } = useDialogController(false);

  if (data == null) dataWasNull.current = true;

  if ((dataWasNull.current || lastPage.current !== page) && data != null) {
    dataWasNull.current = false;
    lastPage.current = page;
    calls.current++;
  }


  type MsAsnmtMap = { [mId: number]: Assignment[] };
  type StudentMap = { [sId: number]: { student: Exclude<typeof data, undefined>['students2']['students'][0]; milestones: MsAsnmtMap } };

  const studentInfo = useMemo(() => {
    const rtnVal: StudentMap = {};
    data?.students2.students.forEach(s => {
      const m = s.assignments?.assignments?.reduce((sm, a) => {
        sm[a.milestoneId] != null ? sm[a.milestoneId].push(a) : (sm[a.milestoneId] = [a]);
        return sm;
      }, {} as MsAsnmtMap);
      if (m != null) rtnVal[s.id] = { student: s, milestones: m };
    });
    return rtnVal;
  }, [calls.current, filter]);

  if (data?.students2 != null && totalStudents !== data.students2.totalCount) setTotalStudents(data.students2.totalCount);

  // TODO: Testing raw html for performance
  // const msIcon = (assignments?: Assignment[]) =>
  //   assignments
  //     ? assignments.every(a => a.isComplete)
  //       ? MilestoneState.COMPLETE
  //       : assignments.some(a => a.startedAt)
  //       ? MilestoneState.STARTED
  //       : MilestoneState.NOT_STARTED
  //     : MilestoneState.NA;

  // const studentRow = (children: string) => `<tr class="MuiTableRow-root">${children}</tr>`;
  // const stntNameCell = (children: string) => `<td class="MuiTableCell-root MuiTableCell-body"><p class="MuiTypography-root MuiTypography-body1 MuiTypography-noWrap">${children}</p></td>`;
  // const stntTaskCell = (link: string, status: string) => `<td class="MuiTableCell-root MuiTableCell-body"><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="${link}"><span class="material-icons MuiIcon-root MuiIcon-colorError" aria-hidden="true">${status}</span></a></td>`;
  // const perfTable = () => TypedReflect.ownKeys(studentInfo).map(sId => studentRow(stntNameCell(`${studentInfo[sId].student.firstName} ${studentInfo[sId].student.lastName}`))).join('');
  // Done Testing

  const setPage = (newPage: number) => {
    setPageLocal(newPage);
    pageVar(newPage);
  };

  const handleMsFilterClick: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void = (_e, ids) => {
    setMsGrpFilter({
      grpIds: ids.filter(id => id.startsWith('grp-')).map(id => Number(id.substring(4))),
      msIds: ids.filter(id => id.startsWith('ms-')).map(id => Number(id.substring(3)))
    });
  };

  const openMsFilter = () => controller.setOpen();

  const filteredMilestones = useMemo(() => {
    const mss =
      msFilter.grpIds.length || msFilter.msIds.length
        ? msMap.filter(msInfo => msInfo.groupPath.filter(gp => msFilter.grpIds.includes(gp)).length).map(msInfo => msInfo.milestone)
        : msMap.map(msInfo => msInfo.milestone);

    return mss;
  }, [msMap, msFilter]);

  console.time('Render');

  const rslt = (
    <Card>
      <CardHeader
        title="Student Snapshot"
        action={
          <Fragment>
            <IconButton onClick={openMsFilter}>
              <Icon>assignment</Icon>
            </IconButton>
            <IconButton onClick={openFilter}>
              <Icon>filter_list</Icon>
            </IconButton>
          </Fragment>
        }
      />
      <CardContent>
        <TableContainer style={{ position: 'relative' }}>
          {loading && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#40404080',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                {filteredMilestones.map((ms, index) => (
                  <Tooltip key={`${index}-${ms.id}`} title={<LanguageString languageString={ms.tasks?.[0]?.name} />}>
                    <TableCell>{index + 1}</TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </TableHead>
            {studentInfo ? (
              // <Box dangerouslySetInnerHTML={{ __html: perfTable() }} clone>
              <TableBody>
                {TypedReflect.ownKeys(studentInfo).map(s => (
                // {data?.students2.students.map(s => (
                  <TableRow key={s}>
                    <TableCell>
                      <Link component={RouterLink} to={link(s)}>
                        <Typography noWrap>{`${studentInfo[s].student.firstName} ${studentInfo[s].student.lastName}`}</Typography>
                      </Link>
                    </TableCell>
                    {filteredMilestones.map((ms, index) => (
                      <StatusCell
                        key={`${index}-${ms.id}`}
                        assignments={studentInfo[s].milestones[ms.id]}
                        link={link(s, getMsInfo(msMap, ms.id)?.groupPath, ms.id)}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              // </Box>
              <p>No Data</p>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 75, 100]}
          count={totalStudents}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, np) => setPage(np)}
          onChangeRowsPerPage={rpp => setRowsPerPage(Number(rpp.target.value))}
        />
        <Dialog {...props} maxWidth="md" fullWidth>
          <DialogTitle onClose={controller.setClose}>
            <LanguageString groupName="CASELOAD" resourceName="SET_MS_FILTER" />
          </DialogTitle>
          <DialogContent dividers>
            <TreeView multiSelect onNodeSelect={handleMsFilterClick}>
              {path?.milestoneGroups?.map(msg => (
                <GroupTreeItem key={msg.id} group={msg} />
              ))}
            </TreeView>
          </DialogContent>
          <DialogActions>
            <Button onClick={controller.setClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );

  console.timeEnd('Render');

  return rslt;
};

const NeedingApproval: React.FC<CaseLoadComponentProps> = ({ msMap: milestones, link, openFilter }) => {
  const { loading, data } = useSiteNeedingApproval();
  const assignmentLink = (a: Assignment) =>
    a.user?.id != null ? link(a.user.id, getMsInfo(milestones, a.milestoneId)?.groupPath, a.milestoneId) : '/';

  data == null ? console.time('Loading Needing Approval') : console.timeEnd('Loading Needing Approval');

  return (
    <Card>
      <CardHeader title="Students That Need Milestone Counselor Approval" />
      {loading ? (
        <CardContent>
          <CircularProgress color="primary" />
        </CardContent>
      ) : data?.site.milestonesAwaitingApproval?.totalCount ? (
        <Table>
          {data.site.milestonesAwaitingApproval.assignments?.map(a => (
            <TableRow key={a.id}>
              <LinkCell text={`${a.user?.firstName} ${a.user?.lastName}`} to={assignmentLink(a)} />
              <LinkCell text={a.task?.name?.text ?? 'No Name'} to={assignmentLink(a)} />
              <TableCell>
                <Typography>{a.completedAt != null ? format(new Date(a.completedAt), 'MM/dd/yy h:mm a') : 'No Date'}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ) : (
        <p>No Data</p>
      )}
    </Card>
  );
};

const CaseLoad: React.FC = () => {
  const { controller, props } = useDialogController(false);
  const { loading, data } = useSitePaths();
  const siteCtx = useContext(SiteContext);
  const [filter, setFilter] = useState<SubmitFormInput>(() => {
    const sf = localStorage.getItem('cs_filter');
    if (sf != null) return JSON.parse(sf);
  });
  const [msGrpIdFilter, setMsGrpIdFilter] = useState<number>();
  const milestones = data?.site != null ? buildMsBranchPaths(data?.site.paths[0]) : [];

  const sRoute = useMemo(() => siteCtx.site?.navRoutes?.find(nr => nr.role === 'ADVISOR')?.children?.find(nr => nr.name === 'students'), [
    siteCtx.site
  ]);
  const sLink = sRoute?.path != null ? pathToRegexp.compile(sRoute.path.replace(/\(([^\)]+)\)\?/, '$1')) : undefined;
  const pRoute = sRoute?.children?.find(cr => cr.name === 'task-library');
  const gRoute = pRoute?.children?.find(cr => cr.name === 'ms-group');
  const gLink = gRoute?.path ? pathToRegexp.compile(gRoute.path) : undefined;
  const mRoute = gRoute?.children?.find(cr => cr.name === 'milestone');
  const mLink = mRoute?.path != null ? pathToRegexp.compile(mRoute.path) : undefined;
  const link: LinkFunc = (sId, grpIds, msId) =>
    grpIds
      ? `${sLink?.({ id: sId, studentId: sId })}${pRoute?.path}${grpIds.map(groupId => gLink?.({ groupId })).join('')}${mLink?.({ msId })}`
      : `${sLink?.({ id: sId, studentId: sId })}${pRoute?.path}`;

  const onFilterClick = () => controller.setOpen();

  const handleSubmit = (input: SubmitFormInput, form: Form) => {
    setFilter(input);
    const { answers, ...sinput } = input;
    const msGrpQuestion = getFormQuestion('msGroup', form);
    if (msGrpQuestion != null) {
      const msGrpId = answers?.find(ans => ans.questionId === msGrpQuestion.id)?.text;
      if (msGrpId !== msGrpIdFilter) setMsGrpIdFilter(msGrpId != null ? Number(msGrpId) : undefined);
    }
    // TODO: temporary quick fix to only persist the class list part of the filter
    localStorage.setItem('cs_filter', JSON.stringify({ ...sinput, answers: answers?.filter(a => 'selections' in a) }));
    controller.setClose();
  };

  return loading ? (
    <CircularProgress color="primary" />
  ) : (
    <Grid container spacing={2} direction="column">
      <Grid item style={{ width: '100%' }}>
        <Snapshot msMap={milestones} link={link} openFilter={onFilterClick} filter={filter} path={data?.site.paths[0]} msGrpId={msGrpIdFilter} />
      </Grid>
      <Grid item>
        <NeedingApproval msMap={milestones} link={link} openFilter={onFilterClick} />
      </Grid>
      <FormContainerProvider>
        <Dialog maxWidth="sm" fullWidth {...props}>
          <DialogTitle onClose={controller.setClose}>
            <LanguageString groupName="CASELOAD" resourceName="SET_FILTER" />
          </DialogTitle>
          <DialogContent dividers>
            <DynamicForm
              formName="userFilter"
              autoloadFormSubmission={false}
              showSubmitButton={false}
              overrideSubmitHandler={handleSubmit}
              overrideFormSubmissionInput={filter}
            />
          </DialogContent>
          <DialogActions>
            <FormButtons />
            <Button onClick={controller.setClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </FormContainerProvider>
    </Grid>
  );
};

export default CaseLoad;
