import React, { useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Role, User } from '../../dto';
import { useLoggedInUser } from '../../hooks';
import StudentProvider from '../StudentProvider';
import { ApplicationInsightsContext } from '../ApplicationInsightsProvider';
import { CircularProgress, Grid } from '@material-ui/core';

interface UserContextProps {
  user: User
}

export const UserContext = React.createContext<UserContextProps>({
  get user(): User {
    return { id: -1, roles: [Role.STUDENT]} as User;
  }
});

const AuthRedirect: React.FC = () => {
  const location = useLocation();
  return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
};

export const UserProvider: React.FC = ({ children }) => {
  const insightsCtx = useContext(ApplicationInsightsContext);
  const { loading, data } = useLoggedInUser({ fetchPolicy: 'cache-first', errorPolicy: 'ignore' });

  useEffect(() => {
    if (data?.me?.telemetryId && insightsCtx.telemetryId != data.me.telemetryId) {
      insightsCtx.setTelemetryId(data.me.telemetryId);
      insightsCtx.setUsername(data.me.username);
    }
  }, [data?.me]);

  return loading ? (
    <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  ) : data?.me ? (
    <UserContext.Provider value={{ user: data.me }}>
      <StudentProvider>{children}</StudentProvider>
    </UserContext.Provider>
  ) : (
    <AuthRedirect />
  );
};

export default UserProvider;
