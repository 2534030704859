import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  GridSize,
  Icon,
  IconButton as MuiIconButton,
  IconButtonTypeMap,
  Input as MuiInput,
  InputBase as MuiInputBase,
  InputBaseProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  PropTypes
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useField } from 'formik';
import React, { ChangeEventHandler, CSSProperties } from 'react';
import { HtmlTextField } from '../HtmlTextField/HtmlTextField';

type Gds = Partial<Record<Breakpoint, GridSize | boolean>>;
export type GridWrapperProps = Gds & { item?: boolean };

export type TextFieldProps = GridWrapperProps & {
  name: string;
  label?: MuiTextFieldProps['label'];
  hint?: string;
  select?: boolean;
  style?: CSSProperties;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  rows?: MuiTextFieldProps['rows'];
  type?: string;
  disable?: boolean;
};

export const TextField: React.FC<TextFieldProps> = props => {
  const { name, onChange, disable, hint, children, placeholder, xs, sm, md, lg, xl, item, ...miscProps } = props;
  const [{ onChange: onFieldChange, value = '', ...field }, meta] = useField(name);
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onFieldChange(event);
    if (onChange) onChange(event);
  };
  const hasError = meta.touched && meta.error != null;
  const fieldProps = { ...field, onChange: handleChange, autoComplete: 'off', placeholder: placeholder, error: hasError };
  const helperText = meta.touched ? meta.error || hint : hint;

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <MuiTextField {...fieldProps} value={value ?? ''} disabled={disable} helperText={helperText} {...miscProps} multiline={(props.rows ?? 1) > 1} fullWidth>
        {children}
      </MuiTextField>
    </Grid>
  );
};

type CheckboxProps = GridWrapperProps & { name: string; label: MuiTextFieldProps['label']; onChange?: ChangeEventHandler<HTMLInputElement> };
export const Checkbox: React.FC<CheckboxProps> = ({ name, label, item, xs, sm, md, lg, xl, onChange }) => {
  const [field, meta] = useField({ name: name, type: 'checkbox' });
  const hasError = meta.touched && meta.error != null;

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }} style={{ paddingTop: 0, paddingBottom: 0 }}>
      <FormControl fullWidth error={hasError}>
        <FormControlLabel label={label} control={<MuiCheckbox {...field} onChange={onChange ? onChange : field.onChange} />} />
        {hasError && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export const Input: React.FC<{ name: string; label?: string; onKeyPress?: React.KeyboardEventHandler }> = ({ name, label, onKeyPress }) => {
  const [field] = useField(name);

  return <MuiInput {...field} fullWidth onKeyPress={onKeyPress} />;
};

export const InputBase: React.FC<InputBaseProps & { name: string }> = ({ name, ...props }) => {
  const [field] = useField(name);

  return <MuiInputBase {...props} {...field} fullWidth />;
};

export const LSTextField: React.FC<TextFieldProps> = ({ name, label, hint, item, xs, sm, md, lg, xl }) => {
  const [field] = useField(`${name}.text`);
  const [formatField] = useField<{ [name: string]: string }>({ name: `${name}.isHtml`, type: 'checkbox' });
  const { value: checked } = formatField;

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <Grid container direction="column">
        <Checkbox {...formatField} label={`Html ${label}`} />
        {checked ? <HtmlTextField name={field.name} /> : <TextField name={field.name} label={label} hint={hint} />}
      </Grid>
    </Grid>
  );
};

type IconButtonProps = {
  icon: string;
  color?: PropTypes.Color;
  style?: React.CSSProperties;
  onClick?: () => void;
  edge?: IconButtonTypeMap['props']['edge'];
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
};

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(({ icon, ...props }, ref) => (
  <MuiIconButton {...props} ref={ref}>
    <Icon>{icon}</Icon>
  </MuiIconButton>
));
