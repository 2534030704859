export default class RouteBuilderHelper {

  static staticRootPath = '/students';
  static studentsPath = `${RouteBuilderHelper.staticRootPath}/:id([^\/a-zA-Z]+)*/(student)?/:studentId?`;

  private static pruneDuplicates(openStudents: number[]) {
    const users: number[] = [];
    for (const student of openStudents) {
      const foundUsers = users.findIndex(u => u === student);
      if (foundUsers < 0) {
        users.push(student);
      }
    }

    return users;
  }

  static allStudentsRoute(openStudents: number[]) {
    const _openStudents = this.pruneDuplicates(openStudents);
    return openStudents.length > 0 ? `${RouteBuilderHelper.staticRootPath}/` + _openStudents.map(s => s).join('/') : '/students';
  }

  static openStudentRoute(page: string, openStudents: number[], selectedStudent?: number) {
    if (openStudents.length > 0) {
      const _openStudents = this.pruneDuplicates(openStudents);
      let url = RouteBuilderHelper.staticRootPath + '/';
      url += _openStudents.map(s => s).join('/');
      url += '/student/' + (selectedStudent ?? 0);
      url += page;

      return url;
    }

    return RouteBuilderHelper.staticRootPath;
  }

}
