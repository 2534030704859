import { gql, useMutation } from '@apollo/client';
import { ResetUsernameInput, ResetUsernameResult } from 'tuapath-common/generated/schema';

export function useResetUsername() {
  const rpMutation = gql`
    mutation resetUsername($input: ResetUsernameInput!) {
      resetUsername(input: $input) {
        success
        reason
      }
    }
  `;

  return useMutation<{ resetUsername: ResetUsernameResult }, { input: ResetUsernameInput }>(rpMutation);
}
