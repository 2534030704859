import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { format, getDate, getYear } from 'date-fns';
import { format as formatTz} from 'date-fns-tz';
import React, { Fragment, ReactNode, useContext, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { RoadmapView } from '.';
import { StudentContext, useChildLinks, UserContext } from '../../contexts';
import { Roadmap, RoadmapMilestonesResponse, Role } from '../../dto';
import { DialogController, useClasses, useDialogController, useRoadmap } from '../../hooks';
import { useCompletedMilestones } from '../../hooks/gql/useCompletedMilestones';
import { DialogTitle, GeneralErrorMessage } from '../Common';
import { LanguageString } from '../Common/LanguageString';
import { NotificationsDialog } from '../Notifications';
import { Goal } from './Goal';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { MeetingDialog } from './MeetingDialog';
import { NotificationsProvider } from '../../contexts/NotificationsProvider';

const RoadmapStatus: React.FC<{ leftContents: ReactNode; action?: () => void }> = ({ children, leftContents, action }) => (
  <Grid className="middle-rm" item xs={12} sm>
    <Card>
      <CardActionArea onClick={action}>
        <Grid container>
          <Box p={2} clone>
            <Grid container item xs={4}>
              {leftContents}
            </Grid>
          </Box>
          <Grid item xs={8}>
            <Box p={2}>{children}</Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  </Grid>
);

const CompletedMilestonesList: React.FC<{ dprops: DialogController['props']; completedMilestonesResults: RoadmapMilestonesResponse }> = ({
  dprops,
  completedMilestonesResults
}) => {
  const childLinks = useChildLinks({
    items: completedMilestonesResults?.roadmapMilestones ?? [],
    paramMap: { milestoneId: 'msId' },
    routeName: 'milestone'
  });

  return (
    <Dialog {...dprops} scroll="paper">
      <DialogTitle onClose={dprops.onClose}>
        <LanguageString groupName="ROADMAP" resourceName="VICTORIES" defaultText="Victories" />
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {childLinks?.length ? (
            childLinks.map(cl => (
              <ListItem key={cl.item.id} component={RouterLink} to={`${cl.path}/${cl.item.id}`} button>
                <ListItemIcon>
                  <Icon>thumb_up</Icon>
                </ListItemIcon>
                <ListItemText>
                  <LanguageString languageString={cl.item.tasks?.[0].name} />
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <ListItem>No steps have been completed yet</ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const RoadmapInner: React.FC<{ roadmap: Roadmap, completedMilestones: RoadmapMilestonesResponse }> = ({ roadmap, completedMilestones }) => {
  const classes = useClasses();
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const [dialogBlock, setDialogBlock] = useState(false);
  const { controller: { setOpen: openVictoryDlg }, props: vProps } = useDialogController(false);
  const { controller: { setOpen: openMeetingDlg }, props: mProps } = useDialogController(false);
  const { controller: { toggle: toggleNotificationsDialog }, props: { open: notificationsDialogOpen }} = useDialogController(false);
  const { empInfo } = useParams<{ empInfo?: string }>();

  if (studentCtx == null || userCtx.user == null) throw new Error('RoadmapViewMain must be wrapped in a StudentContext');

  const ctx = useMemo(() => {
    return roadmap.user?.id === userCtx.user.id;
  }, [userCtx.user.id, roadmap.user?.id]);
  const showAdvisorItems = userCtx.user.id !== studentCtx.student.id && userCtx.user.roles?.includes(Role.ADVISOR);
  const meeting = roadmap.meeting;
  const date = meeting?.date != null ? new Date(meeting.date) : undefined;
  const empInfoLs = { groupName: 'ROADMAP', resourceName: 'EDIT_EMPLOYMENT_INFO' };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid className={classes.infocards} container item spacing={3}>
          {date ? (
            <RoadmapStatus
              leftContents={
                <Grid item>
                  <Typography align="center">{format(date, 'MMMM')}</Typography>
                  <Typography align="center" variant="h4" style={{ fontWeight: 'bold' }}>
                    {getDate(date)}
                  </Typography>
                  <Typography align="center">{getYear(date)}</Typography>
                </Grid>
              }
              action={() => {
                if (!dialogBlock) {
                  openMeetingDlg();
                }
              }}
            >
              <Typography>
                <LanguageString groupName="ROADMAP" resourceName="NEXT_MEETING" defaultText="My next meeting" />
              </Typography>
              <Typography variant="h4">{format(date, 'h:mm aa')}</Typography>
              <Grid container justify='space-between'>
                <Grid item>
                  <Typography>{formatTz(date, 'zzzz')}</Typography>
                  <Typography>({meeting?.meetingType?.replace('_', ' ')})</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title='Download calendar event'>
                    <IconButton
                      onClick={() => { window.open(meeting?.event?.attachment?.url, '_blank'); }}
                      onMouseEnter={() => { setDialogBlock(true); }}
                      onMouseLeave={() => { setDialogBlock(false); }}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </RoadmapStatus>
          ) : (
            <RoadmapStatus leftContents={<Icon fontSize="large">calendar_today</Icon>} action={openMeetingDlg}>
              <Typography>
                <LanguageString groupName="ROADMAP" resourceName="NO_MEETING" defaultText="No Meeting Scheduled" />
              </Typography>
            </RoadmapStatus>
          )}
          <RoadmapStatus leftContents={<Icon fontSize="large">notifications</Icon>} action={toggleNotificationsDialog}>
            <Typography><LanguageString groupName="ROADMAP" resourceName="NOTIFICATIONS" defaultText="Notifications" /></Typography>
            <Typography variant="h4">{ctx ? userCtx.user.unreadNotificationCount : studentCtx.student.unreadNotificationCount}</Typography>
          </RoadmapStatus>
          <RoadmapStatus leftContents={<Icon fontSize="large">check</Icon>} action={openVictoryDlg}>
            <Typography><LanguageString groupName="ROADMAP" resourceName="VICTORIES" defaultText="Victories" /></Typography>
            <Typography variant="h4">{completedMilestones.totalCount}</Typography>
            <Typography variant="caption">
              <LanguageString languageString={completedMilestones.roadmapMilestones?.[0]?.tasks?.[0].name} />
            </Typography>
          </RoadmapStatus>
        </Grid>
        <Grid container item spacing={3}>
          <Grid container item xs={12} sm={4}>
            <Grid container direction="column">
              <Grid container spacing={3} item>
                <Grid item xs={12}>
                  <Box bgcolor="primary.light" color="primary.contrastText">
                    <Toolbar>
                      <LanguageString groupName="ROADMAP" resourceName="ROADMAP_GOAL" />
                    </Toolbar>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2} clone>
                    <Paper>
                      <Grid container>
                        <Goal goalItem={roadmap} lsData={{ groupName: 'ROADMAP', resourceName: 'ROADMAP_GOAL' }} gp='goal' tp='translation' placeholder="ROADMAP_GOAL" />
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box bgcolor="primary.light" color="primary.contrastText">
                    <Toolbar>
                      <LanguageString groupName="ROADMAP" resourceName="EMPLOYMENT_INFORMATION" />
                    </Toolbar>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={2} clone>
                    <Paper>
                      {empInfo
                        ? <Goal goalItem={roadmap} lsData={empInfoLs} gp='employmentInfo' tp='empInfoTranslation' readonly={empInfo.includes('ro')} placeholder="EMPLOYMENT_GOAL" />
                        : <LanguageString groupName="ROADMAP" resourceName="EDIT_EMPLOYMENT_INFO" />}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <RoadmapView />
          </Grid>
        </Grid>
      </Grid>
      <CompletedMilestonesList dprops={vProps} completedMilestonesResults={completedMilestones} />
      {showAdvisorItems && <MeetingDialog dprops={mProps} rmId={roadmap.id} />}

      <NotificationsProvider type={roadmap.user?.id === userCtx.user?.id ? 'user' : 'student'} userId={roadmap.user?.id}>
        <NotificationsDialog
          open={notificationsDialogOpen}
          toggle={toggleNotificationsDialog}
          currentUserContext={roadmap.user?.id === userCtx.user?.id}
        />
      </NotificationsProvider>
    </Fragment>
  );
};

export const RoadmapViewMain: React.FC = () => {
  const studentCtx = useContext(StudentContext);
  if (studentCtx == null) throw new Error('RoadmapViewMain must be wrapped in a StudentContext');
  const userId = studentCtx.student.id;
  const { data: rmData, loading: rmLoading, error: rmDataError } = useRoadmap({ variables: { userId }, fetchPolicy: 'cache-first' });
  const { data: vData, loading: vLoading, error: vDataError } = useCompletedMilestones({ variables: { userId }, fetchPolicy: 'cache-first' });

  if (rmLoading || vLoading) {
    return (
      <Grid container>
        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} color="primary" />
      </Grid>
    );
  }

  if (rmData == null || vData == null) {
    console.log('Failed to load roadmap because rmData or vData was null');
    console.log('rmData: ', rmData);
    console.log('vData: ', vData);
    console.log('rmDataError: ', rmDataError);
    console.log('vDataError: ', vDataError);

    return <GeneralErrorMessage message="Oops. Something was wrong with the roadmap" />;
  }

  const { roadmap } = rmData;
  const { completedRoadmapMilestones: completedMilestones } = vData;

  return <RoadmapInner roadmap={roadmap} completedMilestones={completedMilestones} />;
};
