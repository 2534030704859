import React, { useContext, useMemo } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Paper
} from '@material-ui/core';
import {
  format,
  addDays
} from 'date-fns';
import {
  GeneralErrorMessage
} from '../../Common';
import CalendarMonthViewCell from '../CalendarMonthView/CalendarMonthViewCell';
import { useClasses } from '../../../hooks';
import { MTSContext, ChildRoutes } from '../../../contexts';
import clsx from 'clsx';
import { MTSToolBar } from '../MTSToolBar';
import { MTSFooter } from '../MTSFooter';
import MTSHelpers from '../../../helpers/mtsHelpers';

export const CalendarCustomWeekView: React.FC = (props) => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  const weekdays = useMemo(() => {
    const days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(format(addDays(mtsCtx.startOfWeek, i), 'E'));
    }

    return days;
  }, [mtsCtx.startOfWeek]);

  const daysOfWeek = useMemo(() => {
    const days = [];

    for (let i = 0; i <= 6; i++) {
      const periodDates = mtsCtx.mtsPeriod ? MTSHelpers.getPeriodDates(mtsCtx.mtsPeriod) : undefined;

      const date = addDays(mtsCtx.startOfWeek, i);
      days.push(
        <CalendarMonthViewCell
          key={`custom_week_cell_${date}`}
          showMonth
          mtsPeriod={mtsCtx.mtsPeriod}
          date={date}
          monthDate={date}
          periodStartDate={periodDates?.startDate}
          periodEndDate={periodDates?.endDate}
        />
      );
    }

    return days;
  }, [mtsCtx.startOfWeek, mtsCtx.date, mtsCtx.mtsPeriod]);

  if (mtsCtx.error) {
    return <GeneralErrorMessage message={mtsCtx.error.message} />;
  }

  return (
    <Paper>
      <MTSToolBar />

      <Grid container direction="row" className={classes.mtsCalendarWeek}>
        {mtsCtx.loading && (
          <Grid item xs={12}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!mtsCtx.loading && (
          <Grid item xs={12}>
            <Grid className={clsx([classes.mtsDayHeaderHeight, classes.mtsMonthDayHeader])} container direction="row" justify="space-evenly">
              {weekdays.map(day => (
                <Grid item key={`week_view_day_${day}`} xs>
                  <Typography variant="h6">{day}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        <Grid className={classes.mtsMonthRow} container direction="row" justify="space-evenly">
          {daysOfWeek}
        </Grid>
      </Grid>

      <MTSFooter />
      <ChildRoutes />
    </Paper>
  );
};

export default CalendarCustomWeekView;
