import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { AccountSetupCompletionInput, RegisterResult } from 'tuapath-common/generated/schema';

type Variables = { input: AccountSetupCompletionInput };
type Result = { accountSetupCompletion: RegisterResult };
export const useAccountSetup = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation accountSetupCompletion($input: AccountSetupCompletionInput!) {
      accountSetupCompletion(data: $input) {
        success
        reason
      }
    }
  `;

  return useMutation<Result, Variables>(mutation);
};
