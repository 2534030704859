import { Button, Checkbox as MuiCheckbox, FormControlLabel, Grid, makeStyles, Paper, Tooltip, TooltipProps, Typography } from '@material-ui/core';
import { addDays } from 'date-fns';
import React, { ReactElement, useState } from 'react';
import { LanguageString } from '..';
import { hasProp } from '../../../common';
import { useLocalStorage } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'all'
  },
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0
  },
  '@media (min-width: 600px)': {
    tooltipPlacementLeft: {
      margin: 0
    }
  },
  arrow: {
    fontSize: '1.25rem',
    color: theme.palette.info.main
  },
  showAgain: {
    marginBottom: `-${theme.spacing(2)}px`,
    paddingLeft: `${theme.spacing(2)}px`
  },
  checkbox: {
    color: theme.palette.info.contrastText,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.info.contrastText
    }
  },
  button: {
    color: theme.palette.info.contrastText
  },
  steps: {
    marginLeft: `${theme.spacing(1)}px`,
    alignSelf: 'center;'
  },
  'callout-root': {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  heading: {
    padding: `${theme.spacing(2)}px`,
    paddingBottom: 0,
    '& .MuiTypography-root': {
      lineHeight: 'inherit'
    }
  },
  content: {
    padding: `${theme.spacing(2)}px`
  },
  actions: {
    padding: `${theme.spacing(1)}px`
  }
}));

type PropsStep1 = {
  name: string;
  title?: JSX.Element;
  body: JSX.Element;
  step: 1;
  of: number;
  placement?: TooltipProps['placement'];
  dependsOn?: (string[] | string)[];
  children: ReactElement;
};
type CalloutProps = {
  name: string;
  title?: JSX.Element;
  body: JSX.Element;
  step?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  placement?: TooltipProps['placement'];
  dependsOn?: (string[] | string)[];
  children: ReactElement;
};
type CPComp = {
  (props: CalloutProps, context?: unknown): ReactElement | null;
  (props: PropsStep1, context?: unknown): ReactElement | null;
};

const isPropsStep1 = (props: CalloutProps | Omit<CalloutProps, 'of' | 'step'>): props is PropsStep1 => hasProp(props, 'of');

export const Callout: CPComp = (props: CalloutProps | PropsStep1) => {
  const { name, title, body, step = 1, placement = 'left-start', children, ...childProps } = props;
  const [deactive, setDeactive] = useState(false);
  const classes = useStyles();
  const [settings, setSettings] = useLocalStorage(
    'InlineHelp',
    step === 1
      ? {
          [name]: {
            step: 1,
            showAgain: 0,
            dependsOn: props.dependsOn ?? [],
            of: isPropsStep1(props) ? props.of : 1
          }
        }
      : {}
  );
  if (settings[name] == null) {
    settings[name] = {
      step: 1,
      showAgain: 0,
      dependsOn: props.dependsOn ?? [],
      of: isPropsStep1(props) ? props.of : 1
    };
    if (step === 1) {
      setSettings(settings);
      return null;
    }
  }
  if (settings[name] == null && step === 1) {
    throw new Error('Settings is null');
  }
  const { step: curStep, showAgain, of, dependsOn } = settings[name];
  if (step > of) console.warn(`Callout step greater than total number of steps ${name} step ${step} of ${of}`);
  if (step === 1 && showAgain && showAgain < Date.now() && curStep > of) {
    settings[name].step = 1;
    setSettings(settings);
  }
  const isStepComplete = (cardSet: (typeof settings[string]) | undefined) => cardSet != null && cardSet.step > cardSet.of;
  const open =
    (settings[name].step ?? 1) === step &&
    (dependsOn.length === 0 || dependsOn.some(dep => Array.isArray(dep) ? dep.every(cd => isStepComplete(settings[cd])) : isStepComplete(settings[dep])));

  const handleNext = () => {
    settings[name].step++;
    settings[name].showAgain = step !== of || deactive ? 0 : addDays(Date.now(), 2).getTime();
    setSettings(settings);
  };

  const handlePrev = () => {
    settings[name].step--;
    setSettings(settings);
  };
  const child = React.cloneElement(children, { ...children.props, ...childProps });

  return (
    <Tooltip
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        tooltipPlacementLeft: classes['@media (min-width: 600px)']
      }}
      arrow
      placement={placement}
      open={open}
      title={
        <Paper className={classes['callout-root']}>
          <Grid container direction="column">
            {title != null && (
              <Grid item className={classes.heading}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            )}
            <Grid item className={classes.content}>
              <Typography variant="body1">{body}</Typography>
            </Grid>
            {step === of && (
              <Grid item className={classes.showAgain}>
                <FormControlLabel
                  label={<LanguageString groupName="HELP" resourceName="PREVENT_REDISPLAY" />}
                  control={<MuiCheckbox className={classes.checkbox} onChange={(_, ck) => setDeactive(ck)} />}
                />
              </Grid>
            )}
            <Grid container item className={classes.actions}>
              {of > 1 && (
                <Typography className={classes.steps}>
                  {step} of {of}
                </Typography>
              )}
              <div style={{ marginLeft: 'auto' }}>
                {step > 1 && (
                  <Button className={classes.button} onClick={handlePrev}>
                    <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="PREVIOUS" />
                  </Button>
                )}
                <Button className={classes.button} onClick={handleNext}>
                  <LanguageString groupName="FORM_LABELS_ERRORS" resourceName={step === of ? 'DISMISS' : 'NEXT'} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      }
    >
      {child}
    </Tooltip>
  );
};
