import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { TypedReflect } from '../../common';
import { SiteContext, StudentContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { QueryType } from '../../helpers/query';
import UserHelper from '../../helpers/userHelper';
import { useFormQuery, useRecordOfContacts } from '../../hooks';
import { IconButton, LanguageString } from '../Common';
import { convertFsToValues, getFormQuestions, getQueryParamsForQuestion, getQuestionFormId, isDependsOnSatisfiedForAny, questionsIterator } from '../DynamicForm/common';
import { RecordOfContactDialog } from './RecordOfContactDialog';
import { RecordOfContactsTableBody } from './RecordOfContactsTableBody';
import { RecordOfContactPrintDialog } from './RecordOfContactPrintDialog';

const getSortIndex = (question: DTO.FormQuestion) => {
  if (question.name == null) return Number.POSITIVE_INFINITY;
  const m = question.name.match(/s(\d)/);
  if (m == null) return Number.POSITIVE_INFINITY;
  if (m.length > 1) return Number(m[1]);

  return Number.POSITIVE_INFINITY;
};

const getColumnHeaders = (rocs: DTO.RecordOfContact[], rocTypes: DTO.ROCType[]): [DTO.FormQuestion[], DTO.FormQuestion[], {[id: number]: DTO.ROCType}] => {
  const rtMap: { [rtId: number]: DTO.ROCType } = {};
  rocTypes.forEach(rt => rtMap[rt.id] = rt);

  const displayedTypes = [...new Set(rocs.map(roc => roc.rocType!))];
  const displayedForms = [...new Set(displayedTypes.filter(dt => rtMap[dt.id].form != null).map(dt => rtMap[dt.id].form!))];
  const formIdMap: { [fId: number]: DTO.Form } = {};
  displayedForms.forEach(f => formIdMap[f.id] = f);
  const formQuestions: { [fId: number]: DTO.FormQuestion[] } = {};
  const quesToFormMap: { [qId: number]: DTO.Form } = {};
  displayedForms.forEach(f => getFormQuestions(f).forEach(fq => {
    if (formQuestions[f.id] == null) formQuestions[f.id] = [];
    formQuestions[f.id].push(fq);
  }));
  TypedReflect.ownKeys(formQuestions).forEach(formId => formQuestions[formId].forEach(qId => quesToFormMap[qId.id] = formIdMap[formId]));
  const uniqueQuestions = [...displayedForms.map(f => getFormQuestions(f))].flat();
  let questions = uniqueQuestions.filter(q => q.name?.match(/\w-visible/)).sort((q1, q2) => getSortIndex(q1) - getSortIndex(q2));
  const questionCache: { [qId: string]: DTO.FormQuestion } = {};
  questions.forEach(q => questionCache[getQuestionFormId(q)] = q);
  const va: { [fId: number]: ReturnType<typeof convertFsToValues>[] } = {};
  rocs.filter(r => r.formSubmission && r.rocType && rtMap[r.rocType.id].form).forEach(r => {
    if (va[rtMap[r.rocType!.id].form!.id] == null) va[rtMap[r.rocType!.id].form!.id] = [];
    va[rtMap[r.rocType!.id].form!.id].push(convertFsToValues(r.formSubmission!, questionCache));
  });
  // const valuesArray = rocs.filter(r => r.formSubmission).map(r => convertFsToValues(r.formSubmission!, questionCache));
  questions = questions.filter(q => isDependsOnSatisfiedForAny(va[quesToFormMap[q.id].id], q));
  const qNames = [...new Set(questions.map(q => q.name))];

  return [uniqueQuestions, qNames.map(qn => questions.find(q => q.name === qn)!), rtMap];
};

export type FQContextProps = {
  qresults: { [qname: string]: unknown }
};

export const FQContext = React.createContext<FQContextProps>({ qresults: {} });
export const getFSAnswerMap = (fs: DTO.FormSubmission) => {
  const ansMap: { [questionId: number]: string } = {};
  fs.answers.forEach(ans => ans.answer && (ansMap[ans.question.id] = ans.answer));

  return ansMap;
};

type FQProviderProps = {
  qdata: Parameters<typeof useFormQuery>[]
};

const FQProvider: React.FC<FQProviderProps> = ({ qdata: [qdata, ...otherQueries], children }) => {
  const [queryName, qparams, queryArgs] = qdata;
  const { loading, data: dadv } = useFormQuery(queryName, qparams, queryArgs);
  const fqCtx = useContext(FQContext);

  if (loading || dadv == null) return <CircularProgress color="primary" />;

  return !otherQueries?.length ?
    <FQContext.Provider value={{ qresults: { ...fqCtx.qresults, ...dadv } }}>{children}</FQContext.Provider> :
    <FQContext.Provider value={{ qresults: { ...fqCtx.qresults, ...dadv } }}><FQProvider qdata={otherQueries}>{children}</FQProvider></FQContext.Provider>;
};

// const QuestionOptionsLoader: React.FC<FQProviderProps> = ({ qdata, children }) => Array.isArray(qdata) ? <FQProvider qdata={qdata}>{children}</FQProvider> : <>{children}</>;
const QuestionOptionsLoader: React.FC<FQProviderProps> = ({ qdata, children }) => {
  return qdata.length ? <FQProvider qdata={qdata}>{children}</FQProvider> : <>{children}</>;
};

interface RecordOfContactsProps {
  minDate?: Date;
  maxDate?: Date;
  defaultRowsPerPageIndex?: number;
  filter?: {
    filterTo?: boolean;
    rocTypeId?: number;
  }
  showTitleBar?: boolean;
}

export const RecordOfContacts: React.FC<RecordOfContactsProps> = props => {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const userContext = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [selectedRecordOfContact, setSelectedRecordOfContact] = useState<QueryType<DTO.RecordOfContact> | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.defaultRowsPerPageIndex ? rowsPerPageOptions[props.defaultRowsPerPageIndex] : rowsPerPageOptions[rowsPerPageOptions.length - 1]
  );

  let canEdit = false;

  if (userContext.user) canEdit = UserHelper.isAdvisorOrAdmin(userContext.user);

  const studentCtx = useContext(StudentContext);
  const { data: rocData, loading } = useRecordOfContacts({
    variables: {
      userId: studentCtx?.student.id ?? 0,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      minDate: props.minDate,
      maxDate: props.maxDate
    }
  });
  const data = useMemo(() => {
    if (rocData?.user.rocs?.rocs && props.filter) {
      let rocs: DTO.RecordOfContact[] = rocData.user.rocs.rocs ?? [];

      if (props.filter.filterTo === true) {
        rocs = rocs.filter(r => r.to?.id === studentCtx?.student.id);
      } else if (props.filter.filterTo === false) {
        rocs = rocs.filter(r => r.from?.id === studentCtx?.student.id);
      }

      if (props.filter.rocTypeId) {
        rocs = rocs.filter(r => r.rocType?.id === props.filter?.rocTypeId);
      }

      return { ...rocData, user: { ...rocData.user, rocs: { ...rocData.user.rocs, rocs } } };
    }

    return rocData;
  }, [rocData, props.filter]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleAddRecordOfContact = () => {
    setSelectedRecordOfContact(undefined);
    setDialogOpen(true);
  };

  const handleSelectedRoc = (roc: DTO.RecordOfContact) => {
    setSelectedRecordOfContact(roc);
    setDialogOpen(true);
  };

  const toggleDialog = () => setDialogOpen(!dialogOpen);
  const Wrapper: React.FC = ({ children }) => {
    const siteCtx = useContext(SiteContext);
    const [printDialogOpen, setPrintDialogOpen] = useState(false);
    const toggle = () => setPrintDialogOpen(!printDialogOpen);

    return (
      <Card>
        {(props.showTitleBar == null || props.showTitleBar === true) && (
          <CardHeader
            title={<LanguageString groupName="GENERAL" resourceName="RECORD_OF_CONTACTS_TITLE" defaultText="Record of Contacts" />}
            action={
              <>
                {siteCtx.site?.downloadRocEnabled === true && (
                  <Tooltip title={<LanguageString groupName="GENERAL" resourceName="ROC_TOOLTIP" defaultText="Print ROCs" />}>
                    <IconButton aria-label="print rocs" onClick={toggle} icon="cloud_download" />
                  </Tooltip>
                )}
                {
                  canEdit ? (
                    <Tooltip title="Add Record of Contact">
                      <IconButton aria-label="add record of contact" onClick={handleAddRecordOfContact} icon="add_circle" />
                    </Tooltip>
                  ) : null
                }
              </>
            }
          />
        )}
        <CardContent>{children}</CardContent>
        <RecordOfContactPrintDialog open={printDialogOpen} toggle={toggle} />
      </Card>
    );
  };

  const getFSRocMap = (rocs: DTO.RecordOfContact[]) => {
    const fsRocMap: { [id: number]: DTO.RecordOfContact } = {};
    rocs.forEach(roc => roc.formSubmission != null ? fsRocMap[roc.formSubmission.id] = roc : undefined);

    return fsRocMap;
  };

  if (loading || data?.user.rocs.rocs == null) return <Wrapper><CircularProgress color="primary" /></Wrapper>;
  const [, colQuestions, rtMap] = getColumnHeaders(data.user.rocs.rocs, data.rocTypes);
  const rocs = data.user.rocs.rocs ?? [];
  const rocTypes = data.rocTypes;
  const fsRocMap = getFSRocMap(rocs);

  const pagination = {
    rowsPerPageOptions,
    component: 'div',
    count: data?.user.rocs.totalCount ?? 0,
    rowsPerPage,
    page,
    onChangeRowsPerPage: handleChangeRowsPerPage
  };

  if (colQuestions == null || rtMap == null) return <Wrapper><CircularProgress color="primary" /></Wrapper>;

  const queryQuestions = colQuestions.filter(q => q.optionDefinition != null);
  const getROCForm = (roc: DTO.RecordOfContact, rtMap: { [id: number]: DTO.ROCType }) => roc.rocType != null ? rtMap[roc.rocType.id].form : undefined;

  const getFSQuestionMap = (fs: DTO.FormSubmission, fsRocMap: ReturnType<typeof getFSRocMap>, rtMap: ReturnType<typeof getColumnHeaders>[2]) => {
    const qMap: { [formQuestionId: string]: DTO.FormQuestion } = {};
    const rocType = fsRocMap[fs.id].rocType;
    const form = rocType != null ? rtMap[rocType.id].form : undefined;
    if (form?.elements != null) {
      for (const q of questionsIterator(form.elements)) {
        qMap[getQuestionFormId(q)] = q;
      }
    }

    return qMap;
  };
  const getFsForQuestion = (question: DTO.FormQuestion, rocs: DTO.RecordOfContact[], rtMap: { [id: number]: DTO.ROCType }) => {
    for (const roc of rocs) {
      const form = getROCForm(roc, rtMap);
      if (roc.rocType != null && form?.elements != null) {
        for (const q of questionsIterator(form.elements)) {
          if (q.id === question.id) return roc.formSubmission;
        }
      }
    }

    return;
  };

  const d = queryQuestions.map(qq => [qq.optionDefinition!.query!, getQueryParamsForQuestion(qq, getFSAnswerMap(getFsForQuestion(qq, rocs, rtMap)!), getFSQuestionMap(getFsForQuestion(qq, rocs, rtMap)!, fsRocMap, rtMap))] as Parameters<typeof useFormQuery>);

  const hasDoc = colQuestions.find(cq => cq.name?.match(/^doc(-.*)?$/) != null) != null;
  const hasToc = colQuestions.find(cq => cq.name?.match(/^toc(-.*)?$/) != null) != null;
  const hasFrom = colQuestions.find(cq => cq.name?.match(/^from(-.*)?$/) != null) != null;

  return (
    <Wrapper>
      {(loading || rocs == null || rocTypes == null) ? <CircularProgress color="primary" /> :
        <Grid item xs style={{ overflowX: 'auto' }}>
          <QuestionOptionsLoader qdata={d}>
            <Table aria-labelledby="tableTitle" size={'medium'}>
              <TableHead>
                <TableRow>
                  {!hasDoc && <TableCell align="left"><LanguageString groupName="RECORD_OF_CONTACT" resourceName="DATE_OF_CONTACT" defaultText="Date of Contact" /></TableCell>}
                  {!hasToc && <TableCell align="left"><LanguageString groupName="RECORD_OF_CONTACT" resourceName="TYPE_OF_CONTACT" defaultText="Type of Contact" /></TableCell>}
                  {!hasFrom && <TableCell align="left"><LanguageString groupName="RECORD_OF_CONTACT" resourceName="CONTACTED_BY" defaultText="Contacted By" /></TableCell>}
                  {colQuestions.map(q => <TableCell key={`q-${q.id}`} align="left"><LanguageString languageString={q.text} /></TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                <RecordOfContactsTableBody rocs={rocs} colQuestions={colQuestions} selectedRoc={handleSelectedRoc} rocTypes={rocTypes} />
              </TableBody>
            </Table>
          </QuestionOptionsLoader>
        </Grid>
      }
      <TablePagination {...pagination} onChangePage={(_, newPage) => setPage(newPage)} />

      <RecordOfContactDialog open={dialogOpen} toggle={toggleDialog} recordOfContact={selectedRecordOfContact} rocTypes={rocTypes} rtMap={rtMap} />
    </Wrapper>
  );
};
export default RecordOfContacts;
