import { Dialog, DialogContent, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { MTSContext } from '../../../contexts';
import { DialogTitle, LanguageString } from '../../Common';
import MTSCompletedMTSTab from './MTSCompletedMTSTab';
import MTSDocumentsTab from './MTSDocumentsTab';

interface MTSDocumentsDialogProps {
  open: boolean;
  toggle: () => void;
}

export const MTSDocumentsDialog: React.FC<MTSDocumentsDialogProps> = props => {
  const mtsCtx = useContext(MTSContext);
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Dialog maxWidth="lg" fullWidth fullScreen={fullscreen} open={props.open} onClose={props.toggle} aria-labelledby="Signup Form">
      <DialogTitle onClose={props.toggle}>
        <LanguageString groupName="MTS" resourceName="DOCUMENTS_TITLE" defaultText="Documents" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="Documents Tabs"
              variant="fullWidth"
            >
              <Tab label={ <LanguageString groupName="MTS" resourceName="DOCUMENTS" defaultText="Documents" />} />
              <Tab label={ <LanguageString groupName="MTS" resourceName="COMPLETED_MTS" defaultText="Completed MTS" />} />
            </Tabs>
          </Grid>
          <Grid item xs>
            {currentTab === 0 && (
              <Grid container>
                {mtsCtx.mtses.map(mts => (
                  <Grid key={`document_tab_${mts.id}`} item xs={12}>
                    <MTSDocumentsTab mts={mts} showTitle={mtsCtx.mtses.length > 1} />
                  </Grid>
                ))}
              </Grid>
            )}

            {currentTab === 1 && <MTSCompletedMTSTab />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MTSDocumentsDialog;
