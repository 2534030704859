import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { AddMilestoneGroupToPathMutationArgs, User } from '../../dto';
import { assignmentResultsFrag, roadmapFrag } from './fragments';

type Result = { addMsGroupToPath: User };
type Variables = AddMilestoneGroupToPathMutationArgs;
export function useAddMsGroupToPath(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${assignmentResultsFrag}
    ${roadmapFrag}
    mutation addMsGroupToPath($input: MilestoneGroupInput!) {
      addMilestoneGroupToPath(input: $input) {
        id
        assignments {
          ...AssignmentResultsFrag
        }
        roadmap {
          ...RoadmapFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useAddMsGroupToPath;
