import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { RecordOfContactResults, ROCType } from '../../dto';
import { QueryType } from '../../helpers/query';
import { ReqVars } from './common';
import { formFrag, rocFrag } from './fragments';

export type ROCVariables = { userId: number; limit: number; offset: number; minDate?: Date; maxDate?: Date };
export type ROCResults = { user: { rocs: RecordOfContactResults }; rocTypes: QueryType<ROCType>[] };

const query = gql`
  ${formFrag}
  ${rocFrag}

  query getROCs($userId: Int!, $limit: Int!, $offset: Int!, $minDate: DateTime, $maxDate: DateTime) {
    rocTypes {
      id
      name {
        id
        text
      }
      form {
        ...FormFrag
      }
    }

    user(id: $userId) {
      id
      rocs(limit: $limit, offset: $offset, minDate: $minDate, maxDate: $maxDate) {
        totalCount
        rocs {
          ...rocFrag
        }
      }
    }
  }
`;

export const useRecordOfContacts = (options: ReqVars<QueryHookOptions<ROCResults, ROCVariables>>) => useQuery<ROCResults, ROCVariables>(query, options);

export default useRecordOfContacts;
