import { gql, useMutation, MutationHookOptions } from '@apollo/client';
import { AccountSetupEmailInput, AccounSetupEmailResult } from 'tuapath-common/generated/schema';

type Variables = { input: AccountSetupEmailInput };
type Result = { result: AccounSetupEmailResult };
export const useSendAccountSetupEmail = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation sendAccountSetupEmail($input: AccountSetupEmailInput!) {
      sendAccountSetupEmail( data: $input ) {
        success
      }
    }
  `;

  return useMutation<Result, Variables>(mutation);
};
