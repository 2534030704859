import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useStudents } from '../../hooks';
import { User, SortDirection } from '../../dto';
import * as DTO from 'tuapath-common/generated/schema';
import { useLocalStorage, LocalStorageKey } from '../../hooks';

interface StudentContextProps {
  loading: boolean;
  advisorIds: number[];
  setAdvisorIds: (ids: number[]) => void;
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;
  search?: string;
  setSearch: (search: string) => void;
  order?: DTO.StudentsOrder;
  setOrder: (ordrer: DTO.StudentsOrder) => void;
  filters?: DTO.SubmitFormInput;
  setFilters: (filters: DTO.SubmitFormInput) => void;
  totalCount: number;
  filteredStudents: User[];
  openStudents: User[];
  selectedStudent?: User;
}

export const StudentsContext = React.createContext<StudentContextProps | undefined>(undefined);

export const StudentsProvider: React.FC = ({ children }) => {

  const studentsMatch = useRouteMatch<{ id?: string; studentId?: string; }>(`/students/:id([^\/a-zA-Z]+)*/(student)?/:studentId?`);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<DTO.StudentsOrder>({
    orderBy: 'lastName',
    sortDirection: SortDirection.ASC,
    sortType: DTO.StudentSortType.StudentKey
  });
  const [savedFilters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.StudentsFilters, {});
  const [filters, setFilter] = useState<DTO.SubmitFormInput | undefined>(undefined);
  const [advisorIds, setAdvisorIds] = useState<number[]>([]);

  const _setAdvisorIds = useCallback((ids: number[]) => {
    setAdvisorIds(ids);
  }, []);
  const _setLimit = useCallback((l: number) => {
    setLimit(l);
  }, []);
  const _setOffset = useCallback((o: number) => {
    setOffset(o);
  }, []);
  const _setSearch = useCallback((s: string) => {
    setSearch(s);
  }, []);
  const _setOrder = useCallback((o: DTO.StudentsOrder) => {
    setOrder(o);
  }, []);
  const _setFilters = useCallback((o: DTO.SubmitFormInput) => {
    setFilter(o);
  }, []);

  const { loading, data } = useStudents({
    variables: {
      advisorIds: advisorIds.length > 0 ? advisorIds : undefined,
      limit: limit,
      offset: offset,
      search: search.length > 0 ? search : undefined,
      order: order,
      filter: filters
    },
    fetchPolicy: 'cache-and-network'
  });
  const totalCount = useMemo(() => data?.students.totalCount ?? 0, [data?.students.totalCount]);
  const filteredStudents = useMemo(() =>  data?.students.students ?? [], [data?.students.students]);
  const openStudents = useMemo(() => data?.studentsWithId?.slice() ?? [], [data?.studentsWithId]);
  const allStudents = useMemo(() =>  filteredStudents.concat(openStudents), [filteredStudents, openStudents]);

  const selectedStudentId = useMemo(() => {
    let student: number | undefined;

    if (studentsMatch?.params?.studentId && studentsMatch?.params.studentId.length > 0) {
      return parseInt(studentsMatch?.params.studentId, 0);
    }

    return student;
  }, [studentsMatch]);

  const selectedStudent = useMemo(() => {
    if (selectedStudentId) {
      return allStudents.find(s => s.id === selectedStudentId);
    }

    return undefined;
  }, [allStudents]);

  useEffect(() => {
    if (!filters && Object.keys(savedFilters).length > 0) {
      setFilter(Object.keys(savedFilters).length > 0 ? (savedFilters as DTO.SubmitFormInput) : undefined);
    }
  }, [filters, savedFilters]);

  return (
    <StudentsContext.Provider
      value={{
        loading: loading,
        advisorIds: advisorIds,
        setAdvisorIds: _setAdvisorIds,
        limit: limit,
        setLimit: _setLimit,
        offset: offset,
        setOffset: _setOffset,
        search: search,
        setSearch: _setSearch,
        order: order,
        setOrder: _setOrder,
        filters: filters,
        setFilters: _setFilters,
        totalCount: totalCount,
        filteredStudents: filteredStudents,
        openStudents: openStudents,
        selectedStudent: selectedStudent
      }}
    >
      {children}
    </StudentsContext.Provider>
  );
};

export default StudentsProvider;
