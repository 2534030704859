import { gql, MutationHookOptions, useMutation } from '@apollo/client';

type Result = { removeNotificationTrigger: boolean };
type Variables = { id: number };
export const useDeleteNotificationTrigger = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    mutation removeNotificationTrigger($id: Int!) {
      removeNotificationTrigger(id: $id)
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
