import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { PropertyHistory, PropertyHistoryInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Variables = PropertyHistoryInput;
type Results = {
  propertyHistory: QueryType<PropertyHistory>[];
};

export function usePropetyHistory(options?: QueryHookOptions<Results, Variables>) {
  const query = gql`

    query getPropertyHistory($propertyId: Int!, $userId: Int) {
      propertyHistory(propertyId: $propertyId, userId: $userId) {
        propertyId,
        changeReason,
        value,
        changedBy {
          id,
          username,
          firstName,
          lastName
        },
        createdAt
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
}
