import {
  AppBar,
  Badge,
  Button,
  createStyles,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink, matchPath } from 'react-router-dom';
import { SiteContext, StudentsMenuContext, useChildLinks, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useClasses, useStringGroup } from '../../hooks';
import { AccountMenu, CustomIcon, LanguageString, NotificationsIcon, ProfileIcon } from '../Common';
import { NotificationsMenu } from '../Notifications';
import StudentNavigationBar from './StudentNavigationBar';
import StudentSelectionMenu from './StudentSelectionMenu';

interface NavigationDrawerItemIconProps {
  open: boolean;
  icon: React.ReactNode | string;
  svgIcon: string | undefined;
  title: React.ReactNode | undefined;
}

const drawerWidth = 240;
export const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 0
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(9) + 1
      },
      [theme.breakpoints.down('sm')]: {
        width: 0
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    menuIcon: {
      marginLeft: 8
    }
  })
);

const NavigationDrawerItemIcon: React.FC<NavigationDrawerItemIconProps> = ({ open, icon, svgIcon, title }) => {
  const classes = useStyles((...args) => ({ ...args }));

  let renderedIcon = <>{icon}</>;

  if (svgIcon) {
    renderedIcon = <CustomIcon icon={svgIcon} />;
  }

  if (!open) {
    return (
      <Tooltip title={title ?? ''} placement="right">
        <ListItemIcon className={classes.menuIcon}>{renderedIcon}</ListItemIcon>
      </Tooltip>
    );
  } else {
    return <ListItemIcon className={classes.menuIcon}>{renderedIcon}</ListItemIcon>;
  }
};

export const NavigationDrawer: React.FC<{ updatedBarHeight: (height: number) => void }> = ({ updatedBarHeight }) => {
  const classes = useStyles((...args) => ({ ...args }));
  const allClasses = useClasses();
  const [studentsMenuOpen, setStudentsMenuOpen] = useState(false);
  const childLinks = useChildLinks();
  const notificationsButtonref = useRef<HTMLDivElement>(null);
  const accountButtonRef = useRef<HTMLDivElement>(null);
  const studentsButtonRef = useRef<HTMLButtonElement>(null);
  const studentsMatch = useRouteMatch(`/students`);
  const selectedStudentsMatch = useRouteMatch<{ studentId: string }>(`/students/:id([^\/a-zA-Z]+)*/student/:studentId/*`);
  const location = useLocation();
  const siteCtx = useContext(SiteContext);
  const studentsCtx = useContext(StudentsMenuContext);
  const userCtx = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);
  const [hasSetDefaultOpenState, setHasSetDefaultOpenState] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
  const headerStringGroups = useStringGroup({ variables: { groupName: 'SITE_CONTENT_HEADER' } });
  const mainBarHeight = 48;
  const studentBarHeight = 48;
  const headerHeight = 75;

  if (!hasSetDefaultOpenState && isDesktop && siteCtx.site?.navigationDrawerOpenByDefault) {
    setOpen(true);
    setHasSetDefaultOpenState(true);
  }

  const headerPlacement =
    headerStringGroups.data?.stringGroup && headerStringGroups.data.stringGroup.length > 0
      ? headerStringGroups.data?.stringGroup[0].string
      : undefined;

  let barHeight = mainBarHeight;
  if (selectedStudentsMatch && selectedStudentsMatch.isExact) {
    barHeight += studentBarHeight;
  }
  if (headerPlacement) {
    barHeight += headerHeight;
  }

  useEffect(() => updatedBarHeight(barHeight), [barHeight]);
  const toggleNotificationsMenu = () => setNotificationsMenuOpen(!notificationsMenuOpen);
  const toggleAccountMenu = () => setAccountMenuOpen(!accountMenuOpen);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const toggleStudentsMenu = () => setStudentsMenuOpen(!studentsMenuOpen);
  const clickedNavItem = () => (isMobile ? handleDrawerClose() : undefined);

  const findRouteForMatch = (route: DTO.NavRoute, match: string): DTO.NavRoute | undefined => {
    const routeMatch = matchPath(match, {
      path: route.path,
      exact: route.isExact,
      strict: false
    });

    if (routeMatch && routeMatch.path === route.path && routeMatch.path !== '/') {
      return route;
    } else if (route.children && route.children.length > 0) {
      for (const child of route.children) {
        const matchedChild = findRouteForMatch(child, match);
        if (matchedChild) {
          return matchedChild;
        }
      }
    }

    return undefined;
  };

  const getCurrentRouteTitle = (onStudentsRoute: Boolean) => {
    if (onStudentsRoute && selectedStudentsMatch && selectedStudentsMatch.isExact && studentsCtx?.selectedStudent) {
      const caseId = UserHelper.getCaseId(studentsCtx.selectedStudent);

      let name = studentsCtx.selectedStudent.firstName;
      if (caseId) name += `, ${caseId}`;

      return name;
    }

    if (siteCtx.site && siteCtx.site.navRoutes) {
      const routes = siteCtx.site.navRoutes;

      for (const route of routes) {
        const matchedRoute = findRouteForMatch(route, location.pathname);
        if (matchedRoute) {
          return matchedRoute.title.text ?? '';
        }
      }
    }

    return '';
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx([classes.appBar, allClasses.navigationDrawerAppBar], {
          [classes.appBarShift]: open
        })}
      >
        {headerPlacement && (
          <Toolbar>
            <Grid container spacing={0} justify="space-between">
              <Grid item xs={12}>
                <LanguageString languageString={headerPlacement} />
              </Grid>
            </Grid>
          </Toolbar>
        )}
        <Toolbar style={{ minHeight: mainBarHeight }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={0} justify="space-between">
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open
                    })}
                  >
                    <Icon>menu</Icon>
                  </IconButton>

                  {studentsMatch && (
                    <>
                      <Button className={allClasses.navigationTitleDropdown} ref={studentsButtonRef} onClick={toggleStudentsMenu}>
                        <Typography variant="h6">{getCurrentRouteTitle(true)}</Typography>
                        <Icon>keyboard_arrow_down</Icon>
                      </Button>
                      <StudentSelectionMenu open={studentsMenuOpen} toggle={toggleStudentsMenu} anchor={studentsButtonRef} />
                    </>
                  )}
                  {!studentsMatch && (
                    <Typography className={allClasses.navigationTitle} variant="h6">
                      {getCurrentRouteTitle(false)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {selectedStudentsMatch && selectedStudentsMatch.isExact && (
          <Toolbar style={{ minHeight: studentBarHeight }}>
            <Grid container>
              <Grid item xs={12}>
                <StudentNavigationBar />
              </Grid>
            </Grid>
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar} style={{ height: barHeight }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <Icon>keyboard_arrow_right</Icon> : <Icon>keyboard_arrow_left</Icon>}
          </IconButton>
        </div>
        <Divider />
        <List>
          {childLinks?.map(cl => {
            if (cl.item.group !== 'navbar') return null;

            return (
              <ListItem button key={cl.item.id} component={RouterLink} to={cl.item.href ? cl.item.href : cl.path} onClick={clickedNavItem}>
                {cl.item.icon &&
                  <NavigationDrawerItemIcon
                    open={open}
                    icon={<Icon>{cl.item.icon}</Icon>}
                    svgIcon={cl.item.svgIcon}
                    title={<LanguageString languageString={cl.item.title} />}
                  />
                }
                <ListItemText primary={<LanguageString languageString={cl.item.title} />} />
              </ListItem>
            );
          })}
        </List>
        <div style={{ flexGrow: 1 }} />
        <List>
          {siteCtx.site?.notificationsEnabled && (
            <ListItem button ref={notificationsButtonref} onClick={toggleNotificationsMenu}>
              <NavigationDrawerItemIcon
                open={open}
                icon={
                  <>
                    {userCtx.user.unreadNotificationCount ? (
                      <Badge badgeContent={userCtx.user.unreadNotificationCount} color="secondary">
                        <NotificationsIcon />
                      </Badge>
                    ) : (
                      <NotificationsIcon />
                    )}
                  </>
                }
                svgIcon={undefined}
                title={<LanguageString groupName="GENERAL" resourceName="NOTIFICATIONS" defaultText="Notifications" />}
              />
              <ListItemText primary={<LanguageString groupName="GENERAL" resourceName="NOTIFICATIONS" defaultText="Notifications" />} />
            </ListItem>
          )}

          <ListItem button ref={accountButtonRef} onClick={toggleAccountMenu}>
            <NavigationDrawerItemIcon
              open={open}
              icon={<ProfileIcon />}
              svgIcon={undefined}
              title={<LanguageString groupName="GENERAL" resourceName="PROFILE" defaultText="Profile" />}
            />
            <ListItemText primary={<LanguageString groupName="GENERAL" resourceName="PROFILE" defaultText="Profile" />} />
          </ListItem>

          <NotificationsMenu open={notificationsMenuOpen} toggle={toggleNotificationsMenu} anchor={notificationsButtonref} />
          <AccountMenu open={accountMenuOpen} toggle={toggleAccountMenu} anchor={accountButtonRef} />
        </List>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
