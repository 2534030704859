import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { AppNavBarConfigurationType, Site } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { ReqVars } from './common';

type Variables = { role: string; type: AppNavBarConfigurationType };
type Results = { site: QueryType<Site> };

const query = gql`
  query getCoachNavBar($role: String!, $type: AppNavBarConfigurationType!) {
    site {
      id
      navbarConfiguration(role: $role, type: $type) {
        id
        title
        links {
          id
          title
          path
          className
          exact
          order
          icon
        }
      }
    }
  }
`;

export const useCoachNavBar = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
