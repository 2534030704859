import gql from 'graphql-tag';
import { languageStringFrag } from '.';

export const languageStringGroupFrag = gql`
  ${languageStringFrag}
  fragment LangStringGroupFrag on LanguageStringGroup {
    id
    groupName
    resourceName
    string {
      ...LangStringFrag
    }
  }
`;

export default languageStringGroupFrag;
