import React, { useContext, useEffect, useCallback } from 'react';
import {
  IconButton,
  Icon,
  Tooltip
} from '@material-ui/core';
import { PrintLocation } from 'tuapath-common/generated/schema';
import { LanguageString, FullscreenLoading } from '../';
import { useDownloadPrintFile } from '../../../hooks';
import { ComponentNotificationContext, StudentContext } from '../../../contexts';

interface DownloadTemplateProps {
  location: PrintLocation;
  primary?: boolean;
  relationId?: string;
  schemaId?: number;
  disabledDownloadButton?: boolean;
}

export const downloadPrintTriggerKey = 'download_print';

export const DownloadTemplate: React.FC<DownloadTemplateProps> = (props) => {
  const notificationCtx = useContext(ComponentNotificationContext);
  const userCtx = useContext(StudentContext);
  const [downloadPrint, { loading }] = useDownloadPrintFile({
    fetchPolicy: 'network-only',
    onCompleted: useCallback((data) => {
      if (data && data.user.downloadPrintFile) {
        window.open(data.user.downloadPrintFile, '_blank');
      }
    }, [])
  });

  const triggerDownload = useCallback(() => {
    if (userCtx?.student) {
      void downloadPrint({
        variables: {
          userId: userCtx.student.id,
          location: props.location,
          primary: props.primary,
          relationId: props.relationId,
          schemaId: props.schemaId
        }
      });
    }
  }, [props]);

  useEffect(() => {
    notificationCtx.removeListender(downloadPrintTriggerKey);
    notificationCtx.addListener(downloadPrintTriggerKey, triggerDownload);

    return () => notificationCtx.removeListender(downloadPrintTriggerKey);
  }, [props]);

  return (
    <>
      {!props.disabledDownloadButton && (
        <Tooltip
          title={
            <LanguageString groupName="SITE_CONTENT" resourceName="DOWNLOAD" defaultText="Download" />
          }
        >
          <IconButton onClick={triggerDownload}>
            <Icon>cloud_download</Icon>
          </IconButton>
        </Tooltip>
      )}

      {loading && (
        <FullscreenLoading text="Generating file..." />
      )}
    </>
  );
};

export default DownloadTemplate;
