import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { UpdateMilestoneMutationArgs, UpdateMilestoneResult } from '../../dto';
import { milestoneFrag } from './fragments';

type Result = { updateMilestones: UpdateMilestoneResult };
type Variables = UpdateMilestoneMutationArgs;
export function useUpdateMilestone(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${milestoneFrag}

    mutation updateMilestone($input: [MilestoneInput]!) {
      updateMilestones(input: $input) {
        groupInfo {
          groupId
          msId
          sortIndex
        }
        milestones {
          ...MilestoneFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, { ...options });
}

export default useUpdateMilestone;
