import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { ResetPasswordFailureReason } from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { useLanguageString, useResetPassword } from '../../../hooks';
import { DialogTitle, LanguageString, SuccessIconMessage } from '../../Common';

interface ResetPasswordDialogProps {
  open: boolean;
  token?: string;
  toggle: () => void;
}

interface ResetPasswordSchema {
  email: string;
  newPassword: string;
  confirmPassword: string;
}

interface FormStatus {
  submitted: boolean;
  message?: string;
}

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ open, toggle, token }) => {
  const formRef = useRef<FormikProps<ResetPasswordSchema> | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [resetPassword] = useResetPassword();
  const { data } = useLanguageString({ variables: { groupName: 'CHANGE_PW', resourceName: 'WEAK_PW' } });

  const initialValues: ResetPasswordSchema = {
    email: '',
    newPassword: '',
    confirmPassword: ''
  };

  const initialStatus: FormStatus = {
    submitted: false,
    message: undefined
  };

  const Validation = Yup.object().shape({
    email: Yup.string().email('* Invalid Email').required('* Required'),
    newPassword: Yup.string().required('* Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('* Required')
  });

  const localToggle = () => {
    setSubmitted(false);
    toggle();
  };

  const handleSubmit = (values: ResetPasswordSchema, formikHelpers: FormikHelpers<ResetPasswordSchema>) => {
    resetPassword({
      variables: {
        input: {
          email: values.email,
          token: token ?? '',
          newPassword: values.newPassword
        }
      },
      update: (cache, result) => {
        if (result.data?.resetPassword.success === true) {
          formikHelpers.setStatus({
            submitted: true,
            message: undefined
          });

          setSubmitted(true);
        } else {
          if (result.data?.resetPassword.reason === ResetPasswordFailureReason.weakPassword) {
            formikHelpers.setStatus({ submitted: false, message: data?.string.text });
          }
          formikHelpers.setStatus({
            submitted: false,
            message: 'Oops. Something went wrong'
          });
        }
      }
    }).catch(res => {
      const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
        return error.message;
      });

      if (errors.length > 0) {
        formikHelpers.setStatus({
          submitted: false,
          message: errors[0]
        });
      }
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={localToggle} aria-labelledby="Reset Password Form">
      <DialogTitle onClose={localToggle}>
        <LanguageString groupName="GENERAL" resourceName="RESET_PASSWORD" defaultText="Reset Password" />
      </DialogTitle>
      <DialogContent dividers>
        <Formik initialValues={initialValues} initialStatus={initialStatus} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={Validation} >
          {formikProps => {
            formRef.current = formikProps;

            const status = formikProps.status as FormStatus;

            if (status.submitted) {
              return <SuccessIconMessage animated message="Your password has been reset" />;
            }

            return (
              <Form>
                {!status.submitted && (
                  <DialogContentText>
                    <LanguageString
                      groupName="GENERAL"
                      resourceName="FORGOT_PASSWORD_RESET_DESCRIPTION"
                      defaultText="Please type in your email address and new password"
                    />
                  </DialogContentText>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="email">
                      {() => (
                        <TextField
                          fullWidth
                          name="email"
                          label={<LanguageString groupName="GENERAL" resourceName="EMAIL" defaultText="Email" />}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.email && formikProps.touched.email)}
                          helperText={formikProps.errors.email && formikProps.touched.email && String(formikProps.errors.email)}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="newPassword">
                      {() => (
                        <TextField
                          fullWidth
                          name="newPassword"
                          label={<LanguageString groupName="GENERAL" resourceName="NEWPASSWORD" defaultText="New Password" />}
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.newPassword && formikProps.touched.newPassword)}
                          helperText={formikProps.errors.newPassword && formikProps.touched.newPassword && String(formikProps.errors.newPassword)}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field validateOnBlur validateOnChange name="confirmPassword">
                      {() => (
                        <TextField
                          fullWidth
                          name="confirmPassword"
                          label={<LanguageString groupName="GENERAL" resourceName="CONFIRMNEWPASSWORD" defaultText="Confirm New Password" />}
                          type="password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          error={Boolean(formikProps.errors.confirmPassword && formikProps.touched.confirmPassword)}
                          helperText={
                            formikProps.errors.confirmPassword && formikProps.touched.confirmPassword && String(formikProps.errors.confirmPassword)
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                {(formikProps.status as FormStatus).message && (
                  <Grid item xs={12}>
                    <Alert severity="error">{formikProps.status.message}</Alert>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={localToggle} color="primary">
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        {!submitted && (
          <Button variant="contained" onClick={() => formRef.current?.submitForm()} color="primary">
            <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
