import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useLogout(options?: MutationHookOptions<boolean>) {
  const query = gql`
    mutation logout {
      logout
    }
  `;

  return useMutation<boolean>(query);
}
