import { MTSState } from 'tuapath-common/generated/schema';
import * as DTO from '../dto';
import UserHelper from './userHelper';
import { isSameDay, endOfMonth, getDate, getMonth, getYear, isValid, addDays } from 'date-fns';

type ApprovalTypes = { [typeId: number]: { [userTypeId: number]: DTO.MTSActivity[] } };

export const getTypesNeedingApproval = (mts: DTO.MTS): ApprovalTypes | undefined => {
  return mts.entries?.entries?.filter(e => e.type.externalApprovalFormId != null).reduce((pv, currActivity) => {
    if (pv[currActivity.type.id] == null)
      pv[currActivity.type.id] = {};
    if (pv[currActivity.type.id][currActivity.userType?.id ?? 0] == null)
      pv[currActivity.type.id][currActivity.userType?.id ?? 0] = [];
    pv[currActivity.type.id][currActivity.userType?.id ?? 0].push(currActivity);
    return pv;
  }, {} as { [typeId: number]: { [uTypeId: number]: DTO.MTSActivity[]; }; });
};

type PropertyType = 'approval' | 'modification' | 'submission';

export default class MTSHelpers {
  // #region prermissions

  private static offsetProperties(property: PropertyType) {
    switch (property) {
      case 'approval': return { start: 'mtsApproveStartDayOffset', end: 'mtsApproveEndDayOffset' }; break;
      case 'modification': return { start: 'mtsModificationStartDayOffset', end: 'mtsModificationEndDayOffset' }; break;
      case 'submission': return { start: 'mtsSubmissionStartDayOffset', end: 'mtsSubmissionEndDayOffset' }; break;
    }
  }

  private static dateOffsetsForSite(site: DTO.Site, property: PropertyType, mts: DTO.MTSPeriod | undefined) {
    const properties = MTSHelpers.offsetProperties(property);
    const siteStart = site[properties.start] ?? 0;
    const siteEnd = site[properties.end] ?? 0;
    if (site.mtsPeriodsEnabled && mts) {
      const start = mts[properties.start];
      const end = mts[properties.end];

      return {
        start: start != undefined || start != null ? start : siteStart,
        end: end != undefined || end != null ? end : siteEnd
      };
    }

    return { start: siteStart, end: siteEnd };
  }

  private static datesForMTS(site: DTO.Site, date: Date, periods?: DTO.MTSPeriod[], mts?: DTO.MTS) {
    if (site.mtsPeriodsEnabled) {
      let period = mts?.mtsPeriod;
      if (!period) {
        period = MTSHelpers.getMTSPeriodForDate(date, periods);
      }

      if (period) {
        return { start: MTSHelpers.dateFromString(period.startDate), end: MTSHelpers.dateFromString(period.endDate, true) };
      }

      return undefined;
    } else {
      if (mts) {
        return { start: new Date(mts.year, mts.month - 1, 1), end: endOfMonth(new Date(mts.year, mts.month - 1, 1)) };
      } else {
        return { start: new Date(date.getFullYear(), date.getMonth(), 1), end: endOfMonth(new Date(date.getFullYear(), date.getMonth(), 1)) };
      }
    }
  }

  private static isDateWithinRange(date: Date, startOffset: number, endOffset: number ) {
    const startDate = addDays(date, startOffset);
    const endDate = addDays(date, endOffset);
    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  }

  private static isModInDateRange(periodStart: Date, periodEnd: Date, startOffset: number, endOffset: number) {
    const startDate = addDays(periodStart, startOffset);
    const endDate = addDays(periodEnd, endOffset);
    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  }

  private static getTargetEndDate(mts: DTO.MTS, mtsPeriods?: DTO.MTSPeriod[]) {
    if (mts.mtsPeriod) {
      return MTSHelpers.dateFromString(mts.mtsPeriod.endDate, true);
    }

    return endOfMonth(new Date(mts.year, mts.month - 1, 1));
  }

  static canApproveMTS(user: DTO.User, site: DTO.Site, mts: DTO.MTS, mtsPeriods?: DTO.MTSPeriod[]): boolean {
    if (mts.state === MTSState.APPROVED || mts.state === MTSState.NOT_SUBMITTED) return false;

    if (UserHelper.isAdvisorOrAdmin(user)) {
      const offsets = this.dateOffsetsForSite(site, 'approval', mts.mtsPeriod);
      const targetDate = MTSHelpers.getTargetEndDate(mts, mtsPeriods);

      return MTSHelpers.isDateWithinRange(targetDate, offsets.start, offsets.end);
    }

    return false;
  }

  static canReviewMTS(user: DTO.User, mts: DTO.MTS): boolean {
    if (mts.state === MTSState.SUBMITTED || mts.state === MTSState.NOT_SUBMITTED) return false;

    if (UserHelper.isAdvisorOrAdmin(user)) {
      return true;
    }

    return false;
  }

  static canChangeMTS(user: DTO.User, date: Date, site: DTO.Site, mts?: DTO.MTS, mtsPeriods?: DTO.MTSPeriod[]): boolean {
    if (mts) {
      if (mts.state === MTSState.APPROVED) return false;
      if ((mts.state === MTSState.SUBMITTED) && !UserHelper.isAdvisorOrAdmin(user)) return false;
    }
    if (UserHelper.isAdvisorOrAdmin(user)) return true;

    let mtsPeriod = mts?.mtsPeriod;
    if (!mtsPeriod && mtsPeriods) {
      mtsPeriod = MTSHelpers.getMTSPeriodForDate(date, mtsPeriods);
    }

    const dates = this.datesForMTS(site, date, mtsPeriods, mts);

    if (dates) {
      const offsets = this.dateOffsetsForSite(site, 'modification', mtsPeriod);
      return MTSHelpers.isModInDateRange(dates.start, dates.end, offsets.start, offsets.end);
    }

    return false;
  }

  static canSubmitMTS(user: DTO.User, site: DTO.Site, mts: DTO.MTS, mtsPeriods?: DTO.MTSPeriod[]): boolean {
    if (mts.state !== MTSState.NOT_SUBMITTED) return false;
    if (UserHelper.isAdmin(user)) return true;

    const offsets = this.dateOffsetsForSite(site, 'submission', mts.mtsPeriod);
    const targetDate = MTSHelpers.getTargetEndDate(mts, mtsPeriods);

    return MTSHelpers.isDateWithinRange(targetDate, offsets.start, offsets.end);
  }

  static canUploadMTS(user?: DTO.User, mts?: DTO.MTS, month?: number, year?: number): boolean {
    if (!user) return false;

    return true;
  }

  // #endregion permissions

  // #region Log Notes

  private static logEntries = (mtses: DTO.MTS[]): DTO.MTSActivity[] => {
    const entries: DTO.MTSActivity[] = [];
    const allEntries = mtses.map(mts => mts.entries?.entries ?? []).flat();

    for (const entry of allEntries) {
      if (entry.note && entry.note.length > 0) {
        entries.push(entry);
      }
    }

    return entries;
  };

  private static sortGroups = (groups: Array<Array<DTO.MTSActivity>>): Array<Array<DTO.MTSActivity>> => {
    const sortedGroups: Array<Array<DTO.MTSActivity>> = [];

    for (const activites of groups) {
      const sortedActivites = activites.sort((a, b) => {
        return a.day > b.day ? 1 : -1;
      });
      sortedGroups.push(sortedActivites);
    }

    return sortedGroups;
  };

  static logNotesEntryGroups = (mtses?: DTO.MTS[]): Array<Array<DTO.MTSActivity>> => {
    if (!mtses) {
      return [];
    }

    const finalGroups: Array<Array<DTO.MTSActivity>> = [];
    const entries = MTSHelpers.logEntries(mtses);

    for (const entry of entries) {
      let found = false;
      let i = 0;
      for (const group of finalGroups) {
        if (group.length > 0) {
          const e = group[0];

          if (e.type.id === entry.type.id) {
            found = true;

            break;
          }
        }

        i += 1;
      }

      if (found) {
        const group = finalGroups[i];
        group.push(entry);
        finalGroups[i] = group;
      } else {
        finalGroups.push([entry]);
      }
    }

    return MTSHelpers.sortGroups(finalGroups);
  };

  // #endregion Log Notes

  // #region Calculations

  static dateEquality(dateA: Date, dateB: Date) {
    return getYear(dateA) === getYear(dateB) &&
      getMonth(dateA) === getMonth(dateB) &&
      getDate(dateA) === getDate(dateB);
  }

  static dateWithStaticTime(dateStr: string) {
    return new Date(`${dateStr.substr(0, 10)}T06:00:00`);
  }

  static mtsForDate(date: Date, mtses: DTO.MTS[], period?: DTO.MTSPeriod) {
    if (period) {
      const mts = mtses.find(m => {
        return m.mtsPeriod?.id === period.id;
      });
      if (mts) return mts;
    }

    const month = getMonth(date) + 1;
    const year = getYear(date);

    for (const mts of mtses) {
      if (mts.year === year && mts.month === month) {
        return mts;
      }
    }

    return undefined;
  }

  static totalHoursForMTS = (mts: DTO.MTS) => {
    let totalHours = 0;
    if (mts?.entries?.entries) {
      for (const entry of mts.entries.entries) {
        totalHours += entry.hours;
      }
    }

    return totalHours;
  };

  static totalWPRHoursForMTS = (mts: DTO.MTS) => {
    let totalHours = 0;
    if (mts?.entries?.entries) {
      for (const entry of mts.entries.entries) {
        if (entry.type.wpr) {
          totalHours += entry.hours;
        }
      }
    }

    return totalHours;
  };

  static dayColumnsForMTSEntries = (mts: DTO.MTS) => {
    let days: Date[] = [];

    if (mts && mts.entries && mts.entries.entries) {
      const entries = mts.entries.entries;

      for (const entry of entries) {
        let date = entry.date && (typeof entry.date !== 'string' || entry.date.length) ? MTSHelpers.dateFromString(entry.date) : undefined;
        if (!date || !isValid(date)) date = new Date(mts.year, mts.month - 1, entry.day);

        if (date) {
          const hasDay = days.find(d => isSameDay(d, date!));
          if (!hasDay) {
            days.push(date);
          }
        }
      }
    }

    days = days.sort((a, b) => a.getTime() - b.getTime());

    return days;
  };

  static activitiesGrouped = (mts: DTO.MTS) => {
    const activityGroups: Array<Array<DTO.MTSActivity>> = [];

    if (mts && mts.entries && mts.entries.entries) {
      const entries = mts.entries.entries;

      for (const entry of entries) {
        let foundGroupIndex: number | null = null;
        let index = 0;
        for (const group of activityGroups) {
          if (group.length > 0) {
            const first = group[0];
            if ((first.userType && entry.userType && first.userType.id === entry.userType.id) ||
              (first.type.id === entry.type.id && !entry.userType)
            ) {
              foundGroupIndex = index;
              break;
            }
          }

          index += 1;
        }

        if (foundGroupIndex != null) {
          const group = activityGroups[foundGroupIndex];
          group.push(entry);
          activityGroups[foundGroupIndex] = group;
        } else {
          const newGroup = [entry];
          activityGroups.push(newGroup);
        }
      }
    }

    return activityGroups;
  };

  static compareDateIgnoringTime = (dateA: Date, dateB: Date) => {
    const yearA = getYear(dateA);
    const monthA = getMonth(dateA);
    const dayA = getDate(dateA);
    const yearB = getYear(dateB);
    const monthB = getMonth(dateB);
    const dayB = getDate(dateB);

    return new Date(yearA, monthA, dayA, 0, 0, 0).getTime() - new Date(yearB, monthB, dayB, 0, 0, 0).getTime();
  };

  static mtsEntryForDate(date: Date, mtses: DTO.MTS[], period?: DTO.MTSPeriod): DTO.MTSActivity[] | undefined {
    if (period) {
      return mtses
        .filter(mts => mts.mtsPeriod != null)
        .filter(mts =>  this.compareDateIgnoringTime(date, MTSHelpers.dateFromString(mts.mtsPeriod!.startDate)) >= 0 && this.compareDateIgnoringTime(date, MTSHelpers.dateFromString(mts.mtsPeriod!.endDate, true)) <= 0)
        .map(mts => mts.entries?.entries ?? [])
        .flat()
        .filter(entry => this.dateEquality(date, MTSHelpers.dateFromString(entry.date)));
    } else {
      const month = getMonth(date) + 1;
      const year = getYear(date);
      const day = getDate(date);

      return mtses
        .filter(m => m.month === month && m.year === year)
        .map(m => {
          return (m.entries?.entries ?? []).filter(e => {
            const d = e.date ? getDate(MTSHelpers.dateFromString(e.date)) : e.day;
            return (d === day);
          });
        })
        .flat();
    }
  }

  static totalHoursForYear = (date: Date, mtses: DTO.MTS[]): number => {
    let totalHours = 0;
    const year = getYear(date);
    const month = getMonth(date) + 1;

    for (const mts of mtses) {
      if (mts.entries && mts.entries.entries &&
        (
          (
            mts.mtsPeriod && date >= MTSHelpers.getPeriodDates(mts.mtsPeriod).startDate &&
            date <= MTSHelpers.getPeriodDates(mts.mtsPeriod).endDate
          ) ||
          (mts.year === year && mts.month === month)
        )
      ) {
        for (const entry of mts.entries.entries) {
          totalHours += entry.hours;
        }
      }
    }

    return totalHours;
  };

  static wprHoursForYear = (date: Date, mtses: DTO.MTS[]): number => {
    let totalHours = 0;
    const year = getYear(date);
    const month = getMonth(date) + 1;

    for (const mts of mtses) {
      if (mts.entries && mts.entries.entries &&
        (
          (
            mts.mtsPeriod && date >= MTSHelpers.getPeriodDates(mts.mtsPeriod).startDate &&
            date <= MTSHelpers.getPeriodDates(mts.mtsPeriod).endDate
          ) ||
          (mts.year === year && mts.month === month)
        )
      ) {
        for (const entry of mts.entries.entries) {
          if (entry.type.wpr) {
            totalHours += entry.hours;
          }
        }
      }
    }

    return totalHours;
  };

  static totalHoursForDateRange = (startDate: Date, endDate: Date, mtses: DTO.MTS[]): number => {
    let totalHours = 0;

    for (const mts of mtses) {
      if (mts.entries && mts.entries.entries) {
        for (const entry of mts.entries.entries) {
          let entryDate = new Date();
          if (entry.date && (typeof entry.date !== 'string' || entry.date.length)) {
            entryDate = MTSHelpers.dateFromString(entry.date);
          }
          if (!entryDate || !isValid(entryDate)) {
            entryDate = new Date(mts.year, mts.month - 1, entry.day);
          }

          if (MTSHelpers.compareDateIgnoringTime(entryDate, startDate) >= 0 && MTSHelpers.compareDateIgnoringTime(entryDate, endDate) <= 0) {
            totalHours += entry.hours;
          }
        }
      }
    }

    return totalHours;
  };

  static wprHoursForDateRange = (startDate: Date, endDate: Date, mtses: DTO.MTS[]): number => {
    let totalHours = 0;

    for (const mts of mtses) {
      if (mts.entries && mts.entries.entries) {
        for (const entry of mts.entries.entries) {
          if (entry.type.wpr) {
            let entryDate = new Date();
            if (entry.date && (typeof entry.date !== 'string' || entry.date.length)) {
              entryDate = MTSHelpers.dateFromString(entry.date);
            }
            if (!entryDate || !isValid(entryDate)) {
              entryDate = new Date(mts.year, mts.month - 1, entry.day);
            }

            if (MTSHelpers.compareDateIgnoringTime(entryDate, startDate) >= 0 && MTSHelpers.compareDateIgnoringTime(entryDate, endDate) <= 0) {
              totalHours += entry.hours;
            }
          }
        }
      }
    }

    return totalHours;
  };

  // #endregion Calculations

  // #region Date adjustments

  static shiftUTCDateToLocalDate(tzDate: Date) {
    const tzUTCOffset = tzDate.getTimezoneOffset();
    const utcDate = new Date();
    utcDate.setTime(tzDate.getTime() + tzUTCOffset * 60000);

    const pickerDate = new Date();
    const pickerOffset = pickerDate.getTimezoneOffset();
    pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);

    return pickerDate;
  }

  static shiftStartDateTime(date: Date) {
    const c = date;
    c.setHours(0, 0, 0);

    return c;
  }

  static shiftEndDateTime(date: Date) {
    const c = date;
    c.setHours(23, 59, 59);

    return c;
  }

  static getMTSPeriodForDate(date: Date, periods?: DTO.MTSPeriod[]) {
    if (periods && periods.length) {
      return periods.find(p => date >= MTSHelpers.getPeriodDates(p).startDate && date <= MTSHelpers.getPeriodDates(p).endDate);
    }

    return undefined;
  }

  static getCurrentMTSPeriod(periods?: DTO.MTSPeriod[]) {
    const date = new Date();
    const current = [...(periods ?? [])].find(p => date >= MTSHelpers.dateFromString(p.startDate) && date <= MTSHelpers.dateFromString(p.endDate, true));

    if (current) {
      return current;
    } else {
      const sortedPeriods =  [...(periods ?? [])].sort((a, b) => MTSHelpers.dateFromString(a.endDate).getTime() - MTSHelpers.dateFromString(b.endDate, true).getTime());
      if (sortedPeriods.length > 0) return sortedPeriods[sortedPeriods.length - 1];
    }

    return undefined;
  }

  static getPeriodDates(period: DTO.MTSPeriod) {
    return { startDate: MTSHelpers.dateFromString(period.startDate), endDate: MTSHelpers.dateFromString(period.endDate, true) };
  }

  static dateFromString(dateString: Date | string, end?: boolean) {
    if (dateString instanceof Date) return dateString;
    if (dateString[10] === 'T') {
      if (end === true) return new Date(`${dateString.substring(0, 10)}T23:59:00`);
      return new Date(`${dateString.substring(0, 10)}T00:00:00`);
    } else {
      if (end === true) return new Date(`${dateString}T23:59:00`);
      return new Date(`${dateString}T00:00:00`);
    }
  }

  // #endregion Date adjustments
}
