import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { mtsFrag } from './fragments';

type Result = { useMtsUnapprove: Boolean };
type Variables = { id: number };
export const useMtsUnapprove = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${mtsFrag}

    mutation useMtsUnapprove($id: Int!) {
      mtsUnapprove(mtsId: $id) {
        ...MTSFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useMtsUnapprove;
