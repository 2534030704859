import { Button, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitFormInput } from '../../dto';
import { useImportData } from '../../hooks';
import { useSiteImportActions } from '../../hooks/gql/useSiteImportActions';
import { DynamicForm } from '../DynamicForm';
import { FileTableDropzone } from '../FileBox/Dropzone';
import { LanguageString, MessageDialog } from '../Common';

export const DataImport: React.FC = () => {
  const { data, loading } = useSiteImportActions();
  const [importData] = useImportData();
  const [selActionId, setSelActionId] = useState<string | undefined>();
  const { actionParam } = useParams<{ actionParam?: string }>();
  const selAction = data?.site.importActions.find(ia => actionParam ? ia.action === actionParam : ia.id === Number(selActionId));
  const [selFile, setSelFile] = useState<File | undefined>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState<string[] | null | undefined>();
  const [showSuccess, setShowSuccess] = useState(false);
  const df = useRef<DynamicForm | null>(null);

  const handleSubmit = async (input: SubmitFormInput) => {
    console.log('submit', selFile);
    if (selFile != null && selAction != null) {
      setIsDisabled(true);
      const test = await importData({ variables: { input: { file: selFile, id: selAction.id, formData: input } } });
      setSelFile(undefined);
      setIsDisabled(false);
      if (test.data?.importData) setErrors(test.data?.importData);
      else setShowSuccess(true);
    }
  };

  return loading ? <CircularProgress color="primary" /> : <div>
    {actionParam == null && <div>
      <TextField select onChange={(ev) => setSelActionId(ev.target.value)}>
        {data?.site.importActions.map(ia =>
          <MenuItem key={ia.id} value={ia.id}>{ia.action}</MenuItem>
        )}
      </TextField>
    </div>}
    {selAction?.action != null ?
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <DynamicForm ref={df} disabled={isDisabled} formId={selAction.formId} autoloadFormSubmission={false} overrideSubmitHandler={handleSubmit} showSubmitButton={false} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div>Files</div>
          {selFile && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
            <div>{selFile.name}</div>
            <Button variant="contained" color="primary" disabled={isDisabled} onClick={() => df.current?.submitForm()}>Submit</Button>
          </div>}
          <FileTableDropzone callback={async file => setSelFile(file)} />
          {showSuccess && (
            <MessageDialog
              message={<LanguageString groupName="BULK_UPDATE" resourceName="SUCCESS" />}
              onConfirm={() => setShowSuccess(false)}
            />
          )}
          {errors && <div><div>Errors:</div><div>{errors.map(e => <div>{e}</div>)}</div></div>}
        </div>
      </div> : <div>Select an import job</div>
    }
  </div>;
};
