import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrintLocation } from 'tuapath-common/generated/schema';
import { FullscreenLoading } from '..';
import { StudentContext } from '../../contexts';
import { useDownloadPrintFile } from '../../hooks';

export const DownloadRoute: React.FC = () => {
  const [downloadPrint] = useDownloadPrintFile({
    fetchPolicy: 'network-only',
    onCompleted: useCallback((data) => {
      if (data && data.user.downloadPrintFile) {
        window.open(data.user.downloadPrintFile, '_blank');
        history.goBack();
      }
    }, []),
    onError: useCallback(() => {
      history.goBack();
    }, [])
  });
  const params = useParams<{ tpId: string }>();
  const history = useHistory();
  const stdCtx = useContext(StudentContext);

  useEffect(() => {

    if (stdCtx) {
      void downloadPrint({
        variables: {
          userId: stdCtx.student.id,
          location: PrintLocation.FormSubmission,
          relationId: params.tpId,
          primary: true,
          schemaId: Number(params.tpId)
        }
      });
    }
  }, [stdCtx]);

  return <FullscreenLoading text="Generating file..." />;
};
