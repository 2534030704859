import * as DTO from '../../dto';
import { Attachment } from 'tuapath-common/generated/schema';

export type SendViaOption = 'Email and SMS' | 'Email' | 'SMS';
export const sendViaOptions: SendViaOption[] = ['Email and SMS', 'Email', 'SMS'];

export interface NotificationForm {
  selectedStudents: DTO.User[];
  allStudents: boolean;
  sendVia: number;
  replyTo: string;
  subject: string;
  body: string;
  plainBody: string;
  sendNow: boolean;
  sendDate?: Date;
  attachments?: Attachment[];
  expiration?: Date | null;
}
