import gql from 'graphql-tag';
import { sitePathsTaskFrag, taskFrag } from './taskFrag';
import { languageStringMinFrag } from './languageStringFrag';

export const milestoneFrag = gql`
  ${taskFrag}

  fragment MilestoneFrag on Milestone {
    id
    required
    allowMultipleInstances
    tasks {
      ...TaskFrag
    }
  }
`;

export const sitePathsMilestoneFrag = gql`
  ${languageStringMinFrag}
  ${sitePathsTaskFrag}

  fragment MilestoneFrag on Milestone {
    id
    required
    allowMultipleInstances
		completedEmail {
			id
			subject {
				...LangStringMinFrag
			}
			body {
				...LangStringMinFrag
			}
			additionalRecipients
		}
    tasks {
      ...TaskFrag
    }
  }
`;

export default milestoneFrag;
