import React, { useEffect, useContext, useState, useMemo } from 'react';
import { UserNotification } from '../../dto';
import { useUserNotifications } from '../../hooks';

interface NotificationsContextProps {
  allNotifications: UserNotification[],
  totalNotifications: number,
  setNotificationsPage: React.Dispatch<React.SetStateAction<number>>,
  shouldLoadMoreNotifications: boolean,
}

export const StudentNotificationsContext = React.createContext<NotificationsContextProps>({
  allNotifications: [],
  totalNotifications: 0,
  shouldLoadMoreNotifications: true,
  setNotificationsPage: () => { /* nothing */ }
});

export const UserNotificationsContext = React.createContext<NotificationsContextProps>({
  allNotifications: [],
  totalNotifications: 0,
  shouldLoadMoreNotifications: true,
  setNotificationsPage: () => { /* nothing */ }
});

const PAGE_SIZE = 25;

export const NotificationsProvider: React.FC<{ userId: number | undefined, type: 'student' | 'user' }> = ({ children, type, userId }) => {
  const [notificationsPage, setNotificationsPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [allNotifications, setAllNotifications] = useState<UserNotification[]>([]);
  const [allowLoadMore, setAllowLoadMore] = useState(true);
  const notificationsPagination = useMemo(() => {
    return { userId, limit: PAGE_SIZE, offset: notificationsPage * PAGE_SIZE };
  }, [notificationsPage, userId]);
  const { loading, data: notificationsData } = useUserNotifications({ variables: notificationsPagination });
  const shouldLoadMoreNotifications = useMemo(() => {
    if (loading || !allowLoadMore) {
      return false;
    }
    if (notificationsPage < totalPages) {
      return true;
    }

    return false;
  }, [notificationsData, loading, allowLoadMore]);

  useEffect(() => {
    if (loading) {
      setAllowLoadMore(false);
    }
  }, [loading]);

  useEffect(() => {
    if (notificationsData?.user?.notifications.notifications && notificationsData.user.notifications.notifications.length > 0) {
      const tp = Math.ceil(notificationsData.user.notifications.totalCount / PAGE_SIZE);
      if (tp !== totalPages) {
        setTotalPages(tp);
      }
      if (notificationsData.user.notifications.totalCount !== totalNotifications) {
        setTotalNotifications(notificationsData.user.notifications.totalCount);
      }

      const newNotifications = notificationsData.user.notifications.notifications.filter(
        newNotification => !allNotifications.some(existingNotification => existingNotification.id === newNotification.id)
      );
      if (newNotifications.length > 0) {
        const mergedNotifications = [...allNotifications, ...newNotifications];
        setAllNotifications(mergedNotifications);
      }

      setTimeout(() => {
        setAllowLoadMore(true);
      }, 750);
    }
  }, [notificationsData]);

  if (type === 'student') {
    return (
      <StudentNotificationsContext.Provider value={{ allNotifications, shouldLoadMoreNotifications, setNotificationsPage, totalNotifications }}>
        {children}
      </StudentNotificationsContext.Provider>
    );
  } else {
    return (
      <UserNotificationsContext.Provider value={{ allNotifications, shouldLoadMoreNotifications, setNotificationsPage, totalNotifications }}>
        {children}
      </UserNotificationsContext.Provider>
    );
  }
};

export const useStudentNotificationsCtx = () => {
  return useContext(StudentNotificationsContext);
};

export const useUserNotificationsCtx = () => {
  return useContext(UserNotificationsContext);
};

export default NotificationsProvider;
