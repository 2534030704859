import gql from 'graphql-tag';
import { languageStringMinFrag } from './languageStringFrag';
import { roadmapMeetingFrag } from './roadmapMeetingFrag';
import { roadmapMilestoneFrag } from './roadmapMilestoneFrag';
import { assignmentResultsFrag } from './assignmentResultsFrag';

export const roadmapFrag = gql`
  ${languageStringMinFrag}
  ${roadmapMeetingFrag}
  ${roadmapMilestoneFrag}
  ${assignmentResultsFrag}

  fragment RoadmapFrag on Roadmap {
    id
    user {
      id
      assignments {
        ...AssignmentResultsFrag
      }
    }
    name {
      ...LangStringMinFrag
    }
    meeting {
      ...RoadmapMeetingFrag
    }
    reviewedAt
    startedAt
    goal
    translation
    plannedEndDate
    plannedStartDate
    reviewNotes
    advisorReviewNotes
    employmentInfo
    empInfoTranslation
    milestoneGroups {
      id
      milestoneGroupId
      sortIndex
      goal
      translation
      name {
        ...LangStringMinFrag
      }
      milestones {
        ...RoadmapMilestoneFrag
      }
    }
  }
`;

export default roadmapFrag;
