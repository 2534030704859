import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User, UserNotification } from '../../dto';

type Variables = { limit: number, offset: number };
type Results = { user?: Pick<User, 'id'> & { notifications: {totalCount: number, notifications: UserNotification[]} } };

export function useUserNotifications(options: QueryHookOptions<Results, Variables>) {
  const query = gql`
    query getUserNotifications($userId: Int!, $limit: Int!, $offset: Int!) {
      user(id: $userId) {
        id
        notifications(limit: $limit, offset: $offset) {
          totalCount
          notifications {
            id
            actionUrl
            readDate
            title
            message
            triggerDate
            icon
            showInUi
          }
        }
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
}
