import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MTSActivity, MTSActivityInput } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';
import { mtsActivityFrag } from './fragments';

type Result = { saveMTSEntry: QueryType<MTSActivity> };
type Variables = { input: MTSActivityInput };
export const useSaveMTSEntry = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${mtsActivityFrag}

    mutation saveMTSEntry($input: MTSActivityInput!) {
      saveMTSEntry(input: $input) {
        ...MTSActivityFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useSaveMTSEntry;
