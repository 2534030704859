import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, IconButton, Icon } from '@material-ui/core';
import { NotificationsTable } from '../NotificationsTable';
import { LanguageString } from '../../Common';

interface NotificationsDialogProps {
  currentUserContext: boolean;
  open: boolean;
  toggle: () => void;
}

export const NotificationsDialog: React.FC<NotificationsDialogProps> = ({ open, toggle, currentUserContext }) => {

  return (
    <Dialog maxWidth="lg" fullWidth scroll="paper" open={open} onClose={toggle}>
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <LanguageString groupName="GENERAL" resourceName="NOTIFICATIONS" defaultText="Notifications" />
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent id='dialogBox' dividers>
        <NotificationsTable currentUserContext={currentUserContext} clickedNotification={toggle} scrollId='scrollableDivDialogue'/>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="primary">
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationsDialog;
