import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ApolloError } from 'apollo-client';
import { getMonth, getYear } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useImperativeHandle, useRef, useState } from 'react';
import * as yup from 'yup';
import { StudentContext, MTSContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { useMTSAddFile } from '../../../hooks';
import useDeleteFile from '../../../hooks/gql/useDeleteFile';
import { LanguageString } from '../../Common';
import { dropzoneFormValues, Dropzone } from '../../FileBox/Dropzone';
import { FileTable, FileTableColumn } from '../../FileBox/FileTable';

const formValidation = yup.object<dropzoneFormValues>().shape({
  file: yup.string().required('Required')
});

export type MTSAttachmentUpload = { submitForm: (activity: DTO.MTSActivity) => void };

interface MTSAttachmentUploadProps {
  date: Date;
  activityTypes: DTO.MTSActivityType[];
  selectedActivityType: DTO.MTSActivityType | DTO.UserMTSActivityType;
  mtsActivity?: DTO.MTSActivity;
}

const MTSAttachmentUploadInner: React.RefForwardingComponent<MTSAttachmentUpload, MTSAttachmentUploadProps> = (props, ref) => {
  const month = getMonth(props.date) + 1;
  const year = getYear(props.date);
  const mtsCtx = useContext(MTSContext);
  const studentCtx = useContext(StudentContext);
  const [addFile] = useMTSAddFile();
  const [deleteFile] = useDeleteFile();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [passedMtsActivity, setPassedMtsActivity] = useState<DTO.MTSActivity | undefined>(undefined);
  const formRef = useRef<FormikProps<dropzoneFormValues> | null>(null);
  const activity = passedMtsActivity ? passedMtsActivity : props.mtsActivity;

  useImperativeHandle(ref, () => ({
    submitForm: async (a) => {
      setPassedMtsActivity(a);
      if (formRef.current) {
        await formRef.current.submitForm();
      }
    }
  }));

  const selectedActivityType = props.selectedActivityType ? (('expiring' in props.selectedActivityType) ?
    mtsCtx.mtsActivityTypeById((props.selectedActivityType as DTO.UserMTSActivityType).parentActivityType.id) :
    props.selectedActivityType) :
    null;
  if (!selectedActivityType || (selectedActivityType && !selectedActivityType.attachmentFormEnabled)) {
    return null;
  }

  const deleteElement = async (attachmentId: number, deleteReason: string) => {
    await deleteFile({
      variables: {
        attachmentId: attachmentId,
        deleteReason: deleteReason,
        allowDeleteSpecialTypes: true
      },
      refetchQueries: ['mtsEntries']
    });
  };

  return (
    <Grid container>
      {studentCtx && props.mtsActivity && props.mtsActivity.attachments.length > 0 && (
        <Grid item xs={12}>
          <FileTable
            fileList={activity?.attachments ?? []}
            fileowner={studentCtx.student.id}
            deleteElement={((activity != null) && mtsCtx.canChangeSelectedMTS) ? deleteElement : undefined}
            rowShowOverrides={[{ row: FileTableColumn.DESCRIPTION, override: false }, { row: FileTableColumn.FILETYPE, override: false }]}
            skipUploadRow
            overrideTypeDeleteLogic
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardHeader title={<LanguageString groupName="MTS" resourceName="ATTACHMENTS" defaultText="Attachments" />} />
          <CardContent>
            <Formik<dropzoneFormValues>
              initialValues={{
                file: ''
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={formValidation}
              onSubmit={async (values: dropzoneFormValues) => {
                if (studentCtx?.student.id && activity) {
                  addFile({
                    variables: {
                      input: {
                        file: values.file[0],
                        month: month,
                        year: year,
                        periodId: mtsCtx.mtsPeriod?.id,
                        userId: studentCtx.student.id,
                        activityId: activity.id
                      }
                    },
                    refetchQueries: ['mtsEntries']
                  }).catch(res => {
                    const errors: string[] = res.graphQLErrors.map((error: ApolloError) => {
                      return error.message;
                    });

                    if (errors.length > 0) {
                      setErrorMessage(errors[0]);
                    }
                  });
                }
              }}
            >
              {formikProps => {
                formRef.current = formikProps;

                return (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <Dropzone name="file" formEmbed={true}/>
                    {errorMessage && (
                      <Grid container>
                        <Grid item xs>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const MTSAttachmentUpload = React.forwardRef(MTSAttachmentUploadInner);
