import React from 'react';
import { DialogController } from '../../hooks';
import { usePropetyHistory } from '../../hooks/gql/usePropertyHistory';
import { MessageDialog } from '../Common';
import { LanguageString } from '../Common/LanguageString';

export const PropertyHistory: React.FC<{ propertyId: number, userId: number }> = ({propertyId, userId}) => {
  const {data, loading} = usePropetyHistory({variables: {propertyId, userId}});
  const padding = '16px';

  if (loading) return <div>loading</div>;

  return <div>
    {data
      ? <table>
        <thead><tr>
          <th><LanguageString groupName="PROPERTY_HISTORY" resourceName="CHANGED_BY" /></th>
          <th><LanguageString groupName="PROPERTY_HISTORY" resourceName="CHANGED_DATE" /></th>
          <th><LanguageString groupName="PROPERTY_HISTORY" resourceName="VALUE" /></th>
        </tr></thead>
        <tbody>
          {data.propertyHistory.map(d => <tr>
            <td style={{ paddingLeft: padding, paddingRight: padding }}>{d.changedBy?.firstName} {d.changedBy?.lastName}</td>
            <td style={{ paddingLeft: padding, paddingRight: padding }}>{d.createdAt ? new Date(d.createdAt).toLocaleString() : undefined}</td>
            <td style={{ paddingLeft: padding, paddingRight: padding }}>{d.value}</td>
          </tr>)}
        </tbody>
      </table>
      : <div>no results</div>}
  </div>;
};

export const PropertyHistoryDialog: React.FC<{ userId?: number; propertyId?: number, ctrl: DialogController }> = ({userId, propertyId, ctrl}) => {
  return userId && propertyId != null
    ? <MessageDialog title={<LanguageString groupName="PROPERTY_HISTORY" resourceName="DLG_TITLE" />} message={<PropertyHistory userId={userId} propertyId={propertyId} />} confirmMessage={<LanguageString groupName="PROPERTY_HISTORY" resourceName="CLOSE" />} ctrl={ctrl} />
    : null;
};
