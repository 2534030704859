import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SiteContext, StudentContext, UserContext } from '../../contexts';
import UserHelper from '../../helpers/userHelper';
import { useDialogController } from '../../hooks';
import { LanguageString, MessageDialog } from '../Common';
import { DynamicForm } from '../DynamicForm';
import ChangePasswordDialog from './ChangePasswordDialog';
import { useSendAccountSetupEmail } from '../../hooks';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.spacing(2)}px`
  },
  [theme.breakpoints.up('md')]: {
    container: {
      gridTemplateColumns: '1fr 3fr'
    }
  }
}));

export const Profile: React.FC = () => {
  const { site } = useContext(SiteContext);
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const [ sendAccountSetupEmail ] = useSendAccountSetupEmail();
  const msgCtrl = useDialogController(false);
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();
  const isAdvisor = userCtx.user ? UserHelper.isAdvisorOrAdmin(userCtx.user) : false;
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const ownProfile = studentCtx?.student.id === userCtx.user?.id;
  const formId = (isAdvisor && !ownProfile ? site?.advisorProfileForm?.id : site?.studentProfileForm?.id) ?? 0;

  const toggleChangePasswordModal = () => {
    setChangePasswordModalOpen(!changePasswordModalOpen);
  };

  const reRegisterUser = async () => {
    if (studentCtx?.student.id) {
      await sendAccountSetupEmail({ variables: { input: { userId: studentCtx?.student.id } } });
    }
  };

  const handleFormSaving = (saving: boolean) => {
    console.log(`handleFormSaving ${saving}`);
    setIsSaving(saving);
  };

  const handleFormSaved = () => {
    console.log(`handleFormSaved`);
    setIsSaving(false);
    msgCtrl.controller.setOpen();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={<LanguageString groupName="SITE_CONENT" resourceName="PROFILE_INFORMATION" defaultText="Profile" />} />
          <CardContent>
            <DynamicForm
              formId={formId}
              userId={studentCtx?.student.id ?? 0}
              autoloadFormSubmission={true}
              shouldScrollToTopOnSubmission={true}
              autoSave={false}
              submitText="Save"
              showSubmitButton={true}
              renderFormTitle={false}
              disableCache={true}
              onIsSubmittingChange={handleFormSaving}
              onFormSubmitted={handleFormSaved}
            />
          </CardContent>
        </Card>
      </Grid>
      {studentCtx?.student && userCtx.user && ownProfile && (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<LanguageString groupName="SITE_CONENT" resourceName="SECURITY_INFORMATION" defaultText="Security" />} />
            <CardContent>
              <Button variant="contained" color="primary" onClick={toggleChangePasswordModal}>
                <LanguageString groupName="SITE_CONENT" resourceName="CHANGE_PASSWORD" defaultText="Change Password" />
              </Button>
            </CardContent>
          </Card>

          <ChangePasswordDialog open={changePasswordModalOpen} toggle={toggleChangePasswordModal} />
        </Grid>
      )}
      {isAdvisor && studentCtx?.student && studentCtx.student.id !== userCtx.user.id && site?.accountSetupProcess && (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<LanguageString groupName="SITE_CONENT" resourceName="SECURITY_ACCOUNTSETUP" defaultText="Account Setup" />} />
            <CardContent>
              <Button variant="contained" color="primary" onClick={reRegisterUser} style={{ marginLeft: '10px' }}>
                <LanguageString groupName="SITE_CONENT" resourceName="REREGISTER_USER" defaultText="Send Account Setup Email" />
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
      <MessageDialog ctrl={msgCtrl} message={<LanguageString groupName="FORM" resourceName="PROFILE_SAVED" />} />
      <Backdrop className={classes.backdrop} open={isSaving}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};
export default Profile;
