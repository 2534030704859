import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Roadmap } from '../../dto';

type Variables = { userId: number };
type Results = {
  completedRoadmaps: Roadmap[];
};

export const useCompletedRoadmaps = (options: QueryHookOptions<Results, Variables> & { variables: Variables }) => {
  const query = gql`
    query getCompletedRoadmaps($userId: Int!) {
      completedRoadmaps(userId: $userId) {
        id
        user {
          id
          firstName
          lastName
          username
        }
        name {
          text
        }
        reviewedAt
        startedAt
        meeting {
          id
          meetingType
          requestorRole
          requestReason
          date
        }
        goal
        plannedStartDate
        plannedEndDate
        reviewNotes
        advisorReviewNotes
        employmentInfo
      }
    }
  `;

  return useQuery(query, options);
};

export default useCompletedRoadmaps;

