import gql from 'graphql-tag';
import { attachmentFrag } from './attachmentFrag';
import { mtsActivityMinFrag } from './mtsActivityMinFrag';
import { userInfoFrag } from './userInfoFrag';
import { mtsPeriodFrag } from './mtsPeriodFrag';

export const mtsFrag = gql`
  ${attachmentFrag}
  ${userInfoFrag}
  ${mtsActivityMinFrag}
  ${mtsPeriodFrag}

  fragment MTSFrag on MTS {
    id
    state
    month
    year
    totalHours
    totalWprHours
    submitted
    approved
    mtsPeriod {
      ...MTSPeriodFrag
    }
    generalNotes {
      id
      day
      text
    }
    entries {
      totalCount
      entries {
        ...MTSActivityMinFrag
      }
    }
    attachments {
      ...AttachmentFrag
    }
    participantForm {
      id
    }
    advisorForm {
      id
    }
  }
`;

export const mtsCompleteFrag = gql`
  ${mtsPeriodFrag}

  fragment MTSFrag on MTS {
    id
    state
    month
    year
    totalHours
    totalWprHours
    submitted
    approved
    mtsPeriod {
      ...MTSPeriodFrag
    }
  }
`;

