import { useField, useFormikContext } from 'formik';
import React from 'react';
import { LanguageString } from '..';
import { FormQuestion } from '../../dto';
import { useFormQuery } from '../../hooks';
import { FormFieldComp, getDynamicSelectOptions, getQueryParamsFromFormik, getQuestionFormId, isDateQuestion, isNonBlank, IValues, resolveQueryArgTemplate } from './common';

// TODO: text should be nullable on FormQuestion so we can eliminate checks for blank strings
function hasText(question: FormQuestion) {
  return isNonBlank(question.text);
}

const FormDynamicInformationValue: FormFieldComp = ({ question }) => {
  const formik = useFormikContext<IValues>();
  const fieldId = getQuestionFormId(question);
  const [field] = useField<number>(fieldId);
  const qparams = getQueryParamsFromFormik(formik, question);
  const od = question.optionDefinition;
  const queryName = od?.query ?? 'useAdvisors';
  const { loading, data, error } = useFormQuery(queryName, qparams, od?.queryArgs != null ? JSON.parse(resolveQueryArgTemplate(od.queryArgs, formik.status)) : undefined);

  if (question.optionDefinition == null) throw new Error('No Options');

  if (loading) return <p>'Loading...'</p>;
  else if (error) return <p>'Error'</p>;
  else if (data) {
    const options = getDynamicSelectOptions(question, data);
    const selection = options && options.find(opt => opt.value === field.value);
    if (selection != null) {
      return <p>{selection.label}</p>;
    }
  }

  return <p>No Data</p>;
};

const FormInformationValue: FormFieldComp = ({ question }) => {
  const fieldId = getQuestionFormId(question);
  const [field] = useField<Date | string>(fieldId);
  const { value } = field;
  if (value != null) {
    return question.optionDefinition != null ? (
      <FormDynamicInformationValue question={question} />
    ) : typeof value === 'string' ? (
      question.displayAnswerAsHTML
        ? <LanguageString languageString={{ id: -1, text: value, isHtml: true }} />
        : <div>{value}</div>
    ) : isDateQuestion(question) ? (
      <div>{value.toLocaleDateString()}</div>
    ) : (
      <p>{JSON.stringify(value)}</p>
    );
  }

  return null;
};

export const FormInformation: FormFieldComp = ({ question }) => {
  const fieldId = getQuestionFormId(question);
  const [field] = useField<IValues>(fieldId);
  const { value } = field;
  const label = hasText(question) ? (
    <div id={String(question.id)} className="title">
      <LanguageString languageString={question.text} />
    </div>
  ) : null;

  return label != null || value != null ? (
    <>
      {label}
      <FormInformationValue question={question} />
    </>
  ) : null;
};
