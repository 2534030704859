import React from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../../helpers/auth';
import { useLogout } from '../../hooks/gql/useLogout';

export const Logout: React.FC = () => {
  const [logout, { loading, called, client }] = useLogout();

  console.log(`Logout loading ${loading} called ${called}`);

  if (!loading && !called) {
    void logout({
      update: async () => {
        Auth.logout();
        await client?.cache.reset();
        const test = await client?.resetStore();
        console.log(`test is ${test ? 'not null' : 'null'}`);
      }
    });
  }

  return loading ? <div>Loading...</div> : <Redirect to="/" />;
};

export default Logout;
