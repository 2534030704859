import { Button, Dialog, DialogActions, DialogContent, Grid, Slide, TextField, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApolloError } from 'apollo-client';
import { format } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { ExtApprovalStatus } from 'tuapath-server';
import { TypedReflect } from '../../../common';
import { SiteContext, StudentContext, useInsights } from '../../../contexts';
import * as DTO from '../../../dto';
import { getTypesNeedingApproval } from '../../../helpers/mtsHelpers';
import { DialogController, useDialogController, useGetMTSEntryById, useSaveMTS } from '../../../hooks';
import { getTRPCClient } from '../../../server/getTRPCClient';
import { getApprovalStatus } from '../../../server/useApprovalStatus';
import { DialogTitle, GeneralErrorMessage, LanguageString } from '../../Common';
import { DynamicForm } from '../../DynamicForm';

interface MTSSubmitDialogProps {
  open: boolean;
  toggle: () => void;
  mts?: DTO.MTS;
  typesNeedingApproval: ReturnType<typeof getTypesNeedingApproval>;
}

const DialogTransition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ReqExtApprovalDlgProps = { mts: DTO.MTS; activity: DTO.MTSActivity; onClose: () => void; onSubmit?: () => void; props: DialogController['props']; };
export const ReqExtApprovalDlg: React.FC<ReqExtApprovalDlgProps> = ({mts, activity, props, onClose: handleClose, onSubmit }) => {
  const { type: {id: activityTypeId, reqExternalApprovalFormId}, userType } = activity;
  const { id: userActivityTypeId } = (userType ?? { id: undefined });
  const handleSubmit = (input: DTO.SubmitFormInput) => {
    const client = getTRPCClient();
    void client.mutation('requestActivityApproval', { mtsId: mts.id, activityTypeId, userActivityTypeId, formInput: input }).then(val => onSubmit?.());
    handleClose();
  };

  return (
    <Dialog {...props}>
      <DialogTitle onClose={handleClose}>Request Approval</DialogTitle>
      <DialogContent>
        <DynamicForm autoloadFormSubmission={false} formId={reqExternalApprovalFormId} overrideSubmitHandler={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

interface ApprovalItemProps {
  mts: DTO.MTS, activity: DTO.MTSActivity, onSubmit?: () => void, requested: ExtApprovalStatus
}

const ExtApprovalItemWrapper: React.FC<ApprovalItemProps> = (props) => {
  console.log(`activity id: `, props.activity.id);
  const { data: fullEntries, loading: fullEntriesLoading } = useGetMTSEntryById({
    variables: {
      entryIds: [props.activity.id]
    }
  });

  const activity = useMemo(() => {
    if (fullEntries?.mtsEntryById && fullEntries.mtsEntryById.length > 0) {
      return fullEntries.mtsEntryById[0];
    }

    return undefined;
  }, [fullEntries, fullEntriesLoading]);

  if (activity) {
    return <ExtApprovalItem {...props} activity={activity} />;
  }

  return null;
};

const ExtApprovalItem: React.FC<ApprovalItemProps> = ({mts, activity, onSubmit, requested}) => {
  const { controller, props } = useDialogController(false);

  return !activity
    ? <div>Loading...</div>
    : (
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'row', alignItems: 'center'}}>
        <LanguageString languageString={activity.userType?.name ?? activity.type.name} />
        <Button disabled={requested === 'approved'} onClick={controller.setOpen}>
          {requested === 'requested'
            ? <LanguageString groupName="EXT_MTS_APP" resourceName="RESEND" />
            : requested === 'approved'
              ? <LanguageString groupName="EXT_MTS_APP" resourceName="SUBMITTED" />
              : activity.type.externalApprovalRequired
                ? <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_REQUESTED" />
                : <LanguageString groupName="EXT_MTS_APP" resourceName="NOT_REQUESTED_OPT" />
          }
        </Button>
        <ReqExtApprovalDlg mts={mts} activity={activity} onClose={controller.setClose} props={props} onSubmit={onSubmit} />
      </div>
    );
};

export type ApprovalState = { [activityTypeId: number]: { [userTypeId: number]: { state: ExtApprovalStatus, required: boolean } } };

export const MTSSubmitDialog: React.FC<MTSSubmitDialogProps> = props => {
  const { mts, typesNeedingApproval } = props;
  const insights = useInsights();
  let dynamicFormRef: DynamicForm | null = null;
  const studentCtx = useContext(StudentContext);
  const siteCtx = useContext(SiteContext);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [saveMTS] = useSaveMTS();
  const [hasUnrequestedApprovals, setHasUnrequestedApprovals] = useState(true);
  // const apStatRef = useRef({} as { [typeId: number]: boolean });
  const [newApprovalsRequested, setNewApprovalsRequested] = useState(0);
  const [approvalsRequested, setApprovalsRequested] = useState({} as ApprovalState);

  if (mts == null) return <div>Error</div>;

  const handleReqApprovalSubmit = () => setNewApprovalsRequested(newApprovalsRequested + 1);

  if (typesNeedingApproval != null) {
    void (async () => {
      const localAppsRequested: ApprovalState = {};
      const localHasUnrequestedApprovals = !(await Promise.all(
        TypedReflect.ownKeys(typesNeedingApproval).map(typeId => TypedReflect.ownKeys(typesNeedingApproval[typeId])
          .map(async uTypeId => {
            if (localAppsRequested[typeId] == null) localAppsRequested[typeId] = {};
            return localAppsRequested[typeId][uTypeId] = {
              state: (await getApprovalStatus(mts.id, typeId, Number(uTypeId) === 0 ? undefined : uTypeId)) ?? 'not-requested', required: typesNeedingApproval[typeId][uTypeId][0].type.externalApprovalRequired ?? false
            };
          })).flat())).reduce((pv, cv) => pv && cv.state != 'not-requested', true);

      if (localHasUnrequestedApprovals !== hasUnrequestedApprovals) setHasUnrequestedApprovals(localHasUnrequestedApprovals);
      if (TypedReflect.ownKeys(typesNeedingApproval).some(prop =>
        approvalsRequested[prop] == null || TypedReflect.ownKeys(localAppsRequested[prop]).some(uTypeId => approvalsRequested[prop][uTypeId].state !== localAppsRequested[prop][uTypeId].state)
      )) setApprovalsRequested(localAppsRequested);
    })();
  }

  const handleSubmit = () => {
    if (dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }
  };

  const dynamicFormSubmit = (data?: { submitForm: DTO.SubmitFormResult }, error?: ApolloError) => {
    if (data && data.submitForm && data.submitForm.formSubmission) {
      const submission = data.submitForm.formSubmission;

      if (submission && mts.state === DTO.MTSState.NOT_SUBMITTED) {
        let pin: string | undefined = undefined;

        if (submission.answers) {
          for (const answer of submission.answers) {
            // TODO: This or verifyPIN should be removed when the backend is fixed for STUDENT_PIN
            if (answer.question.name === 'verifyPIN') {
              pin = answer.answer;
              break;
            }
          }
        }

        if (pin) {
          saveMTS({
            variables: {
              mtsId: mts.id,
              formSubmissionId: submission.id,
              pin: pin
            },
            update: (cache, result) => {
              if (result.data?.mtsSubmit) {
                if (props.open) {
                  props.toggle();
                }
              } else if (errorMessage !== 'An unknown error occured') {
                setErrorMessage('An unknown error occured');
              }
            },
            refetchQueries: ['mtsEntries']
          }).catch(res => {
            const errors: string[] = res.graphQLErrors.map((e: ApolloError) => {
              return e.message;
            });

            if (errors.length > 0 && errorMessage !== errors[0]) {
              setErrorMessage(errors[0]);
            }

            insights.trackException(`${MTSSubmitDialog.name} - Failed to submit MTS with errors: ${JSON.stringify(errors)}`, SeverityLevel.Error);
          });
        } else {
          insights.trackEvent(`${MTSSubmitDialog.name} - Failed to submit MTS because the PIN was null`);
        }
      } else {
        insights.trackEvent(`${MTSSubmitDialog.name} - Failed to submit MTS because MTS was already submitted: ${mts.id}`);
      }
    } else {
      insights.trackEvent(`${MTSSubmitDialog.name} - Failed to submit MTS because values were null`);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xl" scroll="paper" open={props.open} onClose={props.toggle} TransitionComponent={DialogTransition}>
      <DialogTitle onClose={props.toggle}>
        <LanguageString groupName="MTS" resourceName="MTS_SUBMIT" defaultText="MTS Submit" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {errorMessage && (
            <Grid item xs>
              <GeneralErrorMessage message={errorMessage} />
            </Grid>
          )}
          {typesNeedingApproval && <Grid item xs={12}>
            <Typography><LanguageString groupName="EXT_MTS_APP" resourceName="TYPES_NEEDING_APPROVAL" /></Typography>
            {TypedReflect.ownKeys(typesNeedingApproval).map(prop => TypedReflect.ownKeys(typesNeedingApproval[prop]).filter(uTypeId => typesNeedingApproval[prop][uTypeId].length > 0).map(uTypeId =>
              <ExtApprovalItemWrapper key={prop} mts={mts} activity={typesNeedingApproval[prop][uTypeId][0]} onSubmit={handleReqApprovalSubmit} requested={approvalsRequested[prop]?.[uTypeId].state} />)).flat()
            }
          </Grid>}
          <Grid item xs={12}>
            <DynamicForm
              ref={f => (dynamicFormRef = f)}
              formId={siteCtx.site?.mtsStudentForm?.id ?? 0}
              userId={studentCtx?.student.id ?? 0}
              autoSave={false}
              showSubmitButton={false}
              autoloadFormSubmission={false}
              onIsSubmittingChange={setSubmitButtonDisabled}
              formSubmissionId={mts.participantForm ? mts.participantForm.id : undefined}
              onFormSubmitted={dynamicFormSubmit}
              renderFormTitle={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField disabled fullWidth value={format(new Date(), 'MM/dd/yyyy')} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitButtonDisabled ||
          Object.getOwnPropertyNames(approvalsRequested).some(
            ar => {
              const aro = approvalsRequested[Number(ar)];
              return Object.getOwnPropertyNames(aro).some(
                sar => {
                  const saro = aro[Number(sar)];
                  return saro.state === 'not-requested' && saro.required === true;
                }
              );
            })
          } onClick={handleSubmit} color="primary" variant="contained">
          <LanguageString groupName="MTS" resourceName="SUBMIT_MTS" defaultText="Submit MTS" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MTSSubmitDialog;
