import React, { useState, useContext, useMemo } from 'react';
import {
  Grid,
  Typography,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { MTSContext, UserContext } from '../../../contexts';
import { MTSState } from 'tuapath-common/generated/schema';
import { useClasses, useMtsUnapprove, useMtsRevise } from '../../../hooks';
import { LanguageString } from '../../Common';
import MTSHelpers from '../../../helpers/mtsHelpers';
import UserHelper from '../../../helpers/userHelper';
import { startOfMonth } from 'date-fns';

export const CalendarMonthSubmissionStatus: React.FC = () => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);
  const userCtx = useContext(UserContext);
  const [unapprove] = useMtsUnapprove();
  const [revise] = useMtsRevise();
  const [unapproveDialogOpen, setUnapproveDialogOpen] = useState(false);
  const [unsubmitDialogOpen, setUnsubmitDialogOpen] = useState(false);

  const mtsState = useMemo(() => {
    let monthStart = startOfMonth(mtsCtx.date);
    let mts = MTSHelpers.mtsForDate(monthStart, mtsCtx.mtses);

    if (mtsCtx.mtsPeriod && mtsCtx.startDate) {
      monthStart = mtsCtx.startDate;
      mts = mtsCtx.mtses.length > 0 ? mtsCtx.mtses[0] : undefined;
    }

    if (mts) {
      return mts.state;
    }

    return undefined;
  }, [mtsCtx.mtsPeriod, mtsCtx.startDate, mtsCtx.date]);

  const toggleUnApproveDialog = () => {
    setUnapproveDialogOpen(!unapproveDialogOpen);
  };

  const toggleUnSubmitDialog = () => {
    setUnsubmitDialogOpen(!unsubmitDialogOpen);
  };

  const handleUnsubmit = async () => {
    toggleUnSubmitDialog();

    if (mtsCtx.selectedMTS) {
      await revise({
        variables: {
          id: mtsCtx.selectedMTS.id
        }
      });
    }
  };

  const handleUnApprove = async () => {
    toggleUnApproveDialog();

    if (mtsCtx.selectedMTS) {
      await unapprove({
        variables: {
          id: mtsCtx.selectedMTS.id
        }
      });
    }
  };

  return (
    <>
      {mtsState === MTSState.SUBMITTED && (
        <Grid container justify="space-between" className={classes.mtsStateBar}>
          <Grid item />
          <Grid item>
            <Grid container justify="center" alignItems="center" direction="row" spacing={2}>
              <Grid item>
                <Icon>swap_horizontal_circle</Icon>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  <LanguageString groupName="MTS" resourceName="STATE_SUBMITTED" defaultText="Submitted" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {userCtx.user && UserHelper.isAdvisorOrAdmin(userCtx.user) && (
              <Button
                color="primary"
                onClick={() => {
                  toggleUnSubmitDialog();
                }}
              >
                <LanguageString groupName="MTS" resourceName="UNSUBMIT" defaultText="UNSUBMIT" />
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {mtsState === MTSState.APPROVED && (
        <Grid container justify="space-between" className={classes.mtsStateBar}>
          <Grid item />
          <Grid item>
            <Grid container justify="center" alignItems="center" direction="row" spacing={2}>
              <Grid item>
                <Icon>check_circle</Icon>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  <LanguageString groupName="MTS" resourceName="STATE_APPROVED" defaultText="Approved" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {userCtx.user && UserHelper.isAdvisorOrAdmin(userCtx.user) && (
              <Button
                color="primary"
                onClick={() => {
                  toggleUnApproveDialog();
                }}
              >
                <LanguageString groupName="MTS" resourceName="UNAPPROVE" defaultText="UNAPPROVE" />
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      <Dialog open={unapproveDialogOpen}>
        <DialogContent>
          <LanguageString groupName="MTS" resourceName="MTS_UNAPPROVE_MESSAGE" defaultText="Are you sure you would like to unapprove this MTS?" />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleUnApproveDialog}>
            <LanguageString groupName="GENERAL" resourceName="NO" defaultText="No" />
          </Button>
          <Button color="primary" onClick={handleUnApprove}>
            <LanguageString groupName="GENERAL" resourceName="YES" defaultText="Yes" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={unsubmitDialogOpen}>
        <DialogContent>
          <LanguageString groupName="MTS" resourceName="MTS_UNSUBMIT_MESSAGE" defaultText="Are you sure you would like to unsubmit this MTS?" />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleUnSubmitDialog}>
            <LanguageString groupName="GENERAL" resourceName="NO" defaultText="No" />
          </Button>
          <Button color="primary" onClick={handleUnsubmit}>
            <LanguageString groupName="GENERAL" resourceName="YES" defaultText="Yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
