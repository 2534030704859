import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Paper
} from '@material-ui/core';
import {
  format,
  addDays
} from 'date-fns';
import {
  GeneralErrorMessage
} from '../../Common';
import CalendarMonthViewCell from '../CalendarMonthView/CalendarMonthViewCell';
import { useClasses } from '../../../hooks';
import { MTSContext, ChildRoutes } from '../../../contexts';
import clsx from 'clsx';
import { MTSToolBar } from '../MTSToolBar';
import { MTSFooter } from '../MTSFooter';

export const CalendarWeekView: React.FC = (props) => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  if (mtsCtx.error) {
    return <GeneralErrorMessage message={mtsCtx.error.message} />;
  }

  const weekdays = [];

  for (let i = 0; i <= 6; i++) {
    weekdays.push(format(addDays(mtsCtx.startOfWeek, i), 'E'));
  }

  const daysOfWeek = [];
  for (let i = 0; i <= 6; i++) {
    const date = addDays(mtsCtx.startOfWeek, i);
    daysOfWeek.push(
      <Grid item key={`day_of_week_cell_${i}`} xs>
        <CalendarMonthViewCell
          monthDate={mtsCtx.date}
          date={date}
        />
      </Grid>
    );
  }

  return (
    <Paper>
      <MTSToolBar />

      <Grid container direction="row" className={classes.mtsCalendarWeek}>
        <Grid item xs={12}>
          <Grid className={clsx([classes.mtsDayHeaderHeight, classes.mtsMonthDayHeader])} container direction="row" justify="space-evenly">
            {mtsCtx.loading && (
              <CircularProgress color="primary" />
            )}

            {!mtsCtx.loading && (
              <>
                {weekdays.map(day => (
                  <Grid item key={`week_view_day_${day}`} xs>
                    <Typography variant="h6">{day}</Typography>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        <Grid className={classes.mtsMonthRow} container direction="row" justify="space-evenly">
          {daysOfWeek}
        </Grid>
      </Grid>

      <MTSFooter />

      <ChildRoutes />
    </Paper>
  );
};

export default CalendarWeekView;
