import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from '../../dto';
import { assignmentResultsFrag, attachmentFrag } from './fragments';

type Variables = { userId?: number | null };
type Results = { user?: User };

export function useUser(options: QueryHookOptions<Results, Variables> & { variables: Variables }) {
  const {
    variables: { userId }
  } = options;

  const query = gql`
    ${assignmentResultsFrag}
    ${attachmentFrag}

    query getUser($userId: Int!, $withUser: Boolean!) {
      user(id: $userId) @include(if: $withUser) {
        id
        username
        firstName
        lastName
        phoneNumber
        telemetryId
        emails {
          id
          email
          primary
        }
        advisor {
          id
          username
          firstName
          lastName
          email
          phoneNumber
        }
        customProperties {
          id
          type {
            id
            name
          }
          value
        }
        files{
          ...AttachmentFrag
        }
        assignments {
          ...AssignmentResultsFrag
        }
        unreadNotificationCount
        programIds
      }
    }
  `;

  return useQuery<Results>(query, { ...options, ...{ variables: { userId, withUser: userId != null } } });
}
