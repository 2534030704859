import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  Chip,
  Tooltip
} from '@material-ui/core';
import { LanguageString, DateTimeField, Switch } from '../../components/Common';
import { useField, useFormikContext } from 'formik';
import { NotificationForm, sendViaOptions } from './NotificationTypes';
import { addHours, format } from 'date-fns';

interface SendDialogProps {
  open: boolean;
  toggle: () => void;
  send: (v: NotificationForm) => void;
  data: NotificationForm;
  studentCount: number | undefined;
}

export const SendDialog: React.FC<SendDialogProps> = ({ open, toggle, send, data, studentCount }) => {
  const [sendNowField] = useField('sendNow');
  const formikContext = useFormikContext<NotificationForm>();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={toggle} aria-labelledby="Error Dialog">
      <DialogTitle>
        <LanguageString groupName="GENERAL" resourceName="SEND_CONFIRMATION" defaultText="Send Notification Confirmation" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignContent="center">
              <Typography>
                <LanguageString groupName="GENERAL" resourceName="SEND_NOW" defaultText="Send Now" />
              </Typography>
              <Switch item name="sendNow" />
            </Box>
          </Grid>
          {!sendNowField.value && (
            <DateTimeField
              item
              xs={12}
              name="sendDate"
              label={<LanguageString groupName="GENERAL" resourceName="NOTIFICATION_DATE" defaultText="Notification Date" />}
              minutesStep={15}
              onChange={(date: Date | null) => {
                formikContext.setFieldValue('sendDate', date);
                formikContext.setFieldValue('expiration', addHours(date ?? new Date(), 24));
              }}
            />
          )}
          <Grid item xs={12}>
            <Tooltip title="This is used to cancel sending a notification to user if it fails to send to them within the specified amount of time">
              <DateTimeField item xs={12} name="expiration" label="Notification Expiration" />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_DATE" defaultText="Notification Date" />
                    </Typography>
                    <Typography variant="caption">
                      {format(formikContext.values.sendDate ?? new Date(), 'MM/dd/yy h:mm a')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="SUBJECT" defaultText="Subject" />
                    </Typography>
                    <Typography variant="caption">
                      {formikContext.values.subject}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="SEND_TO" defaultText="Send To" />
                    </Typography>
                    <Typography variant="caption">
                      {/* {formikContext.values.selectedStudents.map(u => `${u.firstName} ${u.lastName.charAt(0)}`).join(', ')} */}
                      <Grid container spacing={1}>
                      {!formikContext.values.allStudents && (formikContext.values.selectedStudents.length < 10) ?
                                formikContext.values.selectedStudents.map(s => (
                                  <Grid item>
                                    <Chip
                                      label={`${s.firstName} ${s.lastName.charAt(0)}`}
                                    />
                                  </Grid>
                                ))
                                :<Grid item>
                                  <Chip
                                    label={`Sending to ${formikContext.values.allStudents && studentCount ? studentCount - formikContext.values.selectedStudents.length : formikContext.values.selectedStudents.length} participants`}
                                  />
                                </Grid>
                              }
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="SENDVIA" defaultText="Send Via" />
                    </Typography>
                    <Typography variant="caption">
                      {sendViaOptions[formikContext.values.sendVia]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <LanguageString groupName="GENERAL" resourceName="MESSAGE" defaultText="Message" />
                    </Typography>
                    <Typography variant="caption">
                      {formikContext.values.body}
                    </Typography>
                  </Grid>
                  {formikContext.values.expiration && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_EXPIRATION" defaultText="Notification Expiration" />
                      </Typography>
                      <Typography variant="caption">
                        {format(formikContext.values.expiration ?? new Date(), 'MM/dd/yy h:mm a')}
                      </Typography>
                    </Grid>
                  )}
                  {formikContext.values.attachments?.length && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        <LanguageString groupName="GENERAL" resourceName="ATTACHMENTS" defaultText="Attachments" />
                      </Typography>
                      <Typography variant="caption">
                        {formikContext.values.attachments.length}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>
          <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            send(data);
          }}
        >
          <LanguageString groupName="GENERAL" resourceName="CONFIRM_SEND" defaultText="Confirm & Send" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SendDialog;
