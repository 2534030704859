import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { MTSActivityType } from '../../dto';
import { ReqVars } from './common';
import { mtsActivityTypeFrag } from './fragments';

type Variables = { includeDisabled: boolean; includeExpiredGeneratedTypes: boolean; userId: number | undefined };
type Results = { mtsUserActivityTypes: MTSActivityType[] };
const query = gql`
  ${mtsActivityTypeFrag}

  query mtsUserActivityType($includeDisabled: Boolean, $userId: Int) {
    mtsUserActivityTypes(includeDisabled: $includeDisabled, userId: $userId) {
      ...MTSActivityTypeFrag
    }
  }
`;

export const useMTSUserActivityTypes = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);

export default useMTSUserActivityTypes;
