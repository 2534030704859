import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import { LanguageString, LanguageStringEditor, TextField, Switch } from '../../Common';
import { Form, Formik, FormikHelpers, FormikProps, useField } from 'formik';
import * as DTO from 'tuapath-common/generated/schema';
import * as Yup from 'yup';
import { useSaveNotificationTemplate } from '../../../hooks';
import { useDeleteNotificationTemplate } from '../../../hooks/gql/useDeleteNotificationTemplate';

interface NotificationTemplateEditorProps {
  open: boolean;
  toggle: () => void;
  template?: DTO.NotificationTemplate;
}

interface FormType {
  name: string;
  replyTo: string;
  title?: DTO.LanguageString;
  message?: DTO.LanguageString;
  messagePlainText?: DTO.LanguageString;
  email: boolean;
  sms: boolean;
  notificationUi: boolean;
  createRoc: boolean;
  blastEmail: boolean;
  blastSMS: boolean;
}

const schema = () =>
  Yup.object().shape({

  });

const LSField: React.FC<{ name: string, title: string }> = ({ name, title }) => {
    const [resourceName, setResouceName] = useState('');
    const [{ value }] = useField(name);
    const groupName = useMemo(() => {
      return (value as DTO.LanguageString)?.group?.groupName ?? `NOTIFICATION_TEMPLATE_${name}`;
    }, []);

    useEffect(() => {
      const rsName = (value as DTO.LanguageString)?.group?.resourceName;
      setResouceName(rsName ?? `${(Math.random() + 1).toString(36).substring(7)}_${name}`);
    }, [value]);

    return (
      <LanguageStringEditor
        name={name}
        title={title}
        groupName={groupName}
        resourceName={resourceName}
        shouldWarnOnDuplicateString={false}
      />
    );
  };

export const NotificationTemplateEditor: React.FC<NotificationTemplateEditorProps> = ({ open, toggle, template }) => {
  const formik = useRef<FormikProps<FormType> | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [saveNotificationTemplate] = useSaveNotificationTemplate({ refetchQueries: ['notificationTemplates'] });
  const [deleteNotificationTemplate] = useDeleteNotificationTemplate({ refetchQueries: ['notificationTemplates'] });

  const initialValues: FormType = {
    name: '',
    replyTo: '',
    title: undefined,
    message: undefined,
    messagePlainText: undefined,
    email: false,
    sms: false,
    notificationUi: false,
    createRoc: false,
    blastEmail: false,
    blastSMS: false
  };

  const initialStatus = {
    errors: [] as string[]
  };

  useEffect(() => {
    formik.current?.setFieldValue('name', template?.name ?? '');
    formik.current?.setFieldValue('replyTo', template?.replyTo ?? '');
    formik.current?.setFieldValue('email', template?.shouldEmail ?? false);
    formik.current?.setFieldValue('sms', template?.shouldSMS ?? false);
    formik.current?.setFieldValue('notificationUi', template?.showInUI ?? false);
    formik.current?.setFieldValue('createRoc', template?.shouldCreateROC ?? false);
    formik.current?.setFieldValue('message', template?.message);
    formik.current?.setFieldValue('messagePlainText', template?.messagePlainText);
    formik.current?.setFieldValue('title', template?.title);
    formik.current?.setFieldValue('blastEmail', template?.blastEmail ?? false);
    formik.current?.setFieldValue('blastSMS', template?.blastSMS ?? false);
  }, [template, open]);

  const _toggle = () => {
    formik.current?.resetForm();
    toggle();
  };

  const toggleDelete = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleSubmit = async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    if (values.title?.group?.id) {
      await saveNotificationTemplate({
        variables: {
          input: {
            id: template?.id,
            name: values.name,
            replyTo: values.replyTo,
            messageId: values.message?.group?.id,
            messagePlainTextId: values.messagePlainText?.group?.id,
            titleId: values.title.group.id,
            shouldEmail: values.email,
            shouldSMS: values.sms,
            showInUI: values.notificationUi,
            shouldCreateROC: values.createRoc,
            blastEmail: values.blastEmail,
            blastSMS: values.blastSMS
          }
        },
        update: (c, r) => {
          _toggle();
        }
      });
    } else {
      console.log(`failed to save the notification template because the titleId column was null`);
    }
  };

  const deleteTemplate = async () => {
    toggleDelete();

    if (template) {
      await deleteNotificationTemplate({
        variables: { id: template.id },
        update: (c, r) => {
          _toggle();
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize={false}
    >
      {formikVar => {
        formik.current = formikVar;

        return (
          <>
            <Dialog fullWidth maxWidth="md" open={open} onClose={_toggle}>
              <DialogTitle>
                {template?.name ?
                  <LanguageString groupName="GENERAL" resourceName="EDIT_NOTIFICATION_TEMPLATE" defaultText="Edit Template" /> :
                  <LanguageString groupName="GENERAL" resourceName="CREATE_NOTIFICATION_TEMPLATE" defaultText="Create Template" />
                }
              </DialogTitle>
              <DialogContent dividers>
                <Form>
                  <Grid container spacing={2}>
                    <TextField
                      item
                      xs={12}
                      name="name"
                      label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_NAME" defaultText="Name" />}
                      rows={1}
                    />
                    <TextField
                      item
                      xs={12}
                      name="replyTo"
                      hint="Leave blank to default to the user's advisor"
                      label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_REPLY_TO" defaultText="Reply To" />}
                      rows={1}
                    />

                    <Grid item xs={12}>
                      <LSField title="Title" name="title" />
                    </Grid>
                    <Grid item xs={12}>
                      <LSField title="Message" name="message" />
                    </Grid>
                    <Grid item xs={12}>
                      <LSField title="Plain Message" name="messagePlainText" />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Switch item name="email" label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_SEND_EMAIL" defaultText="Send Email" />} />
                        </Grid>
                        <Grid item xs={12}>
                          <Switch item name="sms" label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_SEND_SMS" defaultText="Send SMS" />} />
                        </Grid>
                        <Grid item xs={12}>
                          <Switch item name="blastEmail" label = {<LanguageString groupName="GENERAL" resourceName="TEMPLATE_BLAST_EMAIL" defaultText="Blast Email ROC Type" />} />
                        </Grid>
                        <Grid item xs={12}>
                          <Switch item name="blastSMS" label = {<LanguageString groupName="GENERAL" resourceName="TEMPLATE_BLAST_SMS" defaultText="Blast SMS ROC Type" />} />
                        </Grid>
                        <Grid item xs={12}>
                          <Switch item name="notificationUi" label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_SHOW_NOTIFICATION" defaultText="Show Notification in UI" />} />
                        </Grid>
                        <Grid item xs={12}>
                          <Switch item name="createRoc" label={<LanguageString groupName="GENERAL" resourceName="TEMPLATE_SEND_EMAIL" defaultText="Create ROC" />} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={_toggle}>
                  <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
                </Button>
                {template && (
                  <Button color="secondary" onClick={toggleDelete}>
                    <LanguageString groupName="GENERAL" resourceName="DELETE" defaultText="Delete" />
                  </Button>
                )}
                <Button color="primary" onClick={formikVar.submitForm}>
                  <LanguageString groupName="GENERAL" resourceName="SAVE" defaultText="Save" />
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="sm" open={deleteDialog} onClose={toggleDelete}>
              <DialogTitle>Delete Template</DialogTitle>
              <DialogContent>
                <Typography>Are you sure you want to delete this template?</Typography>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={toggleDelete}>
                  <LanguageString groupName="GENERAL" resourceName="NO" defaultText="No" />
                </Button>
                <Button color="primary" onClick={deleteTemplate}>
                  <LanguageString groupName="GENERAL" resourceName="YES" defaultText="Yes" />
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};
export default NotificationTemplateEditor;
