import { gql } from '@apollo/client';

export const assignmentFrag = gql`
  fragment AssignmentFrag on Assignment {
    id
    isComplete
    startedAt
    completedAt
    percentComplete
    durationWatched
    milestoneId
    taskId
    needsApproval
    roadmapMilestoneId
  }
`;

export default assignmentFrag;
