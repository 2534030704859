import { Card, CardContent, CardHeader, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { add, format, getDaysInMonth } from 'date-fns';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as DTO from '../../../dto';
import { approveMTS, ExternalMTS, useExternalMTS } from '../../../server';
import { LanguageString } from '../../Common';
import { DynamicForm, DynamicFormProps } from '../../DynamicForm';
import { questionsIterator } from '../../DynamicForm/common';

const offsetToLocalTZ = (date: Date) => add(date, { minutes: date.getTimezoneOffset() });
const localLongFormat = (date: Date) => format(offsetToLocalTZ(date), 'MMM dd yyyy');
const localShortFormat = (date: Date) => format(offsetToLocalTZ(date), 'MM/dd');

type Unpacked<T> = Exclude<T, undefined> extends Array<infer A> ? A : Exclude<T, undefined>;
type ExternalAnswer = Unpacked<Unpacked<Exclude<ExternalMTS, undefined>['entries']['entries']>['formSubmission']['answers']>;
type ExternalQuestion = Unpacked<Unpacked<Unpacked<Exclude<ExternalMTS, undefined>['activityTypes']>['form']['elements']>['questions']>;

const answerText = (answer: ExternalAnswer | undefined, question: ExternalQuestion) => {
  return answer?.answer ?? question.options?.find(op => op.id === String(answer?.selections?.[0].id))?.text;
};

const getMtsPeriod = (mts: ExternalMTS) => mts
  ? mts.mtsPeriod
    ? { startDate: mts.mtsPeriod.startDate, endDate: mts.mtsPeriod.endDate }
    : { startDate: new Date(mts.year, mts.month - 1, 1, 0, 0, 0), endDate: new Date(mts.year, mts.month - 1, getDaysInMonth(mts.month - 1), 0, 0, 0) }
  : undefined;

const useStyles = makeStyles(theme => ({
  container: {
    margin: `${theme.spacing(2)}px`,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.spacing(2)}px`
  }
}));

const MTSTable: React.FC<{ mts: Exclude<ExternalMTS, undefined> }> = ({ mts }) => {
  const questions = mts.activityTypes[0].form.elements ? [...questionsIterator(mts.activityTypes[0].form.elements)] : [];

  return (
    <div className="ex-mts-table">
      <table>
        <thead>
        <tr>
          <th>Date</th>
            {questions.map(q =>  <th key={q.id}><LanguageString languageString={q.text} /></th>)}
          <th>Hours</th>
        </tr>
        </thead>
        <tbody>
          {mts.entries.entries.map(activity => (
            <tr key={activity.id}>
              <td>{localShortFormat(activity.date)}</td>
              {questions.map(q => <td key={q.id}>{answerText(activity.formSubmission.answers.find(a => a.question.id === q.id), q) ?? 'No answer'}</td>)}
              <td>{activity.hours}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={questions.length + 1}>Total:</th><th>{mts.entries.entries.reduce((pv, cv) => pv + cv.hours, 0)}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export type RtnMTS = Exclude<Exclude<ReturnType<typeof useExternalMTS>, undefined>['mts'], undefined>;

type EMAProps = {
  mts: RtnMTS;
  approvalSubmission: RtnMTS['entries']['entries'][0]['externalApprovalSubmission'];
  dynFormProps: DynamicFormProps;
};
export const EMA: React.FC<EMAProps> = ({mts, approvalSubmission, dynFormProps}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader title={`Activity for ${mts.activityTypes[0].name.text}`} />
        <CardContent>
        {(() => {
          const period = getMtsPeriod(mts);
          return period && <Typography>{localLongFormat(period.startDate)} - {localLongFormat(period.endDate)}</Typography>;
        })()}
        <MTSTable mts={mts} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={approvalSubmission
          ? <LanguageString groupName="EXT_MTS_APP" resourceName="ALREADY_SUBMITTED" />
          : <LanguageString groupName="EXT_MTS_APP" resourceName="APPROVAL_FORM" />} />
        <CardContent>
          {approvalSubmission && `Approved on ${approvalSubmission.completedAt ?? 'No date'}`}
          <DynamicForm {...dynFormProps} />
        </CardContent>
      </Card>
    </div>
  );
};

export const ExternalMTSApproval: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { mts, loading } = useExternalMTS(token);
  const [approvalSubmission, setApprovalSubmission] = useState<Exclude<ExternalMTS, undefined>['entries']['entries'][0]['externalApprovalSubmission']>();

  const onSubmit = (formInput: DTO.SubmitFormInput) => {
    void approveMTS(token, formInput).then(rtn => {
      if (rtn != null && mts != null) {
        mts.entries.entries.forEach(e => e.externalApprovalSubmission = rtn);
        setApprovalSubmission(rtn);
      }
    });
  };

  if (loading) return <CircularProgress color='primary' />;
  if (mts == null) return <div>Not found</div>;

  const hasEntries = Boolean(mts.entries?.entries?.length);
  const approvalForm = mts.activityTypes[0]?.externalApprovalForm;

  if (!hasEntries) return <div>No records found</div>;
  if (!approvalForm) return <div>No approval form configured for this MTS</div>;

  if (approvalSubmission !== mts.entries.entries[0].externalApprovalSubmission) setApprovalSubmission(mts.entries.entries[0].externalApprovalSubmission);

  const dynFormProps: DynamicFormProps = {
    overrideSubmitHandler: onSubmit,
    formInst: approvalForm,
    autoloadFormSubmission: approvalSubmission != null
  };
  if (approvalSubmission != null) dynFormProps.formSubmissionInst = approvalSubmission as unknown as DTO.FormSubmission;

  return <EMA mts={mts} approvalSubmission={approvalSubmission} dynFormProps={dynFormProps} />;
};
