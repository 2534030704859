import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { StudentContext } from '../../contexts';
import * as DTO from '../../dto';
import { useClasses, useFormSubmissions } from '../../hooks';
import { GeneralErrorMessage, LanguageString } from '../Common';
import SnapShotDialog from './SnapShotDialog';

export const SnapShot: React.FC = () => {
  const classes = useClasses();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFormSubsmission, setSelectedFormSubmission] = useState<DTO.FormSubmission | undefined>(undefined);
  const studentCtx = useContext(StudentContext);

  const submissions = useFormSubmissions({
    variables: {
      formName: 'assessmentToolTests',
      userId: studentCtx?.student.id ?? 0
    }
  });

  const toggleDialog = () => {
    if (dialogOpen) {
      setSelectedFormSubmission(undefined);
    }
    setDialogOpen(!dialogOpen);
  };

  const clickedSubmission = (submission: DTO.FormSubmission) => {
    setSelectedFormSubmission(submission);
    toggleDialog();
  };

  const submittedFormData = async () => {
    await submissions.refetch();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {submissions.loading && <CircularProgress color="primary" />}

        {submissions.error && <GeneralErrorMessage message={submissions.error.message} />}

        {submissions.data && (
          <Card>
            <CardHeader
              title={<LanguageString groupName="GENERAL" resourceName="SNAPSHOT_TITLE" defaultText="Snapshots" />}
              action={
                <IconButton onClick={toggleDialog}>
                  <Icon>add_circle</Icon>
                </IconButton>
              }
            />
            <CardContent>
              <Table className={classes.stripedTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <LanguageString groupName="GENERAL" resourceName="DATE" defaultText="Date" />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissions.data.user?.formSubmissions && (
                    <>
                      {submissions.data.user.formSubmissions.map(sub => (
                        <TableRow key={`snapshot_${sub.id}`}>
                          <TableCell
                            onClick={() => {
                              clickedSubmission(sub);
                            }}
                          >
                            {format(new Date(sub.startedAt ? sub.startedAt : new Date()), 'MM/dd/yy h:mm a')}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                clickedSubmission(sub);
                              }}
                            >
                              <Icon>keyboard_arrow_right</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </Grid>

      <SnapShotDialog open={dialogOpen} toggle={toggleDialog} submission={selectedFormSubsmission} submitted={submittedFormData} />
    </Grid>
  );
};
export default SnapShot;
