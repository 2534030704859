import { BottomNavigation, BottomNavigationAction, Grid, Icon, Paper, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import 'jsoneditor-react/es/editor.min.css';
import { LanguageString } from '../Common';
import React, { useContext, useState } from 'react';
import { SiteContext } from '../../contexts';
import { useUpdateBrandingCSS, useUpdateTheme } from '../../hooks';

// jsoneditor-react doesn't ship pre-transpiled, and while Webpack doesn't have a problem with that,
// jest does. We solve this by replacing the editor with an empty div in the test environment.
const Editor = process.env.NODE_ENV === 'test' ? { JsonEditor: () => <div /> } : require('jsoneditor-react');

export const StylesEditor: React.FC = () => {
  const [statusLabel, setStatusLabel] = useState('');
  const [updateBrand] = useUpdateBrandingCSS();
  const [updateTheme] = useUpdateTheme();
  const siteCtx = useContext(SiteContext);
  const classes = JSON.parse(siteCtx.site?.branding?.cssClasses ?? '{}');
  const theme = JSON.parse(siteCtx.site?.branding?.theme ?? '{}');
  const updatedText = 'Styles updated!';
  const [tab, setTab] = React.useState(0);
  const [editor, setEditor] = React.useState(0);
  let stylesTimer: NodeJS.Timeout | undefined;
  let themeTimer: NodeJS.Timeout | undefined;

  function handleUpdateStyles2(jsonString: string) {
    console.log(`Updating styles`);
    setStatusLabel('Updating Styles...');

    void updateBrand({
      variables: {
        input: {
          cssClasses: jsonString
        }
      },
      update: (cache, rslt) => {
        setStatusLabel(updatedText);
      }
    });
  }

  function handleUpdateStyles(jsonString: string) {
    if (stylesTimer) {
      clearTimeout(stylesTimer);
      stylesTimer = undefined;
    }

    stylesTimer = setTimeout(() => {
      void handleUpdateStyles2(jsonString);
    }, 1000);
  }

  function handleUpdateTheme2(jsonString: string) {
    setStatusLabel('Updating Theme...');

    void updateTheme({
      variables: {
        input: {
          theme: jsonString
        }
      },
      update: (cache, rslt) => {
        setStatusLabel(updatedText);
      }
    });
  }

  function handleUpdateTheme(jsonString: string) {
    if (themeTimer) {
      clearTimeout(themeTimer);
      themeTimer = undefined;
    }

    themeTimer = setTimeout(() => {
      handleUpdateTheme2(jsonString);
    }, 1000);
  }

  function handleTextEditorChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const jsonString = event.currentTarget.value;
    try {
      const json = JSON.parse(jsonString);

      if (json) {
        setStatusLabel('');
        if (tab === 0) {
          handleUpdateStyles(JSON.stringify(json));
        } else {
          handleUpdateTheme(JSON.stringify(json));
        }
      } else {
        setStatusLabel('Invalid JSON');
      }
    } catch (error) {
      setStatusLabel('Invalid JSON');
    }
  }

  // Create a timer to clear the status label if the status was 'updated'
  if (statusLabel === updatedText) {
    setTimeout(() => {
      if (statusLabel === updatedText) {
        setStatusLabel('');
      }
    }, 2000);
  }

  return (
    <>
      <main>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={editor}
                aria-label="Editor"
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => {
                  setEditor(newValue);
                }}
              >
                <Tab label={<LanguageString groupName="GENERAL" resourceName="JSON_EDITOR" defaultText="JSON Editor" />} />
                <Tab label={<LanguageString groupName="GENERAL" resourceName="TEXT_EDITOR" defaultText="Text Editor" />} />
              </Tabs>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12} item className={classes.content}>
            {/* This code has to be rendered like this because passing the props directly based on tab selection doesn't work with JSON Editor */}
            {editor === 0 && (
              <>
                {tab === 0 && (
                  <Editor.JsonEditor
                    value={classes}
                    onChange={(newJson: Object) => {
                      handleUpdateStyles(JSON.stringify(newJson));
                    }}
                  />
                )}

                {tab === 1 && (
                  <Editor.JsonEditor
                    value={theme}
                    onChange={(newJson: Object) => {
                      handleUpdateTheme(JSON.stringify(newJson));
                    }}
                  />
                )}
              </>
            )}

            {editor === 1 && (
              <>
                {tab === 0 && (
                  <TextField
                    fullWidth
                    multiline
                    defaultValue={JSON.stringify(classes, null, 2)}
                    rows={4}
                    rowsMax={20}
                    onChange={handleTextEditorChange}
                  />
                )}

                {tab === 1 && (
                  <TextField
                    fullWidth
                    multiline
                    defaultValue={JSON.stringify(theme, null, 2)}
                    rows={4}
                    rowsMax={20}
                    onChange={handleTextEditorChange}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid xs={12} item className={classes.content}>
            <Typography variant="subtitle1">{statusLabel}</Typography>
          </Grid>
        </Grid>
      </main>

      <BottomNavigation
        value={tab}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction label="Styles" icon={<Icon>brush</Icon>} />
        <BottomNavigationAction label="Theme" icon={<Icon>format_paint</Icon>} />
      </BottomNavigation>
    </>
  );
};

export default StylesEditor;
