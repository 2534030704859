import React, { useState, useContext, useImperativeHandle, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Grid,
  Checkbox,
  IconButton,
  Icon,
  FormControlLabel,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination
} from '@material-ui/core';
import { LanguageString, GeneralErrorMessage } from '../Common';
import { useLocalStorage, LocalStorageKey } from '../../hooks';
import { useAdvisors } from '../../hooks';
import { UserInfo } from '../../dto';
import { DynamicForm } from '../DynamicForm';
import { SiteContext, StudentsContext } from '../../contexts';
import * as DTO from '../../dto';

export interface StudentsTableColumnSettings {
  name: boolean;
  coach: boolean;
  caseNum: boolean;
  phone: boolean;
  username: boolean;
  email: boolean;
}

export const initialColumnsSettings: StudentsTableColumnSettings = {
  name: true,
  coach: true,
  caseNum: true,
  phone: true,
  username: true,
  email: true
};

export interface AdvisorColumnSettings {
  advisorIds: number[];
}

export const initialAdvisorSettings: AdvisorColumnSettings = {
  advisorIds: []
};

interface StudentsFiltersDialogProps {
  open: boolean;
  toggle: () => void;
  searchMode: boolean;
}

export type StudentsColumnsFilters = {
  willDismiss: () => void;
};

const StudentsColumnsFiltersInner: React.RefForwardingComponent<StudentsColumnsFilters, {}> = (props, ref) => {
  const siteCtx = useContext(SiteContext);
  const studentsCtx = useContext(StudentsContext);
  const [filters, setFilters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.StudentsFilters, {});
  let dynamicFormRef: DynamicForm | null = null;

  console.log(`local storage filters: `, filters);

  function willDismiss() {
    if (dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }
  }

  const control: StudentsColumnsFilters = {
    willDismiss
  };

  useImperativeHandle(ref, () => control);

  return (
    <Grid item xs>
      <Grid container>
        <Grid item xs={12} md={6}>
          <DynamicForm
            ref={f => (dynamicFormRef = f)}
            formId={siteCtx.site?.studentsFilterForm?.id ?? 0}
            autoloadFormSubmission={false}
            showSubmitButton={false}
            autoSave={true}
            overrideFormSubmissionInput={studentsCtx?.filters ? studentsCtx.filters : undefined}
            overrideSubmitHandler={(input, form) => {
              if (studentsCtx) {
                studentsCtx.setFilters(input);
              }
              setFilters(input);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
const StudentsColumnsFilters = React.forwardRef(StudentsColumnsFiltersInner);

const StudentsColumnSettings: React.FC = (props) => {

  const [columns, setColumns] = useLocalStorage(LocalStorageKey.StudentsTableColumns, initialColumnsSettings);

  return (
    <Grid item xs>
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.name}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.name = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_NAME" defaultText="Participant Name" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.coach}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.coach = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_COACH" defaultText="Coach" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.caseNum}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.caseNum = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_CASE_NUM" defaultText="Case #" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.phone}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.phone = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_PHONE" defaultText="Phone" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.username}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.username = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_USERNAME" defaultText="Username" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={columns.email}
                value="primary"
                onChange={event => {
                  const c = columns;
                  c.email = event.target.checked;
                  setColumns(c);
                }}
              />
            }
            label={<LanguageString groupName="STUDENTS" resourceName="COLUMN_EMAIL" defaultText="Email" />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const StudentsColumnAdvisorFilters: React.FC = () => {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [checkAll, setCheckAll] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [selectedAdivorSettings, setSelectedAdivorSettings] = useLocalStorage(LocalStorageKey.SearchAdvisors, initialAdvisorSettings);

  console.log(`checked advisors: `, JSON.stringify(selectedAdivorSettings));

  const { loading, data, error } = useAdvisors({
    variables: {
      limit: checkAll ? 100000 : rowsPerPage,
      offset: checkAll ? 0 : page * rowsPerPage
    }
  });

  useEffect(() => {
    if (checkAll && data?.advisors.advisors) {
      setSelectedAdivorSettings({ advisorIds: data.advisors.advisors.map(a => a.id) });
    }
  }, [data]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPageOptions[1]));
    setPage(0);
  };

  const isUserChecked = (advisor: UserInfo) => {
    if (selectedAdivorSettings && selectedAdivorSettings.advisorIds) {
      for (const advisorId of selectedAdivorSettings.advisorIds) {
        if (advisorId === advisor.id) {
          return true;
        }
      }
    }

    return false;
  };

  const checkValueChanged = (advisor: UserInfo, checked: boolean) => {
    let selectedUserIds = selectedAdivorSettings.advisorIds;

    if (checked) {
      selectedUserIds.push(advisor.id);
    } else {
      selectedUserIds = selectedUserIds.filter(x => x !== advisor.id);
    }

    setSelectedAdivorSettings({ advisorIds: selectedUserIds });
  };

  return (
    <Grid item xs>
      {error && (
        <GeneralErrorMessage message={error.message} />
      )}

      {loading && (
        <CircularProgress color="primary" />
      )}

      {!loading && !error && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={checkAll}
                    value="primary"
                    onChange={(event) => {
                      setSelectedAdivorSettings({ advisorIds: [] });
                      setCheckAll(event.currentTarget.checked);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <LanguageString groupName="CASE_LOAD" resourceName="ADVISOR_FILTER_USERNAME" defaultText="Username" />
                </TableCell>
                <TableCell>
                  <LanguageString groupName="CASE_LOAD" resourceName="ADVISOR_FILTER_NAME" defaultText="Name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.advisors.advisors?.map(advisor => (
                <TableRow key={`advisor_${advisor.id}`}>
                  <TableCell>
                    <Checkbox
                      checked={isUserChecked(advisor)}
                      value="primary"
                      onChange={(event) => {
                        checkValueChanged(advisor, event.currentTarget.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {advisor.username}
                  </TableCell>
                  <TableCell>
                    {advisor.firstName} {advisor.lastName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data?.advisors.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Grid>
  );
};

export const StudentsFiltersDialog: React.FC<StudentsFiltersDialogProps> = (props) => {
  const siteCtx = useContext(SiteContext);
  const [currentTab, setCurrentTab] = useState(0);
  let filtersRef: StudentsColumnsFilters | null = null;

  const triggerFiltersDismiss = () => {
    if (((props.searchMode && currentTab === 0) || (!props.searchMode && currentTab === 1)) && filtersRef) {
      filtersRef.willDismiss();
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    triggerFiltersDismiss();
    setCurrentTab(newValue);
  };

  const toggle = () => {
    triggerFiltersDismiss();
    props.toggle();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open}
      onClose={toggle}
      aria-labelledby="Case Load Filters"
    >
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <LanguageString groupName="STUDENTS" resourceName="STUDENT_FILTERS" defaultText="Filters" />
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filter Tabs"
          variant="fullWidth"
        >
          {!props.searchMode && (
            <Tab
              value={0}
              label={
                <LanguageString groupName="STUDENTS" resourceName="ADVISORS" defaultText="Advisors" />
              }
            />
          )}

          {siteCtx.site?.studentFilterEnabled && (
            <Tab
              value={1}
              label={
                <LanguageString groupName="STUDENTS" resourceName="FILTERS" defaultText="Filters" />
              }
            />
          )}
          <Tab
            value={2}
            label={
              <LanguageString groupName="STUDENTS" resourceName="COLUMNS" defaultText="Columns" />
            }
          />
        </Tabs>

        <Grid container>
          {currentTab === 0 && (
            <StudentsColumnAdvisorFilters />
          )}
          {currentTab === 1 && (
            <StudentsColumnsFilters ref={f => (filtersRef = f)} />
          )}
          {currentTab === 2 && (
            <StudentsColumnSettings />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StudentsFiltersDialog;
