import React, { useState, useCallback } from 'react';
import { QuestionCache } from '../../components/DynamicForm/common';
import { FormSubmission, Form } from '../../dto';

interface DynamicFormContextProps {
  form?: Form;
  setForm?: (form: Form) => void;
  formSubmission?: FormSubmission;
  setFormSubmission?: (formSubmission: FormSubmission) => void;
  assignmentId?: number;
  setAssignmentId?: (a: number) => void;
  questionCache?: QuestionCache;
}

export const DynamicFormContext = React.createContext<DynamicFormContextProps>({});

export const DynamicFormProvider: React.FC<DynamicFormContextProps> = (props) => {

  const [form, setForm] = useState<Form | undefined>(props.form);
  const [formSubmission, setFormSubmission] = useState<FormSubmission | undefined>(props.formSubmission);
  const [assignmentId, setAssignmentId] = useState<number | undefined>(props.assignmentId);

  const setFormHandler = useCallback((f: Form) => {
    setForm(f);
  }, []);
  const setFormSubmissionHandler = useCallback((f: FormSubmission) => {
    setFormSubmission(f);
  }, []);
  const setAssignmentIdHandler = useCallback((f: number) => {
    setAssignmentId(f);
  }, []);

  return (
    <DynamicFormContext.Provider
      value={{
        form: form,
        formSubmission: formSubmission,
        setForm: setFormHandler,
        setFormSubmission: setFormSubmissionHandler,
        assignmentId: assignmentId,
        setAssignmentId: setAssignmentIdHandler,
        questionCache: props.questionCache
      }}
    >
      {props.children}
    </DynamicFormContext.Provider>
  );
};
