import gql from 'graphql-tag';
import { languageStringMinFrag } from './languageStringFrag';
export const taskFrag = gql`
  ${languageStringMinFrag}

  fragment TaskFrag on Task {
    id
    name {
      ...LangStringMinFrag
    }
    summary {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    instructions {
      ...LangStringMinFrag
    }
    requiresApproval
    actionType
    action
  }
`;

export const sitePathsTaskFrag = gql`
  ${languageStringMinFrag}

  fragment TaskFrag on Task {
    id
    name {
      ...LangStringMinFrag
    }
    summary {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    instructions {
      ...LangStringMinFrag
    }
		completedEmail {
			id
			subject {
				...LangStringMinFrag
			}
			body {
				...LangStringMinFrag
			}
			additionalRecipients
		}
    requiresApproval
    actionType
    action
  }
`;

export default taskFrag;
