import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { PathInput, SuccessPath } from '../../dto';
import { pathFrag } from './fragments';

type Result = { updatePath: SuccessPath };
type Variables = { input: PathInput };
export function useUpdatePath(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${pathFrag}
    mutation updatePath($input: PathInput!) {
      updatePath(input: $input) {
        ...pathFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdatePath;
