import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { mtsActivityFrag } from './fragments';
import * as DTO from '../../dto';

type Results = { mtsEntryById: DTO.MTSActivity[]};
type Variables = { entryIds: number[] };

export function useGetMTSEntryById(options: QueryHookOptions<Results, Variables>) {
  const query = gql`
    ${mtsActivityFrag}

    query getMTSEntryById($entryIds: [Int]!) {
      mtsEntryById(entryIds: $entryIds) {
        ...MTSActivityFrag
      }
    }
  `;

  return useQuery<Results, Variables>(query, options);
};
