import { tmpId } from '../contexts';
import { FormAnswerType, FormLayoutType, FormOperation, FormOptionType, FormQuestionType, FormType, LanguageString, UpdateStringInput } from '../dto';
import { hasProp } from './typedReflect';

export type Unpacked<T> = T extends (infer A)[]
  ? A // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : T extends (...args: any[]) => infer R
  ? R
  : T extends Promise<infer P>
  ? P
  : T;

export type OValues = {
  id: string;
  text: string;
  code?: string;
};

export type LsValues = {
  id: number;
  isHtml: boolean;
  text?: string;
};

export type LgValues = {
  groupName: string;
  resourceName: string;
  isHtml: boolean;
  text?: string;
};

export type QValues = {
  id: number;
  name?: string;
  questionType: FormQuestionType;
  text: LsValues;
  hint?: LsValues;
  highText?: LsValues;
  lowText?: LsValues;
  answer?: string;
  options?: OValues[];
  isRequired: boolean;
  isReadOnly: boolean;
  transient: boolean;
  encrypt: boolean;
  sortIndex?: number;
  answerType: FormAnswerType;
  userPropertyId?: number;
  hideIfNull: boolean;
  match?: string;
  minLength?: number;
  maxLength?: number;
  minRateLevel?: number;
  rateLevels?: number;
  rows?: number;
  optionType?: FormOptionType;
  dependsOn?: EnableRuleValues[];
};

export type FEValues = {
  id: number;
  name?: string;
  sortIndex: number;
  title?: LsValues;
  showNumbers?: boolean;
  instructions?: LsValues;
  layoutType?: FormLayoutType;
  elements?: FEValues[];
  questions?: QValues[];
  dependsOn?: EnableRuleValues[];
};

export type FValues = {
  id: number;
  name: string;
  title: LsValues;
  formType: FormType;
  layoutType: FormLayoutType;
  elements?: FEValues[];
  downloadButtonEnabled: boolean;
  allowMultipleSubmissions: boolean;
  autoGrade: boolean;
  showCorrect: boolean;
  // TODO: hack to enable 'live' forms in the form previewer
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewVals: { [id: number]: any };
};

export interface EnableRuleValues {
  id: number;
  operation: FormOperation;
  operand: string;
  name: string;
  source: QValues;
  targetForm?: FValues;
  targetElement?: FEValues;
  targetQuestion?: QValues;
}

type LsvType<T extends LanguageString | undefined> = T extends LanguageString ? LsValues : LsValues | undefined;

export function lsValue<T extends LanguageString | undefined>(ls: T): LsvType<T> {
  return ls != null ? ({ id: ls.id, isHtml: ls.isHtml === true, text: ls.text } as LsvType<T>) : (undefined as LsvType<T>);
}

function isLgValues(val: LgValues | LsValues | undefined): val is LgValues {
  const candidateVal = val as LgValues;
  return candidateVal.groupName != null;
}

type UpdateLs<T extends LgValues | LsValues | undefined> = T extends LsValues
  ? UpdateStringInput
  : T extends LgValues
  ? UpdateStringInput
  : UpdateStringInput | undefined;

export function lsDto<T extends LgValues | LsValues | undefined>(lsData: T): UpdateLs<T> {
  return lsData != null && lsData.text != null
    ? isLgValues(lsData)
      ? ({
          group: {
            groupName: lsData.groupName,
            resourceName: lsData.resourceName,
            newText: lsData.text,
            languageCode: ((hasProp(window.navigator, 'userLanguage') && window.navigator.userLanguage) || window.navigator.language) ?? 'en'
          }
        } as UpdateLs<T>)
      : ({
          languageString: {
            id: lsData.id,
            newText: lsData.text ?? '',
            isHtml: lsData.isHtml
          }
        } as UpdateLs<T>)
    : (undefined as UpdateLs<T>);
}

type MaybeLsValues = LsValues | undefined;
type ResLsValues<T extends LsValues | undefined> = T extends LsValues ? LsValues : LsValues | undefined;
function withId<T extends LsValues | undefined>(lsVal: T): ResLsValues<T> {
  return lsVal != null
    ? lsVal.id == null
      ? (({ ...lsVal, id: tmpId.nextId } as unknown) as ResLsValues<T>)
      : ((lsVal as unknown) as ResLsValues<T>)
    : (undefined as ResLsValues<T>);
}
export function lsDtoWithId<T extends MaybeLsValues>(lsVal: T): UpdateLs<ResLsValues<T>> {
  return lsDto(withId(lsVal));
}
