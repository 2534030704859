import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps, Form, Formik, useField } from 'formik';
import React, { useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { FEValues, FValues, LsValues, makeStyles, TypedReflect, Unpacked } from '../../common';
import { tmpId } from '../../contexts';
import { Form as FormDto, FormLayoutType, FormType } from '../../dto';
import { useDialogController, useFormAndSubmission, useForms, useUpdateForm } from '../../hooks';
import { QueryResult } from '../../hooks/gql/common';
import { query as formsQuery } from '../../hooks/gql/useForms';
import { Checkbox, IconButton, LanguageString, LSTextField, TextField } from '../Common';
import { ClickEvent, LayoutTextField } from './common';
import { ElementListItem } from './ElementListItem';
import { FormPreviewDialog } from './FormPreview';
import { convertToDtoFormat, convertToEditorFormat, createNewElement } from './utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.spacing(2)}px`
  },
  [theme.breakpoints.up('md')]: {
    container: {
      gridTemplateColumns: 'minmax(360px, 1fr) 3fr'
    }
  }
}));

const FormChildElementsInner: React.FC<FieldArrayRenderProps> = ({ name, push }) => {
  const [{ value: elements }] = useField<FEValues[]>(name);
  const handleAddFeClick = () => push(createNewElement(elements.length));

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const sourceIndex = result.source.index;
    const destIndex = result.destination?.index;

    if (result.reason === 'DROP' && destIndex != null && elements != null) {
      elements.splice(destIndex, 0, elements.splice(sourceIndex, 1)[0]);
      elements.forEach((el, nIndex) => (el.sortIndex = nIndex));
    }
  };

  return (
    <Card>
      <CardHeader
        title={<LanguageString groupName="FORM_EDITOR" resourceName="SECTIONS_TITLE" defaultText="Sections" />}
        action={<Button onClick={handleAddFeClick}>Add</Button>}
      />
      <Divider />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`form-element-list`}>
          {dpProvided => (
            <List ref={dpProvided.innerRef}>
              {elements.map((el, index) => (
                <ElementListItem key={el.id} element={el} fieldName={`elements[${index}]`} index={index} />
              ))}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
};

// TODO: Remove this hack when Formik fixes their type definitions - see https://github.com/formium/formik/issues/1736
const FormChildElements = FormChildElementsInner as React.FC<FieldArrayRenderProps | void>;

type FormEvents = {
  setSelectedForm: (f: FormDto) => void;
  onSaved: () => void;
};

const FormLoader: React.FC<FormEvents & { formId: number }> = ({ formId, ...evProps }) => {
  const { loading, data } = useFormAndSubmission({ variables: { formId } });

  const formData = useMemo<FValues | undefined>(() => {
    if (data?.form == null) return;

    return convertToEditorFormat(data.form);
  }, [data, data?.form.id]);

  return loading ? <CircularProgress color="primary" /> : data?.form != null && formData != null ? <FormEditor form={formData} {...evProps} /> : null;
};

const NameOrTitleOrDefault: React.FC = () => {
  const [{ value: title }] = useField<LsValues>('title');
  const [{ value: name }] = useField('name');

  return <>{name ? name : title?.text ? title.text : 'Unnamed form'}</>;
};

const FormForm: React.FC = () => {
  const [{ value: fType }] = useField<FormType>(`formType`);

  return (
    <Grid container spacing={2} direction="column">
      <TextField item name="name" label="Name" hint="The name used to reference this form in other parts of the TuaPath app" />
      <LSTextField item name="title" label="Title" />
      <Checkbox item name="allowMultipleSubmissions" label="Allow Multiple Submissions" />
      <Checkbox item name="downloadButtonEnabled" label="Enable Download" />
      <TextField item name="formType" label="Form Type" select style={{ textTransform: 'capitalize' }}>
        {TypedReflect.ownKeys(FormType).map((key) => (
          <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
            {key.toLowerCase()}
          </MenuItem>
        ))}
      </TextField>
      {fType === FormType.TEST && <Checkbox item name="autoGrade" label="Automatically Grade When Submitted" />}
      {fType === FormType.TEST && <Checkbox item name="showCorrect" label="Show Correct Answers" /*hint="Show which answers are correct on a graded form after it is submitted"*/ />}
      <LayoutTextField item name="layoutType" />
    </Grid>
  );
};

const FormEditor: React.FC<FormEvents & { form: FValues | null }> = ({ form, setSelectedForm, onSaved }) => {
  const [updateForm] = useUpdateForm({
    update: (store, { data: rsltData }) => {
      if (rsltData?.updateForm != null && rsltData.updateForm.id !== form?.id) {
        const data = store.readQuery<QueryResult<typeof useForms>>({ query: formsQuery });
        if (data?.site.forms != null) data.site.forms.push(rsltData.updateForm);
        store.writeQuery({ query: formsQuery, data });
        setSelectedForm(rsltData.updateForm);
      }
    },
    onCompleted: () => onSaved()
  });
  const { controller: previewCtrl, props: previewProps } = useDialogController(false);
  if (form == null) return null;

  const handleSubmit = (values: FValues) => updateForm({ variables: { input: convertToDtoFormat(values) } });
  const handlePreviewClick = () => previewCtrl.setOpen();

  return (
    <Formik initialValues={form} onSubmit={handleSubmit} enableReinitialize>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<NameOrTitleOrDefault />}
                action={
                  <Box>
                    <IconButton icon="pageview" onClick={handlePreviewClick} />
                    <IconButton icon="save" type="submit" />
                  </Box>
                }
              />
              <Divider />
              <CardContent>
                <FormForm />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <FieldArray name="elements" component={FormChildElements} />
          </Grid>
        </Grid>
        <FormPreviewDialog {...previewProps} />
      </Form>
    </Formik>
  );
};

export const FormEditorView: React.FC = () => {
  const { loading, data: fsData } = useForms();
  const [selectedForm, setSelectedForm] = useState<Unpacked<Exclude<QueryResult<typeof useForms>, undefined>['site']['forms']>>();
  const [newForm, setNewForm] = useState<FValues | undefined>();
  const { controller: confirmCtrl, props: confirmedProps } = useDialogController(false);
  const classes = useStyles();

  const getForm = (id: number) => {
    if (fsData?.site.forms != null) return fsData.site.forms.find(form => form.id === id);

    return undefined;
  };

  const handleFormClick: ClickEvent = event => {
    const formId = Number(event.currentTarget.dataset.formId);

    formId < 0 ? setSelectedForm(undefined) : setSelectedForm(getForm(formId));
  };

  const handleFormSaved = () => {
    confirmCtrl.setOpen();
  };

  const getNewFormData = (): FValues => ({
    id: tmpId.nextId,
    name: 'newForm',
    formType: FormType.INFORMATION,
    downloadButtonEnabled: false,
    allowMultipleSubmissions: false,
    autoGrade: false,
    showCorrect: false,
    title: { id: tmpId.nextId, isHtml: false, text: 'New Form' },
    layoutType: FormLayoutType.ROWS,
    elements: [],
    previewVals: {}
  });

  const createNewForm = () => {
    setNewForm(getNewFormData());
    setSelectedForm(undefined);
  };

  const addFormAction = <IconButton icon="add" onClick={createNewForm} />;

  return loading ? (
    <CircularProgress color="primary" />
  ) : fsData?.site != null ? (
    <div className={classes.container}>
      <Card>
        <CardHeader title={<LanguageString groupName="GENERAL" resourceName="FORM_EDITOR_TITLE" defaultText="Forms" />} action={addFormAction} />
        <Divider />
        {/* TODO: Remove the hard-coded height */}
        <Box height="600px" overflow="auto" clone>
          <List>
            {newForm && selectedForm == null && (
              <ListItem key={newForm.id} button data-form-id={newForm.id} onClick={handleFormClick} selected={selectedForm == null}>
                <ListItemText primary={newForm.name} />
              </ListItem>
            )}
            {fsData.site.forms.map(form => (
              <ListItem key={form.id} button data-form-id={form.id} onClick={handleFormClick} selected={selectedForm?.id === form.id}>
                <ListItemText primary={form.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Card>
      {selectedForm != null ? (
        <FormLoader formId={selectedForm.id} setSelectedForm={setSelectedForm} onSaved={handleFormSaved} />
      ) : newForm != null ? (
        <FormEditor form={newForm} setSelectedForm={setSelectedForm} onSaved={handleFormSaved} />
      ) : null}
      <Dialog {...confirmedProps}>
        <DialogTitle>Form Saved</DialogTitle>
        <DialogContent dividers>Changes to the form have been saved</DialogContent>
        <DialogActions>
          <Button onClick={() => confirmCtrl.setClose()}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Box>No data</Box>
  );
};
