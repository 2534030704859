import {
  Dialog, DialogContent, Table, TableBody, TableCell,
  TableHead, TableRow, Card, CardHeader, CardContent,
  useMediaQuery, useTheme, Grid, Divider, Box, Typography
} from '@material-ui/core';
import React, { useContext } from 'react';
import { MTSContext } from '../../contexts';
import MTSHelpers from '../../helpers/mtsHelpers';
import { useClasses } from '../../hooks';
import { DialogTitle, LanguageString } from '../Common';
import { format } from 'date-fns';
import ActivityFormsTable from './ReviewAllMTSInfoPage/ActivityFormsTable';
import * as DTO from '../../dto';

const LogNotes: React.FC<{ mtses: DTO.MTS[] }> = ({ mtses }) => {
  const classes = useClasses();
  const activityGroups = MTSHelpers.logNotesEntryGroups(mtses);
  let grandTotal = 0;

  const mtsForActivity = (activity: DTO.MTSActivity) => {
    return mtses.find(mts => (mts.entries?.entries ?? []).find(a => a.id === activity.id));
  };

  return (
    <Box>
      <Box padding={2}>
        <Typography variant="subtitle2" color="textPrimary">
          <LanguageString groupName="MTS" resourceName="MTS" defaultText="MTS" />
        </Typography>
        {mtses.map(mts => (
          <Typography variant="subtitle1" color="textSecondary">
            {mts.mtsPeriod ? (
              <>
                {`${format(MTSHelpers.dateFromString(mts.mtsPeriod.startDate), 'MM/dd/yyyy')} - ${format(MTSHelpers.dateFromString(mts.mtsPeriod.endDate), 'MM/dd/yyyy')}`}
              </>
            ): (
              <>
                {`${mts.month}/${mts.year}`}
              </>
            )}
          </Typography>
        ))}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_DATE" defaultText="Date" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_ACTIVITY_TYPE_NAME" defaultText="Activity Name" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_LOG_NOTES" defaultText="Note" />
            </TableCell>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="MTS_REVIEW_INFO_HOURS" defaultText="Hours" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityGroups.map((activityGroup, i) => {
            let total = 0;
            for (const activity of activityGroup) {
              total += activity.hours;
            }
            grandTotal += total;

            return (
              <>
                {activityGroup.map(activity => {
                  const mts = mtsForActivity(activity);

                  return (
                    <TableRow key={`mts_activity_${activity.id}`}>
                      <TableCell>{activity.date ? format(MTSHelpers.dateFromString(activity.date), 'MM/dd/yyyy') : `${mts?.month ?? 1}/${activity.day}/${mts?.year ?? 2020}`}</TableCell>
                      <TableCell>
                        <LanguageString languageString={activity.type.name} />
                      </TableCell>
                      <TableCell>{activity.note}</TableCell>
                      <TableCell>{activity.hours}</TableCell>
                    </TableRow>
                  );
                })}

                <TableRow className={classes.tableSubfooter}>
                  <TableCell>
                    <LanguageString groupName="MTS" resourceName="MTS_REVIEW_ACTIVITY_TOTAL" defaultText="Activity Total" />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell>{total}</TableCell>
                </TableRow>
              </>
            );
          })}

          {activityGroups.length > 0 && (
            <TableRow className={classes.tableFooter}>
              <TableCell>
                <LanguageString groupName="MTS" resourceName="MTS_REVIEW_GRAND_TOTAL" defaultText="Grand Total" />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell>{grandTotal}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

interface LogNotesDialogProps {
  open: boolean;
  toggle: () => void;
}

export const LogNotesDialog: React.FC<LogNotesDialogProps> = props => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      className={classes.mtsAddActivityDialog}
      maxWidth="lg"
      fullWidth
      fullScreen={fullscreen}
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="Signup Form"
      scroll="paper"
    >
      <DialogTitle onClose={props.toggle}>
        <LanguageString groupName="MTS" resourceName="LOG_NOTES_MODAL_TITLE" defaultText="Notes" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Card>
              <CardHeader
                title={
                  <LanguageString groupName="MTS" resourceName="ACTIVITY_FORMS" defaultText="Activity Forms" />
                }
              />
              <CardContent style={{overflowX: 'auto'}}>
                {mtsCtx.mtses.length > 1 ? (
                  <>
                    {mtsCtx.mtses.map((mts, i) => (
                      <Box key={`activity_forms_table_${mts.id}`}>
                        <Card>
                          <ActivityFormsTable mts={mts} showMtsTitle={mtsCtx.mtses.length > 1} />
                        </Card>
                        {i < mtsCtx.mtses.length - 1 && (
                          <Box marginTop={4} marginBottom={4}>
                            <Divider />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </>
                ): (
                  <ActivityFormsTable mts={mtsCtx.selectedMTS} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12}>
            <Box marginTop={2}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <LanguageString groupName="MTS" resourceName="LOG_NOTES" defaultText="Log Notes" />
                }
              />
              <CardContent>
                <LogNotes mtses={mtsCtx.mtses} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default LogNotesDialog;
