import { createMuiTheme } from '@material-ui/core';
import { useContext } from 'react';
import { SiteContext } from '../contexts';

export const useCustomTheme = () => {
  const siteCtx = useContext(SiteContext);

  let theme = createMuiTheme({
    // Fallback theme
  });

  if (siteCtx.site?.branding?.theme) {
    const jsonOb = JSON.parse(siteCtx.site.branding.theme);

    if (jsonOb) {
      theme = createMuiTheme(jsonOb);
      if (theme.paletteExtension?.information) {
        theme.paletteExtension.information = theme.palette.augmentColor(theme.paletteExtension.information);
      }
      if (theme.paletteExtension?.success) {
        theme.paletteExtension.success = theme.palette.augmentColor(theme.paletteExtension.success);
      }
      theme.props = {
        ...theme.props,
        MuiTextField: { variant: 'outlined' }
      };
    }
  }

  return theme;
};
