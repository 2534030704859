import gql from 'graphql-tag';
import { notificationTemplateFrag } from './notificationTemplateFrag';

export const notificationTriggerFrag = gql`
  ${notificationTemplateFrag}
  fragment NotificationTriggerFrag on NotificationTrigger {
    id
    name
    successPathId
    type
    triggerRate
    operand
    secondaryOperand
    customPropertyTypeId
    customPropertyValue
    notificationTemplate {
      ...NotificationTemplateFrag
    }
    dripCustomPropertyId
    killSwitchDripCustomPropertyId
    dripSchedule
    dripTimeExtensionType
    milestoneIds
  }
`;

export default notificationTriggerFrag;
