import * as React from 'react';
import {
  Grid
} from '@material-ui/core';
import BrowseStudents from './BrowseStudents';
import { useRouteMatch } from 'react-router';
import { ChildRoutes, StudentsProvider, StudentProvider } from '../../contexts';
import { useClasses } from '../../hooks';

export const Students: React.FC = () => {
  const classes = useClasses();
  const studentsMatch = useRouteMatch(`/students/:id([^/a-zA-Z]+)*/(student)?/:studentId?`);
  const selectedStudentsMatch = useRouteMatch(`/students/:id([^/a-zA-Z]+)*/student/:studentId/*`);

  return (
    <StudentsProvider>
      <Grid className={classes.studentComponentContainer} container>
        <Grid item xs={12}>
          {studentsMatch && studentsMatch.isExact && (
            <BrowseStudents />
          )}
          {(!selectedStudentsMatch || (selectedStudentsMatch && selectedStudentsMatch.isExact)) && (
            <StudentProvider>
              <ChildRoutes group="navbar" />
            </StudentProvider>
          )}
        </Grid>
      </Grid>
    </StudentsProvider>
  );
};

export default Students;
