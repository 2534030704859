import gql from 'graphql-tag';
import { languageStringFrag } from './languageStringFrag';

export const attachmentTypeFrag = gql`
  ${languageStringFrag}

  fragment AttachmentTypeFrag on AttachmentType {
    id
    typeName
    name {
      ...LangStringFrag
    }
    canUpload
    canDelete
    editorType
  }
`;
