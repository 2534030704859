import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { MilestoneGroup } from '../../dto';
import { ReqVars } from './common';
import { languageStringFrag } from './fragments';

type Variables = { milestoneGroupId: number };
type Results = { milestoneGroup: MilestoneGroup };

const query = gql`
  ${languageStringFrag}
  query getMilestoneGroup($milestoneGroupId: Int!) {
    milestoneGroup(id: $milestoneGroupId) {
      id
      name {
        ...LangStringFrag
      }
      subGroups {
        id
        sortIndex
        color
        name {
          ...LangStringFrag
        }
        description {
          ...LangStringFrag
        }
      }
      milestones {
        id
        tasks {
          id
          name {
            ...LangStringFrag
          }
        }
      }
    }
  }
`;

export const useMilestoneGroup = (options: ReqVars<QueryHookOptions<Results, Variables>>) => useQuery<Results, Variables>(query, options);
