import { CircularProgress } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { FormQuestionNames } from 'tuapath-common/generated/schema';
import { SiteContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { useFormAndSubmission } from '../../hooks';
import { getFormQuestions } from '../DynamicForm/common';
import CaseLoadInner from './CaseLoadInner';

export const CaseLoad: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);
  const { loading, data } = useFormAndSubmission({
    fetchPolicy: 'cache-and-network',
    variables: {
      formId: siteCtx.site?.caseLoadFilterForm?.id ?? 0,
      userId: userCtx.user?.id ?? 0
    }
  });

  const defaultFilters = useMemo(() => {
    if (data?.form.elements) {
      const questions = getFormQuestions(data.form);

      const filters: DTO.SubmitFormInput = {
        formId: siteCtx.site?.caseLoadFilterForm?.id ?? 0,
        saveOnly: false,
        answers: questions.map(q => {
          const input: DTO.FormAnswerInput = { questionId: q.id };
          if (q.name === FormQuestionNames.openCases || q.name === FormQuestionNames.closingCases) {
            input.text = 'true';
          } else if (q.questionType === DTO.FormQuestionType.BOOLEAN) {
            input.text = 'false';
          }

          return input;
        })
      };

      return filters;
    }

    return undefined;
  }, [data]);

  return (loading) && defaultFilters == null ?
    <CircularProgress color="primary" /> :
    <CaseLoadInner defaultFilters={defaultFilters} />;
};

export default CaseLoad;
