import React from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Box
} from '@material-ui/core';
import { useClasses } from '../../../hooks';

interface FullscreenLoadingProps {
  text?: string;
}

export const FullscreenLoading: React.FC<FullscreenLoadingProps> = props => {
  const classes = useClasses();

  return (
    <Box position="fixed" top="0" left="0" zIndex="modal" className={classes.fullsreenLoadingModal}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
        {props.text && (
          <Grid item>
            <Typography variant="body1">{props.text}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FullscreenLoading;
