import gql from 'graphql-tag';
import { printFileSchemaFrag } from './printFileSchemaFrag';
import { userMtsActivityTypeFrag } from './userMtsActivityTypeFrag';
import { languageStringFrag } from './languageStringFrag';

export const mtsActivityTypeFrag = gql`
  ${printFileSchemaFrag}
  ${userMtsActivityTypeFrag}
  ${languageStringFrag}

  fragment MTSActivityTypeFrag on MTSActivityType {
    id
    name {
      ...LangStringFrag
    }
    description
    code
    noteRequired
    childGeneratedTypesEnabled
    wpr
    enabled
    hidden
    appearsInReviewAllHours
    mtsInRoadmapEnabled
    formId
    secondaryFormId
    attachmentFormEnabled
    defaultHours
    externalApprovalFormId
    externalApprovalRequired
    reqExternalApprovalFormId
    canEditEntryData
    canEditHours
    maxDailyHours
    maxEntryHours
    maxNumberOfEntries
    generatedChildTypes {
      ...UserMTSActivityTypeFrag
    }
    printFileSchema {
      ...PrintFileSchemaFrag
    }
  }
`;
