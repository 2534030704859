import { Chip, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { History } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useField, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { makeStyles } from '../../common';
import { StudentContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { FormQuestion, FormQuestionType } from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useDialogController, useFocusOnError, useFormQuery } from '../../hooks';
import { LanguageString } from '../Common/LanguageString';
import { helperText } from './HelperText';
import { PropertyHistoryDialog } from './PropertyHistory';
import { FormFieldComp, FormStatus, IValues, getDynamicSelectOptions, getQueryParamsFromFormik, getQuestionFormId, isInvalid, resolveQueryArgTemplate } from './common';

type SelectOption = { value: number | string; label: string };

function getStaticSelectOptions(question: FormQuestion) {
  // TODO: error if 'question' is not a 'static' option type question
  return question.options != null ? question.options.map(opt => ({ label: opt.text, value: opt.id })) : [];
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 'fit-content',
    whiteSpace: 'pre-wrap',
    margin: `${theme.spacing(0.5)}px`,
    paddingTop: `${theme.spacing(0.75)}px`,
    paddingBottom: `${theme.spacing(0.75)}px`
  },
  label: {
    whiteSpace: 'inherit'
  }
}));

const FormSelectInner: React.FC<{ question: FormQuestion; options: SelectOption[] | null }> = ({ question, options }) => {
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const formik = useFormikContext<IValues>();
  const [field, meta] = useField(getQuestionFormId(question));
  const classes = useStyles();
  const { disabled: isDisabled, isReadOnly } = formik.status as FormStatus;
  const disabled = isDisabled || isReadOnly || question.isReadOnly;
  const defaultVal = question.questionType === FormQuestionType.MULTISELECT ? [] : '';
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const histCtl = useDialogController(false);
  const cp = UserHelper.isAdvisorOrAdmin(userCtx.user) ? question.userProperty : undefined;
  const handleHistoryClick = async () => histCtl.controller.setOpen();

  const answer = (formik.status?.ans as DTO.FormSubmissionAnswer[])?.find((a: DTO.FormSubmissionAnswer) => a.question.id === question.id);
  const submittedAns = JSON.stringify(answer?.selections?.map(ans => question.options?.findIndex(opt => opt.id === ans.id)));
  const correctAnswer = formik.status.showCorrect && answer?.correctAnswer;
  const correctOpts = question.answer ? JSON.parse(question.answer) : [];
  const correctText = (Array.isArray(correctOpts) ? question.options?.filter((_, index) => correctOpts.includes(index)) : null)?.map(a => a.text).join();

  useFocusOnError(fieldRef, field.name);

  // HACK: Hide questions that have no options. This is to support hiding/showing questions that allow users to set roles on other users. If the user
  // has no roles they are allowed to set we will hide the question. A better solution would be to add support to the DynamicForm framework for permissions
  // to questions and prevent sending any questions to the client the user doesn't have permission to view/modify.
  return options?.length ? (
    <>
      <TextField
        {...field}
        {...{ value: field.value ? field.value : defaultVal }}
        fullWidth
        disabled={disabled}
        label={<LanguageString languageString={question.text} />}
        SelectProps={{
          multiple: question.questionType === FormQuestionType.MULTISELECT,
          renderValue: Array.isArray(field.value) && field.value?.length ? selected => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {(selected as string[]).map(value => (
                <Chip key={value} label={<div>{options?.find(opt => String(opt.value) == value)?.label ?? 'Not found'}</div>} classes={classes} />
              ))}
            </div>
          ) : undefined,
          endAdornment: cp ? <InputAdornment onClick={handleHistoryClick} style={{cursor: 'pointer'}} position="end"><History /></InputAdornment> : undefined
        }}
      helperText={helperText({ hint: question.hint, meta })}
      error={isInvalid(meta)}
      select
      ref={fieldRef}
    >
      {field.value && <MenuItem key={-1} value={''}><LanguageString groupName="FORM" resourceName="CLEAR_SELECTION" /></MenuItem>}
      {options?.map(opt => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
      </TextField>
      {correctAnswer && correctAnswer !== submittedAns && (
        <Alert style={{ marginTop: 8 }} severity={correctAnswer === submittedAns ? 'success' : 'error'}>
          <LanguageString groupName="FORM" resourceName="CORRECT_ANS_IS" />{correctText}
        </Alert>
      )}
      <PropertyHistoryDialog userId={studentCtx?.student.id} propertyId={cp?.id} ctrl={histCtl} />
    </>
  ) : null;
};

const FormDynamicSelect: FormFieldComp = ({ question }) => {
  const formik = useFormikContext<IValues>();
  const qparams = getQueryParamsFromFormik(formik, question);
  const od = question.optionDefinition;
  const queryName = od?.query ?? 'useAdvisors';
  const { loading, data: dadv, error } = useFormQuery(queryName, qparams, od?.queryArgs != null ? JSON.parse(resolveQueryArgTemplate(od.queryArgs, formik.status)) : undefined);

  const qId = getQuestionFormId(question);
  if (formik.getFieldProps(qId).value === '' && question.defaultValue != null && dadv != null) {
    const hlprs = formik.getFieldHelpers(qId);

    const indexRslt = question.defaultValue.match(/^:(\d+|first|last):$/);
    if (indexRslt) {
      const defaultIndex = indexRslt[1];
      const options = getDynamicSelectOptions(question, dadv);
      const rslt = Number(defaultIndex);
      if (Number.isInteger(rslt) && options?.length) {
        if (options.length > rslt) hlprs.setValue(options[rslt]?.value);
      } else {
        switch (defaultIndex) {
          case 'first':
            hlprs.setValue(options?.[0]?.value);
            break;
          case 'last':
            hlprs.setValue(options?.[options.length - 1]?.value);
            break;
        }
      }
    }
  }

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  return dadv != null ? <FormSelectInner question={question} options={getDynamicSelectOptions(question, dadv)} /> : <div>'No Options'</div>;
};

const FormStaticSelect: FormFieldComp = ({ question }) => <FormSelectInner question={question} options={getStaticSelectOptions(question)} />;

export const FormSelect: FormFieldComp = props => props.question.optionDefinition ? <FormDynamicSelect {...props} /> : <FormStaticSelect {...props} />;
