import { Box, Button, Card, CardHeader, CircularProgress, Grid, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { ChildRoutes, SiteContext, StudentContext, UserContext } from '../../contexts';
import { Milestone, MilestoneGroup, Roadmap, RoadmapMilestone, RoadmapMilestoneGroup, RoadmapMilestoneState, Role, SuccessPath, User } from '../../dto';
import UserHelper, { MilestoneState } from '../../helpers/userHelper';
import { useDialogController, useUpdateRoadmapMilestoneGroups, useUpdateRoadmapMilestones } from '../../hooks';
import useRoadmap from '../../hooks/gql/useRoadmap';
import { EmailDialog } from '../Common';
import { LanguageString } from '../Common/LanguageString';
import { MilestoneList, MsGroupBtns } from '../MsGroup/MilestoneList';
import { FinalizeRoadmap } from './FinalizeRoadmap';
import { Goal } from './Goal';
import MTSInRoadmap from './MTSInRoadmap';
import RoadmapPrintDialog from './RoadmapPrintDialog';

type MilestoneGroupMap = {
  [groupId: number]: MilestoneGroup;
};

function addGroupsToGroupMap(grpMap: MilestoneGroupMap, msGroups: MilestoneGroup[]) {
  for (const grp of msGroups) {
    grpMap[grp.id] = grp;
    if (grp.subGroups != null) addGroupsToGroupMap(grpMap, grp.subGroups);
  }
}

function buildMsGroupMap(path: SuccessPath) {
  const msGroupPathMap: MilestoneGroupMap = {};
  if (path.milestoneGroups) addGroupsToGroupMap(msGroupPathMap, path.milestoneGroups);

  return msGroupPathMap;
}

function addGroupsToParentGroupMap(grpMap: MilestoneGroupMap, msGroup: MilestoneGroup) {
  if (msGroup.subGroups) {
    for (const child of msGroup.subGroups) {
      grpMap[child.id] = msGroup;
      if (child.subGroups) addGroupsToParentGroupMap(grpMap, child);
    }
  }
}

function buildParentMsGroupMap(path: SuccessPath) {
  const msGroupPathMap: MilestoneGroupMap = {};
  if (path.milestoneGroups) {
    for (const group of path.milestoneGroups) {
      addGroupsToParentGroupMap(msGroupPathMap, group);
    }
  }

  return msGroupPathMap;
}

function sort(grps: RoadmapMilestoneGroup[]) {
  return grps.slice().sort((g1, g2) => (g1.sortIndex ?? 0) - (g2.sortIndex ?? 0));
}

type GrpHeaderProps = {
  msg: RoadmapMilestoneGroup;
  msGrp: MilestoneGroup;
  msGrpMap: MilestoneGroupMap;
  dragProvided: DraggableProvided;
};

const MilestoneGroupHeader: React.FC<GrpHeaderProps> = ({ msg, msGrp, msGrpMap, dragProvided }) => {
  const dlgCtrl = useDialogController(false);

  let path = `${msGrp.name?.text ?? ''}`;
  let parent = msGrp;

  while ((parent = msGrpMap[parent.id])) path = `${parent.name?.text ?? ''} / ${path}`;

  return (
    <CardHeader
      avatar={
        <Tooltip title={path}>
          <Icon>info_outline</Icon>
        </Tooltip>
      }
      action={
        <Grid container alignItems="center">
          <IconButton color="inherit" onClick={() => dlgCtrl.controller.setOpen()}>
            <Icon>edit</Icon>
          </IconButton>
          <Icon {...dragProvided.dragHandleProps}>drag_indicator</Icon>
        </Grid>
      }
      title={<Goal goalItem={msg} lsData={msGrp.name} dlgCtrl={dlgCtrl} gp="goal" tp="translation" placeholder="GROUP_GOAL" />}
      style={{ backgroundColor: msGrp.color }}
    />
  );
};

const FinalizeSection: React.FC<{ roadmap: Roadmap }> = ({ roadmap }) => {
  const { controller, props } = useDialogController(false);

  return (
    <Grid item>
      <Button onClick={() => controller.setOpen()}>Finalize</Button>
      {props.open && <FinalizeRoadmap roadmap={roadmap} dlgCtrl={{ controller, props }} />}
    </Grid>
  );
};

const UserRoadmap: React.FC<{ student: User; user: User; path: SuccessPath }> = React.memo(({ student, user, path }) => {
  const siteCtx = useContext(SiteContext);
  const { data } = useRoadmap({ variables: { userId: student.id }, fetchPolicy: 'cache-first' });
  const [updateGroupIndecies] = useUpdateRoadmapMilestoneGroups();
  const [updateRmMilestones] = useUpdateRoadmapMilestones();
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const showAdvisorItems = user.id !== student.id && user.roles?.includes(Role.ADVISOR);
  const { taskDetailParam } = useParams<{ taskDetailParam?: string }>();
  const taskDetail = taskDetailParam != null;

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      console.log(`DragEnd`);
      const msGroups = data?.roadmap.milestoneGroups?.slice().sort((g1, g2) => (g1.sortIndex ?? 0) - (g2.sortIndex ?? 0)).map(mg => ({id: mg.id, goal: mg.goal, sortIndex: mg.sortIndex}));
      console.log(`groups`, msGroups);
      if (msGroups == null) return;

      if (result.reason === 'DROP' && result.destination != null) {
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        const dir = Math.sign(destIndex - sourceIndex);
        const [start, end] = dir > 0 ? [sourceIndex, destIndex] : [destIndex, sourceIndex];
        const rmsGrps = sort(msGroups)
          .slice(start, end + 1)
          .map(rmg => ({ id: rmg.id, goal: rmg.goal, sortIndex: rmg.sortIndex }));
        const minIndex = Math.min(sourceIndex, destIndex);
        rmsGrps[sourceIndex - minIndex].sortIndex = msGroups[destIndex].sortIndex;
        for (let index = destIndex; index !== sourceIndex; index -= dir) {
          rmsGrps[index - minIndex].sortIndex = (msGroups[index].sortIndex ?? 0) - dir;
        }

        console.log(`Calling update`);

        void updateGroupIndecies({
          variables: { input: rmsGrps },
          optimisticResponse: { updateRoadmapMilestoneGroups: rmsGrps.map(grp => ({ ...grp, __typename: 'RoadmapMilestoneGroup' })) }
        });

        console.log(`Finished update`);
      }
    },
    [data, data?.roadmap, updateGroupIndecies]
  );

  const toggleEmailDialog = () => setEmailModalOpen(!emailModalOpen);

  const handleUpdateRmMsClick = async (rms: RoadmapMilestone, status: RoadmapMilestoneState) => {
    await updateRmMilestones({ variables: { input: [{ id: rms.id, status }] } });
  };

  const togglePrintModal = () => {
    setPrintModalOpen(!printModalOpen);
  };

  const rmButtons = (ms: Milestone, rmg: RoadmapMilestoneGroup): (rmMsId: number | undefined) => React.ReactNode => (rmMsId: number | undefined) => {
    if (!showAdvisorItems) return null;

    if (rmMsId == null) return <CircularProgress />;

    const rmMs = rmg.milestones?.find(rms => rms.id === rmMsId);
    const msStatus = UserHelper.milestoneStatus(student, ms.id, rmMs?.id);
    if (rmMs != null) {
      if (rmMs.status == null) {
        rmMs.status =
          msStatus === MilestoneState.COMPLETE
            ? RoadmapMilestoneState.COMPLETED
            : msStatus === MilestoneState.STARTED
              ? RoadmapMilestoneState.CONTINUE
              : RoadmapMilestoneState.DEFERRED;
      }

      return <>
        <Tooltip key="completed" title={<LanguageString groupName="ROADMAP" resourceName="COMPLETED" defaultText="Completed" />}>
          <IconButton edge="end" onClick={() => handleUpdateRmMsClick(rmMs, RoadmapMilestoneState.COMPLETED)}>
            <Icon style={rmMs.status === RoadmapMilestoneState.COMPLETED ? { color: 'green' } : undefined}>thumb_up</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip key="continue" title={<LanguageString groupName="ROADMAP" resourceName="CONTINUE" defaultText="Continue" />}>
          <IconButton edge="end" onClick={() => handleUpdateRmMsClick(rmMs, RoadmapMilestoneState.CONTINUE)}>
            <Icon style={rmMs.status === RoadmapMilestoneState.CONTINUE ? { color: '#f1c40f' } : undefined}>play_arrow</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip key="deferred" title={<LanguageString groupName="ROADMAP" resourceName="DEFERRED" defaultText="Deferred" />}>
          <IconButton edge="end" onClick={() => handleUpdateRmMsClick(rmMs, RoadmapMilestoneState.DEFERRED)}>
            <Icon style={rmMs.status === RoadmapMilestoneState.DEFERRED ? { color: 'red' } : undefined}>stop</Icon>
          </IconButton>
        </Tooltip>
      </>;
    } else {
      return [];
    }
  };

  const rmButtonsGen = (ms: Milestone, rmg: RoadmapMilestoneGroup) => {
    return rmButtons(ms, rmg);
  };

  if (data?.roadmap && data.roadmap.milestoneGroups) {
    const roadmap = data.roadmap;
    const msGroupMap = buildMsGroupMap(path);
    const parentGroupMap = buildParentMsGroupMap(path);

    return (
      <>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="roadmap-list">
            {(provided, snapshot) => (
              <Grid container ref={provided.innerRef} direction="column" className="roadmap" spacing={2}>
                <Grid item xs={12}>
                  <Box bgcolor="primary.light" color="primary.contrastText">
                    <Toolbar>
                      <Typography style={{ flexGrow: 1 }}>
                        <LanguageString groupName="ROADMAP" resourceName="ROADMAP" />
                      </Typography>
                      <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="EMAIL" defaultText="Send an Email" />}>
                        <IconButton onClick={toggleEmailDialog} color="inherit">
                          <Icon>email</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="PRINT" defaultText="Print" />}>
                        <IconButton onClick={togglePrintModal} color="inherit" edge="end">
                          <Icon>print</Icon>
                        </IconButton>
                      </Tooltip>
                    </Toolbar>
                  </Box>
                </Grid>
                {sort(roadmap.milestoneGroups ?? []).map((msg, index) => {
                  const tmpGrp = msGroupMap[msg.milestoneGroupId ?? 0];
                  if (!tmpGrp) return null;

                  const msGrp: MsGroupBtns = {
                    ...tmpGrp,
                    milestones: tmpGrp.milestones?.map(ms => ({
                      ...ms,
                      buttons: rmButtonsGen(ms, msg)
                    })),
                    filter: msg.milestones?.map(ms => ms.milestoneId)
                  };
                  msGrp.assignments = student.assignments?.assignments?.filter(a => msGrp.milestones?.findIndex(
                    ms => ms.id === a.milestoneId &&
                    msg.milestones?.findIndex(rms => rms.id === a.roadmapMilestoneId) !== -1) !== -1);
                  const msGrpId = msg.milestoneGroupId ?? 0;

                  return (
                    <Draggable key={msGrpId} draggableId={String(msGrpId)} index={index}>
                      {(dragProvided, dragSnapshot) => (
                        <Grid key={msGrpId} item xs={12} {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
                          <Card>
                            <MilestoneGroupHeader msGrp={msGrp} msGrpMap={parentGroupMap} msg={msg} dragProvided={dragProvided} />
                            {msGrp && <MilestoneList group={msGrp} inPathOnly={true} taskDetail={taskDetail} />}
                          </Card>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {siteCtx.site?.mtsEnabled && siteCtx.site?.mtsInRoadmapEnabled && <MTSInRoadmap />}
                {showAdvisorItems && <FinalizeSection roadmap={roadmap} />}
                <ChildRoutes />
                {provided.placeholder}

                <EmailDialog open={emailModalOpen} toggle={toggleEmailDialog} />
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
        <RoadmapPrintDialog open={printModalOpen} toggle={togglePrintModal} />
        <ChildRoutes />
      </>
    );
  }

  return <div>UserRoadmap</div>;
});

export const RoadmapView: React.FC = () => {
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);

  return studentCtx?.student && studentCtx.path && userCtx.user ? (
    <UserRoadmap student={studentCtx.student} user={userCtx.user} path={studentCtx.path} />
  ) : (
    <CircularProgress color="primary" />
  );
};

export default RoadmapView;
