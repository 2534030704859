import { gql, useMutation } from '@apollo/client';
import { ResetPasswordInput, ResetPasswordResult } from 'tuapath-common/generated/schema';

export function useResetPassword() {
  const rpMutation = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        success
        reason
      }
    }
  `;

  return useMutation<{ resetPassword: ResetPasswordResult }, { input: ResetPasswordInput }>(rpMutation);
}
