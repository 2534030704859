import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { MilestoneGroup } from 'tuapath-common/generated/schema';
import { LanguageString } from '..';
import { ChildRoutes, SiteContext, StudentContext, useChildLinks } from '../../contexts';
import { useAddMsGroupToPath, useDialogController } from '../../hooks';
import { MessageDialog } from '../Common';
import { MsGroupContext } from '../MsGroupProvider';
import { MilestoneList } from './MilestoneList';

const SubGroups: React.FC<{ group: MilestoneGroup }> = ({ group }) => {
  const match = useRouteMatch();
  const siteCtx = useContext(SiteContext);
  const stdCtx = useContext(StudentContext);
  const cnfrmDlgCtrl = useDialogController(false);
  const sl2 = useChildLinks(
    group.subGroups
      ? {
          items: group.subGroups,
          paramMap: { id: 'groupId' },
          routeName: 'ms-group'
        }
      : null
  );
  const [addMsGroup] = useAddMsGroupToPath();
  const vars: Parameters<typeof addMsGroup>[0] = { variables: { input: { userId: stdCtx?.student.id ?? -1, milestoneGroupId: group.id } } };

  return group ? (
    <>
      <Grid container item spacing={2}>
        {match?.isExact && sl2 &&
          sl2.map(sl => (
            <Grid key={sl.path} item className="ms-group">
              <Card style={{ backgroundColor: sl.item.color }}>
                <CardActionArea component={RouterLink} to={sl.path}>
                  <CardContent>
                    <Box>
                      <Typography align="center" style={{ margin: 'auto' }} variant="h5">
                        <LanguageString languageString={sl.item.name} />
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        {group.milestones && <MilestoneList group={group} />}
        <ChildRoutes />
      </Grid>
      {siteCtx.site?.roadmapEnabled && match?.isExact && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => cnfrmDlgCtrl.controller.setOpen()}>
            <LanguageString groupName="SITE_CONTENT" resourceName="ADD_ALL_MS" />
          </Button>
          <MessageDialog
            message={<LanguageString groupName="SITE_CONTENT" resourceName="CONFIRM_ADD_ALL" />}
            confirmMessage={<LanguageString groupName="SITE_CONTENT" resourceName="YES" />}
            cancelMessage={<LanguageString groupName="SITE_CONTENT" resourceName="NO" />}
            onConfirm={() => addMsGroup(vars)}
            ctrl={cnfrmDlgCtrl}
          />
        </Grid>
      )}
    </>
  ) : null;
};

export const MsGroup: React.FC = () => {
  const group = useContext(MsGroupContext);

  return group != null ? <SubGroups group={group} /> : null;
};

export default MsGroup;
