import gql from 'graphql-tag';

export const userInfoFrag = gql`
  fragment UserInfoFrag on UserInfo {
    id
    username
    firstName
    lastName
    email
    phoneNumber
  }
`;
