import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { useStudentsNotification } from '../../hooks';
import { User, SortDirection } from '../../dto';
import * as DTO from 'tuapath-common/generated/schema';
import { useLocalStorage, LocalStorageKey } from '../../hooks';

interface BlastNotificationsContextProps {
  loading: boolean;
  advisorIds: number[];
  setAdvisorIds: (ids: number[]) => void;
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;
  search?: string;
  setSearch: (search: string) => void;
  order?: DTO.StudentsOrder;
  setOrder: (ordrer: DTO.StudentsOrder) => void;
  filters?: DTO.SubmitFormInput;
  setFilters: (filters: DTO.SubmitFormInput) => void;
  totalCount: number;
  filteredStudents: User[];
  openStudents: User[];
  selectedStudent?: User;
}

export const BlastNotificationsContext = React.createContext<BlastNotificationsContextProps | undefined>(undefined);

export const BlastNotificationsProvider: React.FC = ({ children }) => {

  const studentsMatch = useRouteMatch<{ id?: string; studentId?: string; }>(`/students/:id([^\/a-zA-Z]+)*/(student)?/:studentId?`);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<DTO.StudentsOrder>({
    orderBy: 'lastName',
    sortDirection: SortDirection.ASC,
    sortType: DTO.StudentSortType.StudentKey
  });
  const [savedFilters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.StudentsFilters, {});
  const [filters, setFilter] = useState<DTO.SubmitFormInput | undefined>(undefined);

  if (!filters && Object.keys(savedFilters).length > 0) {
    setFilter(Object.keys(savedFilters).length > 0 ? (savedFilters as DTO.SubmitFormInput) : undefined);
  }

  const [advisorIds, setAdvisorIds] = useState<number[]>([]);
  const _setAdvisorIds = useCallback((ids: number[]) => {
    setAdvisorIds(ids);
  }, []);
  const _setLimit = useCallback((l: number) => {
    setLimit(l);
  }, []);
  const _setOffset = useCallback((o: number) => {
    setOffset(o);
  }, []);
  const _setSearch = useCallback((s: string) => {
    setSearch(s);
  }, []);
  const _setOrder = useCallback((o: DTO.StudentsOrder) => {
    setOrder(o);
  }, []);
  const _setFilters = useCallback((o: DTO.SubmitFormInput) => {
    setFilter(o);
  }, []);

  const getSelectedStudentId = () => {
    let student: number | undefined;

    if (studentsMatch?.params?.studentId && studentsMatch?.params.studentId.length > 0) {
      return parseInt(studentsMatch?.params.studentId, 0);
    }

    return student;
  };

  const findSelectedStudent = (passedStudents: User[]) => {
    const id = getSelectedStudentId();

    if (id) {
      for (const student of passedStudents) {
        if (student.id === id) {
          return student;
        }
      }
    }

    return undefined;
  };

  const { loading, data, error } = useStudentsNotification({
    variables: {
      advisorIds: advisorIds.length > 0 ? advisorIds : undefined,
      limit: limit,
      offset: offset,
      search: search.length > 0 ? search : undefined,
      order: order,
      filter: filters
    },
    fetchPolicy: 'cache-and-network'
  });

  if (error) {
    console.log(`failed to get students with error: ${error}`);
  }

  let totalCount = 0;
  let filteredStudents: User[] = [];

  if (data?.students.students) {
    totalCount = data.students.totalCount;
    filteredStudents = data.students.students;
  } else {
    totalCount = 0;
    filteredStudents = [];
  }

  const openStudents = data?.studentsWithId?.slice() ?? [];
  const allStudents = filteredStudents.concat(openStudents);
  const selectedStudent = findSelectedStudent(allStudents);

  return (
    <BlastNotificationsContext.Provider
      value={{
        loading: loading,
        advisorIds: advisorIds,
        setAdvisorIds: _setAdvisorIds,
        limit: limit,
        setLimit: _setLimit,
        offset: offset,
        setOffset: _setOffset,
        search: search,
        setSearch: _setSearch,
        order: order,
        setOrder: _setOrder,
        filters: filters,
        setFilters: _setFilters,
        totalCount: totalCount,
        filteredStudents: filteredStudents,
        openStudents: openStudents,
        selectedStudent: selectedStudent
      }}
    >
      {children}
    </BlastNotificationsContext.Provider>
  );
};

export default BlastNotificationsProvider;
