import gql from 'graphql-tag';
import { languageStringFrag } from './';

export const notificationTemplateFrag = gql`
  ${languageStringFrag}

  fragment NotificationTemplateFrag on NotificationTemplate {
    id
    name
    replyTo
    message {
      ...LangStringFrag
    }
    messagePlainText {
      ...LangStringFrag
    }
    title {
      ...LangStringFrag
    }
    shouldEmail
    shouldSMS
    showInUI
    shouldCreateROC
    blastEmail
    blastSMS
  }
`;
