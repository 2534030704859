import React, { useState, useContext, useMemo, useRef, useCallback } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  Icon,
  TablePagination,
  Grid,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  CircularProgress
} from '@material-ui/core';
import { LanguageString } from '../Common';
import { CaseLoadFilterDialog } from './CaseLoadFilterDialog/CaseLoadFilterDialog';
import { StudentSortType } from '../../dto';
import { useLocalStorage, LocalStorageKey, useCaseLoadMTSPeriods } from '../../hooks';
import { UserContext, SiteContext } from '../../contexts';
import { SortDirection } from 'tuapath-common/generated/schema';
import * as DTO from '../../dto';
import { CaseLoadOrder, CaseLoadOrderColumn } from './CaseLoadCells';
import { CaseLoadData } from './CaseLoadData';
import { CaseLoadOrderData } from '../../types';
import { getMonth, getYear, subMonths } from 'date-fns';

const currentDate = new Date();

const SearchInput: React.FC<{changeSearchTextOnTimeout: (val: string) => void, changeSearchTextNow: (val: string | undefined) => void}> = ({changeSearchTextOnTimeout, changeSearchTextNow}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  return <TextField
    label={<LanguageString groupName="GENERAL" resourceName="CASE_LOAD_SEARCH" defaultText="Search" />}
    value={searchText}
    onKeyPress={(event) => { if (event.key === 'Enter') changeSearchTextNow(searchText); }}
    onChange={event => {
      setSearchText(event.target.value);
      changeSearchTextOnTimeout(event.target.value);
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Icon>search</Icon>
        </InputAdornment>
      )
    }}
  />;
};

export interface CaseLoadInnerProps {
  defaultFilters: DTO.SubmitFormInput | undefined;
}

export const CaseLoadInner: React.FC<CaseLoadInnerProps> = ({ defaultFilters }) => {
  const siteCtx = useContext(SiteContext);
  const userCtx = useContext(UserContext);

  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [realSearchText, setRealSearchText] = useState<string>();
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [filterAdvisorIds, setFilterAdvisorIds] = useLocalStorage<number[]>(LocalStorageKey.CaseLoadAdvisor, [userCtx.user?.id ?? 0]);
  const [showClosedUsers, setShowClosedUsers] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedPathTab, setSelectedPathTab] = useState<number | undefined>(siteCtx.site?.paths && siteCtx.site.paths.length > 0 ? siteCtx.site.paths[0].id : undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(LocalStorageKey.CaseLoadPagination, rowsPerPageOptions[2]);
  const [filters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.CaseLoadFilters, defaultFilters ?? {});
  const [order, setOrder] = useLocalStorage<CaseLoadOrderData>(LocalStorageKey.CaseLoadOrder, {
    column: CaseLoadOrderColumn.Name,
    order: {
      orderBy: 'lastName',
      sortDirection: SortDirection.ASC,
      sortType: StudentSortType.StudentKey
    }
  });

  const srchTimeout = useRef<NodeJS.Timeout>();

  const changeSearchTextOnTimeout = useCallback((newText: string) => {
    if (srchTimeout.current) clearTimeout(srchTimeout.current);

    srchTimeout.current = setTimeout(() => {
      setRealSearchText(newText);
      srchTimeout.current = undefined;
    }, 500);
  }, [setRealSearchText]);

  const changeSearchTextNow = useCallback((val: string | undefined) => {
    if (srchTimeout.current) clearTimeout(srchTimeout.current);

    setRealSearchText(val);
  }, [setRealSearchText]);

  const path = useMemo(() => siteCtx.site?.paths?.find(p => p.id === Number(selectedPathTab)), [siteCtx, selectedPathTab]);
  const programIds = useMemo(() => path?.programs?.map(p => p.id) ?? [], [path]);
  const periodResults = useCaseLoadMTSPeriods({ variables: { programIds } });

  const currentPeriod = useMemo(() => periodResults.data?.site.caseloadMtsPeriods.currentPeriod, [periodResults.data]);
  const previousPeriod = useMemo(() => periodResults.data?.site.caseloadMtsPeriods.previousPeriod, [periodResults.data]);
  const twoPeriodsAgo = useMemo(() => periodResults.data?.site.caseloadMtsPeriods.twoPeriodsAgo, [periodResults.data]);

  const loading = useMemo(() => {
    if (periodResults.loading) return true;
    if (!selectedPathTab) return true;
    if (siteCtx.site?.mtsPeriodsEnabled === true && !currentPeriod) return true;

    return false;
  }, [selectedPathTab, periodResults.loading, currentPeriod, twoPeriodsAgo, previousPeriod]);

  const toggleFilterDialog = useCallback(() => setFilterDialogOpen(prevState => !prevState), [setFilterDialogOpen]);

  const handleChangePage = useCallback((_: unknown, newPage: number) => setPage(newPage), [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  }, [setRowsPerPage, setPage]);

  const clickedHeader = useCallback((column: CaseLoadOrderColumn, customProperty: string | undefined = undefined) => {
    const o: CaseLoadOrder = {
      column: column,
      order: {
        ...order.order,
        sortDirection: column === order.column ? (order.order.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC) : SortDirection.ASC
      }
    };

    const comp = o.order.orderBy.split('-');
    const mtsComp = comp[comp.length - 1];
    const nextMtsStatus = column === order.column ? (mtsComp === 'NOT_SUBMITTED' ? 'SUBMITTED' : (mtsComp === 'SUBMITTED' ? 'APPROVED' : 'NOT_SUBMITTED')) : 'NOT_SUBMITTED';

    const currentDate = new Date();
    const lastMonth = subMonths(currentDate, 1);
    const twoMonthsAgo = subMonths(currentDate, 2);
    const dates = {
      currentMonth: getMonth(currentDate) + 1,
      currentMonthYear: getYear(currentDate),
      lastMonth: getMonth(lastMonth) + 1,
      lastMonthYear: getYear(lastMonth),
      twoMonthsAgo: getMonth(twoMonthsAgo) + 1,
      twoMonthsAgoYear: getYear(twoMonthsAgo)
    };

    switch (column) {
      case 'Name':
        o.order.sortType = StudentSortType.StudentKey;
        o.order.orderBy = 'lastName';
        break;
      case 'TwoMonthsAgoMTS':
        o.order.sortType = StudentSortType.MTSStatus;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${twoPeriodsAgo?.id}-${dates.twoMonthsAgoYear}-${nextMtsStatus}`;
        } else {
          o.order.orderBy = `${dates.twoMonthsAgo}-${dates.twoMonthsAgoYear}-${nextMtsStatus}`;
        }        break;
      case 'TwoMonthsAgoDocs':
        o.order.sortType = StudentSortType.MTSDocs;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${twoPeriodsAgo?.id}-${dates.twoMonthsAgoYear}`;
        } else {
          o.order.orderBy = `${dates.twoMonthsAgo}-${dates.twoMonthsAgoYear}`;
        }
        break;
      case 'TwoMonthsAgoHours':
        o.order.sortType = StudentSortType.MTSHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${twoPeriodsAgo?.id}-${dates.twoMonthsAgoYear}`;
        } else {
          o.order.orderBy = `${dates.twoMonthsAgo}-${dates.twoMonthsAgoYear}`;
        }
        break;
      case 'TwoMonthsAgoWPRHours':
        o.order.sortType = StudentSortType.MTSWPRHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${twoPeriodsAgo?.id}-${dates.twoMonthsAgoYear}`;
        } else {
          o.order.orderBy = `${dates.twoMonthsAgo}-${dates.twoMonthsAgoYear}`;
        }
        break;
      case 'LastMonthMTS':
        o.order.sortType = StudentSortType.MTSStatus;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${previousPeriod?.id}-${dates.lastMonthYear}-${nextMtsStatus}`;
        } else {
          o.order.orderBy = `${dates.lastMonth}-${dates.lastMonthYear}-${nextMtsStatus}`;
        }
        break;
      case 'LastMonthMTSDocs':
        o.order.sortType = StudentSortType.MTSDocs;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${previousPeriod?.id}-${dates.lastMonthYear}`;
        } else {
          o.order.orderBy = `${dates.lastMonth}-${dates.lastMonthYear}`;
        }
        break;
      case 'LastMonthMTSHours':
        o.order.sortType = StudentSortType.MTSHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${previousPeriod?.id}-${dates.lastMonthYear}`;
        } else {
          o.order.orderBy = `${dates.lastMonth}-${dates.lastMonthYear}`;
        }
        break;
      case 'LastMonthMTSWPRHours':
        o.order.sortType = StudentSortType.MTSWPRHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${previousPeriod?.id}-${dates.lastMonthYear}`;
        } else {
          o.order.orderBy = `${dates.lastMonth}-${dates.lastMonthYear}`;
        }
        break;
      case 'CurrentMTS':
        o.order.sortType = StudentSortType.MTSStatus;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${currentPeriod?.id}-${nextMtsStatus}`;
        } else {
          o.order.orderBy = `${dates.currentMonth}-${dates.currentMonthYear}-${nextMtsStatus}`;
        }
        break;
      case 'CurrentMTSDocs':
        o.order.sortType = StudentSortType.MTSDocs;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${currentPeriod?.id}-${dates.currentMonthYear}`;
        } else {
          o.order.orderBy = `${dates.currentMonth}-${dates.currentMonthYear}`;
        }
        break;
      case 'CurrentMTSHours':
        o.order.sortType = StudentSortType.MTSHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${currentPeriod?.id}-${dates.currentMonthYear}`;
        } else {
          o.order.orderBy = `${dates.currentMonth}-${dates.currentMonthYear}`;
        }
        break;
      case 'CurrentMTSWPRHours':
        o.order.sortType = StudentSortType.MTSWPRHours;
        if (siteCtx.site?.mtsPeriodsEnabled) {
          o.order.orderBy = `${currentPeriod?.id}-${dates.currentMonthYear}`;
        } else {
          o.order.orderBy = `${dates.currentMonth}-${dates.currentMonthYear}`;
        }
        break;
      case 'MTSRequired':
        const mtsFormId = siteCtx.site?.caseLoadMTSRequiredForm?.id.toString();
        o.order.sortType = StudentSortType.DynamicForm;
        o.order.orderBy =  mtsFormId ? `${mtsFormId}-HasTrueAnswer` : '';
        break;
      case 'SupportServices':
        const supportFormId = siteCtx.site?.caseLoadSupportServicesForm?.id.toString();
        o.order.sortType = StudentSortType.DynamicForm;
        o.order.orderBy = supportFormId ? `${supportFormId}-HasTrueAnswer` : '';
        break;
      case 'CoachMeeting':
        o.order.sortType = StudentSortType.CoachMeeting;
        break;
      case 'LastMTSActivity':
        o.order.sortType = StudentSortType.LastMTSActivity;
        break;
      case 'LastRoadmapInteraction':
        o.order.sortType = StudentSortType.LastRoadmapInteraction;
        break;
      case 'Custom':
        o.order.sortType = StudentSortType.CustomProperty;
        o.order.orderBy = customProperty ?? '';
        break;
    }

    setOrder({ ...o });
  }, [order, twoPeriodsAgo, currentDate, siteCtx.site?.caseLoadMTSRequiredForm?.id, siteCtx.site?.caseLoadSupportServicesForm?.id]);

  return (
    <>
      <Grid container justify="center">
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>

      <Card>
        <CardHeader
          title={
            <Grid container direction="row" spacing={4}>
              <Grid item>
                <LanguageString groupName="CASE_LOAD" resourceName="TITLE" defaultText="Case Load" />
              </Grid>
            </Grid>
          }
          action={
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={9}>
                <SearchInput changeSearchTextNow={changeSearchTextNow} changeSearchTextOnTimeout={changeSearchTextOnTimeout} />
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={toggleFilterDialog}>
                  <Icon>filter_list</Icon>
                </IconButton>
              </Grid>
            </Grid>
          }
        />

        {siteCtx.site?.caseLoadPathTabsEnabled && (
          <CardHeader
            title={
              <Tabs value={selectedPathTab} onChange={(e, newVal) => setSelectedPathTab(newVal)} aria-label="Path Tabs" variant="fullWidth">
                {siteCtx.site?.paths.map((path, i) => (
                  <Tab tabIndex={path.id} value={path.id} label={<LanguageString languageString={path.name} />} />
                ))}
              </Tabs>
            }
          />
        )}

        {loading ? (
          <Grid container justify='center' alignContent='center' alignItems='center' spacing={4}>
            <Grid item style={{ margin: 20 }}>
              <CircularProgress size={27} color="primary" />
            </Grid>
          </Grid>
        ): (
          <CaseLoadData
            filterAdvisorIds={filterAdvisorIds}
            realSearchText={realSearchText}
            rowsPerPage={rowsPerPage}
            page={page}
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            twoPeriodsAgo={twoPeriodsAgo}
            selectedPathTab={selectedPathTab ?? 0}
            order={order}
            filters={filters}
            clickedHeader={clickedHeader}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
          />
        )}


        {filterDialogOpen && (
          <CaseLoadFilterDialog
            open={filterDialogOpen}
            toggle={toggleFilterDialog}
            selectedAdvisorIds={filterAdvisorIds}
            changedSelectedAdvisorIds={ids => {
              setFilterAdvisorIds(ids);
            }}
            showClosedUsers={showClosedUsers}
            changedShowClosedUsers={showClosed => {
              setShowClosedUsers(showClosedUsers);
            }}
          />
        )}
      </Card>
      <Grid container justify="center">
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
};

export default CaseLoadInner;
