import { useApolloClient } from '@apollo/client';
import {
  Button,
  ButtonProps,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { DialogTitle, LanguageString, MessageDialog, MsGroupContext } from '..';
import {
  ComponentNotificationContext,
  ComponentNotificationProvider,
  MilestoneContext,
  SiteContext,
  StudentContext,
  UserContext
} from '../../contexts';
import * as DTO from '../../dto';
import { Form as FormDto } from '../../dto';
import { RoadmapHelper } from '../../helpers/roadmapHelpers';
import {
  useAddMilestoneToPath,
  useAssignmentAttachments,
  useAttachFileToAssignment,
  useDialogController,
  useResetCompletedAssignment,
  useUpdateAssignment
} from '../../hooks';
import { query } from '../../hooks/gql/useAssignmentAttachments';
import useDeleteFile from '../../hooks/gql/useDeleteFile';
import { downloadPrintTriggerKey, EmailDialog } from '../Common';
import { DynamicForm } from '../DynamicForm';
import { compare, FileTable, FileTableColumn, FileTableSortColumn, SortDirection } from '../FileBox/FileTable';
import { FormTask } from './FormTask';

type FormContext = {
  buttons?: JSX.Element[];
  setButtons?: (buttons: JSX.Element[]) => void;
  form?: FormDto;
  setForm?: (f?: FormDto) => void;
  onSubmitted?: () => void;
  ref: React.MutableRefObject<DynamicForm | undefined>;
  submitForm: () => void;
};

export const FormContainerContext = React.createContext<FormContext | undefined>(undefined);

export const FormButtons: React.FC = () => {
  const formCtx = useContext(FormContainerContext);

  return formCtx != null ? <>{formCtx.buttons}</> : null;
};

export const FormContainerProvider: React.FC = ({ children }) => {
  const [buttons, setButtons] = useState([] as JSX.Element[]);
  const [form, setForm] = useState<FormDto | undefined>(undefined);
  const ref = useRef<DynamicForm>();
  const submitForm = () => ref != null && ref.current?.submitForm();

  return <FormContainerContext.Provider value={{ buttons, setButtons, submitForm, ref, form, setForm }}>{children}</FormContainerContext.Provider>;
};

type DialogItem = { addButtons: (btns: React.ReactElement<ButtonProps>[]) => void };
export type TaskProps = DialogItem & {
  user: DTO.User;
  student?: DTO.User;
  milestone: DTO.Milestone;
  task: DTO.Task;
  assignment?: DTO.Assignment;
  onComplete?: () => void;
};

const progressUpdatePeriod = 3;
const VideoTask: React.FC<TaskProps> = ({ assignment, task, addButtons }) => {
  const [updateAssignment] = useUpdateAssignment();
  const [progress, setProgress] = useState(assignment?.percentComplete ?? 0);
  const lastDur = useRef(assignment?.durationWatched ?? 0);
  const furthestMark = useRef(assignment?.durationWatched ?? 0);
  const totalDur = useRef(0);
  const player = useRef<ReactPlayer>(null);
  useEffect(() => addButtons([]), [addButtons, task.id]);

  useEffect(() => {
    setProgress(assignment?.percentComplete ?? 0);
    lastDur.current = assignment?.durationWatched ?? 0;
    furthestMark.current = assignment?.durationWatched ?? 0;
    totalDur.current = 0;
  }, [assignment?.id]);

  const handleProgress = useCallback(
    async ({ played, playedSeconds }: { played: number; playedSeconds: number }) => {
      if (playedSeconds > furthestMark.current + 1.10) {
        if (player.current != null) player.current.seekTo(furthestMark.current);
      } else {
        if (playedSeconds > furthestMark.current) furthestMark.current = playedSeconds;
        const newProgress = played * 100;
        if (newProgress > progress) setProgress(newProgress);
        if (assignment == null) return;

        if (playedSeconds - lastDur.current > progressUpdatePeriod) {
          lastDur.current = playedSeconds;
          await updateAssignment({
            variables: { input: { assignmentId: assignment.id, durationWatched: playedSeconds, percentComplete: newProgress } }
          });
        }
      }
    },
    [progress]
  );

  const handleVideoEnd = async () => {
    if (assignment == null || assignment.isComplete) return;

    await updateAssignment({
      variables: { input: { assignmentId: assignment.id, durationWatched: totalDur.current, percentComplete: 100 } }
    });
  };

  return (
    <Grid container justify="center">
      <Grid item>
        <ReactPlayer
          ref={player}
          url={`https://www.youtube.com/watch?v=${task.action}`}
          progressInterval={500}
          controls={true}
          config={{ youtube: { playerVars: { start: Math.floor(lastDur.current), rel: 0, modestbranding: 1 } } }}
          onProgress={handleProgress}
          onDuration={duration => (totalDur.current = duration)}
          onEnded={handleVideoEnd}
        />
        <LinearProgress variant="determinate" value={progress} />
      </Grid>
    </Grid>
  );
};

const LinkTask: React.FC<TaskProps> = ({ assignment, task, onComplete, addButtons }) => {
  const [updateAssignment] = useUpdateAssignment({
    onCompleted: ({ updateAssignment: rtndAssnmt }) => {
      if (rtndAssnmt.isComplete === true && onComplete != null) onComplete();
    }
  });
  useEffect(() => addButtons([
    <Button key={1} onClick={handleLinkClick} href={task.action} {...extraProps}>
      <LanguageString groupName="TASK_ACTION" resourceName="LINK" defaultText="Open Link" />
    </Button>
  ]), [addButtons, task.id]);

  const handleLinkClick = async () => {
    if (assignment == null) return;

    await updateAssignment({
      variables: { input: { assignmentId: assignment.id, percentComplete: 100 } }
    });
  };
  const extraProps = { target: '_blank' };

  return null;
};

const DocumentTask: React.FC<TaskProps> = ({ assignment, user, addButtons, onComplete }) => {
  const [addFile] = useAttachFileToAssignment();
  const [deleteFile] = useDeleteFile();
  const [updateAssignment] = useUpdateAssignment();
  const studentCtx = useContext(StudentContext);
  const { data } = useAssignmentAttachments({
    fetchPolicy: 'cache-first',
    variables: { userId: user.id, assignmentId: assignment?.id }
  });

  const sort = { order: SortDirection.DESC, column: FileTableSortColumn.UPLOADDATE };
  const files = data?.user?.assignment?.attachments ?? [];
  const fileSort = useMemo(() => files.slice().sort((a, b) => compare(a.createdAt, b.createdAt, sort.order)), [files, sort]);

  const handleSubmit = async (file: File, description: string, fileTypeId: number, fileowner: number) => {
    if (assignment == null) return;

    await addFile({
      variables: { assignmentId: assignment.id, file: file },
      refetchQueries: [{ query, variables: { userId: user.id, assignmentId: assignment?.id ?? -1, withAssignment: assignment?.id != null } }]
    });
  };

  const deleteElement = async (attachmentId: number, deleteReason: string) => {
    await deleteFile({
      variables: {
        attachmentId: attachmentId,
        deleteReason: deleteReason,
        allowDeleteSpecialTypes: true
      },
      refetchQueries: [{ query, variables: { userId: user.id, assignmentId: assignment?.id ?? -1, withAssignment: assignment?.id != null } }]
    });
  };

  const handleLinkClick = async () => {
    if (assignment != null && (data?.user?.assignment?.attachments ?? []).length > 0) {
      await updateAssignment({
        variables: { input: { assignmentId: assignment.id, percentComplete: 100 } }
      });
      if (onComplete) {
        onComplete();
      }
    }
  };

  const btnDisabled = assignment == null || assignment.isComplete || (data?.user?.assignment?.attachments ?? []).length < 1;

  useEffect(() =>
    addButtons([
      <Button key={1} disabled={btnDisabled} onClick={handleLinkClick}>
        Submit
      </Button>
    ]), [addButtons, btnDisabled]);

  return data && studentCtx ? (
    <FileTable
      fileList={fileSort}
      fileowner={studentCtx.student.id}
      upload={assignment != null ? handleSubmit : undefined}
      deleteElement={(assignment != null) ? deleteElement : undefined}
      rowShowOverrides={[{ row: FileTableColumn.DESCRIPTION, override: false }, { row: FileTableColumn.FILETYPE, override: false }]}
      sort={sort}
      skipUploadRow
      overrideTypeDeleteLogic
    />
  ) : (
      <div>No data</div>
    );
};

const ReadOnlyTask: React.FC<TaskProps> = ({ assignment, addButtons, onComplete }) => {
  const [updateAssignment, { loading }] = useUpdateAssignment({
    onCompleted: ({ updateAssignment: rtndAssnmt }) => {
      if (rtndAssnmt.isComplete === true && onComplete != null) onComplete();
    }
  });

  const handleLinkClick = async () => {
    if (assignment != null) {
      await updateAssignment({
        variables: { input: { assignmentId: assignment.id, percentComplete: 100 } }
      });
    }
  };

  const btnDisabled = assignment == null || loading || assignment.isComplete;

  useEffect(() =>
    addButtons([
      <Button key={1} disabled={btnDisabled} onClick={handleLinkClick}>
        <LanguageString groupName="MILESTONE" resourceName="MARK_AS_READ" />
      </Button>
    ]), [addButtons, btnDisabled]);

  return null;
};

type TaskViewProps = DialogItem & {
  user: DTO.User;
  student: DTO.User;
  milestone: DTO.Milestone;
  task: DTO.Task;
  rmMsId: number | undefined;
  onComplete?: () => void;
};

// TODO: Move styles to the database
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    TuaAddMilestoneHeading: {
      fontSize: theme.typography.h6.fontSize,
      fontFamily: theme.typography.h6.fontFamily,
      fontWeight: theme.typography.h6.fontWeight,
      fontStyle: theme.typography.h6.fontStyle
    }
  })
);

type AddMilestoneSectionProps = {
  user: DTO.User;
  milestone: DTO.Milestone;
};

const AddMilestoneSection: React.FC<AddMilestoneSectionProps> = ({ user: { id: userId }, milestone }) => {
  const classes = useStyles();
  const msgCtx = useContext(MsGroupContext);
  const [addMsToPath] = useAddMilestoneToPath();
  const [addingMs, setAddingMs] = useState(false);

  const handleAddMilestoneConfirm = async () => {
    await addMsToPath({ variables: { input: { milestoneId: milestone.id, userId, milestoneGroupId: msgCtx?.id } } });
    setAddingMs(false);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <LanguageString className={classes.TuaAddMilestoneHeading} groupName="MILESTONE_VIEW" resourceName="RECOMMENDED" defaultText="Recommended Action Step" />
      </Grid>
      <Grid item>
        <LanguageString groupName="MILESTONE_VIEW" resourceName="ADD_TO_PATH" defaultText="Add this action step to your path?" />
      </Grid>
      <Grid item>
        <Button onClick={() => setAddingMs(true)}>
          <LanguageString groupName="MILESTONE_VIEW" resourceName="ADD_BTN" defaultText="Add" />
        </Button>
      </Grid>
      {addingMs && (
        <MessageDialog
          message={<LanguageString groupName="ROADMAP" resourceName="ADD_MILESTONE" defaultText="Add this milestone to your roadmap?" />}
          onConfirm={() => handleAddMilestoneConfirm()}
        />
      )}
    </Grid>
  );
};

type TaskTextProps = { languageString?: DTO.LanguageString; defaultText: string; resourceName: string };
const TaskText: React.FC<TaskTextProps> = ({ languageString, ...titleProps }) =>
  languageString ? (
    <Grid container item direction="column">
      <Grid item>
        <LanguageString variant="h5" groupName="FORM_CONTENT" {...titleProps} />
      </Grid>
      <Grid item>
        <LanguageString languageString={languageString} />
      </Grid>
    </Grid>
  ) : null;

const TaskView: React.FC<TaskViewProps> = React.memo(({ user, student, milestone, task, onComplete, addButtons, rmMsId }) => {
  const assignment = RoadmapHelper.getAssignment(student, milestone, task, rmMsId);
  const taskProps = { user, student, milestone, task, assignment, addButtons };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          {/* <TaskText languageString={task.summary} resourceName="SUMMARY" defaultText="Summary" /> */}
          <TaskText languageString={task.description} resourceName="DESCRIPTION" defaultText="Description" />
          <TaskText languageString={task.instructions} resourceName="INSTRUCTIONS" defaultText="Instructions" />
        </Grid>
      </Grid>
      <Grid item>
        {task.actionType === DTO.TaskActionType.webForm && <FormTask {...taskProps} formId={Number(task.action)} onComplete={onComplete} />}
        {task.actionType === DTO.TaskActionType.video && <VideoTask {...taskProps} />}
        {task.actionType === DTO.TaskActionType.link && <LinkTask {...taskProps} onComplete={onComplete} />}
        {task.actionType === DTO.TaskActionType.document && <DocumentTask {...taskProps} onComplete={onComplete} />}
        {task.actionType === DTO.TaskActionType.readOnly && <ReadOnlyTask {...taskProps} />}
        {assignment == null && <AddMilestoneSection user={student} milestone={milestone} />}
      </Grid>
    </Grid>
  );
});

type Step = { isOptional: boolean; isComplete: boolean; assignment: DTO.Assignment | undefined };
type StepButtonProps = { steps?: Step[]; index: number; onNext: () => void; onPrevious: () => void };
const StepButtons: React.FC<StepButtonProps> = ({ steps, index, onNext, onPrevious }) => {
  if (steps == null) return null;

  const count = steps.length;

  return count > 1 ? (
    <Fragment>
      {index !== 0 && <Button onClick={onPrevious}>Previous</Button>}
      {index !== count - 1 && (
        <Button disabled={!steps[index].isComplete} onClick={onNext}>
          Next
        </Button>
      )}
    </Fragment>
  ) : null;
};

const DownloadButton: React.FC = () => {
  const formCtx = useContext(FormContainerContext);
  const notificationCtx = useContext(ComponentNotificationContext);

  if (formCtx?.form && formCtx.form.downloadButtonEnabled) {
    return (
      <Tooltip title={<LanguageString groupName="SITE_CONTENT" resourceName="DOWNLOAD" defaultText="Download / Print" />}>
        <IconButton color="primary" onClick={() => notificationCtx.triggerListeners(downloadPrintTriggerKey)}>
          <Icon>cloud_download</Icon>
        </IconButton>
      </Tooltip>
    );
  }

  return null;
};

export const MilestoneView: React.FC = () => {
  const siteCtx = useContext(SiteContext);
  const msCtx = useContext(MilestoneContext);
  const studentCtx = useContext(StudentContext);
  const userCtx = useContext(UserContext);
  const history = useHistory();
  const theme = useTheme();
  const client = useApolloClient();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dlgClosed = useRef(false);
  const handleClose = () => {
    if (dlgClosed.current) return;

    dlgClosed.current = true;
    history.goBack();
  };
  const { props: dProps } = useDialogController(true, handleClose);
  const [taskBtns, setTaskBtns] = useState<React.ReactElement<ButtonProps>[] | undefined>();
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  if (studentCtx == null) throw new Error('MilestoneView must be used in a StudentContext');
  const ms = msCtx.milestone;
  const rmMsId = msCtx.rmMsId;
  const { student } = studentCtx;
  const { user } = userCtx;
  if (user == null) throw new Error('Must be logged in to view milestones');

  if (ms == null) throw new Error('MilestoneView must be used in a MilestoneContext');

  const [currentTaskIndex, setCurrentTaskIndex] = useState(() => {
    let index = 0;
    while (ms.tasks?.[index] && RoadmapHelper.getAssignment(student, ms, ms.tasks[index], rmMsId)?.isComplete === true) index++;
    if (index > (ms.tasks?.length ?? 0) - 1) index--;
    return index;
  });

  const steps: Step[] | undefined = ms.tasks?.map(t => {
    const assignment = RoadmapHelper.getAssignment(student, ms, t, rmMsId);

    return { isComplete: assignment?.isComplete === true, isOptional: ms.required === false, assignment };
  });

  const currentTask = ms.tasks?.[currentTaskIndex];
  const onNext = () => setCurrentTaskIndex(ct => ct + 1);
  const onPrevious = () => setCurrentTaskIndex(ct => ct - 1);
  const handleTaskComplete = () => {
    if (dlgClosed.current) return;

    (currentTaskIndex === (steps?.length ?? 0) - 1 ? history.goBack() : onNext());
  };
  // const handleTaskError = () => {
  //   console.log(`MilestoneView handleTaskError`);
  // };

  const toggleEmailDialog = () => setEmailModalOpen(!emailModalOpen);
  const needsApproval = () => user.id !== student.id && user.roles?.includes(DTO.Role.ADVISOR) && steps?.[currentTaskIndex].assignment?.needsApproval;
  const refreshNeedingUpdateList = (site?: DTO.Site, assignment?: DTO.Assignment) => {
    const { cache } = client;
    if (site != null && assignment != null) {
      const sId = cache.identify(site);
      cache.modify({
        id: sId,
        fields: {
          milestonesAwaitingApproval(value, { readField }) {
            const needingApproval = value.assignments.filter((fa: DTO.Assignment) => readField('id', fa) !== assignment.id);
            return { __typename: 'AssignmentResults', totalCount: needingApproval.length, assignments: needingApproval };
          }
        }
      });
    }
  };

  const [updateAssignment] = useUpdateAssignment({ update: (cache, { data }) => refreshNeedingUpdateList(siteCtx.site, data?.updateAssignment) });
  const [resetAssignment] = useResetCompletedAssignment({
    update: (cache, { data }) => refreshNeedingUpdateList(siteCtx.site, data?.resetCompletedAssignment.assignment)
  });

  const handleApproveClick = async () => {
    const assignment = steps?.[currentTaskIndex].assignment;
    if (assignment != null) await updateAssignment({ variables: { input: { assignmentId: assignment.id, percentComplete: 100 } } });
  };

  const handleUnapproveClick = async () => {
    const assignment = steps?.[currentTaskIndex].assignment;
    if (assignment != null) await resetAssignment({ variables: { input: { assignmentId: assignment.id } } });
  };

  return ms && currentTask ? (
    <FormContainerProvider>
      <ComponentNotificationProvider>
        <Dialog fullWidth={true} maxWidth={'lg'} {...dProps} fullScreen={fullscreen}>
          <DialogTitle onClose={handleClose}>
            <LanguageString languageString={currentTask.name} />
          </DialogTitle>
          <DialogContent dividers>
            <TaskView addButtons={setTaskBtns} user={user} student={student} milestone={ms} task={currentTask} onComplete={handleTaskComplete} rmMsId={rmMsId} />
          </DialogContent>
          <DialogActions>
            <Grid container justify="space-between">
              <Grid item>
                <Tooltip title={<LanguageString groupName="ROADMAP" resourceName="EMAIL" defaultText="Send an Email" />}>
                  <IconButton onClick={toggleEmailDialog} color="inherit">
                    <Icon>email</Icon>
                  </IconButton>
                </Tooltip>

                <DownloadButton />
              </Grid>
              <Grid item>
                {needsApproval() && <Button onClick={handleApproveClick}><LanguageString groupName="MS_APPROVAL" resourceName="APPROVE" /></Button>}
                {needsApproval() && <Button onClick={handleUnapproveClick}><LanguageString groupName="MS_APPROVAL" resourceName="UNAPPROVE" /></Button>}
                <Button onClick={handleClose}>
                  <LanguageString groupName="MILESTONE_VIEW" resourceName="SAVE_AND_CLOSE" defaultText="Close/Save" />
                </Button>
                {taskBtns && <Fragment>{taskBtns}</Fragment>}
                <FormButtons />
                <StepButtons steps={steps} index={currentTaskIndex} onNext={onNext} onPrevious={onPrevious} />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <EmailDialog open={emailModalOpen} toggle={toggleEmailDialog} />
      </ComponentNotificationProvider>
    </FormContainerProvider>
  ) : null;
};
