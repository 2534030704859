import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from 'tuapath-common/generated/schema';
import { QueryType } from '../../helpers/query';

type Variables = { userId: number };
type Results = {
  site: Pick<QueryType<Site>, 'availableRoles'>;
};

const query = gql`
  query getAvailableRoles($userId: Int!) {
    site {
      availableRoles(userId: $userId) {
        id
      }
    }
  }
`;

export const useAvailableRoles = (options?: QueryHookOptions<Results, Variables>) => useQuery<Results, Variables>(query, options);
