import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Site } from '../../dto';

export type SiteImportActionsQuery = { site: Pick<Site, 'id' | 'importActions'> };
export const siteImportActionsQuery = gql`
  query siteImportActions {
    site {
      id
      importActions {
        id
        action
        formId
      }
    }
  }
`;

export function useSiteImportActions(options?: QueryHookOptions<SiteImportActionsQuery>) {
  return useQuery<SiteImportActionsQuery>(siteImportActionsQuery, options);
}

