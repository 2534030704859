import gql from 'graphql-tag';
import { dependsOnFrag } from './dependsOnFrag';
import { languageStringFrag } from './languageStringFrag';

export const formQuestionFrag = gql`
  ${languageStringFrag}
  ${dependsOnFrag}

  fragment FormQuestionFrag2 on FormQuestion {
    id
    name
    sortIndex
    rows
    questionType
    answerType
    isRequired
    displayAnswerAsHTML
    shouldHideIfNull
    isReadOnly
    transientResponse
    encryptResponse
    match
    minLength
    maxLength
    answer
    defaultValue
    minRateLevel
    rateLevels
    orientation
    step
    question {
      id
      options {
        id
        code
        text
      }
      optionDefinition {
        id
        name
        query
        optionsPath
        queryArgs
        value
        label
      }
      queryParams {
        id
        name
        hint
        source {
          id
        }
      }
      lowText {
        ...LangStringFrag
      }
      highText {
        ...LangStringFrag
      }
      text {
        ...LangStringFrag
      }
    }
    hint {
      ...LangStringFrag
    }
    lowText {
      ...LangStringFrag
    }
    highText {
      ...LangStringFrag
    }
    text {
      ...LangStringFrag
    }
    optionType
    options {
      id
      code
      text
    }
    optionDefinition {
      id
      name
      query
      optionsPath
      queryArgs
      value
      label
    }
    queryParams {
      id
      name
      hint
      source {
        id
      }
    }
    userProperty {
      id
      name
    }
    dependsOn {
      ...DependsOnFrag
    }
  }
`;

export default formQuestionFrag;
