import {
  Box, Button, Card, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Grid, Icon, IconButton, Table, TableBody,
  TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { MTSState } from 'tuapath-common/generated/schema';
import { TypedReflect } from '../../../common';
import { MTSContext, SiteContext, StudentContext, useParentLinks, UserContext } from '../../../contexts';
import * as DTO from '../../../dto';
import MTSHelpers, { getTypesNeedingApproval } from '../../../helpers/mtsHelpers';
import UserHelper from '../../../helpers/userHelper';
import { useClasses } from '../../../hooks';
import { GeneralErrorMessage, LanguageString } from '../../Common';
import MTSApproveDialog from './MTSApproveDialog';
import MTSReviewRow from './MTSReviewRow';
import MTSSubmitDialog from './MTSSubmitDialog';

const MTSReviewCard: React.FC<{ mts: DTO.MTS; typesNeedingApproval: ReturnType<typeof getTypesNeedingApproval> }> = ({ mts, typesNeedingApproval }) => {
  const mtsCtx = useContext(MTSContext);
  const classes = useClasses();
  const Wrapper = mtsCtx.mtses.length > 1 ? Card : Box;

  const dayColumns: Date[] = MTSHelpers.dayColumnsForMTSEntries(mts);
  const activitesGrouped: Array<Array<DTO.MTSActivity>> = MTSHelpers.activitiesGrouped(mts);
  const totalHours = MTSHelpers.totalHoursForMTS(mts);

  const titleForMTS = useMemo(() => {
    if (mts) {
      if (mts?.mtsPeriod) {
        return `${format(MTSHelpers.dateFromString(mts.mtsPeriod.startDate), 'MM/dd/yyyy')} - ${format(MTSHelpers.dateFromString(mts.mtsPeriod.endDate), 'MM/dd/yyyy')}`;
      } else {
        return `${mts.month}/${mts.year}`;
      }
    }

    return '';
  }, [mts]);

  const hasTypesNeedingApproval = typesNeedingApproval
    ? TypedReflect.ownKeys(typesNeedingApproval)
      .some(tna => TypedReflect.ownKeys(typesNeedingApproval[tna])
        .some(ap => typesNeedingApproval[tna][ap].length > 0)) === true
    : false;

  return (
    <Wrapper style={{ marginBottom: mtsCtx.mtses.length > 1 ? 40 : 0 }}>
      {mtsCtx.mtses.length > 1 && (
        <Box padding={2}>
          <Typography variant="subtitle1" color="textSecondary">
            <Typography variant="subtitle2" color="textPrimary">
              <LanguageString groupName="MTS" resourceName="MTS" defaultText="MTS" />
            </Typography>
            {titleForMTS}
          </Typography>
        </Box>
      )}
      <Table className={clsx([classes.mtsReviewHoursTable, classes.stripedTable])}>
        <TableHead>
          <TableRow>
            {hasTypesNeedingApproval && <TableCell>Approval</TableCell>}
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_ACTIVITY" defaultText="Activity" />
            </TableCell>
            {dayColumns.map(date => {
              return (
                <TableCell key={`day_cell_${date}`}>
                  {format(date, 'do')}
                </TableCell>
              );
            })}
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_TOTAL" defaultText="Total" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activitesGrouped.map((activity, index) => {
            return <MTSReviewRow key={`mts_review_row_${index}`} days={dayColumns} activities={activity} mts={mts} hasTypesNeedingApproval={hasTypesNeedingApproval} />;
          })}

          <TableRow>
            <TableCell>
              <LanguageString groupName="MTS" resourceName="REVIEW_HOURS_TOTAL" defaultText="Total" />
            </TableCell>
            {hasTypesNeedingApproval && <TableCell></TableCell>}
            {dayColumns.map(day => {
              return (
                <TableCell key={`last_row_${day}`} />
              );
            })}
            <TableCell>
              {totalHours}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  );
};

interface MTSDocumentsDialogProps {
  open: boolean;
  toggle: () => void;
}

export const MTSReviewHoursDialog: React.FC<MTSDocumentsDialogProps> = props => {

  const siteCtx = useContext(SiteContext);
  const mtsCtx = useContext(MTSContext);
  const userCtx = useContext(UserContext);
  const studentCtx = useContext(StudentContext);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dateHeader = useMemo(() => {
    if (mtsCtx.selectedMTS?.mtsPeriod) {
      const mtsDates = MTSHelpers.getPeriodDates(mtsCtx.selectedMTS.mtsPeriod);

      return `${format(mtsDates.startDate, 'MMM dd yyyy')} - ${format(mtsDates.endDate, 'MMM dd yyyy')}`;
    } else if (mtsCtx.selectedMTS) {
      const mtsMinDate = new Date(mtsCtx.selectedMTS.year, mtsCtx.selectedMTS.month - 1, 1, 0, 0, 0);
      return format(mtsMinDate, 'MMM yyyy');
    }

    return '';
  }, [mtsCtx.selectedMTS]);

  const reviewCompletedMTSLink = useParentLinks('mts', {
    items: mtsCtx.selectedMTS ? [{ id: mtsCtx.selectedMTS.id }] : [{id: 0}],
    paramMap: { id: 'mtsId' },
    routeName: 'mts-completed'
  });

  const toggleSubmitModal = () => {
    setSubmitModalOpen(!submitModalOpen);
  };

  const toggleApproveModal = () => {
    setApproveModalOpen(!approveModalOpen);
  };

  const canSeeReviewMTS = useMemo(() => {
    if (userCtx.user && siteCtx.site && mtsCtx.selectedMTS) {
      return MTSHelpers.canReviewMTS(userCtx.user, mtsCtx.selectedMTS);
    }

    return false;
  }, [userCtx.user, mtsCtx.selectedMTS]);

  const canSeeApproveMTS = useMemo(() => {
    if (userCtx.user && siteCtx.site && mtsCtx.selectedMTS) {
      return MTSHelpers.canApproveMTS(userCtx.user, siteCtx.site, mtsCtx.selectedMTS, mtsCtx.mtsPeriods);
    }

    return false;
  }, [userCtx.user, mtsCtx.selectedMTS]);

  const canSeeSubmitMTS = useMemo(() => {
    if (mtsCtx.mtses.length > 1) return false;

    if (mtsCtx.selectedMTS && siteCtx.site) {
      return MTSHelpers.canSubmitMTS(userCtx.user, siteCtx.site, mtsCtx.selectedMTS, mtsCtx.mtsPeriods);
    }

    return false;
  }, [mtsCtx.selectedMTS]);

  const typesNeedingApproval = mtsCtx.selectedMTS ? getTypesNeedingApproval(mtsCtx.selectedMTS) : undefined;

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      fullScreen={fullscreen}
      open={props.open}
      onClose={props.toggle}
      aria-labelledby="Signup Form"
      scroll="paper"
    >
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <LanguageString groupName="MTS" resourceName="REVIEWS_HOUR_TITLE" defaultText="Review Hours" />

            <Typography variant="body2">
              {studentCtx?.student.firstName ?? ''} {studentCtx?.student.lastName ?? ''}, {studentCtx?.student ?
                UserHelper.customProperty(studentCtx?.student, DTO.CustomPropertyNames.caseIDNum) : ''}
            </Typography>
            {mtsCtx.mtses.length <= 1 && (
              <Typography variant="body2">{dateHeader}</Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={props.toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {mtsCtx.mtses.map(mts => (
          <MTSReviewCard key={`mts_review_card_${mts.id}`} mts={mts} typesNeedingApproval={typesNeedingApproval} />
        ))}

        {mtsCtx.mtses.length <= 0 && (
          <Grid container>
            <Grid item xs>
              <GeneralErrorMessage message="You must have entries before you can review an MTS" />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          {mtsCtx.selectedMTS && mtsCtx.mtses.length <= 1 && (
            <Grid item>
              <Button
                href={reviewCompletedMTSLink && reviewCompletedMTSLink.length > 0 ? reviewCompletedMTSLink[0].path : ''}
                target="_blank"
                color="primary"
                variant="text"
              >
                <LanguageString groupName="MTS" resourceName="REVIEW_ALL_INFO_BUTTON" defaultText="Review All MTS Info" />
              </Button>
            </Grid>
          )}

          <Grid item>
            {mtsCtx.selectedMTS?.state === MTSState.NOT_SUBMITTED && canSeeSubmitMTS && (
              <Button onClick={toggleSubmitModal} color="primary" variant="contained">
                <LanguageString groupName="MTS" resourceName="SUBMIT_MTS" defaultText="Submit MTS" />
              </Button>
            )}
            {mtsCtx.selectedMTS?.state === MTSState.SUBMITTED && canSeeApproveMTS && (
              <Button onClick={toggleApproveModal} color="primary" variant="contained">
                <LanguageString groupName="MTS" resourceName="Approve_MTS" defaultText="Approve MTS" />
              </Button>
            )}
            {siteCtx.site?.mtsReviewEnabled && mtsCtx.selectedMTS?.state === MTSState.APPROVED && canSeeReviewMTS && (
              <Button onClick={toggleApproveModal} color="primary" variant="contained">
                <LanguageString groupName="MTS" resourceName="Review_MTS" defaultText="Review MTS" />
              </Button>
            )}
          </Grid>
        </Grid>

      </DialogActions>

      <MTSSubmitDialog
        open={submitModalOpen}
        toggle={toggleSubmitModal}
        mts={mtsCtx.selectedMTS}
        typesNeedingApproval={typesNeedingApproval}
      />
      <MTSApproveDialog
        open={approveModalOpen}
        toggle={toggleApproveModal}
        mts={mtsCtx.selectedMTS}
        reviewingMTS={mtsCtx.selectedMTS?.state === MTSState.APPROVED ? true : false}
      />
    </Dialog>
  );
};

export default MTSReviewHoursDialog;
