import gql from 'graphql-tag';
import { dependsOnFrag } from './dependsOnFrag';
import { formQuestionFrag } from './formQuestionFrag';
import { languageStringFrag } from './languageStringFrag';

export const formElementFrag = gql`
  ${languageStringFrag}
  ${formQuestionFrag}
  ${dependsOnFrag}

  fragment FormElementFieldFrag2 on FormElement {
    id
    name
    sortIndex
    showQuestionNumbers
    resetNumbers
    title {
      ...LangStringFrag
    }
    instructions {
      ...LangStringFrag
    }
    layoutType
    questions {
      ...FormQuestionFrag2
    }
    dependsOn {
      ...DependsOnFrag
    }
  }
`;

export default formElementFrag;
