import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Icon, makeStyles, Typography } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import React, { MutableRefObject, useContext, useMemo, useRef, useState } from 'react';
import { TypedReflect } from '../../common';
import * as Data from '../../common/pathDtoTypes';
import * as Edit from '../../common/pathEditTypes';
import * as DTO from '../../dto';
import { useAddOrRemoveMilestone, useAddOrRemoveSubGroups, useDialogController, useSitePaths, useUpdateMilestone, useUpdateMilestoneGroup, useUpdatePath } from '../../hooks';
import { Callout, DialogTitle, IconButton, LanguageString } from '../Common';
import { MilestoneEditorForm } from '../MilestoneEditor/MilestoneEditorForm';
import { GroupDetail } from './GroupDetail';
import { PathDetail } from './PathDetail';
import { PathTreeItem } from './PathTreeItem';
import { TaskDetail } from './TaskDetail';

type PropsOfType<O extends object, T> = { [P in keyof O as Exclude<O[P], undefined> extends T ? P : never]: O[P] };

export const makeDictionary = <T extends AT, AT extends PropsOfType<T, number | string>>(objs: T[], key: keyof AT) =>
  objs.reduce((pv, cv) => {
    pv[cv[key]] = cv;
    return pv;
  }, {} as { [index: string]: T | undefined });

// TODO: Move this to a common location
export function* grpIterator(grps: Data.MilestoneGroup[]): Generator<Data.MilestoneGroup, void, unknown> {
  for (const grp of grps) {
    yield grp;
    if (grp.subGroups) yield* grpIterator(grp.subGroups);
  }
}

export function* cGrpIterator(grps: Edit.MilestoneGroup[]): Generator<Edit.MilestoneGroup, void, unknown> {
  for (const grp of grps) {
    yield grp;
    if (grp.subGroups) yield* cGrpIterator(grp.subGroups);
  }
}

type GroupTreeItem = {
  id: number;
  subGroups?: GroupTreeItem[];
};

export function* nGrpIterator<T extends GroupTreeItem>(grps: T[]): Generator<T, void, unknown> {
  for (const grp of grps) {
    yield grp;
    if (grp.subGroups) yield* nGrpIterator(grp.subGroups as unknown as T[]);
  }
}

const getPath = (pathId: number, cache: Edit.SuccessPath[]) => cache?.find(p => p.id === pathId);

const getGrp = (path: Edit.SuccessPath, grpId: number) => {
  if (path.msGroups != null) {
    for (const grp of cGrpIterator(path.msGroups)) if (grp.id == grpId) return grp;
  }

  return;
};

type Selection = {
  selPath?: Edit.SuccessPath | null;
  selGrp?: Edit.MilestoneGroup;
  selMs?: Edit.Milestone;
  selTask?: Edit.Task;
};

const getPathGroup = (pathId: number, grpId: number, cache: Edit.SuccessPath[]): Selection => {
  const pth = getPath(pathId, cache);
  const grp = pth ? getGrp(pth, grpId) : undefined;

  return grp ? { selPath: pth, selGrp: grp } : {};
};

const getPathGroupMilestone = (pathId: number, grpId: number, msId: number, cache: Edit.SuccessPath[]): Selection => {
  const pthGrp = getPathGroup(pathId, grpId, cache);

  return { ...pthGrp, selMs: pthGrp.selGrp?.milestones?.find(ms => ms.id === msId) };
};

const getPathGroupMilestoneTask = (pathId: number, grpId: number, msId: number, taskId: number, cache: Edit.SuccessPath[]): Selection => {
  const pthGrpMs = getPathGroupMilestone(pathId, grpId, msId, cache);

  return { ...pthGrpMs, selTask: pthGrpMs.selMs?.tasks?.find(tsk => tsk.id === taskId) };
};

const getSelections = (selectedItem: string, cache?: Edit.SuccessPath[] | null): Selection => {
  const match = selectedItem.match(/^(path(?=(?:--?\d+){1}$)|grp(?=(?:--?\d+){2}$)|ms(?=(?:--?\d+){3}$)|task(?=(?:--?\d+){4}$))-(\d+)(?:-(-?\d+))?(?:-(-?\d+))?(?:-(-?\d+))?$/);
  if (cache != null && match != null) {
    const [, type, pathId, grpId, msId, taskId] = match;
    switch (type) {
      case 'path':
        return { selPath: getPath(Number(pathId), cache) };
      case 'grp':
        return getPathGroup(Number(pathId), Number(grpId), cache);
      case 'ms':
        return getPathGroupMilestone(Number(pathId), Number(grpId), Number(msId), cache);
      case 'task':
        return getPathGroupMilestoneTask(Number(pathId), Number(grpId), Number(msId), Number(taskId), cache);
    }
  }

  return {};
};

type PathEditorContextProps = {
  cache: Edit.SuccessPath[];
  cacheContext: Edit.CacheContext;
  hasUnsavedChanges: MutableRefObject<boolean>;
  refresh: () => void;
};

const cacheContext: Edit.CacheContext = { tasks: {}, completedEmails: {}, milestones: {}, msGroups: {}, paths: {} };

export const PathEditorContext = React.createContext<PathEditorContextProps>({ cache: [], cacheContext, refresh: () => undefined, hasUnsavedChanges: { current: false } });
const PathEditorProvider: React.FC<{ cache: Edit.SuccessPath[], cacheContext: Edit.CacheContext }> = ({ cache, cacheContext, children }) => {
  const [, setCacheVersion] = useState(0);
  const hasUnsavedChanges = useRef(false);

  const refresh = () => {
    hasUnsavedChanges.current = false;
    setCacheVersion(prev => prev + 1);
  };

  return <PathEditorContext.Provider value={{ cache, cacheContext, refresh, hasUnsavedChanges }}>{children}</PathEditorContext.Provider>;
};

const isHTMLElement = (elem: EventTarget): elem is HTMLElement => (elem as HTMLElement).classList != null;

export const moveSortable = (list: { sortIndex: number }[], sourceIndex: number, destIndex: number) => {
  const dir = Math.sign(destIndex - sourceIndex);
  list[sourceIndex].sortIndex = destIndex;
  list[destIndex].sortIndex -= dir;
  for (let i = sourceIndex + dir; i !== destIndex; i += dir) list[i].sortIndex -= dir;
  list.sort((i1, i2) => i1.sortIndex - i2.sortIndex);
};

type PathNavProps = { handleSelect: (e: React.ChangeEvent<{}>, id: string) => void, selected: string };
const PathEditorNavigation: React.FC<PathNavProps> = ({ handleSelect, selected }) => {
  const { cache } = useContext(PathEditorContext);
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    const targetSpan = event.target;
    if (isHTMLElement(targetSpan) && targetSpan.classList.contains('MuiIcon-root')) setExpanded(nodes);
  };

  return (
    <Callout name="save-help" body={<LanguageString groupName="HELP" resourceName="CHANGE_INDICATOR" />} step={2} placement="right">
      <TreeView
        selected={selected}
        expanded={expanded}
        onNodeToggle={handleChange}
        onNodeSelect={handleSelect}
        defaultCollapseIcon={<Icon>expand_more</Icon>}
        defaultExpandIcon={<Icon>chevron_right</Icon>}
      >
        {cache.map(path => <PathTreeItem key={path.id} path={path} />)}
      </TreeView>
    </Callout>
  );
};

const DetailView: React.FC<Selection> = ({ selPath, selGrp, selMs, selTask }) =>
  selTask ? (
    <TaskDetail task={selTask} />
  ) : selMs && selGrp ? (
    <MilestoneEditorForm group={selGrp} milestone={selMs} />
  ) : selGrp ? (
    <GroupDetail group={selGrp} />
  ) : selPath ? (
    <PathDetail path={selPath} />
  ) : null;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: `${theme.spacing(2)}px`
  },
  [theme.breakpoints.up('md')]: {
    container: {
      gridTemplateColumns: '1fr 3fr'
    }
  }
}));

const getAddedMilestone = (paths: Edit.SuccessPath[], msInfo: { msId: number, grpId: number }) => {
  for (const path of paths) {
    if (path.msGroups == null) continue;

    for (const msGroup of cGrpIterator(path.msGroups)) {
      if (msGroup.id === msInfo.grpId) {
        const ms = msGroup.milestones?.find(gms => gms.id === msInfo.msId);
        if (ms == null) throw new Error(`Ms ${msInfo.msId} Not found when looking in grp ${msGroup.id}`);

        return ms;
      }
    }
  }

  throw new Error(`Ms ${msInfo.msId} Not found`);
};

const PathEditorUI: React.FC<{ cache: Edit.SuccessPath[]; onSave: () => void, paths: DTO.SuccessPath[]}> = ({cache, onSave, paths}) => {
  const peCtx = useContext(PathEditorContext);
  const [updatePathMutation, { loading: updatingPath }] = useUpdatePath();
  const [updateMsGroup, {loading: _updatingMsGroup}] = useUpdateMilestoneGroup();
  const [addOrRemoveMilestones] = useAddOrRemoveMilestone();
  const [addOrRemoveSubGrousp] = useAddOrRemoveSubGroups({
    update: (cache, result) => {
      result.data?.addOrRemoveSubGroups.forEach(pgrp => {
        cache.modify({
          id: `MilestoneGroup:${pgrp.id}`,
          fields: {
            subGroups(existingSbGps, dets) {
              if (Array.isArray(existingSbGps) && existingSbGps.length === pgrp.subGroups?.length) {
                return [...existingSbGps];
              }

              const newSgGrps = pgrp.subGroups?.map(gp => dets.toReference(gp));
              return newSgGrps;
            }
          }
        });
      });
    }
  });
  const [updateMilestone, { loading: _updatingMilestone }] = useUpdateMilestone({
    update: (cache, result) => {
      const grpUpdates = result.data?.updateMilestones.groupInfo?.reduce((pv, cv) => {
        const milestone = result.data?.updateMilestones.milestones.find(ms => ms.id === cv.msId);
        if (milestone) {
          if (pv[cv.groupId] == null) pv[cv.groupId] = [];
          pv[cv.groupId].push({ milestone, sortIndex: cv.sortIndex });
        }
        return pv;
      }, {} as { [grpId: number]: { milestone: DTO.Milestone, sortIndex: number }[] });

      if (grpUpdates) {
        TypedReflect.ownKeys(grpUpdates).forEach(grpId => {
          cache.modify({
            id: `MilestoneGroup:${grpId}`,
            fields: {
              milestones(existingMs, dets) {
                if (Array.isArray(existingMs)) {
                  return [...existingMs].sort((m1, m2) => {
                    const m1Index = grpUpdates[grpId].find(m => dets.toReference(m.milestone)?.__ref === m1.__ref)?.sortIndex ?? existingMs.findIndex(em => em.__ref === m1.__ref);
                    const m2Index = grpUpdates[grpId].find(m => dets.toReference(m.milestone)?.__ref === m2.__ref)?.sortIndex ?? existingMs.findIndex(em => em.__ref === m2.__ref);
                    return m1Index - m2Index;
                  });
                }
                return [...existingMs];
              }
            }
          });
        });
      }
  }});
  const [selectedItem, setSelectedItem] = useState('path');
  const classes = useStyles();
  const selectedPaths = useRef([] as Edit.SuccessPath[]);
  const newSelection = useRef('');
  const { controller: { setClose, setOpen }, props } = useDialogController(false);
  const updatePath = async () => {
    const changedGrps: Edit.MilestoneGroup[] = [];
    const changedMilestones: { grpId: number, ms: Edit.Milestone }[] = [];
    const deletedMilestones: { grpId: number, msId: number }[] = [];
    const addedMilestones: { grpId: number, msId: number }[] = [];
    const notTesting = true;
    const origGrps = makeDictionary(paths.map(p => p.milestoneGroups ? [...nGrpIterator(p.milestoneGroups)] : []).flat(1), 'id');
    const addRmSubGrps: Edit.MilestoneGroup[] = [];
    const addPathGrps: { [pathId: number]: Edit.MilestoneGroup[] } = { };

    for (const path of selectedPaths.current) {
      for (const grp of cGrpIterator(path.msGroups!)) {
        const origGrp = origGrps[grp.id];

        origGrp?.milestones?.filter(m1 => grp.milestones?.find(m2 => m2.id === m1.id) === undefined).forEach(ms => deletedMilestones.push({ grpId: grp.id, msId: ms.id }));
        grp.milestones?.filter(m1 => origGrp?.milestones?.find(m2 => m2.id === m1.id) === undefined).forEach(ms => addedMilestones.push({grpId: grp.id, msId: ms.id}));

        if (grp.id > 0 && grp.subGroups != null && (origGrp?.subGroups?.length !== grp.subGroups?.length || !origGrp?.subGroups?.every(osg => grp.subGroups?.find(sg => sg.id === osg.id)))) {
          addRmSubGrps.push(grp);
        }

        // Don't include negative IDs. These are added groups and are already in addRmSubGrps
        if ((grp as Edit.Editable<typeof grp>).isChanged && grp.id > 0) changedGrps.push(grp);
        grp.milestones?.forEach(ms => (ms as Edit.Editable<typeof ms>).hasChanges && ms.id > 0 ? changedMilestones.push({ grpId: grp.id, ms }) : undefined);
      }

      if (notTesting) {
        if (changedGrps.length) await updateMsGroup({ variables: { input: changedGrps.map(cg => Data.convertGroup(cg, true)) } });
        if (changedMilestones.length) await updateMilestone({ variables: { input: changedMilestones.map(cm => Data.convertMilestone(cm.ms, cm.grpId)) } });
      }
    }

    for (const path of selectedPaths.current) {
      if (path.msGroups?.find(grp => grp.id < 0)) addPathGrps[path.id] = path.msGroups;
      if ((path as Edit.Editable<typeof path>).isChanged) await updatePathMutation({ variables: {input: Data.convertPath(path, true)}});
    }

    if (addedMilestones.length || deletedMilestones.length) {
      await addOrRemoveMilestones({
        variables: {
          input:
          {
            addMs: addedMilestones.map(ms => ms.msId < 0 ? Data.convertMilestone(getAddedMilestone(selectedPaths.current, ms), ms.grpId) : ({ id: ms.msId, groupId: ms.grpId })),
            removeMs: deletedMilestones.map(ms => ({ id: ms.msId, groupId: ms.grpId }))
          }
        }
      });
    }

    if (addRmSubGrps.length) {
      await addOrRemoveSubGrousp({
        variables: {
          input: addRmSubGrps.filter(gp => gp.subGroups != null).map(gp => ({ groupId: gp.id, subGroups: gp.subGroups!.map(sg => Data.convertGroup(sg)) }))
        }
      });
    }

    if (addPathGrps) {
      for (const pathId of TypedReflect.ownKeys(addPathGrps))
        await updatePathMutation({ variables: { input: { id: pathId, msGroups: addPathGrps[pathId].map(mg => Data.convertGroup(mg, true)) } } });
    }

    onSave();
  };

  useMemo(() => { selectedPaths.current = []; }, cache);
  const { selPath, selGrp, selMs, selTask } = getSelections(selectedItem, cache);

  if (selPath != null && !selectedPaths.current.includes(selPath)) selectedPaths.current.push(selPath);

  const safeSelectItem = (id: string) => {
    if (!peCtx.hasUnsavedChanges.current) setSelectedItem(id);
    else {
      setOpen();
      newSelection.current = id;
    }
  };

  const discard = () => {
    setSelectedItem(newSelection.current);
    setClose();
  };

  return (
    <div className={classes.container}>
      <Card>
        <CardHeader
          title={<LanguageString groupName="EDITOR" resourceName="PATHS" />}
          action={
            <Callout body={<LanguageString groupName="HELP" resourceName="COMMIT_CHANGES" />} name="save-help" step={3} placement="right-end">
              <IconButton icon="save" onClick={updatePath} />
            </Callout>
          }
        />
        <CardContent>
          <PathEditorNavigation handleSelect={(_e, id) => safeSelectItem(id)} selected={selectedItem} />
        </CardContent>
      </Card>
      <DetailView selGrp={selGrp} selMs={selMs} selTask={selTask} selPath={selPath} />
      <Dialog {...props}>
        <DialogTitle onClose={setClose}>
          <LanguageString groupName="EDITOR" resourceName="UNSAVED_CHANGES_TITLE" />
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            <LanguageString groupName="EDITOR" resourceName="UNSAVED_CHANGES" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setClose()}>Cancel</Button>
          <Button onClick={() => discard()}>Continue</Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={updatingPath}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export const PathEditor: React.FC = () => {
  const { loading, data } = useSitePaths({ fetchPolicy: 'cache-first' });
  const cc = useRef<Edit.CacheContext | null>(null);
  const [version, setVersion] = useState(0);
  const cache = useMemo<Edit.SuccessPath[] | null>(() => {
    const localCc = { tasks: {}, completedEmails: {}, milestones: {}, msGroups: {}, paths: {} };
    cc.current = localCc;
    return (data?.site.paths.map(p => Edit.convertPath(p, localCc)) ?? null);
  }, [data?.site.paths, version]);

  const handleSave = () => setVersion(ver => ver + 1);

  return loading ?
    <CircularProgress color="primary" /> :
    (
      data?.site.paths ?
        cc.current && cache && (
          <PathEditorProvider cache={cache} cacheContext={cc.current}>
            <PathEditorUI cache={cache} onSave={handleSave} paths={data.site.paths} />
          </PathEditorProvider>
        ) :
        <div>No Path</div>
    );
};

export default PathEditor;
