import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  IconButton,
  Icon,
  Grid
} from '@material-ui/core';
import { Roadmap } from '../../dto';
import { LanguageString, GeneralErrorMessage, DownloadTemplate } from '../Common';
import { StudentContext, useChildLinks, SiteContext } from '../../contexts';
import { useCompletedRoadmaps, useRoadmap, useClasses } from '../../hooks';
import { format } from 'date-fns';
import { PrintLocation } from 'tuapath-common/generated/schema';

export const RoadmapPrintDialog: React.FC<{ open: boolean; toggle: () => void; }> = ({ open, toggle }) => {
  const classes = useClasses();
  const studentCtx = useContext(StudentContext);
  const siteCtx = useContext(SiteContext);
  const currentRoadmap = useRoadmap({ variables: { userId: studentCtx?.student.id ?? 0 } });
  const completedRoadmaps = useCompletedRoadmaps({ variables: { userId: studentCtx?.student.id ?? 0 } });

  const currentRoadmapChildLinks = useChildLinks({
    items: currentRoadmap.data?.roadmap ? [currentRoadmap.data.roadmap] : [{ id: 0 }],
    paramMap: { id: 'id' },
    routeName: 'print-roadmap'
  });
  const childLinks = useChildLinks({
    items: completedRoadmaps.data?.completedRoadmaps ? completedRoadmaps.data.completedRoadmaps : [],
    paramMap: { id: 'id' },
    routeName: 'print-roadmap'
  });

  const clickedRow = (id: number | undefined) => {
    let path = '';

    if (id) {
      if (childLinks) {
        for (const l of childLinks) {
          if (l.path.includes(String(id))) {
            path = l.path;
            break;
          }
        }
      }
    } else if (currentRoadmapChildLinks && currentRoadmapChildLinks?.length > 0) {
      path = currentRoadmapChildLinks[0].path;
    }

    window.open(path, '_blank');
  };

  let sortedRoadmaps: Roadmap[] | undefined = undefined;
  if (completedRoadmaps.data?.completedRoadmaps) {
    sortedRoadmaps = completedRoadmaps.data.completedRoadmaps.slice().sort((a, b) => {
      if (a.reviewedAt && b.reviewedAt) {
        return a.reviewedAt < b.reviewedAt ? 1 : -1;
      } else if (a.reviewedAt && !b.reviewedAt) {
        return 1;
      } else if (b.reviewedAt && !a.reviewedAt) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={toggle}>
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <LanguageString groupName="ROADMAP" resourceName="PRINT_ROADMAP" defaultText="Print Roadmap" />
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={toggle} aria-label="close">
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {completedRoadmaps.loading && (
          <CircularProgress color="primary" />
        )}
        {completedRoadmaps.error && (
          <GeneralErrorMessage message={completedRoadmaps.error.message} />
        )}
        {!completedRoadmaps.loading && !completedRoadmaps.error && (
          <Table>
            <TableBody>
              <TableRow
                key={`roadmap_print_0`}
                className={siteCtx.site?.roadmapPrintPageEnabled ? classes.clickable : ''}
                onClick={() => {
                  if (siteCtx.site?.roadmapPrintPageEnabled) {
                    clickedRow(undefined);
                  }
                }}
              >
                <TableCell>
                  <LanguageString groupName="ROADMAP" resourceName="CURRENT_ROADMAP" defaultText="Current Roadmap" />
                </TableCell>
                <TableCell align="right">
                  {siteCtx.site?.roadmapPrintPageEnabled ? (
                    <IconButton>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  ) : (
                    <DownloadTemplate
                      location={PrintLocation.Roadmap}
                      relationId={currentRoadmap.data?.roadmap.id.toString()}
                    />
                  )}
                </TableCell>
              </TableRow>
              {sortedRoadmaps && sortedRoadmaps.map(roadmap => (
                <TableRow
                  key={`roadmap_print_${roadmap.id}`}
                  className={siteCtx.site?.roadmapPrintPageEnabled ? classes.clickable : ''}
                  onClick={() => {
                    if (siteCtx.site?.roadmapPrintPageEnabled) {
                      clickedRow(roadmap.id);
                    }
                  }}
                >
                  <TableCell>
                    {format(roadmap.reviewedAt ? new Date(roadmap.reviewedAt) : new Date(), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell align="right">
                    {siteCtx.site?.roadmapPrintPageEnabled ? (
                      <IconButton>
                        <Icon>keyboard_arrow_right</Icon>
                      </IconButton>
                    ) : (
                      <DownloadTemplate
                        location={PrintLocation.RoadmapComplete}
                        relationId={roadmap.id.toString()}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default RoadmapPrintDialog;
