import React, { useState } from 'react';
import { Site } from '../../dto';
import { useSite } from '../../hooks/gql/useSite';
import { CircularProgress, Grid } from '@material-ui/core';
import { ErrorResponse } from '@apollo/client/link/error';

type DismissalType = 'auto' | 'manual';
export interface Success {
  title: string,
  message: string,
  dismissal?: DismissalType
}

export type StatusError = {
  dismissal: DismissalType;
  allUsers?: boolean;
};

export const SiteContext = React.createContext<{ site?: Site }>({});

export const SiteProvider: React.FC = ({ children }) => {
  const { data, loading } = useSite({ fetchPolicy: 'cache-first' });

  return loading ? (
    <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
   ) :
  <SiteContext.Provider value={{ site: data?.site }}>{children}</SiteContext.Provider>;
};

export default SiteProvider;

export const SiteStatusContext = React.createContext<{ status?: Error | ErrorResponse | StatusError | Success, setStatus: (status: Error | ErrorResponse | StatusError | Success | undefined) => void }>({ setStatus: () => undefined });
export const SiteStatusProvider: React.FC = ({ children }) => {
  const [stateStatus, setStateStatus] = useState<Error | ErrorResponse | StatusError | Success | undefined>();
  return <SiteStatusContext.Provider value={{ status: stateStatus, setStatus: setStateStatus }}>{children}</SiteStatusContext.Provider>;
};
