import {
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SiteContext, BlastNotificationsContext } from '../../../contexts';
import * as DTO from '../../../dto';
import { LocalStorageKey, useLocalStorage } from '../../../hooks';
import { DialogTitle, LanguageString } from '../../Common';
import { DynamicForm } from '../../DynamicForm';
import { NotificationBlastAdvisorTab } from './NotificationBlastAdvisorTab';

interface NotificationBlastFilterDialogProps {
  open: boolean;
  toggle: () => void;
  selectedAdvisorIds: number[];
  changedSelectedAdvisorIds: (advisorIds: number[]) => void;
  showClosedUsers: boolean;
  changedShowClosedUsers: (showClosedUsers: boolean) => void;
}

export const NotificationBlastFilterDialog: React.FC<NotificationBlastFilterDialogProps> = props => {
  const studentsContext = useContext(BlastNotificationsContext);
  const [currentTab, setCurrentTab] = useState(0);
  const [dismissOnSave, setDismissOnSave] = useState(false);
  const siteCtx = useContext(SiteContext);
  const [filters, setFilters] = useLocalStorage<DTO.SubmitFormInput | {}>(LocalStorageKey.NotificationBlastFilters, {});
  let dynamicFormRef: DynamicForm | null = null;

  const toggle = () => {
    if (currentTab === 1 && dynamicFormRef) {
      setDismissOnSave(true);
      void dynamicFormRef.submitForm();
    } else {
      props.toggle();
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (currentTab === 1 && dynamicFormRef) {
      void dynamicFormRef.submitForm();
    }

    setCurrentTab(newValue);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={props.open} onClose={toggle} aria-labelledby="Notification Blast Filters">
      <DialogTitle onClose={toggle}>
        <LanguageString groupName="GENERAL" resourceName="NOTIFICATION_BLAST_FILTERS" defaultText="Filters" />
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filter Tabs"
          variant="fullWidth"
        >
          <Tab label={<LanguageString groupName="GENERAL" resourceName="ADVISOR_FILTERS" defaultText="Advisors" />} />
          <Tab label={<LanguageString groupName="GENERAL" resourceName="FILTERS" defaultText="Filters" />} />
        </Tabs>

        <Grid container>
          {currentTab === 0 && (
            <NotificationBlastAdvisorTab selectedAdvisorIds={props.selectedAdvisorIds} changedSelectedAdvisorIds={props.changedSelectedAdvisorIds} />
          )}
          {currentTab === 1 && (
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <DynamicForm
                    ref={f => (dynamicFormRef = f)}
                    formId={siteCtx.site?.notificationBlastForm?.id ?? 0}
                    autoloadFormSubmission={false}
                    showSubmitButton={false}
                    autoSave={true}
                    overrideFormSubmissionInput={Object.keys(filters).length > 0 ? (filters as DTO.SubmitFormInput) : undefined}
                    overrideSubmitHandler={(input, form) => {
                      setFilters(input);
                      studentsContext?.setFilters(input);

                      if (dismissOnSave) {
                        setDismissOnSave(false);
                        props.toggle();
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationBlastFilterDialog;
