import React, { useState, useContext } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  IconButton,
  Tooltip,
  TableHead
} from '@material-ui/core';
import { User } from '../../dto';
import UserHelper from '../../helpers/userHelper';
import { useClasses } from '../../hooks';
import { useHistory } from 'react-router-dom';
import RouteBuilderHelper from '../../helpers/routeBuilderHelper';
import { StudentsContext, useRawChildLinks } from '../../contexts';
import { LanguageString } from '../Common';
import { StudentsTableColumnSettings } from './StudentsFiltersDialog';
import * as DTO from '../../dto';
import { SiteContext } from '../../contexts';

const mouseMoveThreshold = 5;

export const StudentsSearchTable: React.FC<{ students: User[], columns: StudentsTableColumnSettings }> = React.memo(({ students, columns }) => {
  const classes = useClasses();
  const history = useHistory();
  const studentsCtx = useContext(StudentsContext);
  const [pressingMouse, setPressingMouse] = useState(false);
  const [mouseMoved, setMouseMoved] = useState(false);
  const [mouseMoveCount, setMouseMoveCount] = useState(0);
  const siteCtx = useContext(SiteContext);
  const studentsLinks = useRawChildLinks('students', {
    items: [{id: 0}],
    paramMap: { id: 'id'},
    routeName: 'students'
  });
  const firstRoute = studentsLinks?.[0].href ?? studentsLinks?.[0].path ?? '/roadmap';

  const mouseDown = (event: React.MouseEvent<HTMLTableRowElement>) => {
    setPressingMouse(true);
  };

  const mouseUp = (student: User) => {
    if (!mouseMoved) {
      clickedOnStudent(student);
    }

    setPressingMouse(false);
    setMouseMoved(false);
    setMouseMoveCount(0);
  };

  const mouseDidMove = (event: React.MouseEvent<HTMLTableRowElement>) => {
    if (pressingMouse) {
      if (mouseMoveCount > mouseMoveThreshold) {
        setMouseMoved(true);
      } else {
        setMouseMoveCount(mouseMoveCount + 1);
      }
    }
  };

  const clickedOnStudent = (student: User) => {
    const openStudents = studentsCtx?.openStudents.slice() ?? [];
    openStudents.push(student);

    history.push(RouteBuilderHelper.openStudentRoute(firstRoute, openStudents.map(s => s.id), student.id));
  };

  return (
    <Table className={classes.stripedTable} size={'medium'} aria-label="Students Table">
      <TableHead>
        <TableRow>
          {columns.name && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="NAME" defaultText="Name" />
            </TableCell>
          )}
          {columns.username && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="USERNAME" defaultText="Username" />
            </TableCell>
          )}
          {columns.coach && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="COACH" defaultText="Coach" />
            </TableCell>
          )}
          {columns.caseNum && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="CASE_NUM" defaultText="Case #" />
            </TableCell>
          )}
          {columns.email && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="EMAIL" defaultText="Email" />
            </TableCell>
          )}
          {columns.phone && (
            <TableCell align="left">
              <LanguageString groupName="GENERAL" resourceName="PHONE" defaultText="Phone" />
            </TableCell>
          )}
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {students.map(student => {
          let closedUser = false;
          let lastNotificationUser = false;
          if (student.customProperties) {
            // TODO: Remove the hard reference to custom prop names
            closedUser = (student.customProperties.find(cp => cp.type?.name === DTO.CustomPropertyNames.closedCase)?.value === 'true');
            const lastNotificationProp = student.customProperties.find(cp => cp.type?.name === DTO.CustomPropertyNames.lastNotificationDate);
            if (lastNotificationProp && lastNotificationProp.value !== undefined) {
              const date = new Date(lastNotificationProp.value);
              if (new Date() < date) {
                lastNotificationUser = true;
              }
            }
          }

          return (
            <TableRow
              key={`student_search_${student.id}`}
              className={`${classes.clickable} ${lastNotificationUser ? classes.lastNotification : (closedUser ? classes.closedCase : '')}`}
              onMouseDown={mouseDown}
              onMouseUp={() => mouseUp(student)}
              onMouseMove={mouseDidMove}
            >
              {columns.name && (
                <TableCell align="left">
                  {student.firstName} {student.lastName.substring(0, siteCtx.site?.caseLoadLastNameCharacters ?? 1)}
                </TableCell>
              )}
              {columns.username && (
                <TableCell align="left">
                  {student.username}
                </TableCell>
              )}
              {columns.coach && (
                <TableCell align="left">
                  {student.advisor && student.advisor.firstName ? `${student.advisor.firstName} ${student.advisor.lastName}` : ''}
                </TableCell>
              )}
              {columns.caseNum && (
                <TableCell align="left">{UserHelper.getCaseId(student)}</TableCell>
              )}
              {columns.email && (
                <TableCell align="left">{student.emails && student.emails.length > 0 ? student.emails[0].email : ''}</TableCell>
              )}
              {columns.phone && (
                <TableCell align="left">
                  {student.phoneNumber}
                </TableCell>
              )}
              <TableCell align="right">
                <Tooltip
                  title={
                    <LanguageString groupName="STUDENTS" resourceName="VIEW_PARTICIPANT_TOOLTIP" defaultText="View Participant" />
                  }
                >
                  <IconButton
                    onClick={() => {
                      clickedOnStudent(student);
                    }}
                  >
                    <Icon>keyboard_arrow_right</Icon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});

export default StudentsSearchTable;
