import {
  Grid,
  FormControlLabel,
  TextFieldProps as MuiTextFieldProps,
  Switch as MuiSwitch
} from '@material-ui/core';
import { useField } from 'formik';
import React, { ChangeEventHandler, CSSProperties } from 'react';
import { GridWrapperProps } from '../TextField';

export type SwitchFieldProps = GridWrapperProps & {
  name: string;
  label?: MuiTextFieldProps['label'];
  style?: CSSProperties;
  onChange?: ChangeEventHandler<{ name?: string | undefined; value: unknown; }>;
  type?: string;
};

export const Switch: React.FC<SwitchFieldProps> = props => {
  const { name, onChange, xs, sm, md, lg, xl, item, label } = props;
  const [{ onChange: onFieldChange, value = false }] = useField(name);
  const handleChange: ChangeEventHandler<{ name?: string | undefined; value: unknown; }> = event => {
    onFieldChange(event);
    if (onChange) onChange(event);
  };

  return (
    <Grid {...{ item, xs, sm, md, lg, xl }}>
      <FormControlLabel
        control={
          <MuiSwitch
            name={name}
            checked={value}
            onChange={handleChange}
            color="primary"
          />
        }
        label={label}
      />
    </Grid>
  );
};
export default Switch;
