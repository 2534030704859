import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField as MuiTextField
} from '@material-ui/core';
import { Form, Formik, useField } from 'formik';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import * as Edit from '../../common/pathEditTypes';
import { Milestone } from '../../dto';
import { useDialogController, useRemoveMilestone, useSiteMilestones, useSitePaths } from '../../hooks';
import { Checkbox, DialogTitle, LanguageString, TextField } from '../Common';
import { cGrpIterator, PathEditorContext } from '../PathEditor';
import { MilestoneEditorForm } from './MilestoneEditorForm';

type SelectMsHandler = (ms: Edit.Milestone) => void;
type SelectGrpHandler = (grp: Edit.MilestoneGroup | undefined) => void;

type MilestoneSelectorProps = {
  groupId: number;
  milestones: Edit.Milestone[];
  onMsSelect: SelectMsHandler;
};

type AddMilestoneValues = {
  isNew: boolean;
  selectedMilestoneId?: number;
};

const AddMilestoneForm: React.FC<{ milestones: Milestone[] }> = ({ milestones }) => {
  const [{ value }] = useField<boolean>('isNew');

  return (
    <Grid container direction="column" spacing={2}>
      <Checkbox item name="isNew" label={<LanguageString groupName="MS_EDITOR" resourceName="CREATE_MILESTONE" />} />
      {!value && (
        <TextField item name="selectedMilestoneId" label={<LanguageString groupName="MS_EDITOR" resourceName="SELECT_MILESTONE" />} select>
          <MenuItem value="ph" disabled>
            <em><LanguageString groupName="MS_EDITOR" resourceName="SELECT_MILESTONE" /></em>
          </MenuItem>
          {milestones.map(ms => (
            <MenuItem key={ms.id} value={ms.id}>
              <LanguageString languageString={ms.tasks?.[0].name} />
            </MenuItem>
          ))}
        </TextField>
      )}
    </Grid>
  );
};

const MilestoneSelector: React.FC<MilestoneSelectorProps> = ({ groupId, milestones, onMsSelect }) => {
  const { data: msData } = useSiteMilestones();
  const [removeMilestone] = useRemoveMilestone();
  const [selectedMs, setSelectedMs] = useState<Milestone | undefined>();
  const { controller, props } = useDialogController(false);
  const handleAddClick = () => controller.setOpen();
  const handleRemoveClick = (ms: Edit.Milestone) => removeMilestone({ variables: { input: [{ id: ms.id, groupId }] } });
  const { cacheContext: cc } = useContext(PathEditorContext);
  const msList = useMemo(() => msData?.site.milestones.map((ms, index) => Edit.convertMilestone(ms, index, cc, null)) ?? [], [msData?.site.milestones]);

  const handleClick = (ms: Edit.Milestone) => {
    setSelectedMs(ms);
    onMsSelect(ms);
  };

  const initialValues = (): AddMilestoneValues => ({
    isNew: true,
    selectedMilestoneId: undefined
  });

  const handleSubmit = (values: AddMilestoneValues) => {
    if (values.isNew) {
      const newMs = Edit.createNewMilestone({cc, sortIndex: milestones.length, pId: groupId});

      if (milestones != null) milestones.push(newMs);
      onMsSelect(newMs);
    } else {
      const newSelectedMs = msList?.find(ms => ms.id === values.selectedMilestoneId);
      if (newSelectedMs != null) {
        milestones.push(newSelectedMs);
        onMsSelect(newSelectedMs);
      }
    }

    controller.setClose();
  };

  return (
    <Box mt="16px">
      <Card>
        <CardHeader
          title={<LanguageString groupName="EDITOR" resourceName="MILESTONES" defaultText="Milestones" />}
          action={
            <IconButton onClick={handleAddClick}>
              <Icon>add</Icon>
            </IconButton>
          }
        />
        <Divider />
        <List>
          {milestones?.map(ms => (
            <ListItem key={ms.id} selected={selectedMs?.id === ms.id} button onClick={() => handleClick(ms)}>
              <ListItemText primary={<LanguageString languageString={ms.tasks?.[0]?.name} />} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleRemoveClick(ms)}>
                  <Icon>delete</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Card>
      <Dialog {...props} maxWidth="sm" fullWidth>
        <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
          <Form>
            <DialogTitle onClose={controller.setClose}>
              <LanguageString groupName="MS_EDITOR" resourceName="NEW_OR_EXISTING" />
            </DialogTitle>
            <DialogContent>{msData?.site.milestones.length && <AddMilestoneForm milestones={msData.site.milestones} />}</DialogContent>
            <DialogActions>
              <Button onClick={controller.setClose}>Cancel</Button>
              <Button type="submit">Ok</Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Box>
  );
};

const GroupMilestoneSelector: React.FC<{ rootGroups: Edit.MilestoneGroup[]; onGrpSelect: SelectGrpHandler }> = ({ rootGroups, onGrpSelect }) => {
  const [selectedGrp, setSelectedGrp] = useState<Edit.MilestoneGroup | undefined>();
  useMemo(() => (selectedGrp != null ? setSelectedGrp(undefined) : null), [rootGroups.map(g => g.id).join()]);

  const handleGrpSelect: React.ChangeEventHandler<HTMLInputElement> = event => {
    const grp = rootGroups.find(g => g.id === Number(event.target.value));
    setSelectedGrp(grp);
    if (grp != null) onGrpSelect(grp);
  };

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <MuiTextField select fullWidth onChange={handleGrpSelect} value={selectedGrp?.id ?? 'ph'}>
          <MenuItem value="ph" disabled>
            <em>Select an action step group</em>
          </MenuItem>
          {rootGroups.map(grp => (
            <MenuItem key={grp.id} value={grp.id}>
              <LanguageString languageString={grp.name} />
            </MenuItem>
          ))}
        </MuiTextField>
      </Grid>
      {selectedGrp?.subGroups?.length ? <GroupMilestoneSelector rootGroups={selectedGrp.subGroups} onGrpSelect={onGrpSelect} /> : null}
    </Grid>
  );
};

const findGroup = (rootGroups: Edit.MilestoneGroup[], grpId: number) => {
  for (const grp of cGrpIterator(rootGroups)) {
    if (grp.id === grpId) return grp;
  }
  return;
};

type MilestoneSelectorSectionProps = {
  path: Edit.SuccessPath;
  onGrpSelect: SelectGrpHandler;
  onMsSelect: SelectMsHandler;
};

const MilestoneSelectorSection: React.FC<MilestoneSelectorSectionProps> = ({ path, onGrpSelect, onMsSelect }) => {
  const [selectedGrp, setSelectedGrp] = useState<Edit.MilestoneGroup | undefined>();
  const realSelectedGrp = useMemo(
    () => (path.msGroups != null && selectedGrp != null ? findGroup(path.msGroups!, selectedGrp?.id) : undefined),
    [path, selectedGrp]
  );

  if (selectedGrp !== realSelectedGrp) {
    setSelectedGrp(realSelectedGrp);
    onGrpSelect(realSelectedGrp);
  }

  const milestones = selectedGrp?.milestones;

  return (
    <Grid container direction="column">
      <Grid item>
        <LanguageString languageString={path.name} />
      </Grid>
      {path.msGroups != null ? (
        <Box maxWidth="100%" clone>
          <Grid item container spacing={2}>
            <GroupMilestoneSelector rootGroups={path.msGroups} onGrpSelect={setSelectedGrp} />
          </Grid>
        </Box>
      ) : null}
      {milestones != null ? (
        <Grid item>
          <MilestoneSelector groupId={selectedGrp!.id} milestones={milestones} onMsSelect={onMsSelect} />
        </Grid>
      ) : null}
    </Grid>
  );
};

export const MilestoneEditor: React.FC = () => {
  const { loading, data } = useSitePaths({ fetchPolicy: 'cache-first' });
  const [selectedGrp, setSelectedGrp] = useState<Edit.MilestoneGroup | undefined>();
  const [selectedMs, setSelectedMs] = useState<Edit.Milestone | undefined>();
  const cc = useRef<Edit.CacheContext | null>(null);

  const cache = useMemo<Edit.SuccessPath[] | null>(() => {
    const localCc = { tasks: {}, completedEmails: {}, milestones: {}, msGroups: {}, paths: {} };
    cc.current = localCc;

    return (data?.site.paths.map(p => Edit.convertPath(p, localCc)) ?? null);
  }, [data?.site.paths]);

  const handleGrpSelect = useCallback((grp?: Edit.MilestoneGroup) => setSelectedGrp(grp), []);
  const handleMsSelect = useCallback((ms: Edit.Milestone) => setSelectedMs(ms), []);
  const handleMsSaved = (ms: Milestone, grpId: number) => setSelectedMs(Edit.convertMilestone(ms, grpId, cc.current!, grpId));

  return loading ? (
    <CircularProgress color="primary" />
  ) : cache && (
    <Grid container spacing={2}>
      <Box maxWidth="100%" clone>
        <Grid item xs={3}>
          <MilestoneSelectorSection path={cache[0]} onGrpSelect={handleGrpSelect} onMsSelect={handleMsSelect} />
        </Grid>
      </Box>
      <Grid item xs={9}>
        {selectedGrp && selectedMs && <MilestoneEditorForm group={selectedGrp} milestone={selectedMs} onSaved={handleMsSaved} />}
      </Grid>
    </Grid>
  );
};
