import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { notificationsFrag } from './fragments';

type Result = { deleted: Boolean };
type Variables = { id: number };
export const useNotificationMarkDeleted = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
  ${notificationsFrag}
    mutation markNotificationDelete($id: Int!) {
      userNotificationMarkDeleted(id: $id) {
        ...NotificationsFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useNotificationMarkDeleted;
