import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import * as DTO from '../../dto';
import { mtsFrag } from './fragments';

type Result = { mtsRevise: DTO.MTS };
type Variables = { id: number };
export const useMtsRevise = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${mtsFrag}

    mutation mtsRevise($id: Int!) {
      mtsRevise(mtsId: $id) {
        ...MTSFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useMtsRevise;
