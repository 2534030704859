import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { DialogTitle, DynamicForm, FormButtons, FormContainerProvider } from '..';
import { StudentContext } from '../../contexts';
import { useDialogController } from '../../hooks';
import { isNumber } from '../DynamicForm/common';

export const FormRoute: React.FC = () => {
  const params = useParams<{ fmId: string }>();
  const history = useHistory();
  const handleClose = () => history.goBack();
  const { props: dProps } = useDialogController(true, handleClose);
  const stdCtx = useContext(StudentContext);
  const rt = useRouteMatch();

  const dlgTitle = rt.url.split('/')?.[2].split('_').map(s => `${s[0]?.toUpperCase()}${s.substring(1)?.toLowerCase()}`).join(' ');

  const [fmParam, noCache] = params.fmId.split(':');
  const formProps = { onFormSubmitted: dProps.onClose, disableCache: noCache === 'nc' };
  const formSpecifier = isNumber(fmParam) ? { formId: Number(fmParam) } : { formName: fmParam };

  return (
    <FormContainerProvider>
      <Dialog fullWidth={true} maxWidth={'lg'} {...dProps}>
        <DialogTitle onClose={handleClose}>{dlgTitle}</DialogTitle>
        <DialogContent dividers>
          <DynamicForm autoloadFormSubmission={true} userId={stdCtx?.student.id} {...formSpecifier} showSubmitButton={false} {...formProps} />
        </DialogContent>
        <DialogActions>
          <FormButtons />
        </DialogActions>
      </Dialog>
    </FormContainerProvider>
  );
};
