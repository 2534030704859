import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormQuestionType } from 'tuapath-common/generated/schema';
import { TypedReflect } from '../../common';
import { DynamicFormContext, StudentContext } from '../../contexts';
import { FormQuestion, FormSubmission, FormSubmissionAnswer } from '../../dto';
import { useFormSubmissions } from '../../hooks';
import { GraphData, LanguageString, LineChart, Printable, PrintableButton } from '../Common';
import { FormFieldComp } from './common';

const ratingHighTextQuestionName = 'ratingHighText';
const ratingLowTextQuestionName = 'ratingLowText';
const graphDateName = 'graphDate';
const graphDataSourceName = 'graphDataSource';

interface FormSubmissionAnswerMerged {
  answer?: FormSubmissionAnswer;
  formSubmission: FormSubmission;
}

const FormGraphDialog: React.FC<{ question: FormQuestion, toggle: () => void, open: boolean; }> = ({ question, toggle, open }) => {
  const theme = useTheme();
  const formCtx = useContext(DynamicFormContext);
  const studentCtx = useContext(StudentContext);
  const { data, loading } = useFormSubmissions({
    variables: {
      userId: studentCtx?.student?.id ?? -1,
      formId: formCtx.form?.id ?? -1,
      assignmentId: formCtx.formSubmission?.assignment?.id
    }
  });

  const answers = useMemo(() => {
    return (data?.user?.formSubmissions ?? [])
      .filter(fs => {
        return fs.answers.find(a => a.question.name === graphDataSourceName);
      })
      .map(fs => {
        const answer = fs.answers.find(a => a.question.name === graphDataSourceName);
        return { answer: answer, formSubmission: fs } as FormSubmissionAnswerMerged;
      });
    },
    [data?.user?.formSubmissions]
  );

  const answerForQuestion = useCallback((q: FormQuestion) => {
    switch (q.questionType) {
      case FormQuestionType.SELECT:
      case FormQuestionType.MULTISELECT:
        const selections = formCtx.formSubmission?.answers.find(a => a.question.id === q.id)?.selections;
        if (selections) {
          return q.options?.filter(o => selections.find(s => s.id === o.id)).map(o => o.text).join(',');
        }
        break;
      default:
        return formCtx.formSubmission?.answers.find(a => a.question.id === q.id)?.answer;
    }

    return undefined;
  }, [formCtx.formSubmission]);

  const qc = formCtx.questionCache;
  const highText = useMemo(() => {
    const q = qc != null ? TypedReflect.ownKeys(qc).find(p => qc[p]?.name === ratingHighTextQuestionName) : undefined;
    return typeof q === 'string' && qc != null ? answerForQuestion(qc[q]) : undefined;
  }, [formCtx.formSubmission, formCtx.questionCache]);
  const lowText = useMemo(() => {
    const q = qc != null ? TypedReflect.ownKeys(qc).find(p => qc[p]?.name === ratingLowTextQuestionName) : undefined;
    return typeof q === 'string' && qc != null ? answerForQuestion(qc[q]) : undefined;
  }, [formCtx.formSubmission, formCtx.questionCache]);
  const otherFormAnswers = useMemo(() => {
    const questions = qc != null ? TypedReflect.ownKeys(qc).map(qn => qc[qn]).filter(
      q =>
        q.name !== ratingLowTextQuestionName &&
        q.name !== ratingHighTextQuestionName &&
        q.questionType !== FormQuestionType.RATING &&
        q.questionType !== FormQuestionType.PASSWORD
    ) : [];
    return (formCtx.formSubmission?.answers ?? [])
      .filter(a => questions.find(fa => fa.id === a.question.id))
      .map(a => {
        return { answer: a, question: questions.find(fa => fa.id === a.question.id) };
      });
  }, [formCtx.formSubmission]);

  const dateForFormsubmission = (formSubmission: FormSubmission, answer?: FormSubmissionAnswer) => {
    if (question.questionType === FormQuestionType.GRAPHWITHDATE && answer) {
      const q = Object.values(formCtx.questionCache ?? {}).find(x => x.name === graphDateName);

      if (q) {
        const answer = formSubmission.answers.find(a => a.question.id === q.id);

        if (answer?.answer) {
          const date = new Date(answer.answer);
          if (date) {
            return date;
          }
        }
      }
    }

    return formSubmission.completedAt ? new Date(formSubmission.completedAt) : new Date();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={toggle} aria-labelledby="Form Graph">
      <DialogTitle>
        {question.question?.text?.text ?? question.text.text}
      </DialogTitle>
      <DialogContent dividers>
        {loading ?
          <CircularProgress color="primary" /> :
          (
            <Grid container justify="center">
              <Grid item>
                <Printable
                  printActions={
                    <Grid container justify="flex-end">
                      <Grid item>
                        <PrintableButton download />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  }
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{studentCtx?.student?.firstName} {studentCtx?.student?.lastName}</Typography>
                      <Typography variant="h6">{studentCtx?.student?.advisor?.firstName} {studentCtx?.student?.advisor?.lastName}</Typography>
                    </Grid>
                    {otherFormAnswers.length > 0 && (
                      <Grid item xs={12}>
                        {otherFormAnswers.map(a => (
                          <Grid item key={`form_graph_answer_${a.answer.id}`}>
                            <Typography variant="subtitle2">{a.question?.text?.text ?? a.question?.name}</Typography>
                            <Typography variant="body2">{a.question ? answerForQuestion(a.question) : ''}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <Grid item>
                      <Divider />
                    </Grid>
                  </Grid>
                  <LineChart
                    width={800}
                    height={500}
                    yAxisLabel={highText && lowText ? `${lowText} - ${highText}` : 'Answers'}
                    data={
                      answers.map(a => {
                        return {
                          value: a.answer?.answer ? Number(a.answer.answer) : 0,
                          date: dateForFormsubmission(a.formSubmission, a.answer),
                          color: theme.palette.primary.main
                        } as GraphData;
                      }).sort((a, b) => {
                        return a.date.getTime() - b.date.getTime();
                      })
                    }
                  />
                </Printable>
              </Grid>
            </Grid>
          )
        }
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <Button variant="text" onClick={toggle}>
              <LanguageString groupName="GENERAL" resourceName="CANCEL" defaultText="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const FormGraph: FormFieldComp = ({ question }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggle = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      <Button onClick={toggle}>{question.name}</Button>
      <FormGraphDialog toggle={toggle} open={dialogOpen} question={question} />
    </>
  );
};
