import { Grid, MenuItem } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import React from 'react';
import { TextField, TextFieldProps } from '..';
import { TaskActionType } from '../../dto';

type Props = Pick<TextFieldProps, Breakpoint | 'item' | 'name'>;
export const ActionTypeTextField: React.FC<Props> = ({ name, item, xs, sm, md, lg, xl }) => (
  <Grid {...{ item, xs, sm, md, lg, xl }}>
    <TextField name={name} label="Type" select>
      {Reflect.ownKeys(TaskActionType).map(tp => (
        <MenuItem key={tp.toString()} value={tp.toString()}>
          {tp.valueOf()}
        </MenuItem>
      ))}
    </TextField>
  </Grid>
);
