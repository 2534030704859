import { useEffect, useState } from 'react';
import { ExtApprovalStatus } from 'tuapath-server';
import { getTRPCClient } from './getTRPCClient';

export const getApprovalStatus = (mtsId: number, activityTypeId: number, userActivityTypeId: number | undefined) => {
  const client = getTRPCClient();
  return client.query('approvalRequested', { mtsId, activityTypeId, userActivityTypeId });
};

export const useApprovalStatus = (mtsId: number, activityTypeId: number, userActivityTypeId: number | undefined) => {
  const [{ approvalRequested, loading }, setApprovalStatus] = useState<{ loading: boolean, approvalRequested?: ExtApprovalStatus }>({ loading: true });

  useEffect(() => {
    void getApprovalStatus(mtsId, activityTypeId, userActivityTypeId).then(val => {
      setApprovalStatus({ loading: false, approvalRequested: val });
    });
  }, []);

  return { approvalRequested, loading };
};
