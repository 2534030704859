import { Box, Button, CircularProgress, Grid, List, ListItem, ListItemText, Toolbar } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TaskProps } from '.';
import { DynamicForm, LanguageString } from '..';
import * as DTO from '../../dto';
import { useFormAndSubmission, useFormSubmissions, useResetCompletedAssignment } from '../../hooks';

const dt = (dateStr: string) => format(new Date(dateStr), 'MM/dd/yy h:mm a');
const submissionDateText = (fs: DTO.FormSubmission) =>
  fs.completedAt != null ? `${dt(fs.completedAt)}` : fs.startedAt != null ? `${dt(fs.startedAt)} Previous Save` : 'No Date';

export const FormTask: React.FC<TaskProps & { formId: number }> = ({ user, addButtons, student, assignment, formId, onComplete }) => {
  const loc = useLocation();
  const nocache = new URLSearchParams(loc.search).get('nocache');
  const { data: fData } = useFormAndSubmission({ variables: { formId } });
  const variables: Parameters<typeof useFormSubmissions>[0]['variables'] = {
    userId: student?.id ?? 0,
    formId,
    assignmentId: assignment?.id,
    includes: DTO.IncludeSubmissionTypes.SUBMITTED_AND_LAST_SAVED
  };
  const dataDate = useRef<Date>();
  const [dataDate2, setDataDate] = useState<Date>();
  const { data, loading } = useFormSubmissions({
    fetchPolicy: 'cache-and-network',
    variables,
    onCompleted: (td) => {
      dataDate.current = new Date();
      setDataDate(dataDate.current);
    }
  });

  const [selectedSubmission, setSelectedSubmission] = useState<DTO.FormSubmission | undefined>(data?.user?.formSubmissions?.[0]);
  const fsSpecified = useRef(false);
  const fsLengthRef = useRef(data?.user?.formSubmissions?.length);

  const [resetAssignment] = useResetCompletedAssignment({ refetchQueries: ['getSubmissions'] });

  const handleSelectFs = (fs: DTO.FormSubmission | undefined) => {
    fsSpecified.current = true;
    setSelectedSubmission(fs);
  };

  const handleResetClick = () => (assignment != null ? resetAssignment({ variables: { input: { assignmentId: assignment.id } } }) : null);

  useEffect(() => {
    if (user.id !== student?.id && user.roles?.includes(DTO.Role.ADVISOR)) {
      addButtons([
        <Button key="1" onClick={handleResetClick}>
          <LanguageString groupName="FORM_LABELS_ERRORS" resourceName="RESET" defaultText="Reset" />
        </Button>
      ]);
    } else {
      addButtons([]);
    }
  }, [formId]);

  const form = fData?.form;
  const formSubmissions = data?.user?.formSubmissions != null ? [...data?.user?.formSubmissions] : [];
  const prevSaved = formSubmissions.length > 0 && formSubmissions[0].completedAt == null;

  const curSubsLen = fsLengthRef.current;
  if (formSubmissions?.length) {
    if (
      curSubsLen !== formSubmissions.length ||
      (!fsSpecified.current && (selectedSubmission == null || !formSubmissions.includes(selectedSubmission)))
    ) {
      fsLengthRef.current = formSubmissions.length;
      setSelectedSubmission(formSubmissions[0]);
    }
  }

  if (loading && data?.user == null) {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  return form == null ? null : (
    <Box>
      {selectedSubmission?.completedAt != null ?
        <DynamicForm
          formInst={form}
          formSubmissionInst={selectedSubmission}
          userId={student?.id}
          disabled={true}
          disableCache={Boolean(nocache)}
          autoloadFormSubmission={true}
          onFormSubmitted={() => onComplete?.()}
          onError={() => onComplete?.()}
          showSubmitButton={false}
          autoSave={false}
          disabledDownloadButton={true}
          retrieveDate={dataDate2?.toISOString()}
        /> :
        <DynamicForm
          formId={formId}
          userId={student?.id}
          assignmentId={assignment?.id}
          disabled={assignment == null}
          disableCache={Boolean(nocache)}
          autoloadFormSubmission={formSubmissions?.[0] == null && assignment?.id != null && true}
          formSubmissionInst={formSubmissions?.[0]}
          onFormSubmitted={() => onComplete?.()}
          onError={() => onComplete?.()}
          showSubmitButton={false}
          autoSave={true}
          disabledDownloadButton={true}
          retrieveDate={dataDate2?.toISOString()}
        />
      }
      {formSubmissions?.length && form.allowMultipleSubmissions ? (
        <>
          <Box bgcolor="primary.light" color="primary.contrastText" mt="20px">
            <Toolbar>
              <LanguageString groupName="SITE_CONTENT" resourceName="PREV_SUBMISSIONS" />
            </Toolbar>
          </Box>
          <List>
            {!prevSaved &&
              <ListItem button selected={selectedSubmission == null} onClick={() => handleSelectFs(undefined)}>
                <ListItemText primary="New" />
              </ListItem>
            }
            {formSubmissions.map(fs => (
              <ListItem key={fs.id} button selected={fs.id === selectedSubmission?.id} onClick={() => handleSelectFs(fs)}>
                <ListItemText primary={submissionDateText(fs)} />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
    </Box>
  );
};
