import { ApolloError, PureQueryOptions } from '@apollo/client';
import { Button, ButtonProps, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormQuestionType } from 'tuapath-common/generated/schema';
import { FormContainerContext, LanguageString } from '..';
import { TypedReflect } from '../../common';
import { DynamicFormProvider, SiteContext, StudentContext, UserContext } from '../../contexts';
import * as DTO from '../../dto';
import { updateFormAndSubmission, useFormAndSubmission, useSubmitForm } from '../../hooks';
import { convertFsToValues, convertToFormId, getQuestionFormId, isDependsOnSatisfied, IValues, QuestionCache } from './common';
import { FormElement, layoutDir } from './FormElement';
import { FormFooter } from './FormFooter';
import { FormHeader } from './FormHeader';

const ContentMessage: React.FC<{ message: string; isVisible?: boolean; icon?: string }> = ({ message, isVisible }) => {
  return isVisible !== false ? <div>{message}</div> : null;
};

const getDefaultValue = (question: DTO.FormQuestion) => {
  if (question.defaultValue == null) return null;
  const { defaultValue, questionType } = question;

  switch (questionType) {
    case DTO.FormQuestionType.BOOLEAN:
      return defaultValue === 'true';
    case DTO.FormQuestionType.RATING:
      return question.minRateLevel ?? 1;
    case DTO.FormQuestionType.SELECT: {
      // if the options aren't loaded yet just return null
      if (defaultValue.startsWith(':') && question.options == null) return null;

      const indexRslt = defaultValue.match(/^:(\d+|first|last):$/);
      if (indexRslt && question.options) {
        const defaultIndex = indexRslt[1];
        if (Number.isInteger(defaultIndex)) {
          const rslt = Number(indexRslt[1]);
          if (question.options.length > rslt) return question.options[rslt].id;
        } else {
          switch (defaultIndex) {
            case 'first':
              return question.options[0].id;
            case 'last':
              return question.options[question.options.length - 1].id;
          }
        }
      }

      const rslt = JSON.parse(defaultValue) as number[] | number;
      return Array.isArray(rslt) ? rslt[0] : rslt;
    }
    case DTO.FormQuestionType.MULTISELECT: {
      const rslt = JSON.parse(defaultValue) as number[] | number;
      return Array.isArray(rslt) ? rslt : [rslt];
    }
    case DTO.FormQuestionType.DATE:
    case DTO.FormQuestionType.DATETIME:
    case DTO.FormQuestionType.DUEDATE:
    case DTO.FormQuestionType.TIME:
      return new Date(defaultValue);
    default:
      return defaultValue;
  }
};

export interface DynamicFormProps {
  formId?: number;
  formName?: string;
  formInst?: DTO.Form;
  assignmentId?: number;
  userId?: number;
  loadSubmission?: boolean;
  showSubmitButton?: boolean;
  // TODO: Change this to LanguageString
  submitText?: string;
  autoSave?: boolean;
  isReadOnly?: boolean;
  disabled?: boolean;
  disableCache?: boolean;
  renderFormTitle?: boolean;
  autoloadFormSubmission: boolean;
  disabledDownloadButton?: boolean;
  formSubmissionId?: number;
  formSubmissionInst?: DTO.FormSubmission;
  shouldAutoExpandTextAreas?: boolean;
  shouldScrollToTopOnSubmission?: boolean;
  shouldSubmitOnChange?: boolean;
  shouldOnlySubmitIfDirty?: boolean;
  retrieveDate?: string;
  buttons?: React.ReactElement<ButtonProps>;
  overrideFormSubmissionInput?: DTO.SubmitFormInput;
  refreshQueries?: PureQueryOptions[] | string[];
  shouldRemovePaswordFields?: boolean;

  onFormSubmitCancelled?: () => void;
  onFormSubmitted?: (data?: { submitForm: DTO.SubmitFormResult }, error?: ApolloError) => void;
  onFormSaved?: (data?: { submitForm: DTO.SubmitFormResult }, error?: ApolloError) => void;
  overrideSubmitHandler?: (input: DTO.SubmitFormInput, form: DTO.Form, helpers: FormikHelpers<IValues>) => void;
  onIsSubmittingChange?: (isSubmitting: boolean) => void;
  onFormLoaded?: (form: DTO.Form, submission?: DTO.FormSubmission) => void;
  onError?: () => void;
}

export interface IDynamicFormState {
  formIsDirty: boolean;
}

function getQuestionAnswers(questions: { [qId: string]: DTO.FormQuestion }, values: IValues) {
  const answers: DTO.FormAnswerInput[] = [];
  for (const qId of TypedReflect.ownKeys(questions)) {
    const question = questions[qId];
    const value = values[getQuestionFormId(question)];
    if (hasValue(value) && isDependsOnSatisfied(values, question)) {
      switch (question.questionType) {
        case DTO.FormQuestionType.TEXT:
        case DTO.FormQuestionType.BOOLEAN:
        case DTO.FormQuestionType.PASSWORD:
        case DTO.FormQuestionType.NUMBER:
        case DTO.FormQuestionType.INFORMATION:
          answers.push({
            questionId: question.id,
            text: String(value)
          });
          break;
        case DTO.FormQuestionType.DATE:
        case DTO.FormQuestionType.DATETIME:
        case DTO.FormQuestionType.DUEDATE:
        case DTO.FormQuestionType.TIME:
          answers.push({
            questionId: question.id,
            text: value instanceof Date ? value.toUTCString() : String(value)
          });
          break;
        case DTO.FormQuestionType.MULTISELECT:
          if (Array.isArray(value)) {
            answers.push({
              questionId: question.id,
              selections: value.map(v => String(v))
            });
          } else {
            throw new Error('Incorrect value for MULTISELECT question');
          }
          break;
        case DTO.FormQuestionType.SELECT:
          answers.push({
            questionId: question.id,
            selections: [String(value)]
          });
          break;
        case DTO.FormQuestionType.RATING:
          answers.push({
            questionId: question.id,
            text: String(value)
          });
          break;
        case DTO.FormQuestionType.GRAPH:
        case DTO.FormQuestionType.GRAPHWITHDATE:
          break;
        default:
          throw new Error(`Unknown question type ${JSON.stringify(question)} value ${value}`);
      }
    }
  }

  return answers;
}

function addElementQuestions(formElements: DTO.FormElement[], questionCache: QuestionCache, removePasswordQuestions: boolean) {
  formElements.forEach(fe => {
    if (fe.questions) {
      fe.questions.forEach(question => {
        if (question.questionType !== DTO.FormQuestionType.PASSWORD || (question.questionType === DTO.FormQuestionType.PASSWORD && !removePasswordQuestions)) {
          questionCache[getQuestionFormId(question)] = question;
        }
      });
    }
    if (fe.childElements) addElementQuestions(fe.childElements, questionCache, removePasswordQuestions);
  });
}

function initializeQuestionCache(form: DTO.Form, removePasswordQuestions: boolean) {
  const questionCache = {};
  if (form.elements) addElementQuestions(form.elements, questionCache, removePasswordQuestions);

  return questionCache;
}

export type DynamicForm = {
  validateForm: (touchFields?: boolean) => Promise<boolean>;
  submitForm: () => Promise<void>;
  save: () => void;
  reset: () => void;
};

const hasValue = (val: IValues[keyof IValues]): val is Exclude<IValues[keyof IValues], null> => val != null && val !== '';

function addDefaultValues(questionCache: QuestionCache, lInitVals: IValues, isDisabled: boolean) {
  for (const questionFormId of TypedReflect.ownKeys(questionCache)) {
    const question = questionCache[questionFormId];
    const qType = question.questionType;
    const isDate = qType === FormQuestionType.DATE || qType === FormQuestionType.DATETIME || qType === FormQuestionType.TIME || qType === FormQuestionType.DUEDATE;
    if (lInitVals[questionFormId] == null) lInitVals[questionFormId] = getDefaultValue(question) ?? (isDate ? null : '');
  }

  return lInitVals;
}

function getInitialValues(submission: DTO.FormSubmission | undefined, cache: QuestionCache, isDisabled: boolean) {
  const questionCache = cache;
  const values = submission != null ? convertFsToValues(submission, questionCache) : {};

  addDefaultValues(questionCache, values, isDisabled);

  return values;
}

const DynamicFormInner: React.RefForwardingComponent<DynamicForm, DynamicFormProps> = (props, ref) => {
  const { formId, formName, userId, formSubmissionId, assignmentId, overrideSubmitHandler, autoSave, disableCache, shouldRemovePaswordFields } = props;
  const [formSubmission, setFormSubmission] = useState<DTO.FormSubmission>();
  const formContainerCtx = useContext(FormContainerContext);
  const userCtx = useContext(UserContext);
  const studentCtx = useContext(StudentContext);
  const siteCtx = useContext(SiteContext);
  const isSaving = useRef(false);

  if (!props.autoloadFormSubmission && formSubmission != null) setFormSubmission(undefined);

  const { loading: fsLoading, data: fsData, error: fsError } = useFormAndSubmission({
    fetchPolicy: disableCache === true ? 'network-only' : 'cache-first',
    variables: {
      // TODO: Wrap this component in a parent that gets the form only when a form is not passed
      formId: props.formInst?.id ?? formId,
      formName,
      userId: props.formSubmissionInst == null ? props.autoloadFormSubmission === false && !formSubmissionId ? undefined : userId : undefined,
      submissionId: props.formSubmissionInst == null ? formSubmissionId : undefined,
      assignmentId: props.formSubmissionInst == null ? assignmentId : undefined
    }
  });

  const formQuestions = useRef<QuestionCache>({});
  const initialValues = useRef<IValues>({});
  const formik = useRef<FormikProps<IValues> | null>(null);
  const localIsSubmitting = useRef(false);
  const localIsSubmitted = useRef(props.isReadOnly === true);
  const formRef = useRef(fsData?.form);
  const fsRef = useRef(fsData?.user?.formSubmission);
  const retDate = useRef<string>();
  retDate.current = props.retrieveDate;
  formRef.current = props.formInst ?? fsData?.form;
  fsRef.current = props.formSubmissionInst ?? fsData?.user?.formSubmission;
  if (fsData && props.formInst == null && props.formSubmissionInst == null) {
    if (fsData.user && fsData.user.formSubmission !== formSubmission && props.autoloadFormSubmission) {
      setFormSubmission(fsData.user.formSubmission);
    }
    if (fsData.form && props.onFormLoaded) {
      props.onFormLoaded(fsData.form, fsData.user?.formSubmission);
    }
  }
  if (props.formInst != null && props.formSubmissionInst != null && formSubmission !== props.formSubmissionInst) setFormSubmission(props.formSubmissionInst);
  useEffect(() => {
    return () => {
      if (autoSave === true) void save();
    };
  }, [formId, formName, userId, autoSave]);
  useEffect(() => {
    if (formContainerCtx?.setForm) {
      formContainerCtx.setForm(fsData?.form);
    }
  }, [fsData]);

  formQuestions.current = useMemo(
    () => formRef.current != null ? initializeQuestionCache(formRef.current, shouldRemovePaswordFields != undefined ? shouldRemovePaswordFields : false) : {},
    [formRef.current?.id ?? -1, shouldRemovePaswordFields]
  );

  let fakeSubmission: DTO.FormSubmission | undefined;
  if (formRef.current && props.overrideFormSubmissionInput && !formSubmission) {
    // Should set a fake form submission from the passed submit input
    fakeSubmission = {
      id: -1,
      percentComplete: 100,
      startedAt: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      completedAt: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      modifiedAt: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      submittedBy: studentCtx?.student ? studentCtx.student : { id: -1 } as DTO.User,
      form: formRef.current,
      answers: []
    };

    if (props.overrideFormSubmissionInput.answers) {
      let id = 100;
      for (const answer of props.overrideFormSubmissionInput.answers) {
        for (const questionFormId of TypedReflect.ownKeys(formQuestions.current)) {
          const question = formQuestions.current[questionFormId];
          if (question.id === answer.questionId && fakeSubmission) {
            fakeSubmission.answers.push({
              id: id,
              answer: answer.text,
              question: question,
              selections: answer.selections?.map(s => ({ id: String(s), text: '' }))
            });
            id += 1;
          }
        }
      }
    }
  }

  const isDisabled = useMemo(() => props.disabled === true || (formSubmission?.completedAt != null && !formRef.current?.allowMultipleSubmissions), [
    formSubmission ? formSubmission.id : -2,
    props.disabled,
    formRef.current?.allowMultipleSubmissions
  ]);

  initialValues.current = useMemo(
    () =>
      formRef.current != null && fsRef.current != null
        ? getInitialValues(fsRef.current, formQuestions.current, isDisabled)
        : fakeSubmission && formRef.current != null
          ? getInitialValues(fakeSubmission, formQuestions.current, isDisabled)
          : addDefaultValues(formQuestions.current, {}, isDisabled),
    [fsRef.current?.id ?? -2, formRef.current?.id ?? -1]
  );

  const [submitFormMutation] = useSubmitForm({
    onCompleted: submitData => {
      localIsSubmitting.current = true;
      if (formik.current) formik.current.setSubmitting(false);
      if (isSaving.current) isSaving.current = false;
    }
  });

  useEffect(() => () => formContainerCtx?.setButtons?.([]), []);
  useEffect(() => {
    formik.current?.setStatus({ ...formik.current.status, isReadOnly: props.isReadOnly === true || fsData?.form.isReadonly, disabled: isDisabled });
  }, [isDisabled, props.isReadOnly, fsData?.form.isReadonly]);

  async function validateForm(touchFields?: boolean): Promise<boolean> {
    const validation = await formik.current?.validateForm();
    if (touchFields === true) {
      formik.current?.setTouched(Reflect.ownKeys(formik.current.initialValues).reduce((pv, cv) => {
        pv[cv] = true;
        return pv;
      }, {} as { [fn: PropertyKey]: boolean }));
    }
    return !validation || (validation && Object.keys(validation).length <= 0);
  }

  async function submitForm() {
    if (props.shouldOnlySubmitIfDirty && !formik.current?.dirty) {
      if (props.onFormSubmitCancelled) props.onFormSubmitCancelled();
      return;
    }
    if (formik.current) await formik.current.submitForm();
  }

  const callSubmitMutation = async (formInput: DTO.SubmitFormInput, form: DTO.Form, helpers: FormikHelpers<IValues>) => {
    if (overrideSubmitHandler) {
      overrideSubmitHandler(formInput, form, helpers);
      if (formik.current) formik.current.setSubmitting(false);
    } else {
      await submitFormMutation({
        variables: { input: formInput },
        update: (store, { data: rsltData }) => {
          if (props.formSubmissionInst == null) {
            updateFormAndSubmission(
              store,
              {
                formId: formName == null ? formRef.current?.id : undefined,
                formName,
                userId: props.autoloadFormSubmission === false && !props.formSubmissionId ? undefined : userId,
                submissionId: formSubmissionId,
                assignmentId
              },
              ['user', 'formSubmission'],
              rsltData?.submitForm.formSubmission
            );
          }

          if (formInput.saveOnly && rsltData && props.onFormSaved) props.onFormSaved(rsltData);
          if (!formInput.saveOnly && rsltData && props.onFormSubmitted) props.onFormSubmitted(rsltData);
        },
        refetchQueries: props.refreshQueries?.length ? ['getSubmissions', ...props.refreshQueries] : ['getSubmissions'],
        onError: (d1) => {
          if (props.onError) props.onError();
        }
      });
      if (formik.current) formik.current.resetForm({ values: formik.current.values });
    }
  };

  const save = async () => {
    const fmk = formik.current;
    if (!fmk?.dirty || fmk?.isSubmitting) return;
    if (!localIsSubmitting.current && !localIsSubmitted.current && formRef.current && !props.isReadOnly && fmk) {
      if (Reflect.ownKeys(fmk.values).length === 0) return;
      const fsId = fsRef.current?.id ?? undefined;
      isSaving.current = true;
      const answers = getQuestionAnswers(formQuestions.current, fmk.values);
      if (answers.length > 0) {
        await callSubmitMutation(
          {
            formId: formRef.current.id,
            answers,
            userId,
            formSubmissionId: fsId,
            saveOnly: true,
            assignmentId,
            retrievedAt: retDate.current
          },
          formRef.current,
          fmk
        );
      }
    }
  };

  function reset() {
    if (formik.current) {
      formik.current.resetForm();
    }
  }

  async function handleSubmit(values: IValues, helpers: FormikHelpers<IValues>, form: DTO.Form, fs?: DTO.FormSubmission) {
    await callSubmitMutation(
      {
        formId: form.id,
        answers: getQuestionAnswers(formQuestions.current, values),
        userId,
        formSubmissionId: fs?.id ?? undefined,
        saveOnly: false,
        assignmentId,
        retrievedAt: props.retrieveDate
      },
      form,
      helpers
    );
  }

  // TODO: Error messages need to be LanguageStrings
  async function handleValidateForm(form: DTO.Form, values: IValues, fs?: DTO.FormSubmission): Promise<FormikErrors<IValues> | undefined> {
    const errors = {};
    if (formik.current == null) throw new Error('Form not ready for validation');

    const addError = (qFormId: keyof QuestionCache, error: string) => {
      const errRec = errors[qFormId];
      if (errRec == null) errors[qFormId] = error;
      else if (Array.isArray(errRec)) errRec.push(error);
      else errors[qFormId] = [errRec, error];
    };

    if (form && form.elements) {
      for (const qFormId of TypedReflect.ownKeys(formQuestions.current)) {
        const question = formQuestions.current[qFormId];
        // Don't validate questions if they are not enabled
        if (isDependsOnSatisfied(values, question)) {
          const currentValue = values[qFormId];
          if (question.isRequired && !hasValue(currentValue)) {
            addError(qFormId, '* Required');
          }
          if (question.minLength) {
            if (typeof currentValue !== 'string' || currentValue.length < question.minLength) {
              addError(qFormId, `* Must be at least ${question.minLength} characters`);
            }
          }
          if (question.maxLength) {
            if (typeof currentValue === 'string' && currentValue.length > question.maxLength) {
              addError(qFormId, `* Must be less than ${question.maxLength} characters`);
            }
          }
          if (question.match != null) {
            if (question.match.startsWith(':qid:')) {
              const mqFormId = convertToFormId(Number(question.match.substr(5)));
              const mQuestion = formQuestions.current[mqFormId];
              if (mQuestion == null) {
                throw new Error('Form structure error - cannot match non-existing question');
              } else if (values[mqFormId] !== currentValue) {
                addError(qFormId, `* ${mQuestion.text.text}s don't match`);
              }
            } else if (hasValue(currentValue) && !new RegExp(question.match).test(String(currentValue))) {
              addError(qFormId, '* Invalid input');
            }
          }
        }
      }
    }

    if (Object.keys(errors).length <= 0) {
      if (props.shouldSubmitOnChange === true) {
        await callSubmitMutation(
          {
            formId: form.id,
            answers: getQuestionAnswers(formQuestions.current, values),
            userId,
            formSubmissionId: fs?.id ?? undefined,
            saveOnly: false,
            assignmentId,
            retrievedAt: props.retrieveDate
          },
          form,
          formik.current
        );
      }

      return undefined;
    }

    return errors;
  }

  const control: DynamicForm = {
    validateForm,
    submitForm,
    save,
    reset
  };

  useImperativeHandle(ref, () => control);
  const percentCorrect = formSubmission?.percentCorrect;

  const ErrorMessage: React.FC<{ message: string }> = ({ message }) => <ContentMessage isVisible={true} icon="error" message={message} />;
  const buttons = useMemo(
    () => [
      <Button onClick={submitForm} disabled={isDisabled} key={1}>
        Submit
      </Button>
    ],
    [formSubmission, formSubmission?.completedAt, props.disabled]
  );

  if (!props.formId && !props.formName && props.formInst == null) {
    return <ErrorMessage message="The form you requested doesn't exist. Please contact support." />;
  }

  if (fsLoading) {
    return <CircularProgress color="primary" />;
  } else if (fsError) {
    return <ErrorMessage message="Oops. Something went wrong. Please contact support." />;
  } else if (fsData && formRef.current != null) {
    const form = formRef.current;

    const preferredLanguage = userCtx.user.preferredLanguage?.code ?? navigator.language;
    const showTranslation = preferredLanguage.startsWith(siteCtx.site?.defaultLanguage.code ?? 'undefined');
    const showCorrect = form.showCorrect;
    const status = {
      isReadOnly: props.isReadOnly === true || fsData.form.isReadonly === true,
      disabled: isDisabled,
      qc: formQuestions.current,
      ans: fsRef.current?.answers,
      showTranslation,
      showCorrect,
      user: userCtx.user,
      student: studentCtx?.student
    };

    return (
      <DynamicFormProvider
        form={fsData.form}
        formSubmission={formSubmission}
        assignmentId={assignmentId}
        questionCache={formQuestions.current}
      >
        <Formik
          initialValues={initialValues.current}
          onSubmit={(values, helpers) => handleSubmit(values, helpers, form, fsRef.current)}
          validate={async values => handleValidateForm(form, values, fsRef.current)}
          initialStatus={status}
          enableReinitialize={true}
        >
          {formikVar => {
            if (formContainerCtx && formContainerCtx.ref) formContainerCtx.ref.current = control;
            if (formContainerCtx?.setButtons && formContainerCtx.buttons !== buttons) {
              formContainerCtx.setButtons(buttons);
            }
            formik.current = formikVar;
            const { isSubmitting } = formikVar;
            if (localIsSubmitting.current !== isSubmitting && props.onIsSubmittingChange) {
              localIsSubmitting.current = isSubmitting;
              props.onIsSubmittingChange(isSubmitting);
            }
            return form && form.elements ? (
              <Form className={'dynamic-form ' + form.name}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <FormHeader
                      renderFormTitle={props.renderFormTitle}
                      form={form}
                      disabledDownloadButton={props.disabledDownloadButton}
                      formSubmissionId={formSubmissionId ?? formSubmission?.id}
                    />
                  </Grid>
                  {form.autoGrade && percentCorrect != null && (
                    <Grid item>
                    <Alert severity={percentCorrect < 34 ? 'error' : percentCorrect < 75 ? 'warning' : 'success'}>
                      <AlertTitle>
                        {percentCorrect < 34
                          ? <LanguageString groupName="FORM" resourceName="GRADE_BOTTOM_TIER" />
                          : percentCorrect < 75
                            ? <LanguageString groupName="FORM" resourceName="GRADE_MIDDLE_TIER" />
                            : <LanguageString groupName="FORM" resourceName="GRADE_TOP_TIER" />
                        }
                      </AlertTitle>
                      <strong>Score {percentCorrect}%</strong>
                    </Alert>
                    </Grid>
                  )}
                  <Grid className="element-children-container" container item direction={layoutDir(form.layoutType)} wrap="nowrap" spacing={2}>
                    {form.elements?.map(el => (
                      <Grid key={el.id} className="element-child-container" item xs>
                        <FormElement element={el} values={formikVar.values} shouldRemovePaswordFields={shouldRemovePaswordFields != undefined ? shouldRemovePaswordFields : false} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <FormFooter disabled={isDisabled} isSubmitting={isSubmitting} submitButtonEnabled={props.showSubmitButton !== false} submitButtonText={props.submitText} />
              </Form>
            ) : (
              <ErrorMessage message="The form you requested doesn't exist. Please contact support." />
            );
          }}
        </Formik>
      </DynamicFormProvider>
    );
  } else {
    return <ErrorMessage message="Uknown Error" />;
  }
};

export const DynamicForm = React.forwardRef(DynamicFormInner);
export default DynamicForm;
