import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Report } from 'tuapath-common/generated/schema';

type Results = { reports: Array<Report> };

export function useReports(options: QueryHookOptions<Results>) {
  const query = gql`
    query reports {
      reports {
        id
        name
        url
      }
    }
  `;

  return useQuery<Results>(query, options);
}
