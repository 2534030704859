import { Button, Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { DialogTitle, LanguageString } from '../Common';

export interface PBIUnsavedChangesDialogProps {
  closeAgree: () => void;
  close: () => void;
}

export const PBIUnsavedChangesDialog: React.FC<PBIUnsavedChangesDialogProps> = props => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (<Dialog maxWidth="sm" fullWidth fullScreen={fullscreen} open={true} onClose={props.close} aria-label="Unsaved Changes Warning Dialog">
    <DialogTitle onClose={props.close}>
      <LanguageString groupName="REPORTING" resourceName="UNSAVED_CHANGES_DIALOG_TITLE" defaultText="Are you sure?" />
    </DialogTitle>
    <DialogContent dividers>
      <Grid container>
        <Grid item container spacing={2}>
          <Grid item>
            <LanguageString groupName="REPORTING" resourceName="UNSAVED_CHANGES_DIALOG_TEXT" defaultText="There are unsaved changes, if you confirm, those changes will be permanently lost."/>
          </Grid>
          <Grid item>
            <Button
              onClick={() => props.closeAgree()}
              color="primary"
              variant="contained">
              <LanguageString groupName="REPORTING" resourceName="CONFIRM" defaultText="Confirm" />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => props.close()} color="primary" variant="contained">
              <LanguageString groupName="REPORTING" resourceName="CANCEL" defaultText="Cancel" />
            </Button>
          </Grid>
        </Grid>
      </Grid >
    </DialogContent >
  </Dialog >);
};

export default PBIUnsavedChangesDialog;
