import React, { useContext } from 'react';
import { useMemo } from 'react';
import { MTSContext, ChildRoutes } from '../../../contexts';
import {
  Grid,
  CircularProgress,
  Typography,
  Paper
} from '@material-ui/core';
import { MTSToolBar } from '../MTSToolBar';
import { format, addDays, differenceInDays } from 'date-fns';
import CalendarCustomViewRow from './CalendarCustomGroupedViewRow';
import clsx from 'clsx';
import { useClasses } from '../../../hooks';
import { MTSFooter } from '../MTSFooter';
import { startOfWeek } from 'date-fns';
import MTSHelpers from '../../../helpers/mtsHelpers';

export const CalendarCustomGroupedView: React.FC = () => {
  const classes = useClasses();
  const mtsCtx = useContext(MTSContext);

  const startDate = useMemo(() => {
    if (mtsCtx.startDate) {
      return startOfWeek(mtsCtx.startDate);
    }

    return new Date();
  }, [mtsCtx.startDate]);

  const weekdays = useMemo(() => {
    if (mtsCtx.startDate) {
      const days = [];
      const sow = startOfWeek(mtsCtx.startDate);

      for (let i = 0; i <= 6; i++) {
        days.push(format(addDays(sow, i), 'E'));
      }

      return days;
    }

    return [];
  }, [startDate]);

  const rows = useMemo(() => {
    const r: React.ReactNode[] = [];

    if (mtsCtx.mtsPeriods.length > 0) {
      const startPeriod = mtsCtx.mtsPeriods[0];
      const endPeriod = mtsCtx.mtsPeriods.length > 1 ? mtsCtx.mtsPeriods[mtsCtx.mtsPeriods.length - 1] : startPeriod;

      const beginningPeriodStartDate = MTSHelpers.getPeriodDates(startPeriod).startDate;
      const endingPeriodEndDate = MTSHelpers.getPeriodDates(endPeriod).endDate;

      const diff = Math.floor(differenceInDays(endingPeriodEndDate, beginningPeriodStartDate)) + 1;
      const weeks = Math.ceil((diff / 7.0));

      for (let i = 0; i < weeks; i++) {
        const sow = addDays(beginningPeriodStartDate, 7 * i);
        sow.setHours(8);
        const period = mtsCtx.mtsPeriods.find(p =>
          MTSHelpers.compareDateIgnoringTime(sow, MTSHelpers.dateFromString(p.startDate)) >= 0 &&
          MTSHelpers.compareDateIgnoringTime(sow, MTSHelpers.dateFromString(p.endDate, true)) <= 0
        );

        if (period) {
          r.push((
            <CalendarCustomViewRow
              key={`mts_custom_row_${period.id}_${i}`}
              mtsPeriod={period}
              periodStartDate={MTSHelpers.dateFromString(period.startDate)}
              periodEndDate={MTSHelpers.dateFromString(period.endDate, true)}
              weekStartDate={sow}
            />
          ));
        }
      }

      return r;
    }

    return [];
  }, [mtsCtx.mtsPeriods]);

  return (
    <Paper>
      <MTSToolBar />
      <Grid className={classes.mtsMonthRow} container direction="row" justify="space-evenly">
        <Grid item xs={12}>
          <Grid className={clsx([classes.mtsDayHeaderHeight, classes.mtsMonthDayHeader])} container direction="row" justify="space-evenly">
            {mtsCtx.loading && (
              <CircularProgress color="primary" />
            )}

            {!mtsCtx.loading && (
              <>
                {weekdays.map(wday => (
                  <Grid item key={`day_of_week${wday}`} xs>
                    <Typography variant="h6">{wday}</Typography>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        {rows}

        <ChildRoutes />
      </Grid>

      <MTSFooter />
    </Paper>
  );
};
export default CalendarCustomGroupedView;
