import gql from 'graphql-tag';
import { languageStringMinFrag, milestoneFrag, sitePathsMilestoneFrag } from '.';

export const milestoneGroupFrag = gql`
  ${languageStringMinFrag}
  ${milestoneFrag}

  fragment msGroup on MilestoneGroup {
    id
    sortIndex
    color
    name {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    milestones {
      ...MilestoneFrag
    }
  }
`;

export const sitePathsMilestoneGroupFrag = gql`
  ${languageStringMinFrag}
  ${sitePathsMilestoneFrag}

  fragment msGroup on MilestoneGroup {
    id
    sortIndex
    color
    name {
      ...LangStringMinFrag
    }
    description {
      ...LangStringMinFrag
    }
    milestones {
      ...MilestoneFrag
    }
  }
`;
