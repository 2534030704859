import * as React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { MilestoneGroup } from 'tuapath-common/generated/schema';
import { StudentContext } from '../../contexts';
import { usePath } from '../../hooks/gql/usePath';

// TODO: Get this by id from the apollo cache
function getMsGroup(msGroups: MilestoneGroup[], groupId: number): MilestoneGroup | undefined {
  for (const msGroup of msGroups) {
    if (msGroup.id === groupId) return msGroup;
    if (msGroup.subGroups) {
      const childGrp = getMsGroup(msGroup.subGroups, groupId);
      if (childGrp) return childGrp;
    }
  }

  return;
}

export const MsGroupContext = React.createContext<MilestoneGroup | null>(null);

export const MsGroupProvider: React.FC = ({ children }) => {
  const stdCtx = useContext(StudentContext);
  const { groupId } = useParams<{ groupId: string }>();
  const { loading, data: pData } = usePath({ variables: { progId: stdCtx?.student.programIds[0] ?? -1 }, fetchPolicy: 'cache-first' });

  if (groupId == null) throw new Error('groupId param is required for MsGroupProvider');
  if (loading || !pData?.path || !pData.path.milestoneGroups) return <div>Loading...</div>;

  const grp = getMsGroup(pData.path.milestoneGroups, Number(groupId));

  if (grp) {
    return <MsGroupContext.Provider value={grp}>{children}</MsGroupContext.Provider>;
  }

  return null;
};
