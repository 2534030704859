import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Paper
} from '@material-ui/core';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
  addDays
} from 'date-fns';
import CalendarMonthViewCell from './CalendarMonthViewCell';
import { useClasses } from '../../../hooks';
import { ChildRoutes, MTSContext } from '../../../contexts';
import clsx from 'clsx';
import { GeneralErrorMessage } from '../../Common';
import { MTSToolBar } from '../MTSToolBar';
import { MTSFooter } from '../MTSFooter';
import { CalendarMonthSubmissionStatus } from './CalendarMonthSubmissionStatus';
import { useMemo } from 'react';

export const CalendarMonthView: React.FC = (props) => {
  const mtsCtx = useContext(MTSContext);

  const classes = useClasses();
  const selectedDate = mtsCtx.date;

  const monthStart = startOfMonth(selectedDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const weekdays = useMemo(() => {
    const days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(format(addDays(startDate, i), 'E'));
    }

    return days;
  }, [startDate]);

  const rows = useMemo(() => {
    const eachWeek = [];
    let daysOfWeek = [];

    let day = startDate;
    const end = endDate;
    let formattedDate;
    const dateFormat = 'd';

    let weekNumber = 1;
    while (day <= end) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        daysOfWeek.push(
          <CalendarMonthViewCell
            key={formattedDate}
            date={cloneDay}
            monthDate={selectedDate}
          />
        );

        day = addDays(day, 1);
      }

      eachWeek.push(
        <Grid className={classes.mtsMonthRow} container direction="row" justify="space-evenly" key={weekNumber}>{daysOfWeek}</Grid>
      );
      daysOfWeek = [];
      weekNumber += 1;
    }

    return eachWeek;
  }, [selectedDate]);

  if (mtsCtx.error) {
    return <GeneralErrorMessage message={mtsCtx.error.message} />;
  } else if (!selectedDate || isNaN(selectedDate.getTime())) {
    return <GeneralErrorMessage message="Oops, something was wrong with your date" />;
  }

  return (
    <Paper>
      <MTSToolBar />
      <CalendarMonthSubmissionStatus />

      <Grid container direction="row" className={classes.mtsCalendarMonth}>
        <Grid item xs={12}>
          <Grid className={clsx([classes.mtsDayHeaderHeight, classes.mtsMonthDayHeader])} container direction="row" justify="space-evenly">
            {mtsCtx.loading && (
              <CircularProgress color="primary" />
            )}

            {!mtsCtx.loading && (
              <>
                {weekdays.map(wday => (
                  <Grid item key={`day_of_week${wday}`} xs>
                    <Typography variant="h6">{wday}</Typography>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>

        {rows}

        <ChildRoutes />
      </Grid>

      <MTSFooter />
    </Paper>
  );
};

export default CalendarMonthView;
