import { Button, Dialog, DialogContent, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { Attachment } from '../../dto';
import { DialogTitle, LanguageString } from '../Common';

export interface FileBoxDeleteDialogProps {
  attachment: Attachment;
  close: () => void;
  deleteElement: (attachmentId: number, reason: string) => void;
}

interface IFormValues {
  deleteReason: string;
}

export const FileBoxDeleteDialog: React.FC<FileBoxDeleteDialogProps> = props => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formValidation = yup.object<IFormValues>().shape({
    deleteReason: yup.string().required('You must supply a reason.')
  });

  return (
    <Dialog maxWidth="sm" fullWidth fullScreen={fullscreen} open={true} onClose={props.close} aria-label="Confirm File Deletion Dialog">
      <DialogTitle onClose={props.close}>
        <LanguageString groupName="FILEBOX" resourceName="DELETE_DIALOG_TITLE" defaultText="Confirm Delete" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {<Formik<IFormValues>
            initialValues={{
              deleteReason: ''
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={formValidation}
            onSubmit={async (values: IFormValues) => {
              if (props.attachment) {
                props.deleteElement(props.attachment.id, values.deleteReason);
              }
            }}
          >
            {formikProps => {
              return (
                <Grid container direction="column" spacing={2}>
                  <Field validateOnBlur validateOnChange name="deleteReason">
                    {() => (
                      <TextField
                        fullWidth
                        disabled={false}
                        name="deleteReason"
                        label={<LanguageString groupName="FILEBOX" resourceName="DELETE_REASON" defaultText="Delete reason" />}
                        type="text"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.deleteReason ?? ''}
                        error={Boolean(formikProps.errors.deleteReason && (formikProps.touched.deleteReason || formikProps.submitCount > 0))}
                        helperText={formikProps.errors.deleteReason && (formikProps.touched.deleteReason || formikProps.submitCount > 0) ?
                          String(formikProps.errors.deleteReason) : ''}
                        role="textbox"
                        aria-label="Delete Reason"
                      />
                    )}
                  </Field>

                  <Grid item container spacing={2}>
                    <Grid item>
                      <Button onClick={() => formikProps.handleSubmit()} color="primary" variant="contained">
                        <LanguageString groupName="FILEBOX" resourceName="CONFIRMDELETE" defaultText="Delete" />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={() => props.close()} color="primary" variant="contained">
                        <LanguageString groupName="FILEBOX" resourceName="CANCEL" defaultText="Cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>);
            }}
          </Formik>}
        </Grid >
      </DialogContent >
    </Dialog >
  );
};

export default FileBoxDeleteDialog;
