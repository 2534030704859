import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { RoadmapMeetingFormSiteArgs, Site } from '../../dto';
import { formFrag } from './fragments';

type Variables = RoadmapMeetingFormSiteArgs;
export type SiteMeetingFormQuery = { site: Pick<Site, 'id' | 'roadmapMeetingForm'> };
export const siteImportActionsQuery = gql`
  ${formFrag}
  query siteMeetingForm($pathId: Int) {
    site {
      id
      roadmapMeetingForm(pathId: $pathId) {
        ...FormFrag
      }
    }
  }
`;

export function useSiteMeetingForm(options?: QueryHookOptions<SiteMeetingFormQuery, Variables>) {
  return useQuery<SiteMeetingFormQuery>(siteImportActionsQuery, options);
}

