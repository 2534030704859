import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { RoadmapMilestone, UpdateRoadmapMilestoneInput } from '../../dto';
import { roadmapMilestoneFrag } from './fragments';

type Result = { updateRoadmapMilestones: RoadmapMilestone[] };
type Variables = { input: UpdateRoadmapMilestoneInput[] };
export function useUpdateRoadmapMilestones(options?: MutationHookOptions<Result, Variables>) {
  const mutation = gql`
    ${roadmapMilestoneFrag}
    mutation updateRoadmapMilestones($input: [UpdateRoadmapMilestoneInput!]!) {
      updateRoadmapMilestones(input: $input) {
        ...RoadmapMilestoneFrag
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
}

export default useUpdateRoadmapMilestones;
