import { TypedReflect } from '../../common';
import { CustomPropertyType, FormQuery } from '../../dto';
import * as Queries from '../gql';

type QueriesType = (typeof Queries);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QParams<T extends (options: { variables: any }) => any> = Parameters<T>[0]['variables'];

type FormQueryNames = keyof typeof FormQuery;

type SQs = {[P in keyof QueriesType as `${P extends FormQueryNames ? P : never}`]: P};
type SupportedQueryNames = { [P in keyof SQs]: P }[keyof SQs];

export type SupportedQueries = {
  [P in SupportedQueryNames]: QueriesType[P];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QResults<T extends keyof SupportedQueries> = SupportedQueries[T] extends (options: { variables: any }) => infer R ? R : never;

export type FormAnswer = { id: number, text: string }[] | string | null;
export type QueryParams = {
  [id: number]: {
    name: string;
    property?: CustomPropertyType;
    value: FormAnswer;
  }
};

const getValue = (answer: FormAnswer) => typeof answer === 'string' ? answer : String(answer?.[0].id);

const getParamsForAdvisorsQuery = (qp: QueryParams): QParams<SupportedQueries['useAdvisors']> => ({
  params: TypedReflect.ownKeys(qp).map(q => ({ name: qp[q].name, value: getValue(qp[q].value), propId: qp[q].property?.id }))
});

const getParamsForQuery = (qp: QueryParams): {[index: string]: number | string} => {
  const params = {};
  for (const prop of TypedReflect.ownKeys(qp)) {
    params[qp[prop].property?.name ?? qp[prop].name] = Number(qp[prop].value);
  }

  return params;
};

export function convertToQueryParamStructure<T extends keyof SupportedQueries>(qname: T, qp: QueryParams, qa?: object): Parameters<SupportedQueries[T]>[0] {
  switch (qname) {
    case 'useAdvisors':
      return { variables: { ...getParamsForAdvisorsQuery(qp), ...qa } };
    case 'useAvailableRoles':
    case 'useCustomProperties':
    case 'useDepartments':
    case 'useFormSubmissions':
    case 'useLanguages':
    case 'usePath':
    case 'usePrograms':
    case 'useRocTypes':
    case 'useSiteMilestones':
    case 'useSiteMTSActivityTypes':
    case 'useSiteOrganizations':
      return { variables: { ...getParamsForQuery(qp), ...qa } };
    default:
      throw new Error(`Cannot use unsupported query ${qname}`);
  }
}
