import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { LanguageString } from '../Common';

interface FormFooterProps {
  isSubmitting: boolean;
  submitButtonEnabled: boolean;
  submitButtonText: string | undefined;
  disabled: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    }
  })
);

export const FormFooter: React.FC<FormFooterProps> = props => {
  const { submitForm } = useFormikContext();
  const classes = useStyles();

  return (
    <Grid container>
      {props.submitButtonEnabled && (
        <Grid item xs>
          <Button className={classes.root} disabled={props.isSubmitting || props.disabled} color="primary" variant="contained" onClick={submitForm}>
            {props.submitButtonText && <>{props.submitButtonText}</>}
            {(!props.submitButtonText || props.submitButtonText.length <= 0) && (
              <LanguageString groupName="GENERAL" resourceName="SUBMIT" defaultText="Submit" />
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default FormFooter;
