import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { AttachFileToAssignmentMutationArgs, Attachment } from '../../dto';
import { assignmentFrag } from './fragments';

type Result = { attachFileToAssignment: Attachment };
type Variables = AttachFileToAssignmentMutationArgs;
export const useAttachFileToAssignment = (options?: MutationHookOptions<Result, Variables>) => {
  const mutation = gql`
    ${assignmentFrag}
    mutation attachFileToAssignment($assignmentId: Int!, $file: Upload!) {
      attachFileToAssignment(assignmentId: $assignmentId, file: $file) {
        attachment {
          fileName
          url
        }
        assignment {
          ...AssignmentFrag
        }
      }
    }
  `;

  return useMutation<Result, Variables>(mutation, options);
};
export default useAttachFileToAssignment;
