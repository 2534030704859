import React, { useContext, useMemo, useState, useCallback } from 'react';
import {
  TableCell,
  TableSortLabel,
  Tooltip,
  TableCellProps,
  Icon,
  IconButton,
  Grid,
  Button,
  Paper,
  Popover,
  useTheme
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useClasses, UserResult, useDialogController } from '../../hooks';
import { SiteContext, useChildLinkOfParentWithName, UserContext } from '../../contexts';
import { StudentsOrder, MTSState, MTS, RoadmapMeetingType, Role } from '../../dto';
import { SortDirection, MTSView, StudentSortType } from 'tuapath-common/generated/schema';
import { LanguageString } from '../Common';
import { MeetingDialog } from '../../components/Roadmap/MeetingDialog';
import { format } from 'date-fns';
import { MTSPeriod } from 'tuapath-common/generated/schema';

export enum CaseLoadOrderColumn {
  Name = 'Name',
  TwoMonthsAgoMTS = 'TwoMonthsAgoMTS',
  TwoMonthsAgoDocs = 'TwoMonthsAgoDocs',
  TwoMonthsAgoHours = 'TwoMonthsAgoHours',
  TwoMonthsAgoWPRHours = 'TwoMonthsAgoWPRHours',
  LastMonthMTS = 'LastMonthMTS',
  LastMonthMTSDocs = 'LastMonthMTSDocs',
  LastMonthMTSHours = 'LastMonthMTSHours',
  LastMonthMTSWPRHours = 'LastMonthMTSWPRHours',
  CurrentMTS = 'CurrentMTS',
  CurrentMTSDocs = 'CurrentMTSDocs',
  CurrentMTSHours = 'CurrentMTSHours',
  CurrentMTSWPRHours = 'CurrentMTSWPRHours',
  MTSRequired = 'MTSRequired',
  SupportServices = 'SupportServices',
  CoachMeeting = 'CoachMeeting',
  LastMTSActivity = 'LastMTSActivity',
  LastRoadmapInteraction = 'LastRoadmapInteraction',
  Custom = 'Custom'
}

export interface CaseLoadOrder {
  order: StudentsOrder;
  column: CaseLoadOrderColumn;
  customPropName?: string;
}

interface CaseLoadTableHeaderCellProps extends TableCellProps {
  column: CaseLoadOrderColumn;
  order: CaseLoadOrder;
  content: React.ReactNode;
  click?: (column: CaseLoadOrderColumn, target: Element) => void;
  tooltip?: React.ReactChild;
}

interface CaseLoadTableCustomHeaderCellProps extends TableCellProps {
  column: CaseLoadOrderColumn;
  customPropName: string;
  order: CaseLoadOrder;
  content: React.ReactNode;
  click?: (column: CaseLoadOrderColumn, target: Element) => void;
}

interface MTSCaseLoadCellProps {
  mts?: MTS,
  user?: UserResult,
  period?: MTSPeriod
};

export const CaseLoadMeetingCell: React.FC<{ student: UserResult }> = ({ student }) => {
  const { controller: { setOpen: openMeetingDlg }, props: mProps } = useDialogController(false);
  const userCtx = useContext(UserContext);

  const clickedDate = useCallback(() => {
    openMeetingDlg();
  }, [openMeetingDlg]);

  return (
    <TableCell>
      {student.nextMeetingDate?.meeting?.date && (
        <>
          <Grid container justify="center">
            <Button onClick={clickedDate}>
              {format(new Date(student.nextMeetingDate.meeting.date), 'MM/dd/yy h:mm a')}
              <Icon>{student.nextMeetingDate.meeting.meetingType === RoadmapMeetingType.BY_PHONE ? 'phone' : 'person'}</Icon>
            </Button>
          </Grid>
          {userCtx.user.roles?.includes(Role.ADVISOR) && <MeetingDialog dprops={mProps} rmId={student.nextMeetingDate.id} userId={student.id} />}
        </>
      )}
    </TableCell>
  );
};

export const CaseLoadTableHeaderCell: React.FC<CaseLoadTableHeaderCellProps> = React.memo((props) => {
  const classes = useClasses();


  const textCell = (
    <TableSortLabel
      active={props.order.column === props.column}
      direction={props.order.column === props.column ? (props.order.order.sortDirection === SortDirection.ASC ? 'asc' : 'desc') : 'asc'}
      onClick={(e) => {
        if (props.click) {
          props.click(props.column, e.currentTarget);
        }
      }}
    >
      {props.content}
    </TableSortLabel>
  );

  return (
    <TableCell
      key={props.column}
      sortDirection={props.order.column === props.column ? (props.order.order.sortDirection === SortDirection.ASC ? 'asc' : 'desc') : false}
      align={props.align}
      size={props.size}
      className={props.size === 'small' ? classes.noPadding : ''}
    >
      {props.tooltip && (
        <Tooltip title={props.tooltip} placement="top">
          {textCell}
        </Tooltip>
      )}
      {!props.tooltip && (
        <>
          {textCell}
        </>
      )}
    </TableCell>
  );
});

export const CaseLoadTableCustomHeaderCell: React.FC<CaseLoadTableCustomHeaderCellProps> = React.memo((props) => {
  const classes = useClasses();

  const textCell = (
    <TableSortLabel
      active={props.order.column === props.column && props.order.customPropName === props.customPropName}
      direction={props.order.column === props.column ? (props.order.order.sortDirection === SortDirection.ASC ? 'asc' : 'desc') : 'asc'}
      onClick={(e) => {
        if (props.click) {
          props.click(props.column, e.currentTarget);
        }
      }}
    >
      {props.content}
    </TableSortLabel>
  );

  return (
    <TableCell
      key={props.column}
      sortDirection={props.order.column === props.column ? (props.order.order.sortDirection === SortDirection.ASC ? 'asc' : 'desc') : false}
      align={props.align}
      size={props.size}
      className={props.size === 'small' ? classes.noPadding : ''}
    >
      <>
        {textCell}
      </>
    </TableCell>
  );
});

export const CaseLoadMTSCell: React.FC<MTSCaseLoadCellProps> = React.memo(({ mts, user, period }) => {
  const classes = useClasses();
  const theme = useTheme();
  const siteCtx = useContext(SiteContext);

  const mtsMonth = useChildLinkOfParentWithName('mts', undefined, {
    items: [{ id: user?.id ?? 1, studentId: user?.id ?? 1, m: mts?.month, y: mts?.year }],
    paramMap: { id: 'id', studentId: 'studentId', m: 'month', y: 'year' },
    routeName: 'mts-month'
  });
  const mtsYear = useChildLinkOfParentWithName('mts', undefined, {
    items: [{ id: user?.id ?? 1, studentId: user?.id ?? 1, y: mts?.year }],
    paramMap: { id: 'id', studentId: 'studentId', y: 'year' },
    routeName: 'mts-year'
  });
  const mtsWeek = useChildLinkOfParentWithName('mts', undefined, {
    items: [{ id: user?.id ?? 1, studentId: user?.id ?? 1, d: 1, m: mts?.month, y: mts?.year }],
    paramMap: { id: 'id', studentId: 'studentId', d: 'date', m: 'month', y: 'year' },
    routeName: 'mts-week'
  });
  const mtsCustom = useChildLinkOfParentWithName('mts', undefined, {
    items: [{ periodId: period?.id, id: user?.id ?? 1, studentId: user?.id ?? 1 }],
    paramMap: { periodId: 'periodId', id: 'id', studentId: 'studentId' },
    routeName: 'mts-custom'
  });
  const mtsCustomWeek = useChildLinkOfParentWithName('mts', undefined, {
    items: [{ id: user?.id ?? 1, studentId: user?.id ?? 1, d: 1, m: mts?.month, y: mts?.year }],
    paramMap: { id: 'id', studentId: 'studentId', d: 'date', m: 'month', y: 'year' },
    routeName: 'mts-custom-week'
  });

  const monthLink = useMemo(() => {
    if (mts?.mtsPeriod && mtsCustom && mtsCustom.length > 0) {
      return mtsCustom[0].path;
    } else if (mts && mtsMonth && mtsMonth.length > 0) {
      return mtsMonth[0].path;
    }

    return '/';
  }, [mts, mtsMonth, mtsCustom]);

  const weekLink = useMemo(() => {
    if (mts?.mtsPeriod && mtsCustomWeek && mtsCustomWeek.length > 0) {
      return mtsCustomWeek[0].path;
    } else if (mts && mtsWeek && mtsWeek.length > 0) {
      return mtsWeek[0].path;
    }

    return '/';
  }, [mts, mtsCustomWeek, mtsWeek]);

  const yearLink = useMemo(() => {
    if (mts?.mtsPeriod && mtsYear && mtsYear.length > 0) {
      return mtsYear[0].path;
    }

    return '/';
  }, [mts, mtsYear]);

  const iconForMTSState = useMemo(() => {
    if (mts) {
      if (mts.state === MTSState.APPROVED) {
        return <Icon className={classes.success}>check_circle</Icon>;
      } else if (mts.state === MTSState.NOT_SUBMITTED) {
        return <Icon color="error">error</Icon>;
      } else if (mts.state === MTSState.SUBMITTED) {
        return <Icon style={{ color: theme.palette.warning.main }}>swap_vertical_circle</Icon>;
      }
    }

    return <Icon color="error">error</Icon>;
  }, [mts]);

  const iconForMTSAttachments = useMemo(() => {
    if (mts && mts.attachments && mts.attachments.length > 0) {
      return <Icon className={classes.success}>check_circle</Icon>;
    }

    return <Icon color="error">error</Icon>;
  }, [mts]);

  const handleClickMTS = useCallback(() => {
    console.log(`handle click: `, mts);

    if (!mts || !user) return;

    if (siteCtx.site?.defaultMtsView) {
      if (siteCtx.site.defaultMtsView === MTSView.week) {
        window.open(weekLink, '_blank');
      } else if (siteCtx.site.defaultMtsView === MTSView.year) {
        window.open(yearLink, '_blank');
      } else {
        window.open(monthLink, '_blank');
      }
    } else {
      window.open(monthLink, '_blank');
    }
  }, [mts, user, siteCtx.site, weekLink, yearLink, monthLink]);

  return (
    <TableCell align="left">
      <Grid container>

        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <LanguageString groupName="CASE_LOAD" resourceName="STATUS" defaultText="Status" />
            </Grid>
            <Grid item>
              <IconButton onClick={handleClickMTS}>
                {iconForMTSState}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <LanguageString groupName="CASE_LOAD" resourceName="DOCS" defaultText="Docs" />
            </Grid>
            <Grid item>
              <IconButton onClick={handleClickMTS}>
                {iconForMTSAttachments}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <LanguageString groupName="CASE_LOAD" resourceName="HOURS" defaultText="Hours" />
            </Grid>
            <Grid item>
              <Button onClick={handleClickMTS}>
                {mts?.totalHours ?? 0}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
            <LanguageString groupName="CASE_LOAD" resourceName="WPR_HOURS" defaultText="WPR" />
            </Grid>
            <Grid item>
              <Button onClick={handleClickMTS}>
                {mts?.totalWprHours ?? 0}
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </TableCell>
  );
});

export const CaseLoadMTSHeaderCell: React.FC<CaseLoadTableHeaderCellProps> = React.memo((props) => {
  const classes = useClasses();
  const theme = useTheme();
  const [headerRef, setHeaderRef] = useState<Element | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleClick = (target?: Element) => {
    setHeaderRef(target);
    setOpen(!open);
  };

  const changedOrderSelection = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: CaseLoadOrderColumn | null) => {
    if (props.click) {
      const targColVal = event.currentTarget.getAttribute('value');
      handleClick(event.currentTarget);

      if (targColVal) {
        const foundColumn = Object.values(CaseLoadOrderColumn).find(column => column === targColVal);
        if (foundColumn) {
          props.click(foundColumn, event.currentTarget);
        }
      } else {
        props.click(value != null ? value : props.order.column, event.currentTarget);
      }
    }
  };

  const columnForSortType = (sortType: StudentSortType) => {
    switch (props.column) {
      case CaseLoadOrderColumn.TwoMonthsAgoMTS:
        switch (sortType) {
          case StudentSortType.MTSStatus:
            return CaseLoadOrderColumn.TwoMonthsAgoMTS;
          case StudentSortType.MTSDocs:
            return CaseLoadOrderColumn.TwoMonthsAgoDocs;
          case StudentSortType.MTSHours:
            return CaseLoadOrderColumn.TwoMonthsAgoHours;
          case StudentSortType.MTSWPRHours:
            return CaseLoadOrderColumn.TwoMonthsAgoWPRHours;
          default:
            return CaseLoadOrderColumn.TwoMonthsAgoMTS;
        }
      case CaseLoadOrderColumn.LastMonthMTS:
        switch (sortType) {
          case StudentSortType.MTSStatus:
            return CaseLoadOrderColumn.LastMonthMTS;
          case StudentSortType.MTSDocs:
            return CaseLoadOrderColumn.LastMonthMTSDocs;
          case StudentSortType.MTSHours:
            return CaseLoadOrderColumn.LastMonthMTSHours;
          case StudentSortType.MTSWPRHours:
            return CaseLoadOrderColumn.LastMonthMTSWPRHours;
          default:
            return CaseLoadOrderColumn.LastMonthMTS;
        }
      case CaseLoadOrderColumn.CurrentMTS:
        switch (sortType) {
          case StudentSortType.MTSStatus:
            return CaseLoadOrderColumn.CurrentMTS;
          case StudentSortType.MTSDocs:
            return CaseLoadOrderColumn.CurrentMTSDocs;
          case StudentSortType.MTSHours:
            return CaseLoadOrderColumn.CurrentMTSHours;
          case StudentSortType.MTSWPRHours:
            return CaseLoadOrderColumn.CurrentMTSWPRHours;
          default:
            return CaseLoadOrderColumn.CurrentMTS;
        }
      default:
        return CaseLoadOrderColumn.CurrentMTS;
    }
  };

  const accessoryIcon = (sortType: StudentSortType) => {
    if (columnForSortType(sortType) !== props.order.column) {
      return null;
    }

    switch (sortType) {
      case StudentSortType.MTSStatus:
        const comp = props.order.order.orderBy.split('-');
        if (comp.length > 1) {
          const state = comp[comp.length - 1] as MTSState;
          if (state === MTSState.APPROVED) {
            return <Icon className={classes.success}>check_circle</Icon>;
          } else if (state === MTSState.SUBMITTED) {
            return <Icon style={{ color: theme.palette.warning.main }}>swap_vertical_circle</Icon>;
          } else {
            return <Icon color="error">error</Icon>;
          }
        } else {
          return null;
        }
      case StudentSortType.MTSDocs:
        if (props.order.order.sortDirection === SortDirection.DESC) {
          return <Icon className={classes.success}>check_circle</Icon>;
        }

        return <Icon color="error">error</Icon>;
      case StudentSortType.MTSHours:
      case StudentSortType.MTSWPRHours:
        if (props.order.order.sortDirection === SortDirection.ASC) {
          return <Icon>arrow_upward</Icon>;
        }

        return <Icon>arrow_downward</Icon>;
      default:
        return null;
    }
  };

  return (
    <>
      <CaseLoadTableHeaderCell
        column={props.column}
        order={props.order}
        content={props.content}
        click={(column, target) => handleClick(target)}
      />
      <Popover
        open={open}
        anchorEl={headerRef}
        onClose={() => handleClick(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <Paper className={classes.popover}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <LanguageString groupName="CASE_LOAD" resourceName="SORT_BY" defaultText="Sort By" />
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                exclusive
                onChange={changedOrderSelection}
                value={props.order.column}
              >
                <ToggleButton value={columnForSortType(StudentSortType.MTSStatus)}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS_STATUS_SORT" defaultText="MTS Status" />&nbsp;
                  {accessoryIcon(StudentSortType.MTSStatus)}
                </ToggleButton>
                <ToggleButton value={columnForSortType(StudentSortType.MTSDocs)}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS_DOCS_SORT" defaultText="Docs" />&nbsp;
                  {accessoryIcon(StudentSortType.MTSDocs)}
                </ToggleButton>
                <ToggleButton value={columnForSortType(StudentSortType.MTSHours)}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS_HOURS_SORT" defaultText="Hours" />&nbsp;
                  {accessoryIcon(StudentSortType.MTSHours)}
                </ToggleButton>
                <ToggleButton value={columnForSortType(StudentSortType.MTSWPRHours)}>
                  <LanguageString groupName="CASE_LOAD" resourceName="MTS_WPR_SORT" defaultText="WPR Hours" />&nbsp;
                  {accessoryIcon(StudentSortType.MTSWPRHours)}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </>
  );
});
